import { useSelector, useDispatch } from "react-redux"
import { requestPlaceOrder, requestUserOrder, requestCheckShippingZipcode } from './checkout.actions'
import { checkoutActions as actions } from './checkout.slice'


const useCheckout = () => {
  const dispatch = useDispatch();
  const {
    shipping, 
    billing,
    orderCapture, 
    shippingCountry,
    zip,
    warehouse,
    retailer,
    orderPlaced,
    newOrderId,
    square,
    paymentMethod,
    paymentDetails,
    paymentStep,
    isGift,
    giftMessage,
    orderDataFetchState,
    processingRequest,
    processingRequests,
    error,
    orderCaptureAction,
    answer
  } = useSelector(state => state.checkout)
  const checkoutState = {
    shipping, 
    billing,
    orderCapture, 
    shippingCountry,
    zip,
    warehouse,
    retailer,
    orderPlaced,
    newOrderId,
    square,
    paymentMethod,
    paymentDetails,
    paymentStep,
    isGift,
    giftMessage,
    orderDataFetchState,
    processingRequest,
    processingRequests,
    error,
    orderCaptureAction,
    answer
  }
  const checkoutActions = {}

  checkoutActions.requestUserOrder = ({ language = 'en' }) => dispatch(requestUserOrder({ language }))

  checkoutActions.requestPlaceOrder = () => dispatch(requestPlaceOrder())

  checkoutActions.requestCheckShippingZipcode = (zipcode) =>
    dispatch(requestCheckShippingZipcode(zipcode))

  checkoutActions.setPaymentMethod = (paymentMethod) => dispatch(actions.setPaymentMethod(paymentMethod))

  checkoutActions.changePaymentStep = paymentStep => dispatch(actions.changePaymentStep(paymentStep))

  checkoutActions.changeShippingZipcode = zipcode => dispatch(actions.changeShippingZipcode(zipcode))

  checkoutActions.setOrderWarehouse = warehouseData => dispatch(actions.setOrderWarehouse(warehouseData))

  checkoutActions.changeRetailer = retailerData => dispatch(actions.changeRetailer(retailerData))

  checkoutActions.changeShippingCountry = shippingCountry => dispatch(actions.changeShippingCountry(shippingCountry))

  // changeBillingDetails: (state, { payload }) => {
  //   //
  //   const { isEmpty, ...billingData } = payload.billing;
  //   state.billing = billingData
  //   state.paymentDetails = payload.token
  // },
  checkoutActions.changeBillingDetails = ({ billing }) => dispatch(actions.changeBillingDetails({ billing }))

  // changeContactDetails: (state, action) => {
  //   const { contactType, isEmpty, ...details } = action.payload;
  //   state[contactType] = details;
  // },
  checkoutActions.changeContactDetails = (contactData) => dispatch(actions.changeContactDetails(contactData))

  checkoutActions.changeGiftMessage = giftMessage => dispatch(actions.changeGiftMessage(giftMessage))

  checkoutActions.changeIsGiftState = state => dispatch(actions.changeIsGiftState(state))

  checkoutActions.finishCheckout = () => dispatch(actions.finishCheckout())

  checkoutActions.changeCheckoutLegalAgeAnswer = (answer) => dispatch(actions.changeCheckoutLegalAgeAnswer(answer))


  return { checkoutState, checkoutActions }
}


export default useCheckout;
