import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';



const LinkCSV = ({ data, headers, label, fileName, onClick, className }) => {
  return (
    <div>
      <CSVLink
        onClick={onClick}
        className={className}
        data={data} headers={headers}
        separator={","} filename={fileName}
      >{label}</CSVLink>

    </div>
  )
}

LinkCSV.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  label: PropTypes.string,
  fileName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}

LinkCSV.defaultProps = {
  onClick: () => { }
}

export default LinkCSV;
