import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import { CountryContext } from '../../context/country';

import icon from '../../assets/icons/currency.svg';
import './style.scss';


const CurrencyMenu = ({ mainClassName, withIcon, onChange, prefCurrency }) => {
    const { changeCurrency, currency } = useContext(CountryContext);

    const currenciesOptions = [
        { label: 'usd', value: 'usd' },
        { label: 'eur', value: 'eur' }
    ];

    const currencyView = <div className="language-menu-view">
        { withIcon && <img src={icon} alt="currency" /> }

        <span className="language">{ (prefCurrency || currency).toUpperCase() }</span>
    </div>

    const handleChooseCurrency = ({ value }) => {
        changeCurrency(value);

        onChange && onChange(value)
    }


    return (
        <Dropdown
            className={`main-menu${mainClassName && ' ' + mainClassName}`}
            controlClassName="main-menu__control"
            menuClassName="main-menu__menu"
            arrowClassName="main-menu__arrow"
            options={currenciesOptions}
            value={{ label: currencyView, value: '' }}
            onChange={handleChooseCurrency}
            placeholder="Currency Menu"
        />
    )
}


CurrencyMenu.propTypes = {
    mainClassName: PropTypes.string,
    withIcon: PropTypes.bool,
    onChange: PropTypes.func
}

CurrencyMenu.defaultProps = {
    withIcon: false
}

export default CurrencyMenu
