import { createAsyncThunk } from '@reduxjs/toolkit'
import * as OrderService from '../../services/order.service'


export const requestOrdersList = createAsyncThunk(
  '@@orders/request-orders-list',
  async ({ caseId, orderTypes, userId, warehouse, from, to, incomplete, location }, thunkAPI) => {
    try {
      const orders = await OrderService.fetchOrdersList({ 
        caseId, orderTypes, userId, warehouse, from, to, incomplete, location 
      })

      return orders;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestUserOrders = createAsyncThunk(
  '@@orders/request-user-orders',
  async ({ language = 'en' }, thunkAPI) => {
    try {
      const orders = await OrderService.fetchUserOrders({ language });

      return orders;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
