
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import useHelpers from './helpers.hook';

const LOCAL_KEY = 'a7368c854bdef660dc718d09a39c8f67'
const MX_KEY = '4edba69ea847f7dbfc5670e40e92d660'

const { host } = document.location;
const key = host.includes('localhost') ? LOCAL_KEY : MX_KEY
mixpanel.init(key);


const useAnalitics = () => {
  const { createMxUserId } = useHelpers();

  const event = {
    VIEW_HOME_PAGE: 'View Home',
    VIEW_MY_WINES: 'View My Wines',
    VIEW_TASTINGS: 'View Tastings',
    VIEW_CHECKOUT_PAGE: 'View Checkout Page',
  }

  const sendPageView = (title, pathname, search, userId) => {
    if (host.search('vineify.com') < 0) return;

    const tagManagerArgs = {
      dataLayer: {
        userId,
        page: pathname
      },
      dataLayerName: 'PageViewDataLayer'
    }

    TagManager?.dataLayer(tagManagerArgs);
  }

  const sendGtmEvent = (eventName, props) => {
    if (host.includes('vineify')) {
      const tagManagerArgs = {
        dataLayer: { ...props },
        dataLayerName: eventName
      }

      TagManager?.dataLayer(tagManagerArgs);
    }
  }

  const senFbPageView = ({  }) => {
    // fbq
  }

  const sendMxPageView = (eventName, userId, role, userName, ip) => {
    host.includes('vineify.com') && role !== 'admin' &&
      mixpanel.track(eventName, { 'Host': host, 'User ID': userId, 'User Role': role, 'User Name': userName, 'IP': ip });

    host.includes('localhost') &&
      mixpanel.track(eventName, { 'Host': host, 'User ID': userId, 'User Role': role, 'User Name': userName, 'IP': ip });
  }

  const sendMxCustomEvent = (eventName, { userId, caseName, caseId, casePrice, userName, role, ...otherProps }) => {
    const { _suav, ...eventProps } = otherProps;

    host.includes('vineify.com') && !_suav &&
    // !_suav &&
      mixpanel.track(eventName, {
        'Host': host, 'User ID': userId, 'User Role': role, 'User Name': userName,
        'Case Name': caseName, 'Case ID': caseId, 'Case Price': casePrice, 'IP': otherProps?.ip,
        ...eventProps
      });
  }

  const createMxUser = userData => {
    if (!host.includes('vineify.com') || !host.includes('localhost')) return;

    const { firstName, lastName, email, id } = userData;
    const uniqueId = createMxUserId(id);
    mixpanel.people.set({ "$name": `${firstName} ${lastName}`, "$email": email, "$unique_id": uniqueId, "User ID": id });
  }

  const identifyMxUser = id => {
    if (!host.includes('vineify.com') || !host.includes('localhost')) return;

    mixpanel.identify(id);
  }

  return {
    sendMxCustomEvent, sendPageView, sendMxPageView, event, createMxUser, identifyMxUser, sendGtmEvent
  }
}

export default useAnalitics;
