import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import hideIco from '../../assets/icons/chevron-up.svg';
import showIco from '../../assets/icons/chevron-down.svg';

import './style.scss';


const ReportCard = ({ children, name, id, expand, info }) => {
  const [expanded, changeExpandedState] = useState(expand);

  const cardClasses = classNames('card report-card', {
    'expanded': expanded
  });

  return (
    <div className={ cardClasses }>
      <div className="card__body">
        <div className="report-data">
          <div className="id">{ id }</div>
          <div className="name">{ name }</div>
          <div className="info">{ info }</div>
          <button 
            className="button-link"
            onClick={ () => changeExpandedState(!expanded) }
          >
            { expanded ? 'hide' : 'show' } details <img src={ expanded ? hideIco : showIco } alt="expand" />
          </button>
        </div>
        {
          expanded && 
          <div className="report-content">{ children }</div>
        }
      </div>
    </div>
  )
}


ReportCard.propTypes = {
  expand: PropTypes.bool,
  children: PropTypes.node,
  name: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  info: PropTypes.string
}

ReportCard.defaultProps = {
  expand: false
}

export default ReportCard;
