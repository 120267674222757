import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const AccountPreferencesForm = ({ contacts }) => {
    const [shippingData, setShippingData] = useState({
        address: contacts.shipping ? contacts.shipping.address : '', 
        state: '', 
        zip: contacts.shipping ? contacts.shipping.zip : ''
    });
    // eslint-disable-next-line
    const [cards, setCards] = useState([]);
    const [billingData, setBillingData] = useState({
        address: contacts.billing ? contacts.billing.address : '', 
        state: '', 
        zip: contacts.billing ? contacts.billing.zip : ''
    });
    // eslint-disable-next-line
    const [addCardFormIsVisible, changeCardFormVisibility] = useState(false);
    // eslint-disable-next-line
    const [confirmationDialogIsVisible, changeConfirmationDialogVisibility] = useState(false);


    const handleInputChange = (e, setValue) => {
        const [section, field] = e.target.name.split('_');

        let data = { shipping: shippingData, billing: billingData, card: cards };

        const value = {
            ...data[section],
            [field]: e.target.value
        }

        setValue(value);

    }


    return (
        <>
            <form>
                <h3 className="form-section-title">Shipping Details</h3>
                <div className="form-section section_horizontal">
                    <div className="form-control">
                        <label htmlFor="shipping_address">Shipping Address</label>
                        <input
                            type="text" placeholder="Shipping address" name="shipping_address"
                            onChange={e => handleInputChange(e, setShippingData)}
                            value={shippingData.address}
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="shipping_state">State</label>
                        <input
                            type="text" placeholder="TX" name="shipping_state"
                            onChange={e => handleInputChange(e, setShippingData)}
                            value={shippingData.state}
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="shipping_zip">Zip Code</label>
                        <input
                            type="text" placeholder="TX" name="shipping_zip"
                            onChange={e => handleInputChange(e, setShippingData)}
                            value={shippingData.zip}
                        />
                    </div>
                </div>
                <button className="button">Save Shipping Details</button>
            </form>

            <form>
                <h3 className="form-section-title">Billing Details</h3>
                <div className="form-section section_horizontal">

                    <div className="form-control">
                        <label htmlFor="billing_address">Billing Address</label>
                        <input
                            type="text" placeholder="Cardholder Name" name="saved-card-holder"
                            value="John Doe"
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="billing_state">Credit Card Number</label>
                        <input
                            type="text" name="saved-card-number"
                            value="XXXX-XXXX-XXXX-1234"
                            onChange={ e => console.log('Card changed') }
                        />
                    </div>

                    <div className="form-control control-button">
                        <Link className="button-link" onClick={e => console.log('Remove Card')} to="/">Remove Card</Link>
                        <Link className="button-link" onClick={e => console.log('Add Card')} to="/">Add Card</Link>
                    </div>
                </div>
            </form>

            {
                addCardFormIsVisible &&
                <form>
                    <div className="form-section section_horizontal">

                        <div className="form-control">
                            <label htmlFor="billing_address">
                                New Credit Card Number
                            </label>
                            <input
                                type="text" placeholder="XXXX-XXXX-XXXX-1234" name="billing_address"
                                onChange={e => handleInputChange(e, setBillingData)}
                                value={billingData.address}
                            />
                        </div>

                        <div className="form-control">
                            <label htmlFor="billing_state">Expiry Date</label>
                            <input
                                type="text" placeholder="01/20" name="billing_state"
                                onChange={e => handleInputChange(e, setBillingData)}
                                value={billingData.state}
                            />
                        </div>

                        <div className="form-control">
                            <label htmlFor="billing_zip">Security Code</label>
                            <input
                                type="text" placeholder="XXX" name="billing_zip"
                                onChange={e => handleInputChange(e, setBillingData)}
                                value={billingData.zip}
                            />
                        </div>
                    </div>
                    <button className="button">Save Billing Details</button>
                </form>
            }
            {
                confirmationDialogIsVisible &&
                <div className="confirmation-dialog">
                    <p className="no-margin text-gray">Are you sure that you would like to remove this credit card from your account?</p>
                    <button className="button">Yes, Remove Card</button>
                    <button className="button button_outline">No, Cancel</button>
                </div>
            }

            <form>
                <div className="form-section section_horizontal">

                    <div className="form-control">
                        <label className="with-checkbox" htmlFor="billing_address">
                            Billing Address                            
                        </label>
                        <input
                            type="text" placeholder="Billing address" name="billing_address"
                            onChange={e => handleInputChange(e, setBillingData)}
                            value={billingData.address}
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="billing_state">State</label>
                        <input
                            type="text" placeholder="TX" name="billing_state"
                            onChange={e => handleInputChange(e, setBillingData)}
                            value={billingData.state}
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="billing_zip">Zip Code</label>
                        <input
                            type="text" placeholder="TX" name="billing_zip"
                            onChange={e => handleInputChange(e, setBillingData)}
                            value={billingData.zip}
                        />
                    </div>
                </div>
                <div className="button-container small left">
                <button className="button">Save Billing Details</button>
                <label className="checkbox-wrapper">
                    Same as Shipping
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                </label>
                </div>
            </form>
        </>
    )
}


AccountPreferencesForm.propTypes = {
    contacts: PropTypes.shape({
        billing: PropTypes.shape({
            address: PropTypes.string, 
            state: PropTypes.string, 
            zip: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
        }),
        shipping: PropTypes.shape({
            address: PropTypes.string, 
            state: PropTypes.string, 
            zip: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
        })
    })
}

AccountPreferencesForm.defaultProps = {
    contacts: {
        billing: {
            address: '',
            state: '',
            zip: ''
        },
        shipping: {
            address: '',
            state: '',
            zip: ''
        }
    }
}


export default AccountPreferencesForm;
