import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const TrackingInfoAlert = ({ state, message, title }) => {
    const alertClasses = classNames(`alert alert_tracking alert-${state}`);

    return (
        <div className={ alertClasses }>
            <h4 className="alert_tracking-title">{ title }</h4>
            <p className="alert_tracking-msg">{ message }</p>
        </div>
    )
}


TrackingInfoAlert.propTypes = {
    state: PropTypes.oneOf(['success', 'warning', 'info']), 
    message: PropTypes.string, 
    icon: PropTypes.string, 
    title: PropTypes.string
}

TrackingInfoAlert.defaultProps = {
    state: 'info'
}

export default TrackingInfoAlert
