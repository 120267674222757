import React from 'react'
import PropTypes from 'prop-types'

import './style.scss';


const CaseStockBadge = ({ stock, className, isSmall, isNarrow, showCurrentlyUnavailable }) => {
  const classNamesList = ['case-stock-badge'];
  if (className) classNamesList.push(className);
  if (isSmall) classNamesList.push('small-case-card')

  const classNames = classNamesList.join(' ')

  if (stock === null) return null;

  if (stock > 2) return null;

  if (stock === 0) 
    return showCurrentlyUnavailable 
      ? <span className={ classNames }>{ isNarrow ? `Unavailable` : `Currently Unavailable` }</span>
      : null
  
  if (stock > 0 && stock < 3)
    return <span className={ classNames }>Only { stock } left!</span>
}

CaseStockBadge.propTypes = {
  stock: PropTypes.number,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isNarrow: PropTypes.bool,
  showCurrentlyUnavailable: PropTypes.bool,
}

CaseStockBadge.defaultProps = {
  stock: 0,
  isSmall: false,
  isNarrow: false,
  showCurrentlyUnavailable: true
}

export default CaseStockBadge;