import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { v4 as genKey } from 'uuid';
import { TastingWineCard } from '../../components';
import { useHelpers, useScreenSize } from '../../hooks';

import './style.scss';


const TastingWinesBlock = ({ wines, title, onShowVideo }) => {
  const [isExpanded, changeExpandState] = useState(false);
  const { t } = useTranslation();
  const { screenWidth } = useScreenSize();
  const { scrollTo, capitalize } = useHelpers();
  const winesBlockRef = useRef();
  const numOfCardsInRow = screenWidth > 768 ? 3 : 2;
  const winesContent = !wines?.length
    ? <p>{t('TASTINGS_NO_WINES_NOTIFICATION')}</p>
    : !isExpanded
      ? wines.slice(0, numOfCardsInRow).map(
        wine => <TastingWineCard key={genKey()} wine={wine} onPlayVideo={onShowVideo} />
      ) : wines.map(
        wine => <TastingWineCard key={genKey()} wine={wine} onPlayVideo={onShowVideo} />
      )

  const handleToggleExpandState = () => {
    changeExpandState(!isExpanded);
    scrollTo(winesBlockRef.current, 0, 250, 25);
  }


  return (
    <div className={classNames('tasting-wines-block', { 'expanded': isExpanded })}>
      <div className="tasting-wines-block__header" ref={winesBlockRef}>
        <h4>{title}</h4>

        <p>{t('WINES')}: {wines?.length || 0}</p>
      </div>

      <div className="tasting-wines-wrapper">{winesContent}</div>
      {
        wines?.length > 3 &&
        <button
          className="button-link"
          onClick={handleToggleExpandState}
        >{isExpanded ? `${capitalize(t('HIDE'))} ${title}` : `${capitalize(t('SHOW'))} ${title}`}</button>
      }
    </div>
  )
}

TastingWinesBlock.propTypes = {
  wines: PropTypes.array,
  title: PropTypes.string
}

export default memo(TastingWinesBlock)
