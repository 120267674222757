import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHelpers } from '../../hooks';
import classNames from 'classnames';
import {Badge} from '..';

import buttonIcon from '../../assets/icons/info-circle.svg';


const AdminCaseMobileRow = ({ name, price, currency, stock, prices, id, createdAt, updatedAt, active: state, category, customer, onShowCasePriceDetails, orders, curator, hideCurator, lowCaseStock }) => {
  const { user } = useSelector(state => state.auth)
  const { formatDateTime } = useHelpers();
  const showOrders = user.role === 'admin' || hideCurator
  const status = ['draft', 'inactive', 'active', 'highlighted'][state + 1];
  const lowStock = lowCaseStock || 3
  
  const isLowStock = stock > 0 && stock <= lowStock;
  const isOutOfStock = stock === 0;
  const className = stock > lowStock ?
    'admin-case-mobile-row' : stock === 0 ?
      'admin-case-mobile-row no-stock' : 'admin-case-mobile-row low-stock';

  const stockBadge = isLowStock || isOutOfStock
    ? <Badge
      className={classNames('stock-badge', {
        'low': isLowStock,
        'out': isOutOfStock
      })}
      statusText={isLowStock ? `low stock` : `out of stock`} withoutIcon
    />
    : undefined

  const handleInitShowCasePriceDetails = e => {
    e.preventDefault();

    onShowCasePriceDetails(id)
  }

  return (
    <Link to={`/${user?.role === 'curator' ? 'curator' : 'admin'}/cases/${id}`} className={className}>
      {showOrders && <span className="id"><b>ID:&nbsp;</b>{id}</span>}
      <span className="date"><b>Date:&nbsp;</b>{formatDateTime(createdAt, false)}</span>
      <span className="name"><b>Name:&nbsp;</b>{name}</span>
      {
        !hideCurator &&
        <span className="curator"><b>Curator:&nbsp;</b>{curator}</span>
      }
      {
        user?.role === 'admin' &&
        <span className="category"><b>Category:&nbsp;</b>{
          category === 'custom' ?
            `custom\n${customer}` : category
        }</span>
      }
      {
        user?.role === 'admin' &&
        <span className="cost flex-space-between"><b>Cost:&nbsp;</b>
          <b>
            {['all', 'eur'].includes(currency) && <>€{prices?.eur || 0}{' '}</>}
            {['all', 'usd'].includes(currency) && <>${prices?.usd || 0}</>}
          </b>

          {
            user?.role !== 'curator' &&
            <button
              className="button-link not-underlined"
              onClick={handleInitShowCasePriceDetails}
            ><img src={buttonIcon} alt="" /></button>
          }
        </span>
      }
      {
        user?.role === 'curator' &&
        <span className="cost"><b>Cost: </b>${price || 0}</span>
      }
      {
        showOrders &&
        <span className="orders"><b>Orders:&nbsp;</b>{orders || 0}</span>
      }
      <span className="stock"><b>Stock:&nbsp;</b>{stock}{stockBadge}</span>
      <span className={`w-100 text-left status ${status}`}><b>Status:&nbsp;</b>{status}</span>
    </Link>
  )
}


AdminCaseMobileRow.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  prices: PropTypes.shape({
    eur: PropTypes.number,
    usd: PropTypes.number
  }),
  stock: PropTypes.number,
  id: PropTypes.number,
  createdAt: PropTypes.string,
  active: PropTypes.number,
  onShowCasePriceDetails: PropTypes.func
}

AdminCaseMobileRow.defaultProps = {
  stock: 0,
  active: 0
}

export default AdminCaseMobileRow
