import React, { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';

import image from '../../assets/images/no-image.jpg';


const ImageUploadFormControlWithAutoGeneratingCaseImage = ({
  title,
  imageList,
  onImageLoaded,
  buttonLabel,
  autogeneratedContent,
  onUseGeneratedImage,
  showGeneratedImage,
  hideUploadButton
}) => {
  const [images, setImages] = useState(imageList);
  const defaultLabel = images.length > 0 ? 'Update' : 'Upload';

  const handleImageUpload = file => {
    onImageLoaded(file[0], 'manual')
    setImages([...file])
  }

  const handleImageLoading = (e, imageUpload) => {
    e.preventDefault();

    imageUpload();
  }

  const handleDeleteImage = () => {
    onImageLoaded({ file: undefined, data_url: undefined }, 'manual')
  }

  const handleUseGeneratedImage = () => onUseGeneratedImage()

  useEffect(() => { setImages(imageList) }, [imageList]);

  return <ImageUploading
    onChange={handleImageUpload}
    maxNumber={1}
    dataURLKey="data_url"
  >
    {({
      imageList,
      onImageUpload,
      isDragging,
      dragProps,
    }) => (

      <div className="upload__image-wrapper center">
        <div className="img-container">
          {title && <h3 className="img-title">{title}</h3>}
          {
            !showGeneratedImage &&
            <img src={images.length && images[0].data_url ? images[0].data_url : image} alt="uploaded file" />
          }

          {/* <img src={images.length && images[0].data_url ? images[0].data_url : image} alt="uploaded file" /> */}

          {autogeneratedContent}
        </div>

        {
          !hideUploadButton &&
          <div className="upload__control horizontal">
            <button
              style={isDragging ? { color: 'red' } : undefined}
              className="button button_xs w-100"
              onClick={e => handleImageLoading(e, onImageUpload)}
              {...dragProps}
            >
              {/* { console.log('btn label: ', buttonLabel) && images.length > 0 ? 'Update Image' : 'Upload Image' } */}
              {buttonLabel || defaultLabel}
            </button>

            <button
              type="button"
              className="button w-100 button_info button_xs"
              onClick={handleUseGeneratedImage}
            >Generate</button>
          </div>
        }
      </div>
    )}
  </ImageUploading>
}

ImageUploadFormControlWithAutoGeneratingCaseImage.propTypes = {

}

export default ImageUploadFormControlWithAutoGeneratingCaseImage
