import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import { CountryContext } from '../../context/country';
import { useRequests } from '../../hooks';

import icon from '../..//assets/icons/location.svg';
import './style.scss';


const CountryMenu = ({ mainClassName, withIcon, onChange, country }) => {
  const { shippingCountriesList: countries, currentCountry, changeCountryData, currency, countriesTranslations } = useContext(CountryContext);
  const { get } = useRequests();
  const { i18n: { language: lng } } = useTranslation();

  const handleChooseCountry = async ({ value: country }) => {
    const response = await get({ url: `/api/shipping/charges/${ country }` });

    if (response?.data?.success) {
      const { shippingCharge } = response.data?.chargeData || { shippingCharge: { usd: 0, eur: 0 }, currency: null }
      // changeCountry(country, +response.data?.chargeData?.bottle_shipping_charge || 0);
      changeCountryData(country, shippingCharge, currency);
      
      onChange && onChange(country);
    }
  }


  if (currentCountry && countries.length > 0 && !countries.includes(currentCountry)) {
    const newCountry = countries[0];

    get({ url: `/api/shipping/charges/${ newCountry }` })
      .then(
        response => {
          if (response?.data?.success) {

            // changeCountry(newCountry, +response.data?.chargeData?.bottle_shipping_charge || 0);
            changeCountryData(newCountry, +response.data?.chargeData?.shippingCharge || { usd: 0, eur: 0 }, response.data?.chargeData?.currency );
          }
        } 
      )
  }

  // const countriesOptions = countries.map(
  //   country => ({ value: country, label: country })
  // )
  const countriesOptions = countries.map(
    country => ({ value: country, label: countriesTranslations[country][lng] })
  )

  const countryView = <div className="language-menu-view">
    { withIcon && <img src={icon} alt="country" /> }

    <span className="language">{country || countriesTranslations[currentCountry] ? countriesTranslations[currentCountry][lng] : ''}</span>
  </div>

  return (
    <Dropdown
      className={`main-menu${mainClassName && ' ' + mainClassName}`}
      controlClassName="main-menu__control"
      menuClassName="main-menu__menu"
      arrowClassName="main-menu__arrow"
      options={countriesOptions}
      value={{ label: countryView, value: '' }}
      onChange={handleChooseCountry}
      placeholder="Country Menu"
    />
  )
}


CountryMenu.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  withIcon: PropTypes.bool,
  onChange: PropTypes.func
}

CountryMenu.defaultProps = {
  withIcon: true
}


export default CountryMenu;
