import { useEffect } from 'react'; 
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import store from './store';

import { Provider } from 'react-redux'
import RedirectProvider from './providers/RedirectProvider';
import RoutesProvider from './providers/RoutesProvider';
import { CountryProvider } from './context/country';
import { useActions,useRoutes, useTranslations } from './hooks';
import { LoadingScreen } from './components';
import ErrorBoundary from './components/ErrorBoundary';

import 'react-toastify/dist/ReactToastify.css';
import 'react-simple-hook-modal/dist/styles.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './App.scss';


function App() {
  useTranslations()
  const { authActions, regionActions } = useActions();
  // const {
  //   login, logout, user: userData, dataLoaded, initialData, isAuthenticated, region, changeRegion, changeUserNewWinesNotification, setUserData
  // } = useAuth();
  // const { handleSetFetchingPageData, fetchingPageData } = useRequests();
  // const { currency, location, userAgent, changeCurrency, getInitialLocationData } = useGeoLocation();
  // const cart = {
  //   paypalId: initialData.paypalId,
  //   order: {},
  //   shipping: {},
  //   billing: {}
  // }
  // const cases = [];

  // const user = {
  //   ...userData,
  //   currency, location, userAgent, quiz: false
  // };
  const { isAuthenticated, initialDataIsFetched, user } = store.getState().auth

  // const currentCountry = initialData?.country || location?.country?.name || '';

  const initialLocation = {
    // country: currentCountry,
    // charge: initialData?.charge || { usd: 0, eur: 0 },
    // countryCode: location?.country?.code || '',
    // shippingCountriesList: initialData?.countriesList || [],
    // currency: userData?.preferences?.currency || initialData?.currency || 'usd',
    // countriesTranslations: {}
  }

  useEffect(() => {
    store.dispatch(authActions.authenticate())
    store.dispatch(regionActions.requestRegionsList())
    store.dispatch(regionActions.requestCountriesDataList())
  }, [])


  const routes = useRoutes(isAuthenticated, user?.isAdult, undefined, 'USD');
  const content = initialDataIsFetched ? routes : <Route><LoadingScreen /></Route>;


  return (
    <Router>
      <Provider store={store}>
        <ErrorBoundary>
            <RedirectProvider>
              <CountryProvider initialLocation={initialLocation} isAuthenticated={isAuthenticated} >
                <div className="App">
                  <Switch>
                    <RoutesProvider />
                    
                    <Redirect to="/" />
                  </Switch>
                </div>
              </CountryProvider>
            </RedirectProvider>
        </ErrorBoundary>
      </Provider>
    </Router>
  );
}


export default App;
