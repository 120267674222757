import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';

import './style.scss';


const PaypalSteps = () => {
  const history = useHistory();
    const location = useLocation();
    const [steps, setSteps] = useState(null);


    const createSteps = useCallback(() => {
        setSteps([
            // {label: 'Homepage', link: '/home'},
            {label: 'Homepage', link: '/'},
            {label: 'Checkout', link: '/cart/payment'}
        ].map(({label, link}, ind) => {
            return <li key={label} className={classNames('steps__item', {
                'active': label === 'Checkout'
            })}>{ label === 'Checkout' ? <span>{label}</span> : <Link to={ link }>{label}</Link> }</li>
        }))
    //eslint-disable-next-line
    }, [history.location.pathname])

    useEffect(createSteps, [createSteps, location]);


    if (!steps) return null;


    return (        
        <div className="steps">
            <ul className="steps__list">{steps}</ul>
        </div>
    )
}

export default PaypalSteps