import React from 'react'
import PropTypes from 'prop-types'
import {WinesTypesSummary, RegionsTagsList} from '..'
import { useHelpers } from '../../hooks'
import { useHistory } from 'react-router-dom'

import './style.scss'


const CaseTile = ({ className, name, id, slug, inventory, price, region, regions, winesTypes, wines, orders, active, children }) => {
  const { createWinesTypes } = useHelpers()
  const history = useHistory()

  const classnamesList = ['case-data']
  if (className) {
    classnamesList.push(className)
  }

  const wineTypesTags = winesTypes || createWinesTypes(wines)

  const handleGoToCaseDetails = slug => e => {
    history.push(`/cases/${slug}`)
  }

  // const stock = +active > 0 ? inventory : 0
  const stock = inventory
  const activeString = ['inactive', 'active', 'active'][+active]

  const stats = [`stock: ${stock}, ${activeString}`]
  if (orders >= 0) {
    stats.push(`orders: ${orders || 0}`)
  }


  return (
    <div className={classnamesList.join(' ')} key={`case_data_${id}`}>
      {/* <Link to={`/cases/${id}`}>{name}</Link> */}
      <div className="case-data__row">
        <button
          className="button-link"
          onClick={handleGoToCaseDetails(slug)}
        >{name}</button>

        <div className="case-data__wines-summary">
          <WinesTypesSummary {...wineTypesTags} withZeroValues={true} />
        </div>

        <div className="case-main-info">${price}{ stats.length > 0 && <> ({ stats?.reverse()?.join(', ') })</> }</div>

        { children }
      </div>

      {region === 'mixed' && <div className="case-data__row"><RegionsTagsList regions={regions} /></div>}
    </div>
  )
}

CaseTile.propTypes = {
  inventory: PropTypes.number
  , name: PropTypes.string
  , id: PropTypes.number
  , orders: PropTypes.number
  , slug: PropTypes.string
  , price: PropTypes.string
  , region: PropTypes.string
  , regions: PropTypes.array
  , winesTypes: PropTypes.array
  , wines: PropTypes.array
  , children: PropTypes.node
}


export default CaseTile
