import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHelpers } from '../../hooks';

import './style.scss';


const RegisterFooter = () => {
  const { t } = useTranslation(); 
  const { capitalize } = useHelpers();

  
  return (
    <footer>
      <div className="footer-links">
        <Link className="simple-link" to="/feedback">{ capitalize(t('CONTACT_US')) }</Link>

        <Link className="simple-link" to="/terms">{ t('TERMS_LINK') }</Link>

        <Link className="simple-link" to="/privacy-policy">{ t('PRIVACY_LINK') }</Link>
      </div>

      <p className="text-gray">&copy; Copyright 2020 - { new Date().getFullYear() } Vineify Inc.</p>
    </footer>
  )
}


export default RegisterFooter;
