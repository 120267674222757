import React, { memo, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import scrollTo from 'smooth-scroll-into-view-if-needed'
import { CardCarousel, CaseSliderCard, SixPackCasesAlert } from '../../../components'
import { useHistory, useLocation } from 'react-router-dom'
import PublicHomeCasesGrapesFilter from './PublicHomeCasesGrapesFilter'
import { useStoreCases } from '../../../hooks'


const PublicHomeCasesCarousel = ({ cases, onPurchase, onViewCaseContent, showBanner, title, useNewHeader, onChangeGrapeLabel, initialGrape }) => {
  const location = useLocation();
  const history = useHistory()
  const { casesState: { processingRequest } } = useStoreCases()
  const [filterGrapes, changeFilterGrapes] = useState(initialGrape ? [initialGrape] : [])
  const isMobile = window.matchMedia('(max-width: 520px)').matches
  const isTablet = window.matchMedia('(max-width: 768px)').matches

  const filteredCases = useMemo(() => {
    if (filterGrapes.length === 0) return cases;

    return cases?.filter(caseData => {
      // const hasWineWithGrapeFromTheFilterList = caseData.wines
      //   ?.findIndex(({ grape }) => filterGrapes.includes(grape)) >= 0

      // return hasWineWithGrapeFromTheFilterList;

      return caseData.labels?.includes(filterGrapes?.[0])
    }) || []
  }, [cases, filterGrapes])


  // const carouselCards = useMemo(() => filteredCases?.map(caseData => {
  const carouselCards = useMemo(() => cases?.map(caseData => {

    return (
      <CaseSliderCard
        key={`case-slider-card-${caseData.id}`}
        {...caseData}
        cardId={`case-inner-content-${caseData.id}`}
        onPurchase={onPurchase}
        onViewCaseContent={onViewCaseContent}
      />
    )
  }), [cases])

  const classNamesList = useMemo(() => {
    const classes = ['public-home__carousel-wrapper']

    if (processingRequest) classes.push('disabled')

    return classes.join(' ')
  }, [processingRequest])

  const numOfCards = carouselCards?.length || 0

  const showNavigation = isMobile
    ? numOfCards > 1 : isTablet
      ? numOfCards > 2 : numOfCards > 3

  const carousel = useMemo(() => {
    return (
        <CardCarousel
          config={{
            itemsToShow: isMobile ? 'auto' : isTablet ? 2 : 3,
            dots: showNavigation,
            slidesToScroll: 1
          }}
          infinite={false}
          dots={showNavigation}
          dynamicDots={true}
          spaceBetween={isTablet ? 12 : 32}
          cards={carouselCards}
          withNavigation={showNavigation}
          slideClassName="case-slider-card-wrapper"
        />
    )
  }, [carouselCards])

  const carouselContent = useMemo(() => {
    return (
      <div className={classNamesList}>{carousel}</div>
    )
  }, [carousel, classNamesList])


  useEffect(() => {
    if (location.hash === '#cases') {
      const section = document.getElementById('cases')

      scrollTo(section, { behavior: 'smooth', block: 'start', duration: 400, scrollMode: 'if-needed' })
    }
  }, [])

  const handleShowCollections = e => {
    e.preventDefault()

    history.push('/wine-collections')
  }

  const handleChangeGrapesFilter = grape => {
    // changeFilterGrapes(prev => {
    //   const newGrapes = prev.includes(grape)
    //     ? prev.filter(grapeName => grape !== grapeName)
    //     : [ ...prev, grape ]

    //   return newGrapes;
    // })
    if (filterGrapes[0] !== grape) {
      onChangeGrapeLabel(grape)
    } else {
      onChangeGrapeLabel()
    }

    changeFilterGrapes(prev => prev[0] === grape ? [] : [grape])
  }

  const oldHeader = (<>
    <h2 className="public-home__title">{title}</h2>

    <p className="public-home__subtitle">Bringing you the best wine from across Europe.</p>
  </>)

  const newHeader = (<>
    <div className="flex-space-between">
      <h2 className="public-home__title public-title-sm line-breaks fw-400">
        Tell us <b>your favorite grape</b>,{'\n'}
        we’ll show you <b>what else you’ll love</b>
      </h2>

      <button className="button button_outline flex-shrink-0 hide-sm"
        onClick={handleShowCollections}
      >View All Collections</button>
    </div>

  </>)


  return (
    <div className={`public-home__section${useNewHeader ? ` lite` : ``}`} id="cases">
      <div className="container">
        {
          useNewHeader ? newHeader : oldHeader
        }

        {
          useNewHeader &&
          <PublicHomeCasesGrapesFilter onChange={handleChangeGrapesFilter} activeGrapes={filterGrapes} />
        }

        {showBanner && <><br /> <SixPackCasesAlert /></>}

        {carouselContent}
      </div>
    </div>
  )
}


PublicHomeCasesCarousel.propTypes = {
  cases: PropTypes.array,
  title: PropTypes.string,
  onPurchase: PropTypes.func,
  onViewCaseContent: PropTypes.func,
  showBanner: PropTypes.bool
}

PublicHomeCasesCarousel.defaultProps = {
  title: `Explore Our Curated Collections.`
}

export default memo(PublicHomeCasesCarousel)