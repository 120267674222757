import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useActions, useGeoLocation, useRequests } from '../../hooks';

import logo from '../../assets/icons/logo-h-green-3x.svg';
import Select from './Select';
import Contact from '../../classes/Contact';
import { useDispatch } from 'react-redux';
// import { GoogleAuth } from '..';


const RegisterForm = ({ onSubmit, onClick, changeUserData }) => {
  const dispatch = useDispatch();
  const { authActions } = useActions();
  const { getInitialLocationData } = useGeoLocation();
  const { t } = useTranslation();
  const { get, post, errors, requestPending, clearErrors } = useRequests();
  const statesList = [ { value: 'INT', label: 'INT' }, ...Contact.getStatesList() ];
  const [geolocation, setGeolocation] = useState({
    location: null, user_agent: null
  });
  const [formData, changeFormData] = useState({
    firstName: '', lastName: '', email: '', state: statesList[0], city: ''
  });
  const [crdentials, changeCrdentials] = useState({ 
    fbId: undefined, googleId: undefined
  });

  const formDisabled = requestPending;

  let errorData = errors ? { ...errors.errors, message: errors.message } : {};

  const handleChange = e => {
    e.preventDefault();
    clearErrors();

    const { name, value } = e.target;

    changeFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async event => {
    event.preventDefault();

    const { user_agent } = geolocation;
    const { state: { label: state }, city, ...data } = formData

    const body = { ...data, location: { state, city } };

    if (user_agent?.device) {
      body.device = { ...user_agent.device }
    }

    if (!formDisabled) {

      // context.user.email = formData.email;
      changeUserData && dispatch(authActions.changeUserdata({ email: formData.email }))
      const response = await post({ url: '/api/users/wait-list', body });

      if (response && response.data.success) {
        onSubmit();
      }
    }
  }

  const handleSignUpWithGoogle = async token => {
    const response = await get({ url: `/api/users/register/google?g=${token}` })

    if (response?.data?.success) {
      onSubmit();
    }

  }
  

  const handleChangeState = data => {
    changeFormData(prev => ({
      ...prev,
      state: data
    }))
  }

  const getCrdentials = useCallback(async () => {
    const response = await get({ url: `/api/content/auth-config` });

    if (response?.data?.success) {
      changeCrdentials(response.data.config);
    }
  }, [get])

  const getGeolocation = useCallback(async () => {
    const { location, user_agent } = await getInitialLocationData();

    setGeolocation({ location, user_agent })
  }, [get])

  useEffect(() => {
    getGeolocation();
    getCrdentials(); 
  }, []);

  useEffect(() => {
    const initialState = statesList.find(({ value }) => {

      return value.toLowerCase() === geolocation.location?.region?.name?.toLowerCase()
    })

    changeFormData(prev => ({ 
      ...prev, 
      state: initialState || statesList[0], 
      city: geolocation.location?.city || '' 
    }))
  }, [geolocation])

  const isInvalid = errorData?.email?.length || errorData?.sent?.length || errorData?.exist?.length;


  return (
    <>
      <form className="register-form">
        <div className="form-logo">
          <img src={logo} alt="vineify" />
          
        </div>

        {/* <div className="form__header">
          <h3 className="form__title">{t('WAIT_LIST_JOIN_FORM_TITLE')}</h3>
          <p className="form__subtitle">{t('WAIT_LIST_JOIN_FORM_SUBTITLE')}</p>
        </div> */}
        <div className="form-header">
          {/* <h3 className="form-title">{t('WAIT_LIST_JOIN_FORM_TITLE')}</h3> */}
          <h3 className="form-title">Great Wine Takes Time</h3>

          <p className="form-subtitle no-margin">JOIN THE LIST</p>
          {/* <p className="form-subtitle no-margin">{t('WAIT_LIST_JOIN_FORM_SUBTITLE')}</p> */}
        </div>

        <div className={`form-control${errorData?.firstName ? ' invalid' : ''}`}>
          {/* <label className="text-left" htmlFor="firstname">{t('WAIT_LIST_JOIN_FORM_FIRST_NAME')}</label> */}
          <label className="text-left" htmlFor="firstname">{t('WAIT_LIST_JOIN_FORM_FIRST_NAME')}</label>

          <input
            name="firstName" type="text"
            // placeholder={t('WAIT_LIST_JOIN_FORM_FIRST_NAME')}
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />

          { errorData?.firstName && <p className="error-msg">{errors.message}</p> }
        </div>

        <div className={`form-control${errorData?.lastName ? ' invalid' : ''}`}>
          <label className="text-left" htmlFor="lastname">{t('WAIT_LIST_JOIN_FORM_LAST_NAME')}</label>

          <input
            name="lastName" type="text"
            placeholder={t('WAIT_LIST_JOIN_FORM_LAST_NAME')}
            value={formData.lastName}
            onChange={handleChange}
          />

          { errorData?.lastName && <p className="error-msg">{errors.message}</p> }
        </div> 

        <div className={`form-control${isInvalid ? ' invalid' : ''}`}>
          <label className="text-left" htmlFor="email">{t('WAIT_LIST_JOIN_FORM_EMAIL')}</label>

          <input
            name="email" type="text"
            // placeholder={t('WAIT_LIST_JOIN_FORM_EMAIL')}
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
          />
          { errorData?.email && <p className="error-msg">{errors.message}</p> }
          { errorData.sent && <p className="error-msg">{errors.message}</p> }
          {
            errorData.exist &&
            <p className="error-msg">{errorData.message} <Link to="/login">{t('WAIT_LIST_JOIN_FORM_ERROR_LINK')}</Link></p>
          }
          { errorData.sent && <p className="error-msg">{errors.message}</p> }
          { errorData.requested && <p className="error-msg">{errors.message}</p> }
        </div>

        <div className={`form-section-title label${ errorData?.location ? ' invalid' : '' }`}
        >Delivery Location&nbsp;<span className="text-gray">(required)</span></div>

        <div className="form-section">

          <div className={`form-section__col form-control${errorData?.location ? ' invalid' : ''}`}>
            <input type="text"
              placeholder="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>

          <div className="form-section__col form-control">
            <Select
              options={statesList}
              menuPosition="top"
              placeholder="State"
              value={formData.state}
              onChange={handleChangeState}
            />
          </div>
        </div>
          { 
            errorData?.location &&
            <div className="form-control invalid no-margin" style={{ transform: 'translateY(-24px)' }}><p className="error-msg no-margin">{errors.message}</p></div> 
          }

        <div className="button-container button_full-size">
          {/* <div className="button-container button-container--column button_full-size"> */}
          <button disabled={formDisabled} className="button button_green" onClick={handleSubmit}
          >{t('WAIT_LIST_JOIN_FORM_BUTTON')}</button>


          {/* <FacebookAuth onResponse={handleFacebookResponse} appId={crdentials.fbId} /> */}

          {/* "remember to not push this or the google login to prod. Can you hide it now please while we work on content?" - trello, New landing page design card (11 March 2022) */}
          {/* TODO: uncomment later */}
          {/* <div className="submit-divider" />
          <GoogleAuth onSuccess={handleSignUpWithGoogle} googleId={crdentials.googleId} /> */}
        </div>

        {/* {
          errorData.exist &&
          <div className="error-msg-block">
            <p>{errors.message} <Link to="/login">{t('WAIT_LIST_JOIN_FORM_ERROR_LINK')}</Link> {t('WAIT_LIST_JOIN_FORM_ERROR_TEXT')}</p>
          </div>
        } */}
        {/* <p className="form-link text-center">
          {t('WAIT_LIST_JOIN_FORM_HAS_CODE_TEXT')} <button
            style={{ padding: 0 }}
            className="button-link"
            onClick={onClick}
          >{t('WAIT_LIST_JOIN_FORM_HAS_CODE_LINK')}</button>
        </p> */}
      </form>
    </>
  )
}


RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  onClick: PropTypes.func
}

RegisterForm.defaultProps = {
  onSubmit: () => { },
  onClick: () => { },
  changeUserData: true
}

export default RegisterForm;
