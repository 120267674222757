import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';
import Select from './Select';
import { toast } from 'react-toastify';
import ImageUploadFormControl from './ImageUploadFormControl';
import { useHelpers, useRequests } from '../../hooks';
import { GenerateAiDescription } from '..'
import { useHistory } from 'react-router-dom';

import './style.scss'


const ProducerDataForm = ({ id, name, description, region, regionId, image, summary }) => {
  const { post, get, requestPending } = useRequests();
  const { convertDataToSelectOptions } = useHelpers();
  const history = useHistory();
  const [regions, changeRegions] = useState([]);
  const initialData = {
    regionId: region?.id || 0,
    region: region ? { value: region.id, name: region.name } : { value: 0, label: 'Not Specified' },
    name: name || '',
    description: description || '',
    summary: summary || '',
    image: { file: null, data_url: image || '' }
  };

  const [formData, changeFormData] = useState(initialData);

  const regionsOptions = useMemo(() => {
    const regionsRecords = convertDataToSelectOptions(regions);

    return [
      { value: 0, label: 'Not Specified' },
      ...regionsRecords
    ]
  }, [regions]);

  const fetchRegions = async () => {
    const response = await get({ url: `/api/content/regions-list` });

    if (response?.data?.success) {
      changeRegions(response.data.regions)
    }
  }

  useEffect(() => { fetchRegions(); }, []);

  const handleImageLoaded = image => {
    changeFormData({ ...formData, image });
  }

  const handleChangeFormField = e => {
    e.preventDefault();

    const { name, value } = e.currentTarget;

    changeFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleUseGeneratedDescription = description => {
    description?.length && changeFormData(prev => ({ ...prev, description }))
  }

  const handleChangeRegion = data => {
    // const regionData = regions.find(({ id }) => id === data.value);

    changeFormData(prev => ({
      ...prev,
      regionId: data.value,
      region: data,
    }))
  }

  const handleSubmitChanges = async e => {
    e.preventDefault();
    
    const { image, ...producerData } = formData;
    if (!producerData.regionId) return toast.warning(`Please select region`)
    const data = new FormData();

    for (const key of Object.keys(producerData)) {
      data.append(key, producerData[key])
    }

    if (image?.file) {
      data.append('files', image.file)
    } else {
      data.append('image', image?.data_url || '')
    }

    if (initialData?.regionId && initialData.regionId !== producerData.regionId) {
      data.append('initialRegion', initialData.regionId)
    }

    const response = await post({
      url: `/api/producers${id ? `/${id}` : ``}`,
      body: data
    })

    if (response?.data?.success) {
      const successMsg = id
        ? `The data was successfully updated`
        : `New producer ${name} was successfully added`;

      toast.success(successMsg);
      history.push('/admin/producers')
    }
  }

  const promt = formData.name.length > 0 ? `Write an article about the wine producer ${formData.name}. Include where it is located and what kind of wines it makes.` : ``


  return <form>
    <div className="form-section">
      <div className="form-control w-100">
        <ImageUploading multiple={false}>
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
          }) => <ImageUploadFormControl
              imageList={[formData.image]}
              onImageLoaded={handleImageLoaded}
              onImageUpdate={onImageUpdate}
              onImageUpload={onImageUpload}
            />}
        </ImageUploading>
      </div>

      <div className="form-column flex-column">
        <div className="form-control" style={{ width: '100%', marginLeft: 0 }}>
          <label htmlFor="name" style={{ textAlign: 'left' }}>Name</label>

          <input
            name="name"
            value={formData.name}
            onChange={handleChangeFormField}
          />
        </div>

        <div className="form-control" style={{ width: '100%', marginLeft: 0 }}>
          <label htmlFor="regionId" style={{ textAlign: 'left' }}>Select Region</label>

          <Select
            options={regionsOptions}
            isSearchable={true}
            value={regionsOptions.find(({ value }) => value === formData.regionId)}
            onChange={handleChangeRegion}
          />
        </div>
      </div>
    </div>

    <div className="form-control">
      <label htmlFor="summary">Summary</label>

      <textarea
        name="summary"
        rows="3"
        value={formData.summary}
        onChange={handleChangeFormField}
      />
    </div>

    <div className="form-control">
      <label htmlFor="profession">Description</label>

      <textarea
        name="description"
        rows="6"
        value={formData.description}
        onChange={handleChangeFormField}
      />

      <GenerateAiDescription
        onSubmit={handleUseGeneratedDescription}
        initialPromt={ promt }
        disabled={ !promt }
      />
    </div>

    <div className="form-control">
      <button
        className="button"
        style={{ margin: 'auto' }}
        disabled={requestPending}
        onClick={handleSubmitChanges}
      >{id ? 'Submit Changes' : 'Add New Producer'}</button>
    </div>
  </form>;
};


ProducerDataForm.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: PropTypes.string,
};

ProducerDataForm.defaultProps = {
  name: '',
  description: '',
}

export default ProducerDataForm;
