import { configureStore } from '@reduxjs/toolkit';
import toastMiddleware from './middlewares/toastMiddleware'

import reducer from './rootReducer'

console.log('HOST: ', document.location.host)

const store = configureStore({ 
  reducer,
  devTools: !document.location.host.includes('vineify.com'),
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(toastMiddleware),
})

export default store;
