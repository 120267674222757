import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useActions, useRequests } from '../../hooks';
import { CaseSliderCard } from '..';

import './style.scss';
import Case from '../../classes/Case';
import CardCarousel from './CardCarousel';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';


const CaseCardsCarousel = ({ cases, onChooseCase, currentCase, showCases, dots }) => {
  const dispatch = useDispatch()
  const { cartActions } = useActions()
  const { post, requestPending } = useRequests();
  const navSlider = useRef();

  const isSMallCard = showCases > 2;
  const isNarrow = window.matchMedia('(max-width: 520px').matches || showCases > 3


  const navSliderConfig = { 
    itemsToShow: showCases,
    slidesToScroll: 1,
    speed: 500,
    centerPadding: '16px',
    infinite: true,
    showArrows: false,
    dots: dots
  }


  // if (requestPending) return <LoadingScreen />

  const handleCasePurchase = async caseDetails => {

    const { id, caseDetailsId, stock, cost } = caseDetails;
    // const cost = formCasePrice(caseDetails.getPrice(currency, charge, false));

    if (stock === 0) return;

    if (cost <= 0) {
      await post({
        url: '/api/content/error-report',
        body: {
          subject: `Error: cost $0 in case ID${id}`,
          error: `Error: cost $0 in case ID${id}`
        }
      })

      return toast.error('Something went wrong');
    }

    dispatch(cartActions.requestAddCaseToCart({
      caseDetails, caseId: id, caseDetailsId, cost, redirectToCart: true
    }))
  };

  const casesSmallCards = cases.map(
    (caseData, index) => {
      const caseProps = Case.create(caseData);

      // return (
      //   <NewCaseCard
      //     key={keyGen()}
      //     isSmall={ isSMallCard }
      //     isNarrow={ isNarrow }
      //     caseData={caseProps}
      //     descriptionSize={4}
      //     caseCardClassname={ cases?.length === 1 ? 'w-100' : '' }
      //     onChooseCase={onChooseCase}
      //   />
      // )

      return (
        <CaseSliderCard
          { ...caseProps }
          onViewCaseContent={ () => onChooseCase(caseProps.id) }
          onPurchase={handleCasePurchase}
          isSoldOut={ caseProps.stock === 0 }
          processingRequest={ requestPending }
        />
      )
    }
  );
  

  return (
    <CardCarousel
      ref={navSlider}
      name="Cases Cards"
      // dots={ window.matchMedia('(max-width: 1024px)').matches }
      dots
      slideClassName={ window.matchMedia('(max-width: 1024px)').matches ? 'card card_case' : '' }
      infinite={ false }
      centeredSlides={ casesSmallCards?.length === 1 }
      carouselClassName="w-100"
      withNavigation
      dynamicDots
      cards={ casesSmallCards }
      config={ navSliderConfig }
      // config={ config }
    />
  )
}


CaseCardsCarousel.propTypes = {
  currentCase: PropTypes.number,
  cases: PropTypes.arrayOf(PropTypes.object),
  onChooseCase: PropTypes.func,
  showCases: PropTypes.number
}

CaseCardsCarousel.defaultProps = {
  cases: [],
  currentCase: 0,
  onChooseCase: () => { },
  showCases: 4
}


export default CaseCardsCarousel;
