import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../assets/images/case-box.jpg';

import '../style.scss'
import { useDispatch } from 'react-redux';
import { useActions } from '../../../hooks';


const QuizInit = ({ onCancel, onApply }) => {
  const dispatch = useDispatch()
  const { authActions } = useActions()
  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.removeItem('_cna');
    return () => {
      // ctx.user.quiz = true;
      dispatch(authActions.changeUserdata({ quizIsCompleted: true }))
    }
    // eslint-disable-next-line
  }, []);


  return (
    <div className="container">
      <div className="quiz-init">
        <img src={image} alt="" />

        <p className="quiz-init__text">{t('QUIZ_INIT_TEXT')}</p>

        <button className="button" onClick={onApply}>{t('QUIZ_INIT_BUTTON_START')}</button>

        <button className="button-link" onClick={onCancel}>{t('QUIZ_INIT_BUTTON_CANCEL')}</button>
      </div>
    </div>
  )
}

export default QuizInit
