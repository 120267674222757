import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';

import image from '../../assets/images/no-image.jpg';


const ImageUploadFormControl = ({
  rounded,
  imageList,
  onImageLoaded,
  buttonLabel,
  autogeneratedContent,
  onRequestGenerateImage,
  placeHolder,
  showGeneratedContent
}) => {
  const [images, setImages] = useState(imageList);
  const defaultLabel = images.length > 0 ? 'Update Image' : 'Upload Image';

  const handleImageUpload = file => {
    onImageLoaded(file[0], 'manual')
    setImages([...file])
  }

  const handleImageLoading = (e, imageUpload) => {
    e.preventDefault();

    imageUpload();
  }

  const handleDeleteImage = () => {
    onImageLoaded({ file: undefined, data_url: undefined }, 'manual')
  }

  const handleGenerateImage = () => onRequestGenerateImage()

  useEffect(() => { setImages(imageList) }, [imageList]);

  return <ImageUploading
    onChange={handleImageUpload}
    maxNumber={1}
    dataURLKey="data_url"
  >
    {({
      imageList,
      onImageUpload,
      isDragging,
      dragProps,
    }) => (

      <div className="upload__image-wrapper">
        <div className={`img-container${ rounded ? ` rounded` : `` }`}>
          {
            images?.[0]?.data_url &&
            <img src={images.length && images[0].data_url ? images[0].data_url : image} alt="uploaded file" />
          }
          {
            !images[0].data_url && placeHolder &&
            <>{placeHolder}</>
          }

          <div className="auto-gen-content">{autogeneratedContent}</div>
        </div>

        <div className="upload__control horizontal">
          <button
            style={isDragging ? { color: 'red' } : undefined}
            className="button button_xs"
            onClick={e => handleImageLoading(e, onImageUpload)}
            {...dragProps}
          >
            {/* { console.log('btn label: ', buttonLabel) && images.length > 0 ? 'Update Image' : 'Upload Image' } */}
            {buttonLabel || defaultLabel}
          </button>

          <button
            type="button"
            className="button button_red button_xs"
            onClick={handleDeleteImage}
          >Delete</button>
        </div>
      </div>
    )}
  </ImageUploading>
}

ImageUploadFormControl.propTypes = {
  placeHolder: PropTypes.node,
  rounded: PropTypes.bool
}

ImageUploadFormControl.defaultProps = {
  rounded: false
}

export default ImageUploadFormControl
