import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ShortDescription } from '..';
import { useHelpers } from '../../hooks';

import locationIcon from '../../assets/icons/map-pin-line.svg';

import './style.scss';
import { Link } from 'react-router-dom';


const WineCard = ({ name, quantity, region, country, image, description, grapeName, tastes, producer, vintage, grapeCategory, isClickable, slug, showWineLink, showLocation }) => {
  const { getWineName } = useHelpers();
  const tastesList = tastes?.filter(t => !!t).map(taste => <li key={taste}>{taste}</li>);
  const wineLocation = country?.toLowerCase() === region?.toLowerCase()
    ? ` ${region}` : ` ${region}, ${country}`

  return (
    <div className="card wine_card">
      <div className="card-header">
        <img src={image?.length ? image : 'https://vineify-dev-files.s3.amazonaws.com/images/wines/vineify_logo_bg.png'} alt={name} />

        {quantity && <div className="badge">{'x' + quantity}</div>}

        {
          !isClickable &&
          <p className="wine__name">{getWineName({ vintage, producer, name })}</p>
        }

        {
          isClickable &&
          <Link to={`/wines/${slug}`} className="wine__name">{getWineName({ vintage, producer, name })}</Link>
        }
      </div>
      <div className="card__body">
        {
          showLocation && <p className="wine__region">
            <img src={locationIcon} alt="location" />

            {wineLocation}
          </p>
        }

        <div className="wine-space-between">
          <span className={`wine-grape grape_${grapeCategory} no-margin`}>
            {grapeName?.trim()}
          </span>
        </div>

        <div className="wine-additional-data">
          <ShortDescription lines={4} expandable={true}>{description?.trim()}</ShortDescription>

          <ul className="wine-tastes">{tastesList}</ul>
        </div>
      </div>

    </div>
  )
}

WineCard.propTypes = {
  name: PropTypes.string,
  quantity: PropTypes.number,
  image: PropTypes.string,
  description: PropTypes.string,
  grapeName: PropTypes.string,
  vintage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tastes: PropTypes.arrayOf(PropTypes.string)
}

WineCard.defaultProps = {
  image: 'https://vineify-dev-files.s3.amazonaws.com/images/wines/vineify_logo_bg.png',
  showLocation: true
}

export default memo(WineCard);
