import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import { Link, useHistory } from 'react-router-dom';
import { useActions, useHelpers } from '../../hooks';
import { Icon } from '..'

import './style.scss';
import { useDispatch } from 'react-redux';


const MainMenu = ({ role, isAuthenticated }) => {
  const dispatch = useDispatch();
  const { authActions } = useActions();
  const history = useHistory();
  const { t } = useTranslation();
  const { capitalize } = useHelpers();

  const mainMenuLinks = [];

  if (!isAuthenticated) {
    // mainMenuLinks.push({ label: 'Create Account', value: 'create-account' });
    // mainMenuLinks.push({ label: 'Sign in', value: 'login' });
    return (
      <ul className="header-links with-padding">
        <li className="link"><Link className="button login" to="/login">Sign In</Link></li>

        <li className="link"><Link className="button account" to="/create-account">Create an Account</Link></li>
      </ul>
    )
  }

  mainMenuLinks.push({ label: capitalize(t('MY_ACCOUNT')), value: 'account' })
  mainMenuLinks.push({ label: t('WINES_PREFERENCES_LINK'), value: 'wine-preferences' })

  if (role === 'admin') {
    const adminLink = { label: 'Admin panel', value: 'admin' };

    mainMenuLinks.push(adminLink);
  }

  if (role === 'warehouse') {
    const warehouseLink = { label: 'Admin panel', value: 'inventory/orders' };

    mainMenuLinks.push(warehouseLink);
  }

  if (role === 'curator') {
    const curatorLinks = { label: 'Admin panel', value: 'curator' };

    mainMenuLinks.push(curatorLinks)
  }

  if (role === 'retailer') {
    const retailerLinks = { label: 'Admin panel', value: 'retailer/integration' };

    mainMenuLinks.push(retailerLinks);
  }

  // mainMenuLinks.push({ label: 'Sign Out', value: 'logout' })
  mainMenuLinks.push({ label: 'Sign Out', value: 'logout' })


  const handleChooseLink = val => {
    const { value } = val;

    if (value !== 'logout') {
      return history.push(`/${value}`)
    } else {
      // logout(history);
      dispatch(authActions.logout('/login'))
    };


    return { label: capitalize(t('MENU')), value: '' }
  }

  return (
    <Dropdown
      className="main-menu"
      data-cy="main-menu"
      controlClassName="main-menu__control"
      menuClassName="main-menu__menu"
      arrowClassName="main-menu__arrow"
      options={mainMenuLinks}
      value={{ label: (<Icon icon="user" color="white" size={20} />), value: '' }}
      onChange={handleChooseLink}
      placeholder="Menu" //change to user icon
    />
  )
}


MainMenu.propTypes = {
  role: PropTypes.string
}

MainMenu.defaultProps = {
  role: ''
}


export default MainMenu
