import React, { useRef } from 'react';
import ReactSelect from 'react-select';
import Icon from '../common/Icon';


const MultiselectCheckboxes = (props) => {
  const selectRef = useRef()
  const valueContainerRef = useRef()
  // const [isOpen, setIsOpen] = useState(false);

  const classes = ['select-wrapper', 'select-tree']
  props.wrapperClass && classes.push(props.wrapperClass)

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '16px 24px',
      lineHeight: 1,
      zIndex: 120,
      color: '#333231',
      backgroundColor: 'white !important',
      borderBottom: '1px solid #C7C2BA',
      cursor: 'pointer',
      ...props.optionStyles
    }),
    container: (provided, state) => ({
      ...provided,
      ...props.containerProps
    }),
    indicatorSeparator: () => ({}),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      borderWidth: '2px',
      // borderColor: 'rgba(209, 173, 130, 0.25) !important',
      borderColor: state.isDisabled ? '#FAF9F5 !important' : '#C7C2BA !important',
      padding: '0 16px',
      color: state.isDisabled ? '#ABA9A7' : '#403D3B',
      background: state.isDisabled ? '#FAF9F5' : 'white',
      fontSize: '14px',
      ...props.style
    }),
    menu: (provided, state) => ({
      ...provided,
      background: 'white',
      borderRadius: '8px',
      border: '2px solid #C7C2BA',
      ...props.menuStyle
    }),
    menuList: (provided, state) => ({
      ...provided,
      ...props.menuListStyle
    }),
  };

  const MultiValue = ({ data }) => {

    return data.label
      ? (
        <span className="multiselect-value">{data.label}</span>
      ) : undefined
  }

  const ValueContainer = ({ children }) => (
    <div className="value-container" ref={valueContainerRef}>{children}</div>
  )


  // useEffect(() => {
  //   const handleOutsideClick = (e) => {

  //     const isMenuTarget = selectRef.current && !selectRef.current.contains(e.target)

  //     if (isMenuTarget) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleOutsideClick);

  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // }, []);

  // const handleMenuOpen = () => {
  //   setIsOpen(true);
  // };

  // const handleMenuClose = () => {
  //   setIsOpen(false);
  // };



  return (
    <div ref={selectRef} className={classes.join(' ')}>
      <ReactSelect
        isSearchable={false}
        isDisabled={props.disabled}
        styles={customStyles}
        menuPlacement={props.menuPosition || "auto"}
        {...props}
        closeMenuOnSelect
        isClearable={false}
        closeMenuOnScroll={false}
        isMulti
        hideSelectedOptions={false}
        getOptionLabel={optionData => {

          return (<p className="select-checkbox-option">
            <Icon
              icon={optionData.isSelected ? 'checkbox-checked' : 'checkbox-unchecked'}
              color="vineify-green"
              size={20}
            />

            {optionData.label}
          </p>)
        }}
        components={{ MultiValue, ValueContainer }}
        // menuIsOpen={isOpen}
        // onMenuOpen={handleMenuOpen}
        // onMenuClose={handleMenuClose}
      />
      {/* <Icon icon="checkbox-checked" color="vineify-green" />
      <Icon icon="checkbox-unchecked" color="vineify-green" /> */}
    </div>
  );
}


export default MultiselectCheckboxes;
