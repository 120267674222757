import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';
import { toast } from 'react-toastify';
import { useRequests } from '../../hooks';
import { useHistory } from 'react-router';

import ImageUploadFormControl from './ImageUploadFormControl';
import { Spinner, Select, TabbedTextarea, TabbedInput } from '..';

import './style.scss';


const RegionDataForm = ({
  id, name, country, image, mapImage, introImage, descriptionsList, introTitleLangList, introTextLangList, countriesList, subregionIntroTextLangList, parentRegionsList, curatorsList, parentRegion, active, names: namesList, curator, mobileImage, caseCardImage, mobileStripHeaderImage, stripHeaderImage
}) => {
  const { post, requestPending } = useRequests();
  const history = useHistory();
  const [formData, changeFormData] = useState({
    name: name || '',
    id,
    country: country?.id || countriesList[0].value,
    curator: curator?.id || curatorsList[0].value,
    descriptions: [
      { tabName: 'en', text: descriptionsList?.en || '' },
      { tabName: 'it', text: descriptionsList?.it || '' }
    ],
    introTitle: [
      { tabName: 'en', text: introTitleLangList?.en || '' },
      { tabName: 'it', text: introTitleLangList?.it || '' }
    ],
    introText: [
      { tabName: 'en', text: introTextLangList?.en || '' },
      { tabName: 'it', text: introTextLangList?.it || '' }
    ],
    subregionIntroText: [
      { tabName: 'en', text: subregionIntroTextLangList?.en || '' },
      { tabName: 'it', text: subregionIntroTextLangList?.it || '' }
    ],
    names: [
      { tabName: 'en', text: namesList?.en || '' },
      { tabName: 'it', text: namesList?.it || '' }
    ],
    active: active >= 0 ? active : 1,
    image: { file: null, data_url: image },
    mapImage: { file: null, data_url: mapImage },
    introImage: { file: null, data_url: introImage },
    mobileImage: { file: null, data_url: mobileImage },
    mobileStripHeaderImage: { file: null, data_url: mobileStripHeaderImage },
    stripHeaderImage: { file: null, data_url: stripHeaderImage },
    caseCardImage: { file: null, data_url: caseCardImage },
    parentRegion: +parentRegion?.id || 0
  });

  const isNewRegion = !id;

  const filteredRegionsOptions = useMemo(() => parentRegionsList.filter(regionData => {
    const selectedCountry = countriesList?.find(({ value }) => value === formData.country)?.label;

    return regionData.value === 0 
      || regionData?.country === selectedCountry
  }), [formData.country]);

  const statusOptions = [
    { value: 0, label: 'inactive' },
    { value: 1, label: 'active' },
  ];

  const handleImageLoaded = field => image => {
    changeFormData({ ...formData, [field]: image });
  }

  const handleContentChange = (field, data) => changeFormData(prev => ({ ...prev, [field]: data }));

  const handleChangeSelect = (field, value) => {
    changeFormData(
      prev => ({ ...prev, [field]: value })
    )
  }


  const handleSubmit = async e => {
    e.preventDefault();

    const { 
      image, introImage, mapImage, caseCardImage, stripHeaderImage, mobileImage, mobileStripHeaderImage,
      descriptions, introText, introTitle, subregionIntroText, parentRegion, names, 
      ...regionData 
    } = formData;
    const imageName = `${formData.name.split(' ').join('_')}`;

    const convertDescriptionsToReqData = descriprions => descriprions.reduce(
      (result, { tabName, text }) => {
        result = { ...result, [tabName]: text };

        return result;
      }, {}
    );


    const regionDataForm = {
      regionData: JSON.stringify({
        ...regionData,
        parentRegion: +parentRegion > 0 ? +parentRegion : null,
        name: convertDescriptionsToReqData(names),
        description: convertDescriptionsToReqData(descriptions),
        introText: convertDescriptionsToReqData(introText),
        introTitle: convertDescriptionsToReqData(introTitle),
        subregionIntro: convertDescriptionsToReqData(subregionIntroText),
      }),
      image: image?.file || image?.data_url || '',
      mapImage: mapImage?.file || mapImage?.data_url || '',
      introImage: introImage?.file || introImage?.data_url || '',
      caseCardImage: caseCardImage?.file || caseCardImage?.data_url || '',
      stripHeaderImage: stripHeaderImage?.file || stripHeaderImage?.data_url || '',
      mobileImage: mobileImage?.file || mobileImage?.data_url || '',
      mobileStripHeaderImage: mobileStripHeaderImage?.file || mobileStripHeaderImage?.data_url || '',
      imageName
    }

    const form = new FormData();

    for (const field in regionDataForm) {
      form.append(field, regionDataForm[field]);
    }


    const response = isNewRegion ?
      await post({
        url: `/api/content/regions`,
        body: form
      })
      :
      await post({
        url: `/api/content/regions/${id}/update`,
        body: form
      });


    if (response?.data?.success) {
      toast.success(`Region ${formData.name} was successfuly ${!isNewRegion ? 'updated' : 'created'}.`);

      history.push(`/admin/regions`);

    } else {

      toast.error(response?.data?.message || 'Fail to submit data');

    }
  }


  return (
    <>
      <form className="wine-data-form" onSubmit={e => { e.preventDefault() }}>
        <div className="form-section" style={{ gap: '16px' }}>
          {/* <div className="form-control w-100" style={{ maxWidth: 'none' }}>
            <label htmlFor="name">Region Name</label>
            <input
              type="text" name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div> */}

          <TabbedInput
            style={{ maxWidth: 'none', flexGrow: 1 }}
            onChange={handleContentChange}
            tabs={formData.names}
            label="Region Name"
            name="names"
          /> 

          <div className="form-control" style={{ maxWidth: 'none', width: 'calc(33.3333% - 16px)' }}>
            <label htmlFor="active">Status</label>

            <Select
              name="active"
              options={statusOptions}
              value={statusOptions.find(({ value }) => value === formData.active)}
              onChange={val => handleChangeSelect('active', val.value)}
            />
          </div>
        </div>

        <div className="form-section" style={{ gap: '16px' }}>
          <div className="form-control">
            <label htmlFor="country">Country</label>

            <Select
              name="country"
              options={countriesList}
              value={countriesList.find(({ value }) => value === formData.country)}
              onChange={val => handleChangeSelect('country', val.value)}
            />
          </div>

          <div className="form-control">
            <label htmlFor="parentRegion">Parent Region</label>

            <Select
              name="parentRegion"
              options={filteredRegionsOptions}
              value={parentRegionsList.find(({ value }) => value === formData.parentRegion)}
              onChange={val => handleChangeSelect('parentRegion', val.value)}
            />
          </div>

          <div className="form-control">
            <label htmlFor="curator">Region Curator</label>

            <Select
              name="curator"
              options={curatorsList}
              value={curatorsList.find(({ value }) => value === formData.curator)}
              onChange={val => handleChangeSelect('curator', val.value)}
            />
          </div>
        </div>

        <div className="form-section">
          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Region Main Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    imageList={[formData.image]}
                    onImageLoaded={handleImageLoaded('image')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>

          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Region Map Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    imageList={[formData.mapImage]}
                    onImageLoaded={handleImageLoaded('mapImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>

          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Region Intro Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate
                }) => <ImageUploadFormControl
                    imageList={[formData.introImage]}
                    onImageLoaded={handleImageLoaded('introImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>
        </div>

        {/* NEW IMAGES */}
        <div className="form-section">
          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Strip Header Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    imageList={[formData.stripHeaderImage]}
                    onImageLoaded={handleImageLoaded('stripHeaderImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>

          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Case Card Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate
                }) => <ImageUploadFormControl
                    imageList={[formData.caseCardImage]}
                    onImageLoaded={handleImageLoaded('caseCardImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Mobile Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    imageList={[formData.mobileImage]}
                    onImageLoaded={handleImageLoaded('mobileImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>

          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <label htmlFor="image">Mobile Strip Header Image</label>

              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate
                }) => <ImageUploadFormControl
                    imageList={[formData.mobileStripHeaderImage]}
                    onImageLoaded={handleImageLoaded('mobileStripHeaderImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>
            </div>
          </div>
        </div>

        {/* NEW IMAGES END */}

        <div className="form-section-title">Region Intro Text</div>
        <TabbedTextarea
          onChange={handleContentChange}
          tabs={formData.introText}
          label="Description language"
          name="introText"
        />
        <br />

        <div className="form-section-title">Region Description</div>
        <TabbedTextarea
          onChange={handleContentChange}
          tabs={formData.descriptions}
          label="Description language"
          name="descriptions"
        />
        <br />

        {/* <div className="form-section-title">Region Intro Title</div>
        <TabbedTextarea
          onChange={handleContentChange}
          tabs={formData.introTitle}
          label="Description language"
          name="introTitle"
        />
        <br /> */}

        <div className="form-section-title">Region Subregion Intro Text</div>
        <TabbedTextarea
          onChange={handleContentChange}
          tabs={formData.subregionIntroText}
          label="Description language"
          name="subregionIntroText"
        />
        <br />

        <div className="form-control horizontal control-button" style={{ justifyContent: "flex-end" }}>
          <button
            className="button"
            disabled={requestPending}
            onClick={handleSubmit}
          >{isNewRegion ? 'Create New Region' : 'Submit Changes'} {requestPending && <span className="loading"><Spinner /></span>}</button>
        </div>
      </form>
    </>
  )
}


RegionDataForm.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  tastes: PropTypes.string,
  tasteOne: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteTwo: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteThree: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  onSubmit: PropTypes.func
}

RegionDataForm.propTypes = {
}

export default RegionDataForm;
