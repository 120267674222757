import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useRequests } from '../../hooks'; 
import { Spinner } from '..';

const NewImportWinesButton = ({ onSuccess, onFail, label }) => {
  const { post } = useRequests();
  const [file, setFile] = useState();
  const [importingData, setImportingData] = useState(false);
  const fileRef = useRef();

  const handleBrowseFile = e => {
    e.preventDefault();

    fileRef.current.click();
  }

  const handleImportWines = async e => {
    setImportingData(true)

    const file = e.currentTarget.files[0];

    const body = new FormData();
    body.append('wines', file);

    const response = await post({ url: `/api/wines/import-wines`, body });

    response?.data?.success && onSuccess();

    setImportingData(false)
  }


  return (
    <div>
      <button
        className="button button_outline"
        disabled={ importingData }
        onClick={handleBrowseFile}
      >{label}{ 
        importingData && 
        <span className="button-float-content text-black"><i>Importing data</i> <Spinner size={ 20 } /></span> 
      }</button>

      {/* <small style={{ textAlign: 'center', display: 'inline-block', padding: '0 32px' }}>{warehouse ? `Warehouse: ${warehouse.label}` : `Select warehouse`}</small> */}

      <input ref={fileRef} type="file" onChange={handleImportWines} hidden />
    </div>
  )
}

NewImportWinesButton.propTypes = {}

export default NewImportWinesButton