import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useRequests } from '../../hooks'; 
import { VerificationAlert, PasswordInput } from '..';

import icon from '../../assets/icons/check-o.svg';


const ResetPasswordForm = ({ email: paramsEmail }) => {
  const { t } = useTranslation();
  const { post, requestPending, clearErrors, errors } = useRequests();
  const [formData, changeFormData] = useState({
    password: '',
    repassword: ''
  });
  const [passwordChanged, setPasswordChangeState] = useState(false);

  const handleChange = field => value => {

    clearErrors();
    changeFormData(prev => ({
      ...formData,
      [field]: value
    }));

  }

  if (errors?.errors?.password) {
    console.error(errors.errors.password);
  }

  const handleApplyChanges = async e => {
    e.preventDefault();

    const requestData = {
      url: '/api/users/passwords',
      body: {
        // email: ctx.user.email,
        email: paramsEmail,
        password: formData.password
      }
    };


    const response = await post(requestData);

    if (response && response.data.success) {
      setPasswordChangeState(true);
    } else {
      console.log('Response: ', response);
    }
  }

  if (passwordChanged) {
    return <VerificationAlert icon={icon} title={t('RESET_PASSWORD_SUCCESS_ALERT_TEXT')}>
      <Link className="text-underline" to="/login">{t('RESET_PASSWORD_SUCCESS_ALERT_LINK')}</Link>
    </VerificationAlert>
  }


  return (
    <form className="small-form">
      {
        formData.password !== formData.repassword &&
        <div className="error-msg-block up"><p>{t('RESET_PASSWORD_ERROR_PASS_NOT_MATCH')}</p></div>
      }
      {
        errors?.errors?.password &&
        <div className="error-msg-block up"><p>{errors.message}</p></div>
      }
      <div className="form-control">
        <label htmlFor="verification">{t('RESET_PASSWORD_LABEL_PASSWORD')}</label>
        {/* <input
                    name="password" type="password"
                    placeholder={ t('RESET_PASSWORD_LABEL_PASSWORD')?.toLowerCase() }
                    value={ formData.password }
                    onChange={ handleChange }
                /> */}
        <PasswordInput
          placeholder={t('RESET_PASSWORD_LABEL_PASSWORD')?.toLowerCase()}
          onChange={handleChange('password')}
        />
      </div>

      <div className={classNames('form-control', { 'invalid': formData.password !== formData.repassword })} >
        <label htmlFor="repassword">{t('RESET_PASSWORD_LABEL_REPASSWORD')}</label>
        {/* <input
                    name="repassword" type="password"
                    placeholder={ t('RESET_PASSWORD_LABEL_REPASSWORD')?.toLowerCase() }
                    value={ formData.repassword }
                    onChange={ handleChange }
                /> */}
        <PasswordInput
          name="repassword"
          placeholder={t('RESET_PASSWORD_LABEL_REPASSWORD')?.toLowerCase()}
          onChange={handleChange('repassword')}
        />
      </div>
      <div className="button-container button_full-size">
        <button
          className="button"
          onClick={handleApplyChanges}
          disabled={formData.password !== formData.repassword || requestPending}
        >{t('RESET_PASSWORD_BUTTON_APPLY')}</button>
      </div>
    </form>
  )
}


export default ResetPasswordForm;
