import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as keyGen } from 'uuid';

import './style.scss';


const TabbedTextarea = ({ name, label, placeHolder, rows, cols, onChange, tabs: tabsList, parentControl, limit, maxLimitMessage, children }) => {
  const [tabs, changeTabs] = useState(tabsList);
  const [activeTab, changeActiveTab] = useState(tabsList[0].tabName)


  const changeTextTab = e => {
    e.preventDefault();

    const { tab } = e.target.dataset;

    activeTab !== tab && changeActiveTab(tab);
  }

  const handleContentChange = e => {
    e.preventDefault();

    const { value: text, name: textareaName } = e.target;
    // if (limit && text.length > limit) return;

    const [, tabName] = textareaName.split('_');

    const newTabs = tabs.map(
      tab => {
        if (tab.tabName !== tabName) return tab;

        return { ...tab, text }
      }
    );

    !parentControl && changeTabs(newTabs);
    onChange(name, newTabs, activeTab);
  }


  const textareaTabs = tabs.map(
    ({ tabName: name }) => {
      return (
        <li key={keyGen()} className="tag">
          <button
            className={classNames('button-badge tag-button', { 'selected': name === activeTab })}
            data-tab={name}
            onClick={changeTextTab}
          >{name}</button>
        </li>
      )
    }
  );


  if (tabs?.length === 0) return null;

  const text = parentControl ?
    tabsList.find(
      ({ tabName }) => tabName === activeTab
    ).text
    :
    tabs.find(
      ({ tabName }) => tabName === activeTab
    ).text;


  return (
    <div className="form-control">

      <div style={{ justifyContent: 'flex-start !important', display: 'flex', alignItems: 'center' }}>
        <label htmlFor={name}>{label}</label>

        {
          tabsList?.length > 1 &&
          <ul className="form-control__tabs admin-tags-list">{textareaTabs}</ul>
        }

        {limit && <span className={classNames('textarea-limit', { 'max-length': text.length > limit })}>characters: {text.length}</span>}
      </div>

      <textarea
        name={`${name}_${activeTab}`}
        placeholder={placeHolder}
        cols={cols} rows={rows}
        value={text}
        onChange={handleContentChange}
      />

      {
        text.length > limit &&
        <span className="over-limit-message">{maxLimitMessage}</span>
      }

      {children}
    </div>
  )
}


TabbedTextarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.string
  })),
  onChange: PropTypes.func,
  parentControl: PropTypes.bool,
  limit: PropTypes.number
}

TabbedTextarea.defaultProps = {
  placeHolder: '',
  rows: 8,
  cols: 30,
  parentControl: false
}

export default React.memo(TabbedTextarea);
