import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import { toast } from 'react-toastify';
import { LoadingScreen, WaitListInvitationBar } from '../../components';
import { useActions, useRequests } from '../../hooks';

import QuizInitSection from '../quiz/components/QuizInit';

import PublicHomeAbout from './sections/PublicHomeAbout';
import PublicHomeCasesCarousel from './sections/PublicHomeCasesCarousel';
import PublicHomeCurators from './sections/PublicHomeCurators';
import PublicHomeHeader from './sections/PublicHomeHeader';
import PublicHomeRegions from './sections/PublicHomeRegions';
import PublicHomeSubscribeSection from './sections/PublicHomeSubscribeSection';
import PublicHomeWelcome from './sections/PublicHomeWelcome';

import './style.scss';


const PublicHomePage = () => {
  const { regionActions, casesActions, cartActions } = useActions();
  const { dataIsFetched } = useSelector(state => state.cases)
  const dispatch = useDispatch()
  const history = useHistory();
  const { post } = useRequests()
  const [state, changeState] = useState({
    dataIsFetched: false, regions: [], cases: [], 
    notificationIsShawn: true, 
    showQuiz: sessionStorage.getItem('_cna') > 0
  });

  const handleViewCaseContent = ({ slug }) => {
    history.push(`/cases/${ slug }`);
  }

  const handleCasePurchase = async caseDetails => {
    const { id, caseDetailsId, stock, cost } = caseDetails;

    if (stock === 0) return;

    if (cost <= 0) {
      await post({
        url: '/api/content/error-report',
        body: {
          subject: `Error: cost $0 in case ID${id}`,
          error: `Error: cost $0 in case ID${id}`
        }
      })

      return toast.error('Something went wrong');
    }
    

    dispatch(cartActions.requestAddCaseToCart({ caseDetails, caseId: id, caseDetailsId, cost, redirectToCart: true }))
  };

  const fetchAllData = async () => {
    dispatch(regionActions.requestSliderRegionsData())
    dispatch(casesActions.requestAllRecommendations())

    changeState(prev => ({
      ...prev, dataIsFetched: true
    }))
  }

  const handleShowHomePage = e => {
    e && e.preventDefault()

    sessionStorage.setItem('_cna', 0)

    changeState(prev => ({ ...prev, showQuiz: false }))
  }

  const hadleRedirectToWinesPreferences = e => {
    e && e.preventDefault()

    history.push('/wine-preferences')
  }

  useEffect(() => {
    fetchAllData()

    return () => {
      dispatch(casesActions.clearCasesStore())
    }
  }, []);

  const handleDismissAlert = () => {
    changeState(prev => ({ ...prev, notificationIsShawn: false }))
  }

  const cases = useSelector(state => state.cases.list)
  const regions = useSelector(state => state.regions.sliderRegions)
  

  if (!dataIsFetched) return <LoadingScreen />

  if (dataIsFetched && state.showQuiz) {
    return <QuizInitSection
      onApply={hadleRedirectToWinesPreferences}
      onCancel={handleShowHomePage}
    />
  }


  return (
    <div className="public-home">
      {
        state.notificationIsShawn && 
        <WaitListInvitationBar 
          onDismiss={handleDismissAlert} 
          headerClassName="public-home__header-alert"
        />
      }
      <Helmet>
        <title>Vineify - the best way to enjoy European wines</title>
      </Helmet>

      <PublicHomeHeader />

      <PublicHomeCasesCarousel 
        onPurchase={handleCasePurchase}
        onViewCaseContent={ handleViewCaseContent }
        cases={cases}
      />

      <PublicHomeRegions regions={regions} />

      <PublicHomeCurators />

      <PublicHomeAbout />

      <PublicHomeWelcome />

      <PublicHomeSubscribeSection />
    </div>
  )
}


export default PublicHomePage;
