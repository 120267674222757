import everydayImage from '../../assets/images/motivation-everyday.jpg'
import collectorImage from '../../assets/images/motivation-collector.jpg'
import favoriteImage from '../../assets/images/motivation-favorite.jpg'
import entertainImage from '../../assets/images/motivation-entertain.jpg'


export const motivations = [
  {
    name: 'collect',
    title: `Build Your Cellar`,
    intro: `Looking to add some truly special bottles to your cellar, look no further. These wines will shine in any collection, and offer an experience that is truly unforgettable.`,
    text: `Explore our hard-to-find collectible wines from top producers in Italy, Spain and France.`,
    link: {
      label: `Discover Cellar-Builder Collections`,
      path: `/collections/collect`
    },
    image: collectorImage,
    content: `There's something special about having a collection of carefully selected wines in your cellar. It's like having a treasure trove of liquid gems that you can enjoy with friends and family, or simply savor on your own. These carefully curated collections of wines will make great additions to any cellar. These wines have been selected for their exceptional quality, unique character, and the pleasure they offer to the discerning palate. Whether you are a seasoned collector or just starting to build your wine collection, these wines are sure to impress and delight.

    A wine cellar is more than just a storage space for wine bottles. It's a place to showcase and savor the fruits of the vine, to discover new flavors and aromas, and to share memorable moments with loved ones. That's why selecting the right wines to add to your cellar is so important. You want wines that not only taste great, but also offer something unique and special.
    
    The wines in these collections are exactly that - unique, special, and exceptional. These are not your average wines, but rather bottles that have been hand-selected by experts for their outstanding quality, character, and aging potential. These wines come from some of the most renowned and respected wineries in the world, and have been crafted with care and attention to detail.
    
    What sets these wines apart is their ability to offer a truly memorable sensory experience. From the rich and complex aromas to the layered and nuanced flavors, these wines will take your palate on a journey of discovery and delight. Whether you are a seasoned wine connoisseur or just starting to explore the world of wine, these bottles are sure to impress.
    
    But it's not just about the taste - these wines also have a story to tell. They are the product of a particular terroir, a specific vintage, and the expertise of the winemaker. Each bottle is a unique expression of a time and a place, and offers a glimpse into the rich history and culture of wine.
    
    So if you are looking to add some truly special bottles to your cellar, look no further. These wines will shine in any collection, and offer an experience that is truly unforgettable.`
  },
  {
    name: 'favorites',
    title: `Find Your New Favorites`,
    intro: `These collections of wine have proven to be customer favorites and are perfect for those who want to stock up on great wines that can be enjoyed anytime.`,
    text: `Love Cabernet Sauvignon, Chardonnay and other grapes? Expand your horizons with similar wines.`,
    link: {
      label: `Discover Favorite Grape Collections`,
      path: `/collections/favorite`
    },
    image: favoriteImage,
    content: `These are wines that have been tried and tested, ones that consistently impress and satisfy the palate. Whether you plan to age them or enjoy them on a casual Wednesday night, these wines are sure to become some of your favorites as well. So let's explore what makes these wines so special and why you should consider stocking up on them.

    These wines have been carefully selected for their versatility and ability to pair well with a variety of dishes or enjoyed on their own. They are also great options for those who want to age their wines as they have a good potential for improving with time.
    
    These wines have proven to be consistent favorites among our customers. They are reliable and dependable, and a safe bet for those who want to stock up on great wines for their cellar. Whether you prefer red, white or rosé, there is something in our collections for everyone.`
  },
  {
    name: 'entertain',
    title: `Host a Dinner Party `,
    intro: `With these carefully chosen wines, you can rest assured that you will be providing your guests with a truly exceptional dining experience.`,
    text: `Curated collections that pair perfectly with seasonal celebrations and your next great dinner party.`,
    link: {
      label: `Discover Dinner Party Collections`,
      path: `/collections/entertain`
    },
    image: entertainImage,
    content: `When it comes to planning a dinner party, selecting the right wines is key to ensuring a memorable and enjoyable experience. Choosing the perfect wines to pair with your menu can be a daunting task, but it doesn't have to be. When it comes to choosing wines for a dinner party, there are a few key things to consider.

    You want to select wines that will complement the flavors of the dishes you will be serving. For lighter dishes such as salads or seafood, crisp and refreshing white wines are often the perfect choice, while heartier dishes like red meat or stews call for full-bodied red wines. You should also consider the preferences of your guests, whether they prefer dry or sweet wines, red or white, or something in between.
    
    The wines in this selection have been specifically chosen to cater to a wide range of tastes and preferences. They are versatile wines that can pair well with a variety of dishes, and have been crafted with a balanced acidity, soft tannins, and complex flavors to enhance the flavors of your food rather than overpower them. These wines are perfect for any occasion, whether you are hosting a casual dinner with friends or a more formal affair.`
  },
  {
    name: `everyday`,
    title: `Discover Everyday Wines`,
    intro: `The wines in this collection are perfect examples of approachable, versatile, and delicious wines that are meant to be enjoyed anytime.`,
    text: `Looking for that go to weekday wine at a great price point?  We’ve got you covered Monday to Thursday!`,
    link: {
      label: `Discover Everyday Collections`,
      path: `/collections/everyday`
    },
    image: everydayImage,
    content: `These wines have been carefully selected for their approachability, versatility, and deliciousness. They are wines that you can open on a Tuesday night after work, without feeling like you need a special occasion to do so. These wines are not meant to be saved for a special occasion or a fancy meal - they are meant to be enjoyed every day.

    When selecting wines for everyday drinking, there are a few things to consider.
    
    First, you want to choose wines that are easy to drink, with smooth tannins and a balanced acidity. You don't want a wine that's too heavy or too tannic, as that can be overwhelming for casual drinking.
    
    Second, you want to choose wines that are versatile and can be paired with a wide range of dishes. These are wines that can be enjoyed with anything from pizza to grilled chicken to pasta. The wines in this collection tick all these boxes and more. They are made by skilled winemakers who understand the importance of crafting wines that are both approachable and delicious. These are not wines that require a lot of thought or analysis - they are wines that can be enjoyed without pretense or fuss.
    
    While it's easy to default to well-known varietals like Cabernet Sauvignon or Chardonnay, there are many European grape varietals that produce excellent everyday drinking wines that are often overlooked. From France, to Italy to Spain and beyond, there are numerous grape varietals that offer a wide range of flavors and aromas, making them perfect for casual drinking occasions. These wines are typically lighter in body, with a crisp acidity and delicate flavors that pair well with a variety of foods and occasions.
    
    So raise a glass to the joy of everyday drinking, and enjoy a great bottle of wine tonight.`
  },
]