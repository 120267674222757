import { createSlice } from '@reduxjs/toolkit'
import { requestUserOrder, requestCheckShippingZipcode, requestPlaceOrder } from './checkout.actions'



const initialState = {
  // order: undefined, 
  answer: undefined,
  shipping: {}, 
  billing: {},
  orderCapture: undefined, 
  shippingCountry: 'US',
  zip: undefined,
  warehouse: undefined,
  retailer: undefined,
  orderPlaced: undefined,
  newOrderId: undefined,
  square: {},
  paymentMethod: undefined,
  paymentDetails: undefined,
  paymentStep: undefined,
  isGift: false,
  giftMessage: '',
  orderDataFetchState: 'inactive', // [inactive, success, fail]
  processingRequest: false,
  processingRequests: 0,
  error: undefined,
  orderCaptureAction: undefined
}


// NOT IMPLEMENTED
  // export const removeOrderCaptureAction = () => dispatch({
  //   type: REMOVE_ORDER_CAPTURE_ACTION
  // });

  // export const setOrder = orderData => dispatch({
  //   type: SET_ORDER_DATA, payload: orderData
  // })

  // export const setOrderCaptureAction = orderCaptureFunction => dispatch({
  //   type: SET_ORDER_CAPTURE_ACTION, payload: orderCaptureFunction
  // });

  // export const finishCheckout = () => dispatch({ type: RESET_CHECKOUT_STATE });

// IMPLEMENTED!
  // export const setPaymentMethod = method => dispatch({
  //   type: SET_PAYMENT_METHOD, payload: method
  // })

  // export const changePaymentStep = step => dispatch({
  //   type: CHANGE_PAYMENT_STEP, payload: step
  // })

  // export const changeShippingZipcode = zipCode => dispatch({
  //   type: SET_SHIPPING_ZIPCODE, payload: zipCode
  // })

  // export const setOrderWarehouse = warehouse => dispatch({
  //   type: SET_ORDER_WAREHOUSE, payload: warehouse
  // })

  // export const changeRetailer = retailerData => dispatch({
  //   type: CHANGE_RETAILER, payload: retailerData
  // })

  // export const changeShippingCountry = countryCode => dispatch({
  //   type: SET_SHIPPING_COUNTRY, payload: countryCode
  // });

  // export const changeShippingDetails = shippingDetails => dispatch({
  //   type: CHANGE_SHIPPING_DETAILS, payload: shippingDetails
  // });

  // export const changeBillingDetails = billingDetails => dispatch({
  //   type: CHANGE_BILLING_DETAILS, payload: billingDetails
  // });

  // export const changeGiftMessage = message => dispatch({
  //   type: CHANGE_GIFT_MESSAGE, payload: message
  // })

  // export const changeIsGiftState = () => dispatch({ type: CHANGE_IS_GIFT })


const checkoutSlice = createSlice({
  name: '@@checkout',
  initialState: { ...initialState },
  reducers: {
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    },
    changePaymentStep: (state, action) => {
      state.paymentStep = action.payload
    },
    changeCheckoutLegalAgeAnswer: (state, { payload }) => { state.answer = payload },
    changeShippingZipcode: (state, action) => {
      state.zip = action.payload
    },
    setOrderWarehouse: (state, action) => {
      state.warehouse = action.payload
    },
    changeRetailer: (state, action) => {
      state.retailer = action.payload
    },
    changeShippingCountry: (state, action) => {
      state.shippingCountry = action.payload
    },
    changeShippingDetails: (state, action) => {
      state.shipping = action.payload
    },
    changeBillingDetails: (state, { payload }) => {
      //
      const { isEmpty, ...billingData } = payload.billing;
      state.billing = billingData
      state.paymentDetails = payload.token
    },
    changeContactDetails: (state, action) => {
      const { contactType, isEmpty, ...details } = action.payload;

      state[contactType] = details;
    },
    changeGiftMessage: (state, action) => {
      state.giftMessage = action.payload
    },
    changeIsGiftState: (state, action) => {
      state.isGift = action.payload || !state.isGift
    },
    clearErrors: (state) => {
      state.error = undefined
    },
    finishCheckout: () => ({ ...initialState })
  },
  extraReducers: builder => {
    builder
      .addCase(requestPlaceOrder.fulfilled, (state, { payload }) => {
        state.paymentStep = 'complete'
        state.orderCapture = undefined
        state.orderCaptureAction = undefined
        state.orderPlaced = payload.orderPlaced
        state.newOrderId = payload.newOrderId
      })
      .addCase(requestUserOrder.fulfilled, (state, { payload }) => {
        state.shipping = payload?.contacts?.shipping
        state.billing = payload?.contacts?.billing
        state.warehouse = payload?.warehouseData
        state.orderDataFetchState = 'success'
      })
      .addCase(requestUserOrder.rejected, state => {
        state.orderDataFetchState = 'fail'
      })
      .addCase(requestCheckShippingZipcode.fulfilled, (state, { payload }) => {
        state.zip = payload.zip

        state.retailer = payload.retailerData
        state.paymentMethod = payload.paymentMethod
        state.paymentStep = payload.paymentStep
      })
      .addMatcher(
        (action) => action.type.startsWith('@@checkout') && action.type.endsWith('/rejected'), 
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@checkout') && action.type.endsWith('/pending'), 
        (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@checkout') && action.type.endsWith('/fulfilled'), 
        (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
  }
})


export const checkoutActions = { ...checkoutSlice.actions, requestPlaceOrder, requestUserOrder, requestCheckShippingZipcode };

export default checkoutSlice.reducer;
