import React from 'react' 
import PropTypes from 'prop-types'

const SerendipityPriceDetails = ({
  caseMarkup, serviceFee, priceFee, warehouseDeliveryFee
}) => {

  const totalPrices = priceFee.prices;

  const {
    subtotal, total, creditCardProcessingFee, caseMarkup: caseMarkupValue, serviceFee: serviceFeeValue
  } = priceFee;

  return (
    <>
      <li>
        <span>Case Markup ({+caseMarkup * 100}%)</span>
        <span className="dots" />
        <span>${caseMarkupValue?.toFixed(2) || 0}</span>
      </li>

      <li>
        <span>Service Fee ({+serviceFee * 100}%)</span>
        <span className="dots" />
        <span>${serviceFeeValue?.toFixed(2) || 0}</span>
      </li>

      <li>
        <span>Delivery Fee</span>
        {/* <span>$25.00</span> */}
        <span className="dots" />
        <span>${ warehouseDeliveryFee?.toFixed(2) }</span>
      </li>

      <li>
        <span>Subtotal</span>
        <span className="dots" />
        <span>${subtotal?.toFixed(2)}</span>
      </li>

      <li>
        {/* CreditCardFeeDescription */}
        <span>Credit card fee (2.99% on total price + $0.49)</span> 
        <span className="dots" />
        <span>${creditCardProcessingFee?.toFixed(2)}</span>
      </li>

      <li>
        <span>TOTAL BEFORE ROUNDING</span>
        <span className="dots" />
        <span>${total?.toFixed(2)}</span>
      </li>

      <li>
        <span>Rounding number</span>
        <span className="dots" />
        <span>${(totalPrices.usd - total)?.toFixed(2)}</span>
      </li>
      </>
  )
}

SerendipityPriceDetails.propTypes = {
  caseMarkup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  serviceFee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceFee: PropTypes.shape({
    caseMarkupValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    serviceFeeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    creditCardProcessingFee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  totalPrices: PropTypes.shape({
    eur: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    usd: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
}


export default SerendipityPriceDetails;
