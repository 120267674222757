import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { CaseTile, Icon } from '../../components'

import './style.scss'



const CaseDrugAndDropCard = ({ 
  name, id, slug, inventory, price, region, regions, wines, onDrag, onDrop, onToggleOrder, order, orders
}) => {

  const handleDrugCaseCard = () => {
    onDrag({ id, order })
  }

  const handleDropCaseCard = () => {
    onDrop({ id, order })
  }

  const handleChangeOrderWithAdjacentCards = orderDiff => e => {
    e.preventDefault();

    onToggleOrder({orderDiff, id})
  }

  const cardControls = (<>
    <div className="order-inc-buttons">
      <button className="button-link"
        onClick={handleChangeOrderWithAdjacentCards(-1)}
      ><Icon icon="caret-up" size={24} /></button>

      <button className="button-link"
        onClick={handleChangeOrderWithAdjacentCards(1)}
      ><Icon icon="caret-down" size={24} /></button>
    </div>
  </>)


  return (
    <div className={`case-tile-dnd-wrapper${ +inventory === 0 ? ` out-of-stock` : `` }`} 
      key={`${id}-${slug}`}
      draggable
      onDragOver={e => e.preventDefault()}
      onDrag={handleDrugCaseCard}
      onDrop={handleDropCaseCard}
    >
      <CaseTile
        id={id}
        name={name}
        inventory={inventory}
        price={price}
        orders={orders}
        wines={wines}
        regions={regions}
        slug={slug}
      >{cardControls}</CaseTile>
    </div>
  )
}

CaseDrugAndDropCard.propTypes = {
  inventory: PropTypes.number
  , name: PropTypes.string
  , id: PropTypes.number
  , slug: PropTypes.string
  , price: PropTypes.string
  , region: PropTypes.string
  , regions: PropTypes.array
  , wines: PropTypes.array
  , onToggleOrder: PropTypes.func
  , onDrag: PropTypes.func
  , onDrop: PropTypes.func
}

export default memo(CaseDrugAndDropCard)
