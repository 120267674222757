import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './users/user.slice'
import regionReducer from './regions/region.slice'
import cartReducer from './cart/cart.slice'
import checkoutReducer from './checkout/checkout.slice'
import authReducer from './auth/auth.slice'
import casesReducer from './cases/case.slice'
import recommendationsReducer from './recommendations/recommendation.slice'
import winesReducer from './wines/wine.slice'
import producerReducer from './producers/producer.slice'
import curatorReducer from './curators/curator.slice'
import orderReducer from './orders/order.slice'


// const initialState = {
//   regionRecommendationCase: undefined,
//   ip: initialData.ip,
//   isAuthenticated: authenticated,
//   region: authRegion,
//   isAdult: initialData?.isAdult
// }


export default combineReducers({
  users: userReducer,
  regions: regionReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  auth: authReducer,
  cases: casesReducer,
  recommendations: recommendationsReducer,
  wines: winesReducer,
  producers: producerReducer,
  curators: curatorReducer,
  orders: orderReducer
})
