import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Spinner } from '..'
import { useRequests } from '../../hooks'


const CreateAllWinesThumbnailButton = () => {
  const [state, changeState] = useState({
    processingrequest: false
  })
  const { post } = useRequests()

  const handleCreateThumbnails = async e => {
    e.preventDefault();

    changeState(prev => ({ ...prev, processingrequest: true }))

    const response = await post({ url: `/api/wines/create-all-thumbnails` })

    if (response?.data?.success) {
      const message = `Thumbnails were successfuly created.${ response?.data?.noAccessImages ? ` Check images of wines: ${response?.data?.noAccessImages.join(', ')}` : `` }`

      toast.success(message)
    }

    changeState(prev => ({ ...prev, processingrequest: false }))
  }

  return (
    <button
      className="button button_outline"
      disabled={state.processingrequest}
      onClick={handleCreateThumbnails}
    >Create Wines Thumbnails { state.processingrequest && <Spinner spinnerClass="pending-spinner" /> }</button>
  )
}

export default CreateAllWinesThumbnailButton