import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../components';
import { useActions, useRoutes } from '../hooks';

const RoutesContext = createContext()
const Provider = RoutesContext.Provider


const RoutesProvider = () => {
  const dispatch = useDispatch()
  const { authActions, regionActions } = useActions()
  const { processingRequests, initialDataIsFetched, isAuthenticated, config } = useSelector(state => state.auth);
  
  useEffect(() => {
    dispatch(authActions?.authenticate())
    dispatch(regionActions.requestRegionsList())
    dispatch(regionActions.requestCountriesDataList())
  }, [])
  
  const routes = useRoutes(isAuthenticated, undefined, config?.p)

  if (!initialDataIsFetched) return <LoadingScreen /> 
  const content = initialDataIsFetched ? routes : <LoadingScreen />

  return <Provider value={{}}>{content}</Provider>
}

export default RoutesProvider
