import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonWithPopoverConfirmation from '../buttons/ButtonWithPopoverConfirmation';



const OrphansList = ({ name, onConfirmDelete, orphans, isPending }) => {
  const [confirmationState, changeConfirmationPopupState] = useState('hidden');

  const handleShowConfirmation = e => {
    e.preventDefault();

    changeConfirmationPopupState('visible')
  }

  const handleHideConfirmation = e => {
    e.preventDefault();

    changeConfirmationPopupState('hidden')
  }

  const handleConfirmRemoveOrphans = e => {
    e.preventDefault();

    onConfirmDelete();
    changeConfirmationPopupState('hidden');
  }

  const buttonText = `Remove ${ name }`;
  const confirmText = `Please confirm deleting orphan ${ name.toLowerCase() }`;

  const orphansList = orphans.length > 0 ?
    orphans.map(
      ({ id }) => (
        <li key={`${ name.split(' ').join('_').toLowerCase() }_${ id }`} className="orphan-item">{ name } ID {id}</li>
      )
    ) : (
      <li>No { name } orphans found</li>
    );


  return (
    <div className="orphans-list">
      <h3 className="list-caption">{ name } Orphans</h3>
      {
        orphansList.length > 0 &&
        <ButtonWithPopoverConfirmation
          isOpen={confirmationState === 'visible'}
          confirmText={ confirmText }
          onConfirm={ handleConfirmRemoveOrphans }
          onCancel={ handleHideConfirmation }
          pending={ isPending }
          onClick={ handleShowConfirmation }
          position="bottom"
          btnText={ buttonText }
        />
      }

      <ul>{orphansList}</ul>
    </div>
  )
}

OrphansList.propTypes = {
  name: PropTypes.string.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  buttonText: PropTypes.string,
  orphans: PropTypes.arrayOf(PropTypes.number).isRequired,
  isPending: PropTypes.bool
}

OrphansList.defaultProps = {
  isPending: false,
  orphans: [],
  name: 'Records'
}

export default OrphansList