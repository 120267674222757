import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useActions, useHelpers, useRequests } from '../../hooks';

import logo from '../../assets/icons/logo-h-green-3x.svg';
import { useDispatch } from 'react-redux';


const VerificationForm = ({ onClick }) => {
  const dispatch = useDispatch()
  const { authActions } = useActions()
  const { objectIsEmpty } = useHelpers();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { post, requestPending, errors, clearErrors } = useRequests();
  const [formData, changeFormData] = useState({
    email: '', verification: ''
  });


  const errorsData = errors
    ? !objectIsEmpty(errors.errors)
      ? errors.errors : { message: errors.message }
    : {};

  if (errors?.errorCode === 'VERIFICATION_CODE_INVALID') {
    const [message, link] = errors?.message?.split('_') || ['', ''];

    errorsData.codeMessage = (
      <>{ message } <a href="mailto:support@vineify.com" rel="noreferrer noopener"><u>{ link }</u></a>.</>
    )
  }

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    clearErrors();
    changeFormData({
      ...formData,
      [name]: value
    });
  }

  const handleRedirectToLoginPage = (e, email) => {
    e.preventDefault();

    if (email) {
      // ctx.user.email = email;
      dispatch(authActions.changeUserdata({ email }))
    }

    history.push('/login')
  }

  useEffect(
    // eslint-disable-next-line
    () => { clearErrors(); }, [i18n.language]
  );

  const verify = async (e) => {
    e.preventDefault();
    const { verification } = formData;

    const response = await post(
      {
        url: '/api/users/verification/',
        body: {
          code: verification
        }
      }
    );

    if (response && response.data.success) {
      // ctx.user.email = response.data.email;
      dispatch(authActions.changeUserdata({ email: response.data.email }))
      history.push('/check-age');
    }
  }


  return (
    <>
      <form className="verification-form" onSubmit={verify}>
        <div className="form-logo">
          <img src={logo} alt="vineify" />
        </div>

        <div className="form-header">
          {/* <h3 className="form-title">{ t('WAIT_LIST_CODE_FORM_TITLE') }</h3> */}
          {/* <h3 className="form-title">Got a code? Let’s go!</h3> */}
          <h3 className="form-title">{t('WAIT_LIST_CODE_FORM_TITLE')}</h3>

          <p className="form-subtitle no-margin">
            {t('WAIT_LIST_JOIN_FORM_LINK_START')}

            <button onMouseUpCapture={onClick} className="button-link">{t('WAIT_LIST_JOIN_FORM_LINK_END')}</button>
          </p>
        </div>

        <div
          className={
            classNames('form-control margin-lg', {
              'invalid': errorsData.code || errorsData.message || errorsData.email
            })}
        ><label className="text-left" htmlFor="verification">{t('WAIT_LIST_CODE_LABEL_CODE')}</label>

          <input
            name="verification" type="text"
            placeholder={t('WAIT_LIST_CODE_PLACEHOLDER_ACCESS_CODE')}
            value={formData.verification}

            onChange={handleChange}
          />
          {
            errors?.errorCode === 'VERIFICATION_CODE_INVALID' &&
            <small className="error-msg">{errorsData.codeMessage}</small>
          }
          {
            errors?.errors?.email &&
            <small className="error-msg">
              {errors?.message?.replace('{{email}}', errors?.errors?.email)}.{' '}
              <button
                className="button-link no-padding no-margin button_error"
                onClick={e => handleRedirectToLoginPage(e, errorsData?.email)}
              ><u className="text-error">{t('WAIT_LIST_JOIN_FORM_ERROR_LINK')}.</u></button>
            </small>
          }
        </div>
        <button
          className="button button_green"
          // onClick={verify}
          disabled={requestPending}
        >{t('WAIT_LIST_CODE_BUTTON_CONTINUE')}</button>
        {/* <p className="form-link">{ t('WAIT_LIST_JOIN_FORM_LINK_START') } <button onClick={ onClick } className="button-link">{ t('WAIT_LIST_JOIN_FORM_LINK_END') }</button></p> */}
      </form>
    </>
  )
}


VerificationForm.propTypes = {
  onClick: PropTypes.func
}

VerificationForm.defaultProps = {
  onClick: () => { }
}

export default VerificationForm;
