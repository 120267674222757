import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useRequests } from '../../../hooks';
import AiBuddyFilters from './AiBuddyFilters';
import AiBuddyForm from './AiBuddyForm'


const AiWineBuddySection = () => {
  return (
    <div className="public-home__section lite">
      <div className="container">
        <div className="personal-rec">
          <AiBuddyFilters />

          <AiBuddyForm />
        </div>
      </div>
    </div>
  )
}

export default AiWineBuddySection