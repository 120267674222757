import React from 'react';
import PropTypes from 'prop-types';

import icon from '../../assets/icons/question-mark.svg';

import './style.scss';


const RecomendBadge = ({ answer }) => {

    return (
        <span 
            className={`rec-badge state-${answer}`} 
            title={`Will Recommend: ${answer}`}
        ><img src={ icon } alt="recommend answer" /> { answer }</span>
    )
}

RecomendBadge.propTypes = {
    answer: PropTypes.string.isRequired
}

export default RecomendBadge
