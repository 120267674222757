import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom';

import './style.scss'
import Icon from '../common/Icon';


const MotivationCard = ({ title, link, text, image }) => {
  const history = useHistory()
  const { label, path } = link;

  const handleGoToCollection = () => {
    history.push(path)
  }


  return (
    <div className="card motivation-card" onClick={handleGoToCollection}>
      <img src={image} className="motivation-card__background" alt="" />

      <div className="motivation-card__content">
        <h3 className="motivation-card__title">{title}</h3>

        <p className="motivation-card__text">{text}</p>

        <Link className="motivation-card__link" to={path}>
          {label}

          <Icon icon="arrow-right" color="white" size={16} />
        </Link>
      </div>
    </div>
  )
}


MotivationCard.propTypes = {
  title: PropTypes.string
  , text: PropTypes.string
  , image: PropTypes.string
  , link: PropTypes.shape({
    path: PropTypes.string, label: PropTypes.string
  })
}

export default MotivationCard