import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


const OneInputForm = ({ title, formTitle, name, onChange, clearErrors, initialValue, onCancel, onSubmit, formClassName, disabled, error, inputType }) => {
  const [value, changeValue] = useState(initialValue)
  const classNamesList = ['form']

  if (formClassName) classNamesList.push(formClassName)
  const className = classNamesList.join(' ')

  const handleSubmitValue = e => {
    e.preventDefault();

    onSubmit(value)
  }

  const handleChangeValue = e => {
    const { value } = e.currentTarget;

    changeValue(value)
    onChange && onChange(value)
  }

  return (
    <form className={ className }>
      {
        formTitle &&
        <h3 className="form-section-title">{ formTitle }</h3>
      }
      <div className={classNames('form-control', { 'invalid': error })}>
        <label htmlFor={ name }>{ title }</label>

        <input type={ inputType || 'text' } name={ name } value={ value } onChange={ handleChangeValue } />
        {
          error &&
          <span className="err-message">{error}</span>
        }
      </div>

      <div className="form-control horizontal gap_sm">
        {
          onCancel &&
          <button type="button" className="button button_xs button_outline" onClick={onCancel}>Close</button>
        }

        <button 
          disabled={disabled}
          className="button button_xs" 
          onClick={handleSubmitValue}
        >Submit</button>
      </div>
    </form>
  )
}

OneInputForm.propTypes = {
  name: PropTypes.string
  , title: PropTypes.string
  , initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  , onCancel: PropTypes.func
  , onSubmit: PropTypes.func
  , formClassName: PropTypes.string
  , disabled: PropTypes.bool
}

export default OneInputForm