import React from 'react';
import PropTypes from 'prop-types';
import { useRequests } from '../../hooks';
import { toast } from 'react-toastify';


const CustomCaseRow = ({ wine, customers, created, onchange, action, isSingle, caseId, customer }) => {
  const { post, requestPending } = useRequests();

  const handleCreateCustomCase = async e => {
    e.preventDefault();

    if (!isSingle) {
      const response = await post({
        url: '/api/wines/cases/custom-case',
        body: { wineId: wine.id }
      });
  
      if (response?.data?.success) {
        onchange(response.data.wine);
  
        toast.success(`Case ${response.data.wine} was successfully created.`);
      } else {
        toast.error(`Something went wrong...`)
      }
    } else {
      onchange();
    }
  }
  

  return (
    <div className="custom-case-row">
      <div className="main">
        <div className="w-100 name">
          <h4 className="no-margin">{customer?.firstName ? customer.firstName + `'s` : ``} { wine.name }</h4>
        </div>
      </div>

      

      <div className="stock">{ wine.stock }</div>

      {
        created ?
          <span className="text-gray text-center" style={{ width: '130px' }}>{ created.name }</span>
          :
          <button 
            className="button button_xs" style={{ width: '130px' }}
            disabled={ requestPending || action.toLowerCase() === 'created' }
            onClick={ handleCreateCustomCase }
          >{ action }</button>
      }
    </div>
  )
}


CustomCaseRow.propTypes = {
    created: PropTypes.bool,
    wine: PropTypes.shape({
        name: PropTypes.string,
        producer: PropTypes.string,
        vintage: PropTypes.number,
        description: PropTypes.string
    }),
    customers: PropTypes.arrayOf(PropTypes.string),
    isSingle: PropTypes.bool,
    action: PropTypes.string
  }
  
  CustomCaseRow.defaultProps = {
    created: false,
    isSingle: false,
    action: 'Create'
  }
  
  export default CustomCaseRow;
