import React, { memo } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardCarousel, NewRegionCard } from '../../../components'
import { useHelpers } from '../../../hooks'


const PublicHomeRegions = ({ regions }) => {
  const { t } = useTranslation();
  const { capitalize } = useHelpers();
  const isMobile = window.matchMedia('(max-width: 520px)').matches
  const isTablet = window.matchMedia('(max-width: 768px)').matches


  const carouselCards = regions.map(regionData => (
    <NewRegionCard
      key={regionData.name}
      country={t(capitalize(regionData?.country || 'ITALY'))}
      description={regionData.description}
      image={regionData.cardImage}
      mapImg={regionData.mapImg}
      numCases={regionData.numCases}
      name={regionData.name}
      regionKey={regionData.key}
    />
  ))

  const isOldSection = document.location.href.includes('old-homepage')


  return (
    <div className={
      `public-home__section public-home__regions${ isOldSection ? ` lite` : `` }`}>
      <div className="container">
        <h2 className="public-home__title public-title-sm fw-400">Explore The <b>World’s Wine Regions</b> with Vineify</h2>

        {
          isOldSection &&
          <p className="public-home__subtitle">See what Europe has to offer.</p>
        }

        {
          !isOldSection &&
          <p className="public-home__description">Whether you’re new to the wines of Rhône, Piedmont, Rioja (and many other amazing regions) or you’re looking to go deep in each Vineify has you covered.</p>
        }

        <div className="public-home__carousel-wrapper">
        <CardCarousel
          config={{ itemsToShow: isMobile ? 'auto' : 2, dots: true, slidesToScroll: 1 }}
          dots={true}
          dynamicDots={ true }
          withNavigation
          spaceBetween={ isTablet ? 16 : 32}
          cards={carouselCards}
          slideClassName="case-slider-card-wrapper"
        />
        </div>
      </div>
    </div>
  )
}

PublicHomeRegions.propTypes = {

}

PublicHomeRegions.defaultProps = {
  regions: []
}

export default memo(PublicHomeRegions)