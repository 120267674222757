import { createSlice } from '@reduxjs/toolkit';
import { requestCountriesDataList, requestCurrentRegionCases, requestRegionGrapes, requestRegionsList, requestShippingCharges, requestSliderRegionsData, requestRegionData } from './region.actions';


const initialState = {
  processingRequest: false,
  processingRequests: 0,
  error: undefined,
  currentCountry: 'US',
  // charge: { usd: 0, eur: 0 },
  charge: { usd: 0, eur: 0 },
  countryCode: '',
  shippingCountriesList: [],
  sliderRegions: [],
  currency: 'usd',
  countriesTranslations: {},
  currentRegion: undefined,
  regions: {},
  parentRegions: [],
  currentRegionData: {
    mixedCases: {
      active: undefined,
      list: []
    },
    cases: {
      active: undefined,
      list: [] 
    }
  },
  currentRegionMixedCases: [],
  currentRegionCases: [],
};


const regionSlice = createSlice({
  name: '@@regions',
  initialState: { ...initialState },
  reducers: {
    setParentRegions: (state, action) => {
      state.parentRegions = action.payload;
    },
    changeCurrentRegion: (state, { payload }) => {
      state.currentRegion = payload
    },
    changeActiveRegionCase: (state, { payload }) => {
      const { id, isMultiRegion } = payload;

      if (isMultiRegion) {
        state.currentRegionData.mixedCases.active = id
      } else {
        state.currentRegionData.cases.active = id
      }
    },
    clearRegionData: (state) => {
      state.currentRegionData = undefined;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestRegionsList.fulfilled, (state, { payload }) => {
        state.parentRegions = payload.parentRegions
        state.regions = payload.regionsList
      })
      .addCase(requestCountriesDataList.fulfilled, (state, { payload }) => {
        state.countriesTranslations = payload;
      })
      .addCase(requestShippingCharges.fulfilled, (state, { payload }) => {
        state.charge = payload.charge
        state.currency = payload.currency
        state.currentCountry = payload.shippingDataCountry
      })
      .addCase(requestCurrentRegionCases.fulfilled, (state, { payload }) => {
        state.currentRegionData.mixedCases.list = payload.mixedRegionCases

        state.currentRegionData.cases.list = payload.singleRegionCases
        if (!state.currentRegionData.cases.active) {
          state.currentRegionData.cases.active = payload.singleRegionCases?.[0].id
        }
      })
      .addCase(requestRegionGrapes.fulfilled, (state, { payload }) => {
        state.currentRegionData.grapes = payload
      })
      .addCase(requestSliderRegionsData.fulfilled, (state, { payload }) => {
        state.sliderRegions = payload;
      })
      .addCase(requestRegionData.fulfilled, (state, { payload }) => {
        state.currentRegionData = { ...state.currentRegionData, ...payload }
      })
      .addMatcher(
        (action) => action.type.startsWith('@@regions') && action.type.endsWith('/rejected'), 
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@regions') && action.type.endsWith('/pending'), 
        (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@regions') && action.type.endsWith('/fulfilled'), 
          (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})


export const regionActions = { ...regionSlice.actions, requestCountriesDataList, requestCurrentRegionCases, requestRegionGrapes, requestRegionsList, requestShippingCharges, requestSliderRegionsData, requestRegionData };

export default regionSlice.reducer;
