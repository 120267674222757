import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import WineCard from './card/WineCard';
import {
  CardCarousel as Carousel, VerificationAlert, RecommendationWineCard, CaseCountriesList, CaseStockBadge, ShortDescription, CaseImageWithPrice, CaseDetailsCuratorPreview, TwitterShareButton
} from '../components';
import LoadingScreen from './loading/LoadingScreen';
import { useHelpers, useMediaQuery } from '../hooks';


const CaseDetails = ({
  id, name, price, cardType, wines, longDescription, onPurchase, buttonDisabled, stock, isCustomizable, curator, onCaseCustomize, regions, image, shareButtons, mobileDescriptionTruncateSize, descriptionTruncateSize, containerClass, adjustmentDialogPosition, showCaseImage, showCurator, showCurrentlyUnavailable, showWineLink, isMixed, active, buttonIsActive, showShareButtons
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet: isHandHeldDevice } = useMediaQuery()
  const { sortListOfObjectByField, getNumberOfWines } = useHelpers();
  const carouselRef = useRef();
  // const isHandHeldDevice = window.matchMedia('(max-width: 768px)').matches;
  const numberOfWines = getNumberOfWines(wines);
  const curatorName = curator?.firstName;
  const mainClassnames = ['case__details']
  const customizeLinkIsVisible = isCustomizable && active !== 0
  const showCustomizationOnTop = customizeLinkIsVisible && adjustmentDialogPosition === 'top'
  const showHeaderBottomSection = showCustomizationOnTop || showCurator

  const [showPrice, setShowPriceState] = useState(true)

  if (containerClass) mainClassnames.push(containerClass)

  useEffect(() => {
    return () => { carouselRef.current = null; }
  });

  const isActive = stock > 0 && active > 0

  const longDescriptionText = isMobile
    ? mobileDescriptionTruncateSize
      ? (
        <ShortDescription expandable={true} lines={mobileDescriptionTruncateSize}
        ><p className="curator_case-description formatted-spaces">{longDescription}</p>
        </ShortDescription>
      ) : (
        <p className="curator_case-description formatted-spaces">{longDescription}</p>
      )
    : descriptionTruncateSize
      ? (
        <ShortDescription expandable={true} lines={descriptionTruncateSize}
        ><p className="curator_case-description formatted-spaces">{longDescription}</p>
        </ShortDescription>
      ) : (
        <p className="curator_case-description formatted-spaces">{longDescription}</p>
      )

  // const buttonLabel = stock > 0 ? t('CASE_CARD_BUY_CASE_BTN') : t('CASE_CARD_SOLD_OUT');
  const buttonLabel = isActive || buttonIsActive ? 'Buy Now' : t('CASE_CARD_SOLD_OUT');
  const countriesList = useMemo(() => regions?.map(({ country }) => country), [regions])
  const sortedWines = sortListOfObjectByField(wines, 'wine_order', { fieldType: 'number', order: 'asc' })
  const activeButtonNotDisabledCondition = stock > 0 && active !== 0 && buttonIsActive


  const listOfWines = !sortedWines ? [] :
    cardType === 'recommendation' ?
      sortedWines.map(wine => (
        <div
          key={wine.id + '_' + wine?.name?.split(' ').join('_')}
          className="wine-card-wrapper"
          cy-data-wine-id={wine.id}
        >
          <RecommendationWineCard
            key={wine.id + '_' + wine.name.split(' ').join('_')}
            {...wine}
            region="Piedmont"
            country={t('ITALY')}
          /></div>
      ))
      :
      sortedWines.map(wine => {
        return isHandHeldDevice ? (
          <WineCard
            key={wine.id + '_' + wine?.name?.split(' ').join('_')}
            name={wine.name} quantity={wine.quantity}
            description={wine.description}
            image={wine.thumbnail || wine.image}
            grapeName={wine.sortOfGrape}
            grapeCategory={wine.category}
            tastes={wine.tastes}
            region={wine.region}
            country={wine.country}
            vintage={wine.vintage}
            slug={wine.slug}
            producer={wine.producer}
            isClickable={showWineLink}
            showLocation={isMixed}
          />
        ) : (
          <div
            key={wine.id + '_' + wine?.name?.split(' ').join('_')}
            cy-data-wine-id={wine.id} cy-data-order={wine.wine_order}
            className="wine-card-wrapper"
          >
            <WineCard
              name={wine.name} quantity={wine.quantity}
              description={wine.description}
              image={wine.thumbnail || wine.image}
              grapeName={wine.sortOfGrape}
              grapeCategory={wine.category}
              tastes={wine.tastes}
              vintage={wine.vintage}
              producer={wine.producer}
              region={wine.region}
              slug={wine.slug}
              country={wine.country}
              isClickable={showWineLink}
              showLocation={isMixed}
            /></div>
        )
      })

  const winesCarouselConfig = {
    itemsToShow: 0,
    itemsToScroll: 1
  };

  if (!wines) return <LoadingScreen />


  const handlePurchase = e => {
    e.preventDefault();

    onPurchase();
  }

  const stockBadge = (
    <CaseStockBadge showCurrentlyUnavailable={showCurrentlyUnavailable} stock={active === 0 ? 0 : stock} className="with-case-details-btn" />
  )

  const handleHidePriceLabel = e => {
    e && e.preventDefault()

    setShowPriceState(false)
  }

  const purchaseButton = (
    <button
      className="button custom-case-action-btn"
      disabled={buttonDisabled || !activeButtonNotDisabledCondition}
      onClick={handlePurchase}
    // ><CaseStockBadge stock={stock} className="with-case-details-btn" /> {buttonLabel}</button>
    >{stockBadge} {buttonLabel}</button>
  )

  // const purchaseButton = isAuthenticated ? (
  //   <button
  //     className="button custom-case-action-btn"
  //     disabled={buttonDisabled || stock < 1}
  //     onClick={handlePurchase}
  //   // ><CaseStockBadge stock={stock} className="with-case-details-btn" /> {buttonLabel}</button>
  //   >{ stockBadge } {buttonLabel}</button>
  // ) : (
  //   <SignInToBuy
  //     buttonClassname="custom-case-action-btn" 
  //     stockBadge={ stockBadge } 
  //     disabled={buttonDisabled || stock < 1}
  //     onSignedIn={ onSignedIn } 
  //     label={buttonLabel}
  //   />
  // )


  return (
    <div className={mainClassnames.join(' ')} data-cy-cd-id={id}>
      <div className="case__details-header">
        {
          name && name.length > 0 &&
          <h3 className="no-padding">{name}{shareButtons && <> {shareButtons}</>}</h3>
        }
        {
          showCaseImage &&
          <CaseImageWithPrice
            containerClass="case-details__image"
            caseId={id}
            wines={sortedWines}
            image={image}
            price={showPrice ? price : false}
          />
        }
      </div>

      {
        longDescription?.length > 0 
          ? (<>
            <p className="curator_case-subtitle">{curatorName}’s {t('CASE_CARD_DESCRIPTION_TITLE')}:</p>
  
            {/* <p className="curator_case-description formatted-spaces">{longDescription}</p> */}
            {longDescriptionText}
          </>) : (
            <><br /><br /></>
          )
      }

      <CaseCountriesList countries={countriesList} />

      <div className="price-section">
        <div className="">
          <p onClick={handleHidePriceLabel} className="price-section__subtitle">{t('RECOMMENDATIONS_PRICING_LABEL')}</p>

          <span className="price-section__price">{price}</span>
        </div>

        {purchaseButton}

        {
          showHeaderBottomSection && 
          <div className="case-details__header-bottom w-100">
          <div className="customization-request">
            {
              showCustomizationOnTop &&
              <>
                {`Want to add or remove bottles?`}

                <button
                  className="not-underlined button-link"
                  id="customize-button"
                  onClick={onCaseCustomize}
                ><b>Customize Now</b></button>
              </>
            }
          </div>

          {
            showCurator &&
            <CaseDetailsCuratorPreview {...curator} />
          }
        </div>
        }
      </div>

      <div className="case-details__section-divider" />

      <div className="details__wines">
        <p className="details__wines-subtitle">
          {/* {t('CASE_CARD_CONTENT_TITLE')}: */}
          {numberOfWines} wines in this selection:

          {
            customizeLinkIsVisible && adjustmentDialogPosition === 'bottom' &&
            <p className="customization-request">
              Want to add or remove bottles?

              <button
                className="not-underlined button-link"
                id="customize-button"
                onClick={onCaseCustomize}
              ><b>Customize Now</b></button>
            </p>
          }

          {
            showShareButtons &&
            <TwitterShareButton url={`https://${document.location.host}/cases/${id}`} />
          }
        </p>

        {
          listOfWines.length ?
            isHandHeldDevice ?
              <div className="wines-list">
                <Carousel
                  sliderRef={carouselRef}
                  config={winesCarouselConfig}
                  dots={true}
                  dynamicDots
                  withNavigation
                  name="wines"
                  slideClassName="wine-card-wrapper"
                  cards={listOfWines}
                />
              </div>
              :
              <div className="wines-list">{listOfWines}</div>
            :
            <VerificationAlert msg="No Wines" />
        }
      </div>
    </div>
  )
}


CaseDetails.propTypes = {
  name: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wines: PropTypes.array,
  description: PropTypes.string,
  purchaseBtn: PropTypes.bool,
  onPurchase: PropTypes.func,
  screenWidth: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  showCurrentlyUnavailable: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  isCustomizable: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  buttonIsActive: PropTypes.bool,
  adjustmentDialogPosition: PropTypes.oneOf(['top', 'bottom'])
}

CaseDetails.defaultProps = {
  purchaseBtn: true,
  onPurchase: () => { },
  buttonDisabled: false,
  isCustomizable: false,
  cardType: 'case wines',
  isAuthenticated: true,
  showCaseImage: false,
  adjustmentDialogPosition: 'bottom',
  showCurrentlyUnavailable: true,
  buttonIsActive: false,
}

export default memo(CaseDetails);
