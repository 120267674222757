import React from 'react';
import PropTypes from 'prop-types';
import CustomRoute from './CustomRoute';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component, ...rest }) => {
    const { isAuthenticated, user } = useSelector(state => state.auth);
    const notAdultSessionAnswer = window.sessionStorage.getItem('_cag') === 0;


    return <CustomRoute 
        component={ component }
        // accept={ isAuthenticated && user?.isAdult }
        accept={ isAuthenticated }
        // fallback={ 
        //   !isAuthenticated ? '/login' : !user.isAdult 
        //     ? notAdultSessionAnswer 
        //       ? '/access-denied' : '/check-age' 
        //       : '/login'
        // }
        fallback="/login"
        { ...rest }
    />
}


PrivateRoute.propTypes = {
    component: PropTypes.any,
    rest: PropTypes.object
}

export default PrivateRoute;
