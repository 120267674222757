import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRequests } from '../../hooks'
import { Spinner, Icon } from '..'

import './style.scss'


const CuratorFollowersDialog = ({ id, onClose, title }) => {
  const { get } = useRequests()
  const [followers, setFollowers] = useState()

  const fetchFollowers = async () => {
    const response = await get({ url: `/api/curators/${id}/followers` })

    setFollowers(response?.data?.followers || [])
  }

  useEffect(fetchFollowers, [])

  if (!followers) return (
    <div className="curator-followers__dialog flex-center">
      <Spinner />
    </div>
  )

  const { followed, unfollowed } = followers?.reduce(
    (acc, followerData) => {
      if (followerData.status === 'follow') {
        acc.followed.push(followerData)
      } else {
        acc.unfollowed.push(followerData)
      }

      return acc;
    }, { followed: [], unfollowed: [] }
  ) || { followed: [], unfollowed: [] }



  return (
    <div className="curator-followers__dialog">
      <button className="close-modal-btn" onClick={onClose}>
        <Icon icon='close' color="disabled-color" size={20} />
      </button>

      {
        title &&
        <h3 className="curator-followers__title">{title}</h3>
      }

      {
        followed.length > 0 &&
        <FollowersList title="Followers" followers={followed} />
      }

      {
        unfollowed.length > 0 &&
        <FollowersList title="Unfollowed" followers={unfollowed} />
      }

      {
        unfollowed.length === 0 && followed.length === 0 &&
        <p className="m-auto">No followers...</p>
      }
    </div>
  )
}


const FollowersList = ({ title, followers }) => {
  const content = followers.map(followerData => (
    <li key={`fitem-${followerData.id}`} className="followers-list__item">{followerData?.fullName}</li>
  ))

  return (
    <ul className="followers-list">
      <li className="followers-list__item title">{title}</li>
      {content}
    </ul>
  )
}


CuratorFollowersDialog.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  onClose: PropTypes.func
}

export default CuratorFollowersDialog
