import React from 'react';
import { CountryRecommendationHero } from '../../components';
import { Modal, useModal } from 'react-simple-hook-modal';
import { ReferFriendForm, ReferFriendBar, WaitListInvitationBar } from '..';
import { useHelpers } from '../../hooks';

import closeIcon from '../../assets/icons/close.svg';
import caretDown from '../../assets/icons/chevron-down.svg';

import './style.scss';


const RegionHeader = ({ image, mapImage, region, country, numOfCases, handleChangeActiveCase, isAuthenticated }) => {
  const { isModalOpen, openModal, closeModal } = useModal();
  const { scrollToPageSection } = useHelpers();


  return (
    <>
      <div className="home-header region-header">
        <div className="region-header__content">
          <div className="container">
            {
              image &&
              <div className="region">
                {
                  numOfCases > 0 &&
                  <button
                    className="region-cases__badge"
                    onClick={scrollToPageSection('curated-cases')}
                  // >{numOfCases} REGIONAL CASE{numOfCases === 1 ? '' : 's'}</button>
                  >View Cases & Six Packs</button>
                }
                <h1>{region}</h1>

                {
                  country?.toLowerCase() !== region?.toLowerCase() &&
                  <p className="subtitle">{country}</p>
                }
              </div>
            }
          </div>

          {
            window.matchMedia("(max-width: 520px)").matches &&
            <>
              <CountryRecommendationHero region={region} onChangeActiveCase={handleChangeActiveCase} />

              <div className="region__bottom-links">
                <h4>Not Ready to Buy?</h4>

                <button
                  className="button-link text-white"
                  onClick={scrollToPageSection('region-info')}
                >Explore the Region <img src={caretDown} alt="" /></button>
              </div>
            </>
          }
        </div>

        {
          image &&
          <div className="home-header__background">
            <img src={image} alt="" />
          </div>
        }

        {/* {isAuthenticated ? <ReferFriendBar /> : <WaitListInvitationBar />} */}

        <div className="region-map">
          <img src={mapImage} alt="map of Italy" />
        </div>

        {/* {
          mapImage &&
          <div className="region-map">
            <img src={mapImage} alt="map of Italy" />
          </div>
        } */}

        {/* <RegionPreviewCard {...preview} /> */}
      </div>

      <Modal isOpen={isModalOpen} modalClassName="referer-modal">
        <button className="close-modal-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        <ReferFriendForm />
      </Modal>
    </>
  )
}


export default RegionHeader;
