import { createSlice } from '@reduxjs/toolkit'
import { requesCreatetOrderByWineId, requestCurrentUserFavouriteWines, requestCurrentUserOrderedWines, requestUpdateUserWineStatistics, requestWinesList, requestWinesPreviewList, requestWineCasesPreviewsList, requestWinePreviewData, requestWinesCatalog, requestWineSalesReport, requestWineData, requestWineLabels, requestAddNewLabel, requestDeleteLabel, requestUpdateLabel, requestRestockHistory, requestWineCasesList, requestWineOrphansList } from './wine.actions'


const initialState = {
  processingRequest: false,
  processingRequests: 0,
  error: undefined,
  processingWineIds: [],
  list: [],
  labels: [],
  currentWineData: {},
  dataIsFetched: false
}

const wineSlice = createSlice({
  name: '@@wines',
  initialState,
  reducers: {
    resetWinesStore: (state) => {
      state = { ...initialState }
    },
    hideWineFeedback: (state, { payload: index }) => {
      state.list[index].feedback = { alertIsVisible: false, alertContent: '' }
    },
    clearWinesList: (state) => {
      state.list = []
      state.dataIsFetched = false
    },
    changeCurrentWineData: (state, { payload }) => {
      state.currentWineData = payload
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestWinesList.fulfilled, (state, { payload }) => {
        state.list = JSON.parse(payload)
      })
      .addCase(requestWineOrphansList.fulfilled, (state, { payload }) => {
        state.list = payload
      })
      .addCase(requestWineCasesList.fulfilled, (state, { payload }) => {
        state.currentWineData.cases = payload
      })
      .addCase(requestRestockHistory.fulfilled, (state, { payload }) => {
        state.currentWineData.restockHistory = payload
      })
      .addCase(requestAddNewLabel.fulfilled, (state, { payload }) => {
        const newLabels = [ ...state.labels, payload ]
        state.labels = newLabels
      })
      .addCase(requestUpdateLabel.fulfilled, (state, { payload }) => {
        const { index, text } = payload;
        
        state.labels[index].text = text
      })
      .addCase(requestDeleteLabel.fulfilled, (state, { payload:labelId }) => {
        const newLabels = state.labels
          .slice()
          .filter(({ id }) => id !== labelId)

        state.labels = newLabels
      })
      .addCase(requestWineLabels.fulfilled, (state, { payload }) => {
        state.labels = payload
        state.dataIsFetched = true
      })
      .addCase(requestCurrentUserOrderedWines.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(requestWinesCatalog.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(requestWineSalesReport.fulfilled, (state, {payload}) => {
        state.currentWineData.sales = payload
      })
      .addCase(requestWineData.fulfilled, (state, { payload }) => {
        state.currentWineData = payload
        state.dataIsFetched = true
      })
      .addCase(requesCreatetOrderByWineId.fulfilled, () => {

      })
      .addCase(requestWinesPreviewList.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(requestWineCasesPreviewsList.fulfilled, (state, { payload }) => {
        state.currentWineData.cases = payload.cases
        state.currentWineData.regionCases = payload.regionCases
      })
      .addCase(requestWinePreviewData.fulfilled, (state, { payload }) => {
        state.currentWineData = payload
        state.dataIsFetched = true;
      })
      .addCase(requestUpdateUserWineStatistics.pending, (state, { meta }) => {
        if (meta?.arg?.wineId) {
          state.processingWineIds = [ ...state.processingWineIds, meta.arg.wineId ]
        }
      })
      .addCase(requestUpdateUserWineStatistics.fulfilled, (state, { payload }) => {
        const { amountBottlesDrunk, isFavourite, willRecomend, index } = payload
        if (payload?.index >= 0) {
          state.list[index].amountBottlesDrunk = amountBottlesDrunk;

          if (state.list[index]?.inventory > 0) {
            state.list[index].feedback = { alertIsVisible: true, alertContent: 'feedback' }
          }

          if (isFavourite !== undefined) {
            state.list[index].isFavourite = isFavourite
          }

          if (willRecomend !== undefined) {
            state.list[index].willRecomend = willRecomend
            state.list[index].feedback = { alertIsVisible: true, alertContent: 'alert' }
          }

          if (payload.hideFeedback) {
            state.list[index].feedback = { alertIsVisible: false, alertContent: '' }
          }

          state.processingWineIds = state.processingWineIds.filter(id => id !== state.list[index].id)
        }

        if (payload.indexes) {
          for (const ind of payload.indexes) {
            state.list[ind].isAdjusted = false;
          }
        }
      })
      .addMatcher(
        (action) => action.type.startsWith('@@wines') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@wines') && action.type.endsWith('/pending'),
        (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@wines') && action.type.endsWith('/fulfilled'),
        (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})


export const wineActions = {
  ...wineSlice.actions, requestCurrentUserFavouriteWines, requestWinesList, requestCurrentUserOrderedWines, requestUpdateUserWineStatistics, requesCreatetOrderByWineId, requestWinesPreviewList, requestWineCasesPreviewsList, requestWinePreviewData, requestWinesCatalog
}

export default wineSlice.reducer
