import React, { useEffect, useState } from 'react' 
import PropTypes from 'prop-types'
import { v4 as generateId } from 'uuid'
import { Modal, useModal } from 'react-simple-hook-modal'
import { useRequests } from '../../hooks'

import './style.scss'
import { Spinner } from '..'


const GenerateDescriptionDialog = ({ onSubmit, wines, initialPromt }) => {
  const [state, changeState] = useState({
    prompt: initialPromt || ``, aiDescription: ``, processingRequest: false, requestId: undefined
  })
  const [requests, changeRequests] = useState({})
  const { get, post } = useRequests();
  const { isModalOpen, openModal, closeModal } = useModal();


  const handleOpenModal = e => {
    e.preventDefault();

    if (wines?.length) {
      openModal();
    }
  }


  useEffect(() => {
    const winesData = wines?.reduce(
      (acc, { name, vintage, producer }) => {
        const wineName = `${ +vintage > 0 ? vintage : 'NV' } ${ producer } ${ name }`;
  
        acc.push(wineName)
  
        return acc;
      }, []
    ) || []
  
    const defaultPromt = `Write an article about a product which is made up of the wines ${ winesData.join(', ') }.  Include tasting notes and food pairings.`
    
    changeState(prev => ({ ...prev, prompt: defaultPromt }))
  }, [wines])

  const getGeneratedDescription = async (requestId) => {
    const response = await get({ url: `/api/wines/cases/generated-description/${requestId}` })

    if (response?.data?.success) {
      changeState(prev => ({ ...prev, aiDescription: response.data.description?.trim(), processingRequest: false }))
      changeRequests(prev => {
        const newObject = { ...prev }

        if (newObject[requestId] !== undefined) {
          delete newObject[requestId]
        }

        return newObject
      })
    } else {
      if (!requests[requestId] || requests[requestId] < 3) {
        changeRequests(prev => {
          const countRequests = prev[requestId] 
            ? prev[requestId] + 1
            : prev[requestId] === 0 ? 1 : 0

            return { ...prev, [requestId]: countRequests }
        })

        getGeneratedDescription(requestId)
      } else {
        changeState(prev => ({ ...prev, processingRequest: false, error: `Something went wrong...` }))
      }
    }
  }

  const handleGetAiDescription = async e => {
    e.preventDefault();
    const requestId = generateId()

    changeState(prev => ({ ...prev, processingRequest: true }))

    const response = await post({ 
      url: '/api/wines/cases/generate-description', 
      body: { prompt: state.prompt, logRequests: false, requestId },
      config: { timeout: 2 * 60 * 1000 }
    })

    if (response?.data?.success) {
      changeState(prev => ({ ...prev, requestId }))
      changeRequests(prev => ({ ...prev, [requestId]: 0 }))
      // changeState(prev => ({ ...prev, aiDescription: response.data.description?.trim(), processingRequest: false }))
      getGeneratedDescription(requestId)
    }
  }

  const handleChangeData = e => {
    const { name, value } = e.currentTarget;

    changeState(prev => ({ ...prev, [name]: value }))
  }

  const handleUseDescription = e => {
    e.preventDefault()
    
    onSubmit('longDescriptions', [{ tabName: 'en', text: state.aiDescription }], 'en')

    closeModal()
  }


  return (
    <>
      <button
        className="button"
        style={{ 
          backgroundColor: wines?.length === 0 ? '#A59D95' : '#A2905A', color: 'white', height: '48px', width: 'auto', alignSelf: 'flex-end', marginTop: '8px', borderRadius: '4px' 
        }}
        type="button"
        disabled={ wines?.length === 0 }
        onClick={handleOpenModal}
      >Generate</button>
      <Modal isOpen={isModalOpen} modalClassName="modal-dialog large">
        <form className="ai-description-form">
          <div className="form-control">
            <label htmlFor="prompt">Prompt</label>

            <textarea
              name="prompt" id="prompt" rows="6"
              disabled={ state.processingRequest }
              value={state.prompt}
              onChange={ handleChangeData }
            />

            <br />

            <button className="button generate"
              style={{ alignSelf: 'flex-end' }}
              onClick={handleGetAiDescription}
              disabled={ state.processingRequest || wines?.length === 0 }
              type="button"
            >Generate{ state.processingRequest && <Spinner spinnerClass="pending" size={30} /> }</button>
          </div>

          <br />

          <div className="form-control">
            <label htmlFor="aiDescription">Generated Description</label>

            <textarea 
              name="aiDescription" 
              id="aiDescription" rows="6" 
              disabled={ state.processingRequest }
              onChange={handleChangeData}
              value={ state.aiDescription }
            />
          </div>

          <br />
          <div className="form-control horizontal flex-center gap_sm">
            <button className="button button_outline"
              onClick={closeModal}
              type="button"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>

            <button className="button"
              disabled={ state.processingRequest }
              onClick={handleUseDescription}
              type="button"
            >Use Description</button>
          </div>
        </form>
      </Modal>
    </>
  )
}

GenerateDescriptionDialog.propTypes = {
  wines: PropTypes.shape({
    vintage: PropTypes.string,
    name: PropTypes.string,
    producer: PropTypes.string
  }),
  onSubmit: PropTypes.func
}

export default GenerateDescriptionDialog