import React, { useState } from 'react'; 
import PropTypes from 'prop-types';


const OrderBillingDetailsForm = ({ onSubmit, data }) => {
    const [formData, changeFormData] = useState({
        firstName: data?.firstName,
        lastName: data?.lastName,
        address: data?.address,
        state: data?.state,
        country: data?.country,
    });

    const handleFieldChange = e => {
        e.preventDefault();

        const { name, value } = e.target;

        changeFormData(prev => ({ 
            ...prev, [name]: value 
        }))
    }

    const handleSubmitForm = e => {
        e.preventDefault();

        onSubmit('billing', formData);
    }

    const { firstName, lastName, address, country, state } = formData;


    return (
        <form>
            <p className="form-section-title">Order Billing Details</p>
            
            <div className="form-control">
                <label htmlFor="firstName">First Name</label>

                <input
                    type="text" name="firstName"
                    value={ firstName }
                    onChange={ handleFieldChange }
                />
            </div>

            <div className="form-control">
                <label htmlFor="lastName">Last Name</label>

                <input
                    type="text" name="lastName"
                    value={ lastName }
                    onChange={ handleFieldChange }
                />
            </div>

            <div className="form-control">
                <label htmlFor="address">Address</label>

                <input
                    type="text" name="address"
                    value={ address }
                    onChange={ handleFieldChange }
                />
            </div>

            <div className="form-control">
                <label htmlFor="state">State</label>

                <input
                    type="text" name="state"
                    value={ state }
                    onChange={ handleFieldChange }
                />
            </div>

            <div className="form-control">
                <label htmlFor="country">Country</label>

                <input
                    type="text" name="country"
                    value={ country }
                    onChange={ handleFieldChange }
                />
            </div>

            <div className="form-control">
                <button 
                    className="button"
                    onClick={ handleSubmitForm }
                >Save Changing</button>
            </div>
        </form>
    )
}


OrderBillingDetailsForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        address: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string
    })
}

OrderBillingDetailsForm.defaultProps = {
    data: {
        firstName: '',
        lastName: '',
        address: '',
        country: '',
        state: ''
    }
}

export default OrderBillingDetailsForm
