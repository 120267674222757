import {
  SET_INITIAL_DATA, CHANGE_APPELLATIONS_WITH_IMPORTS, CHANGE_APPELLATIONS_WITH_NO_IMPORTS, CHANGE_FILTERS, SET_UPDATED_DATE
} from './serendipityWinesIntegrationActions'

const checkoutReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_DATA:
      return { ...state, ...payload }
    case CHANGE_APPELLATIONS_WITH_IMPORTS:
      return { ...state, withImports: payload }
    case CHANGE_APPELLATIONS_WITH_NO_IMPORTS:
      return { ...state, noImports: payload }
    case CHANGE_FILTERS:
      return { ...state, filters: payload }
    case SET_UPDATED_DATE:
      return { ...state, updatedDate: payload }
    default:
      return { ...state };
  }
}

export default checkoutReducer;
