import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const useRequests = () => {
  const [requestPending, setRequestPending] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [fetchingPageData, setFetchingPageData] = useState(false);
  const [errors, setErrors] = useState(null);
  const { i18n } = useTranslation();

  const createError = data => {
    if (!data) return setErrors(null);

    const { message, ...errorData } = data || {};

    const errorMessage = typeof message === 'object' ?
      message[i18n.language] : message;

    setErrors({ message: errorMessage, ...errorData });
  }

  useEffect(() => {
    if (pendingRequests === 0) {
      setRequestPending(false);
    } else {
      if (!requestPending) {
        setRequestPending(true);
      }
    }
    // eslint-disable-next-line
  }, [pendingRequests]);

  // if (['AUTH_TOKEN_EXPIRED', 'AUTH_INVALID'].includes(errors?.errorCode)) {
  //   createError(null);

  //   window.location.replace('/login');
  // } else 
  if (errors?.errorCode === 'APPLICATION_NOT_UPDATED') {
    createError(null);
    localStorage.setItem('requestsHook', 'redirecting')

    // throw new Error('APPLICATION_NOT_UPDATED')
    window.location.replace('/update');
    localStorage.setItem('referrer', document.location.href);
  }

  const clearErrors = () => createError(null);

  const get = async ({ url, config }) => {

    setPendingRequests(prev => prev + 1);
    try {

      clearErrors();
      const data = await axios.get(url, config);

      return data;

    } catch (err) {

      console.log('Get request error: ', err);

      createError(err.response.data);
      return err.response;
    } finally {
      setPendingRequests(prev => prev - 1);
    }

  }


  const handleSetFetchingPageData = state => setFetchingPageData(state);


  const post = async ({ url, body, config }) => {
    setPendingRequests(prev => prev + 1);
    try {

      clearErrors();
      const data = await axios.post(url, body, config);

      return data;
    } catch (err) {

      // console.log('ERROR in post request: ', err.response.data)
      createError(err.response.data);
      return err.response;
    } finally {
      setPendingRequests(prev => prev - 1);
    }

  }


  const del = async ({ url, config }) => {

    setPendingRequests(prev => prev + 1);
    try {

      clearErrors();
      const data = await axios.delete(url, config);

      return data;

    } catch (err) {

      // console.log('Delete request error: ', err);

      createError(err.response.data);
      return err.response;
    } finally {
      setPendingRequests(prev => prev - 1);
    }

  }

  const sendZipcodeReport = ({ firstName, lastName, zipCode }) => {
    const report = `<b>${ firstName } ${ lastName }</b> wanted to ship to <b>${zipCode}</b>`;
      const subject = `Vineify: Invalid Zipcode Requested`;

      post({
        url: `/api/content/error-report`,
        body: { subject, report }
      })
  }


  return { requestPending, errors, clearErrors, get, post, del, handleSetFetchingPageData, sendZipcodeReport, fetchingPageData }
}


export default useRequests;
