import { createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import * as UserService from '../../services/user.service'
import * as AnalyticsService from '../../services/analytics.service'
import { authActions } from './auth.slice'


export const login = createAsyncThunk(
  '@@auth/login',
  async ({ email, password, keepSigned, redirectPath }, thunkAPI) => {
    try {
      const loginData = await UserService.login({ email, password, keepSigned });

      if (loginData?.success) {
        // return {isAdult, redirectPath};
        const defaultRedirectPath = [
          'curator', 'admin', 'retailer', 'producer'
        ].includes(loginData?.role) ? `/${ loginData?.role }` : `/`

        return thunkAPI.dispatch(authenticate(redirectPath || defaultRedirectPath))
      } else {
        return { errors: { ...loginData?.errors, message: loginData?.message?.en || '' } };
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const logout = createAsyncThunk(
  '@@auth/logout', 
  async (redirectPath, thunkAPI) => {
    try {
      const data = await UserService.logout();

      // return {data, redirectPath};
      return thunkAPI.dispatch(authenticate(redirectPath || '/login'))
    } catch (err) {
      
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const authenticate = createAsyncThunk(
  '@@auth/authenticate',
  async (redirectPath, thunkAPI) => {
    try {
      const withGeolocation = thunkAPI.getState().auth.ip === undefined;

      const data = await UserService.authenticateUser(withGeolocation);
      const mxUser = localStorage.getItem('mxUser')

      if (data.user?.role !== 'guest') {
        if (+mxUser !== +data.user.id) {
          localStorage.setItem('mxUser', +data.user.id)
        }

        AnalyticsService.createMxUser(data.user)
        AnalyticsService.identifyMxUser(data.user.id)
      } else {
        if (mxUser) {
          AnalyticsService.identifyMxUser(mxUser)
        } else {
          const id = nanoid()
          localStorage.setItem('mxUser', id)
          AnalyticsService.createMxUser({ id })
          AnalyticsService.identifyMxUser(id)
        }
      }

      return {...data, redirectPath}; // {user, config, quizIsCompleted}
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestChangeLegalAge = createAsyncThunk(
  '@@user/request-change-legal-age',
  async ({isAdult, redirectPath}, thunkAPI) => {
    const { user: { email } } = thunkAPI.getState().auth
    
    try {
      await UserService.changeUserLegalAge({ isAdult, email })

      thunkAPI.dispatch(authActions.changeUserdata({ isAdult }))

      if (!thunkAPI.getState().auth?.isAuthenticated) {
        thunkAPI.dispatch(authenticate(redirectPath || '/'))
      } else {
        redirectPath && thunkAPI.dispatch(authActions.changeRedirectPath(redirectPath))
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestChangeAccountData = createAsyncThunk(
  '@@auth/request-change-account-data',
  async ({ email, firstName, lastName }, thunkAPI) => {
    try {
      const isChanged = await UserService.changeUserAccountData({ email, firstName, lastName })

      return isChanged ? {email, firstName, lastName} : {}
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestRegisterUser = createAsyncThunk(
  '@@user/request-register',
  async ({ device, email, firstName, lastName, location, password, zipcode, redirectPath }, thunkAPI) => {
    try {
      const registerData = await UserService.registerNewUser({ 
        device, email, firstName, lastName, location, password, zipcode 
      })

      if (registerData.success) {
        // sessionStorage.setItem('_cna', 1)

        !redirectPath && thunkAPI.dispatch(authenticate())
      }

      return { ...registerData, success: registerData.success, email, firstName, lastName, redirectPath};
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCurrentUserOrders = createAsyncThunk(
  '@@user/request-current-user-orders',
  async (_, thunkAPI) => {
    try {
      const cases = await UserService.getCurrentUserOrderedCases();
      
      return cases;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestAiSignIn = createAsyncThunk(
  '@@auth/request-ai-sign-in',
  async (password, thunkAPI) => {
    const data = await UserService.aiSignIn(password)

    if (data?.success === undefined) thunkAPI.rejectWithValue({ message: `Failed` })

    return data
  }
)

export const requestFollowCurator = createAsyncThunk(
  '@@auth/request-follow-curator',
  async ({curatorId, index}, thunkAPI) => {
    try {
      const {status, user} = await UserService.followCurator(curatorId)

      return {status, curatorId, index, user};
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
