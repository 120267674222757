import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRequests, useHelpers } from '../../hooks';

import heartFilledtIcon from '../../assets/icons/heart-filled.svg';
import { Spinner, Icon } from '..';

import plusIcon from '../../assets/icons/plus-circle.svg';
import checkIcon from '../../assets/icons/check.svg';


const CustomeCaseWineRow = ({
  id, isFavourite, name, image, vintage, producer, grape, category, onChooseWine, selected, shortName
}) => {
  const { t } = useTranslation();
  const { capitalize, getWineName } = useHelpers();
  const { requestPending } = useRequests();
  const [isPending, setPendingState] = useState(false);

  const handleChooseWine = async e => {
    setPendingState(true);

    await onChooseWine(id);

    setPendingState(false);
  }

  return (
    <div className="account-wine custom-case">
      <div className="account-wine__row">
        <div className="wine-like">
          {
            isFavourite &&
            // <img src={heartFilledtIcon} alt="favourite wine" />
            <Icon icon="heart-filled" color="warm-gray-9" size={30} />
          }
        </div>

        <div className="wine-image" >
          <img className="popover-image" src={image} alt={name} />
        </div>

        <div className="wine-info">
          <div className="wine-vintage">{ vintage > 0 ? vintage : 'NV' }</div>

          <div className="wine-name">{ shortName || getWineName({ vintage, producer, name }) }</div>

          <div className="wine-producer">{producer}</div>

          <div className={`wine-grape grape_${category || ''}`}>
            {grape?.split(',')[0] || ''}
          </div>
        </div>

        {
          selected ?
            <div className="wine-row-label-wrapper">
              <span className="label success">
                {/* <img src={ checkIcon } alt="" /> */}
                <Icon icon="check" color="alabaster" size={17} />
                
                { capitalize(t('SELECTED')) }
              </span>
            </div>
            :
            <button
              className="custom-case-wine__control-btn button button_outline"
              disabled={requestPending}
              onClick={handleChooseWine}
            >{
              isPending ?
                <span><Spinner /></span>
                :
                <img src={plusIcon} alt="show details" style={{ marginRight: '12px' }} />
            } { t('CASES_CUSTOM_CASE_ADD_WINE_BTN') }</button>
        }
      </div>
    </div>
  )
}


CustomeCaseWineRow.propTypes = {
  id: PropTypes.number,
  isFavourite: PropTypes.bool,
  name: PropTypes.string,
  image: PropTypes.string,
  vintage: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  producer: PropTypes.string,
  grape: PropTypes.shape({
    category: PropTypes.string
  }),
  sortOfGrape: PropTypes.string
}

export default memo(CustomeCaseWineRow);
