import React from 'react';
import PropTypes from 'prop-types';
import { Popover as Container, ArrowContainer } from 'react-tiny-popover';

import './style.scss';


const Popover = ({ isOpen, position, align, children, arrowColor, arrowSize, arrowClassName, notification, padding }) => {
  return (
    <Container
      isOpen={isOpen}
      padding={padding}
      positions={[position]}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            align={align}
            nudgedLeft={100}
            arrowColor={arrowColor}
            arrowSize={arrowSize}
            arrowClassName={arrowClassName}
          >
            <div className="popover-notification shadow">
              <p className="no-margin">{notification}</p>
            </div>
          </ArrowContainer>
        )
      }>{children}</Container>
  )
}

Popover.propTypes = {
  alighn: PropTypes.string,
  arrowColor: PropTypes.string,
  arrowClassName: PropTypes.string,
  notification: PropTypes.string,
  arrowSize: PropTypes.number,
  padding: PropTypes.number
}

Popover.defultProps = {
  align: 'end',
  arrowColor: 'white',
  arrowSize: 12,
  arrowClassName: "popover-arrow",
  padding: 0
}

export default Popover;
