import React from 'react';
import PropTypes from 'prop-types';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { ConfirmationDialog, Spinner } from '..';

import './style.scss';


const ButtonWithPopoverConfirmation = ({
  style, isOpen, position, btnText, confirmText, onConfirm, onCancel, disabled, onClick, pending, mainButtonClasses, confirmBtnColor, title, dialogClassname, cancelButtonLabel, confirmButtonLabel
}) => {
  return (
    <Popover
      isOpen={isOpen}
      padding={0}
      positions={[position]}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            align="end"
            nudgedLeft={100}
            arrowColor={'white'}
            arrowSize={12}
            arrowClassName="popover-arrow"
          >
            <ConfirmationDialog
              text={confirmText}
              confirmButtonLabel={confirmButtonLabel}
              cancelButtonLabel={cancelButtonLabel}
              className={ dialogClassname }
              confirmBtnColor={confirmBtnColor}
              title={ title }
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          </ArrowContainer>
        )
      }><button
        className={mainButtonClasses}
        style={style}
        disabled={disabled || isOpen || pending}
        onClick={onClick}
      >{btnText}{pending && <Spinner spinnerClass="pending-spinner" />}</button>
    </Popover>
  )
}


ButtonWithPopoverConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  position: PropTypes.string,
  btnText: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  mainButtonClasses: PropTypes.string,
  confirmBtnColor: PropTypes.string
}

ButtonWithPopoverConfirmation.defaultProps = {
  mainButtonClasses: 'button button_red button_xs'
}

export default ButtonWithPopoverConfirmation
