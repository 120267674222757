import React from 'react'
import { CardCarousel, MotivationCard } from '../../../components'
import { useMediaQuery } from '../../../hooks'
import { motivations } from '../../data'


const PublicHomeMotivationSection = () => {
  const { isMobile } = useMediaQuery()
  const motivationsContent = motivations.map(motivationData => {

    return <MotivationCard key={motivationData.title} {...motivationData} />
  })

  const navSliderConfig = { 
    itemsToShow: isMobile ? 0 : 2,
    // itemsToShow: 'auto',
    slidesToScroll: 1,
    speed: 500,
    centerPadding: '16px',
    infinite: true,
    showArrows: true,
    dots: true
  }

  const cardsContent = (
    <CardCarousel
      cards={motivationsContent}
      spaceBetween={ !isMobile ? 32 : 8 }
      name="Carousel Cards"
      // dots={ window.matchMedia('(max-width: 1024px)').matches }
      dots
      slideClassName="occasions-slider"
      infinite={ false }
      carouselClassName="w-100"
      withNavigation
      dynamicDots
      config={ navSliderConfig }
    />
  )


  return (
    <div className="public-home__section">
      <div className="container sm-p-32">
        <h2 className="public-home__title public-title-sm fw-400 line-breaks">
          Tell us <b>why you’re shopping</b> for wine,{`\n`}
          we’ll show you <b>our expertly curated collections</b>
        </h2>

        {/* <div className="motivations-list">{motivationsContent}</div> */}
        {cardsContent}

      </div>
    </div>
  )
}

export default PublicHomeMotivationSection
