import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRequests } from '../../hooks';
import { toast } from 'react-toastify';
import { Select, Spinner } from '..';


const UserPreferencesForm = ({ country: nativeCountry, shippingCountry: country, currency, language, userId, onSubmit, onCancel, countriesList, email, firstName, lastName }) => {
  const { post, requestPending } = useRequests();
  const [formData, changeFormData] = useState({
    nativeCountry: nativeCountry || '', country: country || '', language: language || '', currency: currency || '', firstName: firstName || '', lastName: lastName || '', email: email || ''
  });

  // const countriesOptions = countriesList?.map(country => ({ value: country, label: country }));
  const countriesOptions = Object.keys(countriesList)?.map(country => ({ value: country, label: country }));
  const languagesOptions = [
    { value: 'en', label: 'English' },
    { value: 'it', label: 'Italian' },
  ];
  const currenciesOptions = [
    { value: 'usd', label: 'Usd' },
    { value: 'eur', label: 'Eur' },
  ];

  const handleChangeField = data => {
    const { name, value } = data;

    changeFormData(
      prev => ({ ...prev, [name]: value })
    )
  }

  const handleChangeFormField = e => {
    const { name, value } = e.currentTarget

    changeFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const response = await post({ url: `/api/users/${userId}/preferences`, body: formData });

    if (response?.data?.success) {
      const { nativeCountry, country, language, currency, firstName, lastName, email } = formData;

      onSubmit && onSubmit({ country: nativeCountry, shippingCountry: country, currency, language, firstName, lastName, email })
    } else {
      toast.error('Could not save user preferences.')
    }
  }

  return (
    <form className="recommendation-form small">
      <div className="form-section-title">User Preferences</div>

      <div className="form-section wrap-section">
        <div className="form-control">
          <label htmlFor="firstName">First Name</label>

          <input
            name="firstName"
            onChange={handleChangeFormField}
            value={formData.firstName} type="text"
          />
        </div>

        <div className="form-control">
          <label htmlFor="lastName">Last Name</label>

          <input
            name="lastName"
            onChange={handleChangeFormField}
            value={formData.lastName} type="text"
          />
        </div>

        <div className="form-control">
          <label htmlFor="email">Email</label>

          <input
            name="email"
            onChange={handleChangeFormField}
            value={formData.email} type="text"
          />
        </div>
      </div>

      <div className="form-section">
        <div className="form-control flex-grow" style={{ marginTop: '46px' }}>
          <label htmlFor="nativeCountry">Origin Country:</label>

          <Select
            style={{ width: 'auto' }}
            options={countriesOptions}
            value={countriesOptions.find(({ value }) => value === formData.nativeCountry)}
            onChange={({ value }) => handleChangeField({ name: 'nativeCountry', value })}
          />
        </div>

        <div className="form-control flex-grow" style={{ marginTop: '46px' }}>
          <label htmlFor="country">Country:</label>

          <Select
            style={{ width: 'auto' }}
            options={countriesOptions}
            value={countriesOptions.find(({ value }) => value === formData.country)}
            onChange={({ value }) => handleChangeField({ name: 'country', value })}
          />
        </div>
      </div>

      <div className="form-section">
        <div className="form-control flex-grow" style={{ marginTop: '46px' }}>
          <label htmlFor="language">Language:</label>

          <Select
            style={{ width: 'auto' }}
            options={languagesOptions}
            value={languagesOptions.find(({ value }) => value === formData.language)}
            onChange={({ value }) => handleChangeField({ name: 'language', value })}
          />
        </div>

        <div className="form-control flex-grow" style={{ marginTop: '46px' }}>
          <label htmlFor="currency">Currency:</label>

          <Select
            style={{ width: 'auto' }}
            options={currenciesOptions}
            value={currenciesOptions.find(({ value }) => value === formData.currency)}
            onChange={({ value }) => handleChangeField({ name: 'currency', value })}
          />
        </div>
      </div>

      <div className="form-control horizontal flex-space-around" style={{ marginTop: '32px' }}>
        <button
          className="button button_sm button_outline"
          onClick={onCancel}
        >Cancel</button>

        <button
          className="button button_sm"
          disabled={requestPending}
          onClick={handleSubmit}
        >Save {requestPending && <Spinner />}</button>
      </div>
    </form>
  )
}

UserPreferencesForm.propTypes = {
  nativeCountry: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
  language: PropTypes.string,
  userId: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default UserPreferencesForm
