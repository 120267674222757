import { createContext } from 'react';

const CountryContext = createContext({
  currentCountry: undefined,
  charge: undefined,
  countryCode: undefined,
  shippingCountriesList: undefined,
  currency: undefined,
  countriesTranslations: undefined,
  regions: {},
  parentRegions: [],
  changeCountry: () => { },
  setShippingCountries: () => { },
  clearData: () => { },
  changeCountryData: () => { },
  changeCurrency: () => { },
  changeRegions: () => { },
  setParentRegions: () => { }
});


export default CountryContext;
