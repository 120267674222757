import React from 'react';
import PropTypes from 'prop-types';
import { v4 as keyGen } from 'uuid';
import { useHelpers } from '../../hooks';

import { ReportCard } from '..';


const WineSalesReportCard = ({ id, stock, name, bottlesSold, orders, vintage, producer }) => {
  const { formatDateTime } = useHelpers();
  const info = (
    <div className="flex-space-between"><span>{ bottlesSold } sold</span> / <span className={ stock < 10 && 'warning' }>{ stock } left</span></div>
  );

  const reportContent = orders.map(
    ({ id, caseName, quantity, customer, date }) => (
      <li key={ keyGen() }>
        <span className="id">{id}</span>
        <span className="quantity">{quantity}</span>
        <span className="name">{ caseName }</span>
        <span className="date">{ formatDateTime(date, false) }</span>
        <span className="customer">{ customer }</span>
      </li>
    )
  )

  return (
    <ReportCard id={id} name={`${vintage} ${producer} ${name}`} info={ info }>
      <ul className="sales-list">{ reportContent }</ul>
    </ReportCard>
  )
}



WineSalesReportCard.propTypes = {
  id: PropTypes.number,
  vintage: PropTypes.number,
  stock: PropTypes.number,
  name: PropTypes.string,
  producer: PropTypes.string,
  bottlesSold: PropTypes.number,
  orders: PropTypes.shape({
    id: PropTypes.number,
    caseName: PropTypes.string,
    quantity: PropTypes.number,
    customer: PropTypes.string,
    date: PropTypes.string
  })
}

export default WineSalesReportCard
