import { toast } from 'react-toastify';

const toastMiddleware = () => (next) => (action) => {
  if (action.type.endsWith('/fulfilled')) {
    action.meta.successMessage && toast.success(action.meta.successMessage);
  }

  if (action.type.endsWith('/rejected')) {
    action.meta.errorMessage && toast.success(action.meta.errorMessage);
  }

  return next(action);
};

export default toastMiddleware;