import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHelpers } from '../../hooks'
import WinesTypesSummary from '../common/WinesTypesSummary';
import ShortDescription from '../ShortDescription';
import { WineBottlesImages } from '..'

import './style.scss';


const CaseSliderCard = ({ id, slug, image, name, wines, region, regions, description, prices, category, caseDetailsId, stock, price, onPurchase, onViewCaseContent, isSoldOut, showBottles, processingRequest, cardId, isPreview }) => {
  const { createWinesTypes } = useHelpers();

  const winesCategories = createWinesTypes(wines)
  const casePrice = `$${prices?.usd || +price}`;
  const bottleImages = wines?.reduce(
    (acc, wineData) => {
      if (wineData.bottleImage) {
        for (let i = 0; i < wineData.quantity; i++) {
          acc.push(wineData.bottleImage)
        }
      }

      return acc;
    }, []
  ) || []


  const showGeneratedImage = useMemo(() =>
    !image || image.includes('1gallkbkt_default_case_image.jpg') || showBottles,
    [image, showBottles]
  )

  const caseImage = useMemo(() => showGeneratedImage
    ? `https://vineify-dev-files.s3.amazonaws.com/1gpacfocr_full_size_cases_default_card_image.jpg`
    : image,
    [showGeneratedImage]
  )

  const bottlesImagesListClass = useMemo(() =>
    bottleImages?.length > 6 ? 'chess-pattern' : undefined,
    [bottleImages]
  )

  const handleViewDetails = e => {
    e.preventDefault();

    const isMultiRegion = regions?.length > 1

    onViewCaseContent({ id, region, category, slug, isMultiRegion })
  }

  const handleBuyCase = e => {
    e.preventDefault();

    onPurchase({ id, stock, caseDetailsId, cost: prices?.usd || +price })
  }


  const shortDescription = <ShortDescription expandable={false} lines={3}
  ><p className="case-slider-card__description-text">{description}</p></ShortDescription>


  return (
    <div className={`case-slider-card${ isPreview ? ` compact` : `` }`} key={`ph-case-card-${id}`}>
      <span className="case-slider-card__price">{casePrice}</span>
      <div className="case-slider-card__image">
        <img src={caseImage} alt={name} />

        {
          showGeneratedImage &&
          <WineBottlesImages id={id} containerClass={bottlesImagesListClass} images={bottleImages} />
        }
      </div>


      <div className="case-slider-card__body">
        <div className="case-slider-card__title">
          <h3 className="case-slider-card__name">{name}</h3>

          <WinesTypesSummary {...winesCategories} />
        </div>

        {
          !isPreview && <>
            <div className="case-slider-card__description">{shortDescription}</div>

            <div className="case-slider-card__control">
              <button
                className="button button_outline"
                type="button"
                onClick={handleViewDetails}
              >View Details</button>

              {
                !isSoldOut &&
                <button
                  className="button"
                  disabled={processingRequest}
                  type="button"
                  onClick={handleBuyCase}
                >Buy Now</button>
              }

              {
                isSoldOut &&
                <span className="case-slider-card__souldout-label">Currently Sold Out</span>
              }
            </div>
          </>
        }
      </div>
    </div>
  )
}

CaseSliderCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  wines: PropTypes.array,
  description: PropTypes.string,
  id: PropTypes.number,
  processingRequest: PropTypes.bool
}

CaseSliderCard.defaultProps = {
  isPreview: false
}

export default memo(CaseSliderCard)