import axios from 'axios';
import Case from '../classes/Case';


export const createPayment = async ({ tokenData, shipping, billing, isGift, giftMessage, orderId }) => {
  // tokenData: { ...paymentToken, rid: ctx.cart.order.orderInfo.id }, ...contacts, isGift, giftMessage
  try {
    const response = await axios.post(
      `/api/orders/${orderId}/create-payment`,
      { tokenData, shipping, billing, isGift, giftMessage }
    );
  
    if (response?.data?.success) {
      // ctx.cart.shipping = { ...shipping };
      // ctx.user = { ...ctx.user, hasNewWines: true };
  
      const { orderId, orderPlaced, newUserData } = response.data;
  
      return { orderId, orderPlaced, newUserData }
    }
  } catch (err) {
    console.log('Place order err response: ', err?.message)

    throw err?.response?.data;
  }
}

export const addCaseToCart = async ({ caseId, caseDetailsId, cost, caseDetails: providedCaseDetails }) => {
  const data = { caseId, caseDetailsId, cost };

  const response = await axios.post(`/api/orders/`, data);

  if (response?.data?.success) {
    const caseDetails = providedCaseDetails
      ? JSON.stringify(providedCaseDetails)
      : undefined

    return { caseDetails, id: response?.data?.orderId }
  }
}

export const fetchUserOrder = async (language = 'en') => {
  try {
    const response = await axios.get(`/api/orders?lng=${language}&init=true&new=2`)

    if (response?.data?.success) {
      const { contacts, ...order } = response.data.orders[0] || {};

      if (!order) {
        return {}
      }

      const orderCase = Case.create(order.case);

      const warehouseData = orderCase.getWareHouseData();
      // setOrderWarehouse(warehouseData);

      return JSON.stringify({
        order, contacts: contacts || undefined, orderCase, warehouseData
      })
    }

    return {}
  } catch (err) {
    console.log('Error: ', err)

    return { error: err.message }
  }
}

export const addAdjustedCaseToCart = async ({ caseDetails: providedCaseDetails = {}, wines, caseId, descriptions, longDescriptions, names, cost, eur, usd }) => {
  const data = {
    ...providedCaseDetails,
    caseId,
    wines,
    names,
    descriptions: null,
    longDescriptions: null,
    cost, eur, usd
  }

  const response = await axios.post('/api/wines/cases/adjusted-case', data);

  if (response?.data?.success) {
    const caseDetails = providedCaseDetails
      ? JSON.stringify(providedCaseDetails)
      : undefined

    return { caseDetails, id: response?.data?.orderId }
  }
}

export const checkShippingZipcode = async zipCode => {
  const response = await axios.get(`/api/orders/shipping-check/${zipCode}`)

  if (response?.data?.success) {
    return response.data.orderInfo

    // setPaymentMethod(response.data.orderInfo?.default ? 'paypal' : 'square');
    // !zipCode && changeShippingZipcode(checkingZipcode)
    // changeRetailer(response.data.orderInfo)

    // changePaymentState(prev => ({ ...prev, shippingError: false, zipNotValid: false }))
    // changePaymentStep('shipping')
  }

  return {}
}

export const fetchPaymentInfo = async () => {
  const response = await axios.get(`/api/content/init-payments`);

  if (response?.data?.success) {
    const { ai: id, li: location } = response.data;

    // const squareWeb = _checkSquareScript();

    // !squareWeb && _loadSquareWebSdk(id);

    return { id, location }
  } else {
    return {}
  }
}
