import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import { useHelpers, useStoreAuth } from '../../hooks';
import Badge from '../badge/StatusBadge';


const AdminWineCard = ({
  name, vintage, id, image, stock, price, cost, costUsd, selected, onWineSelectToggle, onWineDelete, producer, currency, region, restricted, onInitChangeWineRestock, onInitChangeWinePrice, tastes, country, onShowWineCard, lowWineStock
}) => {
  const history = useHistory();
  const { getWineMarkupPrice } = useHelpers()
  const { authState: { user } } = useStoreAuth()
  const lowStock = lowWineStock || 10

  const isLowStock = stock > 0 && stock <= lowStock;
  const isOutOfStock = stock === 0;

  const stockBadge = isLowStock || isOutOfStock
    ? <Badge 
        className={classNames('stock-badge', {
          'low': isLowStock,
          'out': isOutOfStock
        })} 
        statusText={isLowStock ? `low stock` : `out of stock`} withoutIcon
      />
    : undefined


  const handleWineEdit = e => {
    e.preventDefault();

    !restricted && history.push(`/admin/wines/manage/${id}`);
  }

  const handleChangeWineStock = e => {
    e.preventDefault();
    if (user.role !== 'admin') return;

    onInitChangeWineRestock(id, stock)
  }

  const handleShowWineCard = e => {
    e.preventDefault();

    const wineDataToShow = {
      image, name, tastes, region, country
    }

    onShowWineCard(wineDataToShow)
  }

  const handleChangeWinePrice = e => {
    e.preventDefault();
    if (user.role !== 'admin') return;

    onInitChangeWinePrice({ id, name, cost, costUsd, producer, currency, region })
  }

  const classes = classNames(
    'admin-item-row',
    {
      'low-stock': isLowStock,
      'out-of-stock': isOutOfStock
    }
  );

  const winePrice = currency === 'usd'
    ? `$${user.curatorRole === 'guest' ? getWineMarkupPrice(costUsd) : costUsd}`
    : `€${user.curatorRole === 'guest' ? getWineMarkupPrice(cost) : cost}`;
  const corePath = ['admin', 'retailer', 'curator'].includes(user?.role) ? user?.role : `${user.role}s`


  return (
    <><div className={classes}>
      <button className="wine-id" onClick={handleShowWineCard}>{id}</button>

      <div className="wine-vintage">{+vintage > 0 ? vintage : 'NV'}</div>

      <div className="wine-producer">{producer}</div>
      {
        !restricted || ['admin', 'retailer', 'curator'].includes(user.role) ?
          <div className="wine-name"><Link to={`/${corePath}/wines/${id}`}>{name}</Link></div>
          :
          <div className="wine-name">{name}</div>

      }
      <div className="wine-region">{region}</div>

      {
        user?.role === 'admin' ? (
          <button
            className="wine-stock button-link not-underlined"
            onClick={handleChangeWineStock}
          >{stock}{stockBadge}</button>
        ) : (
          <div className="wine-stock">{stock}{stockBadge}</div>
        )
      }
      {
        !restricted &&
        <>
          <button
            className="wine-cost button-link"
            onClick={handleChangeWinePrice}
          >{winePrice}</button>

          <div className="wine-control">
            {
              user?.role === 'admin' &&
              <button
                className="button-link"
                onClick={handleWineEdit}
              >edit</button>
            }
          </div>
        </>
      }
      {
        user.role === 'curator' &&
        <div className="wine-cost button-link">{winePrice}</div>
      }
    </div>
    </>
  )
}


AdminWineCard.propTypes = {
  name: PropTypes.string,
  vintage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.number,
  image: PropTypes.string,
  selected: PropTypes.bool,
  onWineSelectToggle: PropTypes.func,
  onWineEdit: PropTypes.func,
  stock: PropTypes.number
}

AdminWineCard.defaultProps = {
  selected: false,
  onWineSelectToggle: () => { }
}

export default AdminWineCard;
