const CUSTOM_CASE_DEFAULT_DESCRIPTION = `A curated case made just for you. We've brought together your favorite and top recommended bottles we know you're sure to love.`;
const CUSTOM_CASE_DEFAULT_DESCRIPTION_IT = `Una custodia curata fatta apposta per te. Abbiamo riunito le tue bottiglie preferite e quelle consigliate che sicuramente amerai.`;
const CUSTOM_CASE_DEFAULT_LONG_DESCRIPTION = `A curated case made just for you. We've brought together your favorite and top recommended bottles we know you're sure to love. At Vineify, we're committed to facilitating your unique wine journey. Using your feedback and expert pairings by our regional curators, we have hand-selected these 12 bottles especially for you.`;
const CUSTOM_CASE_DEFAULT_LONG_DESCRIPTION_IT = `Una custodia curata fatta apposta per te. Abbiamo riunito le tue bottiglie preferite e quelle consigliate che sicuramente amerai. In Vineify, ci impegniamo a facilitare il tuo viaggio nel vino unico. Utilizzando il tuo feedback e gli abbinamenti esperti dei nostri curatori regionali, abbiamo selezionato a mano queste 12 bottiglie appositamente per te.`

class Case {

  constructor({ id, case_details_id = null, name, prices = { usd: 0, eur: 0 }, producer, category, price, description, longDescription, image, stock, wines = [], isActive, defaultName, isAdjusted, curator, region, warehouse, regions, isMixed, active }) {
    this.id = id;
    this.isMixed = isMixed;
    this.stock = stock;
    this.name = name;
    this.price = price;
    this.producer = producer;
    this.description = description;
    this.longDescription = longDescription;
    this.category = category;
    this.image = image;
    this.wines = wines;
    this.caseDetailsId = case_details_id;
    this.prices = prices;
    this.isActive = isActive;
    this.defaultName = defaultName;
    this.isAdjusted = isAdjusted;
    this.curator = curator;
    this.region = region;
    this.regions = regions;
    this.warehouse = warehouse;
    this.active = active;
  }


  getPrice = (providedCurrency, perBottleCharge = { usd: 0, eur: 0 }, preview = true, numOfBottles = 12, options) => {
    const warehouse = this?.wines[0]?.warehouse;
    const noCharge = Array.isArray(this?.wines) ?
      warehouse?.name?.startsWith('Serendipity') : false;
    const shippingPerBottleCharge = noCharge ?
      // { eur: 131.5 / 12, usd: 155 / 12 } : perBottleCharge
      { eur: 0, usd: 0 } : perBottleCharge

    const currency = warehouse?.currency || providedCurrency;
    const shippingCharge = shippingPerBottleCharge[currency] * numOfBottles;
    let currencySymbol = '';
    switch (currency.toLowerCase()) {
      case 'eur':
        currencySymbol = '€';
        break;
      default:
        currencySymbol = '$';
        break;
    }

    const price = (+this.prices[currency.toLowerCase()] + shippingCharge).toFixed(2);

    const currencyOutput = options?.withPaypalTax ? price * 8.25 / 100 : price;
    return preview ? currencySymbol + currencyOutput : +currencyOutput;
  }


  getWareHouseData = () => Array.isArray(this?.wines) 
    ? this?.wines?.find(wineData => wineData?.warehouse)?.warehouse 
    : undefined;


  static create = (caseData) => {
    const { wines: winesList, ...otherData } = caseData;
    let wines = winesList?.every(wine => !!wine)
      ? winesList.map(
        position => {
          const { ...wineData } = position;
          return {
            quantity: position.quantity,
            wine_order: position.wine_order,
            ...wineData,
          }
        }
      ) : [];

    const newCaseData = { ...otherData, wines };
    return new Case(newCaseData)
  }

  sortWines = function () {
    Array.isArray(this.wines) &&
      this.wines.sort((wine, prevWine) => {

        return +wine.wine_order - +prevWine.wine_order
      });
  }

  static sortCasesByPrice = cases => {
    if (!cases || !Array.isArray(cases)) return cases;

    const newCases = [...cases];

    if (newCases.every(caseData => +caseData?.prices?.usd > 0)) {
      newCases.sort(({ prices: { usd: currentPrice } }, { prices: { usd: prevPrice } }) => {
        return +currentPrice - +prevPrice;
      })
    } else {
      newCases.sort(({ price: currentPrice }, { price: prevPrice }) => {
        return +currentPrice - +prevPrice;
      })
    }

    return newCases;
  }


  static getDefaultDescription = descType => {
    return descType === 'long' ?
      CUSTOM_CASE_DEFAULT_LONG_DESCRIPTION
      :
      CUSTOM_CASE_DEFAULT_DESCRIPTION
  }

  static getDefaultMultiLanguageDescription = descType => {
    return descType === 'long' ?
      [{ tabName: 'en', text: CUSTOM_CASE_DEFAULT_LONG_DESCRIPTION },
      { tabName: 'it', text: CUSTOM_CASE_DEFAULT_LONG_DESCRIPTION_IT }]
      :
      [{ tabName: 'en', text: CUSTOM_CASE_DEFAULT_DESCRIPTION },
      { tabName: 'it', text: CUSTOM_CASE_DEFAULT_DESCRIPTION_IT }]
  }
}


export default Case;
