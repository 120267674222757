import React from 'react';
import PropTypes from 'prop-types';
import { v4 as keyGen } from 'uuid';
import { Icon } from '..'

const CuratorNotes = ({ notes }) => {
  if (!notes.length) return null;

  const notesContent = notes?.reduce(
    (acc, noteData) => {
      if (noteData && noteData.text?.length > 0) {
        const noteRecord = (
          <li key={ keyGen() }><Icon icon={noteData.icon} color="white-wine" size={33} />{noteData.text}</li>
        )

        acc.push(noteRecord)
      }

      return acc;
    }, []
  );

  return (
    <ul className="region-features">
      {/* {
        notes.map(({ icon, text }) => (
          // <li key={ keyGen() }><img src={icon} alt="icon" />{text}</li>
          <li key={ keyGen() }><Icon icon={icon} color="white-wine" size={33} />{text}</li>
        ))
      } */}
      {notesContent}
    </ul>
  )
}

CuratorNotes.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string
    })
  )
}

export default CuratorNotes;
