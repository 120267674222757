import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import { useRequests } from '../../hooks';
import classNames from 'classnames';


const ImportWinesButton = ({ label, buttonClass, onChange, warehouse }) => {
  const fileRef = useRef();
  const { errors, post, clearErrors } = useRequests();
  const [url, changeUrl] = useState();
  const [vintage, changeVintage] = useState();
  const [processingImportWines, setProcessingImportWines] = useState(false);
  const [importWinesMethod, setImportWinesMethod] = useState('all');

  const handleBrowseFile = () => {
    fileRef.current.click();
  }

  const handleImportWines = async e => {
    setProcessingImportWines(true);

    const file = e.currentTarget.files[0];
    const [importMethod, ] = importWinesMethod.split('-');

    const query = importMethod !== 'single' 
      ? ``
      : `?url=${url}&vintage=${vintage}`;
    
    const endpoint = `/api/wines/import${ query }`;
    const body = new FormData();

    body.append('wines', file);
    body.append('warehouse', warehouse?.value)

    const response = await post({ url: endpoint, body });

    if (response?.data?.success) {
      toast.success(response.data.message);
    } else {
      response?.data?.error && toast.error(response.data.error)
    }

    fileRef.current.value = null;
    setProcessingImportWines(false);
  }

  const handleChangeUInput = field => e => {
    e.preventDefault();
    clearErrors();

    const { value } = e.currentTarget;

    field === 'url' && changeUrl(value);
    field === 'vintage' && changeVintage(value);
  }


  return (
    <>
      <div style={{ alignSelf: 'flex-start', marginRight: '64px' }}>
        <button
          className="button button_outline"
          disabled={!warehouse || processingImportWines}
          onClick={handleBrowseFile}
        >{label}</button>

        <small style={{ textAlign: 'center', display: 'inline-block', padding: '0 32px' }}>{warehouse ? `Warehouse: ${warehouse.label}` : `Select warehouse`}</small>

        <input ref={fileRef} type="file" onChange={handleImportWines} hidden />
      </div>

      <div style={{ flexGrow: 1, gap: '32px', marginTop: '32px' }}>
        <div
          className="form-control"
          style={{ flexDirection: 'column', justifyContent: 'space-around', flexGrow: 1, width: '100%' }}
        >
          <label className="radio-button no-margin">
            All
            <input
              name="file_import_method" type="radio" value="all"
              checked={importWinesMethod === 'all'}
              onChange={() => setImportWinesMethod('all')}
            />
            <span className="checkcircle"></span>
          </label>

          <div className={classNames('form-control radio-horizontal no-margin',
            { 'invalid': errors?.errorCode === 'WINE_URL_NOT_FOUND' })
          }>
            <div className="flex-space-between">
              <label className="radio-button no-margin">
                Single By URL
                <input
                  name="file_import_method" type="radio" value="single-url"
                  checked={importWinesMethod === 'single-url'}
                  onChange={() => setImportWinesMethod('single-url')}
                />
                <span className="checkcircle"></span>
              </label>

              <div className="w-100 form-control horizontal no-margin">
                <label htmlFor="url">URL</label>

                <input style={{ width: '100%', fontSize: '14px' }}
                  type="text" title={url}
                  name="url"
                  id="url"
                  disabled={importWinesMethod !== 'single-url'}
                  value={importWinesMethod === 'single-url' ? url || '' : ''}
                  onChange={handleChangeUInput('url')}
                />

                <label htmlFor="vintage">Vintage</label>

                <input style={{ width: '100%', fontSize: '14px' }}
                  type="text"
                  name="vintage"
                  id="vintage"
                  disabled={importWinesMethod !== 'single-url'}
                  value={importWinesMethod === 'single-url' ? vintage || '' : ''}
                  onChange={handleChangeUInput('vintage')}
                />

                {
                  errors?.errorCode === 'WINE_URL_NOT_FOUND' &&
                  <span className="error-msg-block up" style={{ position: 'absolute', width: 'max-content', transform: 'translateY(150%)' }}>{errors.message}</span>
                }
              </div>
            </div>
          </div>

          {/* <div className={classNames('form-control radio-horizontal no-margin',
            { 'invalid': errors?.errorCode === 'WINE_VINTAGE_NOT_FOUND' })
          }>
            <div className="flex-space-between">
              <label className="radio-button no-margin">
                Single By Vintage
                <input
                  name="file_import_method" type="radio" value="single-vintage"
                  checked={importWinesMethod === 'single-vintage'}
                  onChange={() => setImportWinesMethod('single-vintage')}
                />
                <span className="checkcircle"></span>
              </label>

              <div className="w-100">
                <label htmlFor="vintage">Vintage</label>

                <input style={{ width: '100%', fontSize: '14px' }}
                  type="text"
                  name="vintage"
                  id="vintage"
                  disabled={importWinesMethod !== 'single-vintage'}
                  value={importWinesMethod === 'single-vintage' ? vintage || '' : ''}
                  onChange={handleChangeUInput('vintage')}
                />

                {
                  errors?.errorCode === 'WINE_VINTAGE_NOT_FOUND' &&
                  <span className="error-msg-block up" style={{ width: 'max-content', transform: 'translateY(-90%)' }}>{errors.message}</span>
                }
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

ImportWinesButton.propTypes = {
  label: PropTypes.string,
  buttonClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

ImportWinesButton.defaultProps = {
  label: 'Import CSV',
  buttonClass: 'button button_outline',
  disabled: false
}


export default ImportWinesButton;
