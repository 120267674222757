import React from 'react'
import PropTypes from 'prop-types'

const IwsPriceDetails = ({ exchangeRate, curatorFee, paymentProcessor, shippingCostValue, totalWinesCost, totalWinesCostUsd, margin }) => {

  const curatorFeeValues = {
    usd: +totalWinesCostUsd * +curatorFee,
    eur: +totalWinesCost * +curatorFee
  };
  const paymentProcessorValues = {
    usd: totalWinesCostUsd > 0
      ? (+totalWinesCostUsd + curatorFeeValues.usd + shippingCostValue) * +paymentProcessor : 0,
    eur: totalWinesCost > 0
      ? (+totalWinesCost + curatorFeeValues.eur + shippingCostValue) * +paymentProcessor : 0,
  }
  const marginValues = {
    usd: +totalWinesCostUsd > 0
      ? ((+totalWinesCostUsd + curatorFeeValues.usd + shippingCostValue) * (+paymentProcessor + 1)) * +margin : 0,
    eur: +totalWinesCost > 0
      ? ((+totalWinesCostUsd + curatorFeeValues.eur + shippingCostValue) * (+paymentProcessor + 1)) * +margin : 0,
  }


  return (
    <>
      <li>
        <span>Exchange Rate (€ / $)</span>
        <span>{exchangeRate?.toFixed(2) || '0.00'}</span>
      </li>

      <li>
        <span>Curator Fee ({+curatorFee * 100}%)</span>
        <span>€{(curatorFeeValues.eur)?.toFixed(2) || 0} / ${(curatorFeeValues.usd)?.toFixed(2) || 0}</span>
      </li>

      <li>
        <span>Payment Processor ({+paymentProcessor * 100}%)</span>
        <span>€{(+paymentProcessorValues.eur)?.toFixed(2) || 0} / ${(+paymentProcessorValues.usd)?.toFixed(2) || 0}</span>
      </li>

      <li>
        <span>Margin Value ({+margin * 100}%)</span>
        <span>€{(marginValues.eur)?.toFixed(2) || 0} / ${(marginValues.usd)?.toFixed(2) || 0}</span>
      </li>
    </>
  )
}

IwsPriceDetails.propTypes = {
  exchangeRate: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  curatorFee: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  paymentProcessor: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  margin: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  shippingCostValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  totalWinesCost: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  totalWinesCostUsd: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
}

export default IwsPriceDetails