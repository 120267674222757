import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { Alert, Select, Spinner } from '..';


const ButtonWithVerificationDialog = ({
  id, isOpen, position, disabled, onClick, pending, btnText, mainButtonClass,
  onSelect, options, dialogTitle, containerClassName, notification, warehouses, warehousesList
}) => {
  const initialWarehouse = warehousesList.find(({ name }) => name?.toLowerCase().startsWith('serendipity'))
  const [isOpened, setOpenedState] = useState(false);
  const [selectedWarehouses, changeSelectedWarehouses] = useState(warehouses || initialWarehouse ? [+initialWarehouse.id] : []);
  const [language, changeLanguage] = useState('en');

  const handleSelectValue = option => {
    changeLanguage(option.value);
  }

  const handleChangeSelectedWarehouses = e => {
    const checkbox = e.currentTarget;
    const warehouseId = +e.target.name?.split('_')[1];

    changeSelectedWarehouses(prev => {

      return !checkbox.checked
        ? prev.filter(whId => whId !== warehouseId)
        : [...prev, warehouseId]
    })
  }

  const handleOpenDialog = e => {
    e.preventDefault();

    setOpenedState(true);
    onClick && onClick();
  }

  const handleCloseDialog = e => {
    e.preventDefault();

    setOpenedState(false);
  }

  const handleSubmitData = e => {
    e.preventDefault();

    onSelect(id, language, selectedWarehouses);
    setOpenedState(false);
  }

  const warehousesCheckboxes = warehousesList.map(warehouseData => {

    return (
      <div className="form-control" key={`warehouse_${warehouseData.id}`}>
        <label className="checkbox-wrapper"> {warehouseData.name}

          <input
            type="checkbox"
            name={`warehouse_${warehouseData.id}`}
            value={selectedWarehouses.includes(warehouseData.id)}
            checked={selectedWarehouses.includes(warehouseData.id)}
            onChange={handleChangeSelectedWarehouses}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    )
  })


  return (
    <Popover
      isOpen={isOpened}
      containerClassName={containerClassName}
      padding={0}
      positions={[position]}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            align="end"
            nudgedLeft={100}
            arrowColor={'white'}
            arrowSize={12}
            arrowClassName="popover-arrow"
          >
            <div className="confirmation-dialog shadow" style={{ flexDirection: 'column' }}>
              <h4 className="confirmation-dialog__title">{dialogTitle}</h4>

              {
                notification &&
                <Alert className="warning text-bold" text={notification} />
              }

              <div className="confirmation-dialog__control">
                <Select
                  options={options}
                  value={options.find(({ value }) => value === language)}
                  onChange={handleSelectValue}
                />
              </div>

              <div className="flex-space-between">{warehousesCheckboxes}</div>

              <div className="flex-space-between">
                <button
                  className="button button_outline button_xs"
                  onClick={handleCloseDialog}
                >Close Dialog</button>

                <button
                  className="button button_xs"
                  onClick={handleSubmitData}
                >Send Verification</button>
              </div>
            </div>
          </ArrowContainer>
        )
      }><button
        className={mainButtonClass}
        disabled={disabled || isOpened || pending}
        onClick={handleOpenDialog}
      >{pending ? <Spinner /> : btnText}</button>
    </Popover>
  )
}


ButtonWithVerificationDialog.propTypes = {
  isOpen: PropTypes.bool,
  position: PropTypes.string,
  btnText: PropTypes.string,
  notification: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  mainButtonClasse: PropTypes.string,
  dialogTitle: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func
}

ButtonWithVerificationDialog.defaultProps = {
  mainButtonClass: 'button button_xs',
  containerClassName: 'popover-dialog'
}

export default ButtonWithVerificationDialog
