import React, { useState } from 'react'; 
import { ButtonWithPopoverConfirmation } from '..'; 
import { useRequests } from '../../hooks';
import { toast } from 'react-toastify';


const EmailsCleanButton = ({ records, onClick }) => {
  const [confirmationIsOpened, changeConfirmationOpenState] = useState(false);
  const { get, requestPending } = useRequests();

  const handleCleanEmails = async e => {
    e.preventDefault();

    const response = await get({ url: '/api/users/clean-emails' });

    if (response?.data?.success) {
      toast.success('Emails were successully changed');
      changeConfirmationOpenState(false);

      onClick();
    } else {
      toast.error('Something went wrong');
    }
  }


  return (
    <ButtonWithPopoverConfirmation
      btnText={`Clean Emails (found: ${records})`}
      disabled={!records}
      mainButtonClasses="report-link"
      isOpen={confirmationIsOpened}
      confirmText="Emails of all registered users will be set to 'noreply@vineify.com'. Are you sure?"
      onConfirm={handleCleanEmails}
      onCancel={() => changeConfirmationOpenState(false)}
      pending={requestPending}
      onClick={() => changeConfirmationOpenState(true)}
      position="bottom"
    />
  )
}


export default EmailsCleanButton;
