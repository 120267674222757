import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as keyGen } from 'uuid';
import { CustomeCaseWineRow, Select, Alert } from '..';
import { useHelpers, useScreenSize } from '../../hooks';

import searchIcon from '../../assets/icons/search.svg';
import closeIcon from '../../assets/icons/plus-circle.svg'; 

import './style.scss';


const WinesList = ({ onChooseWine, wines: winesList, onClose, selectedList, region, regions }) => {
  const { t } = useTranslation();
  const { screenWidth } = useScreenSize();
  const { sortListOfObjectByField, capitalize } = useHelpers();
  const [state, changeState] = useState({
    wines: winesList, search: '', 
    // region: regions?.length === 1 ? region : 'All', 
    region: 'All', 
    notificationIsShown: false
  });

  const [sortMethod, changeSortedMethod] = useState(['name', 'asc', 'string']);

  const currentWarehouse = winesList.find(({ id }) => id === selectedList[0])?.warehouse;

  const winesRegions = winesList?.reduce(
    (acc, { region }) => {
      if (!acc.includes(region)) {
        acc.push(region)
      }

      return acc;
    }, []
  )

  const { wines, search } = state;

  const handleChangeSearchString = e => {
    e.preventDefault();

    const { value } = e.target;

    changeState(
      prev => ({
        ...prev,
        search: value
      })
    );
  }

  const handleChangeNotificationState = state => e => {
    e.preventDefault();
    if (regions?.length > 1) return false;

    changeState(prev => ({ ...prev, notificationIsShown: state }))
  }

  const regionsOptions = [
    { value: 'All', label: capitalize(t('all')) },
    // ...regions
    ...winesRegions.map(region => ({ value: region, label: region }))
  ]

  const sortOptions = [
    { value: 'isFavourite_asc_number', label: 'favorite asc' },
    { value: 'isFavourite_desc_number', label: 'favorite desc' },
    { value: 'grape_asc_string', label: 'grape a-z' },
    { value: 'grape_desc_string', label: 'grape z-a' },
    { value: 'name_asc_string', label: 'name a-z' },
    { value: 'name_desc_string', label: 'name z-a' },
    { value: 'producer_asc_string', label: 'producer a-z' },
    { value: 'producer_desc_string', label: 'producer z-a' },
    { value: 'vintage_asc_number', label: 'vintage asc' },
    { value: 'vintage_desc_number', label: 'vintage desc' }
  ]

  const handleFilterWines = e => {
    if (e.target.name === 'search-input' && e.keyCode !== 13) return;

    changeState(
      prev => {
        const searchValue = prev.search?.trim()?.toLowerCase()

        const filteredWines = searchValue.length > 0 ? winesList.filter(
          ({ name, producer, vintage }) => (
            name.toLowerCase().includes(searchValue) ||
            producer.toLowerCase().includes(searchValue) ||
            vintage.toString().includes(searchValue)
          )
        ) : winesList;

        return { ...prev, wines: filteredWines }
      }
    )
  }

  const handleChangeRegion = region => {
    changeState(
      prev => ({
        ...prev, region
      })
    )
  }

  const handleClearSearch = e => {
    e.preventDefault();

    changeState(prev => ({ ...prev, search: '' }));
    handleFilterWines(e)
  }

  const handleChangeSortMethod = ({ value:data }) => {
    const newSortedMethod = data.split('_')
    const [propName, order, fieldType] = newSortedMethod;

    const sortedWinesList = sortListOfObjectByField(state.wines, propName, {
      order, fieldType
    });

    changeSortedMethod(newSortedMethod)
    changeState(
      prev => ({
        ...prev, wines: sortedWinesList
      })
    );
  }

  const handleSortWines = e => {
    const {
      name: propName, order, type: sortType
    } = e.target.dataset;

    const sortOrder = !order ?
      'asc' : order === 'asc' ?
        'desc' : 'asc';

    e.target.dataset.order = sortOrder;


    const sortedWinesList = sortListOfObjectByField(state.wines, propName, {
      order: sortOrder, fieldType: sortType
    });

    changeState(
      prev => ({
        ...prev, wines: sortedWinesList
      })
    );
  }


  const winesRows = useMemo(() => {
    return wines.filter(
      ({ region, warehouse }) => {
        const regionFilter = state?.region === 'All' || region?.toLowerCase() === state?.region?.toLowerCase();
        // const warehouseFilter = selectedList?.length > 0 ? 
        //   currentWarehouse && warehouse === currentWarehouse : true
  
        // return warehouseFilter && regionFilter;
        return regionFilter;
      }
    ).map(
      wine => {
  
        return (
          <CustomeCaseWineRow
            {...wine} key={keyGen()}
            selected={selectedList.includes(wine.id)}
            onChooseWine={onChooseWine}
            shortName={wine.name}
          />
        )
      }
    )
  }, [state?.region, state.wines]);

  

  const headers = screenWidth > 520 ? (
    <div className="custom-case-wines-header">
      <button
        className="wine-like button-sort sort-color-black" data-name="isFavourite" data-type=""
      >{capitalize(t('FAVORITE'))}</button>

      <button
        className="wine-vintage button-sort sort-color-black"
        data-name="vintage" data-type="number"
      >{capitalize(t('VINTAGE'))}</button>

      <button
        className="wine-name button-sort sort-color-black"
        data-name="name" data-type="string"
      >{capitalize(t('NAME'))}</button>

      <button
        className="wine-producer button-sort sort-color-black"
        data-name="producer" data-type="string"
      >{capitalize(t('PRODUCER'))}</button>

      <button
        className="wine-grape button-sort sort-color-black"
        data-name="grape" data-type="string"
      >{capitalize(t('GRAPE'))}</button>

      <div 
        className="select-wrapper" 
        onMouseOut={ handleChangeNotificationState(false)} 
        onMouseOver={ handleChangeNotificationState(true) }
        ><Select
          name="regions"
          style={{ width: '170px', marginLeft: 'auto' }}
          // disabled={!wines.length || region}
          
          placeholder="select region" 
          options={regionsOptions}
          onChange={value => handleChangeRegion(value.value)}
          value={{ value: state.region, label: state.region }}
        /></div>
    </div>
  ) : (
    <div className="sort-list">
      <Select
        name="wineSort"
        disabled={!wines.length}
        placeholder="sorting wines" options={sortOptions}
        onChange={handleChangeSortMethod}
        value={{
          value: `${sortMethod[0]}_${sortMethod[1]}_${sortMethod[2]}`,
          label: sortOptions.find(
            ({ value }) => value === sortMethod.join('_')
          ).label
        }}
      />
    </div>
  )

  return (
    <div className="custom-case-wines-list">
      <button className="close-btn" onClick={onClose}
      ><img src={closeIcon} alt="" /></button>
      <div className="custom-case-wines-title text-center">
        <h3 className="no-margin no-padding">{t('CASES_CUSTOM_CASE_WINES_LIST_TITLE')}</h3>
      </div>

      <div className="search-wrapper">
        <div className="custom-case-wines__search-bar">
          <input
            placeholder="Search"
            name="search-input"
            onKeyUp={handleFilterWines}
            onChange={handleChangeSearchString}
            value={search}
          />

          <button
            className="search-button"
            onClick={handleFilterWines}
          ><img src={searchIcon} alt="" />
          </button>
        </div>

        <button
          className="button"
          onClick={handleClearSearch}
        // >{ t('CLEAR') }</button> TODO: add translation
        >Clear</button>
      </div>

      <div
        onClick={handleSortWines}
      >{headers}</div>

      <div className="custom-case-wines">{
        winesRows.length > 0 ?
          winesRows
          :
          <Alert className="alert-info">
            <p style={{ fontSize: '20px', fontWeight: 600 }}>{t('CASES_CUSTOM_CASE_WINES_NOT_FOUND')}</p>
          </Alert>
      }</div>
    </div>
  )
}


WinesList.propTypes = {
  onChooseWine: PropTypes.func,
  wines: PropTypes.array,
  region: PropTypes.string
}

WinesList.defaultProps = {
  wines: []
}

export default memo(WinesList)
