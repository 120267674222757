import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../common/Icon';
import { Popover, ArrowContainer } from 'react-tiny-popover';


const InfoButtonWithPopover = ({ children, initialOpenState, position, disabled, pending }) => {
  const [isOpened, setIsOpened] = useState(initialOpenState || false);

  const handleOpenDialog = e => {
    e.preventDefault();

    setIsOpened(prev => !prev);
  }


  return (
    <Popover
      isOpen={isOpened}
      containerClassName=""
      padding={0}
      positions={[position]}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            align="end"
            nudgedLeft={100}
            arrowColor={'white'}
            arrowSize={12}
            arrowClassName="popover-arrow"
          >{ children }</ArrowContainer>
        )
      }><button
        className="button-link"
        disabled={disabled || pending}
        onClick={handleOpenDialog}
      ><Icon icon="info-circle" size={20} color="vineify-green" /></button>
    </Popover>
  )
}


InfoButtonWithPopover.propTypes = {
  initialOpenState: PropTypes.bool
  , position: PropTypes.string
  , disabled: PropTypes.bool
  , pending: PropTypes.bool
}

InfoButtonWithPopover.defaultProps = {
  position: 'bottom',
  pending: false,
  initialOpenState: false,
  disabled: false
}

export default memo(InfoButtonWithPopover)