import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useActions, useHelpers } from '../../hooks';
import { CountryContext } from '../../context/country';
import Icon from '../common/Icon';

import logo from '../../assets/icons/logo-h-green-3x.svg';

import './style.scss';


const RegisterFooter = () => {
  const dispatch = useDispatch();
  const { authActions } = useActions();
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const { regions } = useContext(CountryContext);
  const { t, i18n } = useTranslation();
  const { capitalize, filterObject } = useHelpers();

  const handleLogout = e => {
    e.preventDefault();

    // logout(history);
    dispatch(authActions.logout('/login'))
  }


  const filterFunc = objKey => objKey['hasParent'];

  const regionsLinks = Object.keys(filterObject(regions, filterFunc))?.map(
    region => (
      <li className="links__item" key={region}>
        <Link to={`/regions/${region}`}>{regions[region] ? regions[region][i18n.language] : ''}</Link>
      </li>
    )
  )

  return (
    <footer className="reg-footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo-section">
            <img className="footer-logo" src={logo} alt="logo" />

            <div className="footer-social-links">
              <a className="social-link" target="_blank"
                href="https://www.facebook.com/VineifyWine"
              ><Icon icon="facebook" color="vineify-green" size={20} /></a>

              <a className="social-link" target="_blank"
                href="https://www.instagram.com/vineifywine/"
              ><Icon icon="instagram" color="vineify-green" size={20} /></a>
            </div>
          </div>
          <div className="footer-links">
            <ul className="links">
              <li className="links__title">{capitalize(t('HOME'))}</li>
              {
                isAuthenticated &&
                <><li className="links__item">
                  <Link to="/recommendations">{capitalize(t('RECOMMENDATIONS'))}</Link>
                </li>
                  <li className="links__item">
                    <Link to="/my-wines" >{capitalize(t('TRACK_INVENTORY'))}</Link>
                  </li>
                </>
              }
              <li className="links__item">
                <Link to="/wine-collections" >Collections</Link>
              </li>

              <li className="links__item">
                    <Link to="/tastings" >Tastings</Link>
                  </li>

              <li className="links__item">
                <Link to="/feedback" >{capitalize(t('CONTACT_US'))}</Link>
              </li>
            </ul>
          </div>

          <div className="footer-links">
            <ul className="links">
              <li className="links__title">{capitalize(t('REGIONS'))}</li>

              {regionsLinks}
            </ul>
          </div>

          <div className="footer-links">
            <ul className="links">
              <li className="links__title">{capitalize(t('ACCOUNT'))}</li>
              {
                isAuthenticated &&
                <>
                  <li className="links__item">
                    <Link to="/account/orders">{capitalize(t('MY_ORDERS'))}</Link>
                  </li>
                  <li className="links__item">
                    <Link to="/account/settings">{capitalize(t('ACCOUNT_SETTINGS'))}</Link>
                  </li>
                  {
                    user.role === 'admin' &&
                    <li className="links__item">
                      <Link to="/admin">Admin Panel</Link>
                    </li>
                  }
                  <li className="links__item">
                    {/* <Link to="/" className="Link-link" onClick={ handleLogout }>Sign Out</Link> */}
                    <Link to="/" className="Link-link" onClick={handleLogout}>Sign Out</Link>
                  </li></>
              }
              {
                !isAuthenticated &&
                <>
                  <li className="links__item">
                    <Link to="/create-account">Create Account</Link>
                  </li>

                  <li className="links__item">
                    <Link to="/login">Sign In</Link>
                  </li>
                </>
              }
            </ul>
          </div>
          <div className="footer-links">
            <ul className="links">
              <li className="links__title">{capitalize(t('ABOUT'))}</li>
              <li className="links__item">
                <Link to="/faq">FAQ</Link>
              </li>
              <li className="links__item">
                <Link to="/terms">{capitalize(t('TERMS_LINK'))}</Link>
              </li>
              <li className="links__item">
                <Link to="/privacy-policy">{capitalize(t('PRIVACY_LINK'))}</Link>
              </li>
              <li className="links__item">
                <Link to="/sitemap">Sitemap</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <p>&copy; Copyright 2020 - {new Date().getFullYear()} Vineify Inc.</p>
        </div>
      </div>
    </footer>
  )
}


export default RegisterFooter;
