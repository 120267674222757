import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '..';

import './style.scss';


const AccountNavCard = ({ icon, title, description, active, path, isSvg, color, size }) => {
  return (
    <Link to={path} className={classNames('nav-card', { 'active': active })}>
      {
        isSvg ? (
          <Icon icon={ icon } color={ color } size={ size } />
        ) : (
          <img className="nav-card__icon" src={icon} alt="icon" />
        )
      }

      <h3 className="nav-card__title">{title}</h3>
    </Link>
  )
}

AccountNavCard.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  path: PropTypes.string,
  isSvg: PropTypes.bool
}

AccountNavCard.defaultProps = {
  isSvg: false,
  active: false,
  icon: '',
  description: '',
  title: ''
}


export default AccountNavCard;
