import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import classNames from 'classnames';
import { useHelpers } from '../../hooks';


const InputRecordsList = ({ initRecords, label, buttonLabel, noRecordsMessage, onChange, autocompleteValue, errorMessage, recordsType }) => {
  const { isEmail } = useHelpers()
  const inputRef = useRef();
  const [records, changeRecords] = useState(initRecords || []);
  const [inputValue, changeInputValue] = useState('');

  const handleChangeInputValue = e => {
    e.preventDefault();

    changeInputValue(e.currentTarget.value);
  }

  const handleAddNewRecord = e => {
    e && e.preventDefault();

    if (inputValue?.length > 0) {
      let inputRecords = inputValue
        ?.toLowerCase()
        ?.split(',')

      if (recordsType === 'email') {
        inputRecords = inputRecords?.filter(email => isEmail(email))
      }

      const newRecords = [ ...new Set([...records, ...inputRecords.map(record => record.trim())]) ];

      changeRecords(newRecords);
      changeInputValue('');

      onChange(newRecords);
    }
  }

  const handleRemoveRecord = (e, recordToRemove) => {
    e.preventDefault();

    const newRecords = records.filter(record => record !== recordToRemove);

    changeRecords(newRecords);

    onChange(newRecords);
  }

  const handleInputKeyPress = e => {
    e.code === 'Enter' && handleAddNewRecord();
  }

  const recordsList = records?.map(record => (
    <button key={record} className="record" onClick={e => handleRemoveRecord(e, record)}>
      {record} <b>x</b>
    </button>
  ))


  return (
    <div className="input-records-group">
      <div className={classNames('form-control', { 'invalid': errorMessage?.length > 0 })}>
        <label htmlFor="record">{label}</label>

        <div className="input-records-group__records-input">
          <input
            ref={inputRef}
            name="record" autoComplete={ autocompleteValue }
            value={inputValue}
            onChange={handleChangeInputValue}
            onKeyPress={handleInputKeyPress}
          />

          {
            errorMessage &&
            <p className="input-records-group__error">{errorMessage}</p>
          }

          <button type="button" className="button button_xs" onClick={handleAddNewRecord}>{buttonLabel}</button>
        </div>
      </div>

      <div className="records-list">{
        recordsList?.length > 0 
          ? recordsList
          : (
            <p className="message">{ noRecordsMessage }</p>
          )
      }</div>
    </div>
  )
};

InputRecordsList.propTypes = {
  initRecords: PropTypes.arrayOf(PropTypes.oneOf([
    PropTypes.number, PropTypes.string
  ])),
  onChange: PropTypes.func,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  noRecordsMessage: PropTypes.string,
  recordsType: PropTypes.oneOf(['email', 'zip'])
};

InputRecordsList.defaultProps = {
  initRecords: [],
  buttonLabel: 'add record',
  noRecordsMessage: 'No Records.',
  label: 'Records List',
  recordsType: 'email'
}

export default InputRecordsList;
