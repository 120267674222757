import React, { useState } from 'react';
import { v4 as keyGen } from 'uuid';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import hideIco from '../../assets/icons/chevron-up.svg';
import showIco from '../../assets/icons/chevron-down.svg';
import { useHelpers } from '../../hooks';


const WinesSalesReportByDateCard = ({ expand, id, stock, name, bottlesSold, orders, vintage, producer, cost, children, className }) => {
    const [expanded, changeExpandedState] = useState(expand);
    const { formatDateTime } = useHelpers();

    const cardClasses = classNames('card report-card ' + className, {
        'expanded': expanded
    });

    const reportContent = orders.map(
      ({ id, caseName, quantity, customer, date }) => (
        <li key={ keyGen() }>
          <span className="id">{id}</span>
          <span className="quantity">{quantity}</span>
          <span className="name">{ caseName }</span>
          <span className="date">{ formatDateTime(date, false) }</span>
          <span className="customer">{ customer }</span>
        </li>
      )
    )


    return (
        <div className={ cardClasses }>
          <div className="card__body">
            <div className="report-data">
              <div className="id">{ id }</div>
              <div className="vintage">{vintage}</div>
              <div className="producer">{producer}</div>
              <div className="name">{name}</div>
              <div className="sold">{(+bottlesSold).toFixed(0)}</div>
              <div className="cost">{cost}</div>
              <div className="total">{(cost * +bottlesSold).toFixed(2)}</div>
              <button 
                className="button-link"
                onClick={ () => changeExpandedState(!expanded) }
              >
                { expanded ? 'hide' : 'show' } details <img src={ expanded ? hideIco : showIco } alt="expand" />
              </button>
            </div>
            {
              expanded && 
              <div className="report-content">
                <ul className="sales-list">{ reportContent }</ul>
              </div>
            }
          </div>
        </div>
      )
}



WinesSalesReportByDateCard.propTypes = {
    id: PropTypes.number,
    vintage: PropTypes.number,
    stock: PropTypes.number,
    name: PropTypes.string,
    producer: PropTypes.string,
    bottlesSold: PropTypes.number,
    orders: PropTypes.shape({
        id: PropTypes.number,
        caseName: PropTypes.string,
        quantity: PropTypes.number,
        customer: PropTypes.string,
        date: PropTypes.string
    })
}

export default WinesSalesReportByDateCard
