import React, { useState } from 'react'
import PropTypes from 'prop-types'


const OrderContactsForm = ({ onSubmit, data }) => {
    const [formData, changeFormData] = useState({
        email: data?.email,
        phone: data?.phone,
    });

    const handleFieldChange = e => {
        e.preventDefault();

        const { name, value } = e.target;

        changeFormData(prev => ({ 
            ...prev, [name]: value 
        }))
    }

    const handleSubmitForm = e => {
        e.preventDefault();

        onSubmit(formData);
    }

    const { email, phone } = formData;


    return (
        <form>
            <p className="form-section-title">Order Contacts</p>
            
            <div className="form-section">
                <div className="form-control">
                    <label htmlFor="email">Email</label>

                    <input
                        type="text" name="email"
                        value={ email }
                        onChange={ handleFieldChange }
                    />
                </div>

                <div className="form-control">
                    <label htmlFor="phone">Phone</label>

                    <input
                        type="text" name="phone"
                        value={ phone }
                        onChange={ handleFieldChange }
                    />
                </div>
            </div>

            <div className="form-control">
                <button 
                    className="button"
                    onClick={ handleSubmitForm }
                >Save Changing</button>
            </div>
        </form>
    )
}


OrderContactsForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string
    })
}

OrderContactsForm.defaultProps = {
    data: {
        email: '',
        phone: ''
    }
}

export default OrderContactsForm;
