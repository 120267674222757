import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRequests } from '../../hooks';

import icon from '../../assets/icons/lng.svg';

import './style.scss';
import { useSelector } from 'react-redux';


const LanguageMenu = ({ languages, mainClassName }) => {
  const { isAuthenticated } = useSelector(state => state.auth);
  const { i18n } = useTranslation();
  const { post } = useRequests();


  const handleChooseLanguage = val => {
    const { value } = val;

    i18n.changeLanguage(value);

    isAuthenticated && post({ url: `/api/users/preferences`, body: { language: value } });

    return { label: '', value: '' }
  }

  const languageMenuView = (
    <div className="language-menu-view">
      <img src={icon} alt="language" />

      <span className="language">{i18n.language.toUpperCase()}</span>
    </div>
  )

  return null;

  // Temporary disabled!
  // return (
  //   <Dropdown
  //     className={`main-menu${mainClassName && ' ' + mainClassName}`}
  //     controlClassName="main-menu__control"
  //     menuClassName="main-menu__menu"
  //     arrowClassName="main-menu__arrow"
  //     options={languages}
  //     value={{ label: languageMenuView, value: '' }}
  //     onChange={handleChooseLanguage}
  //     placeholder="Language Menu"
  //   />
  // )
}


LanguageMenu.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }))
}

LanguageMenu.defaultProps = {
  languages: [{ value: 'en', label: 'English' }, { value: 'it', label: 'Italiano' }]
}


export default LanguageMenu;
