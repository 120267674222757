import React from 'react';
import PropTypes from 'prop-types';
import { Modal, useModal } from 'react-simple-hook-modal';
import { Spinner } from '..';
import { useHelpers } from '../../hooks';

import closeIcon from '../../assets/icons/close-circle-green.svg';


const AdminOrdersStatistics = ({ orders, pending, currency }) => {
  const { openModal, isModalOpen, closeModal } = useModal();
  const { getOrdersTotalsStatistics } = useHelpers();
  const showEur = ['eur', 'all'].includes(currency);
  const showUsd = ['usd', 'all'].includes(currency);

  const { total, ...casesStatistics } = getOrdersTotalsStatistics(orders);

  const content = [];

  for (const caseStat in casesStatistics) {
    const statOrderedUsd = (
      <span className="stat-ordered">
        <b></b><i>({casesStatistics[caseStat].usd?.ordersCount || 0})</i>
        {' - '}
        <i>${casesStatistics[caseStat].usd?.totalPrice?.toFixed(2) || (0).toFixed(2)}</i>
      </span>
    )

    const statOrderedEur = (
      <span className="stat-ordered">
        <b></b><i>({casesStatistics[caseStat].eur?.ordersCount || 0})</i>
        {' - '}
        <i>€{casesStatistics[caseStat].eur?.totalPrice?.toFixed(2) || (0).toFixed(2)}</i>
      </span>
    )

    content.push(
      <li className="stat-item">
        <span className="stat-case">{caseStat}:</span>

        <span className="flex-space-between" style={{ width: '240px' }}>
          {
            showUsd && <>{ statOrderedUsd }</>
          }
          {
            showEur && <>{ statOrderedEur }</>
          }
        </span>
      </li>
    )
  }

  const fullTotalContent = <span>Total:   { showUsd && <>${total.usd?.toFixed(2)}</> }{ showEur && <>,  €{total.eur?.toFixed(2)}</> }</span>;

  const moreButton = content.length > 4 ?
    <li className="flex-space-between" style={{ width: '100%', alignItems: 'center', marginTop: '8px' }}>
      <span className="stat-case">And {content.length - 4} more cases...</span>

      <button
        style={{ padding: 0, width: 'auto', minWidth: 'auto' }}
        className="button-link button_xs"
        onClick={openModal}
      >Show All</button>
    </li> : null


  return (
    <>
      <ul className="orders-statistics">
        <li className="stat-caption">Cases Summary</li>
        {
          pending ?
            <li className="text-lg text-bold stat-item flex-center"><Spinner /></li>

            :
            <>
              {[...[0, 1, 2, 3, 5].map(i => content[i]), moreButton]}
              <li className="text-lg text-bold stat-item flex-grow">
                <span className="flex-grow">Orders: {orders.length}</span>
                {fullTotalContent}
              </li>
            </>
        }
      </ul>

      <Modal isOpen={isModalOpen} modalClassName="modal-dialog-lg modal-dialog-width-md" >
        <button className="close-modal-btn" style={{ zIndex: 100 }} onClick={closeModal}>
          <img src={closeIcon} alt="close modal" style={{ height: '32px', filter: 'none' }} />
        </button>

        <div style={{ width: '100%', position: 'relative', backgroundColor: 'white', zIndex: 50 }}>
          <h3 className="stat-caption">Cases Full Summary</h3>

          <ul className="orders-statistics" style={{ width: '100%' }}>
            {content}
          </ul>

          <div className="flex-space-between text-lg text-bold" style={{ padding: '16px 32px', backgroundColor: 'whitesmoke', marginBottom: '32px' }}>
            <span className="flex-grow">Orders: {orders.length}</span>
            {fullTotalContent}
          </div>

          <div className="flex-center">
            <div
              className="button button_outline"
              onClick={closeModal}
            >Close</div>
          </div>
        </div>
      </Modal>
    </>
  )
}

AdminOrdersStatistics.propTypes = {
  orders: PropTypes.array,
  currency: PropTypes.oneOf(['all', 'usd', 'eur'])
}


AdminOrdersStatistics.defaultProps = {
  orders: []
}


export default AdminOrdersStatistics
