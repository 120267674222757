import axios from 'axios'
import Case from '../classes/Case';


export const fetchCountriesDataList = async () => {
  const response = await axios.get('/api/content/regions-list');

  if (response?.data?.success) {
    return response.data.regions
  }

  return [];
}

export const fetchShippingCharges = async (country = 'US') => {
  const countryDataResponse = await axios.get(`/api/shipping/charges/${country}`);

  const { shippingCharge: charge, country: shippingDataCountry, currency } = countryDataResponse?.data?.chargeData;

  return { charge, shippingDataCountry, currency }
}

export const fetchRegionsList = async () => {
  const regionsResponse = await axios.get('/api/content/regions-list');

  if (regionsResponse?.data?.success) {
    const { regions } = regionsResponse.data;

    const parentRegions = regions
      ?.filter(({ hasParent }) => !hasParent)
      ?.map(({ name }) => name)

    const regionsList = regions?.reduce(
      (acc, { names, name, hasParent, id, country }) => {
        acc[name] = { ...names, hasParent, id, country };

        return acc;
      }, {}
    );

    return { parentRegions, regionsList }
  }
}

export const fetchRegionDataByName = async ({ regionName, language = 'en' }) => {
  const regionResponse = await axios.get(`/api/content/regions/${regionName}?lng=${language}`);

  if (regionResponse?.data?.success) {
    return regionResponse.data.region;
  }

  return undefined;
}

export const fetchRegionCases = async ({ regionName, language }) => {
  const casesResponse = await axios.get(`/api/wines/cases?region=${regionName}&lng=${language}`)

  if (casesResponse?.data?.success) {

    return Case.sortCasesByPrice(casesResponse.data.cases)
  }

  return [];
}

export const fetchRegionGrapes = async ({ regionName, grapesCategories = ['white','red'], language = 'en' }) => {
  const response = await axios.get(`/api/wines/grapes?lng=${language}&region=${regionName}&cat=${grapesCategories}`);

  if (response?.data?.success) {
    return response.data.grapes
  }

  return []
}

export const fetchMainRegionsDataList = async () => {
  const response = await axios.get(`/api/content/parent-regions`)

  if (response?.data?.success) {
    return response.data.regions
  }

  return []
}

export const fetchSliderRegions = async () => {
  const response = await axios.get(`/api/content/parent-regions?v=6&lng=en`);

  if (response?.data?.success) {
    return response.data.regions;
  }

  return [];
}


//       get({ url: '/api/content/regions?main=true&new=2' }),
//       get({ url: `/api/wines/grapes?lng=${i18n.language}&region=${regionName}&cat=${grapesCategories}` }),
