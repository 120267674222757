import React from 'react'
import PropTypes from 'prop-types'

//TODO: temporary grapes list
const grapes = ['Cabernet Sauvignon', 'Pinot Noir', 'Chardonnay', 'Sauvignon Blanc']

const PublicHomeCasesGrapesFilter = ({ onChange, activeGrapes }) => {
  const toggleGrapeFilter = grape => e => {
    e.preventDefault()

    onChange(grape)
  }

  const grapesContent = grapes.map(grapeName => {
    const classNamesList = ['button', 'button_outline', 'pill', 'w-auto']
    activeGrapes.includes(grapeName) && classNamesList.push('active')

    const className = classNamesList.join(' ')

    return (
      <button key={grapeName} className={ className } onClick={ toggleGrapeFilter(grapeName) }>{ grapeName }</button>
    )
  })

  return (
    <div className="grapes-filter">
      <p className="grapes-filter__label">Your Favorite Grape:</p>

      { grapesContent }
    </div>
  )
}

PublicHomeCasesGrapesFilter.propTypes = {
  activeGrapes: PropTypes.arrayOf(PropTypes.string)
  , onChange: PropTypes.func
}

export default PublicHomeCasesGrapesFilter