import { useSelector, useDispatch } from "react-redux"
import { requestOrdersList, requestUserOrders } from './order.actions'


const useOrders = () => {
  const dispatch = useDispatch();
  const {
    error,
    processingRequest,
    processingRequests,
    list,
    currentOrderData,
    dataIsFetched
  } = useSelector(state => state.orders)
  const ordersState = {
    error,
    processingRequest,
    processingRequests,
    list,
    currentOrderData,
    dataIsFetched
  }
  const ordersActions = {};

  ordersActions.requestUserOrders = ({ language = 'en' }) => dispatch(requestUserOrders({ language }))

  ordersActions.requestOrdersList = ({ caseId, orderTypes, userId, warehouse, from, to, incomplete, location }) => 
    dispatch(requestOrdersList({ caseId, orderTypes, userId, warehouse, from, to, incomplete, location }))


  return { ordersState, ordersActions }
}

export default useOrders;
