import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


// const languages = ['en', 'it'];
const languages = ['en'];

const _setLanguageCode = lng => {
  const splitedLng = lng.split('-');

  if (splitedLng.length > 1) {
    const lngCode = splitedLng[0].toLowerCase();
    const defaultLanguage = languages.includes(lngCode) ? lngCode : 'en';

    i18n.changeLanguage(defaultLanguage);
  }
}

i18n.on('languageChanged', _setLanguageCode);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    },
    returnObjects: true
  });


export default i18n;
