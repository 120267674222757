import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import Case from '../../classes/Case';
import { CountryContext } from '../../context/country';
import { useHistory } from 'react-router';
import { Spinner, WinesPreviewsGallery, Icon } from '..';
import { useActions, useHelpers, useRequests } from '../../hooks';

import heroImage from '../../assets/images/case-box.jpg';
import heroImageMobile from '../../assets/images/case-box-mobile.png';

import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const CountryRecommendationHero = ({ region, onChangeActiveCase }) => {
  const dispatch = useDispatch()
  const { isAuthenticated, user } = useSelector(state => state.auth)
  const { cartActions, recommendationsActions, regionActions } = useActions()
  const { charge, regions } = useContext(CountryContext);
  const history = useHistory();
  const { get, post } = useRequests();
  const { createWinesTypes, capitalize, formCasePrice } = useHelpers();
  const { i18n, t } = useTranslation();
  const isMobileWidth = window.matchMedia('(max-width: 520px)').matches;
  const [expanded, setExpanded] = useState(!isMobileWidth);
  const [state, changeState] = useState({
    fetchingData: true, data: null, recommendationsList: [], processingOrder: false, isFirstRecommendation: undefined
  });

  const price = state?.data ? new Case(state.data)?.getPrice(user?.preferences?.currency || 'usd', charge) : 0;
  const isLastRecommendation = +state.data?.id === +state.recommendationsList[state.recommendationsList.length - 1]?.id;
  const isFirstRecommendation = +state.data?.id === +state.recommendationsList[0]?.id;
  const layoutClassNames = useMemo(() => {
    const classNames = ['hero-wrapper'];
    expanded && classNames.push('expanded');

    return classNames.join(' ')
  }, [expanded]);

  const fetchRecommendationData = async id => {
    changeState(prev => ({ ...prev, fetchingData: true }));

    const response = await get({
      url: `/api/wines/cases/recommendations?r=${regions[region]?.id}&lng=${i18n.language}${id ? `&cid=${id}` : ``}`
    });

    if (response?.data?.success) {
      const { recommendations, caseData } = response.data;

      return changeState(
        prev => ({
          ...prev,
          fetchingData: false,
          recommendationsList: recommendations || [],
          isFirstRecommendation: prev?.isFirstRecommendation === undefined ?
            caseData?.id : prev?.isFirstRecommendation,
          data: caseData
        })
      )
    } else {
      console.log('Failed to fetch recommendation: ', response.data)
    }
  }

  const handlePurchaseCase = async e => {
    e.preventDefault();
    if (state.data?.isSoldOut) return;


    changeState(prev => ({ ...prev, processingOrder: true }));
    // const { user: { preferences: { currency } } } = ctx;
    const currency = isAuthenticated ? user?.preferences?.currency || 'usd' : 'usd'


    // const response = await post({
    //   url: '/api/orders',
    //   body: {
    //     caseId: state.data.id,
    //     caseDetailsId: state.data.caseDetailsId,
    //     cost: formCasePrice(+state.data?.prices[currency])
    //   }
    // });
    // dispatch(cartActions.requestAddCaseToCart({
    //   caseId: state.data.id,
    //   caseDetailsId: state.data.caseDetailsId,
    //   cost: formCasePrice(+state.data?.prices[currency])
    // }))

    dispatch(cartActions.requestAddCaseToCart({
      caseId: state.data.id,
      caseDetailsId: state.data.caseDetailsId,
      cost: formCasePrice(+state.data?.prices[currency]),
      redirectToCart: true
    }))


    // if (response && response.data.success) {
    //   ctx.cart.order = {
    //     case: { ...state.data },
    //     id: response.data.orderId
    //   };

    //   history.push('/cart')
    // } else {
    //   toast('Something goes wrong...')
    // }

    changeState(prev => ({ ...prev, processingOrder: false }));
  };


  const btnLabel = state.data?.isSoldOut ?
  t('SOLD_OUT') :
  t('BUY_CASE') + ' - ' + formCasePrice(price)

  const purchaseBtn = (
    <button
      className="button"
      disabled={state.processingOrder || state.data?.isSoldOut}
      onClick={handlePurchaseCase}
    >{btnLabel}</button>
  ) 

  // const purchaseBtn = ctx.isAuthenticated ? (
  //   <button
  //     className="button"
  //     disabled={state.processingOrder || state.data?.isSoldOut}
  //     onClick={handlePurchaseCase}
  //   >{btnLabel}</button>
  // ) : (
  //   // <p className="no-margin">{formCasePrice(price)} - <Link to="/login"><b>Sign in</b></Link> to buy</p>
  //   <SignInToBuy
  //     onSignedIn={handleSignedIn} 
  //     cost={formCasePrice(price)}
  //     label={ btnLabel }
  //     disabled={state.processingOrder || state.data?.isSoldOut}
  //   />
  // )

  const toggleExpandDetails = () => setExpanded(prev => !prev); 

  // eslint-disable-next-line
  useEffect(() => { fetchRecommendationData(); }, [i18n.language]);

  const toggleRecommendation = action => async e => {
    e.preventDefault();

    if (state.fetchingData) return;

    // console.log(e.target)
    // console.log(e.target.dataset)

    // const { action } = e.target.dataset;

    const currentIndex = state.recommendationsList?.findIndex(
      ({ id }) => state.data.id === id
    )
    

    let caseId = 0;

    if (action === 'next') {
      if (currentIndex === state.recommendationsList.length - 1) return;

      // caseId = currentIndex < (state.recommendationsList.length - 1) ?
      //   state.recommendationsList[currentIndex + 1]?.id
      //   :
      //   state.recommendationsList[0]?.id

      caseId = state.recommendationsList[currentIndex + 1]?.id
    }

    if (action === 'prev') {
      caseId = currentIndex > 0 ?
        state.recommendationsList[currentIndex - 1]?.id
        :
        state.recommendationsList[state.recommendationsList.length - 1]?.id
    }

    await fetchRecommendationData(caseId);
  }

  const { total, ...wineTypes } = createWinesTypes(state?.data?.wines || []);
  const winesStats = [];

  if (!state.fetchingData) {
    // for (const wineType in wineTypes) {
    //   if (wineTypes[wineType] > 0) {
    //     winesStats.push(
    //       `${wineTypes[wineType]} ${capitalize(t(wineType !== 'sparkling' ? wineType.toUpperCase() + 'S' : wineType.toUpperCase()))}`
    //     )
    //   }
    // }
    for (const wineType in wineTypes) {
      const category = wineType === 'rose' ? wineType.replace('e', 'é') : wineType;
      const word = category === 'sparkling'
        ? capitalize(category) : wineTypes[wineType] > 1
          ? capitalize(category + 's') : capitalize(category)
      if (wineTypes[wineType] > 0) {
        winesStats.push(
          // `${wineTypes[wineType]} ${capitalize(t(wineType !== 'sparkling' ? wineType.toUpperCase() + 'S' : wineType.toUpperCase()))}`
          `${wineTypes[wineType]} ${word}`
        )
      }
    }
  }


  const caseIsFromThisRegion = state?.data?.region === region && state?.data?.category === 'standard';
  const isMultiRegion = state?.data?.regions?.length > 1

  const handleViewCaseContent = e => {
    e.preventDefault();


    if (caseIsFromThisRegion) {
      state.data?.id && dispatch(regionActions.changeActiveRegionCase({ id: +state.data.id, isMultiRegion }));
      const caseSectionId = isMultiRegion ? 'mixed-cases-details' : 'case-details'
      const casesSection = document.getElementById(caseSectionId);

      // state.data?.id && onChangeActiveCase(+state.data.id, isMultiRegion);

      scrollIntoView(casesSection, { behavior: 'smooth', scrollMode: 'if-needed', block: 'nearest' })
    } else {
      // ctx.user.recommendedCase = +state?.data?.id;
      dispatch(recommendationsActions.setRecommendedCustomCaseToShow(+state?.data?.id))

      history.push(`/recommendations`);
    }
  }

  const viewCaseButton = (
    <button
      className="button-link"
      onClick={handleViewCaseContent}
    >View Case Contents</button>
  )

  const viewRecommendationDetailsButton = (
    <button
      className="button-link"
      onClick={toggleExpandDetails}
    >{expanded ? 'Close Details' : 'Look Inside'}</button>
  )


  const heroContent = state?.fetchingData ? (
    <Spinner spinnerClass="spin-center" />
  ) : (
    <>
      <div className="hero__case-data">
        {
          state?.isFirstRecommendation === state?.data?.id &&
          <div className="hero_recommendation__prefix">{
            isAuthenticated ? t('RECOMMENDED_FOR_YOU') : 'POPULAR'
          }</div>
        }

        <div className="hero_recommendation__title">
          <h3>{state?.data?.name || state?.data?.defaultName}</h3>
        </div>

        <div className="hero_recommendation__wines-stats">
          {winesStats.join(', ')}
        </div>

        {
          isMobileWidth && expanded &&
          <>
            {viewCaseButton}

            <div className="hero__case-wines">
              <WinesPreviewsGallery wines={state?.data?.wines} />
            </div>
          </>
        }

        <div className="hero_recommendation__purchase">
          {purchaseBtn}
          
          { isMobileWidth ? viewRecommendationDetailsButton : viewCaseButton }
        </div>
      </div>
      {
        !isMobileWidth &&
        <div className="hero__case-wines">
          <WinesPreviewsGallery wines={state?.data?.wines} />
        </div>
      }
    </>
  );

  if (state.isFirstRecommendation === undefined) return null;


  return (
    <div className={layoutClassNames}>
      {
        !isMobileWidth &&
        <h3 className="hero-title">Here is a curated case of unique wines for you:</h3>
      }

      <div className="hero hero_region-recommendation">
        <div className="hero__content">
          <div className="hero__image">
            <img src={isMobileWidth ? heroImageMobile : heroImage} alt="recommendation" />
          </div>

          {heroContent}
        </div>
      </div>

      {
        window.matchMedia('(max-width: 520px)').matches &&
        <div className="hero__controls">
          <button
            className={classNames('button button_outline with_icon', { 'disable-hidden-button': isFirstRecommendation })}
            data-action="prev"
            disabled={state.processingOrder || state.fetchingData}
            onClick={toggleRecommendation('prev')}
          >
            {/* <img data-action="prev" src={arrowLeft} alt="previous recommendation" /> */}
            <Icon data-action="prev" icon="arrow-left" color="vineify-green" />

            <span data-action="prev">{
              window.matchMedia("(min-width: 521px)").matches ?
                t('PREVIOUS_RECOMMENDATION')
                :
                t('PREVIOUS_RECOMMENDATION').split(' ')[0] + ' ' + capitalize(t('CASE'))
            }</span>
          </button>

          <button
            className={classNames('button button_outline with_icon', { 'disable-hidden-button': isLastRecommendation })}
            data-action="next"
            disabled={state.processingOrder || state.fetchingData}
            onClick={toggleRecommendation('next')}
          >
            <span data-action="next">{
              window.matchMedia("(min-width: 521px)").matches ?
                t('NEXT_RECOMMENDATION')
                :
                t('NEXT_RECOMMENDATION').split(' ')[0] + ' ' + capitalize(t('CASE'))
            }</span>

            {/* <img data-action="next" src={arrowRight} alt="next recommendation" /> */}
            <Icon data-action="next" icon="arrow-right" color="vineify-green" />
          </button>
        </div>
      }
    </div>
  )
}

export default CountryRecommendationHero;
