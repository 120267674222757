import { createSlice } from '@reduxjs/toolkit'
import { requestRecommendation } from './recommendation.actions'


const initialState = {
  processingRequest: false,
  processingRequests: 0,
  error: undefined,
  recommendedCustomCaseToShow: undefined,
  regionRecommendationCase: undefined,
  recommendationsList: [],
  currentRecommendationData: {}
}

const recommendationsSlice = createSlice({
  name: '@@recommendations',
  initialState,
  reducers: {
    setScrollToCaseId: (state, { payload }) => {
      state.regionRecommendationCase = payload;
    },
    setRecommendedCustomCaseToShow: (state, { payload }) => {
      state.recommendedCustomCaseToShow = payload
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestRecommendation.fulfilled, (state, { payload }) => {
        state.recommendationsList = payload.recommendations
          ? JSON.parse(payload.recommendations) : state.recommendationsList
        state.currentRecommendationData = payload.caseData
          ? JSON.parse(payload.caseData) : state.currentRecommendationData
      })
      .addMatcher(
        (action) => action.type.startsWith('@@recommendations') && action.type.endsWith('/rejected'), 
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@recommendations') && action.type.endsWith('/pending'), 
        (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@recommendations') && action.type.endsWith('/fulfilled'), 
        (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})


export const recommendationsActions = { ...recommendationsSlice.actions, requestRecommendation }

export default recommendationsSlice.reducer;
