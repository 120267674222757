import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { v4 as keyGen } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useActions, useHelpers } from '../../hooks'; 

import menuIcon from '../../assets/icons/hamburger.svg'; 
import closeIcon from '../../assets/icons/close.svg';
import account from '../../assets/icons/account.svg';

import './style.scss';
import { useDispatch, useSelector } from 'react-redux';


const MobileMenu = ({ menuList, isOpen: opened }) => {
  const dispatch = useDispatch()
  const { authActions } = useActions()
  const { user: { email, firstName, lastName }, isAuthenticated } = useSelector(state => state.auth);
  const location = useLocation();
  const { t } = useTranslation();
  const { capitalize } = useHelpers();
  const [isOpen, setIsOpenState] = useState(opened);

  const classesList = classNames('mobile-menu', { 'opened': isOpen });

  const toggleMenuOpen = () => {
    if (isOpen) {
      document?.body?.classList.remove('mobile-menu-layout')
    } else {
      document?.body?.classList.add('mobile-menu-layout')
    }

    setIsOpenState(prev => !prev);
  }

  const handleLogout = e => {
    e.preventDefault();

    // logout(history);
    dispatch(authActions.logout('/login'));
    setIsOpenState(false);
  }

  const accountLinks = isAuthenticated
    ? (<>
      <div className="header-user">
        <b>{firstName + ' ' + lastName}</b>
        <i>{email}</i>
      </div>

      <div className="flex-space-between">
        <Link className="text-underline" to="/account">{capitalize(t('ACCOUNT'))}</Link>

        <button className="button-link"
          onClick={handleLogout}
        >Sign Out</button>
      </div>
    </>) : (<>
      <div className="header-user">
        <i>You are not signed in.</i>
      </div>

      <Link className="text-underline button-link" to="/create-account">Create Account</Link>

      <Link className="text-underline button-link" to="/account">Sign In</Link>
    </>)


  const menuContent = menuList.map(
      ({ path, title, innerLinks }) => {
        const { pathname } = location;
        const linkClassname = classNames('', {
          // 'active': pathname.endsWith(path) || (pathname === '/home' && path === '/')
          'active': pathname.endsWith(path)
        });
        const innerMenu = innerLinks?.map(
          ({ path: innerPath, title: innerTitle }) => {
            const innerLinkClassname = classNames('inner-link', { 'active': pathname.endsWith(innerPath) });

            return (
              <Link key={`key_link_${innerTitle}`} className={innerLinkClassname} to={innerPath}>{innerTitle}</Link>
            )
          }
        ) || null;

        return (
          <li className="link" key={keyGen()}>
            {
              innerMenu ?
                <>
                  <h3 className="inner-menu-title">{title}</h3>
                  {innerMenu}
                </>
                : <Link className={linkClassname} to={path}>{title}</Link>
            }
          </li>
        )
      }
    );

  useEffect(
    () => {
      setIsOpenState(false);

      document?.body?.classList.remove('mobile-menu-layout')
    }, [location]
  );


  return (
    <>
      <div className={classesList}>
        <div className="mobile-menu__overlay" />

        <div className="mobile-menu__body">
          <button
            className="close-button"
            onClick={toggleMenuOpen}
          ><img src={closeIcon} alt="" /></button>

          <div className="header">
            <div className="header-icon">
              <img className="fill-green" src={account} alt="logo" />
            </div>

            { accountLinks }
          </div>

          <div style={{ padding: '0 32px', lineHeight: 0 }}><div className="divider" /></div>

          <div className="nav-menu">
            <ul className="header-links">{menuContent}</ul>
          </div>
        </div>

        {/* <button
        className="mobile-menu__button"
        onClick={toggleMenuOpen}
      ><img src={menuIcon} alt="" /></button> */}
      </div>
      <button
        className="mobile-menu__button"
        onClick={toggleMenuOpen}
      ><img src={menuIcon} alt="" /></button>
    </>
  )
}

MobileMenu.propTypes = {
  opened: PropTypes.bool,
  menuList: PropTypes.array
}

MobileMenu.defaultProps = {
  opened: false
}

export default MobileMenu;
