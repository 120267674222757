import React, { useEffect, useRef, useState } from 'react';
import { v4 as keyGen } from 'uuid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as genId } from 'uuid';
import { Popover } from '..';

import decIcon from '../../assets/icons/minus.svg';
import incIcon from '../../assets/icons/plus.svg';

import './style.scss';


const CountInput = ({
  name, id, onChange, value, disabled, negativeValues,
  inputClass, containerClass, labelClass, label, placeHolder,
  incButtonDisable, decButtonDisable, incButtonDisableNotification, decButtonDisableNotification,
  ...otherProps
}) => {
  const [notificationIsShown, changeNotificationVisibilityState] = useState(false);
  const inputRef = useRef();
  const containerClasses = `form-control horizontal count-input ${containerClass ? containerClass : ''}`;

  const btnId = keyGen();

  const handleChangeInputValue = e => {
    e.preventDefault();
    const { value:changedValue } = e.currentTarget;

    if (/\D+/.test(changedValue)) return value;

    !disabled && onChange(+inputRef.current.value);
  }

  const handleChangeValueOnClick = (action, e) => {
    e.preventDefault();

    if (disabled) return;

    if (action === 'inc') {
      if (incButtonDisableNotification) {
        changeNotificationVisibilityState(true);

        return setTimeout(() => {
          changeNotificationVisibilityState(false);
        }, 5000)
      }

      onChange(+inputRef.current.value + 1, action)
    } else {
      const currentValue = +inputRef.current.value;
      const newValue = currentValue > 0 ?
        currentValue - 1 : negativeValues ?
          currentValue - 1 : currentValue;

      onChange(newValue, action);
    }
  }

  const notificationEvent = () => {
    if (otherProps?.numOfBottles === 12) {
      changeNotificationVisibilityState(true);
    }
  }

  useEffect(() => {
    const btn = document.getElementById(btnId);

    btn.addEventListener('mouseover', notificationEvent);

    return btn.removeEventListener('mouseover', notificationEvent);
    // eslint-disable-next-line
  }, []);


  return (
    <div className={containerClasses}>
      {
        label &&
        <label htmlFor={name} className={labelClass}>{label}</label>
      }
      <div className="input-group">
        <button
          className={classNames('button control-btn btn-dec', { 'disabled': decButtonDisable })}
          disabled={decButtonDisable}
          onClick={e => handleChangeValueOnClick('dec', e)}
        ><img src={decIcon} alt="decrement input value" /></button>

        <input type="text" ref={inputRef}
          id={id || genId()}
          className={inputClass}
          disabled={disabled}
          value={value}
          placeholder={placeHolder}
          onChange={handleChangeInputValue}
          {...otherProps}
        />

        <Popover
          isOpen={notificationIsShown}
          position="top"
          arrowSize={12}
          padding={10}
          arrowClassName="popover-arrow"
          arrowColor="white"
          notification={incButtonDisableNotification}
        >
          <button
            id={btnId}
            className={classNames('button control-btn btn-inc', { 'disabled': incButtonDisable })}
            onClick={e => handleChangeValueOnClick('inc', e)}
          ><img src={incIcon} alt="increment input value" /></button>
        </Popover>
      </div>
    </div>
  )
}


CountInput.propTypes = {
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  id: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  negativeValues: PropTypes.bool,
  inputClass: PropTypes.string,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  otherProps: PropTypes.object
}

CountInput.defaultProps = {
  negativeValues: false
}

export default CountInput
