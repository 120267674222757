import { useSelector, useDispatch } from 'react-redux'
import { recommendationsActions as actions } from './recommendation.slice'
import { requestRecommendation } from './recommendation.actions'


const useRecommendations = () => {
  const dispatch = useDispatch()
  const {
    processingRequest,
    processingRequests,
    error,
    recommendedCustomCaseToShow,
    regionRecommendationCase,
    recommendationsList,
    currentRecommendationData
  } = useSelector(state => state.recommendations)
  const recommendationsState = {
    processingRequest,
    processingRequests,
    error,
    recommendedCustomCaseToShow,
    regionRecommendationCase,
    recommendationsList,
    currentRecommendationData
  }
  const recommendationsActions = {}

  recommendationsActions.requestRecommendation = ({ caseId, language = 'en' }) => dispatch(requestRecommendation({ caseId, language }))

  recommendationsActions.setScrollToCaseId = (caseId) => dispatch(actions.setScrollToCaseId(caseId))

  recommendationsActions.setRecommendedCustomCaseToShow = (caseId) => 
    dispatch(actions.setRecommendedCustomCaseToShow(caseId))

  return { recommendationsState, recommendationsActions }
}


export default useRecommendations
