import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as keyGen } from 'uuid';
import ImageUploading from 'react-images-uploading';
import { toast } from 'react-toastify';
import { useRequests } from '../../hooks';
import { useHistory } from 'react-router';
import { Modal, useModal } from 'react-simple-hook-modal';

import ImageUploadFormControl from './ImageUploadFormControl';
import { Spinner, Select, TabbedTextarea, ManageTasteForm } from '..';
import { useSelector } from 'react-redux';

import closeIcon from '../../assets/icons/close.svg';

import './style.scss';


const GrapeDataForm = ({ id, tastesList, name: grapeName, region, tastes: grapeTastes, image, description, descriptions, grapeCategories, category, regionsList }) => {
  const { user } = useSelector(state => state.auth);
  const { post, requestPending } = useRequests();
  const history = useHistory();
  const { isModalOpen, openModal, closeModal } = useModal();
  // eslint-disable-next-line
  const [tastes, setTastes] = useState(tastesList);
  // eslint-disable-next-line
  const [categories, setCategories] = useState(grapeCategories);
  const [formData, changeFormData] = useState({
    name: grapeName || '',
    id,
    descriptions: descriptions ? descriptions : [{ tabName: 'en', text: '' }, { tabName: 'it', text: '' }],
    image: { file: null, data_url: image },
    description: description || '',
    region: +region || 1,
    tastes: grapeTastes || [],
    category: grapeCategories?.find(
      ({ label }) => label === category
    )?.value || grapeCategories[0].value
  });

  const isNewGrape = !id;
  const notValid = formData.tastes?.length === 3 ? null : 'Please choose 3 tastes';

  const regions = regionsList;

  const handleChange = e => {
    const field = e.target.name;
    const value = e.target.value;

    changeFormData({
      ...formData,
      [field]: value
    })
  }

  const handleAddNewTaste = ({ id, name }) => {
    setTastes( prev => ([...prev, { id, name }]) );

    closeModal();
  }

  const handleImageLoaded = image => {
    changeFormData({ ...formData, image })
  }

  const handleDescriptionChange = (field, data) => changeFormData(prev => ({ ...prev, [field]: data }));

  const handleChangeSelect = (field, value) => {
    changeFormData(
      prev => ({ ...prev, [field]: value })
    )
  }

  const handleGoBack = e => {
    e.preventDefault();

    history.goBack();
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (notValid) return toast.warn(notValid);

    const { image, descriptions, ...grapeData } = formData;
    const imageName = `${formData.name.split(' ').join('_')}`;

    const convertDescriptionsToReqData = descriprions => descriprions.reduce(
      (result, { tabName, text }) => {
        result = { ...result, [tabName]: text };

        return result;
      }, {}
    )

    const GrapeDataForm = {
      grapeData: JSON.stringify({ ...grapeData, descriptions: convertDescriptionsToReqData(descriptions) }),
      files: image?.file || image?.data_url || '',
      imageName
    }

    const form = new FormData();

    for (const field in GrapeDataForm) {
      form.append(field, GrapeDataForm[field]);
    }

    const response = isNewGrape ?
      await post({
        url: `/api/wines/grapes`,
        body: form
      })
      :
      await post({
        url: `/api/wines/grapes/${id}/update`,
        body: form
      });

    if (response?.data?.success) {
      toast.success(`Grape ${formData.name} was successfuly ${!isNewGrape ? 'updated' : 'created'}.`);

      history.push(`/${ user.role }/grapes`);

      isNewGrape && changeFormData({
        name: '',
        image: { file: null, data_url: null },
        description: '',
        descriptions: [{ tabName: 'en', text: '' }, { tabName: 'it', text: '' }],
        tastes: [],
        category: grapeCategories[0].value
      });

    } else {

      toast.error('Fail to submit data');

    }
  }

  const handleReduceImageSize = async () => {
    if (!id || !image) return false;

    const response = await post({
      url: `/api/wines/grapes/${id}/image`,
      body: { id, image }
    })

    if (response?.data?.success) {
      changeFormData(prev => ({
        ...prev,
        image: { file: null, data_url: response?.data?.image }
      }))

      toast.success(`grape image size was reduced`)
    }
  }

  const handleChangeTastes = e => {
    e.preventDefault();
    const tasteBtn = e.target;

    const grapeTastesIncludesCurrentTaste = formData.tastes.includes(+tasteBtn.id);

    if (grapeTastesIncludesCurrentTaste) {
      return changeFormData(
        prev => ({
          ...prev,
          tastes: prev.tastes.filter(taste => taste !== +tasteBtn.id)
        })
      )
    } else {
      const newTastes = formData.tastes.length < 3 ?
        [...formData.tastes, +tasteBtn.id] : [...formData.tastes]

      return changeFormData(
        prev => ({ ...prev, tastes: newTastes })
      )
    }
  }


  const tastesContent = tastes.map(
    taste => (
      <div className="tag">
        <button
          className={
            classNames('tag-button',
              { 'selected': formData.tastes.includes(taste.id) })
          }
          key={keyGen()}
          id={taste.id} name={taste.name}
          onClick={handleChangeTastes}
        >{taste.name}</button>
      </div>
    )
  );


  return (
    <>
      <form className="wine-data-form" onSubmit={e => { e.preventDefault() }}>
        <div className="form-section">
          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    imageList={[formData.image]}
                    onImageLoaded={handleImageLoaded}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>

              { !!id && !!image && 
                <button type="button"
                  className="button" style={{ alignSelf: 'center', marginTop: '16px' }}
                  disabled={requestPending}
                  onClick={handleReduceImageSize}
                >Reduce Image Size</button> }
            </div>
          </div>

          <div className="form-column">
            <div className="form-control w-100" style={{ maxWidth: 'none' }}>
              <label htmlFor="name">Grape Name</label>
              <input
                type="text" name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="form-section">

              <div className="form-control">
                <label htmlFor="category">Grape Category</label>

                <Select
                  name="category"
                  options={categories}
                  value={categories.find(({ value }) => value === formData.category)}
                  onChange={val => handleChangeSelect('category', val.value)}
                />
              </div>
            </div>

            <div className="form-section no-padding">
              <div className="form-control">
                <label htmlFor="region">Region</label>

                <Select
                  name="region"
                  options={regions}
                  value={regions.find(({ value }) => value === formData.region)}
                  onChange={val => handleChangeSelect('region', val.value)}
                />
              </div>
            </div>
          </div>

        </div>
        <div className="form-section-title flex-space-between">
          Grape Tastes

          <button className="button-link text-sm" onClick={ openModal }>Add New Taste</button>
        </div>
        <div className="form-section">
          <div className="admin-tags-list">
            {tastesContent}
          </div>
        </div>
        <div className="form-section-title">Grape Description</div>
        <TabbedTextarea
          onChange={handleDescriptionChange}
          tabs={formData.descriptions}
          label="Description language"
          name="descriptions"
        />
        <br />
        <div className="form-control horizontal control-button" style={{ justifyContent: "center", gap: '16px' }}>
          <button
            className="button button_outline"
            onClick={handleGoBack}
          >Cancel</button>

          <button
            className="button"
            disabled={requestPending}
            onClick={handleSubmit}
          >{isNewGrape ? 'Add Grape' : 'Change Grape'} {requestPending && <span className="loading"><Spinner /></span>}</button>
        </div>
      </form>
      <Modal isOpen={ isModalOpen } modalClassName="modal-dialog">
        <button className="close-modal-btn" onClick={ () => closeModal() }>
          <img src={ closeIcon } alt="close modal"/>
        </button>

        <ManageTasteForm onSubmit={ handleAddNewTaste } onCancel={ closeModal } />
      </Modal>
    </>
  )
}


GrapeDataForm.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  tastes: PropTypes.string,
  tasteOne: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteTwo: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteThree: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  onSubmit: PropTypes.func
}

GrapeDataForm.propTypes = {
  name: '',
  image: '',
  description: '',
  tastes: '',
  tasteOne: '1',
  tasteTwo: '2',
  tasteThree: '3',
}

export default GrapeDataForm;
