import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useHelpers } from '../../hooks';
import { CustomBadge } from '..';

import './style.scss';


const AdminOrderRow = ({
  id, firstName, lastName, price, caseName, orderDate, status, pathname, currency, isAdjusted, location, userId,
  caseId, isOrderLink, isGift, userRole, showLocation
}) => {
  const { formatDateTime, formatCurrency } = useHelpers();
  const role = userRole || 'admin';
  const customerName = firstName + ' ' + lastName;
  const caseTagName = <>{caseName}
  {
    isAdjusted && <CustomBadge label="edited" />
  }
  {
    isGift && <CustomBadge color="gold" label="gift" />
  }</>
  const caseTag = !isOrderLink && role === 'admin' 
    ? <Link to={`/admin/cases/${caseId}`} className="order-case">{ caseTagName }</Link>
    : <span className="order-case">{ caseTagName }</span>
  const customerTag = !isOrderLink && role === 'admin'
    ? <Link to={`/admin/users/${userId}`} className="order-owner">{customerName}</Link>
    : <span className="order-owner">{customerName}</span>

  return isOrderLink ? (
    <Link to={`/${role}/orders/${ id }`} className="admin-order-row" key={`order${id}case${caseId || caseName}`}>
      <span className="order-number">{id}</span>
      <span className="order-date">{formatDateTime(orderDate, false)}</span>
      {/* <span className="order-owner">{firstName + ' ' + lastName}</span> */}
      {/* <span className="order-case">
        {caseName}
        {
          isAdjusted && <CustomBadge />
        }
        {
          isGift && <CustomBadge color="gold" label="gift" />
        }
      </span> */}

      {customerTag}
      {caseTag}
      <span className="order-status">{location || 'Not Specified'}</span>
      <span className="order-status">{status}</span>
      {
        price &&
        <span className="order-price">{formatCurrency(price, currency, false, false)}</span>
      }
    </Link>
  ) : (
    <div className="admin-order-row" key={`order${id}case${caseId}`}>
      <span className="order-number">{id}</span>
      <span className="order-date">{formatDateTime(orderDate, false)}</span>
      {/* <Link to={`/admin/users/${userId}`} className="order-owner">{firstName + ' ' + lastName}</Link> */}
      {/* <Link to={`/admin/cases/${caseId}`} className="order-case">
        {caseName}
        {
          isAdjusted && <CustomBadge />
        }
        {
          isGift && <CustomBadge color="gold" label="gift" />
        }
      </Link> */}
      {customerTag}
      {caseTag}
      {
        showLocation &&
        <span className="order-status">{location || 'Not Specified'}</span>
      }
      <span className="order-status">{status}</span>
      {
        price &&
        <span className="order-price">{formatCurrency(price, currency, false, false)}</span>
      }
    </div>
  )
}


AdminOrderRow.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  price: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  status: PropTypes.string,
  caseName: PropTypes.string,
  orderDate: PropTypes.string,
  currency: PropTypes.string,
  isAdjusted: PropTypes.bool,
  showLocation: PropTypes.bool,
}

AdminOrderRow.defaultProps = {
  status: '',
  isAdjusted: false,
  isOrderLink: true,
  showLocation: true
}

export default AdminOrderRow;
