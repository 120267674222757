import { createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import * as CuratorService from '../../services/curator.service';


export const requestCuratorCasesPreviewList = createAsyncThunk(
  '@@curators/request-curator-cases-preview-list',
  async (curatorId, thunkAPI) => {
    try {
      const payload = await CuratorService.fetchCuratorCases(curatorId);

      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCuratorPreviewData = createAsyncThunk(
  '@@curators/request-curator-preview-data',
  async (curatorId, thunkAPI) => {
    try {
      const payload = await CuratorService.getCuratorPreviewData(curatorId)

      if (payload && Object.keys(payload).length > 0) {
        thunkAPI.dispatch(requestCuratorCasesPreviewList(curatorId))
      }

      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCuratorsPreviewsList = createAsyncThunk(
  '@@curators/request-curators-previews-list',
  async (_, thunkAPI) => {
    try {
      const payload = await CuratorService.fetchCuratorsPreviewsList()

      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCuratorOrders = createAsyncThunk(
  '@@curators/request-curator-orders',
  async (_, thunkAPI) => {
    try {
      const orders = await CuratorService.fetchCurrentCuratorOrders()

      return orders;
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestSendRecommendations = createAsyncThunk(
  '@@curators/request-send-recommendations',
  async (data, thunkAPI) => {
    const { error, emails } = await CuratorService.sendRecommendations(data)

    return { 
      error, 
      recommendations: emails?.map(email => ({ email, date: new Date(), caseId: data?.case?.id, key: nanoid() })) 
    }
  }
)

export const requestCuratorRecommendationsHistory = createAsyncThunk(
  '@@curators/request-curator-recommendations-history',
  async ({curatorId, caseId}, thunkAPI) => {
    const data = await CuratorService.fetchCuratorRecommendationsHistory({curatorId, caseId})

    const { error, recommendations } = data;

    return { error, recommendations: recommendations?.map(recData => ({ ...recData, key: nanoid() })) }
  }
)
