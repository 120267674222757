import React, { useState, memo } from 'react'; 
import PropTypes from 'prop-types';
import { CountInput, Spinner, Alert, ShortDescription, Icon } from '..';

import deleteIcon from '../../assets/icons/plus.svg';

import './style.scss';
import { useHelpers } from '../../hooks';


const CustomCaseWineCard = ({
  wine, onRemove, onChange, numOfBottles
}) => {
  const { getWineName } = useHelpers();
  const [isPending, setPendingState] = useState(false);
  const wineName = getWineName(wine);
  const {
    id, name, image, quantity, vintage, region, country, isFavourite, category, grape, stock, producer, tastes, description
  } = wine;
  const grapeClass = `wine-grape grape_${category}`;
  const wineLocation = country?.toLowerCase() === region?.toLowerCase()
    ? ` ${region}` : ` ${region}, ${country}`

  const handleRemoveWine = async e => {
    e.preventDefault();

    setPendingState(true);

    await onRemove(id);

    setPendingState(false);
  }

  const handleQuantityChange = value => {
    if (!/\d+/.test(value) || +stock === 0) return false;

    const quantity = +value > wine.stock ? wine.stock : +value;

    onChange({ ...wine, quantity });
  }

  const isOutOfOrder = stock === 0 || stock < quantity


  return (
    <>
      <div className="card wine_card wine_recommendation">
        {
          isOutOfOrder &&
          <div className="out-of-stock-alert">
            <Alert text="This bottle is sold out">
              <button
                className="button-link"
                onClick={handleRemoveWine}
              >remove from case</button>
            </Alert>
          </div>
        }
        <div className="card-header">
          <img src={image} alt={name} />

          <div className="badge">{'x' + quantity}</div>

          {/* <p className="wine__name">{`${vintage} ${producer} ${name}`}</p> */}
          <p className="wine__name">{wineName}</p>

          <button
            className="del-button"
            disabled={isPending}
            onClick={handleRemoveWine}
          >{isPending ? <Spinner /> : <img src={deleteIcon} alt="" />}</button>
        </div>

        <div className="card__body">
          {
            isFavourite &&
            <div className="fav-label">
              {/* <span className="label gold"><img src={heartIcon} alt="" />YOUR FAV</span> */}
              <span className="label gold"><Icon icon="heart" color="white" />YOUR FAV</span>
            </div>
          }

          {/* <p>{vintage}</p>

          <h4 className="wine__name">{name}</h4> */}

          <p className="wine__region">
            {/* <img src={locationIcon} alt="location" /> */}
            <Icon icon="pin" color="warm-gray-9" size={21} />
            {wineLocation}
          </p>

          <div className="wine-space-between">
            <span className={grapeClass} style={{ justifyContent: 'flex-start !important' }}>{grape}</span>
          </div>

          <div className="wine-additional-data">
            <ShortDescription expandable={true} lines={5}>
              <p>{description}</p>
            </ShortDescription>

            <ul className="wine-tastes">{
              tastes?.map(t => <li key={t}>{t}</li>)
            }</ul>
          </div>
        </div>
      </div>

      <div className="custom-case__count-bar">
        {stock === 0 && <div className="out-of-stock-alert" />}

        <CountInput
          label="Qty:" negativeValues={false}
          placeHolder="--"
          disabled={isPending || isOutOfOrder}
          incButtonDisableNotification={
            quantity === stock ?
              `You may not add another bottle of this wine as our inventory is sold out. Please select an alternate bottle of your case.` : quantity === 12 ?
                `You may not add another bottle of this wine. There are already 12 bottles in this case.` : null
          }
          numOfBottles={numOfBottles}
          decButtonDisable={numOfBottles === 0 || isOutOfOrder}
          incButtonDisable={numOfBottles === 12 || quantity >= stock}
          containerClass="custom-case-count"
          inputClass="input" labelClass="label"
          onChange={handleQuantityChange}
          value={quantity}
        />
      </div>
    </>
  )
}

CustomCaseWineCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  quantity: PropTypes.number,
  numOfBottles: PropTypes.number,
  vintage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

CustomCaseWineCard.defaultProps = {
  image: 'https://vineify-dev-files.s3.amazonaws.com/images/wines/vineify_logo_bg.png',
  quantity: 0
}

export default memo(CustomCaseWineCard);
