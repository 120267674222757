import React, { useEffect, useState } from 'react' 
import PropTypes from 'prop-types'
import { Modal, useModal } from 'react-simple-hook-modal'
import { useRequests } from '../../hooks'

import './style.scss'
import { Spinner } from '..'


const GenerateAiDescription = ({ onSubmit, initialPromt, disabled }) => {
  const [state, changeState] = useState({
    prompt: initialPromt || ``, aiDescription: ``, processingRequest: false
  })
  const { post } = useRequests();
  const { isModalOpen, openModal, closeModal } = useModal();


  const handleOpenModal = e => {
    e.preventDefault();

    !disabled && openModal();
  }


  useEffect(() => {
    changeState(prev => ({ ...prev, prompt: initialPromt }))
  }, [initialPromt])

  const handleGetAiDescription = async e => {
    e.preventDefault();

    changeState(prev => ({ ...prev, processingRequest: true }))

    const response = await post({ url: '/api/wines/cases/generate-description', body: { prompt: state.prompt } })

    if (response?.data?.success) {
      changeState(prev => ({ ...prev, aiDescription: response.data.description?.trim(), processingRequest: false }))
    }
  }

  const handleChangeData = e => {
    const { name, value } = e.currentTarget;

    changeState(prev => ({ ...prev, [name]: value }))
  }

  const handleUseDescription = e => {
    e.preventDefault()
    
    onSubmit(state.aiDescription)

    closeModal()
  }


  return (
    <>
      <button
        className="button"
        style={{ 
          backgroundColor: disabled ? '#A59D95' : '#A2905A', color: 'white', height: '48px', width: 'auto', alignSelf: 'flex-end', marginTop: '8px', borderRadius: '4px' 
        }}
        type="button"
        disabled={ disabled }
        onClick={handleOpenModal}
      >Generate</button>
      <Modal isOpen={isModalOpen} modalClassName="modal-dialog large">
        <form className="ai-description-form">
          <div className="form-control">
            <label htmlFor="prompt">Prompt</label>

            <textarea
              name="prompt" id="prompt" rows="6"
              disabled={ state.processingRequest }
              value={state.prompt}
              onChange={ handleChangeData }
            />

            <br />

            <button className="button generate"
              style={{ alignSelf: 'flex-end' }}
              onClick={handleGetAiDescription}
              disabled={ state.processingRequest || disabled }
              type="button"
            >Generate{ state.processingRequest && <Spinner spinnerClass="pending" size={30} /> }</button>
          </div>

          <br />

          <div className="form-control">
            <label htmlFor="aiDescription">Generated Description</label>

            <textarea 
              name="aiDescription" 
              id="aiDescription" rows="6" 
              disabled={ state.processingRequest }
              value={ state.aiDescription }
            />
          </div>

          <br />
          <div className="form-control horizontal flex-center gap_sm">
            <button className="button button_outline"
              onClick={closeModal}
              type="button"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>

            <button className="button"
              disabled={ state.processingRequest }
              onClick={handleUseDescription}
              type="button"
            >Use Description</button>
          </div>
        </form>
      </Modal>
    </>
  )
}

GenerateAiDescription.propTypes = {
  onSubmit: PropTypes.func,
  initialPromt: PropTypes.string
}

export default GenerateAiDescription
