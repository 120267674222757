import React from 'react';
import PropTypes from 'prop-types';
import { Modal, useModal } from 'react-simple-hook-modal';
import { LoadingScreen, Spinner, WarehousePricingInfo } from '..';

import closeIcon from '../../assets/icons/close.svg';

import './style.scss';


const PriceWithPricingDetails = ({
  wines, isLoading, casePriceDetails, active, caseName, warehouseDeliveryFee, withDetails
}) => {
  const { closeModal, isModalOpen, openModal } = useModal();

  const shippingCostValue = 0; // will be added depends on selected country

  const handleOpenWinesPriceDetails = e => {
    e.preventDefault();

    openModal();
  }

  const { prices: { eur, usd } } = casePriceDetails;

  //   {
  //     "caseName": "An Italian Holiday",
  //     "caseId": 121,
  //     "priceFee": {
  //         "category": "serendipity",
  //         "subtotal": 218.995,
  //         "total": 226.0329505,
  //         "creditCardProcessingFee": 7.0379505,
  //         "creditCardFeeDescription": "2.99% + $0.49",
  //         "caseMarkup": 28.74,
  //         "serviceFee": 21.554999999999996,
  //         "totalPrice": 230,
  //         "totalWinesCost": 120,
  //         "totalWinesCostUsd": 143.7,
  //         "prices": {
  //             "eur": 190,
  //             "usd": 230
  //         }
  //     },
  //     "pricing": {
  //         "shippingCost": 190,
  //         "margin": 0.1,
  //         "bottleShippingCost": 13.47,
  //         "paymentProcessor": 0.03,
  //         "curatorFee": 0,
  //         "exchangeRate": 1.1764705882352942,
  //         "caseMarkup": 0.2,
  //         "serviceFee": 0.15
  //     }
  // }



  // {
  //   "winesPrice": 0,
  //   "prices": {
  //       "eur": 0,
  //       "usd": 0
  //   },
  //   "caseMarkup": 0.2,
  //   "serviceFee": 0.15,
  //   "priceFee": {
  //       "prices": {
  //           "success": true,
  //           "totalPrice": 0,
  //           "prices": {
  //               "eur": 0,
  //               "usd": 0
  //           }
  //       },
  //       "warehouse": "IWS",
  //       "winesPrice": 0,
  //       "caseMarkup": 0.2,
  //       "serviceFee": 0.15
  //   },
  //   "success": true,
  //   "totalPrice": 0,
  //   "totalPrices": {},
  //   "shippingCost": 190,
  //   "margin": 0.1,
  //   "bottleShippingCost": 13.47,
  //   "paymentProcessor": 0.03,
  //   "curatorFee": 0,
  //   "exchangeRate": 1.1764705882352942
  // }

  return (
    <>
      <div className="calculated-pricing" style={{ paddingTop: 0 }}>
        {
          isLoading ?
            <Spinner />
            :
            <span>Total: {!casePriceDetails?.warehouse?.includes('Serendipity') && <>€{eur} / </>}${usd}</span>
        }

        {
          withDetails &&
          <button
            className="button-link"
            disabled={!wines?.length}
            onClick={handleOpenWinesPriceDetails}
          > show details </button>
        }
      </div>

      <Modal isOpen={withDetails && isModalOpen} modalClassName="modal-dialog--relative">
        <button className="close-modal-btn" onClick={() => closeModal()}>
          <img src={closeIcon} alt="close modal" />
        </button>

        {
          isLoading
            ? (
              <LoadingScreen className="pricing-details-loading" />
            ) : (
              <WarehousePricingInfo
                caseName={caseName}
                wines={wines}
                warehouseDeliveryFee={warehouseDeliveryFee}
                {...casePriceDetails}
                priceFee={{
                  ...casePriceDetails.priceFee,
                  caseMarkup: casePriceDetails?.fee?.caseMarkup || 0,
                  serviceFee: casePriceDetails?.fee?.serviceFee || 0
                }}
                shippingCostValue={shippingCostValue}
              />
            )
        }
      </Modal>
    </>
  )
}


PriceWithPricingDetails.propTypes = {
  wines: PropTypes.array,
  casePriceDetails: PropTypes.shape({
    margin: PropTypes.number,
    shippingCost: PropTypes.number,
    paymentProcessor: PropTypes.number,
    curatorFee: PropTypes.number,
    warehouse: PropTypes.string,
    exchangeRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    caseMarkup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    serviceFee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalWinesCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalWinesCostUsd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shippingCostValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priceFee: PropTypes.shape({
      caseMarkupValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      serviceFeeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      creditCardProcessingFee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    totalPrices: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    prices: PropTypes.shape({
      usd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      eur: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  })
}


export default PriceWithPricingDetails;
