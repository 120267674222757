import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountNavCard, Icon, Select } from '..';


import './style.scss';


const RetailerNavigation = () => { 
  const location = useLocation();
  const history = useHistory();
  const fullPath = location.pathname;
  const currentPath = [...location.pathname.split('/')].pop();

  const handleChangeRetailerSection = data => {
    history.push(data.value)
  }


  const nav = [
    {
      path: '/retailer/orders',
      icon: 'orders',
      active: fullPath.includes('orders'),
      color: fullPath.includes('orders') ? 'white' : 'vineify-green',
      title: 'Orders',
      description: "Orders"
    },
    {
      path: '/retailer/wines',
      icon: 'wine-glass',
      active: fullPath.includes('wines'),
      title: 'Wines',
      color: fullPath.includes('wines') ? 'white' : 'vineify-green',
      description: "Wines"
    },
    {
      path: '/retailer/contacts',
      icon: 'user',
      active: currentPath === 'contacts',
      title: 'Settings',
      color: currentPath === 'contacts' ? 'white' : 'vineify-green',
      description: "Contact Details"
    },
    {
      path: "/retailer/integration",
      active: currentPath === 'integration',
      icon: 'preferences',
      title: "Merchant Account",
      color: currentPath === 'integration' ? 'white' : 'vineify-green',
      description: "Square merchant account settings"
    }
  ];

  if (window.matchMedia('(max-width: 520px)').matches) {
    const options = nav.map(navData => ({ 
      ...navData, 
      label: <p className="icon-option"><Icon icon={ navData.icon } size={ 18 } /> {navData.title}</p>, 
      value: navData.path 
    }))

    return (
      <div className="manager-nav-wrapper container flex-col">
        <p className="manager-nav-title">Retailer Navigation</p>
  
        <Select
          name="retailer-nav-menu"
          placeholder="Select Retailer Section"
          options={ options}
          value={ options?.find(optionData => location.pathname.includes(optionData.path)) }
          onChange={ handleChangeRetailerSection }
        />
      </div>
    )
  }

  const navCards = nav.map(
    cardData => <AccountNavCard key={cardData.title} {...cardData} isSvg={ true } size={ 20 } />
  );


  return (
    <div className="container">
      <div className="warehouses-nav">{navCards}</div>
    </div>
  )
}


export default RetailerNavigation;
