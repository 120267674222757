import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountNavCard, Icon, Select } from '..';

import order from '../../assets/icons/order-list.svg';
import wines from '../../assets/icons/wine-glass.svg';

import './style.scss';


const WarehousesNavigation = () => {
    const location = useLocation();
    const history = useHistory();
    const currentPath = [ ...location.pathname.split('/') ].pop();

    const handleChangeWarehouseSection = data => {
      history.push(data.value)
    }

    const nav = [
        { 
            path: '/inventory/orders', 
            icon: order,
            active: currentPath === 'orders',
            title: 'Orders',
            description: "List of Orders"
        },
        { 
            path: "/inventory/wines",
            active: currentPath === 'wines',
            icon: wines,
            title: "Wines",
            description: "List of Wines"
        }
    ];

    if (window.matchMedia('(max-width: 520px)').matches) {
      const options = nav.map(navData => ({ 
        ...navData, 
        label: <><Icon icon={ navData.icon } size={ 20 } /> {navData.title}</>, 
        value: navData.path 
      }))
  
      return (
        <div className="d-flex-column">
          <p className="no-margin">Navigation</p>
    
          <Select
            name="retailer-nav-menu"
            placeholder="Select Retailer Section"
            options={ options}
            onChange={ handleChangeWarehouseSection }
          />
        </div>
      )
    }

    const navCards = nav.map(
        cardData => <AccountNavCard key={ cardData.title } { ...cardData } />
    );
    
    return (
        <div className="container">
            <div className="warehouses-nav">{ navCards }</div>
        </div>
    )
}


export default WarehousesNavigation;
