import axios from 'axios'


export const getProducerData = async ({ producerId }) => {
  const response = await axios.get(`/api/producers/${producerId}`)

  if (response?.data?.success) {
    const { producerData } = response.data

    return producerData
  }

  return undefined
}

export const getProducerCasesPreviewsList = async ({ producerId, regionId }) => {
  const response = await axios.get(`/api/producers/${producerId}/cases${regionId ? `?rid=${regionId}` : ``}`)

    if (response?.data?.success) {
      const { cases = [], regionCases = [] } = response.data;

      return { cases, regionCases }
    }

    return {}
}


export const getProducersPreviewList = async () => {
  const response = await axios.get(`/api/producers`)

  if (response?.data?.success) {
    return response.data.producers || []
  }

  return []
}