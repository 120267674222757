import React from 'react';
import Spiner from './LoadingSpinner';

import './style.scss';


const LoadingScreen = ({ className }) => {
    const classes = className ? `load-screen ${className}` : `load-screen`;

    return (
        <div className={ classes }><Spiner /></div>
    )
}

export default LoadingScreen;
