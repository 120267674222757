import React from 'react';
import ReactSelect from 'react-select'; 
import Icon from '../common/Icon';

const Select = (props) => {
  // const createWinesInventory = wines => {
  //   const warehouseWines = wines[props?.warehouse];
  //   const inventoryList = Object.keys(warehouseWines)?.map(category => {
  //     const listItem = (
  //       <li key={`wine_inventory_${category}`}
  //         className="stock-badge"
  //       >{warehouseWines[category]} {
  //         category !== 'all'
  //           ? <span className={`icon ${category}`} />
  //           : <>wines:</>
  //       }</li>
  //     )

  //     return listItem;
  //   })

  //   return (
  //     <ul className="dropdown-wines-inventory">{ inventoryList }</ul>
  //   )
  // }

  const createWinesInventory = wines => {
    const warehouseWines = wines[props?.warehouse];

    return (
      <ul className="dropdown-wines-inventory"><li key={`wine_inventory_all`}>{warehouseWines?.all} wines</li></ul>
    )
  }

  const classes = ['select-wrapper']
  props.wrapperClass && classes.push(props.wrapperClass)

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '16px 24px',
      lineHeight: 1,
      zIndex: 120,
      color: state.isSelected ? 'white' : '#333231',
      backgroundColor: state.isSelected ? ' #224C31' : 'white',
      borderBottom: '1px solid #C7C2BA',
      cursor: 'pointer',
      ...props.optionStyles
    }),
    container: (provided, state) => ({
      ...provided,
      ...props.containerProps
    }),
    indicatorSeparator: () => ({}),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      borderWidth: '2px',
      // borderColor: 'rgba(209, 173, 130, 0.25) !important',
      borderColor: state.isDisabled ? '#FAF9F5 !important' : '#C7C2BA !important',
      padding: '0 16px',
      color: state.isDisabled ? '#ABA9A7' : '#403D3B',
      background: state.isDisabled ? '#FAF9F5' : 'white',
      fontSize: '16px',
      ...props.style
    }),
    menu: (provided, state) => ({
      ...provided,
      background: 'white',
      borderRadius: '8px',
      border: '2px solid #C7C2BA',
      ...props.menuStyle
    }),
    menuList: (provided, state) => ({
      ...provided,
      ...props.menuListStyle
    }),
  };


  return (
    <div className={classes.join(' ')}>
      <ReactSelect
        isSearchable={false}
        isDisabled={props.disabled}
        styles={customStyles}
        menuPlacement={ props.menuPosition || "auto" } 
        {...props}
        filterOption={(candidate, input) => {
          if (input) {
            if (candidate?.data?.email) {
              return candidate?.data?.label?.toLowerCase().includes(input?.toLowerCase())
              || candidate?.data?.email?.toLowerCase().includes(input?.toLowerCase())
            } else {
              return candidate?.data?.label?.toLowerCase().includes(input?.toLowerCase())
            }
          }

          return true;
        }}
        getOptionLabel={optionData => {
          const label = optionData?.email
            ? <p className="no-margin no-padding"><b>{optionData.label}</b><br />{optionData.email}</p>
            : optionData?.wines
              ? <div className="regions-with-inventory-list-item"><b>{optionData.label}</b>
                {createWinesInventory(optionData?.wines)}</div>
              : optionData.label

          return optionData.icon ? <>{label}{'  '}<Icon icon={optionData.icon} color={ optionData.color || 'black' } size={12} /></> : label 
        }}
      />
    </div>
  );
}


export default Select;
