import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../common/Icon'
import { toast } from 'react-toastify'


const ClipboardCopyButton = ({ link, successMessage }) => {

  const handleCopyLink = e => {
    e.preventDefault();

    // const link = `${document.location.host}/cases/${slug}`;

    navigator.clipboard.writeText(link)
      // .then(() => toast.success(`Case "${name}" link was copied to clipboard`));
      .then(() => toast.success(successMessage));
  }

  return (
    <button
      className="button-link icon-xs-button"
      onClick={handleCopyLink}
    ><Icon icon="link-solid" size={18} color="vineify-green" /></button>
  )
}

ClipboardCopyButton.propTypes = {
  link: PropTypes.string,
  successMessage: PropTypes.string
}

ClipboardCopyButton.defaultProps = {
  successMessage: `Link was copied to clipboard`
}

export default ClipboardCopyButton