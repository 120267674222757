import axios from 'axios'

//TODO: config headers??

class ApiClient {
  constructor(baseURL, headers) {
    this.instance = axios.create({ baseURL, headers })
  }

  get = async ({ url }, config) => {
    try {
      const response = await this.instance.get(url, config);
  
      return response.data;
    } catch (err) {
      return err?.response || err;
    }
  }

  post = async ({ url, data }, config) => {
    try {
      const response = await this.instance.post(url, data, config);
  
      return response.data;
    } catch (err) {
      return err?.response?.data || err;
    }
  }

  delete = async ({ url }, config) => {
    try {
      const response = await this.instance.delete(url, config);
  
      return response.data;
    } catch (err) {
      return err?.response || err;
    }
  }
}


export default ApiClient;


// get({ url: `/api/users/${userId}?wh=1` }),
// get({ url: `/api/content/recommended-cases-list?cid=${userId}` }),
// get({ url: '/api/shipping/countries-list' }),
// get({ url: `/api/content/warehouses-list` }),
// /api/orders/${cart.order.id}/add-contacts
// get({ url: `/api/content/regions/${regionName}?lng=${i18n.language}&new=2` }),
// get({ url: '/api/content/regions?main=true&new=2' }),
// get({ url: `/api/wines/grapes?lng=${i18n.language}&region=${regionName}&cat=${grapesCategories}` }),
// get({ url: `/api/wines/cases?region=${regionName}&lng=${i18n.language}&new=2` }),