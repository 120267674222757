import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../components';

import { statesOfUSA, countries } from '../../temp/mock-data';

import './style.scss';


const CustomerInfoForm = ({ 
    errors, name, fields, onChange, disabled
}) => {

    const handleChange = e => {
        const [contactType, name] = e.target.name.split('-');

        const value = e.target.value;
    
        onChange(contactType, name, value);
    }


    const handleChangeSelectValue = (name, field, value) => {
        onChange(name, field, value.value);
    }


    const statesOptions = Object.keys(statesOfUSA).map(
        st => ({ 
            value: st, 
            label: statesOfUSA[st] 
        })
    );

    const countriesOptions = countries.map(
        ({ name, code }) => ({
            value: code, 
            label: name 
        })
    )



    return (
        <>
            <div className="form__row">
                <div className="form-control">
                    <label htmlFor={`${name}-email`}>Email</label>
                    <input 
                        type="text" placeholder="email" 
                        name={`${name}-email`}
                        disabled={ disabled }
                        value={ fields.email }
                        onChange={ handleChange }
                    />
                    {
                        errors.has(`${name}-email`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
                </div>

                <div className="form-control">
                    <label htmlFor={`${name}-phone`}>Mobile phone number</label>
                    <input 
                        type="text" placeholder="mobile phone number"
                        name={`${name}-phone`}
                        disabled={ disabled }
                        value={ fields.phone }
                        onChange={ handleChange }
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form-control">
                    <label htmlFor={`${name}-firstName`}>First Name*</label>
                    <input 
                        type="text" placeholder="First name"
                        name={`${name}-firstName`}
                        disabled={ disabled }
                        value={ fields.firstName }
                        onChange={ handleChange }
                    />
                    {
                        errors.has(`${name}-firstName`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
                </div>
                <div className="form-control">
                    <label htmlFor={`${name}-lastName`}>Last name*</label>
                    <input 
                        type="text" placeholder="Last name"
                        name={`${name}-lastName`}
                        disabled={ disabled }
                        value={ fields.lastName }
                        onChange={ handleChange }
                    />
                    {
                        errors.has(`${name}-lastName`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
                </div>
            </div>
            <div className="form-control">
                <label htmlFor={`${name}-company`}>Company(optional)</label>
                <input 
                    type="text" placeholder="company"
                    name={`${name}-company`}
                    disabled={ disabled }
                    value={ fields.company }
                    onChange={ handleChange }
                />
                {
                        errors.has(`${name}-company`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
            </div>
            <div className="form__row">
                <div className="form-control">
                    <label htmlFor={`${name}-address`}>Address*</label>
                    <input 
                        type="text" placeholder="Address"
                        name={`${name}-address`}
                        disabled={ disabled }
                        value={ fields.address }
                        onChange={ handleChange }
                    />
                    {
                        errors.has(`${name}-address`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
                </div>
                <div className="form-control">
                    <label htmlFor={`${name}-appartment`}>Apt, suite, etc. (optional)</label>
                    <input 
                        type="text" placeholder="Apt, suite, etc"
                        name={`${name}-appartment`}
                        disabled={ disabled }
                        value={ fields.appartment }
                        onChange={ handleChange }
                    />
                    {
                        errors.has(`${name}-appartment`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
                </div>
            </div>
            <div className="form__row">
                <div className="form-control">
                    <label htmlFor={`${name}-city`}>City*</label>
                    <input 
                        type="text" placeholder="City"
                        name={`${name}-city`}
                        disabled={ disabled }
                        value={ fields.city }
                        onChange={ handleChange }
                    />
                    {
                        errors.has(`${name}-city`) &&
                        <small className="error-msg">require at least 3 characters</small>
                    }
                </div>
                <div className="form-control">
                    <label htmlFor={`${name}-state`}>State</label>
                    <Select
                        name={`${name}-state`}
                        disabled={ disabled }
                        placeholder="State" options={ statesOptions }
                        onChange={ value => handleChangeSelectValue(name, 'state', value) }
                        value={ { value: fields.state, label: statesOfUSA[fields.state] } }
                    />
                </div>                
            </div>
            <div className="form__row">
            <div className="form-control">
                <label htmlFor={`${name}-zip`}>ZIP code*</label>
                <input 
                    type="text" placeholder="zip code"
                    name={`${name}-zip`}
                    disabled={ disabled }
                    value={ fields.zip }
                    onChange={ handleChange }
                />
                {
                    errors.has(`${name}-zip`) &&
                    <small className="error-msg">require at least 3 characters</small>
                }
            </div>
            <div className="form-control">
                <label htmlFor={`${name}-country`}>Country</label>
                <Select
                    name={`${name}-country`}
                    disabled={ disabled }
                    placeholder="Country" options={ countriesOptions }
                    onChange={ value => handleChangeSelectValue(name, 'country', value) }
                    value={ { value: fields.country, label: countries[fields.country] } }
                />
            </div> 
            </div>          
        </>
    )
}


CustomerInfoForm.propTypes = {
    errors: PropTypes.any,
    name: PropTypes.string,
    fields: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        company: PropTypes.string,
        address: PropTypes.string,
        appartment: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.oneOfType([ 
            PropTypes.number, PropTypes.string 
        ]),
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}

CustomerInfoForm.defaultProps = {
    fields: PropTypes.shape({
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        company: '',
        address: '',
        appartment: '',
        city: '',
        country: 'US',
        state: 'AL',
        zip: '',
    }),
    errors: [],
    disabled: false
}

export default CustomerInfoForm
