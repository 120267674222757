import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHelpers } from '../../hooks';

const AdminUserRow = ({ onUsereDelete, name, numOfOrders, id, createdAt, email, role }) => {
  const { formatDateTime } = useHelpers();
  const colorClass = role.split(' ')[0];

  const handleDeleteUser = e => {
    e.preventDefault();

    onUsereDelete(id);
  }

  return (<div className="admin-user-row-wrapper">
    <Link to={`/admin/users/${id}`} className={`admin-user-row ${colorClass}`}>
      <span className="id">{id}</span>
      <span className="name">{name}</span> 
      <span className="email">{email}</span>
      <span className="orders">{numOfOrders}</span>
      <span className="date">{formatDateTime(createdAt, false)}</span>
    </Link>
      {
        role === 'test user' ?
        <button 
        className="tools button button_red button_xs"
        onClick={ handleDeleteUser }
        >delete</button>
        :
        <span className="tools" />
      }
  </div>)
}

AdminUserRow.propTypes = {
  onUsereDelete: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.number,
  numOfOrders: PropTypes.number,
  email: PropTypes.string,
  createdAt: PropTypes.string,
  role: PropTypes.oneOf(['test user', 'registered user', 'admin', 'warehouse'])
}

AdminUserRow.defaultProps = {
  name: '',
  numOfOrders: 0
}

export default AdminUserRow
