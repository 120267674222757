import { createSlice } from '@reduxjs/toolkit'
import { requestAddAdjustedCaseToCart, requestAddCaseToCart, requestUserOrder } from '../checkout/checkout.actions'

const initialState = {
  order: {},
  caseIsAdjusted: false,
  cases: [] // for multi cases cart
}


const cartSlice = createSlice({
  name: '@@cart',
  initialState: { ...initialState },
  reducers: {
    clearCartData: () => ({ ...initialState }),
    setOrderData: (state, action) => {
      state.order = action.payload
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestAddCaseToCart.fulfilled, (state, { payload }) => {
        state.order.id = payload.id
        if (payload.caseDetails) {
          const caseDetails =  JSON.parse(payload.caseDetails)
          state.order.cost = +caseDetails?.cost
          state.order.case = caseDetails
          state.cases = [...state.cases, caseDetails]
        }
      })
      .addCase(requestAddAdjustedCaseToCart.fulfilled, (state, { payload }) => {
        const { caseData } = payload
        state.order.id = payload.id
        if (payload.caseDetails) {
          const caseDetails =  { ...JSON.parse(payload.caseDetails), isAdjustedCase: true }
          state.order.cost = +caseDetails?.cost
          state.order.case = caseDetails
          state.cases = [...state.cases, caseDetails]
        }
        
        state.caseIsAdjusted = true;
      })
      .addCase(requestUserOrder.fulfilled, (state, { payload }) => {
        const { order, orderCase } = payload;
        state.order = { 
          ...state.order, 
          ...order,
          case: orderCase 
        }
        state.cases = [ orderCase ]
      })
})


export const cartActions = { ...cartSlice.actions, requestAddCaseToCart, requestAddAdjustedCaseToCart };

export default cartSlice.reducer;
