import {
  SET_ORDER_CAPTURE_ACTION, REMOVE_ORDER_CAPTURE_ACTION, SET_SHIPPING_COUNTRY, SET_SHIPPING_ZIPCODE, SET_ORDER_WAREHOUSE, SET_ORDER_DATA, SET_SQUARE_DATA, SET_PAYMENT_METHOD, CHANGE_PAYMENT_STEP, RESET_CHECKOUT_STATE, CHANGE_RETAILER, CHANGE_SHIPPING_DETAILS, CHANGE_BILLING_DETAILS, CHANGE_IS_GIFT, CHANGE_GIFT_MESSAGE
} from './actions';


const initialState = {
  order: undefined, 
  shipping: undefined, 
  billing: undefined,
  orderCapture: undefined, 
  shippingCountry: '',
  zip: undefined,
  warehouse: undefined,
  square: {},
  paymentMethod: undefined,
  paymentStep: ''
};

const checkoutReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_GIFT_MESSAGE:
      return { ...state, giftMessage: payload};
    case CHANGE_IS_GIFT:
      return { ...state, isGift: !state.isGift };
    case CHANGE_BILLING_DETAILS:
      return { ...state, billing: payload };
    case CHANGE_SHIPPING_DETAILS:
      return { ...state, shipping: payload };
    case CHANGE_RETAILER:
      return { ...state, retailer: payload };
    case CHANGE_PAYMENT_STEP:
      return { ...state, paymentStep: payload };
    case SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: payload };
    case SET_SQUARE_DATA:
      return { ...state, square: payload };
    case SET_ORDER_DATA:
      return { ...state, order: payload };
    case SET_ORDER_WAREHOUSE:
      return { ...state, warehouse: payload };
    case SET_SHIPPING_COUNTRY:
      return { ...state, shippingCountry: payload };
    case SET_SHIPPING_ZIPCODE:
      return { 
        ...state, zip: payload,
        shipping: {
          ...state.shipping, zip: payload
        }
      };
    case SET_ORDER_CAPTURE_ACTION:
      return { ...state, orderCapture: payload };
    case REMOVE_ORDER_CAPTURE_ACTION:
      return { ...state, orderCapture: null, shippingCountry: '' };
    case RESET_CHECKOUT_STATE:
      return { ...state, ...initialState };
    default:
      return { ...state };
  }
}


export default checkoutReducer;
