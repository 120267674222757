import React from 'react';
import PropTypes from 'prop-types';

const CircleItems = ({ fragments, center, radius, fitToParent }) => {

    if (!fragments.length) return null;

    const content = fragments.map((el, ind) => {
        const angle = 360 * ind / fragments.length;
        return <div
                  className={`inner-wrapper${fitToParent ? ' fit-to-parent' : ''}`}
                  key={ '' + angle + ind }
                  style={{ transform: `rotate(${angle}deg) translateY(-${radius}px) rotate(-${angle}deg)` }}
               >{el}</div>
    

    })
    return (
        <div className="circle-container">
            {
                !!center && <div className="inner-wrapper center-icon">{center}</div>
            }
            {content}            
        </div>
    )
}

CircleItems.propTypes = {
    fragments: PropTypes.array,
    fitToParent: PropTypes.bool
}

CircleItems.defaultProps = {
    fitToParent: false
}

export default CircleItems
