import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import badgeIcon from '../../assets/icons/map.svg'


const NewItemBadge = ({ text, color, withIcon }) => {
  const badgeClass = `new-item-badge ${color}`;

  if (!text?.length) return null;

  return (
    <div className={ badgeClass }>
        {
          withIcon &&
          <img className="badge-icon" src={ badgeIcon } alt="badge icon"/> 
        }        
        <p className="badge-text">{ text }</p>
    </div>
  )
}


NewItemBadge.propTypes = {
    text: PropTypes.string,
    color: PropTypes.oneOf(['gold', 'green', 'red', 'white'])
}

NewItemBadge.defaultProps = {
  color: 'gold',
  text: ''
}

export default NewItemBadge;
