import { useSelector, useDispatch } from "react-redux"
import { 
  authenticate, login, logout, requestChangeAccountData, requestChangeLegalAge, requestCurrentUserOrders, requestRegisterUser, requestAiSignIn, requestFollowCurator
} from './auth.actions'
import { authActions as actions } from './auth.slice'


const useAuth = () => {
  const {
    errors,
    processingRequest,
    processingRequests,
    regionRecommendationCase,
    redirectPath,
    location,
    userAgent,
    user,
    isAuthenticated,
    ip,
    country,
    region,
    isAiAuthenticated,
    initialDataIsFetched, answer
  } = useSelector(state => state.auth)
  const authState = {
    errors, processingRequest, processingRequests, regionRecommendationCase, redirectPath, location, userAgent, user, isAuthenticated, ip, country, region, isAiAuthenticated, initialDataIsFetched, answer
  }
  const dispatch = useDispatch();
  const authActions = {}

  authActions.authenticate = (redirectPath) => dispatch(authenticate(redirectPath))

  authActions.logout = (redirectPath) => dispatch(logout(redirectPath))

  authActions.requestAiSignIn = (password) => dispatch(requestAiSignIn(password))

  authActions.requestCurrentUserOrders = () => dispatch(requestCurrentUserOrders())

  authActions.requestFollowCurator = (curatorId, index) => dispatch(requestFollowCurator({curatorId, index}))

  authActions.requestChangeLegalAge = ({isAdult, redirectPath}) =>
    dispatch(requestChangeLegalAge({isAdult, redirectPath}))

  authActions.login = ({ email, password, keepSigned, redirectPath }) => 
    dispatch(login({ email, password, keepSigned, redirectPath }))

  authActions.requestChangeAccountData = ({ email, firstName, lastName }) =>
    dispatch(requestChangeAccountData({ email, firstName, lastName }))
  
  authActions.requestRegisterUser = ({ device, email, firstName, lastName, location, password, zipcode, redirectPath }) =>
    dispatch(requestRegisterUser({ device, email, firstName, lastName, location, password, zipcode, redirectPath }))

  authActions.setRecommendedCaseId = (recommendedCase) => dispatch(actions.setRecommendedCaseId(recommendedCase))

  authActions.changeRedirectPath = (redirectPath) => dispatch(actions.changeRedirectPath(redirectPath))

  authActions.changeUserdata = (userData) => dispatch(actions.changeUserdata(userData))

  authActions.clearErrors = () => dispatch(actions.clearErrors())


  return { authState, authActions }
}


export default useAuth;