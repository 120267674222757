import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRequests } from '../../hooks';


const CreateProducerForm = ({ onSubmit }) => {
  const [producer, changeProducer] = useState('');
  const { post, requestPending } = useRequests();

  const changeProducerName = e => {
    e.preventDefault();

    changeProducer(e.target.value);
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const response = await post({
      url: '/api/users/producer',
      body: { producer }
    });

    if (response?.data?.success) {
      onSubmit(response.data.newProducer)
    }
  }


  return (
    <form className="form-horizontal">
      <div className="form-control">
        <label htmlFor="producer">Producer name</label>

        <input
          type="text" name="producer"
          onChange={changeProducerName}
          value={producer}
        />
      </div>

      <div className="form-control">
        <button
          className="button"
          onClick={handleSubmit}
          disabled={requestPending}
        >Add Producer</button>
      </div>
    </form>
  )
}


CreateProducerForm.propTypes = {
  onSubmit: PropTypes.func
}

export default CreateProducerForm;
