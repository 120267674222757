import React from 'react'
import PropTypes from 'prop-types'

const AdminCaseWinesCategories = ({ categories, onChange }) => {

  const handleToggleCategory = (category) => e => {
    e.preventDefault();

    onChange(category)
  }

  const content = ['red', 'rose', 'white', 'sparkling'].map(category => {
    const classes = ['button','category-button', 'button_xs'];
    categories.includes(category) && classes.push('active');

    
    return (
      <button 
        key={`wine_cat_btn_${ category }`} 
        className={classes.join(' ')}
        onClick={ handleToggleCategory(category) }
      >{ category }</button>
    )
  })

  return (
    <div className="wines-categories-buttons">{ content }</div>
  )
}

AdminCaseWinesCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string), 
  onChange: PropTypes.func
}

export default AdminCaseWinesCategories