import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';


const PriceDetails = ({ subtotal, total, tax, shiping, currency, caseName }) => {
  const currencySymbol = currency === 'usd' ? '$' : '€';

  return (
    <ul className="price-details">
      {
        caseName?.length > 0 &&
        <li className="price-details__case">Purchase units: <b>1 x "{caseName}"</b></li>
      }

      <li className="price-details__subtotal">Subtotal Cost: <b>{currencySymbol}{subtotal?.toFixed(2)}</b></li>

      {
        shiping > 0 &&
        <li className="price-details__shiping">{currencySymbol}{shiping}</li>
      }

      {
        tax > 0 &&
        <li className="price-details__tax"><span>+8.25% Tax</span> <b>{currencySymbol}{tax?.toFixed(2)}</b></li>
      }

      <li className="price-details__total">Total Cost: <b>{`${currencySymbol}${total?.toFixed(2)}`}</b></li>
    </ul>
  )
}

PriceDetails.propTypes = {
  subtotal: PropTypes.number,
  total: PropTypes.number,
  tax: PropTypes.number,
  shipping: PropTypes.number,
  currency: PropTypes.oneOf(['eur', 'usd'])
}

PriceDetails.defaultProps = {
  subtotal: 0,
  total: 0,
  tax: 0,
  shipping: 0,
  currency: 'usd'
}

export default PriceDetails;