import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useActions } from '../hooks';

const RedirectContext = createContext()
const Provider = RedirectContext.Provider


const RedirectProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { authActions } = useActions()
  const { redirectPath } = useSelector(state => state.auth);
  const history = useHistory();


  useEffect(() => {
    if (redirectPath?.length > 0) {
      const path = redirectPath;
      dispatch(authActions.changeRedirectPath(null));

      history.push(path)
    }
  }, [redirectPath])
  
  return <Provider value={{}}>{ children }</Provider>
}

export default RedirectProvider
