import { useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import axios from 'axios'


const useTranslations = () => {
  const { i18n } = useTranslation()

  const initLanguageResources = useCallback(async () => {
    const response = await axios.get('/api/content/translations');

    if (response?.data?.success) {
      const { resources } = response.data;

      for (const lng of Object.keys(resources)) {
        i18n.addResources(lng, 'translations', resources[lng]);
      }
    }
  }, [])

  useEffect(initLanguageResources, [])
}

export default useTranslations
