import React, { useContext, useMemo, useState } from 'react';
import scrollTo from 'smooth-scroll-into-view-if-needed'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckoutContext } from '../../context';
import { OrderSidebar, Alert, OrderContactSummary, EditableText } from '..';
import { useActions, useAnalitics, useHelpers, useRequests } from '../../hooks';
import Contact from '../../classes/Contact';
import Sticky from 'react-sticky-el';
import Case from '../../classes/Case';
import { Icon } from '..';

import paymentLogo from '../../assets/icons/square-logo.svg';
import alertIcon from '../../assets/icons/warning.svg';

import './style.scss';
import { useDispatch, useSelector } from 'react-redux';


const OrderSquareConfirmStep = ({ onEditData, contacts, paymentToken, onFail }) => {
  const dispatch = useDispatch();
  const { checkoutActions } = useActions()
  const { 
    paymentDetails, isGift, giftMessage, retailer, warehouse: caseWarehouse, shipping, billing, error: checkoutError
  } = useSelector(state => state.checkout)
  const { order: { case: orderCase, id: currentOrderId } } = useSelector(state => state.cart)
  const { user } = useSelector(state => state.auth)
  const { countriesTranslations, currentCountry } = useSelector(state => state.regions)
  const { sendMxCustomEvent } = useAnalitics()
  const { t, i18n: { language: lng } } = useTranslation();
  const { post } = useRequests();
  const { getPaypalPriceDetails, formatPhoneValue } = useHelpers();
  const [processingPayment, setProcessingPayment] = useState(false);
  const { shippingCountry, changePaymentStep, changeGiftMessage } = useContext(CheckoutContext);

  // brand, expMonth, expYear, last4
  const { card: { brand, last4 } } = paymentDetails?.details || { card: {} };
  const paymentInfoContent = `${brand}   **** **** **** ${last4}`


  //TODO: check
  const caseData = new Case(orderCase);
  const price = caseData.getPrice('usd', 0, false);
  const [error, setError] = useState();
  const { total } = getPaypalPriceDetails({
    casePrice: price, shipping: 0,
    tax: 8.25,
    user: user?.role
  })


  const {
    firstName, lastName, email, phone, country, zip, address, city, state
  } = shipping;
  const fullName = `${firstName} ${lastName}`;

  const checkoutMessage = retailer?.checkoutMessage || caseWarehouse?.checkoutMessage;

  const zipcodes = orderCase?.wines?.find(wineData => wineData?.warehouse?.zipcodes)?.warehouse?.zipcodes;

  const paypalShippingCountry = Contact.getCountryName(country);

  const verifyed = currentCountry === shippingCountry;
  const outOfShippingRange = zipcodes && !zipcodes.split(',')?.find(zipCode => zipCode?.trim() === '' + zip)

  const confirmReport = async () => {
    await post({
      url: '/api/orders/confirm',
      body: {
        contacts: { address, city, state, zip, country, email, phone },
        description: `Payment method: "Square". Purchased units: 1 x "${orderCase.name}"`
      }
    });
  }

  const shippingErrorIsVisible = useMemo(() => !zipcodes && !verifyed, [verifyed, zipcodes])

  //eslint-disable-next-line
  // useEffect(() => { confirmReport() }, []);

  // const warehouse = orderCase?.getWareHouseData();

  const handleChangeGiftMessage = text => {
    // changeGiftMessage(text)
    dispatch(checkoutActions.changeGiftMessage(text))
  }

  const handleEditContactData = editStep => e => {
    e.preventDefault();

    // changePaymentStep(editStep)
    dispatch(checkoutActions.changePaymentStep(editStep))
    // changePaymentStep(editStep)
  }

  const handlePlaceOrder = async e => {
    e.preventDefault();

    dispatch(checkoutActions.requestPlaceOrder())
      .unwrap()
      .catch(err => {
        if (['GENERIC_DECLINE', 'CVV_FAILURE', 'INVALID_EXPIRATION'].includes(err?.errorCode)) {
          // dispatch(checkoutActions.changePaymentStep('billing'))
        }
      })
    
    // setProcessingPayment(true)

    // const response = await post({
    //   url: `/api/orders/${currentOrderId}/create-payment`,
    //   body: { tokenData: { ...paymentDetails, rid: retailer.id }, shipping, billing, isGift, giftMessage }
    // });

    // if (response?.data?.success) {
    //   ctx.cart.shipping = { ...shipping }; // get data from checkout.shipping
    //   ctx.user = { ...ctx.user, hasNewWines: true }; // add to placeOrder.fullfiled payload

    //   const { orderId, orderPlaced, newUserData } = response.data;

    //   sendMxCustomEvent('Purchase', {
    //     ...user,
    //     userId: user.id,
    //     userName: `${user.firstName} ${user.lastName}`,
    //     caseId: orderCase.id,
    //     caseName: orderCase.name,
    //     casePrice: total + '$',
    //     role: user.role,
    //     test_order: user.role !== 'registered user'
    //   });

    //   if (newUserData) {
    //     const { firstName, lastName } = newUserData;
    //     ctx.user.firstName = firstName?.length > 0 ? firstName : ctx.user.firstName
    //     ctx.user.lastName = lastName?.length > 0 ? lastName : ctx.user.lastName
    //   }

    //   ctx.cart.order.id = orderId;
    //   ctx.cart.order.datePlaced = orderPlaced;
    //   ctx.cart.order.step = 'complete'; 
    //   ctx.cart.orderCapture = null; // add to placeOrder.fullfiled payload

    //   changePaymentStep('complete')

    //   history.push('/cart/success');
    // } else {
    //   setError(true);

    //   onFail();
    //   setTimeout(() => {
    //     const errorAlertContainer = document.getElementById('checkout-error')

    //     scrollTo(errorAlertContainer, { behavior: 'smooth', scrollMode: 'if-needed', block: 'start' })
    //   }, 200)
    // }

    // setProcessingPayment(false);
  }

  // useEffect(() => {
  //   paymentStep === 'complete' && history.push('/cart/success')
  // }, [paymentStep])

  // if (paymentStep === 'complete') {
  //   console.log('Redirecting to success page: ')
  //   console.log(orderCase)

  //   return <Redirect to="/cart/success" />
  // }


  return (
    <>
      <h2>{t('CHECKOUT_ORDER_CONFIRMATION_TITLE')}</h2>

      <p>{t('CHECKOUT_ORDER_CONFIRMATION_SUBTITLE')}</p>

      {checkoutMessage && <p>{checkoutMessage}</p>}

      {
        isGift &&
        <>
          <div className="gift-section">
            <div className="checkbox-wrapper no-padding">
              <Icon icon="gift" size={20} /> This is a gift
            </div>
          </div>

          <EditableText
            initialText={giftMessage}
            title="Gift Message"
            className="gift-message"
            placeholder="(none provided)"
            onConfirmChanges={handleChangeGiftMessage}
          />
        </>
      }

      <div className="order-data">
        <div className="order-data__block">
          <OrderContactSummary
            title={t('SHIPPING_ADDRESS')}
            {...shipping}
            editStep="shipping"
          />
        </div>

        <div className="order-data__block">
          <OrderContactSummary
            title="Billing Address"
            {...billing}
            editStep="billing"
          />
        </div>

        {
          shippingErrorIsVisible &&
          <div id="shipping-error">
            <Alert
              className="error order-shipping-alert"
              icon={alertIcon}
              title={
                t('CHECKOUT_ORDER_CONFIRMATION_ALERT_TITLE', {
                  country: countriesTranslations[currentCountry] ?
                    countriesTranslations[currentCountry][lng] : currentCountry
                })
              }
            >
              <p><button style={{ display: 'inline' }}
                className="button-link"
                onClick={() => onEditData(paypalShippingCountry)}
              >{t('CHECKOUT_ORDER_CONFIRMATION_ALERT_LINK', {
                country: countriesTranslations[paypalShippingCountry] ?
                  countriesTranslations[paypalShippingCountry][lng] 
                  : paypalShippingCountry
              })
                }</button></p>
            </Alert>
          </div>
        }

        <div className="order-data__block">
          <h3 className="contact-title">{t('CONTACT_INFORMATION')}</h3>

          {
            email 
              ? <p className="contact-email">{email}</p>
              : <p className="contact-email">{t('EMAIL')} {t('NOT_SPECIFIED')}</p>
          }

          {
            phone 
              ? <p className="contact-phone">{formatPhoneValue(phone)}</p>
              : <p className="contact-phone">{t('PHONE')} {t('NOT_SPECIFIED')}</p>
          }

          <button
            className="button-link"
            onClick={handleEditContactData('shipping')}
          ><b>Edit</b></button>
        </div>

        <div className="order-data__block">
          <h3 className="contact-title">{t('PAYMENT_METHOD')}</h3>

          <img src={paymentLogo} alt="logo" />

          <p>{paymentInfoContent}</p>

          <button
            className="button-link"
            onClick={handleEditContactData('billing')}
          ><b>Edit</b></button>
        </div>

        <div className="show-xs">
          <OrderSidebar />
        </div>

        {
          checkoutError &&
          <div id="checkout-error" className="w-100"><Alert
            className="error order-shipping-alert w-100"
            isSvg={true}
            icon="warning" color="warning" size={22}
            text={
              checkoutError?.message 
              || 'We are so sorry..... Something went wrong. Please check your contact information and billing details and try again.'
            }
          /></div>
        }
      </div>


      {
        window.matchMedia('(max-width: 720px)').matches
          ? (
            <Sticky mode="bottom">
              <div className="submit-button-wrapper">
                <button
                  className="button contact-form-submit-button"
                  disabled={processingPayment}
                  onClick={handlePlaceOrder}
                >Place your order</button>
              </div>
            </Sticky>
          ) : (
            <div className="submit-button-wrapper">
              <button
                className="button contact-form-submit-button"
                disabled={processingPayment || checkoutError}
                onClick={handlePlaceOrder}
              >Place your order</button>
            </div>
          )
      }
    </>
  )
}


OrderSquareConfirmStep.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  city: PropTypes.string,
  onEditData: PropTypes.func
}

OrderSquareConfirmStep.defaultProps = {
  firstName: '',
  lastName: '',
  address: '',
  state: '',
  country: '',
  zip: '',
  email: '',
  phone: '',
  city: '',
  onEditData: () => { }
}

export default OrderSquareConfirmStep;
