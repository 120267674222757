import { 
  CHANGE_COUNTRY, SET_COUNTRIES_LIST, CLEAR_COUNTRY_DATA, CHANGE_COUNTRY_DATA, CHANGE_CURRENCY, SET_REGIONS_LIST, CLEAR_REGIONS_LIST, SET_PARENT_REGIONS
} from './countryActions';


const checkoutReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PARENT_REGIONS:
      return { 
        ...state, 
        parentRegions: payload 
      };
    case CHANGE_COUNTRY:
      return { 
        ...state, 
        currentCountry: payload 
      };
    case SET_REGIONS_LIST:
      return { 
        ...state, 
        regions: payload 
      };
    case CLEAR_REGIONS_LIST:
      return { 
        ...state, 
        regions: {} 
      };
    case CHANGE_CURRENCY:
      return { 
        ...state, 
        currency: payload 
      };
    case CHANGE_COUNTRY_DATA:
      return { 
        ...state, 
        charge: payload.charge, 
        currentCountry: payload.country ,
        currency: payload.currency ,
      };
    case SET_COUNTRIES_LIST:
      return { 
        ...state, 
        shippingCountriesList: payload.countries,
        countriesTranslations: payload.translations
      };
    case CLEAR_COUNTRY_DATA:
      return {
        currentCountry: '',
        charge: 0,
        countryCode: '',
        shippingCountriesList: []
    };
    default:
      return { ...state };
  }
}

export default checkoutReducer;
