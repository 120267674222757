import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { countries } from '../../temp/mock-data';

import './style.scss'; 
import Select from './Select';


const OrderShippingDetailsForm = ({ onSubmit, data }) => {
  const [formData, changeFormData] = useState({
    firstName: data?.firstName,
    lastName: data?.lastName,
    address: data?.address,
    state: data?.state,
    country: data?.country,
    zip: data?.zip,
    city: data?.city
  });

  const countryOpts = countries.map(
    ({ name, code }) => ({ label: name, value: code })
  )

  const handleChangeSelectValue = country => {
    changeFormData(
      prev => ({ ...prev, country })
    )
  }

  const handleFieldChange = e => {
    e.preventDefault();

    const { name, value } = e.target;

    changeFormData(prev => ({
      ...prev, [name]: value
    }))
  }

  const handleSubmitForm = e => {
    e.preventDefault();

    onSubmit(formData);
  }

  const { firstName, lastName, address, country, state, zip, city } = formData;

  return (
    <form className="edit-contact-data-form">
      <h3 className="form-section-title">Order Shipping Details</h3>

      <div className="form-section">
        <div className="form-control">
          <label htmlFor="firstName">First Name</label>

          <input
            type="text" name="firstName"
            value={firstName}
            onChange={handleFieldChange}
          />
        </div>

        <div className="form-control">
          <label htmlFor="lastName">Last Name</label>

          <input
            type="text" name="lastName"
            value={lastName}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="form-section">
        <div className="form-control country">
          <label htmlFor="country">Country</label>

          <Select
            name="country"
            value={countryOpts.find(({ value }) => value === country)}
            options={countryOpts}
            onChange={({ value }) => { handleChangeSelectValue(value) }}
          />
        </div>

        <div className="form-control">
          <label htmlFor="state">State</label>

          <input
            type="text" name="state"
            value={state}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="form-section">
        <div className="form-control">
          <label htmlFor="address">City</label>

          <input
            type="text" name="city"
            value={city}
            onChange={handleFieldChange} 
          />
        </div>

        <div className="form-control">
          <label htmlFor="address">Zip</label>

          <input
            type="text" name="zip"
            value={zip}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="form-control">
        <label htmlFor="address">Address</label>

        <input
          type="text" name="address"
          value={address}
          onChange={handleFieldChange}
        />
      </div>

      <div className="form-control">
        <button
          className="button"
          onClick={handleSubmitForm}
        >Save Changing</button>
      </div>
    </form>
  )
}


OrderShippingDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string
  })
}

OrderShippingDetailsForm.defaultProps = {
  data: {
    firstName: '',
    lastName: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zip: '',
  }
}

export default OrderShippingDetailsForm
