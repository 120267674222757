import React from 'react'; 
import { withRouter, Redirect } from 'react-router-dom';


export class ErrorBoundary extends React.Component {
  state = {
    error: null, errorInfo: null
  }

  


  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }


  
  render() {
    if (this.state.error) {
      const appShouldBeUpdated = this.state.error?.message.search(/Loading chunk \d+ failed/) >= 0;

      if (appShouldBeUpdated) {

        this.setState({ error: null, errorInfo: null });
        document.location.reload();
      }

      if (this.state.error?.errorCode === 'AUTH_INVALID') {
        localStorage.setItem('session', 'expired')
        return <Redirect to="/login" />;
      };

      if (this.state.error?.message === 'AUTH_TOKEN_EXPIRED') {
        localStorage.setItem('session', 'expired')
        return <Redirect to="/login" />;
      };
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
