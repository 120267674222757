import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-simple-hook-modal';
import ImageUploading from 'react-images-uploading';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { toast } from 'react-toastify';
import { useHelpers, useRequests } from '../../hooks';
import { useHistory } from 'react-router';
import ImageUploadFormControl from './ImageUploadFormControl';
import { Spinner, Select, CreateProducerForm, TabbedTextarea, NewImageWithCropTools, LabelsControlPanel, ButtonWithPopoverConfirmation } from '..';

import closeIcon from '../../assets/icons/close.svg';

import './style.scss';
import { Link } from 'react-router-dom';


const WineDataForm = ({ id, tastesList, producersList, name: wineName, region, cost, costUsd, tastes: wineTastes, image, description, descriptions, vintage, sortOfGrape, active, grapeCategories, category, producer, warehouse, regions, warehousesList, upc, sku, bottleImage, labels, labelsList, shortWinesList }) => {
  const initialData = {
    wineName: wineName || '',
    descriptions: descriptions ? descriptions : [{ tabName: 'en', text: '' }, { tabName: 'it', text: '' }],
    image: { file: null, data_url: image },
    bottleImage: { file: null, data_url: bottleImage },
    // image: { file: null, data_url: 'https://images.vivino.com/thumbs/6k9sXoH7S5qy1V5d_clTgg_pb_x960.png' },
    // image: { file: null, data_url: 'http://fengyuanchen.github.io/cropper/images/picture.jpg' },
    description: description || '',
    region: +region || 1,
    labels: labels || [],
    warehouse: warehouse
      ? warehousesList?.find(({ label }) => warehouse === label)?.value
      : warehousesList?.find(({ label }) => label.toLowerCase().includes('serendipity'))?.value,
    vintage: id ? vintage || 0 : new Date().getFullYear(),
    sortOfGrape: sortOfGrape || '',
    tastes: wineTastes || [],
    cost: cost || 0,
    costUsd: costUsd || 0,
    producer: producersList.find(
      ({ label }) => label === producer
    )?.value || producersList[0].value,
    active: active || 0,
    category: grapeCategories.find(
      ({ label }) => label === category
    )?.value || grapeCategories[0].value,
    upc, sku
  }
  const { post, requestPending } = useRequests();
  const { sortListOfObjectByField, createWineDataRequestObject, createWineSlug } = useHelpers();
  const history = useHistory();
  const tastesSection = useRef();
  const { getWineName } = useHelpers()
  const [modalContent, setModalContent] = useState(); // producer | image
  // eslint-disable-next-line
  const [tastes, setTastes] = useState(tastesList);
  // eslint-disable-next-line
  const [categories, setCategories] = useState(grapeCategories);
  const [producers, setProducers] = useState(producersList);
  const [formData, changeFormData] = useState({ ...initialData });
  const [dialogIsOpened, setDialogState] = useState(false)

  const isNewWine = !id;
  const slug = useMemo(() => {
    const { vintage, wineName: name, producer: producerId } = formData;
    const producer = producersList.find(({ value }) => value === producerId)?.label

    return createWineSlug({ vintage, producer, name })
  }, [formData.wineName, formData.vintage, formData.producer])

  const activeStateOptions = [
    { label: 'inactive', value: 0 },
    { label: 'active', value: 1 }
  ]

  const currentWarehouseData = useMemo(() => {
    const warehouseData = warehousesList?.find(
      ({ value }) => formData.warehouse === value
    )

    return warehouseData;
  }, [formData.warehouse])


  const similarWine = shortWinesList.find(wineData => {
    if (id && id === wineData.id) {
      return false
    }

    const { producer: producerId, wineName: name, vintage } = formData;
    const producer = producersList?.find(({ value }) => value === producerId)?.label || ''

    return getWineName({ name, vintage, producer }) === getWineName(wineData)
  })


  const closeModal = () => setModalContent(null);

  const handleChangeWIneLabels = labels => {
    changeFormData(prev => ({ ...prev, labels }))
  }

  const toggleConfirmationState = e => {
    e.preventDefault()

    setDialogState(prev => !prev)
  }

  const handleChangeLabelStatus = () => {

  }

  const handleChange = e => {
    const field = e.target.name;
    const value = e.target.value;

    changeFormData({
      ...formData,
      [field]: value
    })
  }

  const handleImageLoaded = prop => image => {
    changeFormData({
      ...formData,
      [prop]: image
    })
  }

  const showNewProducerDialog = () => setModalContent('producer');

  const addProducer = producer => {
    setProducers(
      prev => {
        const newProducersList = [
          ...prev,
          { label: producer.name, value: producer.id }
        ];

        return sortListOfObjectByField(newProducersList, 'label', { fieldType: 'string', order: 'asc' });
      }
    );

    closeModal();
    toast.success(`Producer ${producer.name} was successfully added to producers list`);
  }


  const handleDescriptionChange = (field, data) => changeFormData(prev => ({ ...prev, [field]: data }));

  const handleChangeSelect = (field, value) => {
    changeFormData(
      prev => ({ ...prev, [field]: value })
    )
  }

  const handleSaveCropedImage = (file, data_url) => {

    changeFormData(prev => ({
      ...prev,
      image: { data_url, file }
    }))

    closeModal();
  }

  const handleShowCropImageDialog = e => {
    e.preventDefault();

    setModalContent('image');
  }

  
  const newWineData = useMemo(() => {
    return isNewWine ? formData : createWineDataRequestObject(formData, initialData)
  }, [formData])
  
  const hasNoChanges = !isNewWine && Object.keys(newWineData || {}).length === 0

  const handleSubmit = async e => {
    e.preventDefault();

    if (formData.tastes.length > 3) {
      scrollIntoView(tastesSection.current, { behavior: 'smooth', scrollMode: 'if-needed', block: 'center' })

      return toast.warn('You need to choose not more than 3 tastes records to create new wine');
    }

    const newWineData = isNewWine ? formData : createWineDataRequestObject(formData, initialData)
    newWineData.slug = slug;

    const { image, bottleImage, descriptions, ...wineData } = newWineData;
    const producerName = producersList.find(({ value }) => value === formData.producer)?.label;
    //todo `${formData.vintage}_${producerName.split(' ').join('_')}_${formData.wineName.split(' ').join('_')}`
    const imageNameParts = [formData?.vintage || 'NA'];
    producerName && imageNameParts.push(producerName?.split(' ')?.join('_'))
    formData.wineName && imageNameParts.push(formData.wineName?.split(' ')?.join('_'))

    const imageName = imageNameParts.join('_')

    const convertDescriptionsToReqData = descriptions => descriptions ? descriptions.reduce(
      (result, { tabName, text }) => {
        result = { ...result, [tabName]: text };

        return result;
      }, {}
    ) : undefined

    if (descriptions) wineData.descriptions = convertDescriptionsToReqData(descriptions)

    const wineDataForm = {
      wineData: JSON.stringify(wineData),
      wineImage: image?.file || image?.data_url || '',
      bottleImage: bottleImage?.file || bottleImage?.data_url || '',
      imageName
    }

    const form = new FormData();

    for (const field in wineDataForm) {
      form.append(field, wineDataForm[field]);
    }

    const response = isNewWine ?
      await post({
        url: `/api/wines`,
        body: form
      })
      :
      await post({
        url: `/api/wines/${id}/update`,
        body: form
      });

    if (response?.data?.success) {
      toast.success(`Wine ${formData.wineName} was successfuly ${!isNewWine ? 'updated' : 'created'}.`);

      history.push(`/admin/wines`);
    } else {
      toast.error('Fail to submit data');
    }
  }

  const handleChangeWineTastes = tastesOptions => {
    const tastes = tastesOptions.map(({ value }) => value)

    changeFormData(prev => ({
      ...prev, tastes
    }))
  }

  const handleChangeTastes = e => {
    e.preventDefault();
    const tasteBtn = e.target;

    const wineTastesIncludesCurrentTaste = formData.tastes.includes(+tasteBtn.id);

    if (wineTastesIncludesCurrentTaste) {
      return changeFormData(
        prev => ({
          ...prev,
          tastes: prev.tastes.filter(taste => taste !== +tasteBtn.id)
        })
      )
    } else {
      const newTastes = formData.tastes.length < 3 ?
        [...formData.tastes, +tasteBtn.id] : [...formData.tastes]

      return changeFormData(
        prev => ({ ...prev, tastes: newTastes })
      )
    }
  }

  const tastesOptions = tastes.map(({ id: value, name: label }) => ({ value, label }))


  // const tastesContent = tastes.map(
  //   taste => (
  //     <div className="tag" key={`taste_${taste.id}`}>
  //       <button
  //         className={
  //           classNames('tag-button',
  //             { 'selected': formData.tastes.includes(taste.id) })
  //         }
  //         key={keyGen()}
  //         id={taste.id} name={taste.name}
  //         onClick={handleChangeTastes}
  //       >{taste.name}</button>
  //     </div>
  //   )
  // );
  const tastesContent = (
    <Select
      wrapperClass="w-100"
      isMulti={true}
      isSearchable={true}
      options={tastesOptions}
      onChange={handleChangeWineTastes}
      value={tastesOptions.filter(({ value }) => formData.tastes?.includes(value))}
    />
  )

  const handlePreviewWine = e => {
    const {
      wineName: name, vintage, bottleImage: { data_url: bottleImage }, sortOfGrape, category, region,
      producer, descriptions
    } = formData;
    const { value: producerId, label: wineProducer } = producersList.find(({ value }) => value === producer)
    const { value: regionId, label: wineRegion, country, stripHeaderImage } = regions.find(({ value }) => value === region)
    const wineData = {
      name, vintage, bottleImage, sortOfGrape, category, regionId, producerId, country,
      regionStripHeaderImage: stripHeaderImage,
      region: wineRegion,
      producer: wineProducer,
      description: descriptions?.[0]?.text
    }

    localStorage.setItem('temp-wine', JSON.stringify(wineData))
  }


  return (
    <>
      <form className="wine-data-form" onSubmit={e => { e.preventDefault() }}>
        <div className="form-section">
          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    buttonLabel="Upload Image"
                    imageList={[formData.image]}
                    onImageLoaded={handleImageLoaded('image')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>

              {/* <button
                style={{ margin: '12px auto' }}
                className="button"
                onClick={handleShowCropImageDialog}
              >Edit Image</button> */}
            </div>
          </div>

          <div className="form-column flex-column" style={{ alignSelf: 'center' }}>
            <div className="form-control w-100">
              <ImageUploading multiple={false}>
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                }) => <ImageUploadFormControl
                    buttonLabel="Upload Bottle Image"
                    imageList={[formData.bottleImage]}
                    onImageLoaded={handleImageLoaded('bottleImage')}
                    onImageUpdate={onImageUpdate}
                    onImageUpload={onImageUpload}
                  />}
              </ImageUploading>

              {/* <button
                style={{ margin: '12px auto' }}
                className="button"
                onClick={handleShowCropImageDialog}
              >Edit Bottle Image</button> */}
            </div>
          </div>
        </div>

        <div className="form-section main-wine-data-fields">
          <div className="form-section no-padding w-100">
            <div className="form-control" style={{ maxWidth: 'none' }}>
              <label htmlFor="wineName">Wine Name</label>
              <input
                type="text" name="wineName"
                value={formData.wineName}
                onChange={handleChange}
              />
            </div>

            <div className="form-control">
              <label htmlFor="">Slug</label>

              <input
                type="text"
                disabled
                value={slug}
              />
            </div>

          </div>
          <div className="form-control">
            <label htmlFor="upc">UPC</label>
            <input
              type="number" name="upc"
              onChange={handleChange}
              value={+formData.upc > 0 ? formData.upc : ''}
            />
          </div>

          <div className="form-control">
            <label htmlFor="sku">SKU</label>
            <input
              type="text" name="sku"
              onChange={handleChange}
              value={!formData.sku || +formData.sku === 0 ? '' : formData.sku}
            />
          </div>

          <div className="form-control">
            <label htmlFor="sortOfGrape">Wine Grape</label>
            <input
              type="text" name="sortOfGrape"
              value={formData.sortOfGrape}
              onChange={handleChange}
            />
          </div>

          <div className="form-control">
            <label htmlFor="vintage">Wine Vintage</label>
            <input
              type="text" name="vintage"
              value={formData.vintage}
              onChange={handleChange}
            />
          </div>

          <div className="form-control">
            <label htmlFor="category">Wine Category</label>

            <Select
              name="category"
              options={categories}
              value={categories.find(({ value }) => value === formData.category)}
              onChange={val => handleChangeSelect('category', val.value)}
            />
          </div>

          {
            currentWarehouseData.currency === 'eur' &&
            <div className="form-control">
              <label htmlFor="cost">Wine Cost (€)</label>
              <input
                type="text" name="cost"
                onChange={handleChange}
                value={formData.cost}
              />
            </div>
          }

          {
            currentWarehouseData.currency === 'usd' &&
            <div className="form-control">
              <label htmlFor="cost">Wine Cost ($)</label>
              <input
                type="text" name="costUsd"
                onChange={handleChange}
                value={formData.costUsd}
              />
            </div>
          }

          <div className="form-section no-padding">
            <div className="form-control">
              <label htmlFor="producer">Producer</label>

              <Select
                name="producer"
                isSearchable
                options={producers}
                value={producers.find(({ value }) => value === formData.producer)}
                onChange={val => handleChangeSelect('producer', val.value)}
              />
            </div>

            <div className="form-control">
              <button
                className="button-link"
                onClick={showNewProducerDialog}
              >Add New Producer</button>
            </div>
          </div>
        </div>

        <div className="form-section no-padding">
          <div className="form-control">
            <label htmlFor="region">Region</label>

            <Select
              name="region"
              options={regions}
              value={regions.find(({ value }) => value === formData.region)}
              onChange={val => handleChangeSelect('region', val.value)}
            />
          </div>

          <div className="form-control">
            <label htmlFor="region">Warehouse</label>

            <Select
              name="warehouse"
              options={warehousesList}
              value={warehousesList.find(({ value }) => value === formData.warehouse)}
              onChange={val => handleChangeSelect('warehouse', val.value)}
            />
          </div>

          <div className="form-control">
            <label htmlFor="state">Wine State</label>

            <Select
              name="active"
              options={activeStateOptions}
              value={activeStateOptions.find(({ value }) => value === formData.active)}
              onChange={val => handleChangeSelect('active', val.value)}
            />
          </div>
        </div>

        <div className="form-section-title">Wine Tastes</div>
        <div className="form-section">
          <div className="admin-tags-list w-100" ref={tastesSection}>
            {tastesContent}
          </div>
        </div>

        <LabelsControlPanel
          labels={formData.labels}
          allLabelsList={labelsList}
          onChangeLabels={handleChangeWIneLabels}
        />

        {/* **** */}
        {/* <div className="form-control">
          <label htmlFor="">CROP!</label>
          
          
        </div> */}

        <div className="form-section-title">Wine Description</div>

        <TabbedTextarea
          onChange={handleDescriptionChange}
          tabs={currentWarehouseData.currency !== 'eur'
            ? formData.descriptions?.filter(({ tabName }) => tabName === 'en')
            : formData.descriptions
          }
          label="Description language"
          name="descriptions"
        />
        <br />
        <div className="form-control horizontal control-button gap_sm" style={{ justifyContent: "flex-end" }}>
          <Link className="button" target="_blank"
            onClick={handlePreviewWine}
            to="/wines/preview"
          >Preview Wine</Link>

          {
            similarWine &&
            <ButtonWithPopoverConfirmation
              confirmText={`${getWineName(similarWine)} name already exists in the ${similarWine.warehouse} warehouse. Add anyway?`}
              disabled={requestPending}
              isOpen={dialogIsOpened}
              onClick={toggleConfirmationState}
              onCancel={toggleConfirmationState}
              onConfirm={handleSubmit}
              btnText={isNewWine ? 'Create New Wine' : 'Submit Changes'}
              mainButtonClasses="button"
            />
          }

          {
            !similarWine &&
            <button
              className="button"
              disabled={requestPending || hasNoChanges}
              onClick={handleSubmit}
            >
              {
                hasNoChanges ? 'No Changes' : isNewWine ? 'Create New Wine' : 'Submit Changes'
              } {requestPending && <span className="loading"><Spinner /></span>}
            </button>
          }
        </div>

        {
          similarWine &&
          <p className="text-right text-gray"
          ><b>{getWineName(similarWine)}</b> name already exists in the {similarWine.warehouse} warehouse.</p>
        }
      </form>

      <NewImageWithCropTools
        isCropImageDialogOpen={modalContent === 'image'}
        onSaveImage={handleSaveCropedImage}
        onCloseCropImageDialog={closeModal}
        image={formData.image?.data_url}
      />

      <Modal modalClassName="modal-dialog crop-image-dialog" isOpen={modalContent === 'producer'}>
        {
          modalContent === 'producer' &&
          <>
            <h3>Add New Producer</h3>
            <button className="close-modal-btn" onClick={closeModal}>
              <img src={closeIcon} alt="close modal" />
            </button>

            <CreateProducerForm onSubmit={addProducer} />
          </>
        }
      </Modal>
    </>
  )
}


WineDataForm.propTypes = {
  wineName: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  tastes: PropTypes.string,
  vintage: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteOne: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteTwo: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  tasteThree: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ]),
  sortOfGrape: PropTypes.string,
  onSubmit: PropTypes.func
}

WineDataForm.propTypes = {
  wineName: '',
  image: '',
  description: '',
  tastes: '',
  vintage: '',
  sortOfGrape: '',
  tasteOne: '1',
  tasteTwo: '2',
  tasteThree: '3',
}

export default WineDataForm;
