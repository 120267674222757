import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as keyGen } from 'uuid';

import './style.scss';


const WineTypesBadgeList = ({ winesTypes }) => {
  const { t } = useTranslation();
  const winesSummary = [];

  for (const wineType in winesTypes) {
    const key = keyGen();
    const wineBadge = (
      <li className="wine-badge" key={key}>
        <b>{winesTypes[wineType]}</b>&nbsp;{ 
          wineType?.toLocaleLowerCase() === 'sparkling' 
            ? t(wineType.toUpperCase())
            : winesTypes[wineType] === 1
              ? t(wineType.toUpperCase())
              : t(wineType.toUpperCase()) + 'S'
        }
      </li>
    );

    winesSummary.push(wineBadge);
  }


  return (
    <ul className="wines-summary">{winesSummary}</ul>
  )
}


WineTypesBadgeList.propTypes = {
  winesTypes: PropTypes.object
}

WineTypesBadgeList.defaultProps = {
  winesTypes: {}
}

export default WineTypesBadgeList;
