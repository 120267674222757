import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '..'

import franceIcon from '../../assets/icons/country-france.svg'

import './style.scss';


const CaseCountriesList = ({ countries }) => {
  if (!Array.isArray(countries) || countries.length === 0) return null;

  const listItems = [...new Set(countries)]
    .sort().map(country => {
      return (
        <li key={`c-item-${ country }`} className="case-country">
          {
            country?.toLowerCase() === 'france' ? (
              <img src={franceIcon} alt="" />
            ) : (
              <Icon size={24} icon={`country-${ country?.toLowerCase() }`} color="warm-gray-9" />
            )
          } { country }
        </li>
      )
    })

  return (
    <ul className="case__countries">{ listItems }</ul>
  )
}


CaseCountriesList.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string)
}

CaseCountriesList.defaultProps = {
  countries: []
}

export default memo(CaseCountriesList)