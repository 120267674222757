import React from 'react';
import PropTypes from 'prop-types';
import CustomRoute from './CustomRoute';
import { useSelector } from 'react-redux';

const ManagerRoute = ({ component, ...rest }) => {
  const { isAuthenticated, user } = useSelector(state => state.auth)
    const accept = isAuthenticated && ['warehouse', 'admin'].includes(user?.role);
    const fallback = '/';

    return <CustomRoute 
        component={ component }
        accept={ accept } 
        fallback={ fallback }
        { ...rest }
    />
}


ManagerRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    rest: PropTypes.object
}

export default ManagerRoute;
