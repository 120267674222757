import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHelpers, useRequests } from '../../hooks';

import './style.scss';


const ChangeWinePriceForm = ({ currency, cost, costUsd, id, name, producer, vintage, onChangePrice, closeDialog }) => {
  const [data, changeData] = useState({
    cost, costUsd, id
  });
  const { post } = useRequests();
  const { getWineName } = useHelpers();
  const wineName = getWineName({ name, vintage, producer })?.trim();

  const handleSubmitNewPrice = async e => {
    e.preventDefault();

    const response = await post({
      url: `/api/wines/${id}/change-price`,
      body: data
    });

    if (response?.data?.success) {

      onChangePrice(data);
      closeDialog()
    }
  }

  const handleChangeWinePrice = e => {
    e.preventDefault();

    const { name, value } = e.currentTarget;
    if (isNaN(+value)) return;

    changeData(prev => ({ ...prev, [name]: parseFloat(value) }))
  }


  const handleCloseModalDialog = e => {
    e.preventDefault();

    changeData({ cost, costUsd, id });

    closeDialog();
  }


  return (
    <form className="wine-price-form">
      <h3 className="section-title">Enter a price for <b>"{wineName}"</b>:</h3>

      {
        currency === 'usd' &&
        <div className="form-control horizontal w-100">
          <label htmlFor="costUsd"><b>USD</b></label>

          <input
            type="number"
            min="0"
            step={0.01}
            className="w-100"
            name="costUsd"
            value={data.costUsd}
            onChange={handleChangeWinePrice}
          />
        </div>
      }

      {
        currency === 'eur' &&
        <div className="form-control horizontal w-100">
          <label htmlFor="costUsd"><b>EUR</b></label>

          <input
            type="number"
            min="0"
            step={0.01}
            className="w-100"
            name="costUsd"
            value={data.cost}
            onChange={handleChangeWinePrice} 
          />
        </div>
      }

      <div className="form-control">
        <button
          className="button"
          onClick={handleSubmitNewPrice}
        >Submit New Price</button>

        <button
          className="button button_outline"
          onClick={handleCloseModalDialog}
        >Close Dialog</button>
      </div>
    </form>
  )
}

ChangeWinePriceForm.propTypes = {
  currency: PropTypes.string,
  cost: PropTypes.number,
  costUsd: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  producer: PropTypes.string,
  vintage: PropTypes.number,
  onChangePrice: PropTypes.func,
  closeDialog: PropTypes.func
}

export default ChangeWinePriceForm