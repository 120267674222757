import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHelpers } from '../../hooks';
import PropTypes from 'prop-types';

import closeIcon from '../../assets/icons/close-circle-green.svg';


const PromtModal = ({ onCancel, onConfirm, title, text, cancelButtonLabel, conirmButtonLabel, onClose, nextLocation, revert }) => {
  const { t } = useTranslation();
  const { capitalize } = useHelpers();


  return (
    <>
      <button className="close-modal-btn promt-btn" onClick={ onClose }>
        <img className="fill-green" src={ closeIcon } alt="close modal" />
      </button>

      <div className="promt-dialog">
        <h3 className="promt-title">{ title }</h3>

        { text }

        <div className="flex-center">
          <div className="flex-space-between">
            <button
              className="button"
              onClick={ onCancel }
            >{ cancelButtonLabel || capitalize(t('CANCEL')) }</button>

            <button
              className="button button_outline"
              onClick={ onConfirm }
            >{ conirmButtonLabel || t('CASES_CUSTOM_CASE_PROMT_CLOSE_WITHOUT_SAVING') }</button>
          </div>
        </div>
      </div>
    </>
  )
}


PromtModal.propTypes = {
  onCancel: PropTypes.func, 
  onConfirm: PropTypes.func, 
  title: PropTypes.string, 
  text: PropTypes.oneOfType([
    PropTypes.string, PropTypes.node
  ]),
  cancelButtonLabel: PropTypes.string, 
  conirmButtonLabel: PropTypes.string
}

export default PromtModal
