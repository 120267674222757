import React, { useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useHelpers, useRequests, useStoreAuth } from '../../hooks';
import { Modal, useModal } from 'react-simple-hook-modal';
import ImageUploading from 'react-images-uploading';
import { toast } from 'react-toastify';
import ImageUploadFormControl from './ImageUploadFormControl';
import { TabbedTextarea, Icon, CuratorFollowersDialog } from '..';
import Select from './Select';
import home from '../../assets/icons/home.svg';
import certified from '../../assets/icons/certified.svg';
import wine from '../../assets/icons/wine.svg';

import './style.scss'

const curatorRolesOptions = [
  { value: 'guest', label: 'Guest' },
  { value: 'main', label: 'Main' }
]

const noteLabels = {
  'home': 'Personal introduction',
  'certified': 'Wine knowledge and experience',
  'wine': 'Wine credentials (WSET, Master Sommelier etc) '
}


const CuratorDataForm = ({ id, firstName, lastName, notes, photo, profession, about, user_id: userId, role, followers }, ref) => {
  const { post, get, requestPending } = useRequests();
  const { convertDataToSelectOptions } = useHelpers();
  const { authState: { user } } = useStoreAuth()
  const { isModalOpen, openModal, closeModal } = useModal()
  const history = useHistory();
  const [users, changeUsers] = useState([]);
  const initialData = {
    user: userId,
    role: role || 'guest',
    firstName: firstName || '',
    lastName: lastName || '',
    profession: profession || '',
    about: about || '',
    photo: { file: null, data_url: photo },
    notes: ['home', 'certified', 'wine'].map(note => {
      const noteData = notes?.find(({ name }) => name === note);

      return noteData
        ? { ...noteData }
        : { name: note, texts: { en: '', it: '' } }
    })
  };

  const isAdmin = user.role === 'admin'
  const isGuestCurator = user.curatorRole === 'guest'

  const [formData, changeFormData] = useState(initialData);

  const usersOptions = useMemo(() => convertDataToSelectOptions(users, 'email'), [users]);
  // const usersOptions = useMemo(() => users.map(({ id: value, email: label }) => ({ label, value })), [users]);

  const fetchUsers = async () => {
    if (!isAdmin) return;

    const response = await get({ url: `/api/users/names-list?all=1&cur=1` });

    if (response?.data?.success) {
      changeUsers(response.data.customers)
    }
  }

  const handleChangeRole = ({ value: role }) => {
    changeFormData(prev => ({ ...prev, role }))
  }

  const getCuratorData = () => {
    const notes = formData.notes.map(noteData => {
      return { ...noteData, text: noteData.texts.en }
    })
    const photo = formData.photo.data_url

    return {
      ...formData, photo, notes
    }
  }

  useImperativeHandle(ref, () => {
    return { getCuratorData }
  }, [formData])

  useEffect(() => { fetchUsers(); }, []);

  const handleImageLoaded = image => {
    changeFormData({ ...formData, photo: image });
  }

  const noteIcons = {
    home, certified, wine
  }

  const handleNoteChange = (name, newTabs, activeTab) => {
    const changedTabText = newTabs.find(({ tabName }) => activeTab === tabName)?.text;

    changeFormData(prev => {
      const newNotes = prev.notes.map(noteData => {

        return noteData.name === name
          ? { ...noteData, texts: { ...noteData.texts, [activeTab]: changedTabText } }
          : noteData
      });

      return { ...prev, notes: newNotes }
    })
  }
  

  const handleChangeFormField = e => {
    e.preventDefault();

    const { name, value } = e.currentTarget;

    changeFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleUserChange = data => {
    const userData = users.find(({ id }) => id === data.value);

    changeFormData(prev => ({
      ...prev,
      firstName: userData.firstName,
      lastName: userData.lastName,
      user: userData.id
    }))
  }

  const handleOpenModal = e => {
    e.preventDefault()

    !isGuestCurator && openModal()
  }

  const handleSubmitChanges = async e => {
    e.preventDefault();

    const { photo, notes, ...curatorData } = formData;
    const data = new FormData();

    for (const key of Object.keys(curatorData)) {
      data.append(key, curatorData[key])
    }

    data.append('notes', JSON.stringify(notes));

    if (photo?.file) {
      data.append('files', photo.file)
      data.append('curatorPhoto', photo.file)
    } else {
      data.append('photo', photo?.data_url || '')
    }

    if (initialData?.user && initialData.user !== curatorData.user) {
      data.append('initialUser', initialData.user)
    }

    const response = await post({
      url: `/api/content/curators${id ? `/${id}` : ``}`,
      body: data
    })

    if (response?.data?.success) {
      const successMsg = id
        ? `The data was successfully updated`
        : `New curator ${formData.firstName} ${formData.lastName} was successfully added`;

      toast.success(successMsg);
      isAdmin && history.push('/admin/curators')
    }
  }

  const notesContent = formData.notes.map(noteData => {

    return (
      <div className="form-control no-margin" key={'note_' + noteData.name}>
        {/* <img src={noteIcons[noteData.name]} alt={noteData.name} /> */}
        <label htmlFor="" className="curator-note__label">
          <Icon icon={noteData.name} size={28} color="dark-gold" /> {noteLabels[noteData.name]}
        </label>

        <TabbedTextarea
          name={noteData.name}
          parentControl={true}
          tabs={[
            { tabName: 'en', text: noteData.texts?.en || '' }
          ]}
          activeTab={noteData.currentTab}
          onChange={handleNoteChange}
        />
      </div>
    )
  });

  const numOfFollowers = followers?.length || 0

  const placeHolder = (
    <div className="curator-form__photo-placeholder">
      <Icon icon="user" color="white" size={130} />
    </div>
  )


  return <>
    <form>
      <div className="admin-header-content">
        <div className="left-content wide">
          <ImageUploading multiple={false}>
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
            }) => <ImageUploadFormControl
                imageList={[formData.photo]}
                onImageLoaded={handleImageLoaded}
                onImageUpdate={onImageUpdate}
                onImageUpload={onImageUpload}
                placeHolder={placeHolder}
                rounded
              />}
          </ImageUploading>

          <br />

          {
            isGuestCurator
            ? (<>{
              numOfFollowers > 0 &&
              <p className="m-auto">You have {numOfFollowers} { numOfFollowers > 1 ? 'followers' : 'follower'}</p>
            }</>) : (
              <button
            className="button button_xs accent m-auto w-auto"
            onClick={handleOpenModal}
          >Followers</button>
            )
          }
        </div>

        <div className="form-column flex-column">
          {
            isAdmin &&
            <div className="form-control" style={{ width: '100%' }}>
              <label htmlFor="firstName" style={{ textAlign: 'left' }}>Select User</label>

              <Select
                options={usersOptions}
                isSearchable={true}
                value={usersOptions.find(({ value }) => value === formData.user)}
                onChange={handleUserChange}
              />
            </div>
          }

          <div className="form-control" style={{ width: '100%', marginLeft: 0 }}>
            <label htmlFor="firstName" style={{ textAlign: 'left' }}>First name</label>

            <input
              name="firstName"
              value={formData.firstName}
              onChange={handleChangeFormField}
            />
          </div>

          <div className="form-control" style={{ width: '100%', marginLeft: 0 }}>
            <label htmlFor="lastName" style={{ textAlign: 'left' }}>Last name</label>

            <input
              name="lastName"
              value={formData.lastName}
              onChange={handleChangeFormField}
            />
          </div>

          {
            isAdmin &&
            <div className="form-control w-100 no-margin" style={{ width: '100%' }}>
              <label htmlFor="firstName" className="text-left">Curator Role</label>

              <Select
                options={curatorRolesOptions}
                value={curatorRolesOptions.find(({ value }) => value === formData.role)}
                onChange={handleChangeRole}
              />
            </div>
          }
        </div>
      </div>

      <br />
      <br />

      <div className="form-control">
        <label htmlFor="about">Bio</label>

        <textarea
          name="about"
          rows="3"
          value={formData.about}
          onChange={handleChangeFormField}
        />
      </div>

      <br />

      {/* <div className="form-control">
      <label htmlFor="profession">Description</label>

      <textarea
        name="profession"
        rows="5"
        value={formData.profession}
        onChange={handleChangeFormField}
      />
    </div> */}

      <div className="notes-section">{notesContent}</div>

      <div className="form-control">
        <button
          className="button m-auto"
          disabled={requestPending}
          onClick={handleSubmitChanges}
        >{id ? 'Submit Changes' : 'Add New Curator'}</button>
      </div>
    </form>

    <Modal 
      modalClassName="modal-dialog " 
      isOpen={ isModalOpen } onBackdropClick={closeModal}
    ><CuratorFollowersDialog id={ id } onClose={closeModal} /></Modal>
  </>
};


CuratorDataForm.propTypes = {
  firstname: PropTypes.string,
  lastName: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    text: PropTypes.string
  })),
  photo: PropTypes.string,
  profession: PropTypes.string,
  about: PropTypes.string
};

CuratorDataForm.defaultProps = {
  firstname: '',
  lastName: '',
  profession: '',
  about: ''
}

export default forwardRef(CuratorDataForm);
