import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import imageTemplate from '../../assets/images/case3.png';


const TasteCard = ({ image, title, recorded, host, mediaLink, active, onShowTasteDetails }) => {

    return (
        <div onClick={ onShowTasteDetails } className="tasting-card-wrapper">
            <div className={classNames('card card_tasting card_with-details', { 'active': active })}  >
                <div className="card__header card__header_tasting">
                    <div className="card__image">
                        <img src={ image } alt={ title } />
                    </div>
                </div>
                <div className="card__body card__body_tasting">
                    <h4>Host: { host }</h4>

                    <p className="no-margin text-gray">Recorded: { recorded }</p>

                    <h3>{ title }</h3>

                    <button 
                        className="button"
                    >Watch Now</button>

                    <Link to="/">See Full Details</Link>
                </div>
            </div>
        </div>
    )
}


TasteCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    recorded: PropTypes.string,
    host: PropTypes.string,
    mediaLink: PropTypes.string,
    active: PropTypes.bool,
    onShowTasteDetails: PropTypes.func
}

TasteCard.defaultProps = {
    image: '', 
    title: 'Exploring Wine #1', 
    recorded: 'Oct. 17, 2020', 
    host: 'Stefano', 
    mediaLink: '',
    active: false
}


export default TasteCard
