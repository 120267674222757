import { useSelector, useDispatch } from "react-redux"
import { requestAddCaseToCart, requestAddAdjustedCaseToCart } from '../checkout/checkout.actions'


const useCart = () => {
  const { order, caseIsAdjusted, cases } = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const cartState = {order, caseIsAdjusted, cases}
  const cartActions = {}

  cartActions.requestAddAdjustedCaseToCart = ({ caseDetails, wines, caseId, descriptions, longDescriptions, cost, eur, usd, redirectToCart }) =>
    dispatch(requestAddAdjustedCaseToCart({ caseDetails, wines, caseId, descriptions, longDescriptions, cost, eur, usd, redirectToCart }))

  cartActions.requestAddCaseToCart = ({ caseDetails, caseDetailsId, caseId, cost, redirectToCart }) => 
    dispatch(requestAddCaseToCart({ caseDetails, caseDetailsId, caseId, cost, redirectToCart }))


  return { cartState, cartActions }
}

export default useCart;
