import React from 'react'
import PropTypes from 'prop-types'
import { useAnalitics, useStoreAuth } from '../../hooks'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'


const FollowCuratorButton = ({ id, buttonClassName, curatorData }) => {
  const { sendMxCustomEvent } = useAnalitics()
  const { authActions, authState: { isAuthenticated, user: { followedCurators, email, id: userId, role, firstName, lastName } } } = useStoreAuth()
  const followedCuratorIndex = followedCurators?.findIndex(curatorId => +curatorId === +id)
  const isFollowing = followedCuratorIndex >= 0
  const history = useHistory()

  const handleFollowCurrator = e => {
    e.preventDefault()

    if (!isAuthenticated) {
      sessionStorage.setItem('from', history.location.pathname)

      return history.push('/login', { from: history.location.pathname })
    }

    const eventName = isFollowing 
      ? `Stop Following curator ${curatorData.name}` 
      : `Follow curator ${curatorData.name}`

    sendMxCustomEvent(eventName, {
      userId, role, userName: `${firstName} ${lastName}`, 'Email': email
    })

    authActions.requestFollowCurator(id, followedCuratorIndex)

    e.target.blur()
  }

  const buttonLabel = isFollowing ? 'Following' : 'Follow'


  return (
    <button className={classNames('button', { 'accent': isFollowing, [buttonClassName]: !!buttonClassName })}
      onClick={handleFollowCurrator}
    >{buttonLabel}</button>
  )
}

FollowCuratorButton.propTypes = {
  id: PropTypes.number,
  buttonClassName: PropTypes.string
}

export default FollowCuratorButton