import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import randomize from 'randomatic';
import { toast } from 'react-toastify';
import { useHelpers, useRequests } from '../../hooks';
import { Select, Spinner } from '..';


const NewVerificationForm = ({ onFormSubmited, data, onCancel, generateCode, warehousesList }) => {
  const { post, errors, clearErrors } = useRequests(); 
  const { objectIsEmpty, convertDataToSelectOptions } = useHelpers();
  const warehousesOptions = useMemo(() => convertDataToSelectOptions(warehousesList), [warehousesList]);

  const isReferrer = data?.isReferrer;

  const [formData, changeFormData] = useState({
    email: data?.email || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    code: generateCode ? 
      `${randomize('0', 3)}-${randomize('0', 3)}` : '',
    verification_type: 'invited',
    warehouses: warehousesOptions?.filter(({ label }) => label?.toLowerCase()?.startsWith('serendipity')),
  });
  const [language, changeLanguage] = useState('en');
  const [pendingRequest, setRequestState] = useState(false);

  const languageOptions = [
    {value: 'en', label: 'English' },
    {value: 'it', label: 'Italian' },
  ]

  const generateNewCode = e => {
    e.preventDefault();

    const newCode = `${randomize('0', 3)}-${randomize('0', 3)}`;
    changeFormData({
      ...formData,
      code: newCode
    })
  }

  const handleSelectChange = (field, value) => {
    if (field === 'language') {
      changeLanguage(value);
    } else {
      changeFormData(prev => ({ ...prev, [field]: value }))
    }
  }

  const handleFieldChange = e => {
    clearErrors();
    const field = e.target;

    changeFormData({
      ...formData,
      [field.name]: field.value
    })
  }


  const handleSendVerificationCode = async () => {
    setRequestState(true);

    const data = {
      ...formData,
      warehouses: formData.warehouses.map(({ value }) => value)
    }

    const response = await post({
      url: `/api/users/send-verification${ data?.id ? `/${ data.id }` : `` }?lng=${ language }`,
      body: data
    });

    if (response && response.data.success) {
      toast.success(`Verification was sent to email ${formData.email}`);

      onFormSubmited(response.data.verification, isReferrer);
      /*
      {
        "id": "1d436ee7-c27f-4bcd-b536-3e9a5f87a2b6",
        "isSent": true,
        "isUsed": false,
        "data": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdE5hbWUiOiJJdmFuIiwibGFzdE5hbWUiOiJNIiwiZW1haWwiOiJpdmFuLm11cmFldkBpbnRzcGlyaXQyMjIuY29tIiwiaWF0IjoxNjQyNDExMDc2fQ.puAUVyYeOFgMEFqc__Vq3sfP7pVnGrOD-D8r7SWeOwM",
        "email": "ivan.muraev@intspirit222.com",
        "code": "946-855",
        "object": "email",
        "verification_type": "invited",
        "updatedAt": "2022-01-17T09:17:56.383Z",
        "createdAt": "2022-01-17T09:17:56.218Z",
        "sentAt": "2022-01-17T09:17:56.380Z",
        "usedAt": null
      }
       */
    }

    setRequestState(false);
  }

  const handleCancel = e => {
    e.preventDefault();

    onCancel && onCancel();
  }

  const handleChangeWarehouse = values => changeFormData(prev => ({ ...prev, warehouses: values }))


  return (
    <form>
      <h3 className="form-section-title text-center" style={{ marginTop: '32px' }}>New Verification Code</h3> 

      <div className="form-control horizontal flex-center">
        <label htmlFor="language">Language:</label>

        <Select
          options={ languageOptions }
          value={ languageOptions.find(({ value: lng }) => lng === language) }
          onChange={ ({ value }) => handleSelectChange('language', value) }
        />
      </div>

      {
        errors && !objectIsEmpty(errors) &&
        <div className="error-msg-block">{errors.message}</div>
      }
      <div className="form-section no-padding">
        <div className="form-control">
          <label htmlFor="code">First Name</label>
          <input
            name="firstName" type="text"
            placeholder="enter first name"
            value={formData.firstName}
            onChange={handleFieldChange}
          />
        </div>

        <div className="form-control">
          <label htmlFor="code">Last Name</label>
          <input
            name="lastName" type="text"
            placeholder="enter last name"
            value={formData.lastName}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className="form-control">
        <label htmlFor="email">Email Address</label>
        <input
          name="email" type="email"
          placeholder="enter email address"
          value={formData.email}
          onChange={handleFieldChange}
        />
      </div>

      <div className="form-control">
        <label htmlFor="warehouse">Warehouse</label>

        <Select
          options={ warehousesOptions }
          isMulti={ true }
          value={ formData.warehouses }
          onChange={ handleChangeWarehouse }
        />
      </div>

      <div className="form-section no-padding" style={{ justifyContent: 'flex-start' }}>
        <div className="form-control">
          <label htmlFor="code">Registration Code</label>
          <input
            name="code" type="text"
            className="text-center text-lg"
            placeholder="click generate code button"
            disabled={true}
            value={formData.code}
          />
        </div>
        <div className="form-control control-link">
          <button
            className="button-link"
            onClick={generateNewCode}
          >Generate Code</button>
        </div>
      </div>

      <div className="form-control" style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <label className="radio-button no-margin">
          invited
          <input
            name="verification_type" type="radio" value="invited"
            checked={formData.verification_type === 'invited'}
            onChange={handleFieldChange}
          />
          <span className="checkcircle"></span>
        </label>

        <label className="radio-button no-margin">
          test
          <input
            name="verification_type" type="radio" value="test"
            checked={formData.verification_type === 'test'}
            onChange={handleFieldChange}
          />
          <span className="checkcircle"></span>
        </label>
      </div>

      <div className="button-container no-padding">
        {
          onCancel &&
          <button
            className="button button_outline"
            onClick={ handleCancel }
          >Cancel</button>
        }

        <button
          className="button"
          disabled={
            pendingRequest || formData.email.length < 8 || formData.code.length < 7
          }
          onClick={handleSendVerificationCode}
        >Send Verification {pendingRequest && <Spinner />}</button>
      </div>
    </form>
  )
}


NewVerificationForm.propTypes = {
  onFormSubmited: PropTypes.func,
  onCancel: PropTypes.func,
  data: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    code: PropTypes.number,
    verification_type: PropTypes.string,
  })
}

export default NewVerificationForm;
