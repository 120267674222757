import React, { useState } from 'react'; 
import classNames from 'classnames';
import { useRequests } from '../../hooks';
import { useTranslation } from 'react-i18next';

import icon from '../../assets/icons/loving-vineify.svg';


const ReferFriendForm = () => {
  const { post, requestPending, errors, clearErrors } = useRequests();
  const { t, i18n } = useTranslation();
  const [formData, changeFormData] = useState({
    firstName: '', lastName: '', email: ''
  });

  const [showSuccessMessage, setMessageState] = useState(false);

  const {
    firstName: firstNameError, lastName: lastNameError, email: emailError,
  } = errors?.errors || {};
  const { firstName, lastName, email } = formData;

  const clearForm = () => changeFormData({
    firstName: '', lastName: '', email: ''
  });

  const handleFormChange = e => {
    e.preventDefault();
    clearErrors();

    const { name, value } = e.target;
    changeFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const response = await post({
      url: `/api/users/send-invitation?lng=${i18n.language}`,
      body: formData
    });

    if (response && response.data.success) {
      clearForm();
      setMessageState(true);

      setTimeout(() => setMessageState(false), 5000);
    } else {
      const { errors } = response.data;
      const inputName = Object.keys(errors)?.[0]
      const input = document.querySelector(`.refer-friend-form [name="${inputName}"]`)

      input?.focus();
    }
  }

  return (
    <form className="refer-friend-form">

      <h3 className="form-section-title refer-friend-title">
        <img src={icon} alt="icon" />
        {t('HOME_REFER_FRIEND_SECTION_MODAL_TITLE')}
      </h3>

      <div className="form-section">
        <div className={classNames('form-control', { 'invalid': firstNameError })}>
          <label htmlFor="firstName">{t('HOME_REFER_FRIEND_FORM_NAME_LABEL')}*</label>

          <input
            type="text" name="firstName"
            className="input-sm"
            value={firstName}
            onChange={handleFormChange}
          />
        </div>

        <div className={classNames('form-control', { 'invalid': lastNameError })}>
          <label htmlFor="lastName">{t('HOME_REFER_FRIEND_FORM_LAST_NAME_LABEL')}*</label>

          <input
            type="text" name="lastName"
            className="input-sm"
            value={lastName}
            onChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classNames('form-control', { 'invalid': emailError })}>
        <label htmlFor="">{t('HOME_REFER_FRIEND_FORM_EMAIL_LABEL')}*</label>

        <input
          type="text" name="email"
          className="input-sm"
          value={email}
          onChange={handleFormChange}
        />
      </div>

      <div className="form-control">
        {
          showSuccessMessage &&
          <div className="success-msg">{t('HOME_REFER_FRIEND_SECTION_IS_SUBMITED')}</div>
        }
        {
          errors && errors.message &&
          <small
            style={{ top: '36px', height: '40px' }}
            className="error-msg-block up no-padding flex-center"
          >{errors.message}</small>
        }
        <button
          className="button"
          style={{ alignSelf: 'center', marginTop: '32px' }}
          disabled={requestPending}
          onClick={handleSubmit}
        >{t('HOME_REFER_FRIEND_FORM_INVITE_BTN')}</button>
      </div>
    </form>
  )
}

export default ReferFriendForm;
