import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckoutContext } from '../../context'; 
import { useActions } from '../../hooks';

const stepsNames = ['shipping', 'billing', 'confirm']; 


const SquareSteps = () => {
  const dispatch = useDispatch()
  const { checkoutActions } = useActions()
  const history = useHistory();
  const { order: { case: orderCase } } = useSelector(state => state.cart)
  const { paymentStep } = useSelector(state => state.checkout)
  // const { paymentStep, changePaymentStep } = useContext(CheckoutContext);
  const isMobile = window.matchMedia('(max-width: 520px)').matches;

  const handleChangePaymentStep = step => e => {
    if (step === 'home') return history.push('/');

    dispatch(checkoutActions.changePaymentStep(step))
    // changePaymentStep(step)
  }


  const checkoutNavigation = useMemo(() => {
    const currentStep = paymentStep || 'shipping';
    const currentIndex = stepsNames.findIndex(step => step === currentStep);

    return [
      { label: 'Shipping Address', action: 'shipping' },
      { label: 'Payment Details', action: 'billing' },
      { label: 'Review Order', action: 'confirm' },
    ].map(({ label, action }, ind) => {
      const isActive = ind <= currentIndex;
      const isDisabled = currentStep === 'complete' || ind > currentIndex;
      const labelText = isMobile ? label.split(' ')[0] : label;

      const link = isActive ? (
        <button 
          className="button-link"
          disabled={ isDisabled }
          onClick={handleChangePaymentStep(action)}
        >{labelText}</button>
      ) : ( <span className={classNames({ 'link-disabled': currentStep === 'complete' })}>{labelText}</span> )

      return (
        <li 
          key={label} 
          className={classNames('steps__item', { 
            'active': isActive 
          })}
        >{ link }</li>
      )
    });
  }, [paymentStep]);

  if (orderCase?.stock < 1) return null;

  return (
    <div className="steps">
      <ul className="steps__list">{checkoutNavigation}</ul>
    </div>
  )
}

export default SquareSteps