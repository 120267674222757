import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, useModal } from 'react-simple-hook-modal'
import ReferFriendForm from '../form/ReferFriendForm'

import closeIcon from '../../assets/icons/close.svg';


const ReferFriendBar = () => {
  const { isModalOpen, closeModal, openModal } = useModal();
  const {t} = useTranslation();


  return (
    <div className="home-header__alert">
      <p>
        <b>{t('HOME_ALERT_PREFIX')}</b>&nbsp; {t('HOME_ALERT_TEXT')} <button
          className="button-link no-padding not-underlined"
          onClick={openModal}
        ><b className="text-white">{t('HOME_ALERT_LINK')}</b></button>
      </p>

      <Modal isOpen={isModalOpen} modalClassName="referer-modal">

        <button className="close-modal-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        <ReferFriendForm />
      </Modal>
    </div>

  )
}


export default ReferFriendBar