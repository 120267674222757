import React from 'react';
import PropTypes from 'prop-types';
import CustomRoute from './CustomRoute';
import { useSelector } from 'react-redux';

const CuratorRoute = ({ component, main, ...rest }) => {
  const { isAuthenticated, user } = useSelector(state => state.auth);
  const accept = isAuthenticated && user?.role === 'curator';
  // const fallback = isAuthenticated ? '/home' : '/';
  const fallback = user.role !== 'curator' 
    ? '/' : main && user.curatorRole !== 'main' ? '/curator/cases' : '/'


  return <CustomRoute 
    component={ component }
    accept={ main ? accept && user.curatorRole === 'main' : accept } 
    fallback={ fallback }
    { ...rest }
  />
}


CuratorRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  rest: PropTypes.object
}

export default CuratorRoute;
