import React from 'react'
import PropTypes from 'prop-types'
import { CaseImageWithBottles } from '..';

import './style.scss'


const CaseImageWithPrice = ({ caseId, image, wines, bottleImages, alt, price, containerClass }) => {
  const mainClassNames = ['case-image-wrapper']
  if (containerClass) mainClassNames.push(containerClass)

  const bottleImagesList = bottleImages || wines?.reduce(
    (acc, wineData) => {
      if(wineData.bottleImage) {
        for (let i = 0; i < wineData.quantity; i++) {
          acc.push(wineData.bottleImage)
        }
      }

      return acc;
    }, []
  ) || []

  const imageComponent = image
    ? ( <img src={image} alt={alt} /> )
    : (
      <CaseImageWithBottles caseId={caseId} bottleImages={bottleImagesList} />
    )


  return (
    <div className={ mainClassNames.join(' ') }>
      { imageComponent }

      {
        price &&
        <span className="price-label">{ price }</span>
      }
    </div>
  )
}

CaseImageWithPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  , image: PropTypes.string
  , alt: PropTypes.string
  , containerClass: PropTypes.string
}

CaseImageWithPrice.defaultProps = {
  alt: ''
}

export default CaseImageWithPrice