import React from 'react';
import PropTypes from 'prop-types';
import CustomRoute from './CustomRoute';
import { useSelector } from 'react-redux';

const AdminRoute = ({ render, component, ...rest }) => {
  const { isAuthenticated, user } = useSelector(state => state.auth)
  const accept = isAuthenticated && user.role && user.role === 'admin';
  // const fallback = isAuthenticated ? '/home' : '/';
  const fallback = '/';


  return <CustomRoute
    component={component}
    accept={accept}
    fallback={fallback}
    {...rest}
  />
}


AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  rest: PropTypes.object
}

export default AdminRoute;
