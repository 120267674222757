import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ButtonWithPopoverConfirmation } from '..';
import { useRequests } from '../../hooks';


const DeleteWarehouseWines = ({ warehouse }) => {
  const { del, requestPending } = useRequests();
  const [confirmDialogIsOpen, setConfirmDialogState] = useState(false);

  const handleDeleteWarehouseWines = async () => {
    if (warehouse?.value > 0) {
      const response = await del({ url: '/api/wines/?wh=' + warehouse?.value }); 

      if (response?.data?.success) {
        setConfirmDialogState(false);
        toast.success(`Wines from the warehouse "${warehouse?.label}" have been deleted!`);
      }
    }
  }


  return (
      <ButtonWithPopoverConfirmation
        mainButtonClasses="button button_red"
        style={{ width: 'auto', margin: 0 }}
        pending={ requestPending }
        isOpen={ confirmDialogIsOpen }
        disabled={ !warehouse }
        onClick={ () => setConfirmDialogState(true) }
        onCancel={ () => setConfirmDialogState(false) }
        position="bottom"
        btnText="Delete Wines"
        onConfirm={handleDeleteWarehouseWines}
        confirmText={`Are you sure you want to delete wines from ${warehouse?.label}?`}
      />
  )
}

DeleteWarehouseWines.propTypes = {
  warehouse: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

export default DeleteWarehouseWines;
