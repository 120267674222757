import { createAsyncThunk } from '@reduxjs/toolkit'
import * as WineService from '../../services/wine.service'
import { authActions } from '../auth/auth.slice'


export const requestCurrentUserFavouriteWines = createAsyncThunk(
  '@@wines/request-current-user-favourite-wines',
  async (_, thunkAPI) => {
    try {
      const wines = await WineService.fetchUserFavouriteWinesList();

      return JSON.stringify(wines?.length > 0 ? wines : [])
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWinesList = createAsyncThunk(
  '@@wines/request-wines-list',
  async ({ language, warehouseId, regions = [] }, thunkAPI) => {
    try {
      const winesList = await WineService.fetchWinesList({ language, warehouseId, regions });

      const { user } = thunkAPI.getState().auth;

      const wines = winesList?.map(wineData => {
        const favouriteWines = user.favouriteWines;
        const changedWineData = favouriteWines?.length > 0
          ? { ...wineData, isFavourite: favouriteWines.includes(wineData.id) }
          : wineData

        return changedWineData;
      }) || []

      wines.sort((prevWineData, nextWineData) => {
        return +nextWineData.isAdjusted - +prevWineData.isAdjusted
      })

      return JSON.stringify(wines)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCurrentUserOrderedWines = createAsyncThunk(
  '@@wines/request-current-user-ordered-wines',
  async ({ language = 'en' }, thunkAPI) => {
    try {
      const winesList = await WineService.fetchCurrentUserOrderedWines({ language })

      return winesList;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requesCreatetOrderByWineId = createAsyncThunk(
  '@@wines/request-create-order-by-wine-id',
  async (wineId, thunkAPI) => {
    try {
      const isCreated = await WineService.createOrderByWineId(wineId)

      return isCreated
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestUpdateUserWineStatistics = createAsyncThunk(
  '@@wines/request-update-user-wine-statistics',
  async ({ index, wineId, amountBottlesDrunk: bottlesDrunk, willRecomend, hideFeedback, isFavourite: isFavouriteRequest, wines }, thunkAPI) => {
    try {
      const { ids, indexes } = wines?.reduce(
        (acc, { id, index }) => {
          acc.ids.push(id)
          acc.indexes.push(index)

          return acc;
        }, { ids: [], indexes: [] }
      ) || {}

      const wineData = await WineService.updateUserWineStatistics({
        wineId, amountBottlesDrunk: bottlesDrunk, willRecomend, isFavourite: isFavouriteRequest, wines: ids
      })

      if (indexes?.length > 0) {
        thunkAPI.dispatch(authActions.changeUserdata({ hasNewWines: false }))
      }

      const { amountBottlesDrunk, isFavourite } = wineData;
      return { index, amountBottlesDrunk, willRecomend, isFavourite, hideFeedback, indexes };
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWinesPreviewList = createAsyncThunk(
  '@@wines/request-wines-preview-list',
  async ({ producer, regionId, id, warehouse } = {}, thunkAPI) => {

    try {
      const data = await WineService.getWinesPreviewList({ regionId, producer, id, warehouse })

      return data;
    } catch (err) {
      console.log('ERROR: ', err)
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWineCasesPreviewsList = createAsyncThunk(
  '@@wines/request-wine-cases-previews-list',
  async ({ slug, regionId, producer }, thunkAPI) => {
    try {
      const data = await WineService.getWineCasesPreviews({ regionId, slug });
      const { cases = [], regionCases = [] } = data;

      return { cases, regionCases };
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWinePreviewData = createAsyncThunk(
  '@@wines/request-wine-preview-data',
  async ({slug, wineData}, thunkAPI) => {
    try {
      const data = wineData || await WineService.getWinePreview(slug);

      // {
      //   id: 609,
      //   name: 'Beaujolais Cru Levee Pouilly-Fuisse',
      //   vintage: 2016,
      //   bottleImage: 'https://vineify-dev-files.s3.amazonaws.com/1gnk9e9dn_bottle.png',
      //   sortOfGrape: 'Chardonnay',
      //   category: 'white',
      //   producer: 'Manoir du Carra',
      //   producerId: 101,
      //   region: 'Burgundy',
      //   regionId: 35,
      //   regionStripHeaderImage: 'https://vineify-dev-files.s3.amazonaws.com/1gnmghdsq_wine-data-header.jpg',
      //   country: 'France',
      //   description: 'Manoir du Carra is located in the small town of Denice, south of Burgundy, in the heart of the Beaujolais area. The property dates back to 1850 and has been bequeathed from father to son for several generations. It is now owned by Jean-Noël Sambardier, his wife & two sons, Frédéric and Damien. Manoir du Carra began as 4 hectares in 1962, but today covers over 34 hectares (84 acres) and several appellations: Beaujolais, Beaujolais-Villages, Brouilly, Julienas, Moulin à Vent, Fleurie and Beaujolais Blanc. They have 50 vineyard plots ranging from 50-100 years old.\n'
      // }

      // fetchCurrentProducerWines(wineData?.producer, wineData?.regionId)


      // fetchCasesWithWine(wineData?.regionId, wineData?.region, wineData?.name)
      if (data && Object.keys(data).length > 0) {
        const { producer, regionId, id } = data;
        thunkAPI.dispatch(requestWineCasesPreviewsList({ regionId, producer, slug }))
        thunkAPI.dispatch(requestWinesPreviewList({ regionId, producer, id, warehouse: 2 }))
      }

      return data || {}
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWinesCatalog = createAsyncThunk(
  '@@wines/request-wines-catalog',
  async (language = 'en', thunkAPI) => {
    try {
      // /api/wines/catalog?ac=1&lng=${i18n.language}&new=2
      const wines = await WineService.fetchWinesCatalog(language)

      return wines
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)


export const requestWineData = createAsyncThunk(
  '@@wines/request-wine-data',
  async ({ wineId, active }, thunkAPI) => {
    try {
      const payload = await WineService.fetchWineData({ wineId, active })

      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)


export const requestWineSalesReport = createAsyncThunk(
  '@@wines/request-wines-sales-report',
  async (wineId, thunkAPI) => {
    try {
      const payload = await WineService.fetchWineSalesReport(wineId)

      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWineLabels = createAsyncThunk(
  '@@wines/request-wine-labels',
  async (_, thunkAPI) => {
    try {
      const labels = await WineService.fetchWineLabels();

      return labels || []
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestAddNewLabel = createAsyncThunk(
  '@@wines/request-add-new-label',
  async (text, thunkAPI) => {
    try {
      const payload = await WineService.createNewLabel(text)

      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.message || err)
    }
  }
)

export const requestUpdateLabel = createAsyncThunk(
  '@wines/request-update-label',
  async ({ id, text, index }, thunkAPI) => {
    try {
      await WineService.updateLabel({ id, text })

      return { text, index }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.message || err)
    }
  }
)

export const requestDeleteLabel = createAsyncThunk(
  '@@wines/request-delete-label',
  async (id, thunkAPI) => {
    try {
      await WineService.deleteLabel(id)

      return id;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.message || err)
    }
  }
)

export const requestRestockHistory = createAsyncThunk(
  '@@wines/request-wine-restock-history',
  async (wineId, thunkAPI) => {
    try {
      const payload = await WineService.fetchWineRestockHistory(wineId)

      return payload;
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWineCasesList = createAsyncThunk(
  '@@wines/request-wine-cases-list',
  async (wineId, thunkAPI) => {
    try {
      const payload = await WineService.fetchWineCasesList(wineId)

      return payload;
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestWineOrphansList = createAsyncThunk(
  '@@wines/request-wine-orphans-list',
  async (_, thunkAPI) => {
    try {
      const payload = await WineService.fetchWineOrphansList()

      return payload;
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)
