import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon, FollowCuratorButton } from '..'

import './style.scss'


const CaseDetailsCuratorPreview = ({ photo, smallPhoto, id, firstName, lastName }) => {
  const fullName = `${firstName} ${lastName}`
  const link = `/curators/${id}-${firstName?.toLowerCase()}-${lastName?.toLowerCase()}`

  const curatorPhoto = smallPhoto?.length > 0
    ? <img src={smallPhoto} alt={fullName} />
    : photo?.length > 0
      ? <img src={photo} alt={fullName} /> 
      : (
        <div className="case-details__curator-placeholder">
          <Icon icon="user" color="white" size={35} />
        </div>
      )


  return (
    <div className="case-details__curator-preview">
      <Link className="image-link" to={link}>{curatorPhoto}</Link>

      <div className="curator-preview__content">
        <p className="curator-preview__content-text">Curated By</p>

        <Link className="curator-preview__content-link" to={link}>{fullName}</Link>

        <FollowCuratorButton 
          buttonClassName="button_xs curator-preview__follow-button" 
          id={+id}
          curatorData={{ name: fullName, id }}
        />
      </div>
    </div>
  )
}

CaseDetailsCuratorPreview.propTypes = {
  firstName: PropTypes.string
  , lastname: PropTypes.string
  , photo: PropTypes.string
  , id: PropTypes.number
}

export default CaseDetailsCuratorPreview