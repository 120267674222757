import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useHelpers } from '../../hooks';
import { TagsList, ShortDescription } from '..';

import './style.scss';


const PostCard = ({ postData }) => {
  const { getWpImageSrc } = useHelpers();
  const history = useHistory();

  // const imageSrc = getWpImageSrc(postData.content);
  const imageSrc = postData.featuredImage;

  const handleGoToArticlePage = e => {
    e.preventDefault();

    history.push(`/blog/posts/${ postData.name }`)
  }


  return (
    <div className="post-card">
      <div className="post-card__header">

        <img src={imageSrc} alt="" />
      </div>

      <div className="post-card__body">
        <h5>{postData.title}</h5>

        {/* <p className="post-data__excerpt"></p> */}
        <ShortDescription
          lines={2}
          expandable={false}
          withFade={false}
        >{postData.excerpt}</ShortDescription>

        <button className="button button_outline"
          onClick={ handleGoToArticlePage }
        >Read Article</button>

        <TagsList tags={ postData.tags } />
      </div>

    </div>
  )
}

PostCard.propTypes = {

}

export default PostCard