import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import plus from '../../assets/icons/plus.svg';
import minus from '../../assets/icons/minus.svg';

import './style.scss';


const Accordion = ({ items }) => {
  const content = items.map((item, ind) => <AccordionItem key={item.title + '-' + ind} {...item} />)

  return <ul className="accordion">{content} </ul>
}


const AccordionItem = ({ title, text }) => {
  const [collapsed, setCollapsed] = useState(false);
  const icon = collapsed
    ? <img src={minus} alt="collapse" />
    : <img src={plus} alt="expand" />

  const bodyClasses = classNames('accordion__body', { 'collapsed': collapsed });

  const toggleCollapseState = () => {
    setCollapsed(!collapsed);
  }


  return (
    <li className="accordion__item">
      <div className="accordion__header">
        <div className="accordion__toggle-button">
          <button onClick={toggleCollapseState}
          >{icon} <span className="accordion__title">{title}</span></button>
        </div>

      </div>
      <div className={bodyClasses} dangerouslySetInnerHTML={{
        __html: text
      }} />
    </li>
  )
};



Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  }))
}

export default Accordion
