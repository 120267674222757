import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import classNames from 'classnames'


const AdminTastePill = ({ names, name, id, cases, wines, onEdit }) => {
  const [popoverIsOpened, setPopoverOpenState] = useState(false)

  const listOfCases = cases?.map(({ id, name, active }) => {
    return (
      <li key={`label-case-stat-${id}`} className={classNames('wine-case-stat-item flex-space-between gap', { 'active': active > 0 })}>
        <span>{name}</span>

        <b>{id}</b>
      </li>
    )
  }) || (
      <li key={`wine-case-stat-no-cases`} className="wine-case-stat-item">
        There are no cases with this label...
      </li>
    );

  const listOfWines = wines?.map(({ id, name }) => (
    <li key={`lable-wine-stat-${id}`} className="wine-case-stat-item  flex-space-between gap">
      <span>{name}</span>

      <b>{id}</b>
    </li>
  )) || (
      <li key={`wine-case-stat-no-cases`} className="wine-case-stat-item">
        There are no wines with this label...
      </li>
    );

  const handleInitEditTaste = e => {
    e.preventDefault();

    onEdit({ names, name, id })
  }

  const handleTogglePopover = state => e => {
    e.preventDefault()

    setPopoverOpenState(state === 'show')
  }

  return (
    <Popover
      isOpen={popoverIsOpened}
      padding={32}
      positions={['top']}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            nudgedLeft={0}

            arrowColor={'white'}
            arrowSize={0}
          // arrowClassName="popover-arrow"
          ><div className="popover-container wine-popover">
              <ul className="wine-case-stat-list">
                <li className="title">Cases</li>

                {listOfCases}

                <li className="title">Wines</li>

                {listOfWines}
              </ul>
            </div>
          </ArrowContainer>
        )
      }
    >
      <button
        key={`taste_pill_${id}`}
        className="admin-taste-row"
        onClick={handleInitEditTaste}
        onMouseOver={handleTogglePopover('show')}
        onMouseLeave={handleTogglePopover('hide')}
      >{name || names?.en}</button>
    </Popover>
  )
}


AdminTastePill.propTypes = {
  names: PropTypes.shape({
    en: PropTypes.string
    , it: PropTypes.string
  })
  , name: PropTypes.string
  , id: PropTypes.number
  , onEdit: PropTypes.func
}

export default AdminTastePill;
