import React from 'react'
import { Icon } from '../../../components'


const PublicHomeAbout = () => {

  const infoList = [
    {
      icon: 'wine',
      title: 'Expertly curated cases',
      text: <>We select the best wines that are most representative of their regions, and seek out bottles you probably haven’t tried before. Then we curate them in a variety of cases to let you explore Europe in the way best suited to you. By occasion, by season, by region.</>
    },
    {
      icon: 'delivery-truck',
      title: 'Personalized Delivery Time',
      text: <>The team at Davenport Wines & Spirits in Austin will deliver where and when you want (within Travis County currently). All scheduled via text. Say goodbye to one of the biggest hassles of buying wine online!</>
    },
    {
      icon: 'award',
      title: 'Great value!',
      text: <>We secure case-level discounting and pass it on to you. You get great wine …for a great price, with no shipping fees!</>
    },
  ]

  const cardsContent = infoList.map(({ title, text, icon }) => (
    <div key={`${title.replace(' ', '_')}`} className="public-home__info-card">
      <i className="info-card__icon-placeholder">
        <Icon icon={ icon } size={32} color="dark-gold" />
      </i>
      
      <h3 className="info-card__title">{title}</h3>

      <p className="info-card__text">{text}</p>
    </div>
  ))

  return (
    <div className="public-home__section lite">
      <div className="container">
        <h2 className="public-home__title">Why We Think You Will Love Vineify:</h2>

        <div className="public-home__info-cards">{cardsContent}</div>
      </div>
    </div>
  )
}

export default PublicHomeAbout