import React, { useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TruncateMarkup from 'react-truncate-markup';
import { useHelpers } from '../hooks';


const ShortDescription = ({ text, expandable, lines, children, withFade, textClassname }) => {
  const { t } = useTranslation();
  const [isExpanded, changeExpandedState] = useState(false);
  const shortDescRef = useRef();
  const { scrollTo, capitalize } = useHelpers();
  const textClasses = ['short-description'];

  textClassname && textClasses.push(textClassname)


  const toggleTruncate = e => {
    e.preventDefault();

    if (isExpanded) scrollTo(shortDescRef.current, 0, 300, 40, 'top')
    changeExpandedState(!isExpanded);
  }

  const readMoreEllipsis = (
    <span>
      <span onClick={toggleTruncate} className="more-info-link">
        {capitalize(t('READ_MORE'))}...
      </span>
    </span>
  );

  const simpleEllipsis = (
    <span>...</span>
  )



  const content = !isExpanded || !expandable
    ? (
      <TruncateMarkup
        lines={lines}
        ellipsis={expandable ? readMoreEllipsis : simpleEllipsis}
      >
        <p className={textClasses.join(' ')}>
          {children} 
        </p>
      </TruncateMarkup>
    ) : (
      <div className="short-description expanded">
        {children}
        <span onClick={toggleTruncate} className="more-info-link">
          {capitalize(t('READ_LESS'))}
        </span>
      </div>
    )

  return (
    <div className="short-desc-wrapper" ref={shortDescRef} >{content}</div>
  )
}


ShortDescription.propTypes = {
  text: PropTypes.string,
  expandable: PropTypes.bool,
  lines: PropTypes.number,
  withFade: PropTypes.bool
}

ShortDescription.defaultProps = {
  expandable: false,
  withFade: true,
  lines: 3
}

export default memo(ShortDescription)
