import PropTypes from 'prop-types';

import { statesOfUSA, countries } from '../temp/mock-data';

const initialData = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  company: '',
  address: '',
  appartment: '',
  city: '',
  country: '',
  state: '',
  zip: ''
};

class Contact {

  constructor(contactDetails) {
    this.email = contactDetails?.email || '';
    this.firstName = contactDetails?.firstName || '';
    this.lastName = contactDetails?.lastName || '';
    this.company = contactDetails?.company || '';
    this.address = contactDetails?.address || '';
    this.appartment = contactDetails?.appartment || '';
    this.city = contactDetails?.city || '';
    this.country = contactDetails?.country || '';
    this.state = contactDetails?.state || '';
    this.zip = contactDetails?.zip || '';
    this.phone = !contactDetails ? '' : !contactDetails.phone
      ? ''
      : ('' + contactDetails.phone).startsWith('+')
        ? contactDetails.phone
        : ('' + contactDetails.phone).length > 10
          ? '+' + contactDetails.phone
          : '+1' + contactDetails.phone;
  };

  static compare = (obj1, obj2) => {
    const stringifyedFirstObject = JSON.stringify(obj1);
    const stringifyedSecondObject = JSON.stringify(obj2);

    return stringifyedSecondObject === stringifyedFirstObject;
  }

  static getStates = (state) => {
    if (!state) return '';

    return statesOfUSA[state];
  }

  static getStatesList = () => {
    const codes = Object.keys(statesOfUSA);

    return codes.map(code => ({ value: statesOfUSA[code], label: code }));
  }

  static createFromPaypalData = (data) => {
    const {
      address: {
        address_line_1: address,
        address_line_2: appartment,
        admin_area_1: state,
        admin_area_2: city,
        country_code: country,
        postal_code: zip
      },
      name: { full_name: fullName },
      email, phone
    } = data;

    const [firstName, ...lastName] = fullName.split(' ');
    const fullAddress = appartment ? `${address}, ${appartment}` : address

    return new Contact({
      firstName, lastName: lastName.join(' '), address: fullAddress, appartment, state, city, country, zip, email, phone
    })
  }

  static getCountryName = countryCode => {
    const countryName = countries.find(({ code }) => code === countryCode)?.name || '';

    return countryName;
  }

  static getCountryDialCode = countryCode => {
    const dialCode = countries?.find(({ code }) => code === countryCode)?.dial_code || '';

    return dialCode;
  }

  isEmpty = function () {
    return Object.keys(this)
      .every(prop => ['isEmpty', 'company', 'appartment'].includes(prop) || this[prop].length > 0)
  }
}


Contact.propTypes = {
  contactDetails: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    address: PropTypes.string,
    appartment: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string
    ])
  })
}

Contact.defaultProps = {
  ...initialData
}


export default Contact;
