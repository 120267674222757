import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../context/country';

import { useActions, useHelpers, useRequests } from '../../hooks';
import { ShortDescription, WinesTypesSummary } from '../';
import CaseDetails from '../CaseDetails';
import { useHistory } from 'react-router';
import highlitedCaseImage from '../../assets/images/highlited_case_image.jpg';
import { useDispatch, useSelector } from 'react-redux';


const NewHomeCaseCard = ({ previewMode, caseData, highLighted, descriptionSize, currency: providedCurrency }) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.auth)
  const { recommendationsActions, cartActions } = useActions()
  const { charge, currency: userCurrency } = useContext(CountryContext)
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { createWinesTypes, scrollTo, formCasePrice } = useHelpers();
  const { get } = useRequests();
  const caseDetailsRef = useRef();

  const [state, changeState] = useState({
    caseDetails: null, isShawn: false
  });

  const caseCharge = caseData?.warehouse?.search('Serendipity') >= 0
    ? { usd: 0, eur: 0 } : charge;

  const { name, wines, image, description, id, stock } = caseData;
  // const currency = i18n.language === 'it' ? 'eur' : 'usd';

  const showDetails = caseDetails => {
    changeState({
      caseDetails,
      isShawn: true
    });

    scrollTo(caseDetailsRef.current);
  }

  const fetchCaseDetails = async () => {
    const response = await get({ url: `/api/wines/cases/${id}/case-details?lng=${i18n.language}` });

    if (response?.data?.success) {
      return response.data.caseDetails
    }

    return null;
  }

  const requestCaseDetails = () => {
    // let details = force || !state.caseDetails || state.caseDetails.id !== id ?
    //   await fetchCaseDetails() : state.caseDetails;

    // showDetails(new Case(details));
    // ctx.regionRecommendationCase = id;

    dispatch(recommendationsActions.setRecommendedCustomCaseToShow(id))

    history.push(`/regions/${caseData.region}`);
  }

  const addCaseToCart = async caseDetails => {

    const details = !state.caseDetails ?
      await fetchCaseDetails() : state.caseDetails

    if (stock === 0) return;

    // const response = await post({
    //   url: '/api/orders',
    //   body: {
    //     caseId: details.id, caseDetailsId: details.caseDetailsId,
    //     // cost: details.getPrice(currency, charge) 
    //     // cost: formCasePrice(details.getPrice(currency, charge, false))
    //     cost: formCasePrice(+details?.prices[currency])
    //   }
    // });

    // if (response && response.data.success) {
    //   ctx.cart.order = {
    //     case: { ...details },
    //     id: response.data.orderId
    //   };
    //   ctx.cart.order.step = 'customer';

    //   history.push('/cart')
    // } else {
    //   toast('Something goes wrong...')
    // }

    dispatch(cartActions.requestAddCaseToCart({
      caseId: details.id, 
      caseDetailsId: details.caseDetailsId,
      cost: formCasePrice(+details?.prices[currency]),
      redirectToCart: true
    }))
  };

  const handlePurchaseCase = async e => {
    e && e.preventDefault();

    if (!state.caseDetails) {
      const details = await fetchCaseDetails();

      return await addCaseToCart(details);
    }

    await addCaseToCart(state.caseDetails)
  }

  // eslint-disable-next-line
  useEffect(() => { state?.caseDetails?.id && requestCaseDetails(true); }, [i18n.language]);

  const currency = providedCurrency || userCurrency;

  const noInStock = state.caseDetails ?
    state.caseDetails.stock < 1 : stock < 1;

  const wineTypes = Array.isArray(wines) ?
    createWinesTypes(wines) : wines;

  let caseLayoutColor = highLighted ? 'default' : 'mix';

  if (!highLighted && wineTypes.red > wineTypes.white + wineTypes.sparkling) { caseLayoutColor = 'red' }
  if (!highLighted && wineTypes.white > wineTypes.red + wineTypes.sparkling) { caseLayoutColor = 'white' }

  const winesTypesSummary = <WinesTypesSummary {...wineTypes} />


  return (
    <div className="full-size_case-wrapper homepage-case">
      <div className={`card card_case`} style={{ height: '324px' }}>
        <div className={`card-body-wrapper case_${caseLayoutColor}`} />

        {/* <div className="card-bg"><img src={image} alt={name} /></div> */}
        <div className="card-bg"><img src={highlitedCaseImage} alt={name} /></div>

        <div className="card__body">
          <div className="card_case-content">
            <h2 className="case__title">{name}</h2>
            {winesTypesSummary}

            {
              !previewMode &&
              <h4 className="case__price">{
                // formatCurrency(prices[currency], currency, false, false)
                // caseData.getPrice(currency, charge)
                formCasePrice(caseData.getPrice(currency, caseCharge))
              }</h4>
            }

            <div className="card-bottom-section">
              <div className="flex-space-between w-100">
                {
                  descriptionSize > 0 &&
                  <ShortDescription
                    lines={descriptionSize}
                    textClassname="text-shadow"
                    expandable={false}
                    withFade={false}
                  >{description || ''}</ShortDescription>
                }
                <div className="flex-space-between">
                  <button
                    className="button button_outline reversed"
                    style={{ width: '215px' }}
                    onClick={requestCaseDetails}
                  >{t('CASE_CARD_SEE_CASE_DETAILS')}</button>

                  <button
                    className="button reversed"
                    style={{ width: '215px', marginLeft: '16px' }}
                    disabled={noInStock}
                    onClick={handlePurchaseCase}
                  >{noInStock ? t('SOLD_OUT') : t('BUY_THIS_CASE_NOW')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        state.isShawn &&
        <div ref={caseDetailsRef}>
          <CaseDetails
            onPurchase={handlePurchaseCase}
            {...state.caseDetails}
            price={formCasePrice(state.caseDetails?.getPrice(currency, charge))}
            purchaseBtn={true}
            isAuthenticated={isAuthenticated}
          />
        </div>
      }
    </div>
  )
}


NewHomeCaseCard.propTypes = {
  name: PropTypes.string,
  wines: PropTypes.arrayOf(PropTypes.object),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChooseCase: PropTypes.func,
  id: PropTypes.number,
  descriptionSize: PropTypes.number,
  previewMode: PropTypes.bool,
  highLighted: PropTypes.bool
}

NewHomeCaseCard.defaultProps = {
  descriptionSize: 3,
  tempClass: 'mix',
  wines: [],
  previewMode: false,
  highLighted: false,
  onChooseCase: () => { }
}

export default NewHomeCaseCard;
