import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingScreen, WaitListInvitationBar } from '../../components';
import authContext from '../../context/authContext';
import { useActions, useRequests } from '../../hooks';

import PublicHomeAbout from './sections/PublicHomeAbout';
import PublicHomeCasesCarousel from './sections/PublicHomeCasesCarousel';
import PublicHomeCurators from './sections/PublicHomeCurators';
import PublicHomeHeader from './sections/PublicHomeHeader';
import PublicHomeRegions from './sections/PublicHomeRegions';
import PublicHomeSubscribeSection from './sections/PublicHomeSubscribeSection';
import PublicHomeWelcome from './sections/PublicHomeWelcome';

import './style.scss';


const SixWinesPackHomepage = () => {
  const dispatch = useDispatch()
  const { cartActions } = useActions()
  const history = useHistory();
  const { get, post } = useRequests()
  const [state, changeState] = useState({
    dataIsFetched: false, regions: [], cases: [], notificationIsShawn: true
  });

  const handleViewCaseContent = ({ region, id, slug, category }) => {
    history.push(`/cases/${slug}`)
  }

  const handleCasePurchase = async caseDetails => {

    const { id, caseDetailsId, stock, cost } = caseDetails;

    if (stock === 0) return;

    if (cost <= 0) {
      await post({
        url: '/api/content/error-report',
        body: {
          subject: `Error: cost $0 in case ID${id}`,
          error: `Error: cost $0 in case ID${id}`
        }
      })

      return toast.error('Something went wrong');
    }

    dispatch(cartActions.requestAddCaseToCart({
      caseDetails, caseId: id, caseDetailsId, cost, redirectToCart: true
    }))
  };

  const fetchRegions = async () => {
    const response = await get({ url: `/api/content/parent-regions?v=6&lng=en` })

    if (response?.data?.success) {
      return response.data.regions
    } else {
      return []
    }
  }

  const fetchCases = async () => {
    const response = await get({ url: `/api/wines/cases/recommendations/all?ws=1&v=6` })

    if (response?.data?.success) {
      return response.data.cases
    } else { return [] }
  }

  const fetchAllData = async () => {
    const [regions, cases] = await Promise.all([
      fetchRegions(), fetchCases()
    ])

    const sortedCases = cases
      ?.filter(({ wines }) => {
        const numOfBottles = wines.reduce(
          (acc, { quantity }) => {
            acc += quantity;

            return acc;
          }, 0
        )

        return numOfBottles <= 6
      })
      ?.sort((caseData, nextCaseData) => {
        const nextCaseTotalBottles = nextCaseData?.wines.reduce(
          (acc, { quantity }) => {
            acc += +quantity;

            return acc
          }, 0
        )
        const totalBottles = caseData?.wines.reduce(
          (acc, { quantity }) => {
            acc += +quantity;

            return acc
          }, 0
        )

        if (nextCaseTotalBottles === 6 && totalBottles === 6) {
          return caseData?.prices?.usd - nextCaseData?.prices?.usd
        }

        if (totalBottles === 6) return -1;
      })

    changeState(prev => ({
      ...prev, regions, cases: sortedCases, dataIsFetched: true
    }))
  }

  useEffect(() => {
    fetchAllData()
  }, []);

  const handleDismissAlert = () => {
    changeState(prev => ({ ...prev, notificationIsShawn: false }))
  }


  if (!state.dataIsFetched) return <LoadingScreen />


  return (
    <div className="public-home">
      {
        state.notificationIsShawn && <WaitListInvitationBar onDismiss={handleDismissAlert} headerClassName="public-home__header-alert" />
      }

      <PublicHomeHeader />

      <PublicHomeCasesCarousel
        showBanner={true}
        title="Explore Our Curated Collections"
        onPurchase={handleCasePurchase}
        onViewCaseContent={handleViewCaseContent}
        cases={state.cases}
        useRegionCardImage={true}
      />

      <PublicHomeRegions regions={state.regions} />

      <PublicHomeCurators />

      <PublicHomeAbout />

      <PublicHomeWelcome />

      <PublicHomeSubscribeSection />
    </div>
  )
}


export default SixWinesPackHomepage;
