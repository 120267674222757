import React from 'react';
import { useDispatch } from 'react-redux';
import { useActions } from '../../hooks';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { WinesTypesSummary } from '..';

import heartIcon from '../../assets/icons/heart-filled.svg';
import defaultImage from '../../assets/images/recommendation-preview.jpg';

import './style.scss';


const RecommendationCasePreviewCard = ({
  wines, image, hasOrders, hasCustomCases, hasFavouriteWines, caseId
}) => {
  const dispatch = useDispatch()
  const { recommendationsActions } = useActions()
  const history = useHistory();
  const { t } = useTranslation();

  const handleRedirectToRecommendations = e => {
    e.preventDefault();

    if (caseId) dispatch(recommendationsActions.setRecommendedCustomCaseToShow(caseId))

    history.push('/recommendations');
  }

  const { title, subtitle, description } = !hasOrders ?
    {
      title: t('HOME_RECOMMENDATION_PREVIEW_NEW_TITLE'),
      subtitle: t('HOME_RECOMMENDATION_PREVIEW_NEW_SUBTITLE'),
      description: t('HOME_RECOMMENDATION_PREVIEW_NEW_DESCRIPTION')
    } : !hasFavouriteWines ?
      {
        title: t('HOME_RECOMMENDATION_PREVIEW_WITH_ORDERS_TITLE'),
        subtitle: t('HOME_RECOMMENDATION_PREVIEW_WITH_ORDERS_SUBTITLE'),
        description: t('HOME_RECOMMENDATION_PREVIEW_WITH_ORDERS_DESCRIPTION')
      } : {
        title: t('HOME_RECOMMENDATION_PREVIEW_WITH_FAVS_TITLE'),
        subtitle: t('HOME_RECOMMENDATION_PREVIEW_WITH_FAVS_SUBTITLE'),
        description: t('HOME_RECOMMENDATION_PREVIEW_WITH_FAVS_DESCRIPTION')
      }


  return (
    <div className="card recommendation-preview">
      <div className="card__body">
        <div className="recommendation-preview__image">
          <img src={image} alt="" />

          <span className="fav-badge"><img src={heartIcon} alt="" /></span>
        </div>

        <div className="recommendation-preview__content">
          <div className="recommendation-preview__content-header">
            <p className="subtitle">{subtitle}</p>

            <h3 className="title">{title}</h3>

            <WinesTypesSummary
              {...wines}
              style={{ color: 'gray' }}
            />
          </div>

          <div className="recommendation-preview__description">
            <p className="no-margin">{description}</p>
          </div>
        </div>

        <div className="recommendation-preview__footer">
          <button
            className="button"
            onClick={handleRedirectToRecommendations}
          >{t('HOME_RECOMMENDATION_PREVIEW_BTN_TEXT')}</button>
        </div>
      </div>
    </div>
  )
}

RecommendationCasePreviewCard.propTypes = {
  wines: PropTypes.object,
  image: PropTypes.string,
  hasCustomCases: PropTypes.bool,
  hasOrders: PropTypes.bool,
}

RecommendationCasePreviewCard.defaultProps = {
  image: defaultImage,
  hasCustomCases: false,
  hasOrders: false,
  wines: {}
}


export default RecommendationCasePreviewCard;
