import React from 'react';
import PropTypes from 'prop-types';
import CustomRoute from './CustomRoute';
import { useSelector } from 'react-redux';

const RetailerRoute = ({ component, ...rest }) => {
  const { isAuthenticated, user } = useSelector(state => state.auth)
    const accept = isAuthenticated && ['retailer'].includes(user?.role);
    // const fallback = isAuthenticated ? '/home' : '/';
    const fallback = '/';

    return <CustomRoute 
        component={ component }
        accept={ accept } 
        fallback={ fallback }
        { ...rest }
    />
}


RetailerRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    rest: PropTypes.object
}

export default RetailerRoute;
