import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const AdminNavLink = ({ name, icon, path, active }) => {
  return (
    <li className={ classNames('admin-nav__item', { 'active': active}) }>
      <Link className="admin-nav__link" to={ path }>
          { icon && <img className="admin-nav__icon" src={ icon } alt={ name } /> }
          { name }
      </Link>
    </li>
  )
}

AdminNavLink.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool
}

AdminNavLink.defaultProps = {
  active: false
}

export default AdminNavLink
