import React, { memo, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as keyGen } from 'uuid';
import ReactPlayer from 'react-player/lazy';
import { Link, useHistory } from 'react-router-dom';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { ShortDescription, WineFeedbackAlert, Spinner } from '..';
import { useAnalitics, useRequests, useScreenSize, useHelpers, useActions, useStoreAuth, useStoreCart, useStoreWines } from '../../hooks';

import heartIcon from '../../assets/icons/heart.svg';
import hearFilledtIcon from '../../assets/icons/heart-filled.svg';
import chevronUp from '../../assets/icons/chevron-up.svg';
import chevronDown from '../../assets/icons/chevron-down.svg';
import thermometerIcon from '../../assets/icons/thermometer.svg';
import fluteGlassIcon from '../../assets/icons/flute-glass.svg';
import grapeBlack from '../../assets/icons/grape-black.svg';
import calendarBlack from '../../assets/icons/calendar-black.svg';
import play from '../../assets/icons/play.svg';

import './style.scss';


const AccountWineRow = ({
  onChange, onPlayVideo, timeOut, index
}) => {
  const { wineActions, winesState: { list, processingrequests, processingWineIds } } = useStoreWines()
  const { authState: { user: { role, id: userId, firstName, lastName, _suav } } } = useStoreAuth()
  const wine = list[index]
  const history = useHistory();
  const { t } = useTranslation();
  const { capitalize, getWineName } = useHelpers();
  const { sendMxCustomEvent } = useAnalitics();
  const [isPopoverOpen, setPopoverState] = useState(false);
  const { screenWidth } = useScreenSize();
  const { get, post, requestPending } = useRequests();
  // const [wine,] = useState(wineData || {});
  const [isExpanded, setExpandedState] = useState(false);
  // const wine = wineData;

  const wineFeedbackRef = useRef();
  const wineRow = useRef();

  const handleUpdateInventory = async () => {
    if (processingrequests > 0) return;

    clearTimeout(timeOut.current);
    
    wineActions.requestUpdateUserWineStatistics({ index, wineId: wine.id, amountBottlesDrunk: 1 })

    sendMxCustomEvent('Drink Bottle', {
      role, userId, userName: `${firstName} ${lastName}`, _suav,
      'Wine Name': wine.name, 'Wine ID': wine.id
    });
  }


  const goToCheckout = async () => {
    // const response = await get({ url: `/api/orders/case-data/${wineData.id}` });
    wineActions.requesCreatetOrderByWineId(wine.id)
    
    history.push('/cart');
  };


  const addWineToFavourite = async e => {
    e.preventDefault();
    if (processingrequests > 0) return;

    const mxEventName = wine.isFavourite ? 'Remove Favorite' : 'Add Favorite';
    sendMxCustomEvent(mxEventName, {
      role, userId, userName: `${firstName} ${lastName}`, _suav,
      'Wine Name': wine.name, 'Wine ID': wine.id
    });

    const currentIsFavouriteState = !wine.isFavourite;

    wineActions.requestUpdateUserWineStatistics({ 
      index, wineId: wine.id, isFavourite: currentIsFavouriteState, hideFeedback: true
    })
  }


  const handleShowDetails = e => {
    e.preventDefault();

    setExpandedState(prev => !prev);
  }


  const handleChangeFeedback = changes => {

    wine && onChange({ ...wine, ...changes })
  }

  const togglePopoverState = (e, isVisible) => {
    e.preventDefault();

    if (screenWidth > 767) {
      setPopoverState(isVisible);
    }
  }


  //warehouse,hasAvailableCases
  const {
    id, name, video, vintage, image, thumbnail, producer, grape, isFavourite, inventory, description, sortOfGrape,
    tastes, amountBottlesDrunk, category, stock, hasAvailableCase, warehouse, slug, bottleImage
  } = wine;
  const canBeBoughtAgain = hasAvailableCase && warehouse !== 'IWS'

  const bottles = useMemo(() => { return +inventory - +amountBottlesDrunk }, [inventory, amountBottlesDrunk]);

  if (!wine) return null;

  const wineVintage = vintage && !isNaN(+vintage)
    ? vintage : 'NV'


  return (
    <>
      <div className={classNames('account-wine', { 'adjusted': wine.isAdjusted })} ref={wineRow}>
        <div className={classNames('account-wine__row', { 'out-of-stock': stock === 0 && inventory === 0 })}>
          <button
            className="wine-like"
            onClick={addWineToFavourite}
          >
            <img src={isFavourite ? hearFilledtIcon : heartIcon} alt="favourite wine" />
          </button>

          <div
            className="wine-image"
            onMouseEnter={e => togglePopoverState(e, true)}
            onMouseLeave={e => togglePopoverState(e, false)}
          >
            {
              screenWidth > 767
                ? (
                  <Popover
                    isOpen={isPopoverOpen}
                    padding={-65}
                    positions={['right']}
                    content={
                      ({ position, childRect, popoverRect }) => (
                        <ArrowContainer
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          nudgedLeft={100}
                          arrowColor={'white'}
                          arrowSize={20}
                          arrowClassName="popover-arrow"
                        ><div className="popover-container">
                            <div className="image-container">
                              <img className="popover-image" src={thumbnail || image} alt={name} />
                            </div>
                          </div>
                        </ArrowContainer>
                      )
                    }
                  ><img src={thumbnail || image} alt={name} />
                  </Popover>
                ) : (
                  <img className="popover-image" src={thumbnail || image} alt={name} />
                )
            }
          </div>

          <div className="wine-info">
            <div className="wine-vintage">{wineVintage}</div>

            {/* <div className="wine-name">{`${vintage} ${producer} ${name}`}</div> */}
            <Link to={`/wines/${slug}`} className="wine-name">{getWineName({ name, producer, vintage })}</Link>

            <div className="wine-producer">{producer}</div>

            <div className={`wine-grape grape_${grape?.category || ''}`}>
              {sortOfGrape.split(',')[0]}
            </div>
          </div>

          <div className="wine-inventory">
            <button
              type="button"
              className="button-link"
              // disabled={stock === 0 && bottles === 0}
              disabled={processingWineIds.includes(wine.id) || (!canBeBoughtAgain && bottles === 0)}
              onClick={bottles > 0 ? handleUpdateInventory : stock > 0 ? goToCheckout : () => { }}
            // >{bottles > 0 ? capitalize(t('DRINK')) : stock > 0 ? capitalize(t('BUY_AGAIN')) : capitalize(t('OUT_OF_STOCK'))}</button>
            >{bottles > 0 ? capitalize(t('DRINK')) : canBeBoughtAgain > 0 ? capitalize(t('BUY_AGAIN')) : capitalize(t('OUT_OF_STOCK'))}</button>
            <span className="wine-inventory__badge">{bottles}</span>
          </div>

          <button
            className="wine-control-button"
            onClick={handleShowDetails}
          ><img src={isExpanded ? chevronUp : chevronDown} alt="show details" />
          </button>
        </div>
        {
          isExpanded &&
          <WineShortDetails
            grape={sortOfGrape}
            bottleImage={ bottleImage }
            onPlayVideo={onPlayVideo}
            grapeClass={`grape_${category}`}
            tastes={tastes}
            userId={userId}
            sendAnalitics={sendMxCustomEvent}
            userName={`${firstName} ${lastName}`}
            role={role}
            vintage={vintage}
            wine={name}
            wineId={id}
            producer={producer}
            description={description}
            video={video}
          />
        }
      </div>

      <WineFeedbackAlert
        key={keyGen()}
        wineRow={wineRow}
        index={index}
        feedbackRef={wineFeedbackRef}
        feedback={wine.feedback}
        post={post} wine={wine}
        disabled={requestPending}
        onChange={handleChangeFeedback}
        timer={timeOut}
      />
    </>
  )
}

// eslint-disable-next-line
const WineDetails = ({ video, grape, description, tastes }) => {
  const { t } = useTranslation();
  const tastesListItems = tastes.map(
    taste => <li key={taste} className="taste-item">{taste}</li>
  );

  return (
    <div className="wine-details">
      <div className="wine-details__video">
        {
          !video &&
          <div className="video-placeholder">
            <p>{t('MY_WINES_NO_VIDEO')}</p>
          </div>
        }
      </div>

      <div className="wine-details__recomend">
        <ul className="recomendations-list">
          <li className="recomendations-list__item">
            <img src={grapeBlack} alt="sort of grape" />
            {grape}
          </li>
          <li className="recomendations-list__item">
            <img src={fluteGlassIcon} alt="recommendation" />
            Flute Glass
          </li>
          <li className="recomendations-list__item">
            <img src={calendarBlack} alt="recommendation" />
            Drink before 2023
          </li>
          <li className="recomendations-list__item">
            <img src={thermometerIcon} alt="recommendation" />
            Drink very cold
          </li>
        </ul>
      </div>

      <div className="wine-details__info">
        <ul className="tastes-list">{tastesListItems}</ul>
        <p className="wine-details__description">
          <ShortDescription
            lines={6}
            expandable={true}
          ><p className="no-margin">{description}</p>
          </ShortDescription>
        </p>
      </div>
    </div>
  )
}


const WineShortDetails = ({
  video, grape, description, wineId, tastes, grapeClass, onPlayVideo, wine, producer, vintage, role, userName, userId, sendAnalitics, bottleImage
}) => {
  const { t } = useTranslation();
  const { getWineName, getVideoPlayerLink } = useHelpers();
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const videoLink = getVideoPlayerLink(video)?.link;
  const wineName = getWineName({ vintage, producer, wine });

  const tastesListItems = tastes.map(
    taste => <li key={taste} className="taste-item">{taste}</li>
  );
  const grapeRecordClasses = 'recomendations-list__item ' + grapeClass;

  const handleVideoClicked = (e, a) => {
    setVideoIsPlaying(true);

    onPlayVideo(videoLink);
  }

  const handlePlayVideo = player => {
    player.showPreview();

    // sendAnalitics('View Tasting Video', {
    //   role, userId, userName,
    //   'Wine Name': wine, 'Wine ID': wineId, 'Video Link': video
    // });

    // setVideoIsPlaying(true);
    // onPlayVideo(video);
    setVideoIsPlaying(false);
  }


  return (
    <div className="wine-details">
      <div className="wine-details__video">
        {
          !video
            ? (
              <div className="video-placeholder placeholder_bottle-image">
                {/* <p className="video-placeholder__wine-name">{`${vintage} ${producer} ${wine}`}</p> */}
                {/* <p className="video-placeholder__wine-name">{wineName}</p> */}

                {/* <p className="video-placeholder__alert">Tasting Video Coming Soon!</p> */}
                {/* <p className="video-placeholder__alert">{t('MY_WINES_NO_VIDEO')}</p> */}
                <img src={bottleImage} alt="" />
              </div>
            ) : (
              <>
                {
                  videoIsPlaying && <div className="flex-center" style={{ height: '100%' }}><Spinner /></div>
                }
                <div className="player-wrapper">
                  <ReactPlayer
                    width={'100%'}
                    style={{ visibility: `${videoIsPlaying ? 'hidden' : 'visible'}`, zIndex: 5 }}
                    height={'100%'}
                    disabled={videoIsPlaying}
                    light={true}
                    onClick={handleVideoClicked}
                    // url={video}
                    url={videoLink}
                    onReady={handlePlayVideo}
                    playIcon={<img src={play} alt="play" />}
                  />

                  <div className="player-spinner"><Spinner /></div>
                </div>
              </>
            )
        }
      </div>

      <div className="wine-details__info short_info">
        <div className={grapeRecordClasses}>{grape}</div>

        <ul className="tastes-list">{tastesListItems}</ul>

        <p className="wine-details__description">
          <ShortDescription
            lines={6}
            expandable={true}
          ><p className="no-margin">{description}</p>
          </ShortDescription>
        </p>
      </div>
    </div>
  )
}


AccountWineRow.propTypes = {
  wine: PropTypes.shape({
    name: PropTypes.string,
    vintage: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ]),
    config: PropTypes.shape({
      alertIsVisible: PropTypes.bool,
      detailsExpanded: PropTypes.bool,
      alertContent: PropTypes.string
    }),
    id: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ]),
    image: PropTypes.string,
    producer: PropTypes.string,
    sortOfGrape: PropTypes.string,
    grape: PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string
    }),
    isFavourite: PropTypes.bool,
    inventory: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ]),
    amountBottlesDrunk: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ]),
  }),
  timeOut: PropTypes.shape({
    current: PropTypes.number
  })
}

AccountWineRow.defaultProps = {
  wine: {}, timeOut: { current: null }
}

export default memo(AccountWineRow);
