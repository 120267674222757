import { useEffect, useState } from "react";

const mediaQueryBreakpoints = {
  'SM': '(max-width: 768px)',
  'XS': '(max-width: 520px)',
  'MD': '(max-width: 1024px)',
  'LG': '(max-width: 1440px)',
}

const useMediaQuery = () => {
  const [state, changeState] = useState({
    isMobile: window.matchMedia(mediaQueryBreakpoints.XS).matches
    ,isTablet: window.matchMedia(mediaQueryBreakpoints.SM).matches
    ,isFullsizeScreen: window.matchMedia(mediaQueryBreakpoints.LG).matches
  });


  const handleResizeScreen = () => {
    const isMobile = window.matchMedia(mediaQueryBreakpoints.XS).matches
    const isTablet = window.matchMedia(mediaQueryBreakpoints.SM).matches
    const isFullsizeScreen = window.matchMedia(mediaQueryBreakpoints.LG).matches

    changeState({ isMobile, isFullsizeScreen, isTablet })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResizeScreen, false);

    return () => window.removeEventListener('resize', handleResizeScreen);
  }, []);

  return { ...state }
}


export default useMediaQuery;
