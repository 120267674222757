import React from 'react';
import PropTypes from 'prop-types';

import { ShortDescription } from '../';


const GrapeCard = ({ name, image, description, tastes, category }) => {

  const grapeColor = category.toLowerCase();

  const tastesList = tastes.filter(
    taste => !!taste
  ).map(
    taste => <li key={taste} className="grape-taste">{taste}</li>
  );

  return (
    <div className={`card grape_card border-card_${grapeColor}`}>
      <div className="card__header">
        <div className="header-main-info">
          <div className={`grape-name grape_${grapeColor}`}>
            {name}
          </div>

          <ul className="grape-tastes-list">{tastesList}</ul>
        </div>
        {
          image &&
          <img src={image} alt={name} className="grape-image" />
        }
      </div>

      <div className="card__content">
        <p className="grape-description">
          <ShortDescription expandable={true} lines={4}><p>{description}</p></ShortDescription>
        </p>
      </div>
    </div>
  )
}


GrapeCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  tastes: PropTypes.arrayOf(PropTypes.string)
}


export default GrapeCard;
