import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.scss';


const AdminContentRow = ({ 
    id, name, category, pathname
}) => {

    return (
        <Link to={ pathname } className="admin-item-row">
            <span className="item-id">{id}</span>
            <span className="item-name">{name}</span>
            <span className="item-category">{category}</span>
        </Link>
    )
}


AdminContentRow.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.string,
    pathname: PropTypes.string
}

AdminContentRow.defaultProps = {
}

export default AdminContentRow;
