import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthContext, CheckoutContext } from '../../context';
import { CountryContext } from '../../context/country';
import { OrderSidebar, Alert } from '..';
import { useHelpers, useRequests } from '../../hooks';
import Contact from '../../classes/Contact';

import paymentLogo from '../../assets/images/paypal-logo.jpg'; 
import alertIcon from '../../assets/icons/warning.svg';

import './style.scss';


const OrderConfirmStep = ({ onEditData, shippingData }) => {
  const { t, i18n: { language: lng } } = useTranslation();
  const { post } = useRequests();
  const { capitalize } = useHelpers();
  const { cart: { order: { case: orderCase } } } = useContext(AuthContext);
  const { shippingCountry } = useContext(CheckoutContext);
  const { currentCountry, countriesTranslations } = useContext(CountryContext);


  const {
    payer: {
      name: { given_name: firstName, surname: lastName },
      email_address: email,
      phone: { phone_number: { national_number: phone } }
    },
    purchase_units
  } = shippingData;

  const {
    shipping: {
      address: {
        address_line_1: address,
        address_line_2: addressSecondLine,
        admin_area_2: city,
        admin_area_1: state,
        postal_code: zip,
        country_code: country
      },
      name: { full_name: fullName }
    }
  } = purchase_units[0];

  const zipcodes = orderCase?.wines?.find(wineData => wineData?.warehouse?.zipcodes)?.warehouse?.zipcodes;

  const paypalShippingCountry = Contact.getCountryName(country);
  const shippingAddress = addressSecondLine ? address + ', ' + addressSecondLine : address

  const verifyed = currentCountry === shippingCountry;
  const outOfShippingRange = zipcodes && !zipcodes.split(',')?.find(zipCode => zipCode?.trim() === '' + zip)

  const confirmReport = async () => {
    await post({
      url: '/api/orders/confirm',
      body: {
        contacts: { address: shippingAddress, city, state, zip, country, email, phone },
        description: purchase_units[0]?.description
      }
    });
  }

  //eslint-disable-next-line
  useEffect(() => { confirmReport() }, []);

  const warehouse = orderCase?.getWareHouseData();

  return (
    <>
      <h2>{t('CHECKOUT_ORDER_CONFIRMATION_TITLE')}</h2>
      <p>{t('CHECKOUT_ORDER_CONFIRMATION_SUBTITLE')}</p>
      {
        warehouse?.checkoutMessage &&
        <p className="no-margin">{warehouse.checkoutMessage}</p>
      }

      <button
        className="button-link button_cancel-payment"
        onClick={() => onEditData()}
      >{capitalize(t('CANCEL'))}</button>

      <div className="order-data">
        <div className="order-data__block">
          <h3 className="contact-title">{t('SHIPPING_ADDRESS')}</h3>

          {
            firstName?.length && lastName?.length ?
              <p className="contact-name">{fullName}</p>
              :
              <p className="contact-name">{t('NAME')} {t('NOT_SPECIFIED')}</p>
          }

          {
            address ?
              <p className="contact-address">{shippingAddress}</p>
              :
              <p className="contact-address">{t('ADDRESS')} {t('NOT_SPECIFIED')}</p>
          }

          {
            state && zip && city ?
              <p className="contact-state">{city}, {state} {zip}</p>
              :
              <p className="contact-state">{t('STATE')} {t('NOT_SPECIFIED')}</p>
          }

          {
            country ?
              <p className="contact-country">{country}</p>
              :
              <p className="contact-country">{t('COUNTRY')} {t('NOT_SPECIFIED')}</p>
          }
        </div>

        <div className="order-data__block">
          <h3 className="contact-title">{t('PAYMENT_METHOD')}</h3>

          <img src={paymentLogo} alt="paypal" />
        </div>

        {
          outOfShippingRange &&
          <Alert
            className="error order-shipping-alert"
            icon={ alertIcon }
            title={ warehouse?.errorMessage }
          >{
              zipcodes &&
              <p>Zipcodes we ship to right now: { zipcodes.split(',').join(', ') }</p>
          }</Alert>
        }
        {
          !zipcodes && !verifyed &&
          <Alert
            className="error order-shipping-alert"
            icon={alertIcon}
            title={
              t('CHECKOUT_ORDER_CONFIRMATION_ALERT_TITLE', {
                country: countriesTranslations[currentCountry] ?
                  countriesTranslations[currentCountry][lng] : currentCountry
              })
            }
          >
            <p><button style={{ display: 'inline' }}
              className="button-link"
              onClick={() => onEditData(paypalShippingCountry)}
            >{t('CHECKOUT_ORDER_CONFIRMATION_ALERT_LINK', {
              country: countriesTranslations[paypalShippingCountry] ?
                countriesTranslations[paypalShippingCountry][lng] : paypalShippingCountry
            })
              }</button></p>
          </Alert>
        }

        <div className="order-data__block">
          <h3 className="contact-title">{t('CONTACT_INFORMATION')}</h3>

          {
            email ?
              <p className="contact-email">{email}</p>
              :
              <p className="contact-email">{t('EMAIL')} {t('NOT_SPECIFIED')}</p>
          }

          {
            phone ?
              <p className="contact-phone">{Contact.getCountryDialCode(country) + phone}</p>
              :
              <p className="contact-phone">{t('PHONE')} {t('NOT_SPECIFIED')}</p>
          }
        </div>

        <div className="show-xs">
          <OrderSidebar />
        </div>
      </div>
    </>
  )
}


OrderConfirmStep.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  city: PropTypes.string,
  onEditData: PropTypes.func
}

OrderConfirmStep.defaultProps = {
  firstName: '',
  lastName: '',
  address: '',
  state: '',
  country: '',
  zip: '',
  email: '',
  phone: '',
  city: '',
  onEditData: () => { }
}

export default OrderConfirmStep;
