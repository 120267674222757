import { createContext } from 'react';

const CheckoutContext = createContext({
  order: undefined, 
  shipping: undefined, 
  billing: undefined,
  orderCapture: undefined, 
  shippingCountry: '',
  zip: undefined,
  warehouse: undefined,
  retailer: undefined,
  square: {},
  paymentMethod: undefined,
  paymentStep: '',
  isGift: false,
  giftMessage: '',
  removeOrderCaptureAction: () => {},
  setPaymentMethod: () => {},
  changePaymentStep: () => {},
  changeShippingZipcode: () => {},
  setOrderCaptureAction: () => {},
  setOrderWarehouse: () => {},
  changeRetailer: () => {},
  setOrder: () => {},
  changeShippingCountry: () => {},
  changeShippingDetails: () => {},
  changeBillingDetails: () => {},
  finishCheckout: () => {},
  changeIsGiftState: () => {},
  changeGiftMessage: () => {}
});


export default CheckoutContext;
