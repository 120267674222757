import React from 'react';
import PropTypes from 'prop-types';
import { useHelpers } from '../../hooks';
import IwsPriceDetails from './IwsPriceDetails';
import SerendipityPriceDetails from './SerendipityPriceDetails';


const WarehousePricingInfo = ({
  wines, warehouse, exchangeRate, curatorFee, paymentProcessor, margin, caseMarkup, serviceFee,
  shippingCostValue, priceFee, totalPrices, caseName, warehouseDeliveryFee
}) => {
  const { getCaseWinesAsListItemsWithTotalCost, getPaypalPriceDetails } = useHelpers();
  const { eur: priceEur, usd: priceUsd } = priceFee.prices;
  const withEur = wines[0].warehouse.name?.toLowerCase() === 'iws';


  const { winesList } = getCaseWinesAsListItemsWithTotalCost(wines, withEur);
  const { totalTax, total } = getPaypalPriceDetails({ casePrice: priceUsd })

  // {
  //   "category": "serendipity",
  //   "subtotal": 494.125,
  //   "total": 509.3893375,
  //   "creditCardProcessingFee": 15.2643375,
  //   "creditCardFeeDescription": "2.99% + $0.49",
  //   "caseMarkup": 69.5,
  //   "serviceFee": 52.125
  // }


  if (!wines?.length) return (
    <p className="empty-pricing-list">No wines selected</p>
  );


  return (
    <div className="case-pricing-details">
      {/* <h4 className="caption">Case Pricing Details for {warehouse}</h4> */}
      <h4 className="caption">Pricing Details{caseName.length > 0 && <> for {caseName}</>}</h4>

      <ul className="calculated-pricing__wines">
        {winesList}

        <li className="sub-total">
          <span>Total Wines Cost</span>
          <span>{
            withEur && <>€{+priceFee.totalWinesCost?.toFixed(2) || 0} / </>}
            ${(+priceFee.totalWinesCostUsd)?.toFixed(2) || 0
            }</span>
        </li>
        {
          warehouse === 'IWS' &&
          <IwsPriceDetails
            margin={margin}
            curatorFee={curatorFee}
            exchangeRate={exchangeRate}
            paymentProcessor={paymentProcessor}
            shippingCostValue={shippingCostValue}
            totalWinesCost={priceFee.totalWinesCost}
            totalWinesCostUsd={priceFee.totalWinesCostUsd}
          />
        }
        {
          // Total Wines Cost 347.50
          // case markup (20%) 69.50
          // service fee (15%) 52.13
          // Delivery fee 25
          // SUBTOTAL    494.13
          // Credit card fee of 2.99% on total price + $0.49 = 15.26
          // TOTAL BEFORE ROUNDING = 509.39
          // Rounding number: .61
          // CASE DISPLAY PRICE = 510
          warehouse === 'Serendipity Wines' &&
          <SerendipityPriceDetails
            priceFee={priceFee}
            warehouseDeliveryFee={warehouseDeliveryFee}
            caseMarkup={caseMarkup}
            serviceFee={serviceFee}
            totalPrices={totalPrices}
          />
        }
        <li className="section-line" />

        <li>
          <span>Total Case Price</span>
          <span>{withEur && <>€{priceEur?.toFixed(2)} / </>}${priceUsd?.toFixed(2)}</span>
        </li>
        {
          warehouse === 'Serendipity Wines' &&
          <>
            <li>
              <span>Texas Sales Tax 8.25%</span>
              <span>${totalTax}</span>
            </li>

            <li className="section-line" />

            <li className="total">
              <span>Final Sale Price</span>
              <span>${(totalTax + priceUsd)?.toFixed(2)}</span>
            </li>
          </>
        }
      </ul>
    </div>
  )
}

WarehousePricingInfo.propTypes = {
  warehouse: PropTypes.string,
  exhangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  curatorFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentProcessor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caseMarkup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  serviceFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceFee: PropTypes.shape({
    caseMarkupValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    serviceFeeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    creditCardProcessingFee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  caseName: PropTypes.string
}

WarehousePricingInfo.defaultProps = {
  shippingCostValue: 0,
  caseName: ''
}

export default WarehousePricingInfo;