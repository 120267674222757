import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { WineBottlesImages } from '..'


const CaseImageWithBottles = ({ caseId, backgroundImage, bottleImages, containerClass, useSmallBottleImages }) => {
  const mainClassNames = ['case-image-with-bottles']
  if (containerClass) mainClassNames.push(containerClass)
  const bottlesClass = bottleImages?.length > 6 ? 'chess-pattern' : undefined


  return (
    <div className={mainClassNames.join(' ')}>
      <img className="background-image" src={ backgroundImage } alt="" />

      <WineBottlesImages id={caseId} containerClass={bottlesClass} images={bottleImages} useSmallImage={useSmallBottleImages} />
    </div>
  )
}


CaseImageWithBottles.propTypes = {
  bottleImages: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      src: PropTypes.string
    })
  ])),
  backgroundImage: PropTypes.string,
  containerClass: PropTypes.string
}

CaseImageWithBottles.defaultProps = {
  backgroundImage: `https://vineify-dev-files.s3.amazonaws.com/1gpacfocr_full_size_cases_default_card_image.jpg`
}

export default memo(CaseImageWithBottles)
