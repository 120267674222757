import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { useHelpers } from '../../hooks';



const CasePosition = ({ position }) => {
  const { t } = useTranslation();
  const { getWineName } = useHelpers();
  const { pathname } = useLocation()
  const wineName = getWineName(position);
  const { name, producer, vintage, quantity, category } = position;
  const [isVisible, setIsVisible] = useState(false);

  const imageSrc = useMemo(() => position?.image, [position?.image])

  const restricted = pathname.includes('account');

  const handleChangePopoverState = state => e => {
    setIsVisible(state)
  }

  const pathToWinePage = pathname.split('/')?.[1];

  const imageContent = restricted ? (
    <span className="pos-image">
      <img src={imageSrc} alt={position.name} />
    </span>
  ) : (
    <Popover
      isOpen={isVisible}
      padding={0}
      positions={['right']}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            align="end"
            nudgedLeft={100}
            arrowColor={'white'}
            arrowSize={12}
            arrowClassName="popover-arrow"
          >
            <div className="popover-container">
                      <div className="image-container">
                        <img className="popover-image" src={imageSrc} alt="" />
                      </div>
                    </div>
            {/* <div className="popover-image-container"><img src={imageSrc} alt={position.name} /></div> */}
          </ArrowContainer>
        )
      }
    ><span
      className="pos-image popover"
      onMouseOver={handleChangePopoverState(true)}
      onMouseLeave={handleChangePopoverState(false)}
    ><img src={imageSrc} alt={position.name} />
      </span></Popover>
  )

  const wineNameContent = restricted ? (
    <p className="pos-name">{wineName} ({t(category.toUpperCase())})</p>
  ) : (
    <Link to={`/${pathToWinePage}/wines/${ position.id }`} className="pos-name">{wineName} ({t(category.toUpperCase())})</Link>
  )


  return (
    <li className="case-position">
      {imageContent}
      <span className="pos-info">
        {/* <p className="pos-name">{vintage} {producer} {name} ({t(category.toUpperCase())})</p> */}
        { wineNameContent }
        <p className="pos-quantity">Qty: {quantity}</p>
      </span>
    </li>
  )

}

export default memo(CasePosition)