import React from 'react';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({ onConfirm, onCancel, text, confirmBtnColor, title, className, confirmButtonLabel, cancelButtonLabel, hideButtons }) => {
  const confirmBtnClasses = `button${confirmBtnColor === 'red' ? ' button_red' : ''} button_xs`;
  const containerClassnames = ['confirmation-dialog', 'shadow']

  if (className) containerClassnames.push(className);


  return (
    <div className={containerClassnames.join(' ')}>
      {
        title &&
        <h4 className="confirmation-dialog__title">{title}</h4>
      }
      <p className="confirmation-dialog__text">{text}</p>

      {
        !hideButtons &&
        <div className="confirmation-dialog__buttons">
          <button
            className="button button_outline button_xs"
            onClick={onCancel}
          >{cancelButtonLabel}</button>

          <button
            className={confirmBtnClasses}
            onClick={onConfirm}
          >{confirmButtonLabel}</button>
        </div>
      }

    </div>
  )
}


ConfirmationDialog.propTypes = {
  text: PropTypes.string,
  confirmBtnClasses: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  hideButtons: PropTypes.bool,
}

ConfirmationDialog.defaultProps = {
  onConfirm: () => { },
  onCancel: () => { },
  hideButtons: false,
  confirmBtnColor: 'red',
  confirmButtonLabel: 'confirm',
  cancelButtonLabel: 'cancel',
}

export default ConfirmationDialog
