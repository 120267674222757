import { useSelector, useDispatch } from "react-redux";
import { curatorsActions as actions } from './curator.slice'
import { requestCuratorPreviewData, requestCuratorCasesPreviewList, requestCuratorsPreviewsList, requestCuratorOrders, requestCuratorRecommendationsHistory, requestSendRecommendations } from './curator.actions'


const useCurators = () => {
  const dispatch = useDispatch()
  const {
    error,
    processingRequest,
    processingRequests,
    list,
    currentCuratorData,
    dataIsFetched
  } = useSelector(state => state.curators)
  const curatorsState = {
    error,
    processingRequest,
    processingRequests,
    list,
    currentCuratorData,
    dataIsFetched
  }
  const curatorsActions = {}

  curatorsActions.requestCuratorPreviewData = curatorId => dispatch(requestCuratorPreviewData(curatorId))

  curatorsActions.requestCuratorCasesPreviewList = curatorId => dispatch(requestCuratorCasesPreviewList(curatorId))

  curatorsActions.requestSendRecommendations = data => dispatch(requestSendRecommendations(data))

  curatorsActions.requestCuratorRecommendationsHistory = ({curatorId, caseId}) => 
    dispatch(requestCuratorRecommendationsHistory({curatorId, caseId}))

  curatorsActions.requestCuratorsPreviewsList = () => dispatch(requestCuratorsPreviewsList())

  curatorsActions.resetCuratorStore = () => dispatch(actions.clearCuratorStore())

  curatorsActions.requestCuratorOrders = () => dispatch(requestCuratorOrders())

  curatorsActions.setCurrentCuratorData = (curatorData) => dispatch(actions.setCurrentCuratorData(curatorData))


  return { curatorsState, curatorsActions }
}

export default useCurators;