import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from '..';

import searchIcon from '../../assets/icons/search.svg';

import './style.scss';
import { useHelpers } from '../../hooks';


const SearchBar = ({
  onChange, onClick, placeHolder, initialValue, label, name, contanerClassName, inputClassName, buttonClassName,
  searchField
}) => {
  const { searchFields, selectedField, onChangeSearchField } = searchField
  const { capitalize } = useHelpers();
  const [searchString, changeSearchString] = useState(initialValue);
  const searchFieldsoptions = searchFields?.map(field => ({ value: field, label: capitalize(field) }))

  const handleChangeSearchBarValue = e => {
    e.preventDefault();
    const { value } = e.target;

    changeSearchString(value);

    onChange(value);
  }


  const handleChangeSearchField = ({ value: field }) => {
    onChangeSearchField(field)
  }

  const handleSearchOnClick = e => {
    const provideSearchValue = e.type === 'click' || e.code === 'Enter';

    provideSearchValue && onClick(searchString);
  }

  const handleClearSearchValue = e => {
    e.preventDefault();

    changeSearchString('');
    onChange('')
    onClick()
  }

  const searchInput = searchFields?.length > 0
    ? (
      <div className="input-group">
        <Select
          onChange={handleChangeSearchField}
          options={searchFieldsoptions}
          value={searchFieldsoptions?.find(({ value }) => selectedField === value)}
        />

        <input
          type="text"
          className={inputClassName}
          name={name}
          placeholder={placeHolder}
          value={searchString}
          onChange={handleChangeSearchBarValue}
          onKeyPress={handleSearchOnClick}
        />
      </div>
    ) : (
      <input
        type="text"
        className={inputClassName}
        name={name}
        placeholder={placeHolder}
        value={searchString}
        onChange={handleChangeSearchBarValue}
        onKeyPress={handleSearchOnClick}
      />
    )


  return (
    <div className="search-bar-wrapper">

      <div className={`form-control horizontal search-bar ${contanerClassName}`}>
        {
          label &&
          <label htmlFor={name}>{label}</label>
        }

        {searchInput}

        <button
          className={`search-button ${buttonClassName}`}
          onClick={handleSearchOnClick}
        ><img src={searchIcon} alt="" />
        </button>
      </div>

      <button
        className="button button_outline"
        onClick={handleClearSearchValue}
      >clear</button>
    </div>
  )
}


SearchBar.propTypes = {
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  contanerClassname: PropTypes.string,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  searchField: PropTypes.shape({
    searchFields: PropTypes.array,
    selectedField: PropTypes.string,
    onChangeSearchField: PropTypes.func
  })
}

SearchBar.defaultProps = {
  onChange: () => { },
  onClick: () => { },
  placeHolder: '',
  initialValue: '',
  name: 'search-bar',
  contanerClassName: '',
  inputClassName: '',
  buttonClassName: '',
  searchField: {
    searchFields: undefined,
    selectedField: undefined,
    onChangeSearchField: undefined
  }
}

export default memo(SearchBar)
