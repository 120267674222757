import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import hiddenIcon from '../../assets/icons/eye.svg';
import visibleIcon from '../../assets/icons/eye-off.svg';


const PasswordInput = ({ value, hidden, onChange, name, placeholder, onSubmit }) => {
  const [state, changeState] = useState({hidden, value});

  // const handleSubmitIfAllowed = e => {
  //   if (['Enter', 'NumpadEnter'].includes(e.code)) {
  //     onSubmit && onSubmit()
  //     e.preventDefault();
  //   }
  // }

  const handleChangeValue = e => {
    e.preventDefault();

    const { value } = e.currentTarget;

    changeState(prev => ({ ...prev, value }))
    onChange(value)
  }

  const handleTogglePasswordVisibility = e => {
    e.preventDefault();

    changeState(prev => ({ ...prev, hidden: !prev.hidden }));
  }

  const inputValue = state.value;
  const icon = state.hidden ? hiddenIcon : visibleIcon;


  return (
    <div className="input-group input-group_password">
      <input
        name={ name } placeholder={ placeholder }
        type={ state.hidden ? "password" : "text" }
        value={inputValue}
        onChange={handleChangeValue}
        // onKeyPress={ handleSubmitIfAllowed }
      />

      <button 
        className="button-link" type="button"
        onClick={ handleTogglePasswordVisibility }
        // onKeyPress={ handleSubmitIfAllowed }
      ><img src={ icon } alt="" /></button>
    </div>
  )
}

PasswordInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  hidden: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

PasswordInput.defaultProps ={
  hidden: true,
  value: '',
  placeholder: 'your password',
  name: 'password',
}

export default memo(PasswordInput)