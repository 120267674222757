import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Icon from '../common/Icon'
import './style.scss'


const RecommendationEmailPreview = ({ name, lastCase, recommendText, myWinesLink, nextCase, StandardCaseEmail, CustomCaseEmail, link, onClose }) => {
  const handleCloseDialog = e => {
    e.preventDefault()

    onClose()
  }

  const defaultImage = ``
  // const defaultImage = `https://vineify-dev-files.s3.amazonaws.com/1gsmp4845_Test-3-Customers-Case.jpg`


  return (<>
    <div className="email-preview" style={{ padding: '15px' }}>
      <header styie="padding:15px">

        <img src="http://cdn.mcauto-images-production.sendgrid.net/501be10801dfadfb/ff076ace-1a97-427c-9a81-ce00414768f6/196x50.png" alt="Vineify logo" />
      </header>

      <div class="content">
        <div class="header-content">
          <a href={nextCase.link} class="header-content__image visible-xs">
            <img src={nextCase.image || defaultImage} />
          </a>

          <div class="header-content__text">
            <h2>Recommended Just For You</h2>

            <hr />

            <p>Get this case on its way to your doorstep in just  a few clicks!</p>
          </div>

          <a href={nextCase.link} class="header-content__image hidden-xs">
            <img src={nextCase.image || defaultImage} />
          </a>
        </div>

        <div dangerouslySetInnerHTML={{
          __html: StandardCaseEmail.text
        }} />

        <footer>
          <p style={{ margin: 0, color: '#787878', marginBottom: '8px', textAlign: 'center' }}>© Vineify Inc.</p>
          <p style={{ margin: 0, color: '#787878', marginBottom: '8px', textAlign: 'center' }}>If you have any questions please contact us at <Link style={{ color: '#224C31' }} to="mailto:support@vineify.com" target="_blank">support@vineify.com</Link>
          </p>
        </footer>
      </div>
    </div>

    <button
      className="button-link close-modal-btn"
      onClick={handleCloseDialog}
    ><Icon icon="close" color="vineify-green" size={24} /></button>
  </>)
}


RecommendationEmailPreview.propTypes = {
  CustomCaseEmail: PropTypes.shape({
    text: PropTypes.string
  })
  , name: PropTypes.string
  , lastCase: PropTypes.string
  , recommendText: PropTypes.string
  , myWinesLink: PropTypes.string
  , nextCase: PropTypes.shape({
    name: PropTypes.string
    , link: PropTypes.string
    , image: PropTypes.string
  })
  , link: PropTypes.string
  , lastCase: PropTypes.shape({
    name: PropTypes.string
    , link: PropTypes.string
  })
}

RecommendationEmailPreview.defaultProps = {

}

export default RecommendationEmailPreview


