import tasting1 from '../assets/images/tasting1.png';
import tasting2 from '../assets/images/tasting2.png';
import tasting3 from '../assets/images/tasting3.png';
import arneis from '../assets/images/arneis.png';
import barbera from '../assets/images/barbera.png';
import chardonnay from '../assets/images/chardonnay.png';
import dolcetto from '../assets/images/dolcetto.png';
import nebbiolo from '../assets/images/nebbiolo.png';
import sauvignonBlanc from '../assets/images/sauvignon_blanc.png';
import timorasso from '../assets/images/timorasso.png';
import mainSlideOne from '../assets/images/main-slide-1.jpg';
import mainSlideTwo from '../assets/images/main-slide-2.jpg';
import mainSlideThree from '../assets/images/main-slide-3.jpg';
import mainSlideFour from '../assets/images/main-slide-4.jpg';
import mainSlideFive from '../assets/images/main-slide-5.jpg';
import mainSlideSix from '../assets/images/main-slide-6.jpg';


export const contacts = {
    phone: '1 617 255 22 55',
    city: 'Austin',
    state: 'TX',
    country: 'USA',
    zip: 78704,
    address: '123 Main Street',
    company: '',
    contactType: 'universal',
    isOneTime: true,
}


export const faq = [
    {
        title: 'Why does Vineify only feature cases (12 bottles) of wine? Can I get another quantity?',
        text: `Because that's the best way to get wine! Nothing beats having a case delivered to your doorstep (or workplace). Vineify works to connect you directly to wine producers that you love. These are typically family owned wineries and it works best for them to ship a case directly to you. This approach also works better for shipping. We'll explore offering 1/2 cases (6 bottles) but now we only offer cases.`
    },
    {
        title: 'How many cases can I order at a time?  How frequently can I order?',
        text: 'Right now, just one case at a time.  Also, we recommend one case per month per individual as this is in compliance in most jurisdictions.  We are working on automating this more and providing more information on this topic.'
    },
     {
        title: 'Can retailers or distributors use Vineify?',
        text: 'No, Vineify is designed to work exclusively in connecting consumers to their new favorites producers around the world.  Someone who works at a retailer or distributor may use Vineify as a consumer but never to order wines for their operations. '
    },
    {
        title: 'How does Vineify come up with case pricing?',
        text: 'Our simplified all-in pricing includes everything: the wine, shipping, customs ... and great tasting notes and content! In putting together a case, we account for that and the transaction costs to give you a case that we think represents a great value... and includes wines you typically can not find easily.'
    },
    {
        title: 'Is Vineify a wine club?',
        text: 'We are not.  Wine clubs can be run by wineries or by other organizations.  They typically charge a monthly or quarterly fee and then send you wines that they think you will like.  You might have some control over the types of those wines.  At Vineify we work to first educate you about the wine regions of the world and the wines that are available in each, and then we connect you directly to the producers to enjoy and order their wines — on your terms.  That is, you can buy once a year, once a quarter, once a month or even more frequently based on the regulations where you live.'
    },
    {
        title: 'Where does Vineify ship?',
        text: `We are in the very early days of our company. We are still testing this out. Buyers on Vineify are expected to comply with local regulations.`
    },
    
    {
        title: 'How does shipping work for my order?',
        text: `Vineify uses IWS, an Italy-based specialist in wine shipments, to get your wine to you. IWS takes care of all of the paperwork, including VAT, customs duties, packaging. IWS arranges for UPS to get the wine to your doorstep. You can check the status of your case in your order history. You'll also receive updates from PayPal and UPS if you have accounts with them.`
    },

    {
        title: 'How long does shipping take?',
        text: `Cases leave Italy typically within 1-3 days and arrive at your doorstep 3-5 days later. You'll receive a receipt from support@vineify.com in your inbox. You can also expect to get updates from PayPal and UPS. And of course we'll keep you posted in your order history as well. We're exploring other options that will take longer and cost less.`
    },
    
    {
        title: 'What happens if there is a problem with my wine?',
        text: `Candidly, we're working through that. We are in the early days so we are reviewing coverage both with IWS (the shipper) and with our own insurance company. For now, email us at support@vineify.com.`
    },
    {
        title: 'What payment methods does Vineify accept?',
        text: 'We accept all major credit cards. We use PayPal for credit card processing. If you have a PayPal account you can use that.'
    },
    {
        title: 'Can I change an order? Can I cancel an order?',
        text: `Once its on its way to the airport we can't change it or cancel it. If you get to us before it leaves we will do the best we can to accommodate you.`
    },
    {
        title: 'Can I give you suggestions on how to improve Vineify?',
        text: `Yes! Please send us an email at hello@vineify.com. We can't wait to hear from you.`
    },
    {
        title: 'How can I contact Vineify?',
        text: 'We have a few emails you can use to reach us. If you want to say hi, hello@vineify.com. Need some more info, use info@vineify.com. Need help? Use support@vineify.com. Want to tell us about your winery? Sales@vineify.com. We look forward to hearing from you!'
    }
]


export const tastings = [
    {
        image: tasting1, 
        title: 'Exploring Langhe’s Up & Coming', 
        recorded: 'Oct. 17, 2020', 
        host: 'Stefano', 
        mediaLink: 'https://youtu.be/ND4xzg4qrjo?t=21',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, sodales varius imperdiet faucibus risus posuere nisl. Dictum in blandit velit est euismod. Sit varius aliquam eget orci integer egestas cras. Morbi et sit enim, amet a non urna, semper in. Neque vitae, urna, sed ultrices a sagittis sit est orci. Vitae convallis lobortis volutpat aliquam turpis aliquam. Penatibus risus quam orci magna turpis at morbi tincidunt id. Tristique quam varius risus, vitae.'
    },
    {
        image: tasting2, 
        title: 'Exploring Wine #1', 
        recorded: 'Oct. 17, 2020', 
        host: 'Stefano', 
        mediaLink: 'https://youtu.be/ND4xzg4qrjo?t=21',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, sodales varius imperdiet faucibus risus posuere nisl. Dictum in blandit velit est euismod. Sit varius aliquam eget orci integer egestas cras. Morbi et sit enim, amet a non urna, semper in. Neque vitae, urna, sed ultrices a sagittis sit est orci. Vitae convallis lobortis volutpat aliquam turpis aliquam. Penatibus risus quam orci magna turpis at morbi tincidunt id. Tristique quam varius risus, vitae.'
    },
    {
        image: tasting3, 
        title: 'Exploring Wine #2', 
        recorded: 'Oct. 17, 2020', 
        host: 'Stefano', 
        mediaLink: 'https://youtu.be/ND4xzg4qrjo?t=21',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, sodales varius imperdiet faucibus risus posuere nisl. Dictum in blandit velit est euismod. Sit varius aliquam eget orci integer egestas cras. Morbi et sit enim, amet a non urna, semper in. Neque vitae, urna, sed ultrices a sagittis sit est orci. Vitae convallis lobortis volutpat aliquam turpis aliquam. Penatibus risus quam orci magna turpis at morbi tincidunt id. Tristique quam varius risus, vitae.'
    },
    {
        image: tasting2, 
        title: 'Exploring Wine #3', 
        recorded: 'Oct. 17, 2020', 
        host: 'Stefano', 
        mediaLink: 'https://youtu.be/ND4xzg4qrjo?t=21',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, sodales varius imperdiet faucibus risus posuere nisl. Dictum in blandit velit est euismod. Sit varius aliquam eget orci integer egestas cras. Morbi et sit enim, amet a non urna, semper in. Neque vitae, urna, sed ultrices a sagittis sit est orci. Vitae convallis lobortis volutpat aliquam turpis aliquam. Penatibus risus quam orci magna turpis at morbi tincidunt id. Tristique quam varius risus, vitae.'
    },
]

// American Samoa, micronesia, mariana, marshall islands, palau and virgin islands international. You can shorten to "intl"

export const statesOfUSA = {
    "AL": "Alabama",
    "AK": "Alaska",
    // "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    // "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    // "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    // "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    // "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    // "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export const grapes = [
    {
        name: 'Arneis',
        region: 'Piedmont',
        image: arneis,
        category: 'White',
        tastes: ['FLORAL', 'FULL BODY', 'FRUITY'],
        description: `Arneis is a white grape typical of Piedmont and is cultivated mainly in the Roero area however it is also possible to find it in the Langhe next to the Nebbiolo vineyards.
        
        The wines are characterized by the floral note and delicate aromas, which contrast with a relatively full body and the other fruity notes, typically of pearl and apricot, rounded with a slight note of hazelnut.  Roero Arneis, produced on the left bank (North) of the Tanaro River, have earned the nickname Barolo Bianco. It’s a perfect white wine for an easy aperitif or for any time you wanna make a friendly "cheers"!`
    },
    {
        name: 'Timorasso',
        region: 'Piedmont',
        image: timorasso,
        category: 'White',
        tastes: ['FRESH + MINERAL', 'WILDFLOWERS + RIPE FRUITS', 'CREAMY'],
        description: `Timorasso is a niche wine.  But it is not a wine intended for a small audience of connoisseurs and specialists, rather it is a white grape variety that finds great acclaim among both consumers and critics.  It is considered "niche" because the territory suitable for its cultivation is very limited.  A companion of the peasants' table and the local nobility, its merits also conquered Leonardo da Vinci who wanted to donate "Timuràs" wine to Isabella of Aragon, Duchess of Milan in the 16th century, on the occasion of his wedding.  It is a fresh and mineral white wine, with an intense yellow color that conquers the nose with refined aromas of wildflowers, apricots and citrus fruits. In the mouth it is mobile and warm but at the same time creamy and body.  It is a white wine that can age by offering aromas of hydrocarbon, honey and ripe fruits.`
    },
    {
        name: 'Sauvignon Blanc',
        region: 'Piedmont',
        image: sauvignonBlanc,
        category: 'White',
        tastes: ['RIPE FRUIT', 'HAZELNUT', 'DRY + FULL BODIED'],
        description: `An international grape grown in many regions, over the years Sauvignon Blanc has spread discreetly among the hills of the Langhe as well.  High altitudes, different soil composition and the biodiversity have enabled Sauvignon Blanc to express itself uniquely here.  In the glass you will find an intense yellow color with greenish reflections, aromas of ripe fruit with hints of hazelnut and in the mouth the typical dry and full-bodied taste.`
    },
    {
        name: 'Chardonnay',
        region: 'Piedmont',
        image: chardonnay,
        category: 'White',
        tastes: ['INTENSE AROMAS', 'LIGHT', 'DRY + VELVETY'],
        description: `Chardonnay, the world's most popular white wine grape, is native to the Burgundy region in France, yet in the Langhe we can find some of the best Italian white wines produced from this grape.  It is a very dynamic white wine, which often leaves room for the flavors of the kitchen, complementing them quite nicely. First of all we can recommend Chardonnay as an aperitif, thanks to the usually not too high gradation and the lightness with which it marries with the food that is served during any time of the day.  It’s a white wine with a rich yellow color, intense aromas and a dry, velvety and fascinating flavor. A wine not to miss.`
    },
    {
        name: 'Nebbiolo',
        region: 'Piedmont',
        image: nebbiolo,
        category: 'Red',
        tastes: ['FINE + ELEGANT', 'STRUCTURED', 'DEEP AROMAS'],
        description: `The Nebbiolo grape variety is the quintessential Piedmontese native grape.  Its name could derive from nebbia (the Italian word for fog), according to some because the berries are covered with a white, powder-like bloom during harvest.   Others believe it's called this because the grape is harvested in late October, when the vineyards are wrapped in morning mists.
        
        Nebbiolo is certainly the most valuable and difficult red grape variety in Italy. The excellence of Nebbiolo's production is concentrated in the Langhe and more precisely in the Barolo and Barbaresco areas.  Outside these areas of production, Nebbiolo grapes no longer have that thickness, power and "nobility" that make Barolo's and Barberesco's unique in the world.
        
        Considered among the best wines in the world, they are very fine, elegant and structured, with a remarkable longevity.  The more or less intense color, the more or less deep aromas can change according to the type of wine produced.  The Langhe Nebbiolo will be more approchable while we will have its best as a Barbaresco and Barolo denominations.`
    },
    {
        name: 'Barbera',
        region: 'Piedmont',
        image: barbera,
        category: 'Red',
        tastes: ['FINE + ELEGANT', 'STRUCTURED', 'DEEP AROMAS'],
        description: `Barbera grapes are one of the most cultivated varieties in Italy and find its maximum recognition in the Langhe where it takes the name of Barbera d'Alba. Cultivated over the centuries, Barbera has always been a "meal" wine for its dry taste, full body and high acidity. It’s a red wine of a violet color with engaging aromas of cherries, strawberries, plum and, if aged, leather.  In recent years the Barbera d’Alba has obtained the esteem of the public because it offers, through appropriate winemaking processes, both excellent wines ready to drink, and wines of medium longevity and good structure that resist in time and confirm, after many years, the most original characteristics of a land and a wine with great prestige.`
    },
    {
        name: 'Dolcetto',
        region: 'Piedmont',
        image: dolcetto,
        category: 'Red',
        tastes: ['DRY', 'MEDIUM BODIED', 'LICORICE + CHERRY'],
        description: `Dolcetto is one of the best known Italian red wines, produced with grapes grown in Piedmont where the wine take the same name.  The Dolcetto probably owes its name to the high sweetness of ripe grapes, with an intense and complex scent with notes of licorice, almond, blackberries and cherries. Sometimes it also shows floral notes. In the mouth it’s dry, medium-bodied, quite tannic, soft and harmonious. A symbolic Italian daily wine.`
    },
    {
        name: 'Vermentino',
        region: 'Tuscany',
        category: 'White',
        tastes: ['FRUITY', 'HERBACEOUS'],
        description: `Vermentino is the typical Italian white grape with a Mediterranean soul as it grows along the Tuscan coasts. It’s a dry and slightly fruity and herbaceous white wine that goes perfectly with raw fish, spaghetti with clams, risotto with seafood, and grilled fish. Whenever you visit the region you’ll meet local people drinking Vermentino at any time during the day as it’s the local daily wine.`
    },
    {
        name: 'Viognier',
        region: 'Tuscany',
        category: 'White',
        tastes: ['HARMONIOUS', 'SOFT', 'FRUITY'],
        description: `Viognier is a white grape originally cultivated in France’s Rhône valley that has become very common in central and southern Italy, especially near the coasts where the climate is windier, over the past couple of decades. Once it was one of the "forgotten" grapes while today it’s highly appreciated all over the world for giving rise to aromatic and soft wines, with aromas of apricot, peach, white flowers, jasmine, apple blossom and violet.`
    },
    {
        name: 'Fiano',
        region: 'Tuscany',
        category: 'White',
        tastes: ['CITRUS', 'RICH', 'FRUITY'],
        description: `Before the birth of Christ, the Greeks brought the white “Fiano” grape to the coasts of central Italy and still today it’s considered among the best white vines of the Bella Italia. Today it is mainly cultivated in the province of Avellino, south of Naples, but it also spread in Tuscany and in a few northern regions. Fiano stands out for the finesse and richness of its fruity and floral aromas, with notes of pears and apples, peaches, medlar and citrus fruits, pine nuts and freshly cut grass. Fiano is often used for blends as it brings minerality and a very rich and persistent finish.`
    },
    {
        name: 'Sangiovese',
        region: 'Tuscany',
        category: 'Red',
        tastes: ['HARMONIOUS', 'SPICY', 'FRUITY'],
        description: `Cultivated for centuries in central Italy, Sangiovese is the prince among the red grape varieties of Tuscany.It gives rise to some of the most appreciated wines in the world such as Brunello di Montalcino, Chianti Classico, Vino Nobile di Montepulciano and Maremma Rosso. Sangiovese is a harmonious and fruity red wine, to be drunk young or long-aged, you may fall in love for its plum, violet and cherry aromas often intertwined with spicy hints of coffee bean and cocoa beans. The best combinations are with red meats and aged cheeses. We suggest drinking it with friends or while enjoying a nice meal.`
    },
    {
        name: 'Super Tuscan Blends',
        region: 'Tuscany',
        category: 'Red',
        tastes: ['RICH', 'FULL BODY', 'ELEGANT'],
        description: `These are rich, complex, elegant, soft, powerful, concentrated and full-bodied wines with a Tuscan soul and an international style. Generally born from a blend of Cabernet Sauvignon, Cabernet Franc and Merlot grapes, and sometimes Syrah, in most cases they are subjected to long aging in wood. This production process, combined with a generous and extraordinarily well-suited territory, is the secret of famous and prestigious bottles that have become an oenological reference point all over the world.`
    },
    {
        name: 'Syrah',
        region: 'Tuscany',
        category: 'Red',
        tastes: ['INTENSE AROMAS', 'SOFT + ELEGANT'],
        description: `Syrah is a red grape typically from the Rhône valley in France and more than half a century ago, it found a place in the best Italian areas. 100% Syrah wine shows an intense ruby ​​color with aromas of black pepper, tobacco, blueberry, and graphite. The sip is intense, concentrated and tasty yet exhibiting balance that will enable improvement over time. Very fragrant 3-4 years from harvest, soft and elegant if aged a few more years.`
    }
]

export const countries = [ 
    { "name": "Afghanistan", "dial_code": "+93", "code": "AF", "flag": "🇦🇫" }, 
    { "name": "Albania", "dial_code": "+355", "code": "AL", "flag": "🇦🇱" }, 
    { "name": "Algeria", "dial_code": "+213", "code": "DZ", "flag": "🇩🇿" }, 
    { "name": "AmericanSamoa", "dial_code": "+1684", "code": "AS", "flag": "🇦🇸" }, 
    { "name": "Andorra", "dial_code": "+376", "code": "AD", "flag": "🇦🇩" }, 
    { "name": "Angola", "dial_code": "+244", "code": "AO", "flag": "🇦🇴" }, 
    { "name": "Anguilla", "dial_code": "+1264", "code": "AI", "flag": "🇦🇮" }, 
    { "name": "Antarctica", "dial_code": "+672", "code": "AQ", "flag": "🇦🇶" }, 
    { "name": "Antigua and Barbuda", "dial_code": "+1268", "code": "AG", "flag": "🇦🇬" }, 
    { "name": "Argentina", "dial_code": "+54", "code": "AR", "flag": "🇦🇷" }, 
    { "name": "Armenia", "dial_code": "+374", "code": "AM", "flag": "🇦🇲" }, 
    { "name": "Aruba", "dial_code": "+297", "code": "AW", "flag": "🇦🇼" }, 
    { "name": "Australia", "dial_code": "+61", "code": "AU", "preferred": true, "flag": "🇦🇺" }, 
    { "name": "Austria", "dial_code": "+43", "code": "AT", "flag": "🇦🇹" }, 
    { "name": "Azerbaijan", "dial_code": "+994", "code": "AZ", "flag": "🇦🇿" }, 
    { "name": "Bahamas", "dial_code": "+1242", "code": "BS", "flag": "🇧🇸" }, 
    { "name": "Bahrain", "dial_code": "+973", "code": "BH", "flag": "🇧🇭" }, 
    { "name": "Bangladesh", "dial_code": "+880", "code": "BD", "flag": "🇧🇩" }, 
    { "name": "Barbados", "dial_code": "+1246", "code": "BB", "flag": "🇧🇧" }, 
    { "name": "Belarus", "dial_code": "+375", "code": "BY", "flag": "🇧🇾" }, 
    { "name": "Belgium", "dial_code": "+32", "code": "BE", "flag": "🇧🇪" }, 
    { "name": "Belize", "dial_code": "+501", "code": "BZ", "flag": "🇧🇿" }, 
    { "name": "Benin", "dial_code": "+229", "code": "BJ", "flag": "🇧🇯" }, 
    { "name": "Bermuda", "dial_code": "+1441", "code": "BM", "flag": "🇧🇲" }, 
    { "name": "Bhutan", "dial_code": "+975", "code": "BT", "flag": "🇧🇹" }, 
    { "name": "Bolivia, Plurinational State of", "dial_code": "+591", "code": "BO", "flag": "🇧🇴" }, 
    { "name": "Bosnia and Herzegovina", "dial_code": "+387", "code": "BA", "flag": "🇧🇦" }, 
    { "name": "Botswana", "dial_code": "+267", "code": "BW", "flag": "🇧🇼" }, 
    { "name": "Brazil", "dial_code": "+55", "code": "BR", "flag": "🇧🇷" }, 
    { "name": "British Indian Ocean Territory", "dial_code": "+246", "code": "IO", "flag": "🇮🇴" }, 
    { "name": "Brunei Darussalam", "dial_code": "+673", "code": "BN", "flag": "🇧🇳" }, 
    { "name": "Bulgaria", "dial_code": "+359", "code": "BG", "flag": "🇧🇬" }, 
    { "name": "Burkina Faso", "dial_code": "+226", "code": "BF", "flag": "🇧🇫" }, 
    { "name": "Burundi", "dial_code": "+257", "code": "BI", "flag": "🇧🇮" }, 
    { "name": "Cambodia", "dial_code": "+855", "code": "KH", "flag": "🇰🇭" }, 
    { "name": "Cameroon", "dial_code": "+237", "code": "CM", "flag": "🇨🇲" }, 
    { "name": "Canada", "dial_code": "+1", "code": "CA", "flag": "🇨🇦" }, 
    { "name": "Cape Verde", "dial_code": "+238", "code": "CV", "flag": "🇨🇻" }, 
    { "name": "Cayman Islands", "dial_code": "+345", "code": "KY", "flag": "🇰🇾" }, 
    { "name": "Central African Republic", "dial_code": "+236", "code": "CF", "flag": "🇨🇫" }, 
    { "name": "Chad", "dial_code": "+235", "code": "TD", "flag": "🇹🇩" }, 
    { "name": "Chile", "dial_code": "+56", "code": "CL", "flag": "🇨🇱" }, 
    { "name": "China", "dial_code": "+86", "code": "CN", "flag": "🇨🇳" }, 
    { "name": "Christmas Island", "dial_code": "+61", "code": "CX", "flag": "🇨🇽" }, 
    { "name": "Cocos (Keeling) Islands", "dial_code": "+61", "code": "CC", "flag": "🇨🇨" }, 
    { "name": "Colombia", "dial_code": "+57", "code": "CO", "flag": "🇨🇴" }, 
    { "name": "Comoros", "dial_code": "+269", "code": "KM", "flag": "🇰🇲" }, 
    { "name": "Congo", "dial_code": "+242", "code": "CG", "flag": "🇨🇬" }, 
    { "name": "Congo, The Democratic Republic of the", "dial_code": "+243", "code": "CD", "flag": "🇨🇩" }, 
    { "name": "Cook Islands", "dial_code": "+682", "code": "CK", "flag": "🇨🇰" }, 
    { "name": "Costa Rica", "dial_code": "+506", "code": "CR", "flag": "🇨🇷" }, 
    { "name": "Cote d'Ivoire", "dial_code": "+225", "code": "CI", "flag": "🇨🇮" }, 
    { "name": "Croatia", "dial_code": "+385", "code": "HR", "flag": "🇭🇷" }, 
    { "name": "Cuba", "dial_code": "+53", "code": "CU", "flag": "🇨🇺" }, 
    { "name": "Cyprus", "dial_code": "+537", "code": "CY", "flag": "🇨🇾" }, 
    { "name": "Czech Republic", "dial_code": "+420", "code": "CZ", "flag": "🇨🇿" }, 
    { "name": "Denmark", "dial_code": "+45", "code": "DK", "flag": "🇩🇰" }, 
    { "name": "Djibouti", "dial_code": "+253", "code": "DJ", "flag": "🇩🇯" }, 
    { "name": "Dominica", "dial_code": "+1767", "code": "DM", "flag": "🇩🇲" }, 
    { "name": "Dominican Republic", "dial_code": "+1849", "code": "DO", "flag": "🇩🇴" }, 
    { "name": "Ecuador", "dial_code": "+593", "code": "EC", "flag": "🇪🇨" }, 
    { "name": "Egypt", "dial_code": "+20", "code": "EG", "flag": "🇪🇬" }, 
    { "name": "El Salvador", "dial_code": "+503", "code": "SV", "flag": "🇸🇻" }, 
    { "name": "Equatorial Guinea", "dial_code": "+240", "code": "GQ", "flag": "🇬🇶" }, 
    { "name": "Eritrea", "dial_code": "+291", "code": "ER", "flag": "🇪🇷" }, 
    { "name": "Estonia", "dial_code": "+372", "code": "EE", "flag": "🇪🇪" }, 
    { "name": "Ethiopia", "dial_code": "+251", "code": "ET", "flag": "🇪🇹" }, 
    { "name": "Falkland Islands (Malvinas)", "dial_code": "+500", "code": "FK", "flag": "🇫🇰" }, 
    { "name": "Faroe Islands", "dial_code": "+298", "code": "FO", "flag": "🇫🇴" }, 
    { "name": "Fiji", "dial_code": "+679", "code": "FJ", "flag": "🇫🇯" }, 
    { "name": "Finland", "dial_code": "+358", "code": "FI", "flag": "🇫🇮" }, 
    { "name": "France", "dial_code": "+33", "code": "FR", "flag": "🇫🇷" }, 
    { "name": "French Guiana", "dial_code": "+594", "code": "GF", "flag": "🇬🇫" }, 
    { "name": "French Polynesia", "dial_code": "+689", "code": "PF", "flag": "🇵🇫" }, 
    { "name": "Gabon", "dial_code": "+241", "code": "GA", "flag": "🇬🇦" }, 
    { "name": "Gambia", "dial_code": "+220", "code": "GM", "flag": "🇬🇲" }, 
    { "name": "Georgia", "dial_code": "+995", "code": "GE", "flag": "🇬🇪" }, 
    { "name": "Germany", "dial_code": "+49", "code": "DE", "flag": "🇩🇪" }, 
    { "name": "Ghana", "dial_code": "+233", "code": "GH", "flag": "🇬🇭" }, 
    { "name": "Gibraltar", "dial_code": "+350", "code": "GI", "flag": "🇬🇮" }, 
    { "name": "Greece", "dial_code": "+30", "code": "GR", "flag": "🇬🇷" }, 
    { "name": "Greenland", "dial_code": "+299", "code": "GL", "flag": "🇬🇱" }, 
    { "name": "Grenada", "dial_code": "+1473", "code": "GD", "flag": "🇬🇩" }, 
    { "name": "Guadeloupe", "dial_code": "+590", "code": "GP", "flag": "🇬🇵" }, 
    { "name": "Guam", "dial_code": "+1671", "code": "GU", "flag": "🇬🇺" }, 
    { "name": "Guatemala", "dial_code": "+502", "code": "GT", "flag": "🇬🇹" }, 
    { "name": "Guernsey", "dial_code": "+44", "code": "GG", "flag": "🇬🇬" }, 
    { "name": "Guinea", "dial_code": "+224", "code": "GN", "flag": "🇬🇳" }, 
    { "name": "Guinea-Bissau", "dial_code": "+245", "code": "GW", "flag": "🇬🇼" }, 
    { "name": "Guyana", "dial_code": "+595", "code": "GY", "flag": "🇬🇾" }, 
    { "name": "Haiti", "dial_code": "+509", "code": "HT", "flag": "🇭🇹" }, 
    { "name": "Holy See (Vatican City State)", "dial_code": "+379", "code": "VA", "flag": "🇻🇦" }, 
    { "name": "Honduras", "dial_code": "+504", "code": "HN", "flag": "🇭🇳" }, 
    { "name": "Hong Kong", "dial_code": "+852", "code": "HK", "flag": "🇭🇰" }, 
    { "name": "Hungary", "dial_code": "+36", "code": "HU", "flag": "🇭🇺" }, 
    { "name": "Iceland", "dial_code": "+354", "code": "IS", "flag": "🇮🇸" }, 
    { "name": "India", "dial_code": "+91", "code": "IN", "preferred": true, "flag": "🇮🇳" }, 
    { "name": "Indonesia", "dial_code": "+62", "code": "ID", "flag": "🇮🇩" }, 
    { "name": "Iran, Islamic Republic of", "dial_code": "+98", "code": "IR", "flag": "🇮🇷" }, 
    { "name": "Iraq", "dial_code": "+964", "code": "IQ", "flag": "🇮🇶" }, 
    { "name": "Ireland", "dial_code": "+353", "code": "IE", "flag": "🇮🇪" }, 
    { "name": "Isle of Man", "dial_code": "+44", "code": "IM", "flag": "🇮🇲" }, 
    { "name": "Israel", "dial_code": "+972", "code": "IL", "flag": "🇮🇱" }, 
    { "name": "Italy", "dial_code": "+39", "code": "IT", "flag": "🇮🇹" }, 
    { "name": "Jamaica", "dial_code": "+1876", "code": "JM", "flag": "🇯🇲" }, 
    { "name": "Japan", "dial_code": "+81", "code": "JP", "flag": "🇯🇵" }, 
    { "name": "Jersey", "dial_code": "+44", "code": "JE", "flag": "🇯🇪" }, 
    { "name": "Jordan", "dial_code": "+962", "code": "JO", "flag": "🇯🇴" }, 
    { "name": "Kazakhstan", "dial_code": "+77", "code": "KZ", "flag": "🇰🇿" }, 
    { "name": "Kenya", "dial_code": "+254", "code": "KE", "flag": "🇰🇪" }, 
    { "name": "Kiribati", "dial_code": "+686", "code": "KI", "flag": "🇰🇮" }, 
    { "name": "Korea, Democratic People's Republic of", "dial_code": "+850", "code": "KP", "flag": "🇰🇵" }, 
    { "name": "Korea, Republic of", "dial_code": "+82", "code": "KR", "flag": "🇰🇷" }, 
    { "name": "Kuwait", "dial_code": "+965", "code": "KW", "flag": "🇰🇼" }, 
    { "name": "Kyrgyzstan", "dial_code": "+996", "code": "KG", "flag": "🇰🇬" }, 
    { "name": "Lao People's Democratic Republic", "dial_code": "+856", "code": "LA", "flag": "🇱🇦" }, 
    { "name": "Latvia", "dial_code": "+371", "code": "LV", "flag": "🇱🇻" }, 
    { "name": "Lebanon", "dial_code": "+961", "code": "LB", "flag": "🇱🇧" }, 
    { "name": "Lesotho", "dial_code": "+266", "code": "LS", "flag": "🇱🇸" }, 
    { "name": "Liberia", "dial_code": "+231", "code": "LR", "flag": "🇱🇷" }, 
    { "name": "Libyan Arab Jamahiriya", "dial_code": "+218", "code": "LY", "flag": "🇱🇾" }, 
    { "name": "Liechtenstein", "dial_code": "+423", "code": "LI", "flag": "🇱🇮" }, 
    { "name": "Lithuania", "dial_code": "+370", "code": "LT", "flag": "🇱🇹" }, 
    { "name": "Luxembourg", "dial_code": "+352", "code": "LU", "flag": "🇱🇺" }, 
    { "name": "Macao", "dial_code": "+853", "code": "MO", "flag": "🇲🇴" }, 
    { "name": "Macedonia, The Former Yugoslav Republic of", "dial_code": "+389", "code": "MK", "flag": "🇲🇰" }, 
    { "name": "Madagascar", "dial_code": "+261", "code": "MG", "flag": "🇲🇬" }, 
    { "name": "Malawi", "dial_code": "+265", "code": "MW", "flag": "🇲🇼" }, 
    { "name": "Malaysia", "dial_code": "+60", "code": "MY", "flag": "🇲🇾" }, 
    { "name": "Maldives", "dial_code": "+960", "code": "MV", "flag": "🇲🇻" }, 
    { "name": "Mali", "dial_code": "+223", "code": "ML", "flag": "🇲🇱" }, 
    { "name": "Malta", "dial_code": "+356", "code": "MT", "flag": "🇲🇹" }, 
    { "name": "Marshall Islands", "dial_code": "+692", "code": "MH", "flag": "🇲🇭" }, 
    { "name": "Martinique", "dial_code": "+596", "code": "MQ", "flag": "🇲🇶" }, 
    { "name": "Mauritania", "dial_code": "+222", "code": "MR", "flag": "🇲🇷" }, 
    { "name": "Mauritius", "dial_code": "+230", "code": "MU", "flag": "🇲🇺" }, 
    { "name": "Mayotte", "dial_code": "+262", "code": "YT", "flag": "🇾🇹" }, 
    { "name": "Mexico", "dial_code": "+52", "code": "MX", "flag": "🇲🇽" }, 
    { "name": "Micronesia, Federated States of", "dial_code": "+691", "code": "FM", "flag": "🇫🇲" }, 
    { "name": "Moldova, Republic of", "dial_code": "+373", "code": "MD", "flag": "🇲🇩" }, 
    { "name": "Monaco", "dial_code": "+377", "code": "MC", "flag": "🇲🇨" }, 
    { "name": "Mongolia", "dial_code": "+976", "code": "MN", "flag": "🇲🇳" }, 
    { "name": "Montenegro", "dial_code": "+382", "code": "ME", "flag": "🇲🇪" }, 
    { "name": "Montserrat", "dial_code": "+1664", "code": "MS", "flag": "🇲🇸" }, 
    { "name": "Morocco", "dial_code": "+212", "code": "MA", "flag": "🇲🇦" }, 
    { "name": "Mozambique", "dial_code": "+258", "code": "MZ", "flag": "🇲🇿" }, 
    { "name": "Myanmar", "dial_code": "+95", "code": "MM", "flag": "🇲🇲" }, 
    { "name": "Namibia", "dial_code": "+264", "code": "NA", "flag": "🇳🇦" }, 
    { "name": "Nauru", "dial_code": "+674", "code": "NR", "flag": "🇳🇷" }, 
    { "name": "Nepal", "dial_code": "+977", "code": "NP", "flag": "🇳🇵" }, 
    { "name": "Netherlands", "dial_code": "+31", "code": "NL", "flag": "🇳🇱" }, 
    { "name": "Netherlands Antilles", "dial_code": "+599", "code": "AN", "flag": "🇦🇳" }, 
    { "name": "New Caledonia", "dial_code": "+687", "code": "NC", "flag": "🇳🇨" }, 
    { "name": "New Zealand", "dial_code": "+64", "code": "NZ", "flag": "🇳🇿" }, 
    { "name": "Nicaragua", "dial_code": "+505", "code": "NI", "flag": "🇳🇮" }, 
    { "name": "Niger", "dial_code": "+227", "code": "NE", "flag": "🇳🇪" }, 
    { "name": "Nigeria", "dial_code": "+234", "code": "NG", "flag": "🇳🇬" }, 
    { "name": "Niue", "dial_code": "+683", "code": "NU", "flag": "🇳🇺" }, 
    { "name": "Norfolk Island", "dial_code": "+672", "code": "NF", "flag": "🇳🇫" }, 
    { "name": "Northern Mariana Islands", "dial_code": "+1670", "code": "MP", "flag": "🇲🇵" }, 
    { "name": "Norway", "dial_code": "+47", "code": "NO", "flag": "🇳🇴" }, 
    { "name": "Oman", "dial_code": "+968", "code": "OM", "flag": "🇴🇲" }, 
    { "name": "Pakistan", "dial_code": "+92", "code": "PK", "flag": "🇵🇰" }, 
    { "name": "Palau", "dial_code": "+680", "code": "PW", "flag": "🇵🇼" }, 
    { "name": "Palestinian Territory, Occupied", "dial_code": "+970", "code": "PS", "flag": "🇵🇸" }, 
    { "name": "Panama", "dial_code": "+507", "code": "PA", "flag": "🇵🇦" }, 
    { "name": "Papua New Guinea", "dial_code": "+675", "code": "PG", "flag": "🇵🇬" }, 
    { "name": "Paraguay", "dial_code": "+595", "code": "PY", "flag": "🇵🇾" }, 
    { "name": "Peru", "dial_code": "+51", "code": "PE", "flag": "🇵🇪" }, 
    { "name": "Philippines", "dial_code": "+63", "code": "PH", "flag": "🇵🇭" }, 
    { "name": "Pitcairn", "dial_code": "+872", "code": "PN", "flag": "🇵🇳" }, 
    { "name": "Poland", "dial_code": "+48", "code": "PL", "flag": "🇵🇱" }, 
    { "name": "Portugal", "dial_code": "+351", "code": "PT", "flag": "🇵🇹" }, 
    { "name": "Puerto Rico", "dial_code": "+1939", "code": "PR", "flag": "🇵🇷" }, 
    { "name": "Qatar", "dial_code": "+974", "code": "QA", "flag": "🇶🇦" }, 
    { "name": "Romania", "dial_code": "+40", "code": "RO", "flag": "🇷🇴" }, 
    { "name": "Russia", "dial_code": "+7", "code": "RU", "flag": "🇷🇺" }, 
    { "name": "Rwanda", "dial_code": "+250", "code": "RW", "flag": "🇷🇼" }, 
    { "name": "Réunion", "dial_code": "+262", "code": "RE", "flag": "🇷🇪" }, 
    { "name": "Saint Barthélemy", "dial_code": "+590", "code": "BL", "flag": "🇧🇱" }, 
    { "name": "Saint Helena, Ascension and Tristan Da Cunha", "dial_code": "+290", "code": "SH", "flag": "🇸🇭" }, 
    { "name": "Saint Kitts and Nevis", "dial_code": "+1869", "code": "KN", "flag": "🇰🇳" }, 
    { "name": "Saint Lucia", "dial_code": "+1758", "code": "LC", "flag": "🇱🇨" }, 
    { "name": "Saint Martin", "dial_code": "+590", "code": "MF", "flag": "🇲🇫" }, 
    { "name": "Saint Pierre and Miquelon", "dial_code": "+508", "code": "PM", "flag": "🇵🇲" }, 
    { "name": "Saint Vincent and the Grenadines", "dial_code": "+1784", "code": "VC", "flag": "🇻🇨" }, 
    { "name": "Samoa", "dial_code": "+685", "code": "WS", "flag": "🇼🇸" }, 
    { "name": "San Marino", "dial_code": "+378", "code": "SM", "flag": "🇸🇲" }, 
    { "name": "Sao Tome and Principe", "dial_code": "+239", "code": "ST", "flag": "🇸🇹" }, 
    { "name": "Saudi Arabia", "dial_code": "+966", "code": "SA", "flag": "🇸🇦" }, 
    { "name": "Senegal", "dial_code": "+221", "code": "SN", "flag": "🇸🇳" }, 
    { "name": "Serbia", "dial_code": "+381", "code": "RS", "flag": "🇷🇸" }, 
    { "name": "Seychelles", "dial_code": "+248", "code": "SC", "flag": "🇸🇨" }, 
    { "name": "Sierra Leone", "dial_code": "+232", "code": "SL", "flag": "🇸🇱" }, 
    { "name": "Singapore", "dial_code": "+65", "code": "SG", "flag": "🇸🇬" }, 
    { "name": "Slovakia", "dial_code": "+421", "code": "SK", "flag": "🇸🇰" }, 
    { "name": "Slovenia", "dial_code": "+386", "code": "SI", "flag": "🇸🇮" }, 
    { "name": "Solomon Islands", "dial_code": "+677", "code": "SB", "flag": "🇸🇧" }, 
    { "name": "Somalia", "dial_code": "+252", "code": "SO", "flag": "🇸🇴" }, 
    { "name": "South Africa", "dial_code": "+27", "code": "ZA", "flag": "🇿🇦" }, 
    { "name": "South Georgia and the South Sandwich Islands", "dial_code": "+500", "code": "GS", "flag": "🇬🇸" }, 
    { "name": "Spain", "dial_code": "+34", "code": "ES", "flag": "🇪🇸" }, 
    { "name": "Sri Lanka", "dial_code": "+94", "code": "LK", "flag": "🇱🇰" }, 
    { "name": "Sudan", "dial_code": "+249", "code": "SD", "flag": "🇸🇩" }, 
    { "name": "Suriname", "dial_code": "+597", "code": "SR", "flag": "🇸🇷" }, 
    { "name": "Svalbard and Jan Mayen", "dial_code": "+47", "code": "SJ", "flag": "🇸🇯" }, 
    { "name": "Swaziland", "dial_code": "+268", "code": "SZ", "flag": "🇸🇿" }, 
    { "name": "Sweden", "dial_code": "+46", "code": "SE", "flag": "🇸🇪" }, 
    { "name": "Switzerland", "dial_code": "+41", "code": "CH", "flag": "🇨🇭" }, 
    { "name": "Syrian Arab Republic", "dial_code": "+963", "code": "SY", "flag": "🇸🇾" }, 
    { "name": "Taiwan, Province of China", "dial_code": "+886", "code": "TW", "flag": "🇹🇼" }, 
    { "name": "Tajikistan", "dial_code": "+992", "code": "TJ", "flag": "🇹🇯" }, 
    { "name": "Tanzania, United Republic of", "dial_code": "+255", "code": "TZ", "flag": "🇹🇿" }, 
    { "name": "Thailand", "dial_code": "+66", "code": "TH", "flag": "🇹🇭" }, 
    { "name": "Timor-Leste", "dial_code": "+670", "code": "TL", "flag": "🇹🇱" }, 
    { "name": "Togo", "dial_code": "+228", "code": "TG", "flag": "🇹🇬" }, 
    { "name": "Tokelau", "dial_code": "+690", "code": "TK", "flag": "🇹🇰" }, 
    { "name": "Tonga", "dial_code": "+676", "code": "TO", "flag": "🇹🇴" }, 
    { "name": "Trinidad and Tobago", "dial_code": "+1868", "code": "TT", "flag": "🇹🇹" }, 
    { "name": "Tunisia", "dial_code": "+216", "code": "TN", "flag": "🇹🇳" }, 
    { "name": "Turkey", "dial_code": "+90", "code": "TR", "flag": "🇹🇷" }, 
    { "name": "Turkmenistan", "dial_code": "+993", "code": "TM", "flag": "🇹🇲" }, 
    { "name": "Turks and Caicos Islands", "dial_code": "+1649", "code": "TC", "flag": "🇹🇨" }, 
    { "name": "Tuvalu", "dial_code": "+688", "code": "TV", "flag": "🇹🇻" }, 
    { "name": "Uganda", "dial_code": "+256", "code": "UG", "flag": "🇺🇬" }, 
    { "name": "Ukraine", "dial_code": "+380", "code": "UA", "flag": "🇺🇦" }, 
    { "name": "United Arab Emirates", "dial_code": "+971", "code": "AE", "preferred": true, "flag": "🇦🇪" }, 
    { "name": "United Kingdom", "dial_code": "+44", "code": "GB", "preferred": true, "flag": "🇬🇧" }, 
    { "name": "United States", "dial_code": "+1", "code": "US", "preferred": true, "flag": "🇺🇸" }, 
    { "name": "Uruguay", "dial_code": "+598", "code": "UY", "flag": "🇺🇾" }, 
    { "name": "Uzbekistan", "dial_code": "+998", "code": "UZ", "flag": "🇺🇿" }, 
    { "name": "Vanuatu", "dial_code": "+678", "code": "VU", "flag": "🇻🇺" }, 
    { "name": "Venezuela, Bolivarian Republic of", "dial_code": "+58", "code": "VE", "flag": "🇻🇪" }, 
    { "name": "Viet Nam", "dial_code": "+84", "code": "VN", "flag": "🇻🇳" }, 
    { "name": "Virgin Islands, British", "dial_code": "+1284", "code": "VG", "flag": "🇻🇬" }, 
    { "name": "Virgin Islands, U.S.", "dial_code": "+1340", "code": "VI", "flag": "🇻🇮" }, 
    { "name": "Wallis and Futuna", "dial_code": "+681", "code": "WF", "flag": "🇼🇫" }, 
    { "name": "Yemen", "dial_code": "+967", "code": "YE", "flag": "🇾🇪" }, 
    { "name": "Zambia", "dial_code": "+260", "code": "ZM", "flag": "🇿🇲" }, 
    { "name": "Zimbabwe", "dial_code": "+263", "code": "ZW", "flag": "🇿🇼" }, 
    { "name": "Åland Islands", "dial_code": "+358", "code": "AX", "flag": "🇦🇽" }
];


export const mainSlides = [
    {img: mainSlideOne, text: 'We’ll get started in Piedmont, Italy...'}, 
    {img: mainSlideTwo, text: 'We’ll get started in Piedmont, Italy...'}, 
    {img: mainSlideThree, text: 'We’ll get started in Piedmont, Italy...'}, 
    {img: mainSlideFour, text: 'We’ll get started in Piedmont, Italy...'}, 
    {img: mainSlideFive, text: 'We’ll get started in Piedmont, Italy...'}, 
    {img: mainSlideSix, text: 'We’ll get started in Piedmont, Italy...'}
];

// https://vineify-dev-files.s3.amazonaws.com/tuscany-preview-310.jpg - preview
// https://vineify-dev-files.s3.amazonaws.com/piemonte-1980.jpg - image
