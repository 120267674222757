import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';


const Card = ({ title, children }) => {
    return (
        <div className="card">
            <div className="card__header"><h4 className="no-margin">{title}</h4></div>
            <div className="card__body">{ children }</div>          
        </div>
    )
}

Card.propTypes = {
    title: PropTypes.string
}

export default Card;
