import axios from 'axios'


export const getCuratorPreviewData = async curatorId => {
  const response = await axios.get(`/api/curators/${curatorId}`)

  if (response?.data?.success) {
    return response.data.curatorData
  }

  return {}
}

export const fetchCuratorsPreviewsList = async () => {
  const response = await axios.get(`/api/curators`);

  if (response?.data?.success) {
    return response.data.curators
  }

  return []
}

export const fetchCuratorCases = async curatorId => {
  const response = await axios.get(`/api/curators/${curatorId}/cases`)

  if (response?.data?.success) {
    return response.data.cases
  }

  return []
}

export const fetchCurrentCuratorOrders = async () => {
  try {
    const response = await axios.get(`/api/curators/orders`);

    return response?.data?.orders || []
  } catch (err) {
    console.log('fetch curator orders error: ', err)

    throw err;
  }
}

export const sendRecommendations = async (data) => {
  // data: {
  //   subject, emails,
  //   curator: { message, firstName: curator.firstName, photo: curator.photo, id: curator.id },
  //   case: { name, image, slug, categories: caseWinesTypes, id: caseData.id }
  // }
  try {
    const response = await axios.post(`/api/curators/send-recommendation`, data)

    return { emails: response?.data?.emails || [] }
  } catch (error) {
    return { error }
  }
}

export const fetchCuratorRecommendationsHistory = async ({curatorId, caseId}) => {
  const queryParamsArray = [`cid=${caseId}`];
  curatorId && queryParamsArray.push(`c=${curatorId}`)

  const queryParams = `?${queryParamsArray.join('&')}`

  try {
    const response = await axios.get(`/api/curators/recommendations${queryParams}`)

    return { recommendations: response.data?.recommendations || [] }
  } catch (error) {
    return { error }
  }

}
