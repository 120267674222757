import React, { useState, useRef, useContext } from 'react';   
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../context/country';
import classNames from 'classnames';
import { useHelpers } from '../../hooks';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NewCaseCard, AccountOrderContact, CasePosition } from '..';

// eslint-disable-next-line
import Badge from '../badge/StatusBadge';
import Case from '../../classes/Case';


import chevronUp from '../../assets/icons/chevron-up.svg';
import chevronDown from '../../assets/icons/chevron-down.svg';


const OrderCard = ({ order, account, restricted }) => {
  const { charge } = useContext(CountryContext);
  const { user } = useSelector(state => state.auth);
  const { t } = useTranslation();
  const orderCardRef = useRef();
  const orderDetailsRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const { countBottles, formatDateTime, scrollTo, capitalize, getCurrencySymbol, getPaypalPriceDetails, getWineName } = useHelpers();
  const btn = !expanded
    ? <>{t('ACCOUNT_ORDER_CARD_SHOW_CONTENT')} <img src={chevronDown} alt="icon" /></>
    : <>{t('ACCOUNT_ORDER_CARD_HIDE_CONTENT')} <img src={chevronUp} alt="icon" /></>

  const isRetailer = user.role === 'retailer';
  const isAdjusted = order?.case?.isAdjusted;
  const orderCase = Case.create(order.case);
  const orderShipping = order.contacts;
  const currency = order?.currency || 'usd';
  const orderPrice = parseFloat(order?.cost)?.toFixed(2) || orderCase.getPrice(currency, charge, false);
  const showPrice = !restricted || isRetailer;

  // eslint-disable-next-line
  const orderStatus = order.current_status
  const orderDate = order.order_placed;

  let cardClasses = classNames('order-card', { 'expanded': expanded || !account });
  orderCase.sortWines();
  const positions = orderCase.wines.map(wine => <CasePosition key={`order-wine_${getWineName(wine)}`} position={wine} />)

  const toggleOrderContentShow = () => {
    const newState = !expanded;

    setExpanded(newState);

    const node = newState
      ? orderDetailsRef.current
      : orderCardRef.current

    scrollTo(node, 0, 300, 50);
  };

  const orderSubtotal = +order?.case?.prices?.usd || 0;
  const taxTotal = Math.round((+orderPrice - orderSubtotal) * 100) / 100;

  const priceContent = order?.warehouse?.name === 'Serendipity Wines' ? (
    <div className="body-section">
      <div className="body-section__title">{t('ALL_IN_PRICE')}:</div>

      <div className="body-section__content">
        <ul className="order-price-details">
          <li className="price-details__row">
            <span className="price-details__value">${orderSubtotal?.toFixed(2)}</span>
            <span className="price-details__label">Case Price</span>
          </li>

          <li className="price-details__row">
            <span className="price-details__value">${(+taxTotal)?.toFixed(2)}</span>
            <span className="price-details__label">Sales Tax</span>
          </li>

          <li className="price-details__row divider" />

          <li className="price-details__row total-price">
            {/* <span>Order subtotal:</span> */}
            <b>${(+orderPrice)?.toFixed(2)}</b>
          </li>
        </ul>
      </div>

    </div>
  ) : (
    <div className="body-section">
      <div className="body-section__title">{t('ALL_IN_PRICE')}:</div>
      <p className="body-section__content">
        <b>{getCurrencySymbol(currency)}{orderPrice}</b>
      </p>
      {/* <small className="text-gray">{currency.toUpperCase()}</small> */}
    </div>
  )


  return (
    <div className={cardClasses}>
      {
        account && !restricted &&
        <div className="order-card__header" ref={orderCardRef}>
          <div className="order-card__title">
            <h3>{capitalize(t('ORDER'))} #{order.id}</h3>
            <p className="order-date">{capitalize(t('PLACED'))}: {formatDateTime(orderDate)}</p>
          </div>
          <Badge status={orderStatus} />
        </div>
      }
      <div className="order-card__body">
        <NewCaseCard
          caseData={orderCase}
          showDynamicPicture={true}
          showStockBadge={false}
          previewMode={true}
          descriptionSize={0}
          isAdjustedCase={isAdjusted}
          isOrderCase
        />

        <div className="body-info">
          <div className="body-section">
            <div className="body-section__title">{capitalize(t('CASE_NAME'))}:</div>
            <p className="body-section__content">{orderCase.name}</p>

            <small>{capitalize(t('CASE'))}, {countBottles(orderCase.wines)} {capitalize(t('BOTTLES'))}</small>

            { 
              order?.isGift && <>
              <p className="body-section__title">Gift Message</p>
              <p className="order-card__gift-message no-margin">{ order?.giftMessage }</p>
              </>
            }
          </div>
          {
            account && !restricted &&
            <div className="body-section">
              <AccountOrderContact title={t('ACCOUNT_ORDER_CARD_SHIPPING')} contactData={orderShipping} />

              <AccountOrderContact title="Billing Address" contactData={order.billing} />
            </div>
            // <div className="body-section">
            //   <div className="body-section__title">{t('ACCOUNT_ORDER_CARD_SHIPPING')}:</div>

            //   <div className="body-section__content">
            //     <p>{
            //       orderShipping?.customer.length > 0 ?
            //         orderShipping?.customer : t('NOT_SPECIFIED')
            //     },</p>

            //     <p>{
            //       !orderShipping || [
            //         orderShipping.address, orderShipping.city, orderShipping.zip
            //       ].every(contact => !contact)
            //         ? t('NOT_SPECIFIED')
            //         : `${orderShipping.address}, ${orderShipping.city},${!!orderShipping.state && ' ' + orderShipping.state + ', '}${orderShipping.zip}`
            //     }</p>
            //   </div>
            // </div>
          }
          {
            showPrice && priceContent
            // <div className="body-section">
            //   <div className="body-section__title">{t('ALL_IN_PRICE')}:</div>
            //   <p className="body-section__content">
            //     <b>{getCurrencySymbol(currency)}{orderPrice}</b>
            //   </p>
            //   {/* <small className="text-gray">{currency.toUpperCase()}</small> */}
            // </div>
          }
        </div>
      </div>
      <div className="order-card__footer">
        {
          account && !restricted &&
          <div className="card__ctrl">
            <button
              className="ctrl-btn"
              onClick={toggleOrderContentShow}
            >{btn}</button>
          </div>
        }
        <div className="order-details" ref={orderDetailsRef}>
          <ul className="positions-list">
            <li className="case-position header">
              <span>{capitalize(t('WINE'))}:</span>
              <div className="pos-info">
                <span className="pos-name"></span>
                <span className="pos-quantity">Qty:</span>
              </div>
            </li>
            {positions}
          </ul>
        </div>
      </div>
    </div>
  )
}




OrderCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    cases: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      image: PropTypes.any,
      bottles: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        image: PropTypes.any,
        grapeName: PropTypes.string,
        producer: PropTypes.object,
        tastes: PropTypes.arrayOf(PropTypes.string),
        quantity: PropTypes.number
      }))
    })),
    contact: PropTypes.shape({
      phone: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      zip: PropTypes.number,
      address: PropTypes.string,
      company: PropTypes.string,
      contactType: PropTypes.string,
      isOneTime: PropTypes.bool,
      createdAt: PropTypes.string
    })
  })
}

OrderCard.defaultProps = {
  account: true,
  restricted: false,
  order: {
    contact: {
      phone: 'Not specified',
      city: 'Not specified',
      country: 'Not specified',
      zip: 'Not specified',
      address: 'Not specified',
      company: 'Not specified',
    }
  }
}

export default OrderCard;
