import React from 'react'

import photo from '../../../assets/images/public-home-cofounders.jpg';


const PublicHomeWelcome = () => {


  return (
    <div className="public-home__welcome-section ">
      <img className="welcome-section__image" src={photo} alt="" />
      
      <div className="welcome-section__info">
        <h2 className="welcome-section__title public-title-sm text-xs">Welcome to <b>Your Wine Journey</b> with Vineify</h2>

        {/* <p className="welcome-section__description">We started Vineify for you. We believe wine drinkers in the US needed a better way to enjoy the wonders of European wines. No more staring at a wall of wines in a store or being confused by a wine menu. Vineify is truly the easiest way to enjoy European wines. Cheers and Saluti!</p> */}

        <p className="welcome-section__description">We started Vineify for you. We believe wine drinkers in the US needed a better way to enjoy the wonders of European wines. No more staring at a wall of wines in a store or being confused by a wine menu. Vineify is truly the easiest way to enjoy European wines. Cheers and Saluti!</p>

        <p className="welcome-section__author">Jay Hallberg & Paolo Dalla Mora</p>
        <small>Co-Founders, Vineify</small>
      </div>
    </div>
  )
}

export default PublicHomeWelcome