import classNames from 'classnames'
import React, { useState } from 'react'
import { MultiselectCheckboxes, RadioButtonsGroup, Select, Spinner } from '../../../components'
import { useMediaQuery, useRequests, useStoreAuth } from '../../../hooks'


const AiBuddyForm = () => {
  const localFilters = JSON.parse(localStorage.getItem('_cdffs') || '{}')
  const { authState: { user } } = useStoreAuth()
  const { requestPending, post } = useRequests()
  const [processingRequest, setProcessingRequest] = useState(false)
  const { isMobile } = useMediaQuery()
  const initialState = {
    bottles: localFilters.bottles || 12,
    budget: localFilters?.budget?.[localFilters.bottles || '12'],
    categories: [], 
    message: '', 
    email: user.email || ''
  }
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [formData, changeFormData] = useState({ ...initialState, isSent: false })

  const pricesOptions = {
    '6': [
      { label: '$100 - $200', value: '100-200' },
      { label: '$200 - $300', value: '200-300' },
      { label: '$300+', value: '300+' },
    ],
    '12': [
      { label: '$200 - $300', value: '200-300' },
      { label: '$300 - $500', value: '300-500' },
      { label: '$500 - $700', value: '500-700' },
      { label: '$700+', value: '700+' },
    ]
  }

  const categoriesOptions = [
    { label: 'Whites', value: 'white', isSelected: formData.categories.includes('white') },
    { label: 'Reds', value: 'red', isSelected: formData.categories.includes('red') },
    { label: 'Rosé', value: 'rose', isSelected: formData.categories.includes('rose') },
    { label: 'Sparkling', value: 'sparkling', isSelected: formData.categories.includes('sparkling') }
  ]

  const bottlesFilters = [
    { buttonContent: <>6 Bottles</>, value: 6 },
    { buttonContent: <>12 Bottles</>, value: 12 }
  ]
  

  const handleChangeCategories = data => {
    const categories = data.map(({ value }) => value)

    changeFormData(prev => ({ ...prev, categories }))
  }

  const handleChangeFormData = field => data => {
    if (field) {
      if (field === 'budget') {
        const savedSixBottlesBudget = formData.bottles === 6 ? data : localFilters?.budget?.['6'] || {}
        const savedTwelveBottlesBudget = formData.bottles === 12 ? data : localFilters?.budget?.['12'] || {}

        localFilters.budget = {
          '6': savedSixBottlesBudget,
          '12': savedTwelveBottlesBudget
        }
        localStorage.setItem('_cdffs', JSON.stringify(localFilters))
      }

      if (field === 'bottles') {
        localFilters.bottles = data
        localStorage.setItem('_cdffs', JSON.stringify(localFilters))
      }

      changeFormData(prev => ({
        ...prev, [field]: data, 
        budget: field !== 'bottles' 
          ? field === 'budget' 
            ? data : prev.budget 
          : null
      }))
    } else {
      const { name, value } = data.target

      changeFormData(prev => ({
        ...prev, [name]: value
      }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setProcessingRequest(true)

    const { message, budget, email, bottles } = formData;
    const categories = categoriesOptions.reduce(
      (acc, { label, isSelected }) => {
        isSelected && acc.push(label)

        return acc
      }, []
    )

    const body = {
      email, message, bottles,
      categories, 
      price: budget?.label
    }

    const response = await post({ url: '/api/curators/message', body })

    if (response?.data?.success) {
      setShowSuccessMessage(true)
      changeFormData(prev => ({ ...prev, isSent: true }))
    }

    setProcessingRequest(false)
  }


  return (
    <form className="personal-rec__form" onSubmit={handleSubmit}>
      <div className="personal-rec__form-filters">
        <div className="form-control">
          <label htmlFor="">Number of Bottles</label>

          <RadioButtonsGroup
            onClick={handleChangeFormData('bottles')}
            buttons={bottlesFilters}
            currentValue={formData.bottles}
          />
        </div>

        <div className="form-control personal-rec__preferences-control">
          <label htmlFor="price">Budget</label>

          <Select
            options={pricesOptions[formData.bottles]}
            value={formData.budget}
            onChange={handleChangeFormData('budget')}
          />
        </div>

        <div className="form-control personal-rec__preferences-control">
          <label htmlFor="category">Exclude Wine Type(s)</label>

          <MultiselectCheckboxes
            options={categoriesOptions}
            onChange={handleChangeCategories}
            value={categoriesOptions.filter(({ value }) => formData.categories.includes(value))}

          />
        </div>
      </div>

      <div className="form-control">
        <label htmlFor="message">Describe what you’re shopping for:</label>

        <textarea
          name="message" id="message" cols="30" rows="6"
          placeholder={
            isMobile 
            ? `For example, I’m looking for rosé, white and light red wines to go with the summer BBQs and sipping poolside. No heavy reds please!`
            : ``
          }
          onChange={handleChangeFormData()}
          value={formData.message}
        />
      </div>

      <div className="form-control horizontal">
        {
          showSuccessMessage &&
          <p className="personal-rec__form-success">We’ll be in touch within 24 hrs with your personalized recommendation!</p>
        }

        <input
          placeholder="Email address"
          name="email"
          onChange={handleChangeFormData()}
          value={formData.email}
        />

        <button
          className={classNames('button', { 'button_outline': formData.isSent })}
          disabled={formData.email.length < 1 || requestPending || processingRequest}
        >
          { formData.isSent ? 'Submit Another Request' : 'Submit Request' }
          {
            processingRequest &&
            <Spinner spinnerClass="pending-spinner small inner" />
          }
        </button>
      </div>
    </form>
  )
}


export default AiBuddyForm
