import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ShortDescription } from '../../components';
import { useHelpers } from '../../hooks';
import { Link } from 'react-router-dom';


const TastingWineCard = ({ wine, onPlayVideo }) => {
  const { t } = useTranslation();
  const { getWineName, getVideoPlayerLink } = useHelpers();
  const wineName = getWineName(wine);
  const {
    name, grape: { category: grapeCategory, name: grapeName }, image, thumbnail, description, tastes, video,
    vintage, producer, slug
  } = wine;

  const videoData = video ? getVideoPlayerLink(video) : {};


  return (
    <div className="tasting-wine-card-container">
      <div className="card tasting-wine-card">
        <div className="card__body">
          <div className="tasting-wine__video-preview">
            <img src={thumbnail || image} alt={name} />
            {/* <p className="tasting-wine__name">{`${vintage} ${producer} ${name}`}</p> */}
            {/* <p className="tasting-wine__name">{ wineName }</p> */}
            <Link to={`/wines/${slug}`} className="tasting-wine__name">{wineName}</Link>
            {
              video && (
                <button
                  className="play-button"
                  onClick={() => onPlayVideo(videoData?.link)}
                ></button>
                //     ) : (
                //       <div className="tasting-wine__alert"><p>{t('TASTINGS_NO_VIDEO_NOTIFICATION')}</p></div>
              )
            }
          </div>

          <div className="tasting-wine__content">
            <ShortDescription lines={4} expandable={true}>
              <p>{description}</p>
            </ShortDescription>

            <div className={`wine-grape grape_${grapeCategory}`}>{grapeName}</div>
            <ul className="wine-tastes">{
              tastes.map(taste => <li key={taste}>{taste}</li>)
            }</ul>
          </div>
        </div>
      </div>
    </div>
  )
}


TastingWineCard.propTypes = {
  wine: PropTypes.shape({
    name: PropTypes.string,
    video: PropTypes.string,
    grape: PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string
    }),
    image: PropTypes.string,
    description: PropTypes.string,
    tastes: PropTypes.arrayOf(PropTypes.string)
  })
}


export default memo(TastingWineCard);
