import { useDispatch, useSelector } from "react-redux"
import { regionActions as actions } from './region.slice'
import { requestCountriesDataList, requestCurrentRegionCases, requestRegionData, requestRegionGrapes, requestRegionsList, requestShippingCharges, requestSliderRegionsData } from './region.actions'


const useRegions = () => {
  const dispatch = useDispatch()
  const {
    processingRequest,
    processingRequests,
    error,
    currentCountry,
    charge,
    countryCode,
    shippingCountriesList,
    sliderRegions,
    currency,
    countriesTranslations,
    currentRegion,
    regions,
    parentRegions,
    currentRegionData,
    currentRegionMixedCases,
    currentRegionCases
  } = useSelector(state => state.regions)
  const regionsState = {
    processingRequest,
    processingRequests,
    error,
    currentCountry,
    charge,
    countryCode,
    shippingCountriesList,
    sliderRegions,
    currency,
    countriesTranslations,
    currentRegion,
    regions,
    parentRegions,
    currentRegionData,
    currentRegionMixedCases,
    currentRegionCases
  }
  const regionActions = {}

  regionActions.requestCountriesDataList = () => dispatch(requestCountriesDataList())

  regionActions.requestCurrentRegionCases = ({ regionName, language = 'en', activeMixedCase, activeCase }) =>
    dispatch(requestCurrentRegionCases({ regionName, language, activeMixedCase, activeCase }))

  regionActions.requestRegionData = ({ regionName, language = 'en' }) =>
    dispatch(requestRegionData({ regionName, language }))
  
  regionActions.requestRegionGrapes = ({ regionName, language = 'en', grapesCategories }) =>
    dispatch(requestRegionGrapes({ regionName, language, grapesCategories }))  
    
  regionActions.requestRegionsList = () => dispatch(requestRegionsList())

  regionActions.requestShippingCharges = (country = 'en') => dispatch(requestShippingCharges(country))

  regionActions.requestSliderRegionsData = () => dispatch(requestSliderRegionsData())

  regionActions.setParentRegions = (parentRegionsList = []) => dispatch(actions.setParentRegions(parentRegionsList))

  regionActions.changeCurrentRegion = (currentRegion) => dispatch(actions.changeCurrentRegion(currentRegion))

  regionActions.changeActiveRegionCase = ({ id, isMultiRegion }) => 
    dispatch(actions.changeActiveRegionCase({ id, isMultiRegion }))

    // clearRegionData: (state) => {
    //   state.currentRegionData = undefined;
    // }
  regionActions.resetRegionData = (currentRegionData) => dispatch(actions.clearRegionData(currentRegionData))


  return { regionsState, regionActions }
}


export default useRegions;
