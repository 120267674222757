import React from 'react';
import { v4 as keyGen } from 'uuid';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ShortDescription } from '..';

import './style.scss';


const AdminGrapeRow = ({ id, name, image, description, category, tastes, region }) => {
  const { user } = useSelector(state => state.auth)
  const history = useHistory();

  const tastesList = tastes?.map(
    taste => <li key={keyGen()}>{taste}</li>
  );

  const handleEditGrapeData = e => {
    e.preventDefault();

    history.push(`/${user.role}/grapes/${id}/grape-data`)
  }

  return (
    <div className="admin-grape-row">
      <div className="admin-grape__img-container"><img src={image} alt={name} /></div>

      <div className="admin-grape__info">
        <div className="flex-space-between">
          <div className={`admin-grape__name grape_${category}`}>{name}</div>

          <ul className="admin-grape__tastes">{tastesList}</ul>
        </div>

        <div className="admin-grape__region">region: {region}</div>

        <div className="admin-grape__description">
          <ShortDescription
            expandable={true}
            lines={3}
          ><p className="no-margin">{description}</p>
          </ShortDescription>
        </div>
      </div>

      <div className="admin-grape__tools">
        <button
          className="button_xs button button_outline"
          onClick={handleEditGrapeData}
        >Edit Grape</button>
      </div>
    </div>
  )
}

AdminGrapeRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  tastes: PropTypes.array,
  region: PropTypes.string
}

export default AdminGrapeRow;
