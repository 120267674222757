import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { v4 as keyGen } from 'uuid';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { ReportCard, CustomCaseRow } from '..';

import heartIcon from '../../assets/icons/heart-filled.svg';
import likeIcon from '../../assets/icons/thumbs-up.svg';

import './style.scss';
import { useRequests } from '../../hooks';


const PreferencesReportCard = ({ customer, customers: allCustomers, wines, customCases, createdCases, onChange }) => {
  const { post, errors, clearErrors } = useRequests();

  

  const content = wines.map(
    ({
      isFavourite, vintage, name, amountBottlesDrunk, producer, willRecomend, inventory
    }) => {
      const wineClass = classNames('preference-wine', {
        'green': willRecomend === 'yes',
        'red': willRecomend === 'no',
        'gray': willRecomend === 'maybe',
      });

      return (
        <div className={ wineClass } key={ keyGen() }>
          <div className="date">{ vintage }</div>
          <div className="producer">{ producer }</div>
          <div className="name">{ name }</div>
          <div className="stats">{ amountBottlesDrunk || 0 } / { inventory }</div>
          <div className="recomend">{  
            willRecomend && willRecomend !== 'no_answer' ?
              willRecomend : '-'
          }</div>
          {
            isFavourite &&
            <img src={ heartIcon } alt="favourite wine" />
          }
        </div>
      )
    }
  )


  const stats = useMemo(() => wines.reduce(
    (acc, { amountBottlesDrunk = 0, isFavourite, willRecomend, inventory = 0 }) => {
      acc.drankWines += +amountBottlesDrunk;
      acc.purchased += +inventory;
      acc.favorites += +isFavourite;
      acc.drinkAgain += +['yes', 'maybe'].includes(willRecomend);

      return acc;
    }, { drankWines: 0, favorites: 0, drinkAgain: 0, purchased: 0 }
  ), [wines]);


  const statInfoIsNotEmpty = [
    'drankWines', 'favorites', 'drinkAgain'
  ].findIndex( stat => stats[stat] > 0 ) >= 0;

  const { drankWines, favorites, drinkAgain, purchased } = stats;


  const handleCreateSinglePersonalCase = async (wine, customers, created) => {
    if (created && customers.includes(customer.name)) return;
    

    const userId = customer.id;
    const newUrl = '/api/wines/cases/custom-case';

    const response = await post({ 
      url: newUrl, 
      body: { 
        wineId: wine.id, 
        customers: JSON.stringify([ userId ])
      } 
    });

    if (response?.data?.success) {

      onChange(response?.data?.newPersonalCase);
      const sucessMsg = `New Case ${response.data.newPersonalCase.name} was successfully created.`

      toast.success(sucessMsg);
    } else {
      
    }
  }

  const customCasesContent = customCases?.length > 0 ? 
    customCases.map(
      caseData => {
        const { wine, customers, created } = caseData;

        const createdCase = createdCases.find(
          ({ wineId, customer: cid }) => (
            customers.some( ({ id }) => id === cid && customer.id === cid ) && wineId === wine.id
          )
        );


        return (
          <CustomCaseRow 
            key={ keyGen() } 
            wine={ wine }
            customer={ allCustomers.find(({ value }) => value === customer.id ) }
            onchange={ e => handleCreateSinglePersonalCase(wine, customers, created) }
            action={  
              createdCase ? 'Created' : 'Create' 
            }
            caseId={ createdCase?.id }
            created={ createdCase }
            isSingle={ true }
          />
        ) 
      }
    ) : null;


  const info = statInfoIsNotEmpty ? (
    <div className="flex-space-between text-sm">
      drank { drankWines } / { purchased }
      &nbsp;&nbsp;&nbsp;<img src={ heartIcon } alt="favorites" height="14" />&nbsp;x&nbsp;{ favorites } 
      &nbsp;&nbsp;&nbsp;<img className="fill-green" src={ likeIcon } alt="would drink again" height="14" />&nbsp;x&nbsp;{ drinkAgain }
    </div>
  ) : null;


  if (errors?.message) {
    const { message: errMessage } = errors;

    toast.error(errMessage);

    clearErrors();
    toast.clearWaitingQueue(true);
  }


  return (
    <ReportCard id={ customer?.id || '' } name={ customer?.name || '' } info={ info }>
      <>
      <div className="preference-wines-header">
        <div className="date">Vintage</div>
        <div className="producer">Producer</div>
        <div className="name">Name</div>
        <div className="stats">Drank</div>
        <div className="recomend">Recommend</div>
      </div>
      <div className="preference-wines-list">
        { content }
      </div>

      {
        customCasesContent &&
        <div className="custom-cases-list">
          <div className="preference-wines-header">
            <h3 className="title">Custom Cases for {customer.name}</h3>
          </div>

          { customCasesContent }
        </div>
      }
      </>
    </ReportCard>
  )
}


PreferencesReportCard.propTypes = {
  wines: PropTypes.arrayOf(PropTypes.shape({
    isFavourite: PropTypes.bool,
    vintage: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    name: PropTypes.string,
    amountOfBottlesDrunk: PropTypes.number,
    producer: PropTypes.string,
    willRecomend: PropTypes.oneOf(['yes', 'maybe', 'no']),
    inventory: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
  })),
  customer: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
}


export default memo(PreferencesReportCard);
