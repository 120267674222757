import axios from 'axios';
import ApiClient from '../api'

const USER_BASE_URL = `/api/users`
const UserClient = new ApiClient(USER_BASE_URL);

export const getUsersList = async (selectedRole = 'all') => {
  const response = await UserClient.get({ url: `?ur=${selectedRole}` })

  if (response?.success) {
    return response.users
  } 
  
  return []
}


export const login = async ({ email, password, keepSigned }) => {
  const data = { email, password, keepSigned };

  const response = await UserClient.post({ url: `/login`, data });

  return response;
}

export const logout = async (history = null, isAdult = true) => {
  const response = await UserClient.get({ url: '/logout' });

  if (response?.success && history?.push) history?.push('/login');

  return response?.success;
}

export const authenticateUser = async (withGeolocation) => {
  const requests = [UserClient.get({ url: `/authenticate` })]
  withGeolocation && requests.push(axios.get('https://api.ipregistry.co/?key=dh0ozvc3szq9mj'))
  
  const [userResponse, locationResponse] = await Promise.all(requests)

  // user: { ...user, lastBilling: { ...user.lastBilling, phone: userBillingPhone }, 
  // quizIsCompleted: req.quizIsCompleted,
  // config: { p, g, f }

  if (userResponse?.success) {
    const { user, config, quizIsCompleted } = userResponse;

    const data = {user, config, quizIsCompleted}

    if (withGeolocation) {
      const locationData = locationResponse?.data || {};
      data.ip = locationData?.ip
      data.userAgent = locationData.user_agent
      data.location = {
        region: locationData.location.region,
        country: locationData.location.country.name
      }
    }

    return data
  } 
  
  return { 
    isAuthenticated: userResponse.isAuthenticated,
    isAiAuthenticated: userResponse.isAiAuthenticated,
     user: { id: -1, role: 'guest' }, config: undefined 
  }
}

export const getUserRoles = async () => {
  const response = await axios.get('/api/content/user-roles');

  if (response?.data?.success) {
    return response.data
  } 
  
  return []
}

export const getUserStatistics = async () =>  {
  axios.get(`${USER_BASE_URL}/statistics`)
  const response = await UserClient.get({ url: `/statistics` })

  if (response?.success) {
    const { statistics, total } = response;
    return { statistics, total }
  } 
  
  return { statistics: undefined, total: undefined }
}

export const deleteUser = async userId => {
  const response = await UserClient.get({ url: `/${userId}` })

  return response?.success;
}

export const changeUserData = async (userId, data) => {
  const response = await UserClient.post({ url: `/${userId}/change-data`, data  });

  return response?.success;
}

export const getCurrentUserOrderedCases = async () => {
  const response = await axios.get(`/api/orders/user-cases`);

  if (response?.data?.success) {
    return response.data.cases
  }

  return []
}

export const registerNewUser = async ({ firstName, lastName, email, password, zipcode, location, device }) => {
  const data = {firstName, lastName, email, password, zipcode, location, device}
  try {
    const response = await axios.post('/api/users/register', data);

    return response?.data
  } catch (err) {
    if (err.response) return err.response.data;

    throw err;
  }
}


export const changeUserLegalAge = async ({ email, isAdult }) => {
  const data = { email, isAdult };

  const response = await axios.post(`/api/users/user-age`, data);

  return response?.data?.success
}

export const changeUserAccountData = async ({ email, firstName, lastName }) => {
  const data = { email, firstName, lastName }

  const response = await axios.post(`/api/users/settings`, data)

  return response?.data?.success
}

export const aiSignIn = async password => {
  const response = await axios.post(`/api/users/ai-login`, { password })

  return response?.data
}

export const followCurator = async curatorId => {
  const response = await axios.post(`/api/users/follow-curator/${curatorId}`)

  if (response?.data?.success) {
    const { record: user, status } = response.data

    return { user, status }
  }

  return {}
}