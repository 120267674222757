import React, { useState } from 'react'
import { v4 as genId } from 'uuid'
import PropTypes from 'prop-types'
import Icon from '../common/Icon'
import { iconsShapes, colors } from '../../assets/iconsData'
import { useRequests } from '../../hooks'
import { toast } from 'react-toastify'
import { Spinner } from '..'


const HiResImageButton = ({ iconData, id: caseId, caseName }) => {
  const { get, post } = useRequests()
  const [imageProcessing, setImageProcessing] = useState(false)
  const [requests, changeRequests] = useState(0)

  const { icon = 'image-v', color = 'vineify-green', size = 18 } = iconData

  const getHiResImage = async (requestId) => {
    const response = await get({ url: `/api/cases/generated-hr-image/${requestId}` })

    if (response?.data?.success) {
      const link = document.createElement('a');
      link.href = response.data?.image;
      link.style.display = 'none'
      link.download = `${caseName?.split(' ').join('_')}.png`;
      link.click();
      
      link.remove()
      
      setImageProcessing(false)
      changeRequests(prev => prev + 1)
    } else {
      if (response?.data?.success === false || requests > 5) {
        setImageProcessing(false)

        changeRequests(0)

        toast.error(`Could not create hi-res image of the case ${caseName}`)
      } else {
        changeRequests(prev => prev + 1)
        getHiResImage(requestId)
      }
    }
  }

  const handleCreateHiResImage = async e => {
    e.preventDefault()

    if (imageProcessing) return;
    const requestId = genId();

    setImageProcessing(true)

    const response = await post({ url: `/api/cases/request-hires-image`, body: { requestId, caseId } })


    if (response?.data?.success) {
      changeRequests(0)

      getHiResImage(requestId)
    }
  }

  return (
    <button
      className="button-link icon-xs-button"
      disabled={imageProcessing}
      onClick={handleCreateHiResImage}
    >{
        imageProcessing
          ? (
            <Spinner size={size || 18} />
          ) : (
            <Icon icon={icon || 'image-v'} size={size || 18} color={color || 'vineify-green'} />
          )
      }</button>
  )
}

HiResImageButton.propTypes = {
  iconData: PropTypes.shape({
    icon: PropTypes.oneOf(Object.keys(iconsShapes)),
    color: PropTypes.oneOf(Object.keys(colors)),
    size: PropTypes.number
  }),
  id: PropTypes.number
}

HiResImageButton.defaultProps = {
  iconData: {}
}

export default HiResImageButton