import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useRequests } from '../../hooks';
import { Spinner } from '..';

const ImportSerendipityWinesDataButton = ({ onSuccess, onFail, label }) => {
  const { post } = useRequests();
  const [file, setFile] = useState();
  const [importingData, setImportingData] = useState(false);
  const fileRef = useRef();

  const handleBrowseFile = e => {
    e.preventDefault();

    fileRef.current.click();
  }

  const handleImportWines = async e => {
    setImportingData(true)

    const file = e.currentTarget.files[0];

    const body = new FormData();
    body.append('wines', file);

    const response = await post({ url: `/api/wines/import-sw-wines`, body });
    const { updatedAt, data } = response?.data

    response?.data?.success && onSuccess(data, updatedAt);

    setImportingData(false)
  }


  return (
    <div>
      <button
        className="button button_info"
        disabled={ importingData }
        onClick={handleBrowseFile}
      >{label}{ 
        importingData && 
        <small className="button-float-content bottom text-black"><i>Importing data</i> <Spinner size={ 20 } /></small> 
      }</button>

      {/* <small style={{ textAlign: 'center', display: 'inline-block', padding: '0 32px' }}>{warehouse ? `Warehouse: ${warehouse.label}` : `Select warehouse`}</small> */}

      <input ref={fileRef} type="file" onChange={handleImportWines} hidden />
    </div>
  )
}

ImportSerendipityWinesDataButton.propTypes = {

}

export default ImportSerendipityWinesDataButton