import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useActions, useHelpers, useMediaQuery } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { MainMenu, LanguageMenu, Alert, MobileMenu, Popover, RegionsMenu, ReferFriendBar } from '../../components';

import logo from '../../assets/icons/logo-h-white-3x.svg'; 
import closeIcon from '../../assets/icons/close.svg';
import bellIcon from '../../assets/icons/bell.svg';

import 'react-dropdown/style.css';
import './style.scss';
import { useRef } from 'react';


const Header = ({ transparent }) => {
  const dispatch = useDispatch()
  const { isMobile } = useMediaQuery()
  const { authActions } = useActions();
  const history = useHistory();
  const { t } = useTranslation();
  const { capitalize } = useHelpers();
  const location = useLocation();
  const [popoverIsShown, changePopoverVisibilityState] = useState(false);
  const [showNotification, setNotificationState] = useState(true);
  const [mobileMenuIsOpen, changeMobileMenuState] = useState(false);
  const timeoutRef = useRef();
  const scrollDataRef = useRef();
  const headerRef = useRef()
  const { isAuthenticated, user } = useSelector(state => state.auth)
  const { parentRegions } = useSelector(state => state.regions)

  const handleScroll = () => {

    if (!location.hash || !location.hash.length) {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'instant' }), 0);
    }

  }

  const toggleMobileMenuVisibility = () => {
    changeMobileMenuState(prev => !prev);
  }

  const handleHideNotification = e => {
    e.preventDefault();

    setNotificationState(false);
  }

  const handleLogout = () => dispatch(authActions.logout(history));

  const handleChangeScrollData = () => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {

        const { scrollPosition } = scrollDataRef.current;
        const newScrollPosition = window.scrollY <= 0 ? 0 : window.scrollY;
        const lastScrollAction = scrollPosition > newScrollPosition ? 'reduce' : 'increase';

        if (scrollPosition > newScrollPosition) {
          window.scrollY > 120 ? headerRef.current.classList.add('is-sticky') : headerRef.current.classList.remove('is-sticky')
          headerRef.current.classList.remove('is-hidden')
        } else if (window.scrollY > 120) {
          headerRef.current.classList.add('is-hidden')
          headerRef.current.classList.remove('is-sticky')
        }

        scrollDataRef.current = {
          scrollPosition: window.scrollY <= 0 ? 0 : window.scrollY, lastScrollAction 
        }

        scrollDataRef.current = { scrollPosition: newScrollPosition, lastScrollAction }
        timeoutRef.current = 0;
      }, 200)
    }
  }
  

  useEffect(() => {
    scrollDataRef.current = { scrollPosition: 0, lastScrollAction: undefined }
    timeoutRef.current = 0;
    document.addEventListener('scroll', handleChangeScrollData)

    return () => {
      document.removeEventListener('scroll', handleChangeScrollData)
    }
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    handleScroll(); 
  }, [location]);


  const headerLinks = <div className="nav-menu">
    <ul className="header-links">
      {
        ['admin', 'test user'].includes(user?.role) &&
        <li className="link"><Link to="/blog/posts">Blog</Link></li>
      }
      {
        isAuthenticated && <>
        <li className="link"><Link to="/recommendations">{capitalize(t('RECOMMENDATIONS'))}</Link></li>
        
        <li className="link">
        <Popover
          isOpen={popoverIsShown && user?.hasNewWines}
          padding={0}
          arrowSize={12}
          arrowColor="white"
          align="end"
          arrowClassName="popover-arrow"
          position="bottom"
          notification={t('NEW_WINES_ALERT_TEXT')}
        ><Link to="/my-wines"
          onMouseOver={e => changePopoverVisibilityState(true)}
          onMouseOut={e => changePopoverVisibilityState(false)}
        >{capitalize(t('MY_WINES'), true)}</Link></Popover>
        {
          user.hasNewWines &&
          <span className="new-wines-notification">
            <img src={bellIcon} alt="new wines" />
          </span>
        }
      </li>
      {/* <li className="link"><Link to="/tastings">{capitalize(t('TASTINGS'))}</Link></li> */}
      </>}
      <li className="link"><Link to="/wine-collections">Collections</Link></li>
      <li className="link flex-space-between" data-cy="regions-dropdown">
        <RegionsMenu regionsList={ parentRegions } />
      </li>

    </ul>

    <MainMenu role={user.role} isAuthenticated={ isAuthenticated } /> 
  </div>

  const mobileMenuLinks = [
    { path: '/', title: capitalize(t('HOME')) },
    { path: '/wine-collections', title: 'View Collections' },
    // { path: '/tastings', title: capitalize(t('TASTINGS')) },
    {
      title: 'Regions List', innerLinks: parentRegions?.map(
        region => ({ path: '/regions/' + region, title: region })
        )
    }
  ]

  if (isAuthenticated) {
    const myWines = { path: '/my-wines', title: capitalize(t('MY_WINES'), true) };
    // const tastings = { path: '/tastings', title: capitalize(t('TASTINGS')) };
    // const collections = { path: '/wine-collections', title: 'Collections' };
    const winesPreferences = { path: '/wine-preferences', title: capitalize(t('WINES_PREFERENCES_LINK')) }
    const recommendationsLink = { path: '/recommendations', title: capitalize(t('RECOMMENDATIONS')) }

    mobileMenuLinks.splice(2,0,recommendationsLink)
    mobileMenuLinks.splice(1,0,myWines)
    // mobileMenuLinks.splice(1,0,tastings)
    // mobileMenuLinks.splice(1,0,collections)
    mobileMenuLinks.push(winesPreferences)
  }

  if (['admin', 'curator', 'retailer'].includes(user.role)) {
    mobileMenuLinks.push({ path: `/${user.role}`, title: 'Admin Panel' });
  }

  const headerMenu = 
  !isMobile ? headerLinks : (
    <MobileMenu
      isOpen={mobileMenuIsOpen}
      onChange={toggleMobileMenuVisibility}
      menuList={mobileMenuLinks}
    >{headerLinks}</MobileMenu>
  )


  return (
    <>
      <header ref={headerRef} id="header" className={classNames({ 
        'transparent': transparent
      })}>
        <div className="container">
          <div className="header-nav">
            <Link to="/">
              <img className="logo" src={logo} alt="logo" />
            </Link>

            {headerMenu}
          </div>
        </div>
      </header>

      {
        user._suav && showNotification &&
        <div className="sa-label shadow">
          <button className="close-modal-btn" onClick={handleHideNotification}>
            <img src={closeIcon} alt="close modal" />
          </button>

          <Alert className="sa-alert">
            <><p>You are logged in as Super Admin</p>
              <button className="button button_xs"
                onClick={handleLogout}

              >Sign Out</button></>
          </Alert>
        </div>
      }
    </>
  )
}


export default Header;
