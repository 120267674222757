import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import './style.scss';


const EditableText = ({ initialText, onConfirmChanges, onChange, className, placeholder, title }) => {
  const [text, changeText] = useState(initialText);
  const [editMode, setEditMode] = useState(false)

  const classNames = ['editable-text-wrapper']
  className && classNames.push(className)

  const handleToggleEditMode = e => {
    e.preventDefault();

    setEditMode(true)
  }

  const handleChangeText = e => {
    const { value } = e.currentTarget;

    onChange && onChange(value)
    changeText(value)
  }

  const handleSaveChanges = e => {
    e.preventDefault();

    onConfirmChanges && onConfirmChanges(text);
    setEditMode(false)
  }


  if (!editMode)
    return (
      <div className={classNames.join(' ')}>
        { title && <h4 className="subtitle">{ title }</h4> }
        <p className={className}>{text?.length > 0 ? text : placeholder}</p>
        <button className="button-link" onClick={handleToggleEditMode}>Edit</button>
      </div>
    )

  if (editMode)
    return (
      <div className={classNames.join(' ')}>
        { title && <h4 className="subtitle">{ title }</h4> }
        <textarea
          rows={4} 
          value={text}
          onChange={handleChangeText}
        />

        <button className="button-link" onClick={handleSaveChanges}>Save Changes</button>
      </div>
    )
}

EditableText.propTypes = {
  title: PropTypes.string,
  initialText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onConfirmChanges: PropTypes.func,
  onChange: PropTypes.func
}

EditableText.defaultProps = {
  initialText: '',
  placeholder: '',
  className: '',
}

export default memo(EditableText)