import React from 'react';
import PropTypes from 'prop-types';

import defaultIcon from '../../assets/icons/no-order.svg'

import './style.scss';
import Icon from '../common/Icon';


const VerificationAlert = ({ icon, title, msg, children, style, containerClass, isSvg, size, color }) => {
    const classes = containerClass + ' no-order'
    return (
    <div className={ classes } style={ style }>
        {
          isSvg ? (
            <Icon icon={ icon } color={ color } size={ size } />
          ) : (
            <img className="no-order__img" src={icon} alt="icon" />
          )
        }
        <div className="no-order__title">{title}</div>
        <p className="no-order__text">{msg}</p>
        {children}
    </div>
    )
}


VerificationAlert.propTypes = {
    title: PropTypes.string,
    msg: PropTypes.string,
    icon: PropTypes.string,
    style: PropTypes.object
}

VerificationAlert.defaultProps = {
    style: {},
    icon: defaultIcon,
    containerClass: ''
}

export default VerificationAlert;
