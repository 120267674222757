import React from 'react';
import PropTypes from 'prop-types';


const DownloadLink = ({ data, title }) => {
    const url = window.URL.createObjectURL(new Blob([
        JSON.stringify(data, null, 2)
    ]));

    return <a className="button-link" href={ url } download="orders.json"><small>{ title }</small></a>
}


DownloadLink.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string
}

DownloadLink.defaultProps = {
    title: 'orders.json'
}

export default DownloadLink
