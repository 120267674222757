import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdminNavLink } from '..';

import verifications from '../../assets/icons/calendar.svg';
import wines from '../../assets/icons/wine-glass.svg';
import orders from '../../assets/icons/box.svg';
import users from '../../assets/icons/group.svg';
import tools from '../../assets/icons/settings.svg';
import logo from '../../assets/icons/logo-h-green-3x.svg';
import grapes from '../../assets/icons/grape-black.svg';
import regions from '../../assets/icons/lng.svg';
import chevronLeftIcon from '../../assets/icons/chevron-left.svg';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useActions } from '../../hooks';


const AdminSidebar = () => {
  const dispatch = useDispatch()
  const { authActions } = useActions()
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const [isHidden, setIsHidden] = useState(false)
  const history = useHistory();

  const handleToggleVisibilityOfSidebar = e => {
    e.preventDefault();

    setIsHidden(!isHidden)
  }

  const handleLogout = e => {
    e.preventDefault();

    // logout(history)
    dispatch(authActions.logout('/login'))
  }

  const activePath = pathname.replace('/admin/', '');

  const adminLinks = [
    { name: 'Orders', icon: orders, path: '/admin/orders', active: activePath === 'orders' },
    { name: 'Wines', icon: wines, path: '/admin/wines', active: activePath === 'wines' },
    { name: 'Cases', icon: orders, path: '/admin/cases', active: activePath === 'cases' },
    { name: 'Users', icon: users, path: '/admin/users', active: activePath === 'users' },
    { name: 'Grapes', icon: grapes, path: '/admin/grapes', active: activePath === 'grapes' },
    { name: 'Labels', icon: wines, path: '/admin/labels', active: activePath === 'labels' },
    { name: 'Tastes', icon: wines, path: '/admin/tastes', active: activePath === 'tastes' },
    { name: 'Regions', icon: regions, path: '/admin/regions', active: activePath === 'regions' },
    { name: 'Curators', icon: users, path: '/admin/curators', active: activePath.includes('curators') },
    { name: 'Producers', icon: users, path: '/admin/producers', active: activePath.includes('producers') },
    { name: 'Retailers', icon: users, path: '/admin/retailers', active: activePath.includes('retailers') },
    { name: 'Reports', icon: verifications, path: '/admin/reports/', active: activePath.includes('reports') },
    { name: 'Verifications', icon: verifications, path: '/admin/verifications', active: activePath === 'verifications' },
    { name: 'Tools', icon: tools, path: '/admin/tools', active: activePath.includes('tools') },
    { name: 'Warehouses', icon: orders, path: '/admin/warehouses', active: activePath.includes('warehouses') },
  ].map(
    linkData => {
      if (linkData.name === 'tools' && ![1, 2].includes(user.id)) return null;

      return <AdminNavLink key={linkData.name} {...linkData} />
    }
  );


  return (
    <>
      <button className="button button_outline button_xs collapse-sidebar-button"
        onClick={handleToggleVisibilityOfSidebar}
      >{isHidden ? <>Show&nbsp;Sidebar</> : <>Hide&nbsp;Sidebar</>}</button>

      <div className={classNames('admin-sidebar', { 'collapsed': isHidden })}>
        <ul className="admin-nav">
          <li className="admin-nav__logo">
            {/* <Link to="/home"> */}
            <Link to="/">
              <img className="no-margin" src={logo} alt="Vineify logo" height="50" />

              <br />

              <span><img src={chevronLeftIcon} alt="return to home page" />
                Go Back to Home Page</span>
            </Link>

            <br />

            <button
              className="button-link no-padding no-margin text-left"
              onClick={handleLogout}
            >Sign Out</button>
          </li>

          {adminLinks}

          <li className="admin-nav__item" style={{ marginTop: '32px' }}>
            <button
              className="admin-nav__link button-link no-padding no-margin text-lg text-left"
              onClick={handleLogout}
            >Sign Out</button>
          </li>
        </ul>
      </div>
    </>
  )
}


export default AdminSidebar;
