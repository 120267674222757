import React from 'react';

import './style.scss';


const AdminHeader = () => {
    return (
        <header className="admin-header">
            
        </header>
    )
}

export default AdminHeader
