import React from 'react'

import photoStefano from '../../../assets/images/home-section-stefano.jpg'
import photoJose from '../../../assets/images/public-home-jose.jpg'


const PublicHomeCuratorCard = ({ key, name, country, photo, spec, desc }) => {


  return (
    <div className="public-home__curator-card" key={key}>
      <img src={photo} alt={name} />

      <div className="public-home__curator-info">
        <p className="public-home__curator-name">{name}</p>

        <p className="public-home__curator-spec">{spec}</p>

        <p className="public-home__curator-desc">{desc}</p>
      </div>
    </div>
  )
}


const PublicHomeCurators = () => {
  // const {isMobile} = useMediaQuery()
  const curatorsList = [
    { 
      name: 'José', 
      country: 'Spain',
      spec: `WSET Level 4 & importer`,
      desc: `Seeks the best little winemakers unknown to the public`,
      // photo: isMobile ? photoJoseMobile : photoJose 
      photo: photoJose 
    },
    { 
      name: 'Stefano', 
      country: 'Italy',
      spec: `Professional sommelier & winemaker`,
      desc: `Passionate for the purest and most interesting wines`,
      // photo: isMobile ? photoStefanoMobile : photoStefano 
      photo: photoStefano 
    },
  ]

  // const curatorsCards = curatorsList.map(curatorData => (
  //   <PublicHomeCuratorCard key={`ph-curator-${curatorData.name}`} {...curatorData} />
  // ))


  return (
    <div className="public-home__section curators-section">
      {/* <div className="container">
        <h2 className="public-home__title">Meet Our Curators</h2>

        <p className="public-home__section-description">Our wine experts live in and know their regions. They chose the wines that best represent these regions so you can get to know the grapes and producers that make each so special.</p>
      </div> */}
      
      <div className="public-home__curators-list">
        <PublicHomeCuratorCard {...curatorsList[0]} />

        <div className="curators-list__info">
          <h3 className="info-title">Meet The <b>Curators</b></h3>

          <p className="info-description">Our experts live in and know their regions. They choose the wines that best represent what makes each region so special.</p>
        </div>

        <PublicHomeCuratorCard {...curatorsList[1]} />
      </div>

    </div>
  )
}


export default PublicHomeCurators
