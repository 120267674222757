import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'


const RegionsTagsList = ({ regions, wrapperClassName }) => {
  const classNames = ['regions-tags-list']
  wrapperClassName && classNames.push(wrapperClassName)

  const regionsContent = regions?.map(region => <li className="regions-tags-list__region" key={region}>{region}</li>)

  return (
    <ul className={classNames.join(' ')}>{regionsContent}</ul>
  )
}


RegionsTagsList.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.string)
}

export default RegionsTagsList
