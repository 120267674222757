import axios from 'axios';
import ApiClient from '../api'

const WINE_BASE_URL = `/api/wines`
const WineClient = new ApiClient(WINE_BASE_URL);

// get({ url: `/api/wines/cases?region=${regionName}&lng=${i18n.language}&new=2` }),

export const getListOfRegionGrapes = async (regionName, grapesCategories) => {
  const response = await WineClient.get({ url: `/grapes?lng=en&region=${regionName}&cat=${grapesCategories}` })

  if (response?.success) {
    return response?.grapes
  }

  return []
}


export const getListOfCases = async regionName => {
  const response = await WineClient.get({ url: `/cases?region=${regionName}&lng=en&new=2` })

  if (response?.success) {
    return response?.cases
  }

  return []
}

export const fetchUserFavouriteWinesList = async () => {
  const response = await axios.get(`/api/wines/favourites`);

  if (response?.data?.success) {
    return response.data.favouriteWines;
  }

  return []
}

// regions -> array of regions ids
export const fetchWinesList = async ({ language, warehouseId = 2, regions = [] }) => {
  const regionsQueryString = regions?.length > 0 ? `&regs=${regions}` : ``;
  const apiEndpoint = `/api/wines?ins=1&lng=${language}&wh=${warehouseId}${regionsQueryString}`

  const response = await axios.get(apiEndpoint)

  if (response?.data?.success) {
    //TODO in wines actions: winesList.map(wine => ({ ...wine, isFavourite: favouriteWines?.includes(wine.id) }))

    return response.data.winesList
  }

  return []
}

export const fetchCurrentUserOrderedWines = async ({ language = 'en' }) => {
  const response = await axios.get(`/api/orders/wines?lng=${language}&new=2`);

  if (response && response.data.success) {
    const { wines } = response.data;

    const winesList = wines
      .sort(({ isAdjusted: adjusted }, { isAdjusted: prevAdjusted }) => +prevAdjusted - +adjusted)
      .map(
        wine => ({
          ...wine,
          isFavorite: !!wine.isFavorite,
          amountBottlesDrunk: wine.amountBottlesDrunk || 0,
          feedback: { alertIsVisible: false, alertContent: '' }
        })
      )

    return winesList;
  }

  return [];
}

export const createOrderByWineId = async wineId => {
  const response = await axios.get(`/api/orders/case-data/${wineId}`);

  return response?.data?.success;
}

export const updateUserWineStatistics = async ({ wineId, amountBottlesDrunk, willRecomend, isFavourite, wines }) => {
  const endpoint = wineId ? `/api/wines/${wineId}/update-statistic` : `/api/wines/update-statistics`;

  const response = await axios.post(
    endpoint,
    { amountBottlesDrunk, willRecomend, isFavourite, wines }
  );

  return response?.data?.userWine ? { ...response.data.userWine, wineId } : { wineId };
}


export const getWineCasesPreviews = async ({ slug, regionId }) => {
  const response = await axios.get(`/api/wines/${slug}/wine-cases-previews${regionId ? `?rid=${regionId}` : ``}`)

  if (response?.data?.success) {
    // const { cases = [], regionCases = [] } = response.data;

    return response.data
  }
}

export const getWinesPreviewList = async ({ regionId, producer, warehouse }) => {
  const queryArray = [] // warehouse id
  warehouse && queryArray.push(`wid=${warehouse}`)
  producer && queryArray.push(`prod=${producer}`)
  regionId && queryArray.push(`rid=${regionId}`)

  const query = queryArray.length > 0 ? '?' + queryArray.join('&') : ''

  const response = await axios.get(`/api/wines/preview-list${query}`)

  if (response?.data?.success) {
    return response.data.winesList || []
  }

  return undefined;
}

export const getWinePreview = async slug => {
  const response = await axios.get(`/api/wines/${slug}/wine-preview`)

  if (response?.data?.success) {
    return response.data.wineData
  }

  return response;
}

export const fetchWinesCatalog = async (language = 'en') => {
  const response = await axios.get(`/api/wines/catalog?ac=1&lng=${language}&new=2`)

  if (response?.data?.success) {
    return response.data.winesList
  }

  return []
}

export const fetchWineData = async ({wineId, active}) => {
  const query = [`new=2`]
  const queryString = query.join('&')

  active && query.push(`active=${active}`)
  const response = await axios.get(`/api/wines/catalog/${wineId}?${queryString}`)

  if (response?.data?.success) {
    return response.data.wine
  }
}

export const fetchWineSalesReport = async (wineId) => {
  const response = await axios.get(`/api/wines/sales-report?w=${wineId}`)

  if (response?.data?.success) {
    return response.data?.wines?.[0]
  }

  return undefined;
}

export const fetchWineLabels = async () => {
  const response = await axios.get(`/api/wines/labels`)

  if (response?.data?.success) {
    return response.data.labels
  }
}

export const createNewLabel = async text => {
  const response = await axios.post(`/api/wines/labels`, { text })

  if (response?.data?.success) {
    return response.data.newLabel
  }

  return undefined;
}

export const updateLabel = async ({ id, text }) => {
  await axios.patch(`/api/wines/labels/${id}`, { text })
}

export const deleteLabel = async (id) => {
  const response = await axios.delete(`/api/wines/labels/${id}`)

  return response?.data?.success;
}

export const fetchWineRestockHistory = async wineId => {
  const response = await axios.get(`/api/wines/restock-history?w=${wineId}`)

  if (response?.data?.success) {
    return response.data.history
  }

  return []
}

// const wineCasesRequest = get({ url: `/api/wines/${wineId}/wine-cases` });
//     const checkCouldBeDeletedRequest = get({ url: `/api/wines/get-orphans` });

export const fetchWineCasesList = async wineId => {
  const response = await axios.get(`/api/wines/${wineId}/wine-cases`)

  if (response?.data?.success) {
    return response.data.cases
  }

  return []
}

export const fetchWineOrphansList = async wineId => {
  const response = await axios.get(`/api/wines/get-orphans`)

  if (response?.data?.success) {
    return response.data.wines
  }

  return []
}
