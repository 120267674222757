import React from 'react'
import PropTypes from 'prop-types'
import { v4 as keyGen } from 'uuid'
import { useHelpers } from '../../hooks'


const WinePriceHistory = ({ data: priceChangeHistory }) => {
  const { formatDateTime } = useHelpers();
  const isMobile = window.matchMedia('(max-width: 520px)').matches;


  const priceChangeHistoryContent = priceChangeHistory.length ? priceChangeHistory.map(
    ({ old_price_usd: oldPrice, price_usd: newPrice, createdAt: date, user, action }) => (
      <li key={keyGen()} className="report-row">
        <span className="report-item date">{isMobile && <b>Date: </b>}{formatDateTime(date, false)}</span>
        <span className="report-item action">{isMobile && <b>Action: </b>}{action}</span>
        <span className="report-item user">{isMobile && <b>User: </b>}
          {user.firstName && user.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : ` - `}
        </span>
        <span className="report-item price">{isMobile && <b>Previous: </b>}${oldPrice}</span>
        <span className="report-item price">{isMobile && <b>Updated: </b>}${newPrice}</span>
      </li>
    )
  ) : (
    <li className="report-item no-history">Pricing history is empty...</li>
  );



  return (
    <div className="report-content">
      <ul className="admin-report-header sales-list wine-price-history-report">
        {
          !isMobile && <li className="report-row">
            <span className="report-item date">Date</span>

            <span className="report-item action">Action</span>

            <span className="report-item user">User</span>

            <span className="report-item price" title="Stock before value changed">Previous Price</span>

            <span className="report-item price">Updated Price</span>
          </li>
        }
        {
          isMobile && <span className="report-item no-history">Details</span>
        }
      </ul>

      <ul className="sales-list wine-price-history-report">{priceChangeHistoryContent}</ul>
    </div>
  )
}

WinePriceHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}

export default WinePriceHistory