import React, { useContext } from 'react';
import { CheckoutContext } from '../../context';

import './style.scss';
import SquareSteps from './SquareSteps';
import PaypalSteps from './PaypalSteps';


const Steps = () => {
  const { paymentMethod } = useContext(CheckoutContext);

  return paymentMethod === 'paypal' ? <PaypalSteps /> : <SquareSteps />
}


export default Steps;
