import React from 'react';
import PropTypes from 'prop-types';

import locationIcon from '../../assets/icons/map-pin-line.svg'
import { useHelpers } from '../../hooks';
import ShortDescription from '../ShortDescription';

import './style.scss';


const AdminWineCardPopover = ({ image, name, quantity, vintage, producer, country, grape, description, tastes, category, region, sortOfGrape }) => {
  const { getWineName } = useHelpers();

  const grapeClass = `wine-grape grape_${category}`;
  const wineLocation = country?.toLowerCase() === region?.toLowerCase()
    ? ` ${region}` : ` ${region}, ${country}`

  return (
    <div className="card wine_card wine_admin-data">
      <div className="card__header">
        {
          image && <img src={image} alt={name} />
        }

        {!!quantity && <div className="badge">{'x' + quantity}</div>}
        <p className="wine__name">{getWineName({ vintage, producer, name })}</p>
      </div>

      <div className="card__body">

        <p className="wine__region no-margin">
          <img src={locationIcon} alt="location" />
          {wineLocation}
        </p>

        <div className="wine-space-between">
          <span className={grapeClass} style={{ justifyContent: 'flex-start !important' }}>{sortOfGrape}</span>
        </div>

        <div className="wine-additional-data">
          <ShortDescription expandable={true} lines={5}>
            <p>{description}</p>
          </ShortDescription>

          {
            tastes?.length > 0 &&
            <ul className="wine-tastes">{
              tastes.map(t => <li key={t}>{t}</li>)
            }</ul>
          }
        </div>
      </div>

    </div>
  )
}

AdminWineCardPopover.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.number,
  vintage: PropTypes.string,
  producer: PropTypes.string,
  regionName: PropTypes.string,
  country: PropTypes.string,
  grape: PropTypes.string,
  description: PropTypes.string,
  tastes: PropTypes.arrayOf(PropTypes.string)
}

export default AdminWineCardPopover