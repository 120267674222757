// eslint-disable-next-line
import { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ModalProvider } from 'react-simple-hook-modal';
import { CheckoutProvider } from '../context';

import '../pages/admin/style.scss'
import PublicHomePage from '../pages/home/PublicHomePage'
import NewPublicHomePage from '../pages/home/NewPublicHomePage'
import SixWinesPackHomepage from '../pages/home/SixWinesPackHomepage'
import UnsubscribePage from '../pages/common/UnsubscribePage';
import WithOrderData from '../components/hoc/withOrderData';
import {
  Steps, OrderSidebar, PrivateRoute, AdminRoute, ManagerRoute, GuestRoute, LoadingScreen, AdminSidebar, AdminHeader, WarehousesNavigation, CuratorRoute, RetailerRoute, RetailerNavigation, ReferFriendBar, WaitListInvitationBar
} from '../components';


import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import SerendipityWinesIntegrationProvider from '../context/serendipity-wines-integration/SerendipityWinesIntegrationProvider';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Header = lazy(() => import('../components/header'));
const Footer = lazy(() => import('../components/footer/Footer'));
const TrendingWineSignupPage = lazy(() => import('../pages/home/TrendingWineSignupPage'));
const ProducerListPage = lazy(() => import('../pages/producers/ProducerListPage'));
const ProducerDataPage = lazy(() => import('../pages/producers/ProducerDataPage'));
const AdminProducersList = lazy(() => import('../pages/admin/AdminProducersList'));
const AdminProducerDataPage = lazy(() => import('../pages/admin/AdminProducerDataPage'));
const CuratorPublicDataPage = lazy(() => import('../pages/curator/CuratorPublicDataPage'))
const CuratorsPublicListPage = lazy(() => import('../pages/curator/CuratorsPublicListPage'))

const AdminCasesOrderList = lazy(() => import('../pages/admin/AdminCasesOrderList'))
const AdminCasesList = lazy(() => import('../pages/admin/AdminCasesList'));
const CuratorCasesList = lazy(() => import('../pages/curator/CuratorCasesList'));
const CuratorOrdersPage = lazy(() => import('../pages/curator/CuratorOrdersPage'));
const CuratorProfilePage = lazy(() => import('../pages/curator/CuratorProfilePage'));
const CuratorCasePage = lazy(() => import('../pages/curator/CuratorCasePage'));
const CuratorWinePage = lazy(() => import('../pages/curator/CuratorWinePage'));
const RetailerDashboard = lazy(() => import('../pages/retailer/RetailerDashboard'));
const RetailerContactDetails = lazy(() => import('../pages/retailer/RetailerContactDetails'));
const AdminWarehousePage = lazy(() => import('../pages/admin/AdminWarehousePage'));
const AdminCasePage = lazy(() => import('../pages/admin/AdminCasePage'));
const AdminReportsPage = lazy(() => import('../pages/admin/AdminReportsPage'));
const AdminCaseDataPage = lazy(() => import('../pages/admin/AdminCaseDataPage'));
const AdminUtilsPage = lazy(() => import('../pages/admin/AdminUtilsPage'));
const AdminWineDataPage = lazy(() => import('../pages/admin/AdminWineDataPage'));
const AdminUserPage = lazy(() => import('../pages/admin/AdminUserPage'));

const Sitemap = lazy(() => import('../pages/root/Sitemap'));
const WineSelectionsPage = lazy(() => import('../pages/cases/WineSelectionsPage'));
const NewCollections = lazy(() => import('../pages/cases/NewCollections'));
const WineDataPage = lazy(() => import('../pages/wines/WineDataPage'))
const WinesListPage = lazy(() => import('../pages/wines/WinesListPage'))
const CartSection = lazy(() => import('../pages/order/sections/CartSection'));
const SuccessPaymentInfo = lazy(() => import('../pages/order/sections/SuccessPaymentInfo'));
const AdminVerificationsList = lazy(() => import('../pages/admin/AdminVerificationsList'));
const AdminWinesList = lazy(() => import('../pages/admin/AdminWinesList'));
const AdminWinePage = lazy(() => import('../pages/admin/AdminWinePage'));
const CreateAccount = lazy(() => import('../pages/wait-list/sections/CreateAccount'));
const LoginPage = lazy(() => import('../pages/wait-list/sections/LoginPage'));
const CheckAge = lazy(() => import('../pages/wait-list/sections/CheckAge'));
const UnderageAccessDeny = lazy(() => import('../pages/wait-list/sections/UnderageAccessDeny'));
const VerificationPage = lazy(() => import('../pages/wait-list/sections/VerificationPage'));
const ResetPasswordPage = lazy(() => import('../pages/wait-list/sections/PasswordReset'));
// const MainSection = lazy(() => import('../pages/wait-list/sections/NewMainSection'));
const RegionsListPage = lazy(() => import('../pages/region/RegionsListPage'));
const Nav = lazy(() => import('../pages/account/sections/Navigation'));
const CuratorNavigation = lazy(() => import('../pages/curator/sections/CuratorNavigation'));
const AccountOrders = lazy(() => import('../pages/account/sections/AccountOrders'));
const AccountPreferences = lazy(() => import('../pages/account/sections/AccountPreferences'));
const AccountSettings = lazy(() => import('../pages/account/sections/AccountSettings'));
const FaqPage = lazy(() => import('../pages/home/sections/FaqSection'));
const ContactUs = lazy(() => import('../pages/home/sections/ContactUs'));
const RecommendationView = lazy(() => import('../pages/recommendations/CatchRecommendation'));
const NewTastings = lazy(() => import('../pages/home/sections/NewTastings'));
const TermsOfUse = lazy(() => import('../pages/common/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('../pages/common/PrivacyPolicy'));
const AdminOrdersList = lazy(() => import('../pages/admin/AdminOrdersList'));
const NewAdminOrderPage = lazy(() => import('../pages/admin/NewAdminOrderPage'));
const NotFoundPage = lazy(() => import('../pages/common/NotFoundPage'));
const AccountWinesPage = lazy(() => import('../pages/account/sections/AccountWines'));
const RegionPage = lazy(() => import('../pages/region'));
const AdminUsersList = lazy(() => import('../pages/admin/AdminUsersList'));
const RegisterFooter = lazy(() => import('../components/footer/RegisterFooter'));
const RecommendationsPage = lazy(() => import('../pages/recommendations'));
const CustomCasePage = lazy(() => import('../pages/cases/CustomCasePage'));
const CasesListPage = lazy(() => import('../pages/cases/CasesList'));
const CustomStandartCasePage = lazy(() => import('../pages/cases/CustomStandartCasePage'));
const AdminGrapesList = lazy(() => import('../pages/admin/AdminGrapesList'));
const AdminGrapeDataPage = lazy(() => import('../pages/admin/AdminGrapeDataPage'));
const AdminRegionsList = lazy(() => import('../pages/admin/AdminRegionsList'));
const AdminRegionsDataPage = lazy(() => import('../pages/admin/AdminRegionDataPage'));
const AdminTastesList = lazy(() => import('../pages/admin/AdminTastesList'));
const AdminWineLabels = lazy(() => import('../pages/admin/AdminWineLabels'));
const AdminCuratorsList = lazy(() => import('../pages/admin/AdminCuratorsList'));
const AdminCuratorsDataPage = lazy(() => import('../pages/admin/AdminCuratorsDataPage'));
const AdminRetailersList = lazy(() => import('../pages/admin/AdminRetailersList'));
const AdminRetailerDataPage = lazy(() => import('../pages/admin/AdminRetailerDataPage'));
const BlogPostsList = lazy(() => import('../pages/blog/PostsList'));
const BlogCategory = lazy(() => import('../pages/blog/BlogCategory'));
const PostPage = lazy(() => import('../pages/blog/PostPage'));
const CasePage = lazy(() => import('../pages/cases/CasePage'));
const QuizPage = lazy(() => import('../pages/quiz'));
const WarehouseWinesIntegration = lazy(() => import('../pages/admin/WarehouseWinesIntegration'));
const AddToCart = lazy(() => import('../pages/order/AddToCart'));
const AiPage = lazy(() => import('../pages/ai/AiPage'))
const AdminAiLogsList = lazy(() => import('../pages/admin/AdminAiLogsList'))


const useRoutes = (isAuthenticated, isAdult, paypalId, currency) => {

  const paypalOptions = {
    "client-id": paypalId,
    commit: false,
    currency: 'USD'
  }

  const routesWithoutNotification = ['reset-password', '/create-account', '/login', '/curator', '/admin', '/check-age', '/access-denied', '/new-six-packs', 'new-homepage', 'trending-wine-signup']

  const CartSteps = () => {

    return (
      <PayPalScriptProvider deferLoading={true} options={{ ...paypalOptions }} >
        <CheckoutProvider>
          <div className="container container_with-sidebar">
            <div className="section">
              <WithOrderData>
                <div className="section__part">
                  {isAuthenticated && <Steps />}

                  <Switch>
                    <Route exact path="/cart/payment" component={CartSection} />
                    <Route exact path="/cart/shipping" component={CartSection} />
                    <PrivateRoute exact path="/cart/billing" component={CartSection} />
                    <PrivateRoute exact path="/cart/confirm" component={CartSection} />
                    <PrivateRoute exact path="/cart/success" component={SuccessPaymentInfo} />
                    <Route path="/cart"><Redirect to="/cart/payment" /></Route>
                  </Switch>
                </div>

                <div className="section__part"><OrderSidebar /></div>
              </WithOrderData>
            </div>
          </div>
        </CheckoutProvider>
      </PayPalScriptProvider>
    )
  }


  const AccountSections = () => {

    return (
      <div className="container">
        <Route path="/account" component={Nav} />
        <div className="account-content">
          <Switch>
            <Route exact path="/account/orders" component={AccountOrders} />
            <Route exact path="/account/preferences" component={AccountPreferences} />
            <Route exact path="/account/settings" component={AccountSettings} />
            <Route path="/account"><Redirect to="/account/orders" /></Route>
          </Switch>
        </div>
      </div>
    )
  }


  const ManagerSections = () => {
    return (
      <>
        <Route path="/inventory" component={WarehousesNavigation} />

        <div className="warehouses-content">
          <Switch>
            <Route exact path="/inventory/wines" render={() => <AdminWinesList />} />
            <Route exact path="/inventory/orders" render={() => <AdminOrdersList />} />
            <Route exact path="/inventory/orders/:orderId" render={() => <NewAdminOrderPage />} />
            <Redirect to="/inventory/orders" />
          </Switch>
        </div>
      </>
    )
  }

  const RetailerSection = () => {

    return (
      <>
        <Route path="/retailer" component={RetailerNavigation} />

        <div className="retailer-content">
          <Switch>
            <Route exact path="/retailer/integration" render={() => <RetailerDashboard />} />
            {/* {/* <Route exact path="/retailer/orders" render={() => <AdminOrdersList />} /> */}
            <Route exact path="/retailer/contacts" render={() => <RetailerContactDetails />} />
            <Route exact path="/retailer/orders" render={() => <AdminOrdersList />} />
            <Route exact path="/retailer/orders/:orderId" render={() => <NewAdminOrderPage />} />
            <Route exact path="/retailer/wines" render={() => <AdminWinesList controls={false} />} />
            <Route exact path="/retailer/wines/:wineId" render={() => <AdminWinePage />} />
            <Redirect to="/retailer/orders" />
          </Switch>
        </div>
      </>
    )
  }


  const CuratorSection = () => {

    return (
      <>
        <Route path="/curator" component={CuratorNavigation} />

        <div className="curator-content">
          <Switch>
            <CuratorRoute exact path="/curator/cases" component={CuratorCasesList} />
            <CuratorRoute exact path="/curator/cases/case-data" component={AdminCaseDataPage} />
            <CuratorRoute exact path="/curator/cases/case-data/:caseId" component={AdminCaseDataPage} />
            <CuratorRoute exact path="/curator/cases/:caseId" component={CuratorCasePage} />
            <CuratorRoute exact path="/curator/wines" component={AdminWinesList} />
            <CuratorRoute exact path="/curator/wines/:wineId" component={CuratorWinePage} />
            <CuratorRoute main exact path="/curator/tastes" component={AdminTastesList} />
            <CuratorRoute main exact path="/curator/grapes" component={AdminGrapesList}/>
            <CuratorRoute exact path="/curator/orders" component={CuratorOrdersPage} />
            <CuratorRoute exact path="/curator/profile" component={CuratorProfilePage}/>
            <CuratorRoute main exact path="/curator/grapes/grape-data" component={AdminGrapeDataPage} />
            <CuratorRoute main exact path="/curator/grapes/:grapeId/grape-data" component={AdminGrapeDataPage} />
            <Redirect to="/curator/cases" />
          </Switch>
        </div>
      </>
    )
  }


  const AdminSections = () => {

    return (
      <>
        <AdminHeader />
        <div className="admin-container">
          <div className="admin-content-wrapper">
            <AdminSidebar />
            <div className="admin-content">
              <Switch>
                <Route exact path="/admin/verifications" component={AdminVerificationsList} />
                <Route exact path="/admin/ai-logs" component={AdminAiLogsList} />
                <Route exact path="/admin/wines" render={() => <AdminWinesList controls={true} />} />
                <Route exact path="/admin/wines/manage/" component={AdminWineDataPage} />
                <Route exact path="/admin/tastes" component={AdminTastesList} />
                <Route exact path="/admin/labels" component={AdminWineLabels} />
                <Route exact path="/admin/producers" component={AdminProducersList} />
                <Route exact path="/admin/producers/manage-data" component={AdminProducerDataPage} />
                <Route exact path="/admin/producers/manage-data/:producerId" component={AdminProducerDataPage} />
                <Route exact path="/admin/curators" component={AdminCuratorsList} />
                <Route exact path="/admin/curators/create" component={AdminCuratorsDataPage} />
                <Route exact path="/admin/curators/:curatorId" component={AdminCuratorsDataPage} />
                <Route exact path="/admin/retailers" component={AdminRetailersList} />
                <Route exact path="/admin/retailers/data" component={AdminRetailerDataPage} />
                <Route path="/admin/retailers/:retailerId/data" component={AdminRetailerDataPage} />
                <Route exact path="/admin/grapes" component={AdminGrapesList} />
                <Route exact path="/admin/grapes/grape-data" component={AdminGrapeDataPage} />
                <Route exact path="/admin/grapes/:grapeId/grape-data" component={AdminGrapeDataPage} />
                <Route exact path="/admin/wines/manage/:wineId" component={AdminWineDataPage} />
                <Route exact path="/admin/wines/:wineId/" component={AdminWinePage} />
                <Route exact path="/admin/orders" render={() => <AdminOrdersList controls={true} />} />
                <Route exact path="/admin/orders/:orderId" render={() => <NewAdminOrderPage controls={true} />} />
                <Route exact path="/admin/users" component={AdminUsersList} />
                <Route exact path="/admin/regions/region-data-form" component={AdminRegionsDataPage} />
                <Route exact path="/admin/regions/:regionId/region-data-form" component={AdminRegionsDataPage} />
                <Route exact path="/admin/regions" component={AdminRegionsList} />
                <Route exact path="/admin/users/:userId" component={AdminUserPage} />
                <Route exact path="/admin/wines-curation" render={
                  () => (<SerendipityWinesIntegrationProvider>
                    <WarehouseWinesIntegration />
                  </SerendipityWinesIntegrationProvider>)
                } />
                <Route exact path="/admin/warehouses" render={() => <AdminWarehousePage />} />
                <Route exact path="/admin/warehouses/:warehouseId" component={AdminWarehousePage} />
                <Route exact path="/admin/cases" component={AdminCasesList} />
                <Route exact path="/admin/cases/order" component={AdminCasesOrderList} />
                <Route exact path="/admin/cases/:caseId/case-data" component={AdminCaseDataPage} />
                <Route exact path="/admin/cases/case-data" component={AdminCaseDataPage} />
                <Route exact path="/admin/cases/:caseId" component={AdminCasePage} />
                <Route path="/admin/reports/" component={AdminReportsPage} />
                <Route path="/admin/tools" component={AdminUtilsPage} />
                <Route path="/admin" ><Redirect to="/admin/orders" /></Route>
              </Switch>
            </div>
          </div>
        </div>
      </>
    )

  }


  return (
    <>
      <Suspense fallback={LoadingScreen}>
        <ModalProvider backdropClassName="modal-wrapper">
          <Switch>
            <Route path="/admin" component={null} />
            <Route render={
              ({ location }) => [
                '/check-age', '/access-denied', '/new-six-packs', '/new-homepage', '/old-homepage'
              ].some(path => location.pathname.includes(path))
                || '/' === location.pathname
                ? null : <Header />
            } />
          </Switch>
          <Route render={
            ({ location }) => routesWithoutNotification
              .some(path => location.pathname.includes(path)) 
              || '/' === location.pathname
                  ? null 
                  : isAuthenticated 
                    ? <ReferFriendBar /> 
                    : <WaitListInvitationBar />}
          />

          <Switch>
            <Route exact path="/sitemap" component={Sitemap} />
            <Route exact path="/trending-wine-signup" component={TrendingWineSignupPage} />
            <Route exact path="/ai" component={AiPage} />
            <Route exact path="/collections/:label" component={NewCollections} />
            <Route exact path="/wine-collections" component={WineSelectionsPage} />
            <Route exact path="/wine-preferences" component={QuizPage} />
            <Route exact path="/check-age" component={CheckAge} />
            <Route exact path="/new-six-packs" component={SixWinesPackHomepage} />
            <Route exact path="/terms" component={TermsOfUse} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/unsubscribe" component={UnsubscribePage} />
            <Route path="/reset-password/:verification" component={ResetPasswordPage} />
            <Route path="/reset-password/" component={ResetPasswordPage} />
            <Route exact path="/verification" component={VerificationPage} />
            <Route exact path="/create-account" title="Create Account Page" track={true} component={CreateAccount} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/access-denied" component={UnderageAccessDeny} />
            {/* <Route exact path="/create-account" component={MainSection} /> */}
            <Route exact path="/faq" component={FaqPage} />
            {/* <PrivateRoute exact path="/tastings" title="View Tastings" track={true} component={NewTastings} /> */}
            <PrivateRoute exact path="/tastings" title="View Tastings" track={true} component={NewTastings} />
            <Route exact path="/your-wines" render={
              () => <Redirect to="/my-wines" />
            } />
            <PrivateRoute exact path="/my-wines" title="View My Wines" track={true} component={AccountWinesPage} />
            {/* <Route exact path="/home" render={() => <Redirect to="/" />} /> */}
            <Route exact path="/" title="View Home Page" track={true} component={NewPublicHomePage} />
            <AdminRoute exact path="/old-homepage" title="View Home Page" track={true} component={PublicHomePage} />
            <Route exact path="/recommendations/view/:stats" component={RecommendationView} />
            <PrivateRoute exact path="/blog/categories/:categoryName" component={BlogCategory} />
            <PrivateRoute exact path="/blog/posts" component={BlogPostsList} />
            <PrivateRoute exact path="/blog/posts/:slug" component={PostPage} />
            <PrivateRoute exact path="/recommendations" title="View Recommendations Page" track={true} component={RecommendationsPage} />
            <Route exact path="/cases" component={CasesListPage} />
            <PrivateRoute exact path="/cases/custom" component={CustomCasePage} />
            <Route exact path="/producers" component={ProducerListPage} />
            <Route exact path="/producers/:slug" component={ProducerDataPage} />
            <Route exact path="/cases/:caseId" component={CasePage} />
            <Route exact path="/wines" component={WinesListPage} />
            <AdminRoute exact path="/wines/preview" component={WineDataPage} />
            <Route exact path="/wines/:slug" component={WineDataPage} />
            <Route exact path="/cases/:caseId/adjust" component={CustomStandartCasePage} />
            {/* <PrivateRoute exact path="/regions/:regionName" component={RegionPage} /> */}
            <Route exact path="/regions" component={RegionsListPage} />
            <Route exact path="/regions/:regionName" component={RegionPage} />
            <Route exact path="/cart/add/:caseId" component={AddToCart} />
            <Route path="/cart" component={CartSteps} />
            <PrivateRoute path="/account" title="Account Page" track={true} component={AccountSections} />
            <AdminRoute path="/admin" component={AdminSections} />
            <ManagerRoute path="/inventory" component={ManagerSections} />
            <Route exact path="/curators" component={CuratorsPublicListPage} />
            <Route exact path="/curators/preview" component={CuratorPublicDataPage} />
            <Route exact path="/curators/:slug" component={CuratorPublicDataPage} />
            <CuratorRoute path="/curator" component={CuratorSection} />
            <RetailerRoute path="/retailer" component={RetailerSection} />
            <Route path="/feedback" component={ContactUs} />
            <Route component={NotFoundPage} />
          </Switch>

          <ToastContainer position="bottom-right" limit={1} />
          <Switch>
            <Route path="/login" component={Footer} />
            <Route path="/create-account" component={Footer} />
            <Route render={
              ({ location }) => !['/check-age', '/access-denied', '/admin'].includes(location.pathname)
                ? <RegisterFooter /> : null
            } />
            {/* <Route component={isAuthenticated && isAdult ? RegisterFooter : Footer} /> */}
          </Switch>
        </ModalProvider>
      </Suspense>
    </>
  );

}


export default useRoutes;
