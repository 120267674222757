import React, { useMemo, useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-simple-hook-modal'
// import { useStoreWines } from '../../hooks'
import { AddLabelForm, Alert } from '..'

import './style.scss'


const LabelsControlPanel = ({ allLabelsList, labels, onChangeLabels, title }) => {
  const [managingLabel, changeManagingLabel] = useState()
  

  const closeModal = () => {
    changeManagingLabel()
  }
  const openModal = e => {
    e.preventDefault();

    changeManagingLabel({})
  }

  const notUsedLabels = allLabelsList
    .slice()
    .filter(labelData => labels.findIndex(({ id }) => labelData.id === id) < 0)

  const handleToggleLabelStatus = labelData => e => {
    e.preventDefault();

    changeManagingLabel(labelData)
  }

  const handleSubmitChanges = (newData, oldData) => {
    const { id, status, text } = newData || {}
    // 'edit' : 'add' => action
    if (oldData?.id) {
      const newLabels = !id 
      ? labels.filter(({ id }) => id !== oldData.id)
        : labels 
          ? labels.map(label => {
            return label.id === oldData.id
              ? { id, text, status } : label
          }) : [{ id, text, status }]

      onChangeLabels(newLabels)
    } else {
      onChangeLabels([...labels, { id, status, text }])
    }

    closeModal()
  }


  const labelsContent = useMemo(() => {
    // if (labels?.length === 0) {
    //   return (
    //     <Alert
    //       title="No Labels"
    //       text={`There is no labels. Press "Add New Label" button to choose label from the list.`}
    //       className="alert-info"
    //     />
    //   )
    // }

    if (labels?.length === 0) return null;

    const labelsItems = labels.map((labelData, index) => {
      const { id, text, status } = labelData;
      const labelClassnames = ['label-pill', status]

      return (
        <button key={`${id}-label-${text}`}
          className={labelClassnames.join(' ')}
          onClick={handleToggleLabelStatus({ id, text, status })}
        >{text}</button>
      )
    })

    return (
      <div className="labels-control__items">{labelsItems}</div>
    )
  }, [labels])


  useEffect(() => {
    //fetch labels here
  })

  return (
    <div className="labels-control">
      <div className="labels-control__header">
        <h3 className="labels-control__title">{title || 'Labels'}</h3>

        <button
          className="button button_xs button_outline"
          onClick={openModal}
        >Add New Label</button>
      </div>

      {labelsContent}

      {
        labels?.length > 0 &&
        <ul className="labels-control__history">
          <li className="labels-control__history-item private">private</li>
          <li className="labels-control__history-item public">public</li>
        </ul>
      }

      <Modal isOpen={!!managingLabel} modalClassName="modal-dialog labels-dialog">
        <AddLabelForm
          onCancel={closeModal}
          labelData={managingLabel}
          onSubmit={handleSubmitChanges}
          labels={notUsedLabels}
        />
      </Modal>
    </div>
  )
}


LabelsControlPanel.propTypes = {
  onChangeLabelStatus: PropTypes.func
  , allLabelsList: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.number
  }))
  , labels: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.oneOf(['public', 'private'])
  }))
  , onChangeLabels: PropTypes.func
  , title: PropTypes.string
}

LabelsControlPanel.defaultProps = {
  labels: []
}

export default memo(LabelsControlPanel)
