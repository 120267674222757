import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';


const AdminRegionRow = ({ 
    id, name, category, pathname, parentRegion, country, curator
}) => {

    return (
        <Link to={ pathname } className={ classNames('admin-region-row', { 'parent-region': !parentRegion }) }>
            <span className="admin-region-id">{id}</span>
            <span className="admin-region-name">{name}</span>
            <span className="admin-region-country">{ country }</span>
            <span className="admin-region-parent-region">{ parentRegion?.name || '-' }</span>
            <span className="admin-region-curator">{ curator || '' }</span>
        </Link>
    )
}


AdminRegionRow.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.string,
    pathname: PropTypes.string
}

AdminRegionRow.defaultProps = {
}

export default AdminRegionRow;
