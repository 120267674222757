import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Case from '../../classes/Case';

import './style.scss';
import { useHelpers } from '../../hooks';
import { useSelector } from 'react-redux';


const CaseSaleRow = ({ customer, id, statuses, case: caseData, cost, currency }) => {
  const { formatDateTime } = useHelpers();
  const { user } = useSelector(state => state.auth);


  return (
    <div className={classNames('case-sale-row', { 'test-order': customer.role !== 'registered user' })}>
      {
        user?.role === 'admin'
          ? (
            <Link to={`/admin/orders/${id}`} className="sale-link">{id}</Link>
          ) : (
            <div to={`/admin/orders/${id}`} className="sale-link">{id}</div>
          )
      }

      {
        user?.role === 'admin'
          ? (
            <Link to={`/admin/orders/${id}`} className="sale-link fluid">
              {customer.firstName + ' ' + customer.lastName} {caseData?.isAdjusted && <span className="text-blue"> (Customized)</span>}
            </Link>
          ) : (
            <div to={`/admin/orders/${id}`} className="sale-link fluid">
              {customer.firstName + ' ' + customer.lastName} {caseData?.isAdjusted && <span className="text-blue"> (Customized)</span>}
            </div>
          )
      }
      <span className="sale-date" style={{ width: '100px' }}>{formatDateTime(statuses[0].date, false)}</span>
      <span className="sale-price" style={{ width: '60px' }}>{+cost > 0 ? cost : caseData?.prices[currency]?.toFixed(2)}</span>
    </div>
  )
};

CaseSaleRow.propTypes = {
  id: PropTypes.number,
  customer: PropTypes.shape({
    role: PropTypes.oneOf(['registered user', 'admin', 'test user', 'warehouse']),
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  statuses: PropTypes.array,
  caseData: PropTypes.shape(Case),
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CaseSaleRow;
