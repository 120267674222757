import { createContext } from 'react';

const noop = () => { };

/**
 * TODO: CHANGE IT TO BE LIKE CHECKOUT CONTEXT AFTER "NEBBIOLO" RELEASE!!!
 * 
 * - make all state changes through DISPATCH method!!!
 */
const authContext = createContext({
  isAuthenticated: false,
  fetchingPageData: false,
  currency: {}, location: {}, userAgent: {},
  region: '',
  user: {
    currency: {
      code: 'usd'
    },
    preferences: {},
    quiz: false
  },
  cart: {
    shipping: {},
    order: {},
    paypalId: ''
  },
  ip: undefined,
  meta: {
    fbId: null,
    googleId: '',
    registerForRoute: undefined
  },
  changeCurrency: noop,
  login: noop,
  logout: noop,
  setUserData: noop,
  changeRegion: noop,
  handleSetFetchingPageData: noop,
  changeUserNewWinesNotification: noop,
});

export default authContext;
