import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Icon } from '../../../components'
import { useGeoLocation, useRequests } from '../../../hooks';

import defaultImage from '../../../assets/images/newsletter-image.jpg'
import classNames from 'classnames';


const PublicHomeNewsletterDialog = ({ onClose, onGoback, image, title, subtitle, newsletterEndpoint, submitMessage, newsletterSubject }) => {
  const { post, requestPending } = useRequests();
  const { getInitialLocationData } = useGeoLocation();
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [errors, changeErrors] = useState();
  const [email, changeEmail] = useState('')

  const handleChangeEmail = e => {
    e.preventDefault()
    errors && changeErrors()

    const { value } = e.currentTarget;

    changeEmail(value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const { location, user_agent } = await getInitialLocationData();
    const body = { email }

    body.state = location?.region?.name;
    body.city = location.city;
    body.country = location.country?.name;
    body.device = user_agent?.device

    body.newsletter = newsletterSubject

    const response = await post({ url: newsletterEndpoint, body });

    if (response?.data?.success) {
      setIsSubscribed(true)
    } else {
      console.log('Error response data: ', response.data)
      changeErrors(response.data.errors)
    }

    // onClose()
  }

  const handleCloseDialog = () => {
    if (onGoback) return onGoback()

    if (onClose) return onClose()
  }


  return (
    <div className="card newsletter-card">
      {
        onClose &&
        <button className="close-modal-btn"
          onClick={onClose}
        ><Icon icon="close" size={16} color="white" /></button>
      }

      <div className="newsletter-card__image">
        <img src={image} alt="" />
      </div>

      <h4 className="newsletter-card__title">{title}</h4>

      <p className="newsletter-card__subtitle">{subtitle}</p>

      {
        !isSubscribed &&
        <div className={classNames('form-control subscription horizontal', { 'invalid': errors?.email })}>
          <input type="text"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={handleChangeEmail}
          />
          {
            errors?.email &&
            <span className="error-msg">{errors.email}</span>
          }

          <button
            className="button accent"
            disabled={requestPending}
            onClick={handleSubmit}
          >Join Now</button>
        </div>
      }

      {
        isSubscribed &&
        <div className="form-control subscription">
          <Alert className="sub-success-alert">
            <Icon icon="check" color="vineify-green" size={18} />

            <p className="no-margin">{submitMessage}</p>
          </Alert>

          <button className="button-link no-padding" onClick={handleCloseDialog}>Back to Home</button>
        </div>
      }
    </div>
  )
}


PublicHomeNewsletterDialog.propTypes = {
  newsletterEndpoint: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  , image: PropTypes.string
  , title: PropTypes.string
  , subtitle: PropTypes.string
  , submitMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  , onClose: PropTypes.func
}

PublicHomeNewsletterDialog.defaultProps = {
  image: defaultImage
}


export default PublicHomeNewsletterDialog
