import { createContext } from 'react';

const SerendipityWinesIntegrationContext = createContext({
  subregionsList: [], withImports: [], noImports: [], appellationsList: [], updatedDate: undefined,
  filters: { region: 'all' },
  setInitialData: () => {},
  changeWithImportsAppellations: () => {},
  changeNoImportsAppellations: () => {},
  setUpdatedDate: () => {},
  changeFilters: ({ region, minPrice, maxPrice }) => {},
  resetFilters: ({ region, minPrice, maxPrice }) => {},
});


export default SerendipityWinesIntegrationContext;
