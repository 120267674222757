import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHelpers } from '../../hooks'

const PhoneInput = ({ phone, inputName, onChange }) => {
  const { unmaskPhoneInput, maskPhoneInput } = useHelpers()
  

  const handleChange = e => {
    e.preventDefault();
    
    const { value:inputValue } = e.currentTarget;
    // const unmaskedValue = inputValue.replaceAll(/\(|\)|\W|\-|\+/g, '')
    const unmaskedValue = unmaskPhoneInput(inputValue)

    if (!unmaskedValue || unmaskedValue.length === 0) return onChange('');

    const value = unmaskedValue?.length === 11 && unmaskedValue.startsWith('1')
      ? unmaskedValue.split('').splice(1).join('') : unmaskedValue

    // const value = formatPhoneInput(inputValue)

    const isValid = /\({0,1}[0-9]{0,3}\){0,1}\W{0,1}[0-9]{0,3}\-{0,1}[0-9]{0,4}/.test(value)
    
    if (value.length > 10) return;
    
    if (!/^\d+$/.test(value)) return;
    if (!/[2-9][0-9]{0,9}/.test(value)) return;
    
    if (!isValid) return;

    onChange(value);
  }

  const value = useMemo(() => {
    // const phoneNumbers = unmaskPhoneInput(phone)
    
    // const value = phoneNumbers?.length === 11 && phoneNumbers.startsWith('1')
    //   ? phoneNumbers.split('').splice(1).join('') : phoneNumbers

    // const maskedValue = value.split('').map((char, ind) => {
    //   switch(ind) {
    //     case 0: return `(${char}`
    //     case 3: return `) ${char}`
    //     case 6: return `-${char}`
    //     default: return char;
    //   } 
    // })

    // return maskedValue.join('');
    return maskPhoneInput(phone)
  }, [phone])



  return (
      <input
        name={ inputName || 'phone' }
        placeholder="(xxx) xxx-xxxx"
        value={ value }
        onChange={ handleChange }
      />
  )
}

PhoneInput.propTypes = {
  phone: PropTypes.string,
  label: PropTypes.string,
  inputName: PropTypes.string,
  onChange: PropTypes.func
}

export default PhoneInput;
