import { createSlice } from '@reduxjs/toolkit'
import { requestAllRecommendations, requestCaseDetails, requestCasePrice, requestCasePageData, requestCasesPreviewsList, filterCases, requestCasesList } from './case.actions'


const initialState = {
  error: undefined,
  processingRequest: false,
  processingRequests: 0,
  list: [],
  currentCaseDetails: { wines: [] },
  dataIsFetched: false
}

const casesSlice = createSlice({
  name: '@@cases',
  initialState,
  reducers: {
    clearCasesStore: () => {
      return { ...initialState }
    },
    setCurrentCase: (state, { payload }) => {
      state.currentCaseDetails(payload)
      state.dataIsFetched = true
    },
    setDataLoading: (state) => { state.dataIsFetched = false },
    filterCasesList: (state, { payload }) => {
      const { price, quantity, soldoutLast } = payload;
      
      state.list = filterCases(state.list, { price, quantity, soldoutLast })
    },
    setCasesList: (state, action) => {
      state.list = action.payload
    },
    changeCurrentCaseDetails: (state, action) => {
      state.currentCaseDetails = action.payload
    },
    changeCurrentCaseWines: (state, { payload }) => {
      state.currentCaseDetails.wines = payload.newWines

      if (payload.newRegions) {
        state.currentCaseDetails.regions = payload.newRegions
      }
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestAllRecommendations.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(requestCaseDetails.fulfilled, (state, { payload }) => {
        state.currentCaseDetails = JSON.parse(payload)
        state.dataIsFetched = true
      })
      .addCase(requestCasesPreviewsList.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(requestCasePageData.fulfilled, (state, { payload }) => {
        state.currentCaseDetails = payload
        state.dataIsFetched = true
      })
      .addCase(requestCasePrice.fulfilled, (state, { payload }) => {
        state.currentCaseDetails.prices = payload.prices
        state.currentCaseDetails.price = payload.totalPrice
      })
      .addCase(requestCasesList.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addMatcher(
        (action) => action.type.startsWith('@@cases') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@cases') && action.type.endsWith('/pending'),
          (state, _) => {
            state.dataIsFetched = false;
            state.processingRequest = true;
            state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@cases') && action.type.endsWith('/fulfilled'),
          (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})


export const casesActions = {...casesSlice.actions, requestAllRecommendations, requestCaseDetails, requestCasePrice, requestCasePageData, requestCasesPreviewsList, requestCasesList};

export default casesSlice.reducer;
