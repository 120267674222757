import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import { useRequests } from '../../hooks';
import { LoadingScreen, VerificationAlert } from '../../components';

import icon from '../../assets/icons/check-o.svg';


const UnsubscribePage = () => {
    const { get } = useRequests();
    const [isUnsubscribed, changeSubscribeState] = useState(false);


    const unsubscribe = async () => {
        const response = await get({ url: '/api/users/unsubscribe' });

        if (response?.data?.success) {
            changeSubscribeState(true);
        }
    }


    // eslint-disable-next-line
    useEffect(() => { unsubscribe(); }, []);

    if (!isUnsubscribed) return <LoadingScreen />;


    return (
        <div className="section section_centered">
            <div className="section__content">
                <VerificationAlert icon={ icon } title="Thank you. You have been unsubscribed.">
                    <Link className="text-underline" to="/">Click here to return to the home page.</Link>
                </VerificationAlert>
            </div>
        </div>
    )
}


export default UnsubscribePage;
