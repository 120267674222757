import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHelpers } from '../../hooks';
import { InfoButtonWithPopover, Badge } from '..';
import buttonIcon from '../../assets/icons/info-circle.svg'; 
import { useSelector } from 'react-redux';
import classNames from 'classnames';



const AdminCaseRow = ({ name, price, currency, stock, prices, id, createdAt, updatedAt, active: state, category, customers, onShowCasePriceDetails, orders, curator, hideCurator, lowCaseStock }) => {
  const { user } = useSelector(state => state.auth)
  const isCurator = user.role === 'curator'
  const showOrders = user.role === 'admin' || hideCurator
  const { formatDateTime } = useHelpers();
  const status = ['draft', 'inactive', 'active', 'highlighted'][state + 1];
  const lowStock = lowCaseStock || 3
  
  const isLowStock = stock > 0 && stock <= lowStock;
  const isOutOfStock = stock === 0;
  const className = stock > lowStock ?
    'admin-case-row' : stock === 0 ?
      'admin-case-row no-stock' : 'admin-case-row low-stock';

  const handleInitShowCasePriceDetails = e => {
    e.preventDefault();

    onShowCasePriceDetails(id)
  }
  const showId = user.role === 'admin' || user.curatorRole === 'main'
  const stockBadge = isLowStock || isOutOfStock
    ? <Badge
        className={classNames('stock-badge', {
          'low': isLowStock,
          'out': isOutOfStock
        })} 
        statusText={isLowStock ? `low stock` : `out of stock`} withoutIcon
      />
    : undefined


  return (
    <Link to={`/${user?.role === 'curator' ? 'curator' : 'admin'}/cases/${id}`} className={className}>
      {showId && <span className="id">{id}</span>}
      <span className="date">{formatDateTime(createdAt, false)}</span>
      <span className="name">{name}</span>
      {
        isCurator && !hideCurator &&
        <span className="curator">{curator}</span>
      }
      {
        user?.role !== 'curator' && <>
          <span className="category">
            {category}
            {
              category === 'custom' &&
              <InfoButtonWithPopover>
                <ul className="admin-case-row__customers">
                  <li><h5>Customers list:</h5></li>
                  {
                    customers?.map((customerData, ind) => (
                      <li key={`${ind}-${customerData.name}`}>
                        <b className="flex-grow">{customerData.name?.trim()?.length > 0 ? customerData.name : '-'}</b>
                        {customerData.email}
                      </li>
                    ))
                  }</ul>
              </InfoButtonWithPopover>
            }
          </span>
        </>
      }
      <span className="cost flex-space-between">
        <b>
          {
            user?.role === 'admin' && <>
              {['all', 'eur'].includes(currency) && <>€{prices?.eur || 0}{' '}</>}
              {['all', 'usd'].includes(currency) && <>${prices?.usd || 0}</>}
            </>
          }

          {
            user?.role === 'curator' && price && 
            <>${price || 0}</>
          }
        </b>

        {
          user?.role !== 'curator' &&
          <button
            className="button-link not-underlined"
            onClick={handleInitShowCasePriceDetails}
          ><img src={buttonIcon} alt="" /></button>
        }
      </span>
      {
        showOrders &&
        <span className="orders">{orders || 0}</span>
      }
      <span className="stock">{stock}{stockBadge}</span>
      <span className={`status ${status}`}>{status}</span>
    </Link>
  )
}


AdminCaseRow.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  prices: PropTypes.shape({
    eur: PropTypes.number,
    usd: PropTypes.number
  }),
  stock: PropTypes.number,
  id: PropTypes.number,
  createdAt: PropTypes.string,
  active: PropTypes.number,
  hideCurator: PropTypes.bool,
  onShowCasePriceDetails: PropTypes.func
}

AdminCaseRow.defaultProps = {
  stock: 0,
  active: 0,
  hideCurator: false
}

export default AdminCaseRow
