import { createAsyncThunk } from '@reduxjs/toolkit'
import * as CaseService from '../../services/case.service'


export const requestRecommendation = createAsyncThunk(
  '@@recommendations/request-recommendation',
  async ({ caseId, language = 'en' }, thunkAPI) => {
    try {
      const data = await CaseService.fetchRecommendation({ language, caseId })
      const { caseData, recommendations } = data;

      const serializedCaseData = caseData ? JSON.stringify(caseData) : undefined
      const serializedRecommendations = recommendations?.length > 0
        ? JSON.stringify(recommendations) : []

      return { caseData: serializedCaseData, recommendations: serializedRecommendations };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
)
