import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, ButtonWithPopoverConfirmation } from '..';
import { useStoreWines } from '../../hooks';


const ManageWineLabelForm = ({ labelData, onSubmit, onCancel }) => {
  const { winesState: { processingRequest } } = useStoreWines()
  const [label, changeLabel] = useState(labelData?.name || '');
  const [confirmationIsOpened, changeConfirmationState] = useState(false);

  const forUpdate = +labelData?.id > 0;

  const handleChangeLabelText = e => {

    changeLabel(e.currentTarget.value);
  }

  const handleCancel = e => {
    e.preventDefault();

    onCancel();
  }

  const handleToggleConfirmation = state => e => {
    e && e.preventDefault();

    changeConfirmationState(state === 'open')
  }

  const handleRemoveLabel = async e => {
    e && e.preventDefault();

    onSubmit('delete', labelData)
  }

  const handleSubmit = async e => {
    e.preventDefault();

    onSubmit(forUpdate ? 'update' : 'create', { ...labelData, text: label })

    // const response = await post({
    //   url: forUpdate ? `/api/wines/labels/${labelData?.id}` : '/api/wines/labels',
    //   body: { label: { ...convertTabbedContentToLanguagesObject(label), it: '' } }
    // });

    // if (response?.data?.success) {
    //   forUpdate ?
    //     onSubmit({ names: convertTabbedContentToLanguagesObject(label), id: labelData?.id })
    //     :
    //     onSubmit(response?.data.newTaste)
    // } else {
    //   toast.error(response.data.message)
    // }
  }


  return (<>
    <button
      className="close-modal-btn" type="button"
      onClick={handleCancel}
    ><Icon icon="close" color="tertiary" size={24} /></button>
    <form className="form-horizontal">
      <div className="form-section-title" style={{ marginTop: 0 }}>{forUpdate ? `Edit Label` : `Create New Label`}</div>

      <div className="form-control">
        <input name="label" value={label} onChange={handleChangeLabelText} />
      </div>

      <div className="form-control horizontal flex-space-around gap_xs" style={{ marginTop: '24px' }}>
        {
          forUpdate &&
          <ButtonWithPopoverConfirmation
            title="Confirmation"
            btnText="Delete Label"
            disabled={ processingRequest }
            isOpen={confirmationIsOpened}
            confirmText={`Please confirm you want to delete label "${label}"`}
            onClick={handleToggleConfirmation('open')}
            onCancel={handleToggleConfirmation('close')}
            onConfirm={handleRemoveLabel}
          />
        }
        {
          !forUpdate &&
          <button
            className="button button_outline button_xs"
            onClick={handleCancel}
          >Cancel</button>
        }

        <button
          className="button button_xs"
          onClick={handleSubmit}
          disabled={processingRequest}
        >{forUpdate ? `Change Label` : `Add Label`}</button>
      </div>
    </form>
  </>)
}


ManageWineLabelForm.propTypes = {
  onSubmit: PropTypes.func
}

export default ManageWineLabelForm;
