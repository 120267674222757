import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-simple-hook-modal'
import { useTranslation } from 'react-i18next'
import VerificationAlert from '../alert/VerificationAlert'

import icon from '../../assets/icons/check-o.svg';
import closeIcon from '../../assets/icons/close.svg';
import RegisterForm from '../form/RegisterForm'
import { useSelector } from 'react-redux'


const WaitListInvitationBar = ({ headerClassName, onDismiss }) => {
  const { isAuthenticated } = useSelector(state => state.auth)
  const [modalContent, setModalContent] = useState();
  const {t} = useTranslation();

  const handleOpenModal = e => {
    e.preventDefault();

    setModalContent('wait-list')
  }

  const handleJoinWaitlist = () => {
    setModalContent('join-success')
  }

  const handleCloseModal = () => setModalContent()


  return (
    <div className="home-header__alert">
      <p>
        <b>We are currently only shipping cases within Travis County, Austin, TX.</b> If you are outside of this area <button
          className="button-link no-padding not-underlined"
          onClick={handleOpenModal}
        ><b className="text-white">Join Our Waitlist</b></button>
      </p>

      <Modal isOpen={modalContent?.length > 0} modalClassName="referer-modal">
        <button className="close-modal-btn" onClick={handleCloseModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        {/* <ReferFriendForm /> */}

        { 
          modalContent === 'wait-list' && 
            <RegisterForm onSubmit={handleJoinWaitlist} changeUserData={!isAuthenticated} /> 
        }
        { 
          modalContent === 'join-success' && 
          <VerificationAlert
            icon={icon} 
            title={t('THANK_YOU')} 
            // msg={t('WAIT_LIST_REGISTRATION_SUCCESS')}
            msg="Thank you for your interest in Vineify. We will let you know when Vineify is available in your area."
          />
        }
        </Modal>
    </div>
  )
}


WaitListInvitationBar.propTypes = {
  headerClassName: PropTypes.string
  , onDismiss: PropTypes.func
}

export default WaitListInvitationBar;
