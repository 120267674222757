import React, { useReducer } from 'react';
import SerendipityWinesIntegrationContext from './SerendipityWinesIntegrationContext';
import reducer from './serendipityWinesIntegrationReducer';

import {
  SET_INITIAL_DATA, CHANGE_APPELLATIONS_WITH_IMPORTS, CHANGE_APPELLATIONS_WITH_NO_IMPORTS, CHANGE_FILTERS, SET_UPDATED_DATE
} from './serendipityWinesIntegrationActions';


const SerendipityWinesIntegrationProvider = ({ children }) => {
  const initialState = {
    subregionsList: [], 
    withImports: [], 
    noImports: [], 
    appellationsList: [], 
    updatedDate: undefined,
    filters: { region: 'all' }
  };
  

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {};

  actions.setInitialData = ({ subregionsList, withImports, noImports, appellationsList }) => dispatch({
    type: SET_INITIAL_DATA, payload: {subregionsList, withImports, noImports, appellationsList}
  })

  actions.changeWithImportsAppellations = appellationsList => dispatch({
    type: CHANGE_APPELLATIONS_WITH_IMPORTS, payload: appellationsList
  })

  actions.changeNoImportsAppellations = appellationsList => dispatch({
    type: CHANGE_APPELLATIONS_WITH_NO_IMPORTS, payload: appellationsList
  })

  actions.changeFilters = ({ region, minPrice, maxPrice }) => dispatch({
    type: CHANGE_FILTERS, payload: { region, minPrice, maxPrice }
  })

  actions.resetFilters = () => dispatch({
    type: CHANGE_FILTERS, payload: { region: 'all', minPrice: undefined, maxPrice: undefined }
  })

  actions.setUpdatedDate = date => dispatch({
    type: SET_UPDATED_DATE, payload: date
  })
  

  return (
    <SerendipityWinesIntegrationContext.Provider
      value={{ ...state, ...actions }}
    >{children}</SerendipityWinesIntegrationContext.Provider>
  )
}


export default SerendipityWinesIntegrationProvider;
