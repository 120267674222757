import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CountInput } from '..';
import { useRequests } from '../../hooks';

import './style.scss';


const RestockForm = ({ wineId, wineName, stock: currentValue, closeDialog, onChangeStock, currentWineStock }) => {
  const [stock, changeStock] = useState(0);
  const [error, setError] = useState();
  const { post } = useRequests();
  

  const handleChangeStockValue = value => {
    setError();

    changeStock(value)
  }

  const handleSubmitrestock = async e => {
    e.preventDefault();

    const changedStockValue = +currentValue + +stock;

    if (changedStockValue < 0) {
      const input = document.querySelector('.count-input>.input-group>input');
      input.focus()
      return setError(`Stock will be ${changedStockValue}.\nWine stock value should not be negative`)
    }

    if (stock !== 0) {
      const response = await post({
        url: `/api/wines/${wineId}/change-stock`,
        body: { value: stock, stock: currentValue }
      });

      if (response?.data?.success) {

        // onChangeStock(+stock);
        onChangeStock({
          ...response.data.newRecord,
          newValue: +stock,
        });

        changeStock(0);

        closeDialog()
      }
    }
  }


  const handleCloseRestockDialog = e => {
    e.preventDefault();

    changeStock(0);

    closeDialog();
  }


  return (
    <form className="restock-form">
      <h3 className="section-title"><b>{wineName}</b></h3>

      <p className="text-lg">Enter number of bottles you want to add (+) or remove (-)</p>
      <p className="text-lg text-center">Restock Value</p>
      <CountInput name="value"
        value={stock}
        negativeValues={true}
        onChange={handleChangeStockValue}
      />
      { error && <p className="restock-warning">{error}</p> }
      <div className="form-control">
        <button
          className="button"
          disabled={stock === 0}
          onClick={handleSubmitrestock}
        >Submit Restock</button>

        <button
          className="button button_outline"
          onClick={handleCloseRestockDialog}
        >Close Dialog</button>
      </div>
    </form>
  )
}

RestockForm.propTypes = {
  wineId: PropTypes.number,
  closeDialog: PropTypes.func,
  stock: PropTypes.number
}

export default RestockForm
