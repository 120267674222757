import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import ButtonWithPopoverConfirmation from '../buttons/ButtonWithPopoverConfirmation';
import { useHelpers } from '../../hooks';

const NewVintageForm = ({ vintage, similarWines, requestPending, onSubmit }) => {
  const { getWineName } = useHelpers()
  const [newVintage, changeNewVintage] = useState(vintage);
  const [dialogIsOpened, setDialogState] = useState(false)
  const isDisabled = !newVintage
    || newVintage === vintage
    || requestPending

  const toggleConfirmationState = e => {
    e.preventDefault()

    setDialogState(prev => !prev)
  }
  
    const handleSubmit = e => {
    e.preventDefault();

    onSubmit(newVintage)
  }

  const handleChangeVintage = e => {
    changeNewVintage(e.currentTarget.value)
  }

  const similarWine = similarWines.find(({ vintage: wineVintage }) => +wineVintage === +newVintage)

  const button = similarWine
    ? (
      <ButtonWithPopoverConfirmation
        confirmText={`${getWineName(similarWine)} name already exists in the ${similarWine.warehouse} warehouse. Add anyway?`}
        disabled={isDisabled}
        isOpen={dialogIsOpened}
        onClick={ toggleConfirmationState }
        onCancel={toggleConfirmationState}
        onConfirm={handleSubmit}
        btnText="Create Wine With New Vintage"
        mainButtonClasses="button_info button"
      />
    ) : (
      <button
          className="button button_info"
          disabled={isDisabled}
          onClick={handleSubmit}
        >Create Wine With New Vintage</button>
    )

  return (
    <form>
      <br />
      <div className="form-section-title">Create New Wine</div>
      <div className="form-control">
        <label htmlFor="vintage">Vintage</label>

        <input
          type="number" name="vintage"
          onChange={handleChangeVintage}
          value={newVintage || +vintage}
        />

        <br />

        {button}
      </div>
    </form>
  )
}

NewVintageForm.propTypes = {
  requestPending: PropTypes.bool
  , vintage: PropTypes.number
  , similarWines: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    producer: PropTypes.string,
    warehouse: PropTypes.string,
    vintage: PropTypes.number,
    id: PropTypes.number
  }))
  , onSubmit: PropTypes.func
}

export default memo(NewVintageForm)