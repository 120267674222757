import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './style.scss';

import delivery from '../../assets/icons/delivery-truck.svg';
import processing from '../../assets/icons/cart.svg';
import arrived from '../../assets/icons/case-sm.svg';


const Badge = ({ status, statusText, className, children, withoutIcon }) => {
    const { t } = useTranslation();
    const statusKey = status ? 
      status.toUpperCase().split(' ').join('_') : '';

    const badgeClassName = [ 'status-badge', className ].join(' ')

    const icon = {
        'Processing Order': processing,
        'Out for Delivery': delivery,
        'Arrived': arrived,
        'Shipped': delivery,
    };

    return (
        <div className={ badgeClassName }>
            { status && <p className="status__name">{ t(statusKey) }</p> }
            { statusText && <p className="status__name">{ statusText }</p> }
            { !withoutIcon && <img className="status__icon" src={icon[status]} alt="status"/> }
            { children }          
        </div>
    )
}

Badge.propTypes = {
    status: PropTypes.string,
    badgeClassName: PropTypes.string,
    withoutIcon: PropTypes.bool
}

Badge.defaultProps = {
  withoutIcon: false
}


export default Badge;
