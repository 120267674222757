import React from 'react'
import PropTypes from 'prop-types'


const AiBuddyFilters = () => (
  <div className="personal-rec__filters">
    <div className="personal-rec__photo" />

    <h5 className="personal-rec__filters-title">Get personalized recommendations from Vineify’s experts</h5>

    <p className="personal-rec__filters-subtitle">A recommendation will be in your inbox within 24 hours</p>
    {/* <p className="personal-rec__filters-subtitle">from expert curators</p> */}

    <p className="personal-rec__filters-placeholder">Example:
    ”I’m looking for rosé, white and light red wines to go with the summer BBQs and sipping poolside. No heavy reds please!”</p>
  </div>
)


AiBuddyFilters.propTypes = {
  categories: PropTypes.array
}

export default AiBuddyFilters
