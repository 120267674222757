import React from 'react'
import PropTypes from 'prop-types'


const DownloadJsonDataButton = ({ data, label, fileName }) => {

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${fileName || 'orders'}.json`;

    link.click();
  };


  return (
    <button
      className="button button_outline"
      disabled={!data} 
      onClick={exportData}
    >{label || 'Export Orders'}</button>
  )
}

DownloadJsonDataButton.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  fileName: PropTypes.string
}

export default DownloadJsonDataButton