import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '..'

import './style.scss'


const AddLabelForm = ({ onSubmit, onCancel, labelData, labels, title }) => {
  const labelsOptions = labels.map(({ text, id }) => ({ label: text, value: id }))
  if (labelData?.id) {
    labelsOptions.unshift({ value: labelData.id, label: labelData.text })
  }
  const statusoptions = [
    { label: 'Private', value: 'private' },
    { label: 'Public', value: 'public' },
  ]

  const labelText = labelsOptions?.find(({ value }) => value === labelData?.id)
  const labelStatus = statusoptions?.find(({ value }) => value === labelData?.status)

  const [state, changeState] = useState({
    label: labelText || labelsOptions[0],
    status: labelStatus || statusoptions[0]
  })


  const handleChnage = prop => data => {
    changeState(prev => ({ ...prev, [prop]: data }))
  }

  const handleSubmit = e => {
    e.preventDefault();
    const newLabel = {
      id: state.label.value,
      text: state.label.label,
      status: state.status.value,
    }

    onSubmit(newLabel, labelData)
  }

  const handleCancel = e => {
    e.preventDefault();

    onCancel()
  }

  const handleRemove = e => {
    e.preventDefault();

    onSubmit(null, labelData)
  }


  return (
    <form className="add-label-form">
      <h3 className="form-section-title">{title || 'Add Label'}</h3>

      <div className="form-section">
        <div className="form-control">
          <label htmlFor="label">Label</label>

          <Select
            menuStyle={{
              position: 'absolute !important',
              zIndex: '1000 !important'
            }}
            options={labelsOptions}
            value={state.label}
            onChange={handleChnage('label')}
          />
        </div>

        <div className="form-control">
          <label htmlFor="status">Status</label>

          <Select
            options={statusoptions}
            value={state.status}
            onChange={handleChnage('status')}
          />
        </div>
      </div>

      <div className="flex-center gap">
        <button
          className="button button_xs button_outline"
          onClick={handleCancel}
        >Cancel</button>

        {
          labelData?.id &&
          <button
            className="button button_xs button_red"
            onClick={handleRemove}
          >Remove</button>
        }

        <button
          className="button button_xs button-info"
          onClick={handleSubmit}
        >{labelData?.id ? 'Change' : 'Add Label'}</button>
      </div>
    </form>
  )
}


AddLabelForm.propTypes = {
  onSubmit: PropTypes.func
  , onCancel: PropTypes.func
  , labels: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.number
  }))
  , labelData: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.number
  })
  , title: PropTypes.string
}

export default AddLabelForm
