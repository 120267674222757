import { useEffect, useState } from "react";


const useScrrenSize = () => {
  const [state, changeState] = useState({
    screenWidth: window.innerWidth,
    verticalOffset: 0
  });


  const calcOffset = () => {
    const header = document.getElementsByTagName('header')[0];
    const footer = document.querySelector('.reg-footer');
    const headerHeight = header ? header.scrollHeight : 0;
    const footerHeight = footer ? footer.scrollHeight : 0;

    return headerHeight + footerHeight;
  }

  const calcNewState = () => {
    const newVertOffset = calcOffset();
    changeState({
      screenWidth: window.innerWidth,
      verticalOffset: newVertOffset
    })
  }

  useEffect(() => {
    window.addEventListener('resize', calcNewState);

    return () => {
      window.removeEventListener('resize', calcNewState);
    }
  });

  const { screenWidth, verticalOffset } = state;

  return {
    screenWidth, verticalOffset, calcOffset
  }
}

export default useScrrenSize;
