import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from '..';
import { useGeoLocation, useRequests, useStoreAuth } from '../../hooks';


const CompactRegisterForm = ({ id, onSwitchForm, subtitle }) => {
  const { authActions, authState: { user, errors, processingRequest } } = useStoreAuth();
  const { t } = useTranslation();
  const { get } = useRequests();
  const { getInitialLocationData } = useGeoLocation();
  const history = useHistory();
  const [geolocation, setGeolocation] = useState({
    location: null, user_agent: null
  });
  const [formData, changeFormData] = useState({
    email: user.email || '',
    firstName: '', lastName: '', password: '', zipcode: ''
  });


  const errorData = errors || {};

  const handleChangeFormField = e => {
    const { name, value } = e?.currentTarget;

    // clearErrors();
    authActions.clearErrors()
    if (name === 'email') sessionStorage.setItem('lastEmail', value)
    changeFormData(prev => ({ ...prev, [name]: value }));
  }

  const getGeolocation = useCallback(async () => {
    const { location, user_agent } = await getInitialLocationData();

    setGeolocation({ location, user_agent })
  }, [get])

  useEffect(() => {
    getGeolocation();

    return () => {

    }
  }, [])

  const disabled = processingRequest;

  const handleRegisterNewUser = async (e) => {
    e.preventDefault();

    const { user_agent, location: { city, region, country: { name, code } } } = geolocation;

    const state = region?.name;
    const country = { name, code }
    const formFields = {
      password: formData.password?.trim(),
      firstName: formData.firstName?.trim(),
      lastName: formData.lastName?.trim(),
      email: formData.email?.trim(),
      zipcode: formData.zipcode?.trim()
    }

    changeFormData(formFields)

    const body = {
      ...formFields,
      location: { state, city, country }
    };

    if (user_agent?.device) {
      body.device = { ...user_agent.device }
    }

    // ctx.user.email = formData.email;
    // ctx.user.isAdult = false;
    // dispatch(authActions.changeUserdata({ email: formData.email }))
    
    if (history.location.pathname.includes('cart')) {
      localStorage.setItem('registerForRoute', 'cart/payment')
    } else {
      sessionStorage.setItem('_cna', new Date().getTime())
    }

    const redirectPath = !history.location.pathname.includes('cart') ? '/check-age' : undefined
    authActions.requestRegisterUser({ ...body, redirectPath })

    // const result = await post({ url: '/api/users/register', body });
    // if (result && result.data.success) {
    //   if (history.location.pathname.includes('cart')) {
    //     localStorage.setItem('registerForRoute', 'cart/payment')
    //   }
    //   history.push('/check-age')
    // }
  }

  const handleChangePassword = password => {
    authActions.clearErrors()

    changeFormData(prev => ({ ...prev, password }))
  }
  
  const { email, firstName, lastName, password, zipcode } = formData;


  return (
    <form className="main-register-form" id={id}>
      <div className="compact-form-title section__title text-center">
        <h3>Create Your Account</h3>
      </div>
      {subtitle && <p className="compact-form-subtitle">{subtitle}</p>}
      {onSwitchForm && <p className="compact-form-subtitle">
        Already have an account? <button className="button-link" type="button" onClick={onSwitchForm.action}>Sign In</button>
      </p>}

      {!onSwitchForm && <p className="compact-form-subtitle"><Link className="text-center" to="/login">{t('WAIT_LIST_HAVE_ACCOUNT_LINK')}</Link></p>}
      <br />

      <div className={classNames('form-control', {
        'invalid': errorData?.email || errorData?.invalid || errorData?.exist
      })}>
        <label htmlFor="email">Email</label>

        <input
          name="email" type="text"
          placeholder="Email Address"
          autoComplete="email"
          value={email}
          onChange={handleChangeFormField}
        />
        {
          errorData?.email &&
          <p className="error-msg">{errors?.message}</p>
        }
        {
          errorData?.exist &&
          <p className="error-msg">{errors?.message}. <Link to="/login"><b>{t('WAIT_LIST_JOIN_FORM_ERROR_LINK')}</b></Link></p>
        }
        {
          errorData?.invalid &&
          <p className="error-msg">{errors?.message}</p>
        }
      </div>

      <div className={classNames('form-control', { 'invalid': errorData?.password })} >
        <label htmlFor="password">Password</label>

        <PasswordInput
          onChange={handleChangePassword}
          placeholder="Password"
          name="password"
        />
        {
          errorData.password &&
          <p className="error-msg">
            {errorData.password}
          </p>
        }

      </div>

      <div className="button-container button_full-size">
        <button
          // className="button button--register"
          className="button"
          onClick={handleRegisterNewUser}
          disabled={disabled}
        >Get Started</button>
      </div>

      {/* <div className="form-control">
        {!onSwitchForm && <Link className="text-center" to="/login">{t('WAIT_LIST_HAVE_ACCOUNT_LINK')}</Link>}

        {onSwitchForm && <button className="button-link" onClick={onSwitchForm.action}>{onSwitchForm.label}</button>}
      </div> */}
    </form>
  )
}


CompactRegisterForm.propTypes = {
  onSwitchForm: PropTypes.shape({
    action: PropTypes.func,
    label: PropTypes.string
  }),
  id: PropTypes.string,
  zipcodes: PropTypes.arrayOf(PropTypes.string)
}

export default CompactRegisterForm;
