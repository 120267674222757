import { authActions } from '../store/auth/auth.slice'
import { cartActions } from '../store/cart/cart.slice'
import { checkoutActions } from '../store/checkout/checkout.slice'
import { casesActions } from '../store/cases/case.slice'
import { regionActions } from '../store/regions/region.slice'
import { userActions } from '../store/users/user.slice'
import { recommendationsActions } from '../store/recommendations/recommendation.slice'
import { wineActions } from '../store/wines/wine.slice'
import { producersActions } from '../store/producers/producer.slice'
import { curatorsActions } from '../store/curators/curator.slice'
import { ordersActions } from '../store/orders/order.slice'


const useActions = () => {

  return {
    authActions, cartActions, checkoutActions, casesActions, regionActions, userActions, recommendationsActions, wineActions, producersActions, curatorsActions, ordersActions
  }
}

export default useActions;
