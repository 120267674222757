import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import noOrderIcon from '../../assets/icons/empty-bottle.svg';

import './style.scss';


const NoOrder = ({ msg }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleRedirect = e => {
        e.preventDefault();
        // history.push('/home#cases');
        history.push('/#cases');
    }


    return (
        <div className="no-order">
            <img className="no-order__img" src={noOrderIcon} alt="icon" />
            <div className="no-order__title">{ t('ACCOUNT_ORDERS_NOORDER_ALERT_TITLE') }</div>
            <p className="no-order__text">{msg}</p>
            <button 
                className="button"
                onClick={ handleRedirect }
            >{ t('BROWSE_AVAILABLE_CASES') }</button>
        </div>
    )
}


NoOrder.propTypes = {
    msg: PropTypes.string
}

export default NoOrder
