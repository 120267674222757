import React from 'react';
import PropTypes from 'prop-types';

import defaultImage from '../../assets/images/main.jpg';

import './style.scss';


const PageHeader = ({ image, title, children, className }) => {
  const hedearClassName = className?.length ?
    `page-header ${className}` : `page-header`;

  return (
    <div
      style={{ background: `center / cover no-repeat url(${image}) ` }}
      className={hedearClassName}
    >
      <div className="overlay" />

      <div className="container">
        {title && <div className="page-title"><h1 >{title}</h1></div>}

        {children}
      </div>
    </div>
  )
}


PageHeader.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string
}

PageHeader.defaultProps = {
  image: defaultImage
}

export default PageHeader;
