import React, { useContext, useState } from 'react';
import { v4 as genKey } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useHelpers, useRequests, useStoreRegions } from '../../hooks';
import { CheckoutContext } from '../../context';
import { CountryContext } from '../../context/country';
import { LoadingScreen, Spinner, NewCaseCard, WinesTypesSummary, CaseImageWithPrice } from '..';

import bottleIcon from '../../assets/icons/bottle.svg';

import './style.scss';
import Case from '../../classes/Case';
import PriceDetails from '../common/PriceDetails';
import { useSelector } from 'react-redux';


const OrderSidebar = () => {
  const { regionsState: { countriesTranslations, charge, currentCountry } } = useStoreRegions()
  const { order: currentOrder, caseIsAdjusted } = useSelector(state => state.cart)
  const { user } = useSelector(state => state.auth)
  const history = useHistory();
  const { get, requestPending } = useRequests();
  const { t, i18n: { language: lng } } = useTranslation();
  const { orderCapture, shippingCountry, removeOrderCaptureAction, zip } = useContext(CheckoutContext);
  // const { charge, currentCountry, changeCountryData } = useContext(CountryContext); 
  const { changeCountryData } = useContext(CountryContext);
  const { countBottles, objectIsEmpty, defineCaseColorLabel, capitalize, getWineName, getPaypalPriceDetails, createWinesTypes } = useHelpers();
  const [state, changeState] = useState({
    orderRequestPending: false, captureOrderButtonIsVisible: false
  });
  // const order = ctx.cart.order.case;
  const { case: order, adjustmentCase } = currentOrder;
  const { currentCaseDetails } = useSelector(state => state.cases)

  const currency = 'usd';
  // const ajustmentId = ctx.cart.order?.adjustmentCase?.id;


  if ((!order || objectIsEmpty(order))) return <LoadingScreen />;

  const zipcodes = order?.wines?.find(wineData => wineData?.warehouse?.zipcodes)?.warehouse?.zipcodes;

  const winesCategories = createWinesTypes(order?.wines)

  const contriesNotMatch = zipcodes
    ? !zipcodes.split(',')?.find(zipCode => zipCode?.trim() === '' + zip)
    : shippingCountry.length > 0 && currentCountry !== shippingCountry;

  const caseColor = defineCaseColorLabel(order?.name || '');

  const handleCaptureOrder = async e => {
    e.preventDefault();
    if (state.orderRequestPending) return false;

    changeState(prev => ({ ...prev, orderRequestPending: true }));

    if (!orderCapture) return;

    await orderCapture();

    changeState(prev => ({ ...prev, orderRequestPending: false }));
  }

  const handleRecalculateOrder = async () => {
    if (state.orderRequestPending) return false;

    changeState(prev => ({ ...prev, orderRequestPending: true }));
    const response = await get({ url: `/api/shipping/charges/${shippingCountry}` });

    if (response?.data?.success) {
      const { shippingCharge: charge } = response.data.chargeData;

      changeCountryData(shippingCountry, charge, currency);
      removeOrderCaptureAction();
      history.push('/cart/payment');
    }
    changeState(prev => ({ ...prev, orderRequestPending: false }));
  }

  const winesListItems = order?.wines?.map(
    ({ name, quantity, vintage, producer }) => (
      <li key={genKey()}>
        <span>
          <img src={bottleIcon} alt="wine name" /> x{quantity}
        </span>
        {/* {`${vintage} ${producer} ${name}`} */}
        {getWineName({ name, vintage, producer })}
      </li>
    )
  );

  const bottlesCount = countBottles(order.wines || []);
  // const currency = i18n.language === 'it' ? 'eur' : 'usd';
  const caseData = new Case(order);
  const warehouseName = caseData.getWareHouseData()?.name;
  const price = caseData.getPrice(currency, charge, false);
  const { subtotal, total, totalTax } = getPaypalPriceDetails({
    casePrice: price, shipping: 0,
    tax: warehouseName === 'IWS' ? 0 : 8.25,
    user: user?.role
  })

  const needRecalculate = shippingCountry.length > 0 && currentCountry !== shippingCountry;


  return (
    <div className="order-sidebar">
      {/* <NewCaseCard
        caseData={caseData}
        showDynamicPicture={true}
        tempClass={caseColor}
        inventoryIsHidden={ true }
        previewMode={true}
        descriptionSize={0}
        isAdjustedCase={caseIsAdjusted || adjustmentCase?.id === order.id }
        isOrderCase
      /> */}
      <CaseImageWithPrice
        containerClass="case-details__image"
        caseId={caseData.id}
        wines={caseData.wines}
        image={caseData.image}
        price={`$${subtotal}`}
      />

      <WinesTypesSummary wrapperClassName="text-gray no-margin flex-center" {...winesCategories} />

      <p className="subtitle text-bold text-gray">{bottlesCount} {capitalize(t('BOTTLES'))}</p>

      <ul className="order-sidebar-wines">{winesListItems}</ul>

      <ul className="price">
        {
          !contriesNotMatch && user?.nativeCountry !== currentCountry &&
          <li className="shipping__item">
            <span>Ship to: {
              countriesTranslations[currentCountry] ?
                countriesTranslations[currentCountry][lng] : currentCountry
            }</span>

            <i>{t('INCLUDED')}</i>
          </li>
        }

        <li className="price__item">
          {
            !zipcodes && needRecalculate ?
              <button
                className="button-link text-underline text-sm"
                disabled={requestPending || state.orderRequestPending}
                onClick={handleRecalculateOrder}
              >{t('CHECKOUT_SIDEBAR_RECALCULATE_LINK')}</button>
              :
              <>{
                state.orderRequestPending
                  ? <Spinner spinnerClass="loading" />
                  : <PriceDetails subtotal={subtotal} total={total} tax={totalTax} />
              }</>

          }
        </li>

        <li><small>{t('CHECKOUT_SIDEBAR_TEXT')}</small></li>
      </ul>
    </div>
  )
}


export default OrderSidebar;
