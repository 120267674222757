import { useCallback, useEffect, useState } from "react";
import useRequests from "./requests.hook";
// DEV
// const APP_ID = 'sandbox-sq0idb-lc7TUG3omBxOB6_Y7kqZ9A';
// const LOCATION = 'LDXB1WPP9FBQ1';


// LOCAL
//// SB
// const APP_ID = 'sandbox-sq0idb-c6uVeWkJEUYfWo4UuzP5IA';
// const LOCATION = 'L27YFZCBWAS0S';
//// PROD
// const APP_ID = 'sq0idp-t9TMogSw2qHimyc-XtPlDw';
// const LOCATION = 'L4F6EQN8YZ4FN';



const usePayments = (id, location) => {
  // const { get } = useRequests();
  const payments = window.Square.payments(id, location);
  const [state, ] = useState({
    id, location, payments
  });

  // const fetchSquareCredentials = useCallback(async () => {
  //   if (state.payments) return;

  //   const response = await get({ url: `/api/content/init-payments`  });

  //   if (response?.data?.success) {
  //     const { ai: id, li: location } = response.data;
  //     const payments = window.Square.payments(id, location);
  //     setState({ id, location, payments });
  //   }
  // }, [get]);

  // useEffect(fetchSquareCredentials, []);

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();

    if (tokenResult.status === 'OK') {
      return tokenResult;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }

  const createCard = async (selectorId) => {
    try {
      const card = await state?.payments.card();

      await card.attach(selectorId);

      return card;
    } catch (err) {
      console.log('Failed to create card data form: ', err)
    }
  }

  if (!window.Square) return null;

  return state ? {
    createCard, tokenize
  } : {}
}


export default usePayments;
