import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHelpers } from '../../hooks';

import icon from '../../assets/icons/check-o.svg';

import './style.scss';


const OrderConfirmed = ({ orderNumber, orderDate, className }) => {
    const { t } = useTranslation();
    const { formatDateTime, capitalize } = useHelpers();

    return (
        <div className={classNames("order-confirmed", className)}>
            <img src={icon} alt="confirmed"/>
            <div className="confirmed-info">
                <div className="confirmed-info_order">
                    <h4 className="confirm-notification">{ t('ORDER_CONFIRMED') }</h4>
                    <p className="subtitle">{ capitalize(t('ORDER')) } #{ orderNumber }</p>
                </div>
                <div className="confirmed-info_date">
                    <p>{ t('ORDER_PLACED') }:</p>
                    <p>{ formatDateTime(orderDate) }</p>
                </div>
            </div>

        </div>
    )
}

OrderConfirmed.propTypes = {
    orderNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderDate: PropTypes.string
}

OrderConfirmed.defaultProps = {
    orderNumber: ''
}

export default OrderConfirmed
