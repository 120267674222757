import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './style.scss';


const AccountOrderContact = ({ contactData, title }) => {
  const { t } = useTranslation();
  

  return (
    <div className="body-section__row">
      <div className="body-section__title">{ title }:</div>

      <div className="body-section__content">
        <p>{
          contactData?.customer?.length > 0 ?
            contactData?.customer : t('NOT_SPECIFIED')
        },</p>

        <p>{
          !contactData || [
            contactData.address, contactData.city, contactData.zip
          ].every(contact => !contact)
            ? t('NOT_SPECIFIED')
            : `${contactData.address}, ${contactData.city},${!!contactData.state && ' ' + contactData.state + ', '}${contactData.zip}`
        }</p>
      </div>
    </div>
  )
}


AccountOrderContact.propTypes = {
  contactData: PropTypes.shape({
    customer: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    state: PropTypes.string
  }),
  title: PropTypes.string
}

export default AccountOrderContact