import { createSlice } from '@reduxjs/toolkit'



const initialState = {
  processingRequest: false,
  processingRequests: 0,
  error: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  isAdult: undefined,
  role: undefined
}

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        (action) => action.type.startsWith('@@user') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@user') && action.type.endsWith('/pending'),
          (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@user') && action.type.endsWith('/fulfilled'),
          (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
  }
})


export const userActions = { ...userSlice.actions };

export default userSlice.reducer;
