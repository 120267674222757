import { createAsyncThunk } from '@reduxjs/toolkit'
import * as ProducerService from '../../services/producer.service';
import { wineActions } from '../wines/wine.slice'



export const requestProducerCasesPreviewslist = createAsyncThunk(
  '@@producers/request-producer-cases-previews-list',
  async ({ producerId, regionId }, thunkApi) => {
    try {
      const data = await ProducerService.getProducerCasesPreviewsList({ producerId, regionId })

      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

export const requestProducerData = createAsyncThunk(
  '@@producers/request-producer-data',
  async ({ producerId }, thunkApi) => {
    try {
      const data = await ProducerService.getProducerData({ producerId })

      if (data && Object.keys(data).length > 0) {
        const { id: producer, region } = data;
        thunkApi.dispatch(wineActions.requestWinesPreviewList({ producer, regionId: region.id, warehouse: 2 }))
        thunkApi.dispatch(requestProducerCasesPreviewslist({ regionId: region.id, producerId }))
      }
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)

export const requestProducersPreviewList = createAsyncThunk(
  '@@producers/request-producers-preview-list',
  async (_, thunkApi) => {
    try {
      const producers = await ProducerService.getProducersPreviewList();

      return producers;
    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
)
