import React from 'react';
import PropTypes from 'prop-types';
import { ShortDescription } from '..'; 

import locationIcon from '../../assets/icons/map-pin-line.svg';
// import infoIcon from '../../assets/icons/info-circle.svg';

import './style.scss';
import { useHelpers } from '../../hooks';


const RecommendationWineCard = ({
  name, image, quantity, vintage, region, country, producer, description, category, grape, tastes, thumbnail
}) => {
  const { getWineName } = useHelpers();
  
  const grapeClass = `wine-grape grape_${category}`;
  const wineLocation = country?.toLowerCase() === region?.toLowerCase()
    ? ` ${ region }` : ` ${ region }, ${country}`
    

  return (
    <div className="card wine_card wine_recommendation">
      <div className="card-header">
        <img src={thumbnail || image} alt={name} />

        <div className="badge">{'x' + quantity}</div>

        {/* <p className="wine__name">{`${vintage} ${producer} ${name}`}</p> */}
        <p className="wine__name">{ getWineName({ vintage, producer, name }) }</p>
      </div>

      <div className="card__body">
        {/* <p>{vintage}</p> */}

        {/* <h4 className="wine__name">{name}</h4> */}

        <p className="wine__region">
          <img src={locationIcon} alt="location" />
          {wineLocation}
        </p>

        <div className="wine-space-between">
          <span className={grapeClass} style={{ justifyContent: 'flex-start !important' }}>{grape}</span>

          {/* <button
            className="change-case-button"
            onClick={toggleExpandState}
          ><img src={infoIcon} alt={grape} /></button> */}
        </div>

          <div className="wine-additional-data">
            <ShortDescription expandable={true} lines={5}>
              <p>{description}</p>
            </ShortDescription>

            <ul className="wine-tastes">{
              tastes.map(t => <li key={t}>{t}</li>)
            }</ul>
          </div>
      </div>

    </div>
  )
}

RecommendationWineCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  vintage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

RecommendationWineCard.defaultProps = {
  image: 'https://vineify-dev-files.s3.amazonaws.com/images/wines/vineify_logo_bg.png'
}

export default RecommendationWineCard;
