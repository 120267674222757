import axios from 'axios'


export const fetchOrdersList = async ({ caseId, orderTypes, userId, warehouse, from, to, incomplete, location }) => {
  // { ot: orderTypes, caid: caseId, cuid: userId, wh: warehouse, from, to, incomplete, loc: location }
  const queryParams = []
  orderTypes &&     queryParams.push(`ot=${orderTypes}`) // 0 - all, 1 - real, 2 - test
  caseId &&         queryParams.push(`caid=${caseId}`)
  userId &&         queryParams.push(`cuid=${userId}`)
  warehouse &&      queryParams.push(`wh=${warehouse}`)
  from &&           queryParams.push(`from=${from}`)
  to &&             queryParams.push(`to=${to}`)
  incomplete &&     queryParams.push(`ot=${incomplete}`)
  location &&       queryParams.push(`loc=${location}`)

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ``;

  const response = await axios.get(`/api/orders/get-all${queryString}`)

  if (response?.data?.success) {
    return response.data.orders
  }

  return []
}

export const fetchUserOrders = async ({ language = 'en' }) => {
  const response = await axios.get(`/api/orders?lng=${language}&new=2`)

  if (response?.data?.success) {
    return response.data.orders
  }

  return []
}