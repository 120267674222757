import { useDispatch, useSelector } from "react-redux";
import { wineActions as actions } from './wine.slice'
import { requestCurrentUserFavouriteWines, requestWinesList, requestCurrentUserOrderedWines, requestUpdateUserWineStatistics, requesCreatetOrderByWineId, requestWinesPreviewList, requestWineCasesPreviewsList, requestWinePreviewData, requestWinesCatalog, requestWineData, requestWineSalesReport, requestWineLabels, requestAddNewLabel, requestDeleteLabel, requestUpdateLabel, requestRestockHistory, requestWineCasesList, requestWineOrphansList } from './wine.actions'


const useWines = () => {
  const dispatch = useDispatch()
  const {
    processingRequest,
    processingRequests,
    error,
    list,
    labels,
    currentWineData,
    dataIsFetched,
    processingWineIds
  } = useSelector(state => state.wines)
  const winesState = {
    processingRequest,
    processingRequests,
    error,
    list,
    labels,
    currentWineData,
    dataIsFetched,
    processingWineIds
  }
  const wineActions = {}

  wineActions.requestCurrentUserFavouriteWines = () => dispatch(requestCurrentUserFavouriteWines())

  wineActions.requestWineData = ({ wineId, active }) => dispatch(requestWineData({ wineId, active }))

  wineActions.requestCurrentUserOrderedWines = ({ language = 'en' }) => dispatch(requestCurrentUserOrderedWines({ language }))

  wineActions.requesCreatetOrderByWineId = (wineId) => dispatch(requesCreatetOrderByWineId(wineId))

  wineActions.requestWinesCatalog = (language = 'en') => dispatch(requestWinesCatalog(language))

  wineActions.requestWineLabels = () => dispatch(requestWineLabels())

  wineActions.requestWineOrphansList = () => dispatch(requestWineOrphansList())

  wineActions.requestAddNewLabel = (text) => dispatch(requestAddNewLabel(text))

  wineActions.requestWineCasesList = (wineId) => dispatch(requestWineCasesList(wineId))

  wineActions.requestUpdateLabel = ({ id, text, index }) => dispatch(requestUpdateLabel({ id, text, index }))

  wineActions.requestDeleteLabel = (id) => dispatch(requestDeleteLabel(id))

  wineActions.requestRestockHistory = (wineId) => dispatch(requestRestockHistory(wineId))

  wineActions.requestWineSalesReport = (wineId) => dispatch(requestWineSalesReport(wineId))

  wineActions.requestWinePreviewData = ({slug, wineData}) => dispatch(requestWinePreviewData({slug, wineData}))

  wineActions.requestWineCasesPreviewsList = ({ slug, regionId, producer }) => 
    dispatch(requestWineCasesPreviewsList({ slug, regionId, producer }))

  wineActions.requestWinesPreviewList = ({ producer, regionId, id, warehouse }) => 
    dispatch(requestWinesPreviewList({ producer, regionId, id, warehouse }))

  wineActions.requestUpdateUserWineStatistics = ({ 
    index, wineId, amountBottlesDrunk: bottlesDrunk, willRecomend, hideFeedback, isFavourite: isFavouriteRequest, wines 
  }) => dispatch(requestUpdateUserWineStatistics({ 
    index, wineId, amountBottlesDrunk: bottlesDrunk, willRecomend, hideFeedback, isFavourite: isFavouriteRequest, wines 
  }))

  wineActions.requestWinesList = ({ language, warehouseId, regions = [] }) => 
    dispatch(requestWinesList({ language, warehouseId, regions }))

  wineActions.resetWinesStore = () => dispatch(actions.resetWinesStore())
  
  wineActions.hideWineFeedback = (index) => dispatch(actions.hideWineFeedback(index))

  wineActions.clearWinesList = () => dispatch(actions.clearWinesList())

  wineActions.changeCurrentWineData = (wineData) => dispatch(actions.changeCurrentWineData(wineData))


  return { winesState, wineActions }
}

export default useWines;
