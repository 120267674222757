import { createSlice } from '@reduxjs/toolkit'
import { requestAddAdjustedCaseToCart, requestAddCaseToCart, requestPlaceOrder } from '../checkout/checkout.actions'
import { login, logout, authenticate, requestCurrentUserOrders, requestRegisterUser, requestChangeLegalAge, requestChangeAccountData, requestAiSignIn, requestFollowCurator } from './auth.actions'
import { requestCurrentUserFavouriteWines } from '../wines/wine.actions'


const initialState = {
  errors: undefined,
  processingRequest: false,
  processingRequests: 0,
  regionRecommendationCase: undefined,
  redirectPath: undefined,
  location: undefined,
  userAgent: undefined,
  user: {
    id: -1,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    isAdult: undefined,
    role: undefined,
    preferences: undefined,
    warehouses: undefined,
    hasNewWines: undefined,
    country: undefined,
    nativeCountry: undefined,
    currency: undefined,
    lastBilling: undefined,
    language: undefined,
    recommendedCase: undefined,
    customCase: undefined,
    orderedCases: [],
    favouriteWines: [],
    followedCurators: []
  },
  isAuthenticated: false,
  isAiAuthenticated: false,
  ip: undefined,
  country: undefined,
  region: undefined,
  initialDataIsFetched: false,
}


const authSlice = createSlice({
  name: '@@auth',
  initialState,
  reducers: {
    clearErrors: (state) => { state.errors = undefined },
    setRecommendedCaseId: (state, { payload }) => {
      state.user.recommendedCase = payload
    },
    changeRedirectPath: (state, { payload }) => {
      state.redirectPath = payload
    },
    changeCheckoutLegalAgeAnswer: (state, { payload }) => {
      state.answer = payload
    },
    changeUserdata: (state, {payload}) => {
      return {
        ...state, user: { ...state.user, ...payload }
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(authenticate.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.isAiAuthenticated = payload.isAiAuthenticated 
          || payload.user?.isAiAuthenticated
        
        if (payload.location) {
          state.location = payload.location
        }

        state.isAuthenticated = payload?.user?.id > 0;

        if(payload.config) {
          state.config = payload.config
        }

        if (payload.userAgent) {
          state.userAgent = payload.userAgent
        }
        
        if (payload.ip) {
          state.ip = payload.ip
        }
        
        if (payload.redirectPath) {
          state.redirectPath = payload.redirectPath
        }
        
        state.initialDataIsFetched = true
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        if (payload?.errors) {
          state.errors = payload.errors
        } else {
          state.isAuthenticated = true
          state.redirectPath = payload.redirectPath
        }

      })
      .addCase(requestAiSignIn.fulfilled, (state, { payload }) => {
        if (!payload.success) {
          state.errors = { message: payload.message }
        }
        state.isAiAuthenticated = payload.success
      })
      .addCase(requestFollowCurator.fulfilled, (state, { payload }) => {
        const { curatorId, status, index } = payload;

        if (status === 'follow') {
          if (index >= 0) {
            state.user.followedCurators[index] = status
          } else {
            state.user.followedCurators = [ ...(state.user.followedCurators || []), curatorId ]
          }
        } else {
          const newCuratorsIdList = state.user.followedCurators?.filter(id => +id !== +curatorId) || []
          state.user.followedCurators = newCuratorsIdList
        }
      })
      .addCase(requestAiSignIn.rejected, (state, { payload }) => {
        state.errors = payload
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.user = {...initialState.user};
        state.isAuthenticated = false;
        state.redirectPath = payload.redirectPath || '/login'
      })
      .addCase(requestCurrentUserOrders.fulfilled, (state, { payload }) => {
        state.user.orderedCases = payload
      })
      .addCase(requestAddCaseToCart.fulfilled, (state, { payload }) => {
        if (payload.redirectToCart) {
          state.redirectPath = '/cart/payment'
        }
      })
      .addCase(requestAddAdjustedCaseToCart.fulfilled, (state, { payload }) => {
        if (payload.redirectToCart) {
          state.redirectPath = '/cart/payment'
        }
      })
      .addCase(requestCurrentUserFavouriteWines.fulfilled, (state, { payload }) => {
        state.user.favouriteWines = JSON.parse(payload)
      })
      .addCase(requestPlaceOrder.fulfilled, (state) => {
        state.user.hasNewWines = true
        state.redirectPath = '/cart/success'
      })
      .addCase(requestRegisterUser.fulfilled, (state, { payload })  => {
        state.user.email = payload?.email
        state.user.firstName = payload?.firstName
        state.user.lastName = payload?.lastName

        if (payload.success) {
          state.redirectPath = payload.redirectPath
        } else {
          const message = payload.message?.en || payload.message
          state.errors = { ...payload?.errors || {}, message }
        }
      })
      .addCase(requestChangeAccountData.fulfilled, (state, { payload }) => {
        if (Object.keys(payload).length > 0) {
          state.user = { ...state.user, ...payload }
        }
      })
      // .addCase(requestChangeLegalAge.pending, (state))
      .addCase(requestChangeLegalAge.fulfilled, (state, { payload: isAdult }) => {
        if (isAdult !== undefined) {
          state.isAdult = isAdult
          state.user.isAdult = isAdult
        }
      })
      .addMatcher(
        (action) => action.type.startsWith('@@auth') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.errors = { message: action.payload || action.error.message };
        })
      .addMatcher(
        (action) => action.type.startsWith('@@auth') && action.type.endsWith('/pending'),
          (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@auth') && action.type.endsWith('/fulfilled'),
          (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
  }
})


export const authActions = { ...authSlice.actions, login, logout, authenticate, requestRegisterUser, requestChangeLegalAge, requestChangeAccountData, requestCurrentUserOrders };

export default authSlice.reducer;
