import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHelpers, useRequests } from '../../hooks';
import VerificationAlert from '../alert/VerificationAlert';

import icon from '../../assets/icons/check-o.svg';
import { Link } from 'react-router-dom';


const ForgotPasswordForm = () => {
  const { t, i18n } = useTranslation();
  const { isEmail, objectIsEmpty, capitalize } = useHelpers();
  const { post, errors, requestPending } = useRequests();
  const [email, changeEmail] = useState('');
  const [accountNotCreated, setAccountNotCreated] = useState(false)
  const [resetPasswordLinkIsSent, setResetPasswordLinkSendingStatus] = useState(false);

  const handleChange = e => {
    accountNotCreated && setAccountNotCreated(false)
    changeEmail(e.target.value);
  }

  const handleRequestPasswordReset = async e => {
    e.preventDefault();

    const response = await post({
      url: `/api/users/reset-password?lng=${i18n.language}`,
      body: { email }
    });

    if (response && response.data.success) {
      setResetPasswordLinkSendingStatus(true);
    } else {
      setAccountNotCreated(true)
    }
  }


  if (resetPasswordLinkIsSent) {
    return <VerificationAlert
      icon={icon}
      title={t('RESET_PASSWORD_SENT_TITLE')}
      msg={t('RESET_PASSWORD_SENT_MESSAGE')}
    />
  }


  return (
    <form className="small-form">
      {
        errors && !objectIsEmpty(errors?.errors || {}) &&<>{
          errors?.errorCode === 'ACCOUNT_NOT_CREATED'
          ? <div className="error-msg-block"><p>We couldn'f find an account with that email.<br /><Link to="/create-account">Click here to create a Vineify account now</Link></p></div>
          : <div className="error-msg-block"><p>{errors.message}</p></div>
        }</>
      }
      <div className={classNames('form-control', {
        'invalid': email.length > 0 && !isEmail(email)
      })}>
        <label htmlFor="verification">{capitalize(t('email'))}</label>
        <input
          name="email" type="email"
          placeholder="enter your email"
          value={email}
          onChange={handleChange}
        />
        {
          email.length > 0 && !isEmail(email) &&
          <span className="error-msg">{t('RESET_PASSWORD_FORM_EMAIL_NOT_VALID')}</span>
        }
      </div>

      <div className="form-control">
        <button
          className="button w-100"
          onClick={handleRequestPasswordReset}
          disabled={!isEmail(email) || requestPending}
        >{t('RESET_PASSWORD_FORM_BTN_TEXT')}</button>
      </div>
    </form>
  )
}

export default ForgotPasswordForm;
