import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as generateId } from 'uuid'
import { useRequests } from '../../hooks';
import { Spinner } from '..';


const GenerateAiTextForm = ({ wines, initialPromt, className, onChangeDescription, logRequests }) => {
  const [state, changeState] = useState({
    prompt: initialPromt || ``, aiDescription: ``, processingRequest: false, requestId: undefined
  })
  const [requests, changeRequests] = useState({})
  const { post, get } = useRequests();
  const classesList = ['ai-description-form']
  className && classesList.push(className)

  const classNames = classesList.join(' ')

  // const defaultPromt = `Write an article about a product which is made up of the wines ${ winesData.join(', ') }.  Include tasting notes and food pairings.`


  const getGeneratedDescription = async (requestId) => {
    const response = await get({ url: `/api/wines/cases/generated-description/${requestId}` })

    if (response?.data?.success) {
      changeState(prev => ({ ...prev, aiDescription: response.data.description?.trim(), processingRequest: false }))
      changeRequests(prev => {
        const newObject = { ...prev }

        if (newObject[requestId] !== undefined) {
          delete newObject[requestId]
        }

        return newObject
      })
    } else {
      if (!requests[requestId] || requests[requestId] < 3) {
        changeRequests(prev => {
          const countRequests = prev[requestId] 
            ? prev[requestId] + 1
            : prev[requestId] === 0 ? 1 : 0

            return { ...prev, [requestId]: countRequests }
        })

        getGeneratedDescription(requestId)
      } else {
        changeState(prev => ({ ...prev, processingRequest: false, error: `Something went wrong...` }))
      }
    }
  }

  const handleGetAiDescription = async e => {
    e.preventDefault();
    const requestId = generateId()

    changeState(prev => ({ ...prev, processingRequest: true }))

    const response = await post({ 
      url: '/api/wines/cases/generate-description', 
      body: { prompt: state.prompt, logRequests, requestId },
      config: { timeout: 2 * 60 * 1000 }
    })

    if (response?.data?.success) {
      changeState(prev => ({ ...prev, requestId }))
      changeRequests(prev => ({ ...prev, [requestId]: 0 }))
      // changeState(prev => ({ ...prev, aiDescription: response.data.description?.trim(), processingRequest: false }))
      getGeneratedDescription(requestId)
    }
  }

  const handleChangeData = e => {
    const { name, value } = e.currentTarget;

    changeState(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    onChangeDescription && onChangeDescription(state.aiDescription)
  }, [state.aiDescription])


  return (
    <form className={classNames}>
      <div className="form-control">
        <label htmlFor="prompt">Prompt</label>

        <textarea
          name="prompt" id="prompt" rows="6"
          disabled={state.processingRequest}
          value={state.prompt}
          onChange={handleChangeData}
        />

        <br />

        <button className="button generate"
          style={{ alignSelf: 'flex-end' }}
          onClick={handleGetAiDescription}
          disabled={state.processingRequest || wines?.length === 0}
          type="button"
        >Generate{state.processingRequest && <Spinner spinnerClass="pending" size={30} />}</button>
      </div>

      <div className="form-control">
        <label htmlFor="aiDescription">Generated Description</label>

        <textarea
          name="aiDescription"
          id="aiDescription" rows="6"
          disabled={state.processingRequest}
          onChange={handleChangeData}
          value={state.aiDescription}
        />
      </div>
    </form>
  )
}

GenerateAiTextForm.propTypes = {
  onChangeDescription: PropTypes.func
  , wines: PropTypes.array
  , initialPromt: PropTypes.string
}

GenerateAiTextForm.defaultProps = {
  logRequests: false
}

export default GenerateAiTextForm
