import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRequests } from '../../hooks'; 
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { OrderSidebar } from '..';

import shippingIcon from '../../assets/icons/shipping-icon.svg';
import CountryMenu from '../menu/CountryMenu';
import { useSelector } from 'react-redux';


const OrderCheckoutStep = ({ caseName }) => {
  const { t } = useTranslation();
  const { warehouse, paymentMethod } = useSelector(state => state.checkout);
  const { post } = useRequests();

  const handleScrollToPayment = e => {
    e.preventDefault();

    const paymentSection = document.getElementById('payment');

    paymentSection && scrollIntoView(paymentSection, { behavior: 'smooth', scrollMode: 'if-needed', block: 'center' })
  }

  const handleSaveCountry = country => {
    post({ url: '/api/users/preferences', body: { country } });
  }


  // const wareHouse = new Case(order?.case)?.getWareHouseData();

  const shippingDescriptionParts = warehouse?.shippingDescription?.split('{{faq}}');

  const shippingDescription = warehouse?.shippingDescription ? (
    <p className="formatted-spaces">
      {shippingDescriptionParts[0]?.trim()} <Link to="/faq" className="button-link"><b>FAQ</b></Link> {shippingDescriptionParts[1]?.trim()}
    </p>
  ) : null;

  return (
    <>
      {
        paymentMethod === 'paypal' &&
        <>
          <h2>{t('CHECKOUT_TITLE')}!</h2>

          <p className="text-primary"
            dangerouslySetInnerHTML={{
              __html: t('CHECKOUT_CASE_LINE', { caseName, interpolation: { escapeValue: false } })
            }}
          />
        </>
      }

      {
        paymentMethod === 'paypal' &&
        <h3 className="order-subtitle">
          <img
            style={{ marginRight: '16px' }}
            src={shippingIcon} alt="delivery"
          />

          {t('CHECKOUT_REVIEW_SHIPPING')}
        </h3>
      }

      {
        paymentMethod === 'paypal' &&
        <>
          {/* <p className="formatted-spaces"
          dangerouslySetInnerHTML={{
            __html: t('CHECKOUT_SHIPMENT_DESCRIPTION', { link: <Link className="text-underline" to="/faq"><b>FAQ page</b></Link>, interpolation: { escapeValue: false } })
          }}
        /> */}
        <div className="show-xs"><OrderSidebar /></div>

          {shippingDescription}

          <div className="shipping-cost">
            {
              !warehouse?.zipcodes &&
              <div className="shipping-country-block">
                  Change shipping country section

                  <b>{t('SHIP_TO')}</b>
                  <CountryMenu
                    mainClassName="country-dropdown-menu"
                    withIcon={false}
                    onChange={handleSaveCountry}
                  />
              </div>
            }

            {/* {
              window.matchMedia('(max-width: 512px)').matches &&
              <div className="case-price-block">
                {requestPending ? <Spinner /> : casePrice + ' ' + currency.toUpperCase()}
              </div>
            } */}
          </div>

          <div className="info-block"
            dangerouslySetInnerHTML={{
              __html: t('CHECKOUT_INFO_BLOCK', { interpolation: { escapeValue: false } })
            }}
          /></>
      }
    </>
  )
}


OrderCheckoutStep.propTypes = {
  casePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caseName: PropTypes.string
}

export default OrderCheckoutStep;
