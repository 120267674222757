import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './style.scss'
import Icon from '../common/Icon'

// {
//   "name": "John",
//   "subject": "Recommendation",
//   "sender": "Test-Curator",
//   "isCurated": true,
//   "homapage": "https://vineify-branch.com",
//   "caseData": {
//       "name": "Hello Rosé!",
//       "description": "This exclusive collection of bottles of rosé wine is perfect for any connoisseur. Start with the Castellroig Rosat Brut, a crisp and bubbly blend of Tempranillo and Garnacha. ",
//       "link": "https://vineify.com",
//       "image": "https://vineify-dev-files.s3.amazonaws.com/1gsmp4845_Test-3-Customers-Case.jpg",
//       "categories": ["2 Reds", "2 Sparkling", "4 Whites"]
//   },
//   "curator": {
//       "photo": "https://vineify-dev-files.s3.amazonaws.com/1fqnves94_stefano.jpg",
//       "name": "Stefano",
//       "lastName": "Campaniello",
//       "link": "https://vineify-branch.com/curators/1",
//       "message": "this is message from curator"
//   }
// }


const CuratorRecommendationPreview = ({ name, sender, subject, isCurated, caseData, curator, onClose }) => {
  const caseCategories = caseData.categories?.map(category => <p className="case-category">{category}</p>)
  const handleCloseDialog = e => {
    e.preventDefault()

    onClose()
  }


  return (<div className="email-preview">
    <header styie="padding:15px">


      {/* <!-- jab: added hspace to logo --> */}
      <img src="http://cdn.mcauto-images-production.sendgrid.net/501be10801dfadfb/ff076ace-1a97-427c-9a81-ce00414768f6/196x50.png" alt="Vineify logo" hspace="15" />
    </header>


    <div className="content">
      <div className="header-content">
        <div className="header-content__text">
          <div className="header-content__curator">
            <Link target="_blank" to={curator.link} className="header-content__curator-photo"><img src={curator.photo} alt="" /></Link>

            <div className="header-content__curator-text">
              <p>Curated by</p>

              <Link target="_blank" to={curator.link} className="name">{curator.name} {curator.lastName}</Link>
            </div>
          </div>
          <h2>Recommended Just For You</h2>
        </div>


        <div className="header-content__case">
          <h3 className="case-name">{caseData.name}</h3>

          <Link target="_blank" to={caseData.link} className="header-content__image">
            <img src={caseData.image} />
          </Link>

          <div className="header-content__case-categories">
            {caseCategories}
          </div>
        </div>
      </div>
      <div className="body-content">
        <p>Hello{name && <> {name}</>},</p>
        <br />
        {
          isCurated &&
          <b>{curator.name} has curated a selection of wines just for you!</b>
        }
        {
          !isCurated &&
          <b>{ sender } has selected this collection of wines just for you!</b>
        }
        <p>

        </p>
        <Link target="_blank" to={caseData.link}><b><u>{ caseData.name }</u></b></Link>
        <p>{caseData.description}</p>

        <br />

        <p><Link target="_blank" to={caseData.link}><u>Click here</u></Link> to review the contents and place your order now</p>

        {
          curator.message &&
          <>
            <br />
            <p>Message from { !isCurated ? sender : curator.name},</p>
            <p>{curator.message}</p>
          </>
        }

        <br />
        <p>Best,</p>
        <p>The Vineify Team</p>

        <br />
        <Link target="_blank" className="case-link-button" to={caseData.link}>View the selection</Link>
      </div>


      <footer>
        <p style={{ margin: 0, color: '#787878', marginBottom: '8px', textAlign: 'center' }}>© Vineify Inc.</p>
        <p style={{ margin: 0, color: '#787878', marginBottom: '8px', textAlign: 'center' }}>If you have any questions please contact us at <Link target="_blank" style={{ color: '#224C31' }} to="mailto:support@vineify.com">support@vineify.com</Link>
        </p>
      </footer>
    </div>

    <button
      className="button-link close-modal-btn"
      onClick={handleCloseDialog}
    ><Icon icon="close" color="vineify-green" size={24} /></button>
  </div>)
}

CuratorRecommendationPreview.propTypes = {
  name: PropTypes.string,
  subject: PropTypes.string,
  sender: PropTypes.string,
  isCurated: PropTypes.bool,
  caseData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string)
  }),
  curator: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    lastName: PropTypes.string,
    link: PropTypes.string,
    message: PropTypes.string
  })
}

export default CuratorRecommendationPreview
