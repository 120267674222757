import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import './style.scss';


const  TextEditor = ({ onChange, initialValue, name }) => {
  const editorRef = useRef(null);

  const handleChange = () => {
    if (editorRef.current) {
      onChange( editorRef?.current?.getContent() || '');
    }
  };

  return (
    <>
      <Editor
        apiKey="yzlkdcjsr8dpw63v32uopm7q2p6ihjoldxaweqj4qlq1z102"
        onInit={(e, editor) => editorRef.current = editor}
        initialValue={ initialValue }
        onChange={ handleChange }
        onUndo={ handleChange }
        onRedo={ handleChange }
        textareaName={ name }
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
          content_style: 'body { font-size:14px }'
        }}
      />
    </>
  );
}


TextEditor.propTypes = {
    initialValue: PropTypes.oneOfType([
        PropTypes.string, PropTypes.node
    ]),
    name: PropTypes.string,
    onChange: PropTypes.func
}

TextEditor.defaultProps ={
    initialValue: ''
}

export default TextEditor;
