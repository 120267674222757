import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { useHelpers } from '../../hooks';
import AdminWineCardPopover from '../common/AdminWineCardPopover';

import heartIcon from '../../assets/icons/heart-filled.svg';
import RecomendBadge from '../badge/RecomendBadge';
import CountInput from '../form/CountInput';

import './style.scss';
import classNames from 'classnames';

const StockBadge = ({ onHover, onBlur, stock }) => {
  const getColor = stock => {
    let color = 'red';

    if ([0, 1, 2].includes(5 - stock)) color = 'orange';
    if ([0, 1].includes(7 - stock)) color = 'blue';
    if (stock > 7) color = 'green';

    return color;
  }

  // const colors = {
  //   'red': '#782A2A',
  //   'blue': '#0090DB',
  //   'orange': '#FF9700',
  //   'green': '#7AB800',
  // }

  const currentColor = getColor(stock)

  return (
    <div
      className="stock-badge"
      onMouseOut={onBlur}
      onMouseOver={onHover}
    >
      {stock} <span className={`icon ${currentColor}`} />
    </div>
  )
}


const AdminCaseWineCard = ({ onWineChange, wineData, currency, showPrice, isMarkupPrice }) => {
  const { name, image, category, quantity, stock, order, willRecomend, isFavourite, cost, costUsd, vintage, producer, region, id, cases, sortOfGrape, labels } = wineData;
  const { getFullWineName, getWineMarkupPrice } = useHelpers();
  const [popoverContent, setPopoverIsOpenState] = useState();
  const wineImageRef = useRef();

  const handleChangeWineField = field => (value, action) => {
    onWineChange({ id, field, value, action })
  }

  const togglePopoverState = content => e => {
    setPopoverIsOpenState(content)
  }

  const winePrice = `${currency === 'eur' ? `€${isMarkupPrice ? getWineMarkupPrice(+cost) : cost}` : ''}${currency === 'usd' ? `$${isMarkupPrice ? getWineMarkupPrice(+costUsd) : costUsd}` : ''}`


  const listOfCasesWithThisWine = cases?.some(({ id }) => !!id) ?
    cases?.map(({ id, name, quantity, active }) => {

      return (
        <li key={`wine-case-stat-${id}`} className={classNames('wine-case-stat-item', { 'active': active > 0 })}>
          <span>{name}</span>
          <span className="dots" />
          <span>{quantity}</span>
        </li>
      )
    }) : (
      <li key={`wine-case-stat-no-cases`} className="wine-case-stat-item">
        This wine is not added in any case...
      </li>
    );

  const wineName = getFullWineName({ vintage, producer, name })

  return (
    <Popover
      isOpen={!!popoverContent}
      padding={popoverContent === 'wine-data' ? -420 : -670}
      positions={['left', 'top']}
      content={
        ({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            nudgedLeft={0}

            arrowColor={'white'}
            arrowSize={0}
          // arrowClassName="popover-arrow"
          ><div className="popover-container wine-popover">
              {
                popoverContent === 'case-stats' &&
                <ul className="wine-case-stat-list">
                  {/* <li className="title">List of cases</li> */}

                  {listOfCasesWithThisWine}
                </ul>
              }
              {
                popoverContent === 'wine-data' &&
                <AdminWineCardPopover {...wineData} description={wineData.descriptions.en} />
              }

            </div>
          </ArrowContainer>
        )
      }
    >
      <div
        className={`case-form-wine-row${quantity > 0 ? ' active' : ''}`}
        disabled={stock === 0}
      ><div className={`grape_${category} wine__category-badge`} />
        {
          // stock === 0 &&
          quantity > 0 && +quantity > +stock &&
          <div className="out-of-stock-layout" />
        }
        <div className="wine-stock-with-img">

          <div
            ref={wineImageRef}
            className="wine-row__img-container"
            onMouseOver={togglePopoverState('wine-data')}
            onMouseLeave={togglePopoverState()}
          ><img className="wine-img" src={image} alt={name} />
          </div>

          <StockBadge onHover={togglePopoverState('case-stats')} onBlur={togglePopoverState()} stock={stock} />
        </div>

        <div className="flex-column flex-grow">
          <div className="flex-space-between w-100">
            <div className={`wine-name ${category}`} title={name} style={{ flexGrow: 1, width: '120px' }}><b
              onMouseOver={togglePopoverState('case-stats')}
              onMouseOut={togglePopoverState()}
              className="text-blue">({stock})</b> {wineName}
            </div>

            {
              category === 'custom' &&
              <div className="flex-space-between" style={{ gap: '4px', padding: '2px 8px' }}>
                {isFavourite && <img src={heartIcon} alt="" className="case-form-wine-row__icon" />}

                {
                  ['yes', 'maybe', 'no'].includes(willRecomend || '') &&
                  <RecomendBadge answer={willRecomend} />
                }
              </div>
            }

            <b className="wine-region">{region}</b>
          </div>

          <div className="text-lg gap_sm" style={{ display: 'flex' }}>
            {
              showPrice &&
              <div className="wine-cost text-lg">
                <b>Cost:</b> {winePrice}
              </div>
            }

            {/* <b className="wine-cost">{region}</b> */}
            <div
              className="wine-cost"
              onMouseOver={togglePopoverState('case-stats')}
              onMouseOut={togglePopoverState()}
            ><b>In {cases?.length || 0} {cases?.length === 1 ? 'Case' : 'Cases'}</b></div>

            <ul className="wine-labels">
              {labels?.length > 0 && labels?.map(labelData => (
                <li key={labelData.text} className={`wine-label ${labelData.status}`}>{labelData.text}</li>
              ))}
            </ul>

            <b className="wine-cost">{sortOfGrape}</b>
          </div>

          <div className="flex-space-between" style={{ padding: '0 16px' }}>

            <div className="flex-space-between">
              <CountInput name="wine-name"
                label="qty:"
                negativeValues={true}
                containerClass="no-padding"
                value={quantity}
                onChange={handleChangeWineField('quantity')}
              />

              {
                quantity > 0 && +quantity > +stock &&
                <p className="not-enought-bottles-text">there are not enough bottles.</p>
              }
            </div>


            {
              quantity > 0 &&
              <CountInput name="wine-name"
                label="order:"
                containerClass="no-padding"
                value={order}
                onChange={handleChangeWineField('order')}
              />
            }
          </div>
        </div>
      </div>
    </Popover>
  )
}

AdminCaseWineCard.propTypes = {
  onWineChange: PropTypes.func,
  name: PropTypes.string,
  region: PropTypes.string,
  quantity: PropTypes.number,
  cost: PropTypes.number,
  costUsd: PropTypes.number,
  stock: PropTypes.number,
  willRecomend: PropTypes.oneOf(['yes', 'no', 'maybe', 'no_answer']),
  currency: PropTypes.oneOf(['usd', 'eur']),
  isFavourite: PropTypes.bool,
  producer: PropTypes.string,
  image: PropTypes.string,
  vintage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default memo(AdminCaseWineCard)
