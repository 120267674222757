import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../common/Icon'

const FacebookWineCard = ({ image, tastes, region, country }) => {
  const icon = `country-${country?.toLowerCase()}`;
  const tastesList = tastes?.map(taste => (
    <li key={`fb-wine-taste-${taste}}`}>{ taste }</li>
  ));

  return (
    <div className="facebook-wine">
      <img src={image} alt="wine" />

      <div className="region">
        <Icon icon={icon} color="warm-gray-9" /> {country}
      </div>

    <div className="flex-grow" />

      <ul className="tastes">{tastesList}</ul>
    </div>
  )
}

FacebookWineCard.propTypes = {
  image: PropTypes.string,
  tastes: PropTypes.arrayOf(PropTypes.string),
  region: PropTypes.string,
  country: PropTypes.string
}

export default FacebookWineCard