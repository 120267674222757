import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as keyGen } from 'uuid';

import './style.scss';


const TabbedInput = ({ name, label, placeHolder, onChange, tabs: tabsList, parentControl, style }) => {
  const [tabs, changeTabs] = useState(tabsList);
  const [activeTab, changeActiveTab] = useState(tabsList[0].tabName)

  const changeTextTab = e => {
    const { tab } = e.target.dataset;

    changeActiveTab(tab);
  }

  const handleContentChange = e => {
    e.preventDefault();

    const { value: text, name: inputName } = e.target;
    const [, tabName] = inputName.split('_');

    const newTabs = tabs.map(
      tab => {
        if (tab.tabName !== tabName) return tab;

        return { ...tab, text }
      }
    );

    !parentControl && changeTabs(newTabs);
    onChange(name, newTabs, activeTab);
  }


  const inputTabs = tabs.map(
    ({ tabName: name }) => {
      return (
        <li key={keyGen()} className="tag">
          <button
            className={classNames('button-badge tag-button', { 'selected': name === activeTab })}
            data-tab={name}
            onClick={changeTextTab}
          >{name}</button>
        </li>
      )
    }
  );


  if (tabs?.length === 0) return null;

  const text = parentControl ?
    tabsList.find(
      ({ tabName }) => tabName === activeTab
    ).text
    : 
    tabs.find(
      ({ tabName }) => tabName === activeTab
    ).text;


  return (
    <div className="form-control" style={ style }>
      <div style={{ justifyContent: 'flex-start !important', display: 'flex', alignItems: 'center' }}>
        <label htmlFor={name}>{label}</label>

        {
          tabsList?.length > 1 &&
          <ul className="form-control__tabs admin-tags-list">{inputTabs}</ul>
        }
      </div>

      <input
        name={`${name}_${activeTab}`}
        id={`${name}_${activeTab}`}
        placeholder={placeHolder}
        value={text}
        onChange={handleContentChange}
      />
    </div>
  )
}


TabbedInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.string
  })),
  onChange: PropTypes.func
}

TabbedInput.defaultProps = {
  placeHolder: '',
  name: 'input',
  parentControl: false
}

export default TabbedInput;
