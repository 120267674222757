import React, { useEffect, useReducer } from 'react'; 
import CountryContext from './CountryContext';
import reducer from './countryReducer';

import {
  CHANGE_COUNTRY, SET_COUNTRIES_LIST, CLEAR_COUNTRY_DATA, CHANGE_COUNTRY_DATA, CHANGE_CURRENCY, SET_REGIONS_LIST, SET_PARENT_REGIONS
} from './countryActions';
import { useRequests } from '../../hooks';


const CountryProvider = ({ children, initialLocation, isAuthenticated }) => {
  const { get } = useRequests();
  const initialState = {
    currentCountry: initialLocation?.country || 'US',
    charge: initialLocation?.charge || { usd: 0, eur: 0 },
    countryCode: initialLocation?.countryCode || '',
    shippingCountriesList: initialLocation?.shippingCountriesList || [],
    currency: initialLocation?.currency || 'usd',
    countriesTranslations: initialLocation.countriesTranslations || {},
    regions: {},
    parentRegions: []
  };

  const [countryState, dispatch] = useReducer(reducer, initialState);

  const fetchCountriesList = async () => {
    const [countriesResponse, regionsResponse] = await Promise.all([
      get({ url: '/api/shipping/countries-list' }),
      get({ url: '/api/content/regions-list' })
    ]);

    if (countriesResponse?.data?.success) {
      const { countries:translations } = countriesResponse.data;

      setShippingCountries(translations)
    }

    if (regionsResponse?.data?.success) {
      const { regions } = regionsResponse.data;
      const parentRegions = regions
        ?.filter(({ hasParent }) => !hasParent)
        ?.map(({name}) => name)
      const regionsList = regions?.reduce(
        (acc, { names, name, hasParent, id, country }) => {
          acc[name] = { ...names, hasParent, id, country };

          return acc;
        }, {}
      );

      setParentRegions(parentRegions)
      changeRegions(regionsList)
    }
  }
  

  useEffect(
    () => {
      if (isAuthenticated) {
        changeCountryData(initialLocation?.country || '', initialLocation?.charge || 0, initialLocation?.currency || 'usd');
        setShippingCountries(initialLocation?.shippingCountriesList || []);
      } else {

        changeCountry('', 0, '');
        fetchCountriesList()
      }
      // eslint-disable-next-line
    }, []
  );

  const changeCountryData = (country, charge, currency) => {
    dispatch({
      type: CHANGE_COUNTRY_DATA,
      payload: { country, charge, currency }
    });
  }

  const changeCountry = (country) => dispatch({
    type: CHANGE_COUNTRY,
    payload: country
  });

  const setShippingCountries = countriesObkects => {
    const countries = Object.keys(countriesObkects);

    dispatch({
      type: SET_COUNTRIES_LIST,
      payload: { countries, countriesObkects }
    })
  };

  const clearData = () => {
    dispatch({ type: CLEAR_COUNTRY_DATA })
  }

  const setParentRegions = regions => dispatch({
    type: SET_PARENT_REGIONS,
    payload: regions
  })

  const changeCurrency = currency => dispatch({
    type: CHANGE_CURRENCY,
    payload: currency
  });

  const changeRegions = regions => dispatch({
    type: SET_REGIONS_LIST, payload: regions
  })


  return (
    <CountryContext.Provider
      value={{
        ...countryState,
        changeCountry, setShippingCountries, clearData, changeCountryData, changeCurrency, changeRegions, setParentRegions
      }}
    >{children}</CountryContext.Provider>
  )
}


export default CountryProvider;
