import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHelpers, useRequests } from '../../hooks';
import { InputRecordsList, Select, OneInputForm } from '..';
import { useModal, Modal } from 'react-simple-hook-modal';


const WarehouseDataForm = ({ name, id, users, zipcodes, messages, email, usersList, currency, shippingDescription, delivery_fee, lowWineStock, lowCaseStock }) => {
  const feeRef = useRef();
  const { post, requestPending } = useRequests();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { convertWarehouseStringToOptions, convertDataToSelectOptions } = useHelpers();
  const [formData, changeFormData] = useState({
    name: name,
    currency: ['eur', 'usd'].includes(currency) ? currency : 'usd',
    users: convertWarehouseStringToOptions(users),
    zipcodes: convertWarehouseStringToOptions(zipcodes),
    // email: email,
    email: convertWarehouseStringToOptions(email),
    shippingDescription: shippingDescription,
    checkoutMessage: messages?.checkout,
    successMessage: messages?.success,
    errorMessage: messages?.error,
    deliveryFee: delivery_fee,
    lowWineStock, lowCaseStock
  });

  const currencyOptions = [
    { label: 'usd', value: 'usd' },
    { label: 'eur', value: 'eur' }
  ]


  // eslint-disable-next-line
  const usersOptions = useMemo(() => convertDataToSelectOptions(usersList, 'email'), [usersList]);
  const usersValue = useMemo(() => usersOptions?.filter(({ value }) => formData.users.includes('' + value)),
    // eslint-disable-next-line
    [formData.users, users]);

  const handleChangeFormField = e => {
    e.preventDefault();

    const { name, value } = e.currentTarget;

    changeFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleChangeDeliveryFee = async newFee => {
    if (!newFee || isNaN(+newFee)) return false;

    const response = await post({
      url: `/api/warehouses/${id}/delivery-fee`,
      body: { fee: +newFee }
    })

    if (response?.data?.success) {
      changeFormData(prev => ({ ...prev, deliveryFee: newFee }))
    }

    closeModal();
  }

  // const handleChangeZipcodes = records => changeFormData(prev => ({ ...prev, zipcodes: records }));
  const handleChangeMultiInputRecords = propName => records => changeFormData(prev => ({ ...prev, [propName]: records }));

  const handleChangeSelectFieldValue = selectedUsers => {
    changeFormData(prev => ({ ...prev, users: selectedUsers?.map(({ value }) => '' + value) || [] }))
  };

  const handleChangeWarehouseCurrency = data => {
    changeFormData(prev => ({ ...prev, currency: data.value }))
  }

  const handleOpenEditDialog = e => {
    e.preventDefault();

    openModal();

    feeRef.current.value = formData.deliveryFee
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const body = {
      ...formData,
      zipcodes: formData.zipcodes.join(','),
      email: formData.email.join(','),
      users: formData.users.join(','),
    }

    const response = await post({
      url: `/api/content/warehouses/${id}`, body
    });

    if (response?.data?.success) {
      toast.success(`Warehouse data was successfully updated`)
    }
  }


  return (
    <>
      <form autoComplete="off">
        <div className="form-control" style={{ maxWidth: 'none', flexGrow: 1 }}>
          <label htmlFor="name">Warehouse Name</label>

          <input
            name="name"
            autoComplete="warehouse-name"
            value={formData.name}
            onChange={handleChangeFormField}
          />
        </div>

        <div className="form-section gap" style={{ marginBottom: '16px' }}>
          {/* <div className="form-control">
            <label htmlFor="email">Warehouse Email</label>

            <input
              name="email"
              value={formData.email}
              onChange={handleChangeFormField}
            />
          </div> */}
          <div className="form-control w-15 flex-grow no-margin">
            <label htmlFor="lowWineStock">Low Wine Stock</label>

            <input
              name="lowWineStock"
              onChange={handleChangeFormField}
              value={formData.lowWineStock}
            />
          </div>

          <div className="form-control w-15 flex-grow no-margin">
            <label htmlFor="lowCaseStock">Low Case Stock</label>

            <input
              name="lowCaseStock"
              onChange={handleChangeFormField}
              value={formData.lowCaseStock}
            />
          </div>

          <div className="form-control w-15 flex-grow no-margin">
            <label htmlFor="currency">Warehouse Currency</label>

            <Select
              options={currencyOptions}
              value={{ label: formData.currency, value: formData.currency }}
              onChange={handleChangeWarehouseCurrency}
            />
          </div>

          <div className="form-control flex-grow no-margin">
            <label htmlFor="currency">Warehouse Delivery Fee</label>

            <div className="input-group">
              <input disabled value={formData.deliveryFee} />

              <button className="button-link"
                onClick={handleOpenEditDialog}
              >change</button>
            </div>
          </div>
        </div>

        <div className="form-control">
          <label htmlFor="users">Warehouse Users</label>

          <Select
            options={usersOptions}
            isMulti={true}
            isSearchable={true}
            value={usersValue}
            onChange={handleChangeSelectFieldValue}
          />
        </div>

        <InputRecordsList
          label="Warehouse Zipcodes"
          buttonLabel="add zipcode"
          recordsType="zip"
          autocompleteValue="warehouse-zipcodes"
          initRecords={formData.zipcodes}
          // onChange={handleChangeZipcodes}
          onChange={handleChangeMultiInputRecords('zipcodes')}
        />

        <InputRecordsList
          label="Warehouse Emails"
          buttonLabel="add email"
          initRecords={formData.email}
          autocompleteValue="warehouse-emails"
          // onChange={handleChangeZipcodes}
          onChange={handleChangeMultiInputRecords('email')}
        />

        <div className="form-section" style={{ gap: '64px' }}>
          <div className="form-control" style={{ maxWidth: 'none' }}>
            <label htmlFor="successMessage">Success Message</label>

            <textarea
              name="successMessage"
              rows={4}
              value={formData.successMessage}
              onChange={handleChangeFormField}
            />
          </div>

          <div className="form-control" style={{ maxWidth: 'none' }}>
            <label htmlFor="errorMessage">Error Message</label>

            <textarea
              name="errorMessage"
              rows={4}
              value={formData.errorMessage}
              onChange={handleChangeFormField}
            />
          </div>
        </div>

        <div className="form-section" style={{ gap: '64px' }}>
          <div className="form-control" style={{ maxWidth: 'none' }}>
            <label htmlFor="shippingDescription">Shipping Description</label>

            <textarea
              name="shippingDescription"
              rows={4}
              value={formData.shippingDescription}
              onChange={handleChangeFormField}
            />
          </div>

          <div className="form-control" style={{ maxWidth: 'none' }}>
            <label htmlFor="checkoutMessage">Checkout Message</label>

            <textarea
              name="checkoutMessage"
              rows={4}
              value={formData.checkoutMessage}
              onChange={handleChangeFormField}
            />
          </div>
        </div>

        <div className="form-control flex-center" style={{ marginTop: '32px' }}>
          <button
            className="button"
            disabled={requestPending}
            onClick={handleSubmit}
          >Submit Changes</button>
        </div>
      </form>

      <Modal modalClassName="modal-dialog" isOpen={isModalOpen}>
        <OneInputForm
          initialValue={formData.deliveryFee}
          onCancel={closeModal}
          onSubmit={handleChangeDeliveryFee}
          title="Change Warehouse Delivery Fee"
          name="fee"
        />
      </Modal>
    </>
  );
};


WarehouseDataForm.propTypes = {
  name: PropTypes.string,
  shippingDescription: PropTypes.string,
  users: PropTypes.string,
  zipcodes: PropTypes.string,
  email: PropTypes.string,
  messages: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
    checkout: PropTypes.string
  })
};

WarehouseDataForm.defaultProps = {
  name: '',
  shippingDescription: '',
  users: '',
  zipcodes: '',
  email: '',
  messages: PropTypes.shape({
    success: '',
    error: '',
    checkout: ''
  })
}

export default WarehouseDataForm;
