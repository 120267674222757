import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Icon from '../common/Icon';

import './style.scss'


const TwitterShareButton = ({ text, url, imageUrl, title }) => {
  const encodedText = encodeURIComponent(text);
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedImageUrl = encodeURIComponent(imageUrl);


  useEffect(() => {
    const scriptNotExists = !document.querySelector('script[src="https://platform.twitter.com/widgets.js"]');

    if (scriptNotExists) {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      document.head.appendChild(script);
    }
  })


  return (<ul className="share-buttons__list">
    <li><a href={`https://twitter.com/intent/tweet?url=${url}`}
      className="button twitter share-button"
      target="_blank"
      rel="noopener noreferrer"
    ><Icon icon="twitter" color="white" size={14} /> Tweet</a></li>

    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
      className="button share-button facebook"
      target="_blank"
      rel="noopener noreferrer"
    ><Icon icon="facebook" color="white" size={14} /> Share</a></li>
  </ul>);
}




TwitterShareButton.propTypes = {
  imageUrl: PropTypes.string
  , text: PropTypes.string
  , url: PropTypes.string
}

export default TwitterShareButton
