import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHelpers, useRequests } from '../../hooks'; 
// eslint-disable-next-line
import { Spinner, Select, LoadingScreen, GenerateAiTextForm, ButtonWithPopoverConfirmation } from '..';
import classNames from 'classnames';


const SendRecommendationsForm = ({ customer, lastOrder, selectedCase, onCloseForm, onSendRecommendation, orders, onOpenPreview }) => {
  const { get, post, requestPending } = useRequests();
  const { capitalize } = useHelpers()
  const [popoverState, changePopoverState] = useState(false)
  const [state, changeState] = useState({
    isFetchTemplate: false, message: '', text: '', language: 'en', aiText: '', prompt: '', wines: [],
    subject: firstName || lastName ? `${firstName} ${lastName}, Your Next Vineify Case is Ready` : `Your Next Vineify Case is Ready`, errors: []
  });

  const stock = selectedCase.stock;

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'it', label: 'Italian' }
  ];

  const fetchCaseWines = async () => {
    const response = await get({ url: `/api/wines/cases/${selectedCase.id}/wines` })

    return response?.data?.wines || []
  }

  const fetchRecommendationEmailContent = async () => {
    const [response, wines] = await Promise.all([
      get({
        url: `/api/content/recommendation-template?lng=${state.language}&cat=${selectedCase.category}&cid=${customer.id}_${selectedCase.id}`
      }),
      fetchCaseWines()
    ]);

    if (response?.data?.success) {
      const { template } = response.data;

      changeState(
        prev => ({
          ...prev,
          isFetchTemplate: true,
          wines,
          text: template
            .replace('{{userName}}', customer?.firstName || 'Dear Customer')
            .replace('{{caseText}}', selectedCase.text || '')
        })
      );
    }
  }

  const handleCancel = e => {
    e.preventDefault();

    onCloseForm();
  }

  const handleFormFieldChange = e => {
    e.preventDefault();

    changeState(
      prev => ({
        ...prev,
        [e.target.name]: e.target.value,
        errors: prev.errors.filter(error => error !== e.target.name)
      })
    )
  }

  // eslint-disable-next-line
  useEffect(() => { fetchRecommendationEmailContent() }, [state.language]);
  // useEffect(() => { if (errors?.errorCode === 'NO_ORDERS') {
  //   toast.error('User has no last case')
  // } }, [errors]);

  const handleLanguageChange = ({ value: language }) => changeState(prev => ({ ...prev, language }))

  const createEmailBody = () => {
    const { text, subject } = state;
    const { id: caseId, name: nextCaseName } = selectedCase;
    const { caseId: lastCaseId = 0, caseName: lastCaseName = '' } = lastOrder || {};

    const link = `/cases/${caseId}`;
    const nextCaseLink = `/cases/${caseId}`;

    // const linkTemplate = /(<a href=\"[.a-zA-z0-9/:-]+\/recommendations\/view\/\d+\_\d+)/g;
    // const emailBodyText = text.replace(linkTemplate, (match, cnt) => { return cnt + '_' + newRecommendationId });

    return {
      name: customer?.firstName,
      subject: subject || 'Recommendation',
      myWinesLink: '/my-wines',
      link: link,
      CustomCaseEmail: { text, link: nextCaseLink },
      StandardCaseEmail: { text, link: nextCaseLink },
      // CustomCaseEmail: { text: emailBodyText, link: nextCaseLink },
      // StandardCaseEmail: { text: emailBodyText, link: nextCaseLink },
      recommendText: text,
      nextCase: {
        name: nextCaseName, link: nextCaseLink, text
        // , image: caseData?.image
        , image: ''
      },
      lastCase: {
        name: lastCaseName,
        link: lastCaseId > 0 ? `/cases/${lastCaseId}` : undefined
      }
    }
  }

  const handleTogglePopoverConfirmation = state => e => {
    e && e.preventDefault();

    const isOpened = state === 'open'
    changePopoverState(isOpened)
  }

  const createRecommendationEmailBody = () => {
    const { message, text, subject } = state;
    const { firstName, email, id: userId } = customer;
    const { caseId: lastCaseId = 0, caseName: lastCaseName = '' } = lastOrder || {};
    const { id: caseId, name: nextCaseName, category } = selectedCase;

    return {
      caseId, nextCaseName, userName: firstName, email, userId, lastCaseId, lastCaseName, message, category, text, subject
    }
  }

  const checkErrors = () => {
    const errors = [];
    
    if (state.text.length === 0) errors.push('text')
    if (state.subject.length === 0) errors.push('subject')

    if (errors.length > 0) {
      changeState(prev => ({ ...prev, errors })) 
    }

    return errors.length > 0
  }

  const handleOpenPreview = async e => {
    e.preventDefault()

    if (checkErrors()) return;

    const body = createRecommendationEmailBody()

    const response = await post({
      url: `/api/wines/cases/get-recommendation-template`, body
    })

    if (response?.data?.success) {
      // const data = createEmailBody()

      onOpenPreview(response.data.templateData)
    }
  }

  const handleSendRecommendation = async e => {
    e.preventDefault();

    if (checkErrors()) return;

    const body = createRecommendationEmailBody()

    const response = await post({ url: `/api/wines/cases/send-recommendation?lng=${state.language}`, body });

    if (response?.data?.success) {
      toast.success('Recommendation was successfuly sent');

      onSendRecommendation();
    }

    onCloseForm();
  }

  if (!state.isFetchTemplate) return <LoadingScreen />;

  const sendRecommendationButton = selectedCase.stock > 0
    ? (
      <button
        className="button button_sm"
        disabled={requestPending}
        onClick={handleSendRecommendation}
      >Send {requestPending && <Spinner />}</button>
    ) : (
      <ButtonWithPopoverConfirmation
        onConfirm={handleSendRecommendation}
        isOpen={popoverState}
        onClick={handleTogglePopoverConfirmation('open')}
        onCancel={handleTogglePopoverConfirmation()}
        confirmButtonLabel="yes"
        cancelButtonLabel="no"
        btnText="Send"
        mainButtonClasses="button button_sm"
        confirmText="his case is out of stock. Are you sure you want to recommend it?"
      />
    )

  const winesList = state.wines.map(({ fullName }) => fullName)
  const propmt = `Write an article about a product which is made up of the wines ${winesList.join(', ')}.  Include tasting notes and food pairings.`

  const { firstName, lastName, email } = customer;
  const customerInfo = firstName || lastName
    ? `${firstName} ${lastName}, ${email} (${orders} orders)`
    : ` ${email} (${orders})`

  return (
    <form className="recommendation-form">
      <div className="flex-space-between">
        <div className="form-section-title no-margin">Recommendation</div>

        {/* <div className="form-control horizontal flex-grow">
          <label htmlFor="language">Language:</label>

          <Select
            style={{ marginLeft: '16px', width: '165px' }}
            options={ languageOptions }
            value={ languageOptions.find(({ value: lng }) => lng === state.language) }
            onChange={ handleLanguageChange }
          />
        </div> */}
      </div>

      <div className="form-section">
        <ul className="recommendation-info">
          <li className="info-item"><b>Customer:</b> {customerInfo}</li>

          {/* <li className="info-item"><b>Email:</b> { email }</li> */}

          <li className="space" />

          <li className="info-item">
            <b>Selected Case:</b> {selectedCase.name.replace(/\s\(\d+\)$/, '')}{', '}
            <b>Last ordered Case:</b> {lastOrder?.caseName || '-'}
          </li>

          {/* <li className="info-item"><b>Last ordered Case:</b> { lastOrder?.caseName || '-' }</li> */}
        </ul>
      </div>

      <GenerateAiTextForm initialPromt={propmt} />

      <br /><br />

      <div className={classNames('form-control', { 'invalid': state.subject.length === 0 })}>
        <label htmlFor="subject">Subject</label>

        <input
          name="subject"
          placeholder="enter recommendation subject"
          value={state.subject}
          onChange={handleFormFieldChange}
        />
      </div>

      <div className={classNames('form-control', { 'invalid': state.text.length === 0 })}>
        <label htmlFor="text">Recommend Text</label>

        <textarea
          name="text" rows={15}
          placeholder="enter recommendation text"
          value={state.text}
          onChange={handleFormFieldChange}
        />
      </div>

      {
        state.errors.length > 0 &&
        <p className="text-error text-lg text-center"><b>{ capitalize(state.errors.join(' and ')) } should not be blanked</b></p>
      }

      <div className="form-control horizontal flex-space-around" style={{ marginTop: '32px' }}>
        <button
          className="button button_sm button_outline"
          onClick={handleCancel}
        >Cancel</button>

        <button
          className="button button_sm button_outline"
          onClick={handleOpenPreview}
        >Preview</button>

        <button
          className="button button_sm"
          disabled={requestPending}
          onClick={handleSendRecommendation}
        >Send {requestPending && <Spinner />}</button>
      </div>
    </form>
  )
}

SendRecommendationsForm.propTypes = {
  selectedCase: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    text: PropTypes.string
  }),
  customer: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  }),
  lastOrder: PropTypes.shape({
    caseName: PropTypes.string,
    caseId: PropTypes.number
  }),
  onCloseForm: PropTypes.func
}

SendRecommendationsForm.defaultProps = {
  selectedCase: {
    text: ''
  }
}

export default SendRecommendationsForm;
