import { createSlice } from '@reduxjs/toolkit'
import { requestProducerData, requestProducerCasesPreviewslist, requestProducersPreviewList} from './producer.actions'


const initialState = {
  error: undefined,
  processingRequest: false,
  processingRequests: 0,
  list: [],
  currentProducerData: {},
  dataIsFetched: false
}

const producerSlice = createSlice({
  name: '@@producers',
  initialState,
  reducers: {
    clearProducerData: () => {
      return { ...initialState }
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestProducerData.fulfilled, (state, { payload }) => {
        state.currentProducerData = payload
        state.dataIsFetched = true
      })
      .addCase(requestProducerCasesPreviewslist.fulfilled, (state, { payload }) => {
        state.currentProducerData.cases = payload.cases
        state.currentProducerData.regionCases = payload.regionCases
      })
      .addCase(requestProducersPreviewList.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true

      })
      .addMatcher(
        (action) => action.type.startsWith('@@producers') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@producers') && action.type.endsWith('/pending'),
          (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@producers') && action.type.endsWith('/fulfilled'),
          (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})




export const producersActions = {...producerSlice.actions, requestProducerData, requestProducersPreviewList};

export default producerSlice.reducer;
