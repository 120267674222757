
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import { v4 as keyGen } from 'uuid'

const LOCAL_KEY = 'a7368c854bdef660dc718d09a39c8f67'
const MX_KEY = '4edba69ea847f7dbfc5670e40e92d660'

const { host } = document.location;
const key = host.includes('localhost') ? LOCAL_KEY : MX_KEY
mixpanel.init(key);


const _events = {
  VIEW_HOME_PAGE: 'View Home',
  VIEW_MY_WINES: 'View My Wines',
  VIEW_TASTINGS: 'View Tastings',
  VIEW_CHECKOUT_PAGE: 'View Checkout Page',
}

const _isAllowedOnProd = host.includes('vineify.com')
const _isAllowedOnLocal = host.includes('localhost')
const _isAllowed = _isAllowedOnProd || _isAllowedOnLocal


export const sendMxPageView = (eventName, userId, role, userName, ip) => {
  _isAllowed &&
    mixpanel.track(eventName, { 'Host': host, 'User ID': userId, 'User Role': role, 'User Name': userName, 'IP': ip });
}

export const sendMxCustomEvent = (eventName, { userId, caseName, caseId, casePrice, userName, role, ...otherProps }) => {
  const { _suav, ...eventProps } = otherProps;

  _isAllowed &&
    mixpanel.track(eventName, {
      'Host': host, 'User ID': userId, 'User Role': role, 'User Name': userName,
      'Case Name': caseName, 'Case ID': caseId, 'Case Price': casePrice, 'IP': otherProps?.ip,
      ...eventProps
    });
}

export const createMxUser = userData => {
  if (!_isAllowed) return;

  const { firstName, lastName, email, id } = userData;
  const uniqueId = id || keyGen();
  const userName = firstName || lastName ? `${firstName || '-'} ${lastName || '-'}` : `Anonymous`;

  mixpanel.people.set({ "$name": `${userName}`, "$email": email, "$unique_id": uniqueId, "User ID": id });
}

export const identifyMxUser = id => {
  if (!_isAllowed) return;
  
  mixpanel.identify(id);
}