import React, { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as keyGen } from 'uuid'

import './style.scss'


const WineBottlesImages = ({ id, images, containerClass, useSmallImage }) => {
  const [state, changeState] = useState({
    opacity: 0
  })
  const bottleImagesContainer = useRef()
  const mainClassNames = ['case-bottles']
  // if (containerClass) mainClassNames.push(containerClass)
  
  const handleImageError = e => {
    const node = e.target;

    if (node) {
      node.style.display = 'none'
      // node && node.remove()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      // bottleImagesContainer.current.style.opacity = 1
      changeState(prev => ({ ...prev, opacity: 1 }))
    }, 500)
  }, [images])
  
  const content = useMemo(() => images
  ?.filter(imageData => {
    const image = imageData?.src || imageData

    return image?.includes('.png') && !image.includes(`11gnk9e9dn_bottle.png`)
  })
  ?.map((imageData, ind) => {
    const image = imageData?.src || imageData
    const bottleImage = useSmallImage ? image?.replace('.png', '-xs.png') : image;

    return <img key={`${imageData?.key || keyGen()}-${image}`} onError={ handleImageError } src={bottleImage} alt="" />
  }), [images])

  // const numOfImges = document.querySelector('.case-bottles')?.querySelectorAll('img')?.length || 0
  const numOfImges = document.querySelector(`[data-container-id="${id}"]`)?.querySelectorAll('img')?.length || 0
  if (numOfImges > 6) {
    mainClassNames.push('chess-pattern')
  }
  

  return (
    <div data-container-id={id} style={{ opacity: state.opacity }} className={mainClassNames.join(' ')} ref={bottleImagesContainer}>{ content }</div>
  )
}


WineBottlesImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      src: PropTypes.string
    })
  ])),
  useSmallImage: PropTypes.bool
}

WineBottlesImages.defaultProps = {
  useSmallImage: true
}

export default memo(WineBottlesImages)
