import { createAsyncThunk } from '@reduxjs/toolkit' 
import * as RegionService from '../../services/region.service'


export const requestShippingCharges = createAsyncThunk(
  '@@regions/request-shipping-charges',
  async (country = 'US', thunkAPI) => {
    try {
      const data = await RegionService.fetchShippingCharges(country);

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCountriesDataList = createAsyncThunk(
  '@@regions/request-countries-data', 
  async (_, thunkAPI) => {
    try {
      const regions = await RegionService.fetchCountriesDataList();

      return regions;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestRegionsList = createAsyncThunk(
  '@@regions/request-regions-list',
  async (_, thunkAPI) => {
    try {
      const data = await RegionService.fetchRegionsList();

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)


export const requestCurrentRegionCases = createAsyncThunk(
  '@@regions/request-region-cases',
  async ({ regionName, language = 'en', activeMixedCase, activeCase }, thunkAPI) => {
    try {
      const cases = await RegionService.fetchRegionCases({ language, regionName });

      const { singleRegionCases, mixedRegionCases } = cases.reduce(
        (acc, caseData) => {
          if (caseData.regions?.length > 1) {
            acc.mixedRegionCases.push(caseData)
          } else {
            acc.singleRegionCases.push(caseData)
          }

          return acc;
        }, { singleRegionCases: [], mixedRegionCases: [], activeMixedCase, activeCase }
      )

      return { singleRegionCases, mixedRegionCases, activeMixedCase, activeCase }
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestRegionGrapes = createAsyncThunk(
  '@@regions/request-region-grapes',
  async ({ regionName, language = 'en', grapesCategories }, thunkAPI) => {
    try {
      const grapes = await RegionService.fetchRegionGrapes({ regionName, language, grapesCategories });

      return grapes;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestSliderRegionsData = createAsyncThunk(
  '@@regions/request-slider-regions',
  async (_, thunkAPI) => {
    try {
      const regions = await RegionService.fetchSliderRegions();

      return regions;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestRegionData = createAsyncThunk(
  '@@regions/request-region-data',
  async ({ regionName, language = 'en' }, thunkAPI) => {
    try {
      const regionData = await RegionService.fetchRegionDataByName({ regionName, language })

      return regionData;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
