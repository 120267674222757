import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-simple-hook-modal';
import Cropper from 'react-cropper';

import rotateLeftIcon from '../../assets/icons/rotate-left.svg'
import rotateRightIcon from '../../assets/icons/rotate-right.svg'

import "cropperjs/dist/cropper.css";
import './style.scss';


const NewImageWithCropTools = ({ image, onSaveImage, isCropImageDialogOpen, onCloseCropImageDialog }) => {
  const cropperRef = useRef();
  const browseImgRef = useRef();
  const [imageSrc, changeImageSrc] = useState(image);
  const [cropper, setCropper] = useState();
  const [imageUrl, changeImageUrl] = useState();
  const [croppedImage, setCroppedImage] = useState() // HTMLCanvasElement
  const [crop, changeCrop] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 70,
    height: 113
  });

  useEffect(() => {
    !isCropImageDialogOpen && changeImageUrl()
  }, [isCropImageDialogOpen]);

  useEffect(() => { changeImageSrc(image) }, [image])

  const handleBrowseFileImage = e => {
    e.preventDefault();

    browseImgRef?.current?.click();
  }

  const handleChangeImageUrl = e => {
    e.preventDefault();

    changeImageUrl(e.currentTarget.value)
  }

  const handleLoadImageFromUrl = e => {
    e.preventDefault();

    changeImageSrc(imageUrl);
  }

  const handleUploadImage = e => {
    e.preventDefault();
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      changeImageSrc(reader.result)
    };

    reader.readAsDataURL(files[0]);
  }

  const handleSaveCroppedImage = e => {
    e.preventDefault();

    if (croppedImage) {
      croppedImage?.toBlob((blob) => {
        const imageData = {
          file: blob,
          data_url: URL.createObjectURL(blob)
        }

        onSaveImage(imageData.file, imageData.data_url);
        onCloseCropImageDialog();
      })
    } else {
      onCloseCropImageDialog();
    }
  }

  const handleRotate = deg => e => {
    e.preventDefault();

    cropper?.rotate(deg);
  }

  const handleCropInit = instance => {
    setTimeout(() => { changeImageSrc(imageSrc); }, 300);
    setCropper(instance)
  }

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();

    setCroppedImage(canvas)
  };


  return (
    <Modal isOpen={!!isCropImageDialogOpen} >
      <div className="crop-image__layout">
        <div className="crop-image__input">
          <div className="form-control">
            <label htmlFor="">Image URL</label>

            <div className="flex-space-between" style={{ gap: '16px' }}>
              <input
                style={{ flexGrow: 1 }}
                type="text"
                onChange={handleChangeImageUrl}
                value={imageUrl}
              />

              <button
                style={{ flexShrink: 0 }}
                className="button button_xs"
                disabled={!imageUrl || imageUrl.length === 0}
                onClick={handleLoadImageFromUrl}
              >Load from URL</button>
            </div>
          </div>

          <div className="form-control horizontal">
            <label htmlFor="">Upload new image</label>
            <button
              className="button button_xs"
              onClick={handleBrowseFileImage}
            >Upload Image</button>

            <input ref={browseImgRef} onChange={handleUploadImage} type="file" hidden />
          </div>
        </div>

        {
          imageSrc?.length > 0
            ? (
              <Cropper
                src={imageSrc}
                style={{ height: 400, width: 600 }}
                initialAspectRatio={16 / 9}
                viewMode={2}
                crossOrigin="anonymous"
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                rotatable={true}
                background={false}
                responsive={true}
                guides={false}
                onInitialized={handleCropInit}
                crop={onCrop}
                autoCrop={true}
                dragMode="move"
                ref={cropperRef}
              />
            ) : (
              <div style={{ height: 400, width: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p className="no-margin">Enter image URL or Upload file</p>
              </div>
            )
        }

        <div className="crop-image__controls">
          <button
            className="button button_xs button_outline"
            onClick={handleRotate(-90)}
          >Rotate 90 <img src={ rotateLeftIcon } alt="rotate counterclockwise" /></button>

          <button
            className="button button_xs button_outline"
            onClick={handleRotate(90)}
          >Rotate 90 <img src={ rotateRightIcon } alt="rotate clockwise" /></button>
        </div>

        <div className="crop-image__controls">
          <button className="button button_outline" onClick={onCloseCropImageDialog}
          >Close Dialog</button>

          <button
            className="button"
            onClick={handleSaveCroppedImage}
          >Save Cropped Image</button>
        </div>
      </div>
    </Modal>
  );
}

export default NewImageWithCropTools;