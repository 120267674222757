import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '..'

import './style.scss';


const Alert = ({ title, text, icon, className, children, isSvg, color, size }) => {
    const classes = 'alert ' + className;

    return (
        <div className={classes}>
            { icon && !isSvg && <img className="alert__icon" src={ icon } alt="icon" />}
            { icon && isSvg && <Icon className="alert__icon" size={size} icon={ icon } color={ color } />}
            { title && <h4 className="alert__title">{ title }</h4>}
            { text && <p className="alert__text">{ text }</p>}
            { children }
        </div>
    )
}

Alert.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    isSvg: PropTypes.bool
}

Alert.defaultProps = {
    className: '',
    isSvg: false
}

export default Alert;
