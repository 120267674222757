import React, { useMemo } from 'react'
import { v4 as keyGen } from 'uuid';
import PropTypes from 'prop-types'
import { useHelpers } from '../../hooks'

import './style.scss';


const RestockHistory = ({ restockHistory }) => {
  const { createRestockHistoryList, sortListOfObjectByField, formatDateTime } = useHelpers();
  const isMobile = window.matchMedia('(max-width: 520px)').matches

  const sortedRestockHistory = createRestockHistoryList(sortListOfObjectByField(restockHistory, 'date', { fieldType: 'date', order: 'asc' }))?.reverse()

  const restockContent = sortedRestockHistory.length ? sortedRestockHistory.map(
    ({ changeValue, stockValue, userId: id, date, name, category, updatedStock, reason, className }) => (
      <li key={keyGen()} className="restock-history__row">
        <span className="restock-history__item text-center"
        >{isMobile && <b>Date: </b>}{formatDateTime(date, false)}</span>

        <span className="restock-history__item main"
        >{isMobile && <b>User: </b>}{name}</span>

        <span className="restock-history__item"
        >{isMobile && <b>Reason: </b>}{reason}</span>

        <span className="restock-history__item stock-change text-center"
        >{isMobile && <b>Stock: </b>}{stockValue}</span>

        <span className={`restock-history__item stock-change text-center 
        ${className}`}>{isMobile && <b>Change: </b>}{changeValue}</span>

        <span className="restock-history__item text-center"
        >{isMobile && <b>Updated stock: </b>}{updatedStock}</span>
      </li>
    )
  ) : (
    <li className="subtitle">Restock history is empty...</li>
  );


  return (
    <div className="report-layout restock-history">
      <div className="restock-history__title">
        <h2>Restock History</h2>
      </div>

      <div className="report-content">
        <div className="admin-report-header restock-history__header">
          {
            !isMobile && <>
              <span className="restock-history__item text-center">Date</span>

              <span className="restock-history__item main">User</span>

              <span className="restock-history__item">Action</span>

              <span
                className="restock-history__item text-center"
                title="Stock before value changed"
              >Stock</span>

              <span className="restock-history__item text-center">Change</span>

              <span className="restock-history__item text-center">Updated Stock</span>
            </>
          }
          {
            isMobile && <span className="restock-history__item flex-grow">Details</span>
          }
        </div>

        <ul className="restock-history__list">{restockContent}</ul>
      </div>
    </div>
  )
}

RestockHistory.propTypes = {
  restockHistory: PropTypes.shape({
    changeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    category: PropTypes.string,
    stockValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

export default RestockHistory