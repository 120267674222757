import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './style.scss'


const CaseRegionsTags = ({ regions }) => {
  const regionsContent = regions?.map(region => <li className="case-data__region" key={region}>{region}</li>)

  return (
    <ul className="case-data__regions-list">{regionsContent}</ul>
  )
}


CaseRegionsTags.propTypes = {
  regions: PropTypes.array
}

export default memo(CaseRegionsTags);
