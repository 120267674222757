import classNames from 'classnames';
import React, { useState } from 'react' 
import { toast } from 'react-toastify';
import { useRequests, useGeoLocation } from '../../../hooks';


const NewPublicHomeSubscriptionForm = ({ userData }) => {
  const { post, requestPending } = useRequests();
  const { getInitialLocationData } = useGeoLocation();
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [formData, changeFormData] = useState({
    // firstName: userData.firstName || '', 
    // lastName: userData.lastName || '',
    // email: userData.email || ''
    firstName: '', 
    lastName: '', 
    email: ''
  });
  const [errors, changeErrors] = useState();

  const clearErrors = () => changeErrors([])

  const handleSubscribe = async e => {
    e.preventDefault();

    const { location, user_agent } = await getInitialLocationData();
    const body = { ...formData }
    
    body.state = location?.region?.name;
    body.city = location.city;
    body.country = location.country?.name;
    body.device = user_agent?.device

    const response = await post({
      // url: `/api/users/subscription?s=1${userData?.id ? `&u=${userData.id}` : ``}`, 
      url: `/api/users/subscription?s=1`, 
      body 
    });

    if (response?.data?.success) {
      setIsSubscribed(true)
      toast.success(`Successfuly subscribed`)
    } else {
      console.log('Error response data: ', response.data)
      changeErrors(response.data.errors)
    }
  }

  const handleFieldChange = e => {
    e.preventDefault();
    clearErrors();

    const { name, value } = e.currentTarget;

    changeFormData(prev => ({ ...prev, [name]: value }))
  }

  const { email } = formData;


  return (
    <form className="public-home__subscription-form">
      <div className={classNames('form-control email', { 'invalid': errors?.email })}>
        <input
          placeholder="Email Address"
          name="email"
          value={ email }
          onChange={ handleFieldChange }
        />

        {
          errors?.email &&
          <p className="error-msg-block">{errors?.email}</p>
        }
      </div>

      <button 
        className="button primary"
        type="button"
        disabled={ requestPending }
        onClick={ handleSubscribe }
      >Subscribe Now</button>

      { isSubscribed && <p className="subscribed-alert"><b>Thanks! Your email has been added to our mailing list.</b></p> }
    </form>
  )
}


export default NewPublicHomeSubscriptionForm;
