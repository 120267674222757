import React from 'react'
import { Header, Icon } from '../../../components'
import { Modal, useModal } from 'react-simple-hook-modal'
import PublicHomeNewsletterDialog from './PublicHomeNewsletterDialog'
import { useHistory } from 'react-router-dom'


const NewPublicHomeHeader = () => {
  const { isModalOpen, closeModal, openModal } = useModal()
  const history = useHistory()

  const handleShowCollections = e => {
    e.preventDefault()

    history.push('/wine-collections')
  }


  return (
    <div className="public-home__section public-home__header lite new">
      <div className="dark-bg-layout" />
      <Header transparent={true} />

      <div className="container sm-p-32 zi-5">
        <h1 className="public-home__title main text-left">Wine Buying Made Easy!</h1>

        <p className="public-home__subtitle text-left">Skip the hassle and let Vineify handle everything. Spend your time enjoying wine, not shopping for it.</p>

        {/* <Icon className="public-home__logo" icon="logo-with-text-vertical" color="white" size={200} /> */}
        <div className="public-home__header-buttons">
          <button className="button accent" onClick={handleShowCollections}>Explore Collections</button>

          <button type="button" className="button reversed text-accent" onClick={openModal}>Join Newsletter</button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} modalClassName="newsletter-dialog">
        <PublicHomeNewsletterDialog
          title="Wine is always best paired with others!"
          subtitle="Join our community newsletter for offers and news straight to your inbox."
          submitMessage={<><b>You’re in!</b> Thanks for signing up.</>}
          newsletterEndpoint="/api/users/subscription?s=1"
          onClose={ closeModal }
        />
      </Modal>
    </div>
  )
}


export default NewPublicHomeHeader
