import { useTranslation } from 'react-i18next';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import Case from '../classes/Case';

const _capitalizeWord = word => {
  if (!word?.length) return '';

  const trimedWord = word.trim();
  const firstChar = trimedWord[0].toUpperCase();

  return firstChar + trimedWord.slice(1);
}

const useHelpers = () => {
  const { i18n } = useTranslation();

  const sortListOfObjects = (array, objectField, order = 'desc') => {
    let field;

    switch (objectField) {
      case 'grape':
        field = 'sortOfGrape';
        break;
      case 'favourite':
        field = 'isFavourite';
        break;
      default:
        field = '' + objectField;
    }

    return [...array].sort((first, second) => {
      const toSort1 = field === 'vintage' || field === 'inventory'
        ? field === 'inventory'
          ? +first[field] - first.amountBottlesDrunk : +first[field]
        : first[field];

      const toSort2 = field === 'vintage' || field === 'inventory'
        ? field === 'inventory'
          ? +second[field] - second.amountBottlesDrunk : +second[field]
        : second[field];


      if (toSort1 > toSort2) {
        return order === 'desc' ? -1 : 1;
      } else if (toSort1 < toSort2) {
        return order === 'desc' ? 1 : -1;
      } else {
        return 0;
      }
    })
  }

  const sortListOfObjectByField = (array, field, { order, fieldType }) => {
    if (!array || array?.length < 2) return array || [];

    const arrayCopy = [...array]

    const _stringSort = (cur, prev) => {
      if (cur[field]?.trim() > prev[field]?.trim()) {
        return order === 'asc' ? 1 : -1
      }

      if (cur[field]?.trim() < prev[field]?.trim()) {
        return order === 'desc' ? 1 : -1
      }

      return 0;
    }

    const _numSort = (cur, prev) => {
      return order === 'asc' ? +cur[field] - +prev[field] : +prev[field] - +cur[field];
    }

    const _dateSort = (curDate, prevDate) => {
      const cur = new Date(curDate[field]).getTime();
      const prev = new Date(prevDate[field]).getTime();

      return order === 'asc' ? cur - prev : prev - cur;
    }

    switch (fieldType) {
      case 'string': return arrayCopy.sort(_stringSort);
      case 'date': return arrayCopy.sort(_dateSort);
      default: return arrayCopy.sort(_numSort);
    }
  }

  const isEmail = email => {

    const match = /^[\w\d\.]+@[a-zA-Z0-9\-]+?\.[a-zA-Z]{2,3}$/;

    return match.test(email);
  }

  const isMobilePhone = phone => {
    //test empty field
    // test < 10 numbers
    // test combination of numbers, letters and special chars
    // test that number is not starting with 0 or 1
  }

  const getCurrencySymbol = currency => {
    switch (currency.toLowerCase()) {
      case 'eur':
        return '€';
      default:
        return '$';
    }
  }

  const formatCurrency = (currency, currencyName = 'USD', withCents = true, withSuffix = true) => {
    const currencySymbol = getCurrencySymbol(currencyName);

    const amount = withCents ? currency : (+currency).toFixed(2);

    return withSuffix ?
      currencySymbol + amount + ' ' + currencyName?.toUpperCase() : currencySymbol + amount
  }

  /**
   * 
   * @param {Date} date 
   * @param {Boolean} withTime - default value true
   * @returns {String}
   */
  const formatDateTime = (date, withTime = true) => {
    if (!date) return '-';

    const time = withTime ? { hour: 'numeric', minute: 'numeric' } : {}

    return new Date(date)
      .toLocaleString('en-US',
        {
          timeZone: 'America/Chicago', hour12: false,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          ...time
        })
  }


  const countBottles = (bottles) => {

    return bottles.reduce((acc, position) => {
      const { quantity } = position;
      acc += quantity;

      return acc;
    }, 0)
  }


  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);

    if (section) section.scrollIntoView();
  }

  const SVS_B = (eAmt, where) => {
    if (where === "center" || where === "")
      window.scrollBy(0, eAmt / 2);
    if (where === "top")
      window.scrollBy(0, eAmt);
  }


  const scrollTo = (element, delay = 0, time = 500, offset = 0, where = 'top') => {

    if (typeof window === "undefined" || !element) {
      return;
    }


    const scrolling = () => {
      const eTop = element.getBoundingClientRect().top - offset;
      const eAmt = eTop / 100;
      let curTime = 0;
      while (curTime <= time) {
        window.setTimeout(SVS_B, curTime, eAmt, where);
        curTime += time / 100;
      }
    }

    setTimeout(() => { scrolling(); }, delay);
  }


  const makeShortDescription = (text = '', limit = 105) => {
    let array = [...text.trim().split(' ')];
    let textShortened = '';

    if (text && text.length) {
      while (textShortened.length < limit) {
        const word = array.shift();
        if (!word) break;

        if (textShortened.length + word.length < limit) {
          textShortened = textShortened + ' ' + word;
        } else {
          textShortened += '...';
          break;
        }
      }
    }

    return textShortened;
  }


  const objectIsEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }


  const defineCaseColorLabel = name => {
    const caseName = name.trim().toLowerCase();

    if (caseName.search(' red') > 0) {
      return 'red';
    } else if (caseName.search(' white') > 0) {
      return 'white'
    } else {
      return 'mix'
    }
  }


  const createWinesTypes = wines => {
    return wines?.reduce(
      (result, wine) => {
        if (wine.category) {
          result[wine.category] += wine?.quantity || 0;

          result.total += wine?.quantity || 0;
        }

        if (wine.cat) {
          result[wine.cat] += wine?.qty || 0;

          result.total += wine?.qty || 0;
        }

        // result.total += wine?.quantity || 0;

        return result;
      },
      { red: 0, white: 0, sparkling: 0, rose: 0, total: 0 }
    ) || { red: 0, white: 0, sparkling: 0, rose: 0, total: 0 };
  }


  const countAndSortWines = wines => {
    return wines.reduce(
      (result, wine) => {

        if (wine.category) {
          result[wine.category].wines += 1;
          result[wine.category].bottles += +wine.stock;
        }

        result.total.wines += 1;
        result.total.bottles += +wine.stock;

        return result;

      }, {
      total: { wines: 0, bottles: 0 },
      red: { wines: 0, bottles: 0 },
      white: { wines: 0, bottles: 0 },
      rose: { wines: 0, bottles: 0 },
      sparkling: { wines: 0, bottles: 0 }
    }
    )
  }


  const convertDataToSelectOptions = (data, additionalFields) => data?.map(
    dataItem => {
      // const optionData = {
      //   label: additionalFields && dataItem[additionalFields]
      //     ? <p className="no-margin"><b>{dataItem.name}</b> {dataItem[additionalFields]}</p>
      //     : dataItem.name, value: dataItem.id
      // }

      const optionData = {
        label: dataItem.name,
        value: dataItem.id
      }

      if (additionalFields) {
        if (typeof additionalFields === 'string') {
          optionData[additionalFields] = dataItem[additionalFields]
        }

        if (Array.isArray(additionalFields) && additionalFields?.length > 0) {
          for (const field of additionalFields) {
            optionData[field] = dataItem?.[field]
          }
        }
      }

      return optionData;
    }
  );

  const formCasePrice = val => {
    if (typeof val === 'string' && ['€', '$'].includes(val[0])) {
      return val[0] + formCasePrice(val.slice(1))
    }

    if (Number.isNaN(+val) || +val <= 0) return 0;

    if (+val <= 10) return +val;

    const rounded = Math.ceil(+val);

    // if (rounded % 5 === 0) return rounded;
    if (rounded % 10 === 0) return rounded;

    // const nearest5 = Math.ceil(+val / 5) * 5;
    const nearest10 = Math.ceil(+val / 10) * 10;

    // return nearest5 < rounded ? nearest5 + 5 : nearest5;
    return nearest10 < rounded ? nearest10 + 5 : nearest10;
  }


  const capitalize = (word, capitalizeAll = false) => {
    if (!capitalizeAll) return _capitalizeWord(word);

    return word.split(' ').map(w => _capitalizeWord(w)).join(' ');
  }


  const convertTabbedContentToLanguagesObject = content => content.reduce(
    (result, { tabName, text }) => {
      result = { ...result, [tabName]: text };

      return result;
    }, {}
  );


  const getOrdersStatistics = (orders = []) => {
    return orders.reduce(
      (acc, order) => {
        const {
          case: { name: caseName, prices }
        } = order;
        const casePrice = prices[order.currency]?.toFixed(2);
        // const casePrice = cost ? parseFloat(cost)?.toFixed(2) : prices[order.currency]?.toFixed(2);

        const currentOrdersCount = acc[caseName] ?
          acc[caseName][order.currency]?.ordersCount || 0 : 0;
        const currentTotalPrice = acc[caseName] ?
          acc[caseName][order.currency]?.totalPrice || 0 : 0;

        acc[caseName] = {
          ...acc[caseName],
          [order.currency]: {
            ordersCount: currentOrdersCount + 1,
            totalPrice: currentTotalPrice + +casePrice
          }
        };

        acc.total[order.currency] += +casePrice;

        return acc;
      }, { total: { eur: 0, usd: 0 } }
    );
  }


  const getOrdersTotalsStatistics = (orders = []) => {
    return orders.reduce(
      (acc, order) => {
        const {
          case: { name: caseName, prices }
        } = order;
        const orderPrice = +order.cost > 0 ? +order.cost : +prices[order.currency];
        // const orderPrice = cost ? parseFloat(cost)?.toFixed(2) : prices[order.currency]?.toFixed(2);

        const currentOrdersCount = acc[caseName] ?
          acc[caseName][order.currency]?.ordersCount || 0.00 : 0.00;
        const currentTotalPrice = acc[caseName] ?
          acc[caseName][order.currency]?.totalPrice || 0.00 : 0.00;

        acc[caseName] = {
          ...acc[caseName],
          [order.currency]: {
            ordersCount: currentOrdersCount + 1,
            totalPrice: currentTotalPrice + +orderPrice
          }
        };

        acc.total[order.currency] += +orderPrice;

        return acc;
      }, { total: { eur: 0.00, usd: 0.00 } }
    );
  }


  const filterObject = (obj, filter) => {
    let result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !filter(obj[key])) {
        result[key] = obj[key];
      }
    }

    return result;
  }

  const getZipCodeFromPaypalDetails = paypalDetails => {
    if (!paypalDetails?.purchase_units) return undefined;

    return paypalDetails.purchase_units[0].shipping.address.postal_code;
  }

  const scrollToPageSection = sectionId => e => {
    e && e.preventDefault();
    const section = document.getElementById(sectionId);

    section && scrollIntoView(section, { behavior: 'smooth', scrollMode: 'if-needed', block: 'start' });
  }

  const convertWarehouseStringToOptions = string => {
    if (!string || string.length === 0) return [];

    return string.split(',').map(chunk => chunk.trim())
  }

  const getNextRegion = (regions, currentRegionName) => {
    if (!Array.isArray(regions)) return {};

    const sortedRegionsList = regions.sort(({ id: currentId }, { id: prevId }) => currentId - prevId)

    const currentRegionIndex = sortedRegionsList?.findIndex(reg => reg.names[i18n.language] === currentRegionName);
    const nextIndex = (currentRegionIndex < regions?.length - 1) ?
      currentRegionIndex + 1 : 0;

    return regions?.find((r, ind) => ind === nextIndex);
  }


  const filterTextRemoveLsep = text => text?.replaceAll(/&#8232;/g, ' ').replaceAll(/\u2028/g, ' ')

  ///////////////////////
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
    })

  const getRadianAngle = async (degreeValue) => {
    return (degreeValue * Math.PI) / 180
  }

  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  const rotateSize = async (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation)

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  }

  const getCroppedImg = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    )

    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    ctx.putImageData(data, 0, 0);
    const src = canvas.toDataURL('image/jpeg', 1)

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve({ data_url: src, file })
      }, 'image/jpeg')
    })
  }


  const getWineName = wine => {
    if (!wine || !wine.name) return ``;
    let winePrefix = ``;

    if (wine?.vintage > 0)
      winePrefix += wine.vintage;

    if (wine?.producer)
      winePrefix += ` ${wine.producer?.trim()}`;

    return `${winePrefix} ${wine.name?.trim()}`
  }

  const roundPrice = (price, withCents = true) => {
    if (isNaN(price)) return undefined;

    const roundedPrice = Math.floor(+price * 100) / 100;

    return withCents ? +(roundedPrice.toFixed(2)) : roundedPrice;
  }

  const getPaypalPriceDetails = ({ casePrice, shipping = 0, tax = 8.25, user }) => {

    const shippingPrice = roundPrice(shipping);
    const price = roundPrice(formCasePrice(casePrice) + shippingPrice);

    const rawTaxCentPrice = +price * tax;
    // bankers' rounding
    const totalTax = rawTaxCentPrice % 2 === 0.5
      ? Math.floor(rawTaxCentPrice) / 100 : Math.round(rawTaxCentPrice) / 100;

    const total = price + shippingPrice + totalTax;

    const result = { subtotal: price, total, totalTax, shipping: shippingPrice }

    return result;
  }


  const getCaseWinesAsListItemsWithTotalCost = (wines, withEur) => {
    if (!wines) return { winesList: [], winesTotal: 0 };

    return wines.reduce(
      (result, { name, cost, costUsd, quantity, producer, vintage }) => {
        const wineTotal = +cost * +quantity;
        const wineTotalUsd = +costUsd * +quantity;
        const wineCost = {
          perBottle: withEur
            ? `(€${(+cost).toFixed(2)} / $${(+costUsd).toFixed(2)} x ${+quantity})`
            : `$${(+costUsd).toFixed(2)} x ${quantity})`,
          total: withEur
            ? `€${+wineTotal.toFixed(2)} / $${(+wineTotalUsd).toFixed(2)}`
            : `$${(+wineTotalUsd).toFixed(2)}`
        }

        const wineRow = (
          <li className="wine-pricing">
            <span>{getWineName({ name, producer, vintage })} {wineCost.perBottle}
            </span>
            <span className="dots" />
            <span>{wineCost.total}</span>
          </li>
        )

        result.winesList.push(wineRow);
        result.winesTotal += wineTotal;

        return result;

      }, { winesList: [], winesTotal: 0 }
    );
  }


  const createDifFieldsObject = (changed, initial, pricingType = 'mannual') => {
    if (!initial || !changed) return []
    const keys = Object.keys(changed).filter(key => key !== 'calculatedPrice');
    const winesOrders = {};

    const omitWineOrder = wineData => {
      const { order, ...otherData } = wineData;

      return otherData;
    }

    const filterWines = ({ quantity }) => +quantity > 0;

    const changedObject = {
      ...changed,
      wines: changed?.wines
        ?.filter(filterWines)
        ?.map(wineData => {
          const initialOrder = initial.wines?.find(initialWineData => initialWineData.id === wineData.id);

          if (initialOrder.order !== wineData.order) {
            winesOrders[wineData.id] = wineData.order;
          }



          return omitWineOrder(wineData)
        }) || []
    }

    const initialObject = {
      ...initial,
      wines: initial?.wines?.filter(filterWines)?.map(omitWineOrder) || []
    }

    const result = keys.reduce(
      (acc, key) => {
        if (key === 'calculatedPrices') {
          const { prices: initialPrices } = initialObject[key];
          const { prices: changedPrices } = changedObject[key];

          if (pricingType === 'calculated' && JSON.stringify(initialPrices) !== JSON.stringify(changedPrices)) {
            acc[key] = changedObject[key]
          }

          return acc;
        } else {
          const isChangedField = JSON.stringify(changedObject[key]) !== JSON.stringify(initialObject[key]);

          if (isChangedField) acc[key] = changedObject[key];

          return acc;
        }
      }, {}
    )

    if (Object.keys(winesOrders).length) {
      result['winesOrders'] = winesOrders
    }

    return result;
  }

  const checkWarehouseManager = user => {
    if (user?.role !== 'warehouse' || user?.role !== 'retailer') return { isManager: false, warehouse: null };

    const check = {};

    if (['serendipity@vineify.com', 'iws@vineify.com'].includes(user?.email)) {
      check.isManager = true;

      check.warehouse = user?.email === 'serendipity@vineify.com'
        ? 2 : 1
    }

    // const warehouse = user?.email?.toLowerCase() === 'serendipity@vineify.com' 
    //   ? '' : '';

    return check;
  }


  const getOptionsData = (options, field) => {
    return options.map(optionData => optionData[field])
  }

  const getOption = (options, { value, label }) => {
    if (!value && !label) return undefined;

    const searchedOption = options?.find(optionData => {
      if (value) return optionData.value === value;

      if (label) return optionData.label === label;
    });

    return searchedOption;
  }

  const createCaseDataRequestObject = (data, pricingToggle) => {
    const {
      image, names, price, eur, usd, calculatedPrice, calculatedPrices, descriptions, longDescriptions, customers, regions,
      curator, wines: allWines, winesOrders, labels, ...caseData
    } = data;
    // const { exchangeRate } = pricingDetails;

    const wines = allWines?.filter(
      ({ quantity }) => quantity > 0
    ) || [];

    const warehouseForCheck = wines?.[0]?.warehouse;

    if (warehouseForCheck && wines?.some(({ warehouse }) => warehouse !== warehouseForCheck)) {
      return 'All wines should be from the same warehouse!';
      // return toast.error('All wines should be from the same warehouse!');
    }

    const descriptionsData = caseData.category === 'custom' && descriptions.every(({ text }) => !text.length)
      ? Case.getDefaultMultiLanguageDescription() : descriptions;

    const longDescriptionsData = caseData.category === 'custom' && longDescriptions.every(({ text }) => !text.length) ? Case.getDefaultMultiLanguageDescription('long') : longDescriptions;

    const caseFormData = {
      ...caseData
    }

    const form = new FormData();

    for (const field in caseData) {
      form.append(field, caseFormData[field]);
    }

    if (pricingToggle === 'calculated') {
      calculatedPrice && form.append('price', +calculatedPrice);
      form.append('calculatePrices', true);
    }

    customers && form.append('customers', JSON.stringify(
      customers?.length > 0 && typeof customers?.[0] === 'object'
        ? customers?.map(({ value }) => value) : customers
    ))
    wines?.length && form.append('wines', JSON.stringify(wines))
    names && form.append('names', JSON.stringify(convertTabbedContentToLanguagesObject(names)))
    if (image?.file || image?.data_url) form.append('files', image?.file || image?.data_url)
    winesOrders && form.append('winesOrders', JSON.stringify(winesOrders))
    pricingToggle !== 'calculated' && price && form.append('price', +price)
    eur && form.append('eur', +eur)
    usd && form.append('usd', usd)
    curator && form.append('curator_id', curator.value)
    regions && form.append('regions', JSON.stringify(getOptionsData(regions, 'value')))
    labels && form.append('labels', JSON.stringify(labels))
    descriptions && form.append('descriptions', JSON.stringify(convertTabbedContentToLanguagesObject(descriptionsData)))
    longDescriptions && form.append('longDescriptions', JSON.stringify(convertTabbedContentToLanguagesObject(longDescriptionsData)))

    return form;
  }

  const formatDatepickerValue = date => {
    if (!date) return '';

    return `${date.month < 10 ? '0' + date.month : date.month}/${date.day < 10 ? '0' + date.day : date.day}/${date.year}`;
  }

  const convertDateToDatepickerValue = date => {
    if (!date) return { day: null, month: null, year: null }
    const defaultDate = new Date(date);

    const day = defaultDate.getDate();
    const month = defaultDate.getMonth() + 1;
    const year = defaultDate.getFullYear();

    return { day, month, year }
  }

  const createWineDataRequestObject = (wineData, initialWineData) => {
    const fields = Object.keys(initialWineData);
    const diff = {};

    for (const field of fields) {
      if (!wineData[field] && wineData[field] !== 0) continue;

      const string = JSON.stringify(wineData[field]);
      const stringOfInitial = JSON.stringify(initialWineData[field]);

      if (string !== stringOfInitial) diff[field] = wineData[field]
    }

    return diff;
  }

  const getVideoPlayerLink = link => {
    if (typeof link !== 'string' || link.length < 1) return undefined;

    const vimeoLinkPattern = /^https:\/\/vimeo\.com\/(\d+)/;

    const matches = link.match(vimeoLinkPattern);

    return { id: matches?.[1], link: matches?.[0] }
  }

  const getWpImageSrc = content => {
    // https://alicekate.com/wp-content/uploads/2022/09/DSC_3847.jpg
    if (typeof content !== 'string' || content.length < 1) return undefined;
    const imageSrcPattern = /https:\/\/alicekate.com\/wp-content\/uploads[\S]+\.(jpg|jpeg|png)/;

    const matches = content.match(imageSrcPattern);

    return matches?.[0]
  }

  const getFullWineName = wine => `${wine.vintage > 0 ? wine.vintage : 'NV'} ${wine.producer} ${wine.name}`;

  const getHighLightedCase = cases => {
    const indexOfHighLightedCase = Math.round(cases.length / 2) - 1;

    return cases.find((caseData, index) => index === indexOfHighLightedCase)
  }

  const splitCases = cases => {
    if (!cases || cases.length === 0) return { cases: [], highlighted: undefined }
    if (cases.length === 1) return { cases: [], highlighted: cases[0] }

    if (cases.length === 2) return { cases, highlighted: undefined }

    const highLightedByDefault = cases.find(({ active }) => active === 2);

    if (highLightedByDefault) {
      return {
        cases: cases.filter(({ id }) => id !== highLightedByDefault.id),
        highlighted: highLightedByDefault
      }
    } else {
      const highlighted = getHighLightedCase(cases);

      return {
        cases: cases.filter(({ id }) => id !== highlighted.id), highlighted
      }
    }
  }

  const convertSerendipitySalesToCsvData = sales => sales?.reduce(
    (acc, { wines, id: vineifyOrder, customer, service_fee: serviceFee, case_markup: caseMarkup, deliveryFee, casePrice, statuses, orderTotal }) => {
      let totals = {
        serendipityTotal: 0,
        davenport: 0,
        vineify: 0,
        deliveryFee: 0,
        subTotal: 0,
        cardAllocation: 0,
        additionalServices: 0,
        totalBeforeTax: 0,
        tax: 0, total: 0
      }

      const winesTotal = wines?.reduce(
        (acc, { cost, quantity }) => {
          const wineTotal = cost * quantity;
          acc += wineTotal;

          return acc
        }, 0
      )

      const caseMarkupValue = winesTotal * +caseMarkup;
      const serviceFeeValue = winesTotal * +serviceFee;
      // const subTotal = +winesTotal + caseMarkupValue + serviceFeeValue + +deliveryFee;
      const winesTotalWithMarginAndFee = Math.round((+winesTotal + caseMarkupValue + serviceFeeValue + +deliveryFee) * 100) / 100;
      const cardAllocationTotal = Math.round(winesTotalWithMarginAndFee * 2.99) / 100 + 0.49;
      const subTotal = cardAllocationTotal + winesTotalWithMarginAndFee;
      const date = statuses?.[0]?.date;

      const roundingValue = +casePrice - subTotal;
      const totalTax = +orderTotal - +casePrice;
      // const roundingValue = +casePrice - subTotal - cardAllocationTotal;

      for (const { id, name: wine, cost, quantity, vintage, producer } of wines) {

        const serendipityTotal = cost * quantity;
        totals.serendipityTotal += serendipityTotal;

        const davenport = serendipityTotal * 0.2;
        totals.davenport += davenport;

        const vineify = serendipityTotal * 0.15;
        totals.vineify += vineify;

        const deliveryFee = 10 / 12 * quantity;
        totals.deliveryFee += deliveryFee;

        const subTotal = serendipityTotal + davenport + vineify + deliveryFee;
        totals.subTotal += subTotal;

        const cardAllocation = Math.round(subTotal * 2.99 + 49 / 12 * quantity) / 100;
        totals.cardAllocation += cardAllocation;

        const additionalServices = roundingValue / 12 * quantity;
        totals.additionalServices += additionalServices;

        const totalBeforeTax = subTotal + cardAllocation + additionalServices;
        totals.totalBeforeTax += totalBeforeTax;

        const tax = Math.round(totalBeforeTax * 8.25) / 100;
        totals.tax += tax;

        const total = totalBeforeTax + tax;
        totals.total += total;

        const dataRow = {
          'ID': id,
          'Vintage': vintage,
          'Producer': producer,
          'Wine': wine,
          'Qty': quantity,
          'Per bottle': cost?.toFixed(2),
          'Serendipity total': serendipityTotal?.toFixed(2),
          'Davenport 20%': davenport?.toFixed(2),
          'Vineify 15%': vineify?.toFixed(2),
          'Delivery Fee': deliveryFee?.toFixed(2),
          'Subtotal': subTotal?.toFixed(2),
          'Square fee 2.99% + $0.49': cardAllocation,
          'Additional Service Fee to Davenport': additionalServices?.toFixed(2),
          'Total before tax': totalBeforeTax?.toFixed(2),
          'Tax 8.25%': tax?.toFixed(2),
          // 'Total': total?.toFixed(2),
          'Total': total?.toFixed(2),
          'Customer': customer,
          'Vineify Order': vineifyOrder,
          'Order Date': formatDateTime(date, false)
        }

        acc.push(dataRow)
      }

      acc.push({
        'Serendipity total': (+winesTotal)?.toFixed(2),
        // 'Davenport 20%': totals.davenport?.toFixed(2),
        'Davenport 20%': caseMarkupValue?.toFixed(2),
        // 'Vineify 15%': totals.vineify?.toFixed(2),
        'Vineify 15%': serviceFeeValue?.toFixed(2),
        'Delivery Fee': deliveryFee.toFixed(2),
        'Subtotal': (+winesTotalWithMarginAndFee)?.toFixed(2),
        'Square fee 2.99% + $0.49': (+cardAllocationTotal)?.toFixed(2),
        // 'Additional Service Fee to Davenport': totals.additionalServices?.toFixed(2),
        'Additional Service Fee to Davenport': (+roundingValue)?.toFixed(2),
        // 'Total before tax': totals.totalBeforeTax?.toFixed(2),
        'Total before tax': (+casePrice)?.toFixed(2),
        // 'Tax 8.25%': +orderTotal - +casePrice,
        'Tax 8.25%': totalTax?.toFixed(2),
        // 'Total': totals.total?.toFixed(2),
        'Total': (+orderTotal)?.toFixed(2),
      })

      acc.push({})

      return acc;
    }, [])


  const separateCases = cases => cases?.reduce(
    (acc, caseData) => {
      if (caseData.regions.length > 1) {
        acc.mixedCases.push(caseData)
      } else {
        acc.singleRegionCases.push(caseData)
      }

      return acc;
    }, { singleRegionCases: [], mixedCases: [] }
  ) || { singleRegionCases: [], mixedCases: [] }


  const createWpImageFullLink = value => {
    const link = `https://alicekate.com/wp-content/uploads/${value}`
  }

  const createRestockHistoryList = list => {
    if (!Array.isArray(list) || !list.length) return [];

    let currentStock = 0;

    const restockList = list.map(restockData => {
      const { changeValue, category, stockValue } = restockData;
      const value = category === 'purchase' ? +changeValue * -1 : changeValue;
      const prevStock = stockValue > 0 ? stockValue : currentStock
      currentStock = category === 'purchase' ? currentStock + value : stockValue + value;
      const className = value < 0 ? 'negative' : 'positive'

      return { ...restockData, changeValue: value, updatedStock: currentStock, stockValue: prevStock, className }
    })

    return restockList;
  }


  // name	vintage	producer	wine	cost	stock
  // 05.05.2023
  // type of wine -- red, white, sparkling or rose
  // grape types -- comma separated grapes "chardonnay, pinot noir" etc
  // wine tastes --- "leather, red fruit, citrus"
  // labels -- "favorite, everyday"  (I dont even think we have labels for them yet but want to be able to add and export)
  const convertWinesListToCsvData = wines => sortListOfObjectByField(wines, 'region', {
    fieldType: 'string', order: 'desc'
  })?.map(({ region, vintage, producer, name, costUsd, stock, sku, upc, category, labels, tastes, grape }) => ({
    'Region': region,
    'Type of Wine': category,
    'Labels': labels?.join(',') || '',
    'Wine Tastes': tastes?.join(',') || '',
    'Grape Types': grape,
    'Vintage': +vintage > 0 ? vintage : 'NV',
    'Producer': producer,
    'Wine': name,
    'Cost': costUsd,
    'Stock': stock,
    'SKU': sku,
    'UPC': upc
  }))

  const splitExternalWineNameOnNameAndVintage = wineName => {
    if (typeof wineName !== 'string' || wineName.length < 1) return { name: '', vintage: '' };

    const pattern = /(\D{1,}\s)?(\d{4})?/;

    const [, name = '', vintage = 'NV'] = wineName.match(pattern);

    return { name: name?.trim(), vintage: vintage?.trim() }
  }

  const changeAppellationsList = (appellationsList, { wineAppellation, wineSku, field, value }) => {
    const newList = appellationsList.map(appellationData => {
      if (appellationData.appellation !== wineAppellation) return appellationData;

      const newWines = appellationData.wines.map(wineData => {
        if (wineData.sku !== wineSku) return wineData;

        const newWineData = { ...wineData, [field]: value }
        return newWineData
      })


      return { ...appellationData, wines: newWines }
    });

    return newList;
  }

  const createMxUserId = userId => {
    const href = window.location.href;

    if (href.includes('staging')) return `staging-${userId}`;
    if (href.includes('localhost')) return `localhost-${userId}`;
    if (href.includes('dev')) return `dev-${userId}`;

    return userId;
  }


  const getIntersectedArraysItems = (mainArray, secondaryArray) => {
    const uniqueItems = mainArray?.filter((mainItem, index) => {
      if (mainArray?.findIndex(item => item === mainItem) !== index)
        return false;

      return !secondaryArray.includes(mainItem)
    }) || []

    return uniqueItems;
  }

  const getSubstringIntersection = (firstStr, secondStr) => {
    if (!firstStr || !secondStr || typeof firstStr !== 'string' || typeof secondStr !== 'string') return undefined;

    const firstStringWords = firstStr.split(' ');
    const secondStringWords = secondStr.split(' ');
    const substring = [];
    let wordIndex = 0;

    while (wordIndex !== -1) {
      if (!firstStringWords[wordIndex] || !secondStringWords[wordIndex]) {
        wordIndex = -1;
      } else if (firstStringWords[wordIndex].toLowerCase() === secondStringWords[wordIndex].toLocaleLowerCase()) {
        substring.push(firstStringWords[wordIndex]);
        wordIndex += 1;
      } else {
        wordIndex = -1;
      }
    }

    return substring.length > 0 ? substring.join(' ') : undefined;
  }

  const formatPhoneValue = phone => {
    if (!phone) return '';

    const numbers = ('' + phone).replace(/\D/g, '');
    const groupsPattern = /^(\d)?(\d{3})(\d{3})(\d{4})$/
    const groups = numbers.match(groupsPattern);

    if (groups) {
      return `(${groups[2]})${groups?.[3] ? ` ${groups[3]}` : ``}${groups?.[4] ? `-${groups[4]}` : ``}`
    }

    return phone;
  }

  const createWineSlug = ({ vintage, name, producer }) => {
    const cleanProducerName = producer?.replaceAll(/[^\s\d\w]/g, '')?.trim() || ''
    const cleanWineName = name?.replaceAll(/[^\s\d\w]/g, '')?.trim() || ''

    const slugParts = [];

    vintage && slugParts.push(('' + vintage)?.trim())
    cleanProducerName && slugParts.push(cleanProducerName?.split(/\s+/)?.join('-')?.trim())
    cleanWineName && slugParts.push(cleanWineName?.split(/\s+/)?.join('-')?.trim())

    return slugParts.join('-')
  }

  const getNumberOfWines = wines => {
    if (!Array.isArray(wines)) return 0;

    return wines.reduce((acc, { quantity = 0 }) => {
      return acc + +quantity;
    }, 0)
  }

  const getListOfCaseBottleImages = (wines, asObjects = false) => {
    if (!Array.isArray(wines)) return []

    return wines?.reduce(
      (acc, { bottleImage, quantity }) => {
        for (let i = 0; i < quantity; i++) {
          const data = asObjects
            ? { key: `${i}-${bottleImage}`, src: bottleImage } : bottleImage
          acc.push(data)
        }

        return acc;
      }, []
    ) || []
  }

  const filterSameProducerOrRegionWinesList = ({ winesList, wineId = 0, producer }) => {
    const { producerWines, regionWines } = winesList
      ?.filter(({ id }) => wineId !== id)
      ?.reduce(
        (acc, wineData) => {
          if (wineData.producer?.trim() === producer?.trim()) {
            acc.producerWines.push(wineData)
          } else {
            acc.regionWines.length < 3 && acc.regionWines.push(wineData)
          }

          return acc;
        }, { producerWines: [], regionWines: [] }
      ) || { producerWines: [], regionWines: [] }

    const producerWinesList = producerWines.length > 0
      ? producerWines
      : regionWines

    return producerWinesList
  }

  const sortCases = (cases, { quantity, price, soldoutLast = true }) => {
    const list = quantity
      ? cases?.filter(({ wines }) => {
        const numOfBottles = getNumberOfWines(wines)

        return numOfBottles === quantity
      }) : cases

    return price
      ? list.sort(({ prices: { usd: price }, stock }, { prices: { usd: prevPrice }, stock: prevStock }) => {
        if (soldoutLast) {
          if (stock === 0 && prevStock > 0) {
            return 1
          }

          if (stock > 0 && prevStock === 0) {
            return -1
          }
        }

        return price === 'asc' ? +price - +prevPrice : +prevPrice - +price
      }) : list
  }

  const unmaskPhoneInput = inputValue => {
    const unmaskedValue = inputValue.replaceAll(/\(|\)|\W|\-|\+/g, '')

    return unmaskedValue;
  }

  const maskPhoneInput = phone => {
    const phoneNumbers = unmaskPhoneInput(phone)

    const value = phoneNumbers?.length === 11 && phoneNumbers.startsWith('1')
      ? phoneNumbers.split('').splice(1).join('') : phoneNumbers

    const maskedValue = value.split('').map((char, ind) => {
      switch (ind) {
        case 0: return `(${char}`
        case 3: return `) ${char}`
        case 6: return `-${char}`
        default: return char;
      }
    })

    return maskedValue.join('');
  }

  const getWineMarkupPrice = (cost) => {
    if (!cost) return '0.00';

    const withMurkup = Math.round(cost * 100 * 1.2) / 100

    return withMurkup.toFixed(2)
  }


  return { formatCurrency, isEmail, countBottles, scrollToSection, scrollTo, makeShortDescription, objectIsEmpty, getNextRegion, formatDateTime, defineCaseColorLabel, sortListOfObjects, createWinesTypes, convertDataToSelectOptions, countAndSortWines, getOrdersStatistics, sortListOfObjectByField, formCasePrice, capitalize, convertWarehouseStringToOptions, scrollToPageSection, convertTabbedContentToLanguagesObject, filterObject, filterTextRemoveLsep, getCurrencySymbol, getZipCodeFromPaypalDetails, getCroppedImg, getWineName, getPaypalPriceDetails, checkWarehouseManager, getCaseWinesAsListItemsWithTotalCost, getOrdersTotalsStatistics, createDifFieldsObject, createCaseDataRequestObject, createWineDataRequestObject, getOptionsData, getOption, formatDatepickerValue, convertDateToDatepickerValue, roundPrice, getVideoPlayerLink, getFullWineName, getHighLightedCase, splitCases, convertSerendipitySalesToCsvData, separateCases, getWpImageSrc, createWpImageFullLink, createRestockHistoryList, convertWinesListToCsvData, splitExternalWineNameOnNameAndVintage, changeAppellationsList, createMxUserId, getIntersectedArraysItems, getSubstringIntersection, formatPhoneValue, createWineSlug, getNumberOfWines, getListOfCaseBottleImages, filterSameProducerOrRegionWinesList, sortCases, unmaskPhoneInput, maskPhoneInput, getWineMarkupPrice }
}


export default useHelpers;
