import React from 'react'
import { Header, Icon } from '../../../components'


const PublicHomeHeader = () => {

  return (
    <div className="public-home__section public-home__header">
      <Header transparent={true} />
      
      <h1 className="public-home__title main">The easiest way to enjoy European wines.</h1>

      <p className="public-home__subtitle">Curated by experts. Delivered to you.</p>

      <Icon className="public-home__logo" icon="logo-with-text-vertical" color="white" size={200} />
    </div>
  )
}

export default PublicHomeHeader