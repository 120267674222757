import React from 'react';
import PropTypes from 'prop-types';

import './style.scss'


const TagsList = ({ tags }) => {
  const tagsList = tags?.map(tag => (
    <li key={`tags-list-item-${tag}`} className="tags-list__item">{tag}</li>
  ))

  return (
    <ul className="tags-list">{tagsList}</ul>
  )
}

TagsList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string)
}

export default TagsList