import React from 'react';
import PropTypes from 'prop-types';
import Badge from './StatusBadge';

import './style.scss';


const CustomBadge = ({ float, className, label }) => {
  const classNames = float ? 'custom-case-badge float' : 'custom-case-badge';
  const badgeClasses = className 
    ? `${ classNames } ${ className }` : classNames;

  return <Badge className={ badgeClasses } status={ label } withoutIcon={ true } />
};


CustomBadge.propTypes = {
  float: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
}

CustomBadge.defaultProps = {
  float: false,
  label: "CUSTOM"
}

export default CustomBadge;
