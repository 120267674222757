import { useSelector, useDispatch } from "react-redux"
import { casesActions as actions } from './case.slice'
import { requestAllRecommendations, requestCaseDetails, requestCasePrice, requestCasePageData, requestCasesPreviewsList, filterCases, requestCasesList } from './case.actions'

const useCases = () => {
  const dispatch = useDispatch();
  const { 
    error, processingRequest, processingRequests, list, currentCaseDetails, dataIsFetched 
  } = useSelector(state => state.cases);
  const casesState = { error, processingRequest, processingRequests, list, currentCaseDetails, dataIsFetched }
  const casesActions = {}

  casesActions.requestAllRecommendations = ({ label, numOfRecords } = {}) => 
    dispatch(requestAllRecommendations({ label, numOfRecords } || {}))

  casesActions.requestCaseDetails = ({ caseId = 0, language = 'en', withCustomerDetails }) => 
    dispatch(requestCaseDetails({ caseId, language, withCustomerDetails }))

  casesActions.requestCasePrice = ({ wines = [], caseDetailsId, warehouse }) =>
    dispatch(requestCasePrice({ wines, caseDetailsId, warehouse }))

  casesActions.requestCasePageData = ({slug, includeInactives, warehouse, language}) =>
    dispatch(requestCasePageData({slug, includeInactives, warehouse, language}))

    // { active = 1, warehouse = 2, category = 'all', withOrders }
  casesActions.requestCasesPreviewsList = ({ active, warehouse, category, withOrders }) => 
    dispatch(requestCasesPreviewsList({ active, warehouse, category, withOrders }))

  casesActions.requestCasesList = () => dispatch(requestCasesList())
  
  casesActions.filterCases = (cases, options = { quantity: undefined, price: undefined, soldoutLast: true }) => 
    dispatch(filterCases(cases, options))

  casesActions.changeCurrentCaseDetails = (currentCaseDetails) => {
    dispatch(actions.changeCurrentCaseDetails(currentCaseDetails))
  }

  casesActions.changeCurrentCaseWines = ({ newWines, newRegions }) => {
    dispatch(actions.changeCurrentCaseWines({ newWines, newRegions }))
  }

  casesActions.clearCasesStore = () => dispatch(actions.clearCasesStore())

  casesActions.setDataLoading = () => dispatch(actions.setDataLoading())

  casesActions.setCasesList = casesList => dispatch(actions.setCasesList(casesList))

  casesActions.setCurrentCase = caseData => dispatch(actions.setCurrentCase(caseData))

  casesActions.filterCasesList = ({ price, quantity, soldoutLast }) => {
    dispatch(actions.filterCasesList({ price, quantity, soldoutLast }))
  }
  

  return { casesState, casesActions }
}

export default useCases;
