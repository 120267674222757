import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useRequests } from '../../hooks';
import { toast } from 'react-toastify';
import { Select } from '../../components';


const TrackingInfoForm = ({ update, trackingNumber, carrier, orderId, onChange }) => {
  const { post, requestPending, errors, clearErrors } = useRequests();
  const [formData, changeFormData] = useState({
    trackingNumber: trackingNumber,
    carrier: carrier
  });

  let errorData = errors ? errors.errors : {};

  const shippingCarriersOpts = [
    { value: 'UPS', label: 'UPS' },
    { value: 'DHL', label: 'DHL' }
  ]

  const handleChange = e => {
    clearErrors();

    changeFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleFormSubmit = async e => {
    e.preventDefault();

    const response = await post({
      url: update
        ? `/api/orders/${orderId}/update-tracking`
        : `/api/orders/${orderId}/add-tracking`,
      body: formData
    });

    let trackingData;

    if (response && response.data.success) {
      const toastMessage = `Tracking info was successfully ${update ? 'updated' : 'saved'}.`;

      trackingData = response.data.shipping;
      const { newStatus } = response.data;
      toast.success(toastMessage);

      onChange(trackingData, newStatus);
    } else {
      toast.error('Request was failed...');
    }

  }


  return (
    <form className="small-form form_tracking">
      <h3>Tracking Info Form</h3>
      <div className={classNames("form-control", { 'invalid': errorData.trackingNumber })}>
        <label htmlFor="trackingNumber">Tracking Number</label>
        <input
          name="trackingNumber" type="text"
          placeholder="enter tracking number here"
          value={formData.trackingNumber}
          onChange={handleChange}
        />
        {
          errorData.trackingNumber &&
          <small className="error-msg">{errorData.trackingNumber}</small>
        }
      </div>

      <div className="form-control">
        <label htmlFor="carrier">Shipping Company Name</label>

        <Select
          name="carrier"
          disabled={requestPending}
          placeholder="shipping company name" options={shippingCarriersOpts}
          onChange={val => changeFormData({
            ...formData,
            carrier: val.value
          })}
          value={{ value: formData.carrier, label: formData.carrier }}
        />
      </div>

      <div className="button-container button_full-size">
        <button
          className="button"
          onClick={handleFormSubmit}
          disabled={requestPending}
        >{update ? 'Update' : 'Add'} Tracking Information</button>
      </div>
    </form>
  )
}


TrackingInfoForm.propTypes = {
  update: PropTypes.bool,
  trackingNumber: PropTypes.string,
  carrier: PropTypes.string,
  orderId: PropTypes.string
}

TrackingInfoForm.defaultProps = {
  update: false,
  trackingNumber: '',
  carrier: 'IWS'
}

export default TrackingInfoForm;
