import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'
import classNames from 'classnames'


const RadioButtonsGroup = ({ buttons, label, onClick, currentValue }) => {
  const handleClick = value => e => {
    e.preventDefault();

    onClick(value)
  }

  const content = buttons.map(({ buttonContent, value }) => {

    return (
      <button
        key={value}
        data-selected={currentValue === value}
        className={ classNames('button button_outline', { 'active': value === currentValue }) }
        onClick={ handleClick(value) }
      >{ buttonContent }</button>
    )
  })

  return (
    <div className="radio-button-group">
      {
        label &&
        <label>{ label }</label>
      }

      <div className="radio-button-group__buttons">{content}</div>
    </div>
  )
}


RadioButtonsGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    buttonContent: PropTypes.node,
    value: PropTypes.string
  }))
  , label: PropTypes.string
  , onClick: PropTypes.func
}

export default RadioButtonsGroup