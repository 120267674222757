import axios from 'axios'


export const fetchAllRecommendations = async ({ label, numOfRecords }) => {
  const queriesList = ['ws=1']
  queriesList.push('v=6')
  label && queriesList.push(`l=${label}`)
  numOfRecords && queriesList.push(`nc=${numOfRecords}`)

  const queryString = queriesList.join('&')

  const response = await axios.get(`/api/wines/cases/recommendations/all?${queryString}`);

  if (response?.data?.success) {
    return response?.data?.cases || []
  }

  return [];
}


export const fetchRecommendation = async ({ language = 'en', caseId }) => {
  const query = caseId ? `&cid=${ caseId }` : ``;
  const response = await axios.get(`/api/wines/cases/recommendations?lng=${language}${query}`);

  if (response?.data?.success) {
    return response.data
  }

  return {}
}

export const fetchCaseDetails = async ({ caseId = 0, language, withCustomerDetails }) => {
  const queryParams = [`lng=${ language }`]
  if (withCustomerDetails) queryParams.push(`cd=1`)
  
  const response = await axios.get(`/api/wines/cases/${caseId}?${ queryParams.join('&') }`);

  if (response?.data?.success) {
    return response.data.caseData;
  }

  return {};
}

export const getCasePrice = async ({ wines = [], warehouse, caseDetailsId }) => {
  const data = { wines, wh: warehouse, cid: caseDetailsId }
  const response = await axios.post(`/api/wines/cases/price`, data);

  if (response?.data?.success) {
    const { totalPrice, prices } = response?.data || { totalPrice: 0 };

    return { totalPrice, prices};
  }

  return { totalPrice: 0, prices: { usd: 0, eur: 0 } }
}


export const fetchCasesPreviewsList = async ({ active = 1, warehouse = 2, category, withOrders }) => {
  // { active, r: region, wh: warehouse, c: category, wo: withOrders }
  const queryParams = []
  active && queryParams.push(`active=${active}`)
  warehouse && queryParams.push(`wh=${warehouse}`)
  category && queryParams.push(`c=${category}`)
  withOrders && queryParams.join(`wo=1`)

  const response = await axios.get(`/api/content/cases-list${queryParams.length > 0 ? `?${queryParams.join('&')}` : ``}`)

  if (response?.data?.success) {
    return response.data.cases
  }

  return []
}


export const fetchCasePageData = async ({slug, includeInactives, warehouse = 2, language = 'en'}) => {
  const queryParams = [`lng=${language}`]
  warehouse && queryParams.push(`whid=${warehouse}`)
  includeInactives && queryParams.push(`a=-1`)

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ``

  const response = await axios.get(`/api/wines/cases/${slug}/case-details${queryString}`);

  if (response?.data?.success) {
    return response.data.caseDetails
  }

  return {}
}

export const fetchCasesList = async () => {
  const response = await axios.get(`/api/cases/get-all`);
  
  if (response?.data?.success) {
    return response?.data?.cases || [];
  }
}
