import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import useAnalitics from '../../hooks/analitics.hook';
import { useSelector } from 'react-redux';


const CustomRoute = ({ component: Component, fallback, accept, ...rest }) => {
  const { user, ip } = useSelector(state => state.auth);
  const { sendPageView, sendMxPageView } = useAnalitics();


  return (
    <Route
      {...rest}
      render={props => {
        const { location: { pathname, search } } = props.history;

        if (rest.track) {
          const title = rest.title || '';

          if (user?.role === 'registered user' && !user._suav) {
            sendPageView(title, pathname, pathname + search);
            sendMxPageView(title, user.id, user.role, `${user?.firstName} ${user?.lastName}`, user, ip);
          }
        }


        if (accept) {
          return <Component {...props} />
        }
        else {
          return (
            <Redirect push to={{
              pathname: fallback,
              state: {
                from: props.location.pathname + props.location.search
              }
            }}
            />
          )
        }
      }}
    />
  )
}


CustomRoute.propTypes = {
  component: PropTypes.any,
  fallback: PropTypes.string,
  accept: PropTypes.bool
}

CustomRoute.defaultProps = {
  accept: true
}

export default CustomRoute;
