import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { useHelpers } from '../../hooks';
import { CustomBadge } from '..';

import './style.scss';


const AdminOrderMobileRow = ({
  id, firstName, lastName, price, caseName, orderDate, status, pathname, currency, isAdjusted, location, userId,
  caseId, isOrderLink, isGift, userRole
}) => {
  const { formatDateTime, formatCurrency } = useHelpers();
  const role = userRole || 'admin'

  const customerName = <><b>Customer:</b> {firstName + ' ' + lastName}</>;
  const caseTagName = <><b>Case Name: </b>{' '}{caseName}
  {
    isAdjusted && <CustomBadge />
  }
  {
    isGift && <CustomBadge color="gold" label="gift" />
  }</>
  const caseTag = !isOrderLink && role === 'admin' 
    ? <Link to={`/admin/cases/${caseId}`} className="order-case">{ caseTagName }</Link>
    : <span className="order-case">{ caseTagName }</span>
  const customerTag = !isOrderLink && role === 'admin'
    ? <Link to={`/admin/users/${userId}`} className="order-owner">{customerName}</Link>
    : <span className="order-owner">{customerName}</span>

  return isOrderLink ? (
    <Link to={`/${role}/orders/${ id }`} className="admin-order-mobile-row" key={`m-order${id}case${caseId || caseName}`}>
      <span className="order-number"><b>ID:</b> {id}</span>
      <span className="order-date"><b>Date:</b> {formatDateTime(orderDate, false)}</span>
      {/* <span className="order-owner"><b>Customer:</b> {firstName + ' ' + lastName}</span>
      <span className="order-case"><b>Case Name: </b>{' '}
        {caseName}
        {
          isAdjusted && <CustomBadge />
        }
        {
          isGift && <CustomBadge color="gold" label="gift" />
        }
      </span> */}

      {customerTag}
      {caseTag}
      <span className="order-status"><b>Location:</b> {location || 'Not Specified'}</span>
      <span className="order-status"><b>Status:</b> {status}</span>
      {
        price &&
        <span className="order-price"><b>Cost:</b> {formatCurrency(price, currency, false, false)}</span>
      }
    </Link>
  ) : (
    <div className="admin-order-mobile-row" key={`m-order${id}case${caseId}`}>
      <span className="order-number"><b>ID:</b>{id}</span>
      <span className="order-date"><b>Date:</b> {formatDateTime(orderDate, false)}</span>
      {/* <Link to={`/admin/users/${userId}`} className="order-owner"><b>Customer:</b> {firstName + ' ' + lastName}</Link>
      <Link to={`/admin/cases/${caseId}`} className="order-case"><b>Case Name: </b>{' '}
        {caseName}
        {
          isAdjusted && <CustomBadge />
        }
        {
          isGift && <CustomBadge color="gold" label="gift" />
        }
      </Link> */}

      {customerTag}
      {caseTag}
      <span className="order-status"><b>Location:</b> {location || 'Not Specified'}</span>
      <span className="order-status"><b>Status:</b> {status}</span>
      {
        price &&
        <span className="order-price"><b>Cost:</b> {formatCurrency(price, currency, false, false)}</span>
      }
    </div>
  )
}

AdminOrderMobileRow.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  price: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  status: PropTypes.string,
  caseName: PropTypes.string,
  orderDate: PropTypes.string,
  currency: PropTypes.string,
  isAdjusted: PropTypes.bool,
}

AdminOrderMobileRow.defaultProps = {
  status: '',
  isAdjusted: false,
  isOrderLink: true
}

export default AdminOrderMobileRow;
