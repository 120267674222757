import React from 'react'
import { Icon } from '../../../components'


const NewPublicHomeAbout = () => {

  const infoList = [
    {
      icon: 'award',
      title: 'Community Curation',
      text: <>Vineify’s experts choose the best wines from their regions and create collections that go with every occasion.</>
    },
    {
      icon: 'map',
      title: 'Explore Wine Your Way',
      text: <>Whether you’re looking to expand your collection or just discover your new favorite weekday wine we’ve got you covered.</>
    },
    {
      icon: 'delivery-truck',
      title: 'Scheduled, Direct Delivery',
      text: <>Tired of waiting all day for that FedEx wine delivery? We let you schedule delivery to the minute with our local delivery partner. </>
    },
    {
      icon: 'shopping-cart',
      title: 'No Subscription Required',
      text: <>Buy wine when you need it, not when a wine club decides to ship it.  No monthly fees or taking wines you don’t want. </>
    },
    {
      icon: 'thumb-up',
      title: 'Great Wine, Great Value',
      text: <>Our simple pricing by collection includes the volume discount you’d normally receive if you bought 12 bottles at a wine store.</>
    },
    {
      icon: 'flashlight',
      title: 'Simple Shopping',
      text: <>With just a couple of clicks you get 6 or 12 bottles on their way to your house. Spend your time sipping, not shopping!</>
    },
  ]

  const cardsContent = infoList.map(({ title, text, icon }) => (
    <div key={`${title.replace(' ', '_')}`} className="public-home__info-card">
      <i className="info-card__icon-placeholder">
        <Icon icon={icon} size={20} color="dark-gold" />
      </i>

      <h3 className="info-card__title">{title}</h3>

      <p className="info-card__text">{text}</p>
    </div>
  ))

  return (
    <div className="public-home__section lite">
      <div className="container sm-p-32">
        <h2 className="public-home__title public-title-sm fw-400">Why You Will <b>Love Vineify!</b></h2>

        <div className="public-home__info-cards">{cardsContent}</div>
      </div>
    </div>
  )
}

export default NewPublicHomeAbout