import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LoginForm } from '..'
import CompactRegisterForm from './CompactRegisterForm'
import { useActions } from '../../hooks'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'


const CreateAccountOrSignedInForm = ({ onSignIn, hasAccount }) => {
  const location = useLocation()
  const dispatch = useDispatch();
  const { authActions } = useActions();
  const [currentForm, setCurrentForm] = useState(hasAccount ? 'login' : 'register')

  const handleSwitchForm = form => e => {
    e && e.preventDefault();

    setCurrentForm(form)
  }

  const switchLoginFrom = {
    label: 'Don’t have an account? click here',
    action: handleSwitchForm('register')
  }

  const switchRegisterFrom = {
    label: 'Already have an account? Click here',
    action: handleSwitchForm('login')
  }

  // const handleSignIn = () => login()
  const handleSignIn = () => {
    // const redirectPath = location.pathname.includes('/cart') ? '/cart/payment' : '/';

    // dispatch(authActions.login(redirectPath))
  }


  if (currentForm === 'login') {
    return (
      <>
        <div className="section__title login_title text-center">
          <h3>Sign In</h3>
        </div>

        <LoginForm
          onSignedIn={handleSignIn}
          onSwitchForm={switchLoginFrom}
        />
      </>
    )
  }

  return (
    // <MainRegisterForm onSwitchForm={switchRegisterFrom} />
    <CompactRegisterForm 
      onSwitchForm={switchRegisterFrom}
      subtitle="Create an account to complete your purchase."
    />
  )
}

CreateAccountOrSignedInForm.propTypes = {
  onSignIn: PropTypes.func
}

export default CreateAccountOrSignedInForm