import { useCallback, useState } from "react";
import axios from 'axios';


const useGeoLocation = () => {
  const [state, setState] = useState();
  const GEOLOCATION_ENDPOINT = 'https://api.ipregistry.co/?key=dh0ozvc3szq9mj';

  const getInitialLocationData = async () => {

    try {

      if (state) { return state; }

      const response = await axios.get(GEOLOCATION_ENDPOINT);

      const stateData = response?.data || {};
      const newState = {
        ...stateData,
        currency: {
          ...stateData.currency,
          code: ['usd', 'eur'].includes(stateData.currency?.code?.toLowerCase()) ?
            stateData.currency.code.toLowerCase() : 'usd'
        }
      }

      setState(newState);

      return newState;

    } catch (err) {
      // console.log('Geolocation Error: ', err);
    }
  }

  // eslint-disable-next-line

  const changeCurrency = useCallback(
    code => {
      setState(
        prev => ({ ...prev, currency: { ...prev.currency, code } })
      )
    }, []
  );

  const { location, currency, connection, carrier, user_agent: userAgent, ip } = state || {
    location: null, currency: null, connection: null, carrier: null, user_agent: null
  };

  return {
    state, ip, location, currency, connection, carrier, userAgent, changeCurrency, getInitialLocationData
  }
}

export default useGeoLocation;
