import React from 'react'
import Icon from '../common/Icon'

import './style.scss'


const SixPackCasesAlert = () => {

  return (
    <div className="six-pack-banner">
      <Icon icon="hot" color="white-wine-text" size={18} />
      
      {/* <p><b>Now sold in a convinient 6-pack size!</b> Find the best pack for you from the selection below.</p> */}
      <p><b>Now sold in a convenient 6-pack size!</b></p>
    </div>
  )
}

export default SixPackCasesAlert