import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const AdminWineRow = ({ name, stock, id, quantity, vintage, producer, cost, costUsd, currency, region, showPrice }) => {
  const { user } = useSelector(state => state.auth)
  const classname = stock < 4 ? 'admin-wine-row low-stock' : 'admin-wine-row';

  const wineCost = currency === 'usd' ? `$${ costUsd?.toFixed(2) }` : `€${ cost?.toFixed(2) }`;

  return (
    <Link to={`/${ user?.role === 'curator' ? 'curator' : 'admin' }/wines/${id}`} className={ classname }>
      <span className="id">{id}</span>
      <span className="date">{+vintage > 0 ? vintage : 'NV'}</span>
      <span className="producer">{ producer }</span>
      <span className="region">{ region }</span>
      <span className="name">{ name }</span>
      <span className="quantity">{ quantity }</span>
      {
        wineCost && showPrice &&
        <span className="cost">{ wineCost }</span>
      }
      <span className="stock">{ stock }</span>
    </Link>
  )
}


AdminWineRow.propTypes = {
  name: PropTypes.string,
  vintage: PropTypes.number,
  stock: PropTypes.number,
  id: PropTypes.number,
  quantity: PropTypes.number,
  producer: PropTypes.string,
  showPrice: PropTypes.bool
}

AdminWineRow.defaultProps = {
  stock: 0,
  showPrice: true
}

export default AdminWineRow
