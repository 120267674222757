import React, { useState, useRef, useContext, useEffect, useMemo, memo } from 'react'; 
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { Modal } from 'react-simple-hook-modal';
import { v4 as keyGen } from 'uuid';
import { toast } from 'react-toastify';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import authContext from '../../context/authContext';
import { CountryContext } from '../../context/country';
import { CheckoutContext } from '../../context';
import { useAnalitics, useHelpers, useRequests } from '../../hooks';
import { Alert, Spinner, VerificationAlert } from '..';

import Contact from '../../classes/Contact';
import Case from '../../classes/Case';

import closeIcon from '../../assets/icons/close-circle.svg';

import './style.scss';
import { useSelector } from 'react-redux';


const PaypalPaymentForm = ({ onSubmit, refPaymentForm, step, injectedCharge }) => {
  const { user } = useSelector(state => state.auth)
  const { order } = useSelector(state => state.cart)
  const { zip, warehouse } = useSelector(state => state.checkout)
  let ctx = useContext(authContext);
  const { getZipCodeFromPaypalDetails, getPaypalPriceDetails } = useHelpers();
  const { charge, currentCountry, currency: userCurrency } = useContext(CountryContext);
  const { setOrderCaptureAction, removeOrderCaptureAction, changeShippingCountry, changeShippingZipcode, orderCapture } = useContext(CheckoutContext);
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { capitalize, formCasePrice } = useHelpers();
  // eslint-disable-next-line
  const [paypalIsActive, setPaypalActiveState] = useState(true);
  const [showInitErrorAlert, setShowInitErrorAlertState] = useState(false);
  const [regions, setRegions] = useState([]);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [isModalOpen, changeModalOpenState] = useState(false);
  const paypalBtn = useRef();
  const initTimerRef = useRef();
  const paypalActionsRef = useRef();
  // eslint-disable-next-line
  const { post, get, errors } = useRequests();
  const { sendMxCustomEvent } = useAnalitics();

  const caseInstance = useMemo(() => new Case(order?.case), [order?.case])
  // const warehouseData = useMemo(() => caseInstance.getWareHouseData(), [caseInstance]);
  const warehouseName = useMemo(() => warehouse?.name, [warehouse]);
  // const currency = warehouseName?.startsWith('Serendipity')
  //   ? 'usd' : userCurrency
  const currency = useMemo(() => warehouse?.currency, [warehouse]);;

  const price = useMemo(() => caseInstance?.getPrice(currency, injectedCharge || charge, false), [currency, order?.case]);
  const paypalPaymentDetails = getPaypalPriceDetails({
    casePrice: price, shipping: 0, tax: warehouseName === 'IWS' ? 0 : 8.25, user: user?.role
  })
  const caseIsNotSoldOut = order?.case?.isActive;

  const { subtotal, total: casePrice, totalTax, shipping } = paypalPaymentDetails;

  const handleOrderCancel = () => {

    sendMxCustomEvent('Checkout Cancel', {
      ...user,
      userId: user.id,
      userName: `${user.firstName} ${user.lastName}`,
      caseId: order.case.id,
      caseName: order.case.name,
      casePrice: casePrice + ' ' + currency.toUpperCase(),
      role: user.role,
      test_order: user.role !== 'registered user'
    });
  }

  const shippingOutOfRange = useMemo(() => {
    return warehouse.zipcodes && !warehouse?.zipcodes.includes(zip)
  }, [zip])

  const handleCloseModal = e => {
    e.preventDefault();

    changeModalOpenState(false);
  }

  const handleOrderCapture = async e => {
    e.preventDefault();

    if (!orderCapture || paymentProcessing) return false;

    setPaymentProcessing(true);
    await orderCapture();
    setPaymentProcessing(false);
  }

  const checkInventory = async () => {
    const response = await get({ url: `/api/wines/cases/${order.case.caseDetailsId}/check-inventory` });

    return response?.data?.isAvailable;
  }

  const handleOrderApprove = async (data, actions) => {
    setPaypalActiveState(false);

    try {
      const paypalOrderDetails = await actions.order.get();

      const { address: { country_code: shippingCountryCode } } = paypalOrderDetails.purchase_units[0].shipping;

      // const shippingCountry = Contact.getCountryName(shippingCountryCode);

      // if (currentCountry !== shippingCountry) {
      //   setPaypalActiveState(true);

      //   return toast.warn(`Please choose shipping to ${currentCountry} or change the country in the menu in the header of the site`);
      // }

      const orderCapture = async () => {
        const order = await actions.order.capture();

        const {
          payer: {
            address: { country_code },
            email_address: email,
            phone: { phone_number: { national_number: phone } },
            name: { given_name: firstName, surname: lastName }
          },
          purchase_units
        } = order;

        const { shipping, payments: { captures } } = purchase_units[0];

        const contactDetails = Contact.createFromPaypalData({ ...shipping, email, phone });

        const request = {
          url: `/api/orders/payment-intent?lng=${i18n.language}`,
          body: {
            caseId: order.case.id,
            orderId: order.id,
            isOneTime: true,
            currency: currency,
            paymentMethod: 'paypal',
            paymentId: order.id,
            transactionId: captures[0].id,
            shipping: contactDetails,
            cost: casePrice,
            billing: {
              firstName, lastName, email, phone, company: '', city: '', zip: '', address: '',
              state: '',
              country: Contact.getCountryName(country_code)
            },
          }
        }

        user.role === 'registered user' &&
          sendMxCustomEvent('Purchase', {
            ...user,
            userId: user.id,
            userName: `${user.firstName} ${user.lastName}`,
            caseId: order.case.id,
            caseName: order.case.name,
            casePrice: casePrice + ' ' + currency.toUpperCase(),
            role: user.role,
            test_order: user.role !== 'registered user'
          });

        try {
          const response = await post(request);

          ctx.cart.shipping = { ...contactDetails };
          ctx.user = { ...ctx.user, hasNewWines: true };

          const { orderId, orderPlaced } = response.data;

          ctx.cart.order.id = orderId;
          ctx.cart.order.datePlaced = orderPlaced;
          ctx.cart.order.step = 'complete';
          ctx.cart.orderCapture = null;

          removeOrderCaptureAction();

          history.push('/cart/success');
        } catch (err) {
          // IVAN: email me and Jay that someone paid but creating an order failed. Include the users name and email.
          !isModalOpen && toast.error(`Oh no... something went wrong. Your payment went through but there was a problem on our end. Our army of engineers is on it and we will follow up with an email.`)
        }
      }

      ctx.cart.orderCapture = orderCapture;
      changeShippingCountry(Contact.getCountryName(shippingCountryCode));
      setOrderCaptureAction(orderCapture);
      onSubmit(paypalOrderDetails, actions.order, orderCapture);

      ctx.cart.order.paypal = {
        order: actions.order, details: paypalOrderDetails
      }

      changeShippingZipcode(getZipCodeFromPaypalDetails(paypalOrderDetails))
    } catch (err) {
      console.warn('Checkout Error: ', err);
    }

    setPaypalActiveState(true);
  }

  useEffect(() => {
    initTimerRef.current = setTimeout(() => {
      // if (!paypalIsActive && !paypalActionsRef.current) {
      //   setShowInitErrorAlertState(true)
      // }
      if (!paypalIsActive) {
        dispatch({
          type: "resetOptions",
          value: { ...options, currency: currency.toUpperCase() }
        })
      }
    }, 4000);

    dispatch({
      type: "resetOptions",
      value: { ...options, currency: currency.toUpperCase() }
    })

    // if (currency !== 'USD') {
    //   dispatch({
    //     type: "resetOptions",
    //     value: { ...options, currency: currency.toUpperCase() },
    //   });
    // }
    // eslint-disable-next-line 
  }, [currency, currentCountry]
  )

  useEffect(() => {

    if (errors?.errorCode === 'CHECKOUT_SERVER_ERROR') {
      sendMxCustomEvent('Checkout Server Error', {
        ...user,
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        caseId: order.case.id,
        caseName: order.case.name,
        casePrice: casePrice + ' ' + currency.toUpperCase(),
        role: user.role,
        test_order: user.role !== 'registered user',
        error_message: errors.message
      })
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(
    () => {
      if (paypalBtn.current) {
        !paypalIsActive || step === 'confirm'
          ? paypalBtn.current.style.display = 'none'
          : paypalBtn.current.style.display = 'block';
      }
    }, [step, paypalIsActive]
  );


  const handleCreateOrder = async (data, actions) => {
    const isAvailable = await checkInventory();

    if (!isAvailable) {
      const response = await get({ url: '/api/content/regions?main=1' });

      toast.clearWaitingQueue(true);
      setRegions(response?.data?.regions?.map(({ name }) => name) || []);

      return changeModalOpenState(true);
    }

    if (casePrice < 1) {
      // TODO: 
    }

    sendMxCustomEvent('Checkout Attempt', {
      ...user,
      userId: user.id,
      userName: `${user.firstName} ${user.lastName}`,
      caseId: order.case.id,
      caseName: order.case.name,
      casePrice: casePrice + ' ' + currency.toUpperCase(),
      role: user.role,
      test_order: user.role !== 'registered user'
    });

    return actions.order.create({
      purchase_units: [{
        amount: {
          value: casePrice,
          currency_code: currency.toUpperCase(),
          breakdown: {
            item_total: { value: subtotal, currency_code: currency.toUpperCase() },
            tax_total: { value: totalTax, currency_code: currency.toUpperCase() }
          }
        },
        items: [{
          name: order.case.name,
          unit_amount: { value: subtotal, currency_code: currency.toUpperCase() },
          quantity: '1'
        }],
        description: `Case name: "${order.case.name}". Price ${casePrice} ${currency.toUpperCase()}`,
        // payment_instruction: {
        //   platform_fees: [{
        //     amount: {
        //       currency_code: "USD",
        //       value: 333
        //     },
        //     payee: {
        //       email_address: "sb-mdcfl4622151@business.example.com",
        //       // merchant_id: ""
        //     }
        //   }],
        //   disbursement_mode: "INSTANT"
        // }
      }]
    })
  }


  const handleError = async err => {
    console.log('PAYPAL ERROR:::: ', err);

    sendMxCustomEvent('Checkout Error', {
      ...user,
      userId: user.id,
      userName: `${user.firstName} ${user.lastName}`,
      caseId: order.case.id,
      caseName: order.case.name,
      casePrice: casePrice + ' ' + currency.toUpperCase(),
      role: user.role,
      test_order: user.role !== 'registered user',
      error_message: err.message || 'no message'
    });

    await post({
      url: '/api/content/error-report',
      body: { error: err.message, subject: 'Paypal Error' }
    });
    // IVAN -- email me and Jay -- this is if the payment didnt go through at all correct?
    toast.error('So sorry.... there was an error with the payment going through. Please try again.');
  }

  const handlePageRefresh = e => {
    e.preventDefault();

    document.location.reload();
  }

  const handleInitButton = (data, actions) => {
    paypalActionsRef.current = actions;

    setPaypalActiveState(true);
  }

  const regionsButtons = regions.map(
    region => (
      <button key={keyGen()}
        className="button button_outline button_sm"
        onClick={() => history.push(`/regions/${region}`)}
      >{capitalize(t('EXPLORE')) + region}</button>
    )
  );


  return (
    <>
      <form ref={refPaymentForm} className="payment-form" id="payment">
        {
          caseIsNotSoldOut &&
          <>
            {
              showInitErrorAlert &&
              <Alert
                className="connection-failed-alert"
                title="Something went wrong while connecting to Paypal!"
                text="Please refresh the page to establish a new connection and try again."
              ><button className="button button_outline button_red" onClick={handlePageRefresh}
              >Refresh Page</button></Alert>
            }
            {
              !paypalIsActive && !showInitErrorAlert &&
              <div className="form-control paypal-control">
                <div className="processing-wrapper"><Spinner /> {t('PLEASE_WAIT')}...</div>
              </div>
            }

            {
              <div className="form-control paypal-control" ref={paypalBtn}>
                <PayPalButtons
                  onApprove={handleOrderApprove}
                  onCancel={handleOrderCancel}
                  onInit={handleInitButton}
                  createOrder={handleCreateOrder}
                  onError={handleError}
                  showSpinner={true}
                  style={{ label: 'checkout', shape: 'pill', size: 'responsive' }}
                />
              </div>
            }

            {
              orderCapture &&
              <>{
                window.matchMedia('(max-width: 720px)').matches
                  ? (
                    <Sticky mode='bottom'>
                      <div className="submit-button-wrapper">
                        <button
                          className="button contact-form-submit-button button"
                          disabled={paymentProcessing || shippingOutOfRange}
                          onClick={handleOrderCapture}
                        >Place Order {paymentProcessing && <Spinner spinnerClass="loading button-loading" />}</button>
                      </div>
                    </Sticky>
                  ) : (
                    <div className="submit-button-wrapper">
                      <button
                        className="button contact-form-submit-button button"
                        disabled={paymentProcessing || shippingOutOfRange}
                        onClick={handleOrderCapture}
                      >Place Order {paymentProcessing && <Spinner spinnerClass="loading button-loading" />}</button>
                    </div>
                  )
              }</>

            }
          </>
        }
        {
          !caseIsNotSoldOut &&
          <Alert
            className="out-of-stock-alert"
            title={`${order?.case?.name ?
              order?.case?.name : `This case`
              } is sold out`}
          />
        }
      </form>

      <Modal modalClassName="modal-dialog" isOpen={isModalOpen}>
        <button className="close-modal-btn" onClick={handleCloseModal}>
          <img src={closeIcon} alt="close modal" />
        </button>

        <VerificationAlert title="Out of Stock">
          <p className="no-margin">{t('CHECKOUT_WINE_SOLD_OUT_NOTIFICATION')}</p>

          <div className="modal-buttons-container w-100">{regionsButtons}</div>
        </VerificationAlert>
      </Modal>
    </>
  )
}


PaypalPaymentForm.propTypes = {
  customerCard: PropTypes.shape({
    owner: PropTypes.string,
    number: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string
    ]),
    expire: PropTypes.string,
    paymentMethod: PropTypes.string
  }),
  onSubmit: PropTypes.func,
  pending: PropTypes.bool
}

PaypalPaymentForm.defaultProps = {
  customerCard: {
    owner: '',
    number: '',
    expire: '',
    paymentMethod: 'card',
    pending: false
  }
}


export default memo(PaypalPaymentForm);
