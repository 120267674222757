export const SET_ORDER_CAPTURE_ACTION = 'SET_ORDER_CAPTURE_ACTION';
export const REMOVE_ORDER_CAPTURE_ACTION = 'REMOVE_ORDER_CAPTURE_ACTION';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SET_SHIPPING_COUNTRY = 'SET_SHIPPING_COUNTRY';
export const SET_SHIPPING_ZIPCODE = 'SET_SHIPPING_ZIPCODE';
export const SET_ORDER_WAREHOUSE = 'SET_ORDER_WAREHOUSE';
export const SET_SQUARE_DATA = 'SET_SQUARE_DATA';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const CHANGE_PAYMENT_STEP = 'CHANGE_PAYMENT_STEP';
export const RESET_CHECKOUT_STATE = 'RESET_CHECKOUT_STATE';
export const CHANGE_RETAILER = 'CHANGE_RETAILER';
export const CHANGE_SHIPPING_DETAILS = 'CHANGE_SHIPPING_DETAILS';
export const CHANGE_BILLING_DETAILS = 'CHANGE_BILLING_DETAILS';
export const CHANGE_IS_GIFT = 'CHANGE_IS_GIFT';
export const CHANGE_GIFT_MESSAGE = 'CHANGE_GIFT_MESSAGE';

