import React, { memo } from 'react'; 
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useActions, useAnalitics, useHelpers } from '../../hooks';

import likeIcon from '../../assets/icons/like.svg';
import dislikeIcon from '../../assets/icons/dislike.svg';
import questionMarkIcon from '../../assets/icons/question-mark.svg';
import { useDispatch, useSelector } from 'react-redux';


const WineFeedbackAlert = ({
  onChange, disabled, wine, post, feedbackRef, feedback, isVisible, timer, index
}) => {
  const dispatch = useDispatch()
  const { wineActions } = useActions()
  const { user: { role, id: userId, firstName, lastName } } = useSelector(state => state.auth);
  const { t } = useTranslation();
  const { sendMxCustomEvent } = useAnalitics();
  const { capitalize } = useHelpers();
  const content = wine.feedback.alertContent;

  const hideFeedbackDialog = () => {

    clearTimeout(timer.current)

    // onChange({ ...wine, feedback: { alertContent: '', alertIsVisible: false } });
    dispatch(wineActions.hideWineFeedback(index))
  }

  const handleButtonClick = async answer => {
    clearTimeout(timer.current);
    
    dispatch(wineActions.requestUpdateUserWineStatistics({ index, wineId: wine.id, willRecomend: answer }))

    sendMxCustomEvent(`Will Drink Again - ${answer} (${wine.name})`, {
      role, userId, userName: `${firstName} ${lastName}`,
      'Wine Name': wine.name, 'Wine ID': wine.id, 'Will Drink': answer
    });

    clearTimeout(timer.current);
    timer.current = setTimeout(() => { hideFeedbackDialog() }, 10000);
  }


  const handleUndoDrink = async () => {
    clearTimeout(timer.current);

    dispatch(wineActions.requestUpdateUserWineStatistics({ 
      index, wineId: wine.id, amountBottlesDrunk: -1, hideFeedback: true
    }))
  }


  return (
    <div ref={feedbackRef} className={classNames('wine-feedback', { 'hidden': !feedback.alertIsVisible })} >
      {
        content === 'alert' &&
        <p><b>{ capitalize(t('THANK_YOU')) }!</b> { t('MY_WINES_FEEDBACK_ALERT_MESSAGE') }</p>
      }
      {
        content === 'feedback' &&
        <>
          <p><b>{ t('MY_WINES_FEEDBACK_UPDATE_MESSAGE') }</b> { t('MY_WINES_FEEDBACK_DRINK_QUESTION') }</p>

          <button
            className={classNames('button button_outline', {
              'active': wine.willRecomend === 'yes'
            })}
            disabled={disabled}
            onClick={() => handleButtonClick('yes')}
          ><img src={likeIcon} alt="like" /> { capitalize(t('YES')) }!</button>

          <button
            className={classNames('button button_outline', {
              'active': wine.willRecomend === 'maybe'
            })}
            disabled={disabled}
            onClick={() => handleButtonClick('maybe')}
          ><img src={questionMarkIcon} alt="maybe" /> { capitalize(t('MAYBE')) }</button>

          <button
            className={classNames('button button_outline', {
              'active': wine.willRecomend === 'no'
            })}
            disabled={disabled}
            onClick={() => handleButtonClick('no')}
          ><img src={dislikeIcon} alt="dislike" /> { capitalize(t('NO')) }</button>

          <button
            className="button-link"
            onClick={handleUndoDrink}
          >{ capitalize(t('UNDO')) }</button>
        </>
      }
    </div>
  )
}


WineFeedbackAlert.propTypes = {
  isVisible: PropTypes.bool
}


WineFeedbackAlert.defaultProps = {
  isVisible: false
}


export default memo(WineFeedbackAlert);
