import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import 'normalize.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './localization';


if (document.location.host?.includes('vineify.com')) {
  const tagManagerArgs = {
    gtmId: 'GTM-5BWCK7M',
    dataLayerName: 'PageViewDataLayer'
  }
  
  TagManager.initialize(tagManagerArgs);
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
