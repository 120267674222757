import { createAsyncThunk } from "@reduxjs/toolkit";
import * as CheckoutService from '../../services/checkout.service'


// export const removeOrderCaptureAction = () => dispatch({
//   type: REMOVE_ORDER_CAPTURE_ACTION
// });

// export const setPaymentMethod = method => dispatch({
//   type: SET_PAYMENT_METHOD, payload: method
// })

// export const changePaymentStep = step => dispatch({
//   type: CHANGE_PAYMENT_STEP, payload: step
// })

// export const changeShippingZipcode = zipCode => dispatch({
//   type: SET_SHIPPING_ZIPCODE, payload: zipCode
// })

// export const setOrderCaptureAction = orderCaptureFunction => dispatch({
//   type: SET_ORDER_CAPTURE_ACTION, payload: orderCaptureFunction
// });

// export const setOrderWarehouse = warehouse => dispatch({
//   type: SET_ORDER_WAREHOUSE, payload: warehouse
// })

// export const changeRetailer = retailerData => dispatch({
//   type: CHANGE_RETAILER, payload: retailerData
// })

// export const setOrder = orderData => dispatch({
//   type: SET_ORDER_DATA, payload: orderData
// })

// export const changeShippingCountry = countryCode => dispatch({
//   type: SET_SHIPPING_COUNTRY, payload: countryCode
// });

// export const changeShippingDetails = shippingDetails => dispatch({
//   type: CHANGE_SHIPPING_DETAILS, payload: shippingDetails
// });

// export const changeBillingDetails = billingDetails => dispatch({
//   type: CHANGE_BILLING_DETAILS, payload: billingDetails
// });

// export const changeGiftMessage = message => dispatch({
//   type: CHANGE_GIFT_MESSAGE, payload: message
// })

// export const finishCheckout = () => dispatch({ type: RESET_CHECKOUT_STATE });

// export const changeIsGiftState = () => dispatch({ type: CHANGE_IS_GIFT })

// // place order
// const handlePlaceOrder = async e => {
//   e.preventDefault();
//   setProcessingPayment(true)

//   const response = await post({
//     url: `/api/orders/${ctx.cart.order.id}/create-payment`,
//     body: { tokenData: { ...paymentToken, rid: ctx.cart.order.orderInfo.id }, ...contacts, isGift, giftMessage }
//   });

//   if (response?.data?.success) {
//     ctx.cart.shipping = { ...shipping };
//     ctx.user = { ...ctx.user, hasNewWines: true };

//     const { orderId, orderPlaced, newUserData } = response.data;

//     sendMxCustomEvent('Purchase', {
//       ...ctx.user,
//       userId: ctx.user.id,
//       userName: `${ctx.user.firstName} ${ctx.user.lastName}`,
//       caseId: ctx.cart.order.case.id,
//       caseName: ctx.cart.order.case.name,
//       casePrice: total + '$',
//       role: ctx.user.role,
//       test_order: ctx.user.role !== 'registered user'
//     });

//     if (newUserData) {
//       const { firstName, lastName } = newUserData;
//       ctx.user.firstName = firstName?.length > 0 ? firstName : ctx.user.firstName
//       ctx.user.lastName = lastName?.length > 0 ? lastName : ctx.user.lastName
//     }
//     ctx.cart.order.id = orderId;
//     ctx.cart.order.datePlaced = orderPlaced;
//     ctx.cart.order.step = 'complete';
//     ctx.cart.orderCapture = null;

//     changePaymentStep('complete')

//     history.push('/cart/success');
//   } else {
//     setError(true);

//     onFail();
//     setTimeout(() => {
//       const errorAlertContainer = document.getElementById('checkout-error')

//       scrollTo(errorAlertContainer, { behavior: 'smooth', scrollMode: 'if-needed', block: 'start' })
//     }, 200)
//   }

//   setProcessingPayment(false);
// }

export const requestPlaceOrder = createAsyncThunk(
  '@@checkout/request-place-order',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState()

    const { paymentDetails: { token, details }, retailer, shipping, billing, isGift, giftMessage } = state.checkout;
    const { order } = state.cart;

    const tokenData = { details: { card: details.card }, token, rid: retailer.id }

    try {
      const newOrderData = await CheckoutService.createPayment({
        tokenData, shipping, billing, isGift, giftMessage, orderId: order.id
      });

      const { orderId: newOrderId, orderPlaced } = newOrderData;

      return { newOrderId, orderPlaced }
    } catch (err) {
      console.log('Caught error: ', err)
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestAddCaseToCart = createAsyncThunk(
  '@@checkout/add-case-to-cart',
  async ({ caseDetails, caseDetailsId, caseId, cost, redirectToCart }, thunkAPI) => {
    try {
      const payload = await CheckoutService.addCaseToCart({ caseDetails, caseDetailsId, caseId, cost })

      return { ...payload, redirectToCart};
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestAddAdjustedCaseToCart = createAsyncThunk(
  '@@checkout/add-adjusted-case-to-cart',
  async ({ caseDetails, wines, caseId, descriptions, longDescriptions, cost, eur, usd, redirectToCart }, thunkAPI) => {
    // caseDetails: providedCaseDetails = {}, wines, caseId, descriptions, longDescriptions, cost, prices

    try {
      const caseData = await CheckoutService.addAdjustedCaseToCart({ caseDetails, wines, caseId, descriptions, longDescriptions, cost, eur, usd })

      return {caseData, redirectToCart};
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestUserOrder = createAsyncThunk(
  '@@checkout/request-user-order',
  async ({ language = 'en' }, thunkAPI) => {
    try {
      const orderData = await CheckoutService.fetchUserOrder(language)

      return JSON.parse(orderData)
    } catch (err) {
      console.log('Fetch order error: ', err)
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCheckShippingZipcode = createAsyncThunk(
  '@@checkout/request-check-shipping-zipcode',
  async (zipcode, thunkAPI) => {
    try {
      const orderInfo = await CheckoutService.checkShippingZipcode(zipcode);

      const paymentMethod = orderInfo?.default ? 'paypal' : 'square';
      const retailerData = orderInfo;
      const paymentStep = 'shipping';

      return { paymentMethod, retailerData, paymentStep, zip: zipcode }
    } catch (err) {
      thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestPaymentData = createAsyncThunk(
  '@@checkout/request-payment-data',
  async (_, thunkAPI) => {
    const paymentData = await CheckoutService.fetchPaymentInfo()

    
  }
)
