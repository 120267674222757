import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHelpers, useRequests } from '../../hooks';
import WarehousePricingInfo from '../common/WarehousePricingInfo';
import LoadingScreen from '../loading/LoadingScreen';

import './style.scss';  


const FetchCaseDetailsDialog = ({ caseId }) => {
  const { get, requestPending } = useRequests();
  const { getPaypalPriceDetails } = useHelpers();
  const [caseData, changeCaseData] = useState();

  const fetchCaseData = useCallback(async () => {
    if (!caseId) return null;

    const response = await get({ url: `/api/wines/cases/${caseId}/case-price-details` });

    if (response?.data?.success) {
      changeCaseData(response.data.caseDetails)
    }
  }, [caseId])

  useEffect(fetchCaseData, [fetchCaseData, caseId]);

  if (requestPending || !caseData) return <LoadingScreen />

  // const {} = getPaypalPriceDetails({  })

  return (
    <div className="case-price-details-layout">
      { caseData?.caseName && <h3>{ caseData.caseName }</h3> }

      <WarehousePricingInfo {...caseData} {...caseData.pricing} />
    </div>
  )
}

FetchCaseDetailsDialog.propTypes = {
  caseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default FetchCaseDetailsDialog