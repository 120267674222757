import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types' 
import * as htmlToImage from 'html-to-image'
import CaseImageWithBottles from './CaseImageWithBottles'

import './style.scss'


const HtmlWithGenerateImageButton = ({ contentContainerClassname, visible, height, width }, ref) => {
  const [isVisible, setVisibleState] = useState(visible)
  const [bottleImages, changeBottleImages] = useState()
  const imageContainerRef = useRef()

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const className = useMemo(() => {
    const initialClassname = isVisible ? 'visible' : 'not-visible'
    const classNamesList = [initialClassname]

    if (contentContainerClassname) classNamesList.push(contentContainerClassname)

    return classNamesList.join(' ')
  }, [isVisible])

  const changeVisibleState = isVisible => {
    setVisibleState(isVisible)
  }

  const generateImage = async ({ width = 366, height = 205, quality = .5 } = {}) => {
    const blob = await htmlToImage
      .toBlob(
        imageContainerRef.current, 
        { 
          cacheBust: true, quality: quality || .5, 
          style: { visibility: 'visible' },
          height: height || 205, width: width || 366
        }
      )

    const data_url = await blobToBase64(blob)
    const image = { file: blob, data_url }

    return image
  }

  const changeImages = bottleImages => {
    changeBottleImages(bottleImages)
  }

  useImperativeHandle(ref, () => {
    return { generateImage, changeImages, changeVisibleState }
  }, [bottleImages])

  const wrapperStyle = { visibility: isVisible ? 'visible' : 'hidden' }


  return (
    <div className={className} id="image-content" ref={imageContainerRef}>
      <CaseImageWithBottles
        backgroundImage="https://vineify-dev-files.s3.amazonaws.com/1gpacfocr_full_size_cases_default_card_image.jpg"
        bottleImages={bottleImages}
        caseId={0}
        useSmallBottleImages={false}
        containerClass="case-image-wrapper"
      />
    </div>
  )
}

HtmlWithGenerateImageButton.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  onGenerateImage: PropTypes.func
}

export default forwardRef(HtmlWithGenerateImageButton)
