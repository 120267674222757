import { createAsyncThunk } from '@reduxjs/toolkit'
import * as CaseService from '../../services/case.service';


export const requestAllRecommendations = createAsyncThunk(
  '@@cases/request-all-recommendations',
  async ({ label, numOfRecords } = {}, thunkAPI) => {
    try {
      const cases = await CaseService.fetchAllRecommendations({ label, numOfRecords });

      return cases;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCaseDetails = createAsyncThunk(
  '@@cases/request-case-details',
  async ({ caseId = 0, language = 'en', withCustomerDetails }, thunkAPI) => {
    try {
      const caseDetails = await CaseService.fetchCaseDetails({ caseId, language, withCustomerDetails })
  
      return JSON.stringify(caseDetails || {});
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCasePrice = createAsyncThunk(
  '@@cases/request-case-price',
  async ({ wines = [], caseDetailsId, warehouse }, thunkAPI) => {
    try {
      const priceData = await CaseService.getCasePrice({ wines, caseDetailsId, warehouse });

      return priceData;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCasePageData = createAsyncThunk(
  '@@cases/request-case-page-data',
  async ({ slug, includeInactives, warehouse, language }, thunkAPI) => {
    // slug, includeInactives, warehouse = 2, language = 'en'}
    try {
      const data = await CaseService.fetchCasePageData({slug, includeInactives, warehouse, language})

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const requestCasesPreviewsList = createAsyncThunk(
  '@@cases/request-cases-previews-list',
  async ({ active = 1, warehouse = 2, category, withOrders }, thunkAPI) => {
    try {
      // { active = 1, warehouse = 2, category = 'all', withOrders }
      const payload = await CaseService.fetchCasesPreviewsList({ active, warehouse, category, withOrders })

      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const filterCases = (cases, { quantity, price, soldoutLast = true }) => {
  if (!Array.isArray(cases)) return []

  const casesCopy = cases.slice()
  const filteredCases = quantity 
    ? casesCopy?.filter(({ wines }) => {
      const numOfBottles = wines.reduce((acc, { quantity }) => acc + +quantity, 0)

      return numOfBottles === quantity
    }) : casesCopy

    if (price) {
      filteredCases.sort(({ prices: { usd: price }, stock }, { prices: { usd: prevPrice }, stock: prevStock }) => {
        if (soldoutLast) {
          if (stock === 0 && prevStock > 0) {
            return 1
          }
  
          if (stock > 0 && prevStock === 0) {
            return -1
          }
        }

        if (price === 'asc') return +price - +prevPrice
        if (price === 'desc') return +prevPrice - +price

        return 0
      }) 
    }
    
    return filteredCases;
}


export const requestCasesList = createAsyncThunk(
  '@@cases/request-cases-list',
  async (_, thunkAPI) => {
    // thunkAPI.dispatch(`@@cases/setDataLoading`)
    
    try {
      const payload = await CaseService.fetchCasesList()

      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
