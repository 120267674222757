import { createSlice } from '@reduxjs/toolkit'
import { requestCuratorPreviewData, requestCuratorCasesPreviewList, requestCuratorsPreviewsList, requestCuratorOrders, requestCuratorRecommendationsHistory, requestSendRecommendations } from './curator.actions'
import { requestFollowCurator } from '../auth/auth.actions'


const initialState = {
  error: undefined,
  processingRequest: false,
  processingRequests: 0,
  list: [],
  currentCuratorData: {
    followers: [],
    recommedationsSent: []
  },
  dataIsFetched: false
}

const curatorSlice = createSlice({
  name: '@@curators',
  initialState,
  reducers: {
    clearCuratorStore: (state) => {
      return { ...initialState }
    },
    setCurrentCuratorData: (state, { payload }) => {
      state.currentCuratorData = payload
    }
  },
  extraReducers: builder =>
    builder
      .addCase(requestCuratorPreviewData.fulfilled, (state, { payload }) => {
        state.currentCuratorData = payload
        state.dataIsFetched = true
      })
      .addCase(requestCuratorsPreviewsList.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(requestFollowCurator.fulfilled, (state, { payload }) => {
        const { status, user } = payload;
        
          const newFollowersRecord = status === 'follow'
            ? [ ...new Set(state.currentCuratorData.followers || []), +user ]
            : state.currentCuratorData.followers?.filter(id => +id !== +user) || []

          state.currentCuratorData.followers = newFollowersRecord
      })
      .addCase(requestCuratorCasesPreviewList.fulfilled, (state, { payload }) => {
        state.currentCuratorData.cases = payload
      })
      .addCase(requestCuratorOrders.fulfilled, (state, { payload }) => {
        state.currentCuratorData = { orders: payload }
        state.dataIsFetched = true
      })
      .addCase(requestCuratorRecommendationsHistory.fulfilled, (state, { payload }) => {
        state.error = payload.error
        state.currentCuratorData.recommedationsSent = payload.recommendations
      })
      .addCase(requestSendRecommendations.fulfilled, (state, { payload }) => {
        state.error = payload.error
        state.currentCuratorData.recommedationsSent = [
          ...state.currentCuratorData.recommedationsSent,
          ...payload.recommendations
        ]
      })
      .addMatcher(
        (action) => action.type.startsWith('@@curators') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@curators') && action.type.endsWith('/pending'),
          (state, _) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@curators') && action.type.endsWith('/fulfilled'),
          (state, _) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})




export const curatorsActions = {...curatorSlice.actions, requestCuratorPreviewData, requestCuratorCasesPreviewList, requestCuratorsPreviewsList};

export default curatorSlice.reducer;
