import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHelpers } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../context/country';
import Case from '../../classes/Case';
import { CustomBadge, ShortDescription, WinesTypesSummary, CaseStockBadge, CaseImageWithBottles } from '../';

import './style.scss'


const NewCaseCard = ({
  caseData: propsCase, highLighted, onChooseCase, descriptionSize, previewMode, isAdjustedCase, isSmall, inventoryIsHidden, caseCardClassname, isNarrow, showStockBadge, showDynamicPicture, isOrderCase
}) => {
  const { t } = useTranslation();
  const { charge, currency } = useContext(CountryContext); 
  const caseData = propsCase instanceof Case ? propsCase : new Case(propsCase);
  const { name, wines, image, description, active, category, id } = caseData;
  const { createWinesTypes, formCasePrice, getListOfCaseBottleImages } = useHelpers();
  const price = formCasePrice(caseData.getPrice(currency, charge));
  const showCaseImage = image || !showDynamicPicture;
  const showDetails = function (e) {
    e.preventDefault();

    onChooseCase(id)
  }
  const lines = isSmall ? 4 : descriptionSize


  const wineTypes = Array.isArray(wines) ?
    createWinesTypes(wines) : wines;

  let caseLayoutColor = highLighted ? 'default' : 'mix';

  if (isOrderCase) {
    caseLayoutColor = 'mix'
  } else if (!highLighted && category === 'custom') {
    caseLayoutColor = 'brown'
  } else {
    if (!highLighted && wineTypes.red > wineTypes.white + wineTypes.sparkling + wineTypes.rose) { caseLayoutColor = 'red' } 
    if (!highLighted && wineTypes.white > wineTypes.red + wineTypes.sparkling + wineTypes.rose) { caseLayoutColor = 'white' }
    if (!highLighted && wineTypes.rose > wineTypes.red + wineTypes.sparkling + wineTypes.white) { caseLayoutColor = 'rose' }
  }

  const caseCardClassnamesList = ['card', 'card_case'] 
  if (active > 0) caseCardClassnamesList.push('active')
  if (caseCardClassname) caseCardClassnamesList.push(caseCardClassname)


  const winesTypesSummary = inventoryIsHidden ? null : <WinesTypesSummary {...wineTypes} />


  return <div className={caseCardClassnamesList.join(' ')}>
    { 
      showStockBadge && 
      <CaseStockBadge 
        className={ highLighted ? 'highlighted' : '' }
        stock={ caseData.stock }
        isSmall={ isSmall && !highLighted }
        isNarrow={ isNarrow }
      />
    }
    <div className={`card-body-wrapper case_${caseLayoutColor}`} />

    {
      !image && showDynamicPicture &&
      <div className="card-bg">
        <CaseImageWithBottles 
          bottleImages={getListOfCaseBottleImages(caseData.wines)}
          containerClass="order-dynamic-case-image"
        />
      </div>
    }
    {
      showCaseImage &&
      <div className="card-bg"><img src={image} alt={name} /></div>
    }

    <div className="card__body">
      <div className="card_case-content" data-cy-id={ caseData.id }> 
        {isAdjustedCase && <CustomBadge float={true} />}

        <h2 className="case__title">{name}</h2>

        {winesTypesSummary}

        {
          !previewMode &&
          <h4 className="case__price">{price}</h4>
        }
        <div className="card-bottom-section">
          {
            descriptionSize > 0 &&
            <ShortDescription
              lines={lines}
              // textClassname="text-shadow"
              expandable={false}
              withFade={false}
            >{description || ''}</ShortDescription>
          }
          {
            !previewMode &&
            <button
              className="button reversed"
              onClick={showDetails}
            >{t('SEE_CASE_DETAILS')}</button>
          }
        </div>


      </div>

    </div>
  </div>
}


NewCaseCard.propTypes = {
  name: PropTypes.string,
  wines: PropTypes.arrayOf(PropTypes.object),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChooseCase: PropTypes.func,
  category: PropTypes.oneOf(['custom', 'standard', '']),
  id: PropTypes.number,
  caseCardClassname: PropTypes.string,
  descriptionSize: PropTypes.number,
  previewMode: PropTypes.bool,
  highLighted: PropTypes.bool,
  isNarrow: PropTypes.bool,
  isAdjustedCase: PropTypes.bool,
  inventoryIsHidden: PropTypes.bool,
  showStockBadge: PropTypes.bool,
  showDynamicPicture: PropTypes.bool,
  isOrderCase: PropTypes.bool
}

NewCaseCard.defaultProps = {
  descriptionSize: 3,
  tempClass: 'mix',
  wines: [],
  previewMode: false,
  highLighted: false,
  isAdjustedCase: false,
  inventoryIsHidden: false,
  isNarrow: false,
  showStockBadge: true,
  onChooseCase: () => { },
  showDynamicPicture: false,
  isOrderCase: false
}

export default NewCaseCard;
