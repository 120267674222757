import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import { toast } from 'react-toastify';
import { LoadingScreen, WaitListInvitationBar } from '../../components';
import { useRequests, useStoreCart, useStoreCases, useStoreRegions } from '../../hooks';

import QuizInitSection from '../quiz/components/QuizInit';

import PublicHomeCasesCarousel from './sections/PublicHomeCasesCarousel';
import PublicHomeCurators from './sections/PublicHomeCurators';
import PublicHomeMotivationSection from './sections/PublicHomeMotivationSection';
import NewPublicHomeHeader from './sections/NewPublicHomeHeader';
import NewPublicHomeAbout from './sections/NewPublicHomeAbout';
import PublicHomeRegions from './sections/PublicHomeRegions';
import NewPublicHomeSubscribeSection from './sections/NewPublicHomeSubscribeSection';
import PublicHomeWelcome from './sections/PublicHomeWelcome';

import './style.scss';
import AiWineBuddySection from './sections/AiWineBuddySection';


const NewPublicHomePage = () => {
  const { casesActions, casesState: { dataIsFetched, list: cases } } = useStoreCases()
  const { regionActions, regionsState: { sliderRegions: regions } } = useStoreRegions()
  const { cartActions } = useStoreCart()
  const history = useHistory();
  const { post } = useRequests()
  const [state, changeState] = useState({
    dataIsFetched: false, regions: [], cases: [], 
    notificationIsShawn: true, 
    showQuiz: sessionStorage.getItem('_cna') > 0
  });

  const handleViewCaseContent = ({ slug }) => {
    history.push(`/cases/${ slug }`);
  }

  const handleCasePurchase = async caseDetails => {
    const { id, caseDetailsId, stock, cost } = caseDetails;

    if (stock === 0) return;

    if (cost <= 0) {
      await post({
        url: '/api/content/error-report',
        body: {
          subject: `Error: cost $0 in case ID${id}`,
          error: `Error: cost $0 in case ID${id}`
        }
      })

      return toast.error('Something went wrong');
    }
    

    cartActions.requestAddCaseToCart({ caseDetails, caseId: id, caseDetailsId, cost, redirectToCart: true })
  };

  const fetchAllData = async () => {
    regionActions.requestSliderRegionsData()
    // casesActions.requestAllRecommendations({ numOfRecords: 6 })
    casesActions.requestAllRecommendations({ label: 'Cabernet Sauvignon' })

    changeState(prev => ({
      ...prev, dataIsFetched: true
    }))
  }

  const handleChangeGrapeFilter = grapeLabel => {
    if (grapeLabel) {
      return casesActions.requestAllRecommendations({ label: grapeLabel })
    }

    casesActions.requestAllRecommendations({ numOfRecords: 6 })
  }

  const handleShowHomePage = e => {
    e && e.preventDefault()

    sessionStorage.setItem('_cna', 0)

    changeState(prev => ({ ...prev, showQuiz: false }))
  }

  const hadleRedirectToWinesPreferences = e => {
    e && e.preventDefault()

    history.push('/wine-preferences')
  }

  useEffect(() => {
    fetchAllData()

    return () => {
      casesActions.clearCasesStore()
    }
  }, []);

  const handleDismissAlert = () => {
    changeState(prev => ({ ...prev, notificationIsShawn: false }))
  }


  if (!dataIsFetched && !cases?.length) return <LoadingScreen />

  if (dataIsFetched && state.showQuiz) {
    return <QuizInitSection
      onApply={hadleRedirectToWinesPreferences}
      onCancel={handleShowHomePage}
    />
  }


  return (
    <div className="public-home">
      {
        state.notificationIsShawn && 
        <WaitListInvitationBar 
          onDismiss={handleDismissAlert} 
          headerClassName="public-home__header-alert"
        />
      }
      <Helmet>
        <title>Vineify - the best way to enjoy European wines</title>
      </Helmet>

      <NewPublicHomeHeader />

      <AiWineBuddySection />

      <PublicHomeMotivationSection />

      <PublicHomeCasesCarousel 
        onPurchase={handleCasePurchase}
        onViewCaseContent={ handleViewCaseContent }
        onChangeGrapeLabel={handleChangeGrapeFilter}
        cases={cases}
        initialGrape="Cabernet Sauvignon"
        useNewHeader
      />

      <PublicHomeRegions regions={regions} />

      <PublicHomeCurators />

      <NewPublicHomeAbout />

      <PublicHomeWelcome />

      <NewPublicHomeSubscribeSection />
    </div>
  )
}


export default NewPublicHomePage;
