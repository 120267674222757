import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useRequests, useHelpers } from '../../hooks';
import { TabbedInput, Icon, ButtonWithPopoverConfirmation } from '..';


const ManageTasteForm = ({ tasteData, onSubmit, onCancel }) => {
  const { convertTabbedContentToLanguagesObject } = useHelpers(); 
  const { post, del, requestPending } = useRequests();
  const [taste, changeTaste] = useState([
    { tabName: 'en', text: tasteData?.names?.en || '' },
    // { tabName: 'it', text: tasteData?.names?.it || '' }
  ]);
  const [confirmationIsOpened, changeConfirmationState] = useState(false);

  const isDisabled = requestPending
    || taste?.[0].text === tasteData?.names?.en 
    || taste?.[0].text === '';

  const forUpdate = +tasteData?.id > 0;

  const changeTasteName = (val, tabs) => {

    changeTaste(tabs);
  }

  const handleCancel = e => {
    e.preventDefault();

    onCancel();
  }

  const handleToggleConfirmation = state => e => {
    e && e.preventDefault();

    changeConfirmationState(state === 'open')
  }

  const handleRemoveTaste = async e => {
    e && e.preventDefault();

    const response = await del({ url: `/api/wines/tastes/${tasteData?.id}` });

    if (response?.data?.success) {
      toast.success(`Taste "${taste?.[0].text}" was successfuly deleted.`)

      onSubmit(tasteData, true);
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const response = await post({
      url: forUpdate ? `/api/wines/tastes/${tasteData?.id}` : '/api/wines/tastes',
      body: { taste: { ...convertTabbedContentToLanguagesObject(taste), it: '' } }
    });

    if (response?.data?.success) {
      forUpdate ?
        onSubmit({ names: convertTabbedContentToLanguagesObject(taste), id: tasteData?.id })
        :
        onSubmit(response?.data.newTaste)
    } else {
      toast.error(response.data.message)
    }
  }


  return (<>
    <button
      className="close-modal-btn" type="button"
      onClick={handleCancel}
    ><Icon icon="close" color="tertiary" size={24} /></button>
    <form className="form-horizontal">
      <div className="form-section-title" style={{ marginTop: 0 }}>{forUpdate ? `Manage Taste Data` : `Create New Taste`}</div>

      <div className="form-control">
        <TabbedInput name="taste" tabs={taste} onChange={changeTasteName} />
      </div>

      <div className="form-control horizontal flex-space-around gap_xs" style={{ marginTop: '24px' }}>
        {
          forUpdate &&
          <ButtonWithPopoverConfirmation
            title="Confirmation"
            btnText="Delete Taste"
            disabled={ requestPending }
            isOpen={confirmationIsOpened}
            confirmText={`Please confirm you want to delete taste "${taste?.[0].text}"`}
            onClick={handleToggleConfirmation('open')}
            onCancel={handleToggleConfirmation('close')}
            onConfirm={handleRemoveTaste}
          />
        }
        {
          !forUpdate &&
          <button
            className="button button_outline button_xs"
            onClick={handleCancel}
          >Cancel</button>
        }

        <button
          className="button button_xs"
          onClick={handleSubmit}
          disabled={isDisabled}
        >{forUpdate ? `Change Taste` : `Add Taste`}</button>
      </div>
    </form>
  </>)
}


ManageTasteForm.propTypes = {
  onSubmit: PropTypes.func
}

export default ManageTasteForm;
