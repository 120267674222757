import React from 'react'
import PropTypes from 'prop-types';
import Contact from '../../classes/Contact';
import { useHelpers } from '../../hooks';


const OrderContactCard = ({ contactDetails: contactData, title, children }) => {
  const { maskPhoneInput } = useHelpers()
  const contactDetails = !contactData ? null : new Contact(contactData);

  const phone = contactDetails?.phone ? contactDetails.phone : 'not specified';
  const content = !contactDetails ? null : !contactDetails.isEmpty()
    ? <p>Not specified</p>
    : (
      <>
        {/* <p className="no-margin">
          <b>customer:</b> {' ' + contactDetails.firstName + ' ' + contactDetails.lastName}
        </p> */}
        <p className="no-margin">
          <b>phone: </b> {' ' + maskPhoneInput(phone)},
          <br />
          <b>email: </b> {' ' + contactDetails.email}
        </p>
        <p className="no-margin" style={{ whiteSpace: 'pre-line' }}>
          <b>address: </b>{
            `\n${ contactDetails.firstName } ${ contactDetails.lastName }
            ${ contactDetails.address }
            ${ contactDetails.city }, ${ contactDetails.state } ${ contactDetails.zip }, ${ contactDetails.country }`
          }
        </p>
      </>
    )

  return (
    <div className="order-contact-details">
      <h5 className="no-margin">{title}</h5>
      {content}
      {children}
    </div>
  )
}

OrderContactCard.propTypes = {
  title: PropTypes.string,
  contactDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string
  })
}

export default OrderContactCard
