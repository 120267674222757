import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '..'

import './style.scss';


const LoadingSpinner = ({ spinnerClass, color, size }) => {
    const classList = ['spinner']
    spinnerClass && classList.push(spinnerClass)
    // return <img className={ classList } src={loading} alt="loading" />
    return <Icon className={ classList.join(' ') } icon="spinner" color={ color || 'warm-gray-3' } size={ size } />
}
LoadingSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  spinnerClass: PropTypes.string,
}

LoadingSpinner.defaultProps = {
  size: 40
}

export default LoadingSpinner;
