import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useModal, Modal } from 'react-simple-hook-modal';
import { useHelpers, useRequests } from '../../hooks';
import ImageUploading from 'react-images-uploading';
// import ImageUploadFormControl from './ImageUploadFormControl';
import { Select, PriceWithPricingDetails, TabbedTextarea, TabbedInput, Alert, AdminCaseWinesCategories, GenerateDescriptionDialog, CaseRegionsTags, HtmlWithGenerateImageButton, LabelsControlPanel, ImageUploadFormControlWithAutoGeneratingCaseImage, Icon } from '..';
import AdminCaseWineCard from '../card/AdminCaseWineCard';
import Case from '../../classes/Case';

import closeIcon from '../../assets/icons/close.svg';

import './style.scss';

const DEFAULT_IMG_URL = `https://vineify-dev-files.s3.amazonaws.com/1gallkbkt_default_case_image.jpg`;

const _formWinesRequestData = wines => wines
  ?.filter(({ quantity }) => quantity > 0)
  ?.map(({ id, quantity }) => ({ id, quantity })) || []

const getCaseActiveWineCategories = wines => {
  if (!wines) return [];

  const categories = wines.reduce(
    (acc, wineData) => {
      if (wineData?.quantity > 0) acc.push(wineData.category)

      return acc;
    }, []
  )

  return [...new Set(categories)]
}


const CaseDataForm = ({ winesList, regionsList, customersList, warehousesList, caseData: currentCaseData, pricingDetails, longDescriptionsList, descriptionsList, namesList, warehouseData, warehousesDataList, caseMarkup, serviceFee, withoutCaseOrders, customerInfo, curatorsList, curator, hasNoSnapshots, labels, labelsList }) => {
  const filtersData = JSON.parse(localStorage.getItem('_cdffs') || '{}');
  const { user: { role, id: currentUserId, curatorRole } } = useSelector(state => state.auth)
  const { closeModal, isModalOpen, openModal } = useModal();
  const history = useHistory();
  const initialDataLoadingRef = useRef()
  const initialStateRef = useRef()
  const delayedPricingRef = useRef();
  const delaedPriceActionRef = useRef();
  const autogeneratedimageRef = useRef()
  const [search, changeSearch] = useState('');
  const [sortData, changeSortData] = useState()
  const [imageMode, changeImageMode] = useState(
    !currentCaseData?.image || currentCaseData.image.includes('gen-img') ? 'auto' : 'manual'
  );
  const [winesCategoriesFilter, changeWinesCategoriesFilter] = useState(getCaseActiveWineCategories(winesList));
  const [calculatingData, setCalculatingData] = useState(false);
  const { createDifFieldsObject, createCaseDataRequestObject, convertDataToSelectOptions, getListOfCaseBottleImages, sortListOfObjectByField } = useHelpers()
  const { get, post, requestPending } = useRequests();
  const [availability, changeAvailability] = useState(
    role === 'curator' || !currentCaseData.id ? 'In Stock' : 'Selected'
  );
  const [winesRegions, changeWinesRegions] = useState(currentCaseData?.regions || [])
  // const [wineWarehouse, changewineWarehouse] = useState('IWS');
  // const [pricingToggle, changePricing] = useState(currentCaseData?.prices?.eur && currentCaseData?.prices?.usd ? 'manual' : 'calculated');
  const [pricingToggle, changePricing] = useState('calculated');
  const isGuestCurator = curatorRole === 'guest'
  const isAdmin = role === 'admin'
  const isCurator = role === 'curator';

  const initialState = {
    name: currentCaseData?.name || '',
    labels: currentCaseData?.labels || [],
    category: currentCaseData?.category || 'standard',
    region: currentCaseData?.region ?
      regionsList.find(r => r.label === currentCaseData.region)?.value || 1 : 1,
    regions: currentCaseData?.regions || [],
    active: isCurator
      ? -1 : currentCaseData?.active !== undefined ? currentCaseData?.active : 0,
    price: currentCaseData?.price || 0,
    eur: currentCaseData?.prices?.eur || 0,
    usd: currentCaseData?.prices?.usd || 0,
    calculatedPrice: 0,
    calculatedPrices: { prices: { usd: 0, eur: 0 }, totalWinesCost: 0, totalWinesCostUsd: 0 },
    stock: currentCaseData?.stock || 1,
    customers: currentCaseData?.customers || [],
    description: currentCaseData?.description || '',
    longDescription: currentCaseData?.longDescription || '',
    image: { data_url: currentCaseData?.image },
    wines: winesList || [],
    names: namesList || [{ tabName: 'en', text: '' }, { tabName: 'it', text: '' }],
    descriptions: descriptionsList || [{ tabName: 'en', text: '' }, { tabName: 'it', text: '' }],
    longDescriptions: longDescriptionsList || [{ tabName: 'en', text: '' }, { tabName: 'it', text: '' }],
    notes: currentCaseData?.notes || '',
    warehouse: warehouseData?.name || 'Serendipity Wines',
    curator: isCurator
      ? curatorsList?.find(({ userId }) => userId === currentUserId)
      : curatorsList?.find(({ label }) => curator === label) || curatorsList?.[0],
    caseMarkup: +currentCaseData?.caseMarkup >= 0 ? +currentCaseData?.caseMarkup : 0.2,
    serviceFee: +currentCaseData?.serviceFee >= 0 ? +currentCaseData?.serviceFee : 0.15,
    autoGeneratedImage: {}
  }
  const [formData, changeFormData] = useState({ ...initialState });

  const forUpdate = !!currentCaseData.id;
  const defaultDescription = !forUpdate && formData.category === 'custom';

  const activeStateOptions = ['Draft', 'Inactive', 'Active', 'Highlighted'].map(
    (label, value) => ({ value: value - 1, label })
  );


  const currency = useMemo(() => warehousesDataList.find(whData => whData.name === formData.warehouse)?.currency, [formData.warehouse])
  const selectedWarehouseData = useMemo(() => warehousesList?.find(({ label }) => label === formData.warehouse), [formData.warehouse])

  const winesListStats = formData.wines.reduce(
    (result, wine) => {
      result.total += wine.quantity;
      result.different += wine.quantity > 0 ? 1 : 0;

      return result;
    }, { total: 0, different: 0 }
  );

  const availabilitiesOptions = [
    { value: 'All', label: 'All' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Selected', label: 'Selected' }
  ]

  const changeMultilanguageContent = (name, data, tab) => {
    if (!data) return;

    changeFormData(prev => {
      const newData = prev[name].map(
        item => {
          if (item.tabName !== tab) return item;

          return data.find(({ tabName }) => tabName === tab)
        }
      )

      return {
        ...prev, [name]: newData
      }
    });
  };

  const handleChangeWIneLabels = labels => {
    changeFormData(prev => ({ ...prev, labels }))
  }

  const isCustomWithNoCustomers = formData.category === 'custom' && formData.customers.length === 0


  const createCustomCaseName = async customers => {
    if (!customers || customers?.length === 0) return [];

    const listOfCustomersIds = JSON.stringify(customers)
    const responsePrefs = await get({ url: `/api/wines/preference-report?uid=${listOfCustomersIds}&wid=0` })

    return responsePrefs?.data?.preferences[0]?.wines || []
  }

  const handleCHangeSearchFilter = e => {
    e.preventDefault();

    changeSearch(e.currentTarget.value)
  }

  const handleFieldChange = async e => {
    if (e.target.name.search('price_') >= 0 && isNaN(+e.target.value)) return;

    const field = e.target.name.search('price_') >= 0 ?
      e.target.name.split('_')[1] : e.target.name;

    const value = e.target.type === 'checkbox' ?
      e.target.checked : e.target.name === 'notes' ? e.target.value : +e.target.value;
    changeFormData(prev => ({ ...prev, [field]: value }));
  }

  const handleImageUpload = (image, mode) => {
    changeFormData(
      prev => ({ ...prev, image })
    )

    if (mode && mode !== imageMode) {
      changeImageMode(mode)
      autogeneratedimageRef.current.changeVisibleState(mode === 'auto')
    }
  };

  const handleChangePricing = e => {
    changePricing(e.target.value);

    if (e.target.value === 'calculated' && formData.calculatedPrices.prices.usd === 0) {
      changeCalculatedPrice(formData.wines)
    }
  }

  const handleChangeWineField = async ({ id, field, action, value }) => {
    const newWines = formData.wines.map(
      wine => {
        if (field === 'order') return wine.id === id ?
          { ...wine, order: value } : wine;

        if (wine.id !== id || (
          (wine.stock < value && action === 'inc') ||
          // // (wine.quantity < value && winesListStats.total === 12) ||
          value < 0
        )

        ) return wine;

        if (field === 'quantity') {
          delaedPriceActionRef.current = action;
        }

        return { ...wine, [field]: value }
      }
    );


    changeFormData(
      prev => ({
        ...prev, wines: newWines
      })
    );
  }

  const changeCalculatedPrice = wines => {
    !calculatingData && setCalculatingData(true);

    post({
      url: `/api/wines/cases/price?w=${formData.warehouse}`,
      body: { wines: _formWinesRequestData(wines), caseMarkup: formData.caseMarkup, serviceFee: formData.serviceFee }
    }).then(
      response => {
        if (response?.data?.success) {
          const { prices: { eur } } = response.data || { prices: { eur: null } };

          changeFormData(
            prev => ({
              ...prev,
              calculatedPrice: eur || prev.calculatedPrice,
              calculatedPrices: response.data
            })
          );
        }

        setCalculatingData(false)
      }
    )
  }


  const handleChangeSelectValue = async (field, value) => {
    if (field === 'active')
      return changeFormData(prev => ({ ...prev, [field]: value }));

    const prefWines = field === 'customers' ?
      await createCustomCaseName(value?.map(({ value: id }) => id)) : [];

    changeFormData(
      prev => ({
        ...prev,
        [field]: value,
        wines: prev.wines.map(
          wine => {
            const prefWine = prefWines?.find(({ id }) => id === wine.id) || { isFavourite: false, willRecomend: '' };

            return {
              ...wine,
              quantity: field !== 'region' ? wine.quantity : 0,
              order: field !== 'region' ? wine.order : 0,
              isFavourite: prefWine.isFavourite, willRecomend: prefWine.willRecomend
            }
          }
        ),
        calculatedPrices: field !== 'region'
          ? prev.calculatedPrices
          : {
            ...prev.calculatedPrices,
            prices: { eur: 0, usd: 0 }
          }
      })
    )
  }

  const changeCasePrices = async () => {
    const response = await post({
      url: `/api/wines/cases/price?w=${formData.warehouse}`,
      body: {
        wines: _formWinesRequestData(formData.wines),
        caseMarkup: formData.caseMarkup,
        serviceFee: formData.serviceFee
      }
    });

    if (response?.data?.success) {
      const { prices: { eur } } = response.data || { prices: { eur: null } };

      changeFormData(
        prev => ({
          ...prev,
          calculatedPrice: eur || prev.calculatedPrice,
          calculatedPrices: response.data
        })
      );
    }
  }

  const getFullWineName = wine => `${wine.producer} ${wine.name} ${wine.vintage}`

  const generateCaseImage = async () => {
    if (autogeneratedimageRef.current) {
      const selectedWines = wines.filter(({ quantity, bottleImage }) => quantity > 0 && !!bottleImage)
      const bottleImages = getListOfCaseBottleImages(selectedWines, true)
      autogeneratedimageRef.current.changeImages(bottleImages)

      const image = await autogeneratedimageRef.current.generateImage()
    }
  }

  const handleGenerateCaseImage = async () => {
    const image = await autogeneratedimageRef.current.generateImage()

    changeFormData(
      prev => ({ ...prev, image: undefined })
    )

    autogeneratedimageRef.current.changeVisibleState(true)
    changeImageMode('auto')
  }

  useEffect(() => {
    if (!initialDataLoadingRef.current && +formData.calculatedPrices?.prices?.usd > 0) {
      initialStateRef.current = Object.assign(formData, {})

      initialDataLoadingRef.current = true;
    }
  }, [formData.calculatedPrices])

  useEffect(() => {
    ////////
    generateCaseImage()

    if (['dec', 'inc'].includes(delaedPriceActionRef.current)) {
      setCalculatingData(true);
      clearTimeout(delayedPricingRef.current);

      delayedPricingRef.current = setTimeout(changeCalculatedPrice, 600, formData.wines);
      delaedPriceActionRef.current = null;
    }
    // eslint-disable-next-line
  }, [formData.wines]);

  useEffect(() => {
    changeCasePrices()
    // clearTimeout(delayedPricingRef.current);

    // delayedPricingRef.current = setTimeout(changeCalculatedPrice, 600, formData.wines); 
  }, [formData.caseMarkup, formData.serviceFee]);

  const handleChangeWineWarehouse = optionData => {
    changeFormData(prev => ({
      ...prev,
      warehouse: optionData.label,
      wines: prev.wines.map(wineData => ({
        ...wineData, quantity: 0, order: 0
      })),
      calculatedPrices: {
        ...prev.calculatedPrices,
        prices: { eur: 0, usd: 0 }
      }
    }));
  }

  const toggleCategoriesFilter = category => {
    changeWinesCategoriesFilter(prev => {
      return prev.includes(category)
        ? prev.filter(c => c !== category) : [...prev, category]
    })
  }

  const changes = useMemo(
    () => {
      return createDifFieldsObject(formData, initialStateRef.current, pricingToggle)
    },
    [formData, pricingToggle, initialStateRef.current]
  );
  const props = ['image', 'active', 'notes', 'description', 'descriptions', 'longDescription', 'longDescriptions', 'calculatedPrice', 'calculatedPrices'];

  const wineCategoriesFilter = (
    <AdminCaseWinesCategories
      categories={winesCategoriesFilter}
      onChange={toggleCategoriesFilter}
    />
  )


  const getAction = () => {
    const changesPropsList = changes ? Object.keys(changes) : []
    if (!currentCaseData || !currentCaseData.id) {
      return 'create';
    } else if (changesPropsList?.length > 0) {
      const propsAllowToUpdate = [
        'active', 'description', 'descriptions', 'longDescription', 'longDescriptions', 'notes', 'region', 'regions', 'labels'
      ];
      const filteredChangesForSnapshot = changesPropsList
        .filter(caseProp => !propsAllowToUpdate.includes(caseProp))
        .length;

      if (hasNoSnapshots || withoutCaseOrders || !filteredChangesForSnapshot) {
        return 'update'
      } else {
        return 'snapshot'
      }
    } else {
      return 'disabled'
    }


  }

  const filteredChanges = changes ? Object.keys(changes)?.filter(key => !props.includes(key)) : [];
  const allowToEditCurrentCaseDetails = !currentCaseData || filteredChanges?.length === 0 || withoutCaseOrders;

  const handleSubmitNewCase = async (action, e) => {
    e && e.preventDefault();
    const minBottles = isGuestCurator ? 3 : 1

    if (winesListStats.total < minBottles) {
      return toast.error(`Case should contains not less then 3 bottles of wine.`)
    }

    // if (!formData.regions || !formData.regions.length) {
    //   return toast.error(`Please choose at least 1 region`)
    // }
    const imageIsUploaded = image?.data_url || image?.file
    const isAutoGenerated = !imageIsUploaded || imageMode === 'auto'

    const imageName = imageIsUploaded && imageMode !== 'auto'
      ? `${formData.names?.[0].text?.split(' ').join('-')}`
      : `gen-img-${formData.names?.[0].text?.split(' ').join('-')}`

    const caseImage = imageIsUploaded && imageMode !== 'auto'
      ? image : await autogeneratedimageRef.current.generateImage()

    const formDataToSave = getAction() === 'update' ? changes : formData;
    const dataRegions = caseRegions.map(region => regionsList.find(regionData => regionData?.label === region));

    const data = createCaseDataRequestObject({ ...formDataToSave, regions: dataRegions, image: caseImage }, pricingToggle)
    data.append(`imageName`, imageName)

    if (typeof data === 'string') return toast.error(data);

    if (formDataToSave.calculatedPrices) {
      const dataWines = wines?.filter(
        ({ quantity }) => quantity > 0
      ) || [];

      !data.get('wines') && data.append('wines', JSON.stringify(dataWines))
      !data.get('caseMarkup') && data.append('caseMarkup', formData.caseMarkup)
      !data.get('serviceFee') && data.append('serviceFee', formData.serviceFee)
    }

    data.append('isAutoGenerated', isAutoGenerated)

    const response = action === 'new' ?
      await post({
        url: `/api/wines/cases?w=${formData.warehouse}&p=1${forUpdate ? `&cid=${currentCaseData.id}` : ``}${pricingToggle === 'calculated' ? `&calc=1` : ``}`,
        body: data
      })
      :
      await post({
        url: `/api/wines/cases/${currentCaseData.id}/update?w=${formData.warehouse}&p=1${pricingToggle === 'calculated' ? `&calc=1` : ``}`,
        body: data
      });

    if (response?.data?.success) {
      closeModal();
      toast.success(`Case ${formData.name} was successfuly ${forUpdate ? 'updated' : 'created'}.`);

      const caseId = response?.data?.newCase || currentCaseData.id;

      history.push(`/${role}/cases/${caseId}`);

      !forUpdate && changeFormData({
        name: '', region: 1, active: 0, price: 100, stock: 1,
        description: '', longDescription: '', image: null, wines: winesList || []
      });
    } else {
      const errMessage = response?.data?.message || 'Could not create new Case'

      toast.error(errMessage);
    }
  }

  const handleCaseCategoryChange = e => {
    const { value: category } = e.target;

    changeFormData(prev => ({ ...prev, category }))
  }

  const handleChangeAvailability = value => {
    changeAvailability(value);

    const newCaseDataFormFilters = { ...filtersData, availability: value.trim() };
    localStorage.setItem('_cdffs', JSON.stringify(newCaseDataFormFilters))
  }

  const handleChangeWinesRegions = (regionsOptions) => {
    const winesRegions = regionsOptions?.map(({ label, value }) => ({ label, value })) || []

    changeWinesRegions(winesRegions)
  }

  const winesSortOptions = [
    { value: 'costUsd_desc_number', label: 'Price', icon: 'arrow-down' },
    { value: 'costUsd_asc_number', label: 'Price', icon: 'arrow-up' },
    { value: 'stock_desc_number', label: 'Stock', icon: 'arrow-down' },
    { value: 'stock_asc_number', label: 'Stock', icon: 'arrow-up' },
    { value: 'vintage_desc_number', label: 'Vintage', icon: 'arrow-down' },
    { value: 'vintage_asc_number', label: 'Vintage', icon: 'arrow-up' },
    { value: 'region_desc_string', label: 'Region', icon: 'arrow-down' },
    { value: 'region_asc_string', label: 'Region', icon: 'arrow-up' },
  ]

  const changeSortState = data => changeSortData(data)

  const caseRegions = useMemo(() => {
    return formData.wines?.reduce(
      (acc, { region, quantity }) => {
        if (quantity > 0 && !acc.includes(region)) {
          acc.push(region)
        }

        return acc;
      }, []
    )
  }, [formData.wines])

  const {
    region, active, currentLongDescription, currentDescription, wines, image, names, customers, category, eur, usd, descriptions, longDescriptions, warehouse
  } = formData;

  const handleResetFilters = e => {
    e.preventDefault()

    changeSearch('')
    changeSortData()
    changeWinesCategoriesFilter([])
    changeSortData()
    changeAvailability('All')
    changeWinesRegions([])
  }

  const filteredWinesList = wines.filter(wineData => {
    const regions = winesRegions.map(({ label }) => label)

    if (winesRegions.length > 0 && !regions.includes(wineData.region)) return false;

    if (winesCategoriesFilter.length > 0 && !winesCategoriesFilter.includes(wineData.category)) return false;

    if (
      search?.length > 0
      && !getFullWineName(wineData).toLowerCase().includes(search?.trim().toLowerCase())
    ) return false;
    if (availability === 'Selected') return wineData?.quantity > 0
    if (availability === 'In Stock' && wineData.stock < 1) return false;

    const { region: wineRegion, warehouse: wineWarehouse } = wineData;

    // // if (!formData.regions?.some(({ label }) => label === wineRegion)) return false;
    if (warehouse !== wineWarehouse) return false;

    return true;
  });

  const sortWines = wines => {
    if (!wines) return [];

    if (!sortData) return wines;

    const [field, order, fieldType] = sortData.value.split('_')

    return sortListOfObjectByField(wines, field, { fieldType, order })
  }

  const caseWinesList = sortWines(filteredWinesList).map(wine => (
    <AdminCaseWineCard
      key={wine.id}
      currency={currency}
      wineData={wine}
      showPrice
      isMarkupPrice={isGuestCurator}
      onWineChange={handleChangeWineField}
    />
  ))


  const warehouseUsers = warehousesDataList?.find(({ name }) => name === formData.warehouse)?.users?.split(',');

  const customersFilteredList = warehouseUsers
    ? customersList.filter(({ id }) => warehouseUsers.includes('' + id))
    : customersList;

  const customersOptions = convertDataToSelectOptions(customersFilteredList, ['email', 'role'])

  const handleShowChooseActionDialog = e => {
    e.preventDefault();

    openModal();
  }

  const caseCustomer = customerInfo ? <small>ID:{customerInfo.id} {customerInfo.name}, {customerInfo.email}</small> : undefined

  const descriptionsValue = useMemo(() => defaultDescription
    ? [
      {
        tabName: 'en',
        text: !descriptions[0].text.length ?
          Case.getDefaultMultiLanguageDescription()[0].text : descriptions[0].text
      },
      {
        tabName: 'it',
        text: !descriptions[1].text.length ?
          Case.getDefaultMultiLanguageDescription()[1].text : descriptions[1].text
      }
    ] : descriptions, [formData.warehouse, formData.descriptions])

  const descriptionsFilteredValue = useMemo(() => formData.warehouse?.toLowerCase()?.includes('serendipity')
    ? descriptionsValue?.filter(({ tabName }) => tabName === 'en') : descriptionsValue, [descriptionsValue, formData.warehouse])

  const longDescValue = useMemo(() => defaultDescription
    ? [
      {
        tabName: 'en',
        text: !longDescriptions[0].text.length ?
          Case.getDefaultMultiLanguageDescription()[0].text : longDescriptions[0].text
      },
      {
        tabName: 'it',
        text: !longDescriptions[1].text.length ?
          Case.getDefaultMultiLanguageDescription()[1].text : longDescriptions[1].text
      }
    ] : longDescriptions, [formData.warehouse, formData.longDescriptions])

  const longDescFilteredValue = useMemo(() => formData.warehouse?.toLowerCase()?.includes('serendipity')
    ? longDescValue?.filter(({ tabName }) => tabName === 'en') : longDescValue, [formData.warehouse, longDescValue])

  const namesFilteredValue = useMemo(() => {

    return formData.warehouse?.toLowerCase()?.includes('serendipity')
      ? formData.names?.filter(({ tabName }) => tabName === 'en') : formData.names
  }, [formData.warehouse, formData.names])


  const imageContent = useMemo(() => (
    <HtmlWithGenerateImageButton visible={imageMode === 'auto'} ref={autogeneratedimageRef} />
  ), [formData.image, imageMode])


  return (
    <>
      <form className="manage-case-form" onSubmit="return false;">
        <div className="case-main-data-wrapper">
          <div className="case-form-img">
            <ImageUploading
              multiple={false}
              onChange={handleImageUpload}
            >{({
              imageList,
              onImageUpload,
              onImageUpdate,
            }) => <ImageUploadFormControlWithAutoGeneratingCaseImage
                imageList={[{ file: null, data_url: image?.data_url || '' }]}
                onImageUpdate={onImageUpdate}
                onImageUpload={onImageUpload}
                onImageLoaded={handleImageUpload}
                onUseGeneratedImage={handleGenerateCaseImage}
                buttonLabel="Upload"
                // title={imageMode === 'auto' ? 'Generated Image' : 'Uploaded case Image'}
                autogeneratedContent={imageContent}
                showGeneratedContent
                hideUploadButton={isGuestCurator}
                showGeneratedImage={imageMode === 'auto'}
              />}
            </ImageUploading>

            {
              winesListStats.total < 1 &&
              <p className="case-no-photo-message"><b>Add bottles to generate an image</b></p>
            }
          </div>

          <div className="case-main-data">
            <TabbedInput
              onChange={changeMultilanguageContent}
              style={{ width: '100%', flexGrow: 1, maxWidth: '100%' }}
              label="Case Name"
              name="names"
              parentControl={true}
              tabs={namesFilteredValue}
            />

            <div className="form-control">
              <label htmlFor="notes">
                Case Notes (internal, not shown to customer)
              </label>

              <textarea
                name="notes" id="notes" rows="3"
                value={formData.notes}
                onChange={handleFieldChange}
              />
            </div>

            {
              !isGuestCurator && <>{
                (isAdmin && formData.category === 'custom') || !forUpdate
                  ? (
                    <div className="form control">
                      <div className="flex-space-between" style={{ alignItems: 'flex-end', justifyContent: 'flex-start' }}>
                        <div
                          className="form-control horizontal"
                          style={{
                            flexDirection: 'row', justifyContent: 'space-between',
                            flexGrow: 0, width: '50%', flexWrap: 'wrap', gap: '8px'
                          }}
                        >
                          <label htmlFor="active" style={{ width: '100%', display: 'inline-block' }}>Case Type</label>

                          <label className="radio-button no-margin">
                            Standard
                            <input
                              name="category" type="radio" value="standard"
                              checked={category === 'standard'}
                              onChange={handleCaseCategoryChange}
                            />
                            <span className="checkcircle"></span>
                          </label>

                          <label className="radio-button no-margin">
                            Custom
                            <input
                              name="category" type="radio" value="custom"
                              checked={category === 'custom'}
                              onChange={handleCaseCategoryChange}
                            />
                            <span className="checkcircle"></span>
                          </label>
                        </div>

                        <div className="form-control" style={{ width: '50%' }}>

                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-control">
                      <div className="flex-space-between">
                        <b>Category:</b>

                        <span>
                          {formData.category}
                          {/* {
                      formData.category === 'custom' &&
                      <>({caseCustomer})</>
                    } */}
                        </span>
                      </div>
                    </div>
                  )
              }</>
            }

            {
              category === 'custom' &&
              <Select
                style={{ width: 'calc(100% - 32px)', margin: '0 16px' }}
                name="customers"
                disabled={category === 'standard' || (forUpdate && !isAdmin)}
                isSearchable={true}
                isMulti={true}
                placeholder="Select customer"
                options={customersOptions}
                onChange={
                  data => handleChangeSelectValue('customers', data)
                }
                value={
                  category === 'standard' ?
                    // { value: 0, label: 'No Customer' } : customersOptions.find(({ value }) => value === customers[0])
                    null : customers
                }
              />
            }
          </div>
        </div>

        <br />

        <div className="form-section manage-case__section">
          {
            isAdmin &&
            <div className="form-control form-section__col">
              <label htmlFor="curator">Curator</label>

              <Select
                name="curator"
                isSearchable={true}
                isDisabled={isCurator}
                placeholder="Select Curator" options={curatorsList}
                onChange={data => handleChangeSelectValue('curator', data)}
                value={formData.curator}
              />
            </div>
          }

          {
            isAdmin &&
            <div className="form-control form-section__col flex-grow">
              <label htmlFor="warehouse">Warehouse: </label>

              <Select
                name="warehouse"
                isSearchable={true}
                menuStyle={{ zIndex: 20 }}
                isDisabled={isCurator}
                placeholder="Select warehouse"
                options={warehousesList}
                onChange={handleChangeWineWarehouse}
                value={{
                  value: warehousesList.find(({ label }) => label === formData.warehouse)?.value || 0,
                  label: formData.warehouse
                }}
              />
            </div>
          }

          {
            isAdmin &&
            (
              <div className="form-section__col form-control col--status">
                <label htmlFor="active">Status</label>
                <Select
                  name="active"
                  isSearchable={true}
                  isDisabled={isCurator}
                  placeholder="Select State" options={activeStateOptions}
                  onChange={({ value }) => handleChangeSelectValue('active', value)}
                  value={{ value: active, label: activeStateOptions.find(state => state.value === active).label }}
                />
              </div>
            )
          }
        </div>




        <div className={classNames('case-pricing', { 'compact no-margin': isCurator })}>
          <h3 className="form-section-title no-margin" style={{ marginBottom: 0 }}>Case Pricing</h3>

          <div className="case-pricing__details">
          {
              !isGuestCurator &&
              <div className="pricing-fee">
                <div className="form-control pricing-control">
                  <label htmlFor="caseMarkup">Case Markup</label>

                  <input
                    name="caseMarkup"
                    type="number"
                    min="0"
                    step={0.01}
                    value={formData.caseMarkup}
                    onChange={handleFieldChange}
                  />
                </div>

                <div className="form-control pricing-control">
                  <label htmlFor="serviceFee">Service Fee</label>

                  <input
                    name="serviceFee"
                    type="number"
                    min="0"
                    step={0.01}
                    value={formData.serviceFee}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
            }
            {
              !isCurator &&
              <div className="case-pricing__details-controls">
                <div
                  className="form-control horizontal gap_sm flex-wrap w-auto" style={{ alignItems: 'flex-start' }}
                >
                  <label className="radio-button no-margin">
                    <p className="radio-flex no-margin">Manually Set <small>(admin testing only)</small></p>
                    <input
                      name="pricing_method" type="radio" value="manual"
                      checked={pricingToggle === 'manual'}
                      onChange={handleChangePricing}
                    />
                    <span className="checkcircle"></span>
                  </label>

                  <label className="radio-button no-margin">
                    Calculated
                    <input
                      name="pricing_method" type="radio" value="calculated"
                      checked={pricingToggle === 'calculated'}
                      onChange={handleChangePricing}
                    />
                    <span className="checkcircle"></span>
                  </label>
                </div>


                <>{
                  pricingToggle === 'manual' ?
                    <div className="flex-space-between" style={{ justifyContent: 'flex-start' }}>
                      {
                        !formData.warehouse?.includes('Serendipity') &&
                        <div className="form-control horizontal no-margin" style={{ width: '160px' }}>
                          <label htmlFor="price">EUR</label>

                          <input name="price_eur"
                            style={{ width: '100px', padding: '0 16px', textAlign: 'center' }}
                            value={eur}
                            onChange={handleFieldChange}
                          />
                        </div>
                      }

                      <div className="form-control horizontal no-margin" style={{ width: '160px' }}>
                        <label htmlFor="price">USD</label>

                        <input name="price_usd"
                          style={{ width: '100px', padding: '0 16px', textAlign: 'center' }}
                          value={usd}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>
                    :
                    <PriceWithPricingDetails
                      isLoading={calculatingData}
                      caseName={formData.names.find(({ tabName }) => tabName === 'en').text}
                      wines={wines.filter(({ quantity }) => quantity > 0)}
                      active={pricingToggle === 'calculated'}
                      withDetails
                      warehouseDeliveryFee={selectedWarehouseData?.deliveryFee || 0}
                      casePriceDetails={{
                        warehouse: formData.warehouse,
                        winesPrice: formData.calculatedPrice,
                        prices: formData.calculatedPrices,
                        priceFee: {
                          ...formData.calculatedPrices?.fee,
                          ...pricingDetails,
                          prices: formData.calculatedPrices.prices,
                          totalWinesCost: formData.calculatedPrices.totalWinesCost,
                          totalWinesCostUsd: formData.calculatedPrices.totalWinesCostUsd,
                          warehouse: formData.warehouse,
                          winesPrice: formData.calculatedPrice,
                          // caseMarkup: formData.caseMarkup,
                          // serviceFee: formData.serviceFee,
                        },
                        ...formData.calculatedPrices,
                        totalPrices: {
                          eur: formData.calculatedPrices?.eur,
                          usd: formData.calculatedPrices?.usd
                        },
                        ...pricingDetails,
                        caseMarkup: formData.caseMarkup,
                        serviceFee: formData.serviceFee,
                      }}
                    />
                }</>

              </div>
            }
            {
              // winesListStats.total < 3
              isCurator && <>{
                winesListStats.total < 3
                  ? (<>{
                    isGuestCurator &&
                    <p className="calculated-pricing">Please select 3 or more wines to see pricing.</p>}
                  </>) : (
                    <PriceWithPricingDetails
                      isLoading={calculatingData}
                      caseName={formData.names.find(({ tabName }) => tabName === 'en').text}
                      wines={wines.filter(({ quantity }) => quantity > 0)}
                      active={pricingToggle === 'calculated'}
                      withDetails={!isGuestCurator}
                      warehouseDeliveryFee={selectedWarehouseData?.deliveryFee || 0}
                      casePriceDetails={{
                        warehouse: formData.warehouse,
                        winesPrice: formData.calculatedPrice,
                        prices: formData.calculatedPrices,
                        priceFee: {
                          ...formData.calculatedPrices?.fee,
                          ...pricingDetails,
                          prices: formData.calculatedPrices.prices,
                          totalWinesCost: formData.calculatedPrices.totalWinesCost,
                          totalWinesCostUsd: formData.calculatedPrices.totalWinesCostUsd,
                          warehouse: formData.warehouse,
                          winesPrice: formData.calculatedPrice,
                          // caseMarkup: formData.caseMarkup,
                          // serviceFee: formData.serviceFee,
                        },
                        ...formData.calculatedPrices,
                        totalPrices: {
                          eur: formData.calculatedPrices?.eur,
                          usd: formData.calculatedPrices?.usd
                        },
                        ...pricingDetails,
                        caseMarkup: formData.caseMarkup,
                        serviceFee: formData.serviceFee,
                      }}
                    />
                  )
              }</>
            }
          </div>
        </div>

        {
          !isGuestCurator &&
          <div className="form-control">
            <LabelsControlPanel
              labels={formData.labels}
              allLabelsList={labelsList}
              onChangeLabels={handleChangeWIneLabels}
            />
          </div>
        }

        <div className="flex-space-between">
          <h3 className="form-section-title" style={{ marginBottom: 0 }}>Case Wines</h3>

          <div style={{ paddingRight: '32px', alignSelf: 'flex-end', fontSize: '18px' }}>
            wines:&nbsp;<b>{winesListStats.different}</b>&nbsp;/&nbsp;total:&nbsp;<b>{winesListStats.total}</b>
          </div>
        </div>

        <CaseRegionsTags regions={caseRegions} />

        <div className="case-data__wines-filters">
          <div className="flex-space-between" style={{ width: '100%', gap: '16px' }}>
            <div className="form-control horizontal no-padding" style={{ width: '100%', flexShrink: 1 }}>
              <label htmlFor="search">Search: </label>

              <input
                style={{ width: '100%', backgroundColor: '#fcfcfa' }}
                type="text" name="search"
                onChange={handleCHangeSearchFilter}
                value={search}
              />
            </div>

            <div className="form-control horizontal no-padding" style={{ width: 'auto', flexShrink: 0 }}>
              <label htmlFor="availability">Show: </label>

              <Select
                name="availability"
                menuStyle={{ zIndex: 20 }}
                style={{ marginLeft: '16px', width: '165px' }}
                placeholder="Select availability"
                options={availabilitiesOptions}
                onChange={({ value }) => handleChangeAvailability(value)}
                value={{ value: availability, label: availability }}
              />
            </div>
          </div>

          <div className="form-control horizontal w-100 no-padding">
            <label htmlFor="region">Region</label>

            <Select
              name="region"
              wrapperClass="w-100"
              menuStyle={{ zIndex: 20 }}
              style={{ flexGrow: 1, width: '100%' }}
              isMulti={true}
              isSearchable={true}
              warehouse={formData.warehouse?.split(' ')?.[0]?.toLowerCase()}
              placeholder="Select Regions" options={regionsList}
              onChange={handleChangeWinesRegions}
              value={winesRegions}
            />
          </div>

          <div className="flex-space-between gap w-100">
            {wineCategoriesFilter}

            <Select
              name="sortData"
              wrapperClass="wine-sort-dropdown"
              options={winesSortOptions}
              placeholder="Sort by..."
              onChange={changeSortState}
              value={sortData}
            />
          </div>
        </div>

        {/* There are no bottles in this case yet. */}
        <div className="case-form-wines">
          {
            caseWinesList.length > 0
              ? caseWinesList
              : <Alert
                className="alert-info w-100 no-margin"
              ><p>{
                availability === 'Selected' && winesListStats.total < 1
                  ? <>There are no bottles in this case yet.</>
                  : <>There are no selected bottles with the active filters (region, search keywords, wine type).&nbsp;
                    <button className="button-link no-padding"
                      onClick={handleResetFilters}
                    ><u>Click here to reset all filters.</u></button></>
              }</p></Alert>
          }
        </div>

        <TabbedTextarea
          label="Case Description (Max 125 characters)"
          name="descriptions"
          parentControl={true}
          limit={125}
          maxLimitMessage="Your case description is more than 125 characters long."
          tabs={descriptionsFilteredValue}
          activeTab={currentDescription}
          onChange={changeMultilanguageContent}
        />

        <TabbedTextarea
          label="Case Long Description"
          name="longDescriptions"
          activeTab={currentLongDescription}
          parentControl={true}
          tabs={longDescFilteredValue}
          onChange={changeMultilanguageContent}
        >
          <GenerateDescriptionDialog
            onSubmit={changeMultilanguageContent}
            wines={formData.wines?.filter(wineData => wineData?.quantity > 0)}
          />
        </TabbedTextarea>

        <div className="form-control flex-center gap_sm">
          <div className="flex-space-between">
            {
              // (!currentCaseData.id || allowToEditCurrentCaseDetails) &&
              ['update', 'create', 'disabled'].includes(getAction()) &&
              <button
                className="button submit-button"
                disabled={
                  requestPending
                  || names?.[0].text.length < 1
                  || getAction() === 'disabled'
                  || isCustomWithNoCustomers
                }
                onClick={
                  e => getAction() === 'update' ? handleSubmitNewCase('edit', e) : handleSubmitNewCase('new', e)
                }
              >{
                  getAction() === 'update'
                    ? 'Update Case Details' : getAction() === 'create' ? 'Create New Case' : 'No changes'
                }</button>
            }

            {
              // forUpdate && !allowToEditCurrentCaseDetails &&
              getAction() === 'snapshot' &&
              <button
                className="button button_info submit-button"
                style={{ marginLeft: '32px' }}
                disabled={
                  requestPending || names[0].text.length < 1 || isCustomWithNoCustomers
                }
                onClick={handleShowChooseActionDialog}
              >Create New Snapshot</button>
            }
          </div>

          {
            formData.names?.[0]?.text?.length < 1 &&
            <p className="no-margin text-error">Case name should not be empty</p>
          }

          {
            isCustomWithNoCustomers &&
            <p className="no-margin text-error">Please choose customers</p>
          }
        </div>
      </form>

      <Modal modalClassName="modal-dialog" isOpen={isModalOpen}>
        <div style={{ maxWidth: '380px', fontSize: '20px', fontFamily: 'Avenir Next' }}>
          <h5>Because this case has orders, some of the edits require creating a new snapshot. Click Confirm to continue...</h5>
          <button className="close-modal-btn" onClick={() => closeModal()}>
            <img src={closeIcon} alt="close modal" />
          </button>

          <div className="flex-space-between" style={{ marginBottom: '16px' }}>
            <button className="button button_outline button_xs flex-grow"
              onClick={() => closeModal()}
            >Cancel</button>

            <button className="button button_xs flex-grow"
              style={{ marginLeft: '16px' }}
              onClick={e => handleSubmitNewCase('new', e)}
            >Confirm</button>
          </div>
        </div>
      </Modal>
    </>
  )
}


CaseDataForm.propTypes = {
  winesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    image: PropTypes.string,
    withoutCaseOrders: PropTypes.bool
  })),
  regionsList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    withoutCaseOrders: false
  }))
}

export default memo(CaseDataForm);