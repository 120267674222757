import { createSlice } from '@reduxjs/toolkit'
import * as orderAsyncActions from './order.actions'


const initialState = {
  error: undefined,
  processingRequest: false,
  processingRequests: 0,
  list: [],
  currentOrderData: {},
  dataIsFetched: false
}



const orderSlice = createSlice({
  name: '@@orders',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(orderAsyncActions.requestOrdersList.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(orderAsyncActions.requestUserOrders.fulfilled, (state, { payload }) => {
        state.list = payload
        state.dataIsFetched = true
      })
      .addCase(orderAsyncActions.requestUserOrders.pending, state => {
        state.dataIsFetched = false
      })
      .addMatcher(
        (action) => action.type.startsWith('@@orders') && action.type.endsWith('/rejected'),
        (state, action) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
          state.error = action.payload || action.error.message;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@orders') && action.type.endsWith('/pending'),
          (state) => {
          state.processingRequest = true;
          state.processingRequests += 1;
        })
      .addMatcher(
        (action) => action.type.startsWith('@@orders') && action.type.endsWith('/fulfilled'),
          (state) => {
          state.processingRequest = false;
          state.processingRequests -= 1;
        })
})


export const ordersActions = { ...orderSlice.actions, ...orderAsyncActions }

export default orderSlice.reducer;
