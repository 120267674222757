import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-simple-hook-modal';
import { v4 as keyGen } from 'uuid';
import { useHelpers } from '../../hooks';
import { ReportCard, NewVerificationForm } from '..';


const AdminCustomerReferrersCard = ({ customer, expand, onChange }) => {
  const [pendingRequests, changePendingRequests] = useState(new Set());
  const [modalContent, changeModalContent] = useState();
  const { formatDateTime } = useHelpers();

  const handleSendCode = async (e, data) => {
    e.preventDefault();

    changePendingRequests(prev => new Set([...prev, data.email]));

    // const response = await get({ url: `/api/users/send-verification/${verId}` });

    // if (response?.data?.success) {

    // }

    // changePendingRequests(prev => {
    //   const newData = prev.delete(verId);

    //   return newData;
    // })
    changeModalContent(data);
  }

  const handleCloseModal = () => {
    modalContent?.email && 
      changePendingRequests(prev => {
        prev.delete(modalContent.email);

        return prev;
      })

    changeModalContent(null)
  }

  const handleSubmitNewVerification = data => {
    const newReferrersList = customer.referrers?.map(referrerData => {
      if (referrerData?.email === data?.email) {
        referrerData?.verifications?.unshift(data)
      }

      return referrerData;
    })

    onChange(customer.id, newReferrersList);
  }

  const { id, name, referrers } = customer;
  const info = (
    <div className="flex-space-between">Referrers: {referrers.length}</div>
  )

  const referrersList = referrers.map(
    ({ accountCreated, accountCreatedDate, firstName, lastName, name, date, email, verifications }) => {
      const verification = verifications ?
        verifications[0] : undefined;

      const referrerData = { firstName, lastName, email }

      return (
        <div key={keyGen()} className="referrer">
          <div className="name">{name}</div>
          <div className="email">{email}</div>
          <div className="date">{formatDateTime(date, false)}</div>
          <div className="date text-center" >{
            accountCreated ?
            <span className="text-blue">Account created {formatDateTime(accountCreatedDate, false)}</span>
            :
            verification?.isUsed ?
              `Verified ${formatDateTime(verification.sentAt, false)}`
              :
              verification?.isSent ?
                <button
                  className="button button_xs no-margin"
                  disabled={pendingRequests.has(email)}
                  // onClick={e => handleSendCode(e, verification?.id)}
                  onClick={e => handleSendCode(e, referrerData)}
                >Resend Code</button>
                :
                <button
                  className="button button_xs no-margin"
                  disabled={pendingRequests.has(email)}
                  // onClick={e => handleSendCode(e, verification?.id)}
                  onClick={e => handleSendCode(e, referrerData)}
                >Send Code</button>
          }</div>
        </div>
      )
    }
  );


  return (
    <>
      <ReportCard id={id} name={name} info={info}>
        <div className="referrers-list">{referrersList}</div>
      </ReportCard>

      <Modal 
        isOpen={ !!modalContent }
      ><NewVerificationForm
        data={ modalContent }
        generateCode={ true }
        onCancel={ handleCloseModal } 
        onFormSubmited={handleSubmitNewVerification}
      /></Modal>
    </>
  )
}

AdminCustomerReferrersCard.propTypes = {
  expand: PropTypes.bool,
  customer: PropTypes.object
}

AdminCustomerReferrersCard.defaultProps = {
  expand: false
}

export default memo(AdminCustomerReferrersCard)
