import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowContainer, Popover } from 'react-tiny-popover'
import { v4 as keyGen } from 'uuid';

import './style.scss';
import classNames from 'classnames';


const WinesPreviewsGallery = ({ wines }) => {
  const content = [];
  const numOfBottles = wines?.reduce(
    (acc, { quantity }) => {
      acc += +quantity
    }, 0
  ) || 0

  for (const wine of wines) {

    for (let i = 0; i < wine?.quantity; i++) {
      const wineKey = keyGen();
      const galeryItem = <WineGalleryItem key={wineKey} wineKey={wineKey} wine={wine} />;

      content.push(galeryItem);
    }
  }

  return (
    <div className={classNames('hero__wines-gallery', {
      'six-pack-gallery': numOfBottles <= 6
    })}>{content}</div>
  )
}


const WineGalleryItem = ({ wine, wineKey }) => {
  // const { category, thumbnail, quantity } = wine;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { category, image, thumbnail } = wine; // remove and uncomment previos line when later

  const winesContent = [];

  const html = (
    <div
      key={wineKey}
      className="wines-gallery__item"
      onMouseOver={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
    >
      {
        window.matchMedia("(min-width: 768px)").matches
          ? (
            <Popover
              isOpen={isPopoverOpen}
              padding={-30}
              positions={['right']}
              content={
                ({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    nudgedLeft={100}
                    arrowColor={'white'}
                    arrowSize={20}
                    arrowClassName="popover-arrow"
                  ><div className="popover-container">
                      <div className="image-container">
                        <img className="popover-image" src={thumbnail || image} alt="" />
                      </div>
                    </div>
                  </ArrowContainer>
                )
              }
            ><img src={thumbnail || image} alt="" /></Popover>
          ) : (
            <img className="popover-image" src={thumbnail || image} alt="" />
          )
      }
      {/* <img src={thumbnail} alt="" /> */}

      <span className={`wines-gallery__badge ${category}`}></span>
    </div>
  )

  winesContent.push(html);

  return winesContent;
}


WinesPreviewsGallery.propTypes = {
  wines: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    thumbnail: PropTypes.string
  }))
}

WineGalleryItem.propTypes = {
  wines: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    thumbnail: PropTypes.string
  }))
}

export default WinesPreviewsGallery
