import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NewItemBadge as Badge } from '..';
import { CountryContext } from '../../context/country';

import './style.scss';
import { useDispatch } from 'react-redux';
import { useActions } from '../../hooks';


const RegionPreviewCard = ({ name, image, newRegion }) => {
  const dispatch = useDispatch()
  const { regionActions } = useActions()
  const { regions } = useContext(CountryContext); 
  const { t, i18n } = useTranslation();
  const history = useHistory(); 

  const handleViewRegion = () => {
    // changeRegion(name);
    dispatch(regionActions.changeCurrentRegion(name))

    history.push(`/regions/${name}`)
  }

  const regionName = regions[name][i18n.language];

  return (
    <div className="card region-preview">
      <div className="card__body">
        <div className="region-preview__image">
          <img src={ image } alt={ regionName || '' } />
        </div>
        <div className="region-preview__content">
          <p className="text-gold">{ t('REGIONS_PREVIEW_CARD_SUBTITLE') }</p>
          <h3 className="text-gray">{ regionName || '' }</h3>
          <button
            className="button button_outline"
            onClick={ handleViewRegion }
          >{ t('REGIONS_PREVIEW_CARD_BUTTON') }</button>
        </div> 
      </div>
      {
        newRegion &&
        <Badge text="NEW REGION" />
      }
    </div>
  )
}

RegionPreviewCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  newRegion: PropTypes.bool
}

RegionPreviewCard.defaultProps = {
  image: "https://vineify-dev-files.s3.amazonaws.com/tuscany-preview-310.jpg",
  name: 'Tuscany',
  newRegion: false
}

export default RegionPreviewCard;
