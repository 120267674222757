import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './style.scss';


const WinesTypesSummary = ({ red, white, rose, sparkling, style, wrapperClassName, withZeroValues }) => {
  const { t } = useTranslation();
  const wrapperClassList = ['wines-types-list']
  if (wrapperClassName) wrapperClassList.push(wrapperClassName)
  const wrapperClass = wrapperClassList.join(' ')

  const showReds = withZeroValues || +red > 0;
  const showWhites = withZeroValues || +white > 0;
  const showRoses = withZeroValues || +rose > 0;
  const showSparklings = withZeroValues || +sparkling > 0;

  return (
    <ul className={wrapperClass} style={style}>
      {/* {showReds && <li>{red} {t('REDS')}</li>}
      {showWhites && <li>{white} {t('WHITES')}</li>}
      {showRoses && <li>{rose} {t('ROSES')}</li>}
      {showSparklings && <li>{sparkling} {t('SPARKLING')}</li>} */}
      {showReds && <li>{red} {red > 1 ? `reds` : `red`}</li>}
      {showWhites && <li>{white} {white > 1 ? `whites` : `white`}</li>}
      {showRoses && <li>{rose} { rose > 1 ? `rosés` : `rosé`}</li>}
      {showSparklings && <li>{sparkling} sparkling</li>}
    </ul>
  )
}


WinesTypesSummary.propTypes = {
  red: PropTypes.number,
  rose: PropTypes.number,
  white: PropTypes.number,
  sparkling: PropTypes.number,
  color: PropTypes.string,
  withZeroValues: PropTypes.bool
}

WinesTypesSummary.defaultProps = {
  red: 0,
  rose: 0,
  white: 0,
  sparkling: 0, 
  withZeroValues: false
}

export default WinesTypesSummary;
