import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHelpers, useStoreCheckout } from '../../hooks';

import './style.scss';


const OrderContactSummary = ({ title, fullName, firstName, lastName, address, city, state, zip, country, phone, email, appartment, editStep, onEdit, editable, containerClassName, showAdditionalFields }) => {
  const { t } = useTranslation();
  const { formatPhoneValue } = useHelpers();
  const { checkoutActions: { changePaymentStep } } = useStoreCheckout()

  const handleEditContactData = e => {
    e.preventDefault();

    if (onEdit) return onEdit();

    changePaymentStep(editStep)
  }

  const classNames = ['order-contact-summary']
  if (containerClassName) classNames.push(containerClassName)

  return (
    <div className={ classNames.join(' ') } data-cy="contact-summary">
      <h3 className="contact-title">{title}</h3>

      {
        firstName?.length && lastName?.length ?
          <p className="contact-name">{fullName || `${firstName} ${lastName}`}</p>
          :
          <p className="contact-name">{t('NAME')} {t('NOT_SPECIFIED')}</p>
      }

      {
        address ?
          <p className="contact-address">{address}</p>
          :
          <p className="contact-address">{t('ADDRESS')} {t('NOT_SPECIFIED')}</p>
      }

      {
        state && zip && city ?
          <p className="contact-state">{`${city}, ${state} ${zip}`}</p>
          :
          <p className="contact-state">{t('STATE')} {t('NOT_SPECIFIED')}</p>
      }

      {
        appartment &&
        <p className="contact-country">{appartment}</p>
      }

      {
        phone && showAdditionalFields.includes('phone') &&
        <p className="contact-country">{formatPhoneValue(phone)}</p>
      }

      {
        email && showAdditionalFields.includes('email') &&
        <p className="contact-country">{email}</p>
      }

      {
        editable &&
        <button
          className="button-link"
          onClick={handleEditContactData}
        ><b>Edit</b></button>
      }


    </div>
  )
}

OrderContactSummary.propTypes = {
  title: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  appartment: PropTypes.string,
  editStep: PropTypes.string,
  editable: PropTypes.bool,
  containerClassName: PropTypes.string,
  showAdditionalFields: PropTypes.arrayOf(PropTypes.oneOf(['phone', 'email']))
}

OrderContactSummary.defaultProps = {
  editable: true,
  showAdditionalFields: []
}

export default OrderContactSummary