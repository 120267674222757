import React, { useContext } from 'react';  
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { useActions, useHelpers } from '../../hooks';
import { CountryContext } from '../../context/country';
import { Icon } from '..';


import './style.scss';
import { useDispatch } from 'react-redux';


const RegionsMenu = ({ regionsList }) => {
  const dispatch = useDispatch()
  const { regionActions } = useActions();
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { regions, parentRegions } = useContext(CountryContext);
  const { capitalize } = useHelpers();


  const handleSelectRegion = ({ value }) => {
    // changeRegion(value);
    dispatch(regionActions.changeCurrentRegion(value))

    history.push('/regions/' + value);
  }

  const disabled = !Object.keys(regions).length;

  const regionsOptions = Object.keys(regions).length ? 
    parentRegions.map(region => ({ value: region, label: regions[region][i18n.language || 'en'] }))
    : [];
    

  return (
    <Dropdown 
      className="regions-dropdown"
      menuClassName="regions-dropdown__menu"
      controlClassName="regions-dropdown__control"
      arrowClassName="regions-dropdown__arrow"
      arrowClosed={
        // <img src={chevronDownIcon} alt="menu closed" className="regions-dropdown__arrow" />
        <Icon icon="chevron-down" className="regions-dropdown__arrow" color="white" />
      }
      arrowOpen={
        // <img src={chevronUpIcon} className="regions-dropdown__arrow" alt="menu opened" />
        <Icon icon="chevron-up" className="regions-dropdown__arrow" color="white" />
      }
      options={regionsOptions}
      placeholder={capitalize(t('REGIONS'))}
      value={{ label: capitalize(t('REGIONS')), value: capitalize(t('REGIONS')) }}
      onChange={handleSelectRegion}
      disabled={ disabled }
    />
  )
}


RegionsMenu.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }))
}


export default RegionsMenu;
