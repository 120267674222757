import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NewItemBadge, ShortDescription } from '..';
import { useHistory } from 'react-router';
import { useActions, useHelpers } from '../../hooks';

import icon from '../../assets/icons/case-box.svg';

import tuscanyImg from '../../assets/images/tuscany-card-header.jpg';

import './style.scss';
import { useDispatch } from 'react-redux';


const NewRegionCard = ({ name, image, newRegion, country, numCases, description, regionKey, mapImg }) => {
  const dispatch = useDispatch();
  const { regionActions } = useActions()
  const { t } = useTranslation();
  const { capitalize } = useHelpers();
  const history = useHistory();
  const headerImg = name === 'Tuscany' ? tuscanyImg : image;
  // screenWidth > 520 ? screenWidth > 768 ? 5 : 4 : 3
  const lines = window.matchMedia('(max-width: 520px)').matches 
    ? 3 : window.matchMedia('(max-width: 768px)').matches ? 4 : 5

  const handleExploreRegion = e => {
    e.stopPropagation();
    // changeRegion(regionKey);
    dispatch(regionActions.changeCurrentRegion(regionKey))

    history.push(`/regions/${regionKey}`);
  }

  return (
    <div className="card region-card" onClick={handleExploreRegion}>
      <div className="region-card__body">
        <div className="region-card__image flex-center">
          <img src={headerImg} alt={name} />
        </div>

        <div className="region-card__content">
          <div className="region-card__title">
            <h3 className="no-margin">
              {name}
              
              {
                newRegion &&
                <span className="region-card__label"><NewItemBadge color="gold" text={capitalize(t('NEW')) + '!'} /></span>
              }
            </h3>

            <i className="country">{name !== country ? country : ''}</i>
          </div>

          <div className="region-card__description">
            <ShortDescription expandable={false} lines={lines}>
              <div className="float-img" >
                <img
                  src={mapImg} alt={`${regionKey} region`}
                  style={{ maxHeight: '100%' }}
                />
              </div>

              <p className="no-margin" style={{ display: 'inline' }}>
                {description}
              </p>
            </ShortDescription>
          </div>
        </div>
      </div>

      <div className="region-card__footer flex-space-between">
        <div className="region-card__cases-stat">
          <img src={icon} alt="number of cases" />{' '}
          {/* {numCases + ' ' + t('REGION_PREVIEW_CARD_CASES')} */}
          {`${ numCases } ${ numCases > 1 ? 'Collections in Vineify' : 'Collection in Vineify' }`}
        </div>

        <button
          className="button"
          onClick={handleExploreRegion}
        >{t('REGION_PREVIEW_CARD_BTN')}</button>
      </div>
    </div>
  )
}


NewRegionCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  newRegion: PropTypes.bool,
  country: PropTypes.string,
  numCases: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string
}

export default NewRegionCard;
