import React, { useRef, useState } from 'react' 
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useActions, useHelpers, useRequests } from '../../hooks'
import { Link, useLocation } from 'react-router-dom'
import PasswordInput from '../controls/PasswordInput'
import { useDispatch, useSelector } from 'react-redux'


const LoginForm = ({ onSignedIn, initialData, onSwitchForm }) => {
  const dispatch = useDispatch()
  const { processingRequests } = useSelector(state => state.auth)
  const { authActions } = useActions()
  const location = useLocation();
  const { t } = useTranslation();
  const { capitalize } = useHelpers();
  const [email, setEmail] = useState(initialData?.email || '');
  const [password, setPassword] = useState('');
  const [keepSigned, setKeepSigned] = useState(true);

  const { errors, clearErrors, requestPending } = useRequests();

  let errorData = errors ? errors?.errors || {} : {};

  const handleChange = (e, setValue) => {
    const value = e.target.type === 'checkbox'
      ? e.target.checked
      : e.target.value

    clearErrors();
    setValue(value);
  }
  

  const authentificate = async (e) => { 
    e.preventDefault();
    if (requestPending || processingRequests > 0) return;

    const redirectPath = location.pathname.includes('/cart') ? '/cart/payment' : undefined
    dispatch(authActions.login({ email, password, keepSigned, redirectPath }))

    // if (result && result.data.success) {
    //   onSignedIn && onSignedIn();
    // }
  }

  const handleChangePassword = value => setPassword(value)


  return (
    <form className="small-form">
      <div className={classNames('form-control', { 'invalid': errorData?.email || errorData?.invalid })}>
        <label htmlFor="verification">{capitalize(t('EMAIL'))}</label>

        <input
          name="email" type="text"
          placeholder="your email address"
          autoComplete="username"
          value={email}
          onChange={e => handleChange(e, setEmail)}
        />
        {
          errorData?.email &&
          <p className="error-msg">{errors?.message}</p>
        }
        {
          errorData?.invalid &&
          <p className="error-msg">{errors?.message}</p>
        }
      </div>

      <div className={classNames('form-control', { 'invalid': errorData?.password || errorData?.invalid })} >
        <label htmlFor="password">{capitalize(t('PASSWORD'))}</label>

        <PasswordInput
          onChange={handleChangePassword}
        />
        {
          errorData.password &&
          <small className="error-msg-block">
            {errorData.password}. <Link to="/create-account"><b>{t('LOGIN_PAGE_FORGOT_PASSWORD_LINK')}</b></Link>
          </small>
        }

      </div>
      <br />
      <Link className="text-center w-100 inline-block" to="/reset-password/">{t('LOGIN_PAGE_FORGOT_PASSWORD_LINK')}?</Link>
      <label className="checkbox-wrapper">
        {t('LOGIN_PAGE_KEEP_SIGNED')}
        <input
          name="keepSigned" type="checkbox"
          checked={keepSigned}
          onChange={e => handleChange(e, setKeepSigned)}
        />

        <span className="checkmark"></span>
      </label>

      <div className="button-container button-container--column button_full-size">
        <button
          className="button"
          onClick={authentificate}
          disabled={requestPending || processingRequests > 0}
        >Sign In</button>
      </div>


      <div className="form-control">
        <p className="no-margin text-center">
          {capitalize(t('LOGIN_PAGE_DONT_HAVE_ACC_TEXT'))}?{' '}
          <Link className="text-underline"
            to="/create-account"
          >{t('CLICK_HERE')}</Link>
        </p>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  initialData: PropTypes.shape({
    email: PropTypes.string
  }),
  onSignedIn: PropTypes.func
}

export default LoginForm;
