import React from 'react'
import { useSelector } from 'react-redux';
import { PublicHomeSubscriptionForm } from '../../../components';


const PublicHomeSubscribeSection = () => {
  const { user } = useSelector(state => state.auth);
  const userdata = { id: user?.id, firstName: user?.firstName, lastName: user?.lastName, email: user?.email }


  return (
    <div className="public-home__section public-home__subscription">
      <p className="subscription__pretitle">keep in touch</p>

      <h2 className="public-home__title">Stay In The Know About Vineify</h2>

      <p className="subscription__subtitle">Keep in touch for news about Vineify and learn when we offer delivery in your area.</p>

      <div className="container"><PublicHomeSubscriptionForm userData={userdata} /></div>
    </div>
  )
}

export default PublicHomeSubscribeSection;