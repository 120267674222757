import React, { memo, useRef } from 'react'; 
import PropTypes from 'prop-types';
import { v4 as keyGen } from 'uuid';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './style.scss';


const CardCarousel = ({ cards, name, config, sliderRef, slideWidth, dots, infinite, slideClassName, carouselClassName, pagination, centeredSlides, spaceBetween, withNavigation, dynamicDots, disabled, width }) => {
  const isHandHeldDevice = window.matchMedia('max-width: 768px').matches

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   variableWidth: true,
  //   speed: 500,
  //   slidesToShow: config.itemsToShow,
  //   slidesToScroll: config.itemsToScroll,
  //   ...config,
  // };

  if (!cards || cards.length === 0) return null;

  const innerSliderClassname = window.matchMedia('(max-width: 520px').matches && cards?.length === 1 ? 'w-100' : '';
  const sliderClassList = [innerSliderClassname, slideClassName].join(' ')

  // const handleNavEvent = e => {
  //   console.log('Navigation event: ', e)
  // }

  const modules = [];
  const className = []


  if (dots || pagination) {
    modules.push(Pagination);
    className.push('with-pagination')
  }

  const showNavigation = !withNavigation
    ? false
    : infinite 
      || config.itemsToShow < cards.length

  if (showNavigation) {
    modules.push(Navigation)
    className.push('swiper-with-navigation')
  }

  carouselClassName && className.push(carouselClassName)

  const slides = cards.map(
    card => (<SwiperSlide key={keyGen()} className={sliderClassList}>{card}</SwiperSlide>)
  )


  return (<>
    <Swiper
      ref={sliderRef}
      className={className.join(' ')}
      modules={modules}
      // onNavigationNext={ handleNavEvent }
      // onNavigationPrev={ handleNavEvent }
      simulateTouch={isHandHeldDevice}
      width={width}
      centeredSlides={ centeredSlides }
      key={name + new Date().getTime()}
      slidesPerView={config?.itemsToShow || 'auto'}
      spaceBetween={spaceBetween || 12}
      loop={infinite}
      pagination={dots ? {
        clickable: true,
        horizontalClass: 'swiper-pagination-vineify',
        dynamicBullets: dynamicDots,
        dynamicMainBullets: dynamicDots ? 4 : undefined,
        renderBullet: (index, className) => {
          return `<span class="slider-dot ${className}"></span>`;
        },
      } : false}
      navigation={showNavigation}
      // {...config}
      breakpoints={ config?.breakpoints || {}}
    >{slides}</Swiper>
  </>)
}


CardCarousel.propTypes = {
  cards: PropTypes.any,
  name: PropTypes.string,
  config: PropTypes.object,
  slideWidth: PropTypes.string,
  slideClassName: PropTypes.string,
  centeredSlides: PropTypes.bool
}

CardCarousel.defaultProps = {
  cards: [],
  name: '',
  slideClassName: '',
  infinite: true,
  slideWidth: '100%',
  config: {
    itemsToShow: 0,
    itemsToScroll: 1,
    dots: true
  },
  centeredSlides: false
}


export default memo(CardCarousel);
