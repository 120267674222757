import React, { useCallback, useEffect, useReducer } from 'react';
import CheckoutContext from './CheckoutContext';
import reducer from './reducer';

import {
  SET_ORDER_CAPTURE_ACTION, REMOVE_ORDER_CAPTURE_ACTION, SET_SHIPPING_COUNTRY, SET_SHIPPING_ZIPCODE, SET_ORDER_WAREHOUSE, SET_ORDER_DATA, SET_SQUARE_DATA, SET_PAYMENT_METHOD, CHANGE_PAYMENT_STEP, RESET_CHECKOUT_STATE, CHANGE_RETAILER, CHANGE_SHIPPING_DETAILS, CHANGE_BILLING_DETAILS, CHANGE_IS_GIFT, CHANGE_GIFT_MESSAGE
} from './actions';
import { useRequests } from '../../hooks';
import { useSelector } from 'react-redux';


const CheckoutProvider = ({ children }) => {
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const { get } = useRequests();
  const initialState = {
    order: undefined,
    shipping: undefined,
    billing: isAuthenticated
      ? {
        email: user.lastBilling?.email || user.email,
        firstName: user.lastBilling?.firstName || user.firstName,
        lastName: user.lastBilling?.lastName || user.lastName,
        address: user.lastBilling?.address || '',
        city: user.lastBilling?.city || '',
        state: user.lastBilling?.state || 'TX',
        country: user.lastBilling?.country || 'US',
        phone: user.lastBilling?.phone || '',
        zip: user.lastBilling?.zip || '',
      } : undefined,
    orderCapture: undefined,
    shippingCountry: '',
    zip: undefined,
    warehouse: undefined,
    retailer: undefined,
    square: {},
    paymentMethod: undefined,
    paymentStep: '',
    isGift: false,
    giftMessage: '',
    currentStep: 'shipping' // shipping -> billing -> confirm
  };

  const _checkSquareScript = () => {
    const nodes = document.querySelectorAll('script');

    return Array.of(...nodes).find(({ src }) => src.includes('web.squarecdn.com/v1/square.js'))
  }

  const _loadSquareWebSdk = (id) => {
    const squareCore = document.createElement('script');
    squareCore.type = 'text/javascript';
    squareCore.src = `https://${id?.includes('sandbox') ? 'sandbox.' : ''}web.squarecdn.com/v1/square.js`;
    document.body.appendChild(squareCore);
  }


  const checkoutAcions = {};

  const [checkoutState, dispatch] = useReducer(reducer, initialState);

  const fetchPaymentInfo = useCallback(async () => {
    const response = await get({ url: `/api/content/init-payments` });

    if (response?.data?.success) {
      const { ai: id, li: location } = response.data;

      const squareWeb = _checkSquareScript();

      !squareWeb && _loadSquareWebSdk(id);

      const squareData = { id, location }

      dispatch({
        type: SET_SQUARE_DATA, payload: squareData
      });
    }
  }, [get])

  const fetchUserContactData = useCallback(async () => {
    const response = await get({ url: `/api/orders/last-contact-details` })

    if (response?.data?.success) {
      dispatch({
        type: CHANGE_SHIPPING_DETAILS, payload: response.data.shipping
      })

      dispatch({
        type: CHANGE_BILLING_DETAILS, payload: response.data.billing
      })
    }
  }, [get])

  useEffect(() => {
    if (isAuthenticated) {
      fetchPaymentInfo()
      fetchUserContactData()
    }
  }, [])

  useEffect(() => {
    user.email && checkoutAcions.changeShippingDetails({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    })
  }, [user.email, user.firstName, user.lastName])

  checkoutAcions.removeOrderCaptureAction = () => dispatch({
    type: REMOVE_ORDER_CAPTURE_ACTION
  });

  checkoutAcions.setPaymentMethod = method => dispatch({
    type: SET_PAYMENT_METHOD, payload: method
  })

  checkoutAcions.changePaymentStep = step => dispatch({
    type: CHANGE_PAYMENT_STEP, payload: step
  })

  checkoutAcions.changeShippingZipcode = zipCode => dispatch({
    type: SET_SHIPPING_ZIPCODE, payload: zipCode
  })

  checkoutAcions.setOrderCaptureAction = orderCaptureFunction => dispatch({
    type: SET_ORDER_CAPTURE_ACTION, payload: orderCaptureFunction
  });

  checkoutAcions.setOrderWarehouse = warehouse => dispatch({
    type: SET_ORDER_WAREHOUSE, payload: warehouse
  })

  checkoutAcions.changeRetailer = retailerData => dispatch({
    type: CHANGE_RETAILER, payload: retailerData
  })

  checkoutAcions.setOrder = orderData => dispatch({
    type: SET_ORDER_DATA, payload: orderData
  })

  checkoutAcions.changeShippingCountry = countryCode => dispatch({
    type: SET_SHIPPING_COUNTRY, payload: countryCode
  });

  checkoutAcions.changeShippingDetails = shippingDetails => dispatch({
    type: CHANGE_SHIPPING_DETAILS, payload: shippingDetails
  });

  checkoutAcions.changeBillingDetails = billingDetails => dispatch({
    type: CHANGE_BILLING_DETAILS, payload: billingDetails
  });

  checkoutAcions.changeGiftMessage = message => dispatch({
    type: CHANGE_GIFT_MESSAGE, payload: message
  })

  checkoutAcions.finishCheckout = () => dispatch({ type: RESET_CHECKOUT_STATE });

  checkoutAcions.changeIsGiftState = () => dispatch({ type: CHANGE_IS_GIFT })


  return (
    <CheckoutContext.Provider value={{ ...checkoutState, ...checkoutAcions }}>
      {children}
    </CheckoutContext.Provider>
  )
}


export default CheckoutProvider;
