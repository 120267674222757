import React, { useEffect } from "react"; 
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom';
import { LoadingScreen } from "..";
import { useTranslation } from 'react-i18next';
import { useActions } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";


const WithOrderData = ({ children }) => {
  const dispatch = useDispatch()
  const { checkoutActions } = useActions();
  const { i18n: { language: lng } } = useTranslation();
  const { orderDataFetchState } = useSelector(state => state.checkout)

  const fetchCartData = async () => {
    orderDataFetchState === 'inactive' && dispatch(checkoutActions.requestUserOrder(lng || 'en'))

    // const response = await get({
    //   url: `/api/orders?lng=${lng}&init=true&new=2`
    // });


    // if (response?.data?.orders?.length) {
    //   const { contacts, ...order } = response.data.orders[0];
    //   const orderCase = Case.create(order.case);
    //   ctx.cart.order = order;
    //   ctx.cart.order.case = orderCase;

  //   const warehouseData = orderCase.getWareHouseData();
  //   setOrderWarehouse(warehouseData);

    //   setFetchResult('success');
    // } else {
    //   setFetchResult('fail');
    // }
  // }

  //   setFetchResult('success');
  // } else {
  //   setFetchResult('fail');
  // }
  }

  useEffect(() => { fetchCartData(); }, []); // eslint-disable-next-line

  if (orderDataFetchState === 'inactive') return <LoadingScreen className="w-100" />;

  if (orderDataFetchState === 'inactive') return <LoadingScreen className="w-100" />;

  if (orderDataFetchState === 'success') return (
    <>
      <Helmet>
        <title>Checkout - Vineify</title>
      </Helmet>

      {children}
    </>
  );

  // if (orderDataFetchState === 'fail') return <Redirect to="/home" />;
  if (orderDataFetchState === 'fail') return <Redirect to="/" />;
}


export default WithOrderData;
